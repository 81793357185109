import React from "react";
import { getUserAssemblyAssignedEntities, putRemoveAssignedRole } from '../../services/Voters';
import Pagination from "components/Pagination";
import RotatingLinesLoader from "components/RotatingLineLoader";
import Error from "components/error";
import withParams from "../GetParams";
import { Operations } from "../toast/operations";

class AssemblyConstituencyAssignment extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			assemblyEntities: null,
			loading: false,
			error: null,
			pageNumber: 1
		}
		this.handleRemove = this.handleRemove.bind(this);
		this.handleEntities = this.handleEntities.bind(this);
		this.onChangePage = this.onChangePage.bind(this);
	}

	onChangePage(event) {
		this.handleEntities(this.props.userId, event, this.props.pageSize);
	}

	handleEntities(userId, pageNumber, pageSize) {
		this.setState({loading: true, pageNumber});
		getUserAssemblyAssignedEntities(userId, pageNumber, pageSize).then((assemblyEntities) => {
			this.setState({assemblyEntities, loading: false});
		}).catch((error) => {
			this.setState({error, loading: false});
		})
	}

	handleRemove(event) {
		putRemoveAssignedRole(this.props.userId, {id: event}).then((data) => {
			this.props.navigate(`/user-assign-view/${this.props.userId}/?op=${Operations.AC_ASSIGNED_ENTITIES}&status=success`);
			this.handleEntities(this.props.userId, this.state.pageNumber, this.props.pageSize);
		}).catch((error) => {
			this.props.navigate(`/user-assign-view/${this.props.userId}/?op=${Operations.AC_ASSIGNED_ENTITIES}&status=error`);
			this.setState({error});
		})
	}

	componentDidMount() {
		this.handleEntities(this.props.userId, this.state.pageNumber, this.props.pageSize);
	}

	render() {
		return(
			<div className="overflow-x">
			{
				this.state.assemblyEntities != null && this.state.assemblyEntities?.data?.length != 0 ? 
					<>
						<table className="table">
							<thead>
								<tr>
									<th className="align-middle">Entities</th>
									<th className="w-10 text-center align-middle">Action</th>
								</tr>
							</thead>
							<tbody>
								{
									this.state.assemblyEntities?.data?.map(entity => 
										<tr key={`${this.state.assemblyEntities?.data?.length}-${entity.id}`}>
											<td className="align-middle">{`( ${entity.number} )`}{`${entity.name ? ` - ${entity.name}` : ''}`}</td>
											<td className="text-center align-middle"><span onClick={() => {this.handleRemove(entity.id)}} className='btn btn-danger'>Remove</span></td>
										</tr>
									)
								}
							</tbody>
						</table>
						<div className="text-center">
							{
								this.state.assemblyEntities?.count > this.props.pageSize ? 
								<Pagination pageCount={this.state.assemblyEntities?.total_pages} currentPage={this.state.assemblyEntities?.current_page ? this.state.assemblyEntities?.current_page - 1 : -1} pageSize={this.props.pageSize} onChangePage={this.onChangePage} /> : ''
							}
						</div>
					</> : this.state.loading == true ? 
					<RotatingLinesLoader /> : this.state.error != null ? 
					<Error /> : <div className="text-center fw-600 text-black-50 p-3">No data found.</div>
			}
			</div>
		)
	}
}

export default withParams(AssemblyConstituencyAssignment);