import axios from "axios";
import { axiosInstance } from './Auth';
import { STATE } from '../config/defaults';
import { faCloudDownloadAlt, faWheatAwnCircleExclamation } from "@fortawesome/free-solid-svg-icons";

const baseURL = 'https://apitest.cultnerds.io/';

const mediaURL = 'https://joinbjd.in';

const district_ac_map = {
  "51": [
      {
          "id": 6298,
          "name": "Angul",
          "district": 51
      },
      {
          "id": 6299,
          "name": "Athamallik",
          "district": 51
      },
      {
          "id": 6300,
          "name": "Chhendipada",
          "district": 51
      },
      {
          "id": 6301,
          "name": "Pallahara",
          "district": 51
      },
      {
          "id": 6302,
          "name": "Talcher",
          "district": 51
      }
  ],
  "52": [
      {
          "id": 6303,
          "name": "Balangir",
          "district": 52
      },
      {
          "id": 6304,
          "name": "Kantabanji",
          "district": 52
      },
      {
          "id": 6305,
          "name": "Loisingha(S.C)",
          "district": 52
      },
      {
          "id": 6306,
          "name": "Patnagarh",
          "district": 52
      },
      {
          "id": 6307,
          "name": "Titilagarh",
          "district": 52
      }
  ],
  "53": [
      {
          "id": 6308,
          "name": "Boudh",
          "district": 53
      },
      {
          "id": 6309,
          "name": "Kantamal",
          "district": 53
      }
  ],
  "54": [
      {
          "id": 6310,
          "name": "Aska",
          "district": 54
      },
      {
          "id": 6311,
          "name": "Berhampur",
          "district": 54
      },
      {
          "id": 6312,
          "name": "Bhanjanagar",
          "district": 54
      },
      {
          "id": 6313,
          "name": "Chhatrapur",
          "district": 54
      },
      {
          "id": 6314,
          "name": "Chikiti",
          "district": 54
      },
      {
          "id": 6315,
          "name": "Digapahandi",
          "district": 54
      },
      {
          "id": 6316,
          "name": "Gopalpur",
          "district": 54
      },
      {
          "id": 6317,
          "name": "Hinjilicut",
          "district": 54
      },
      {
          "id": 6318,
          "name": "K.S.Nagar",
          "district": 54
      },
      {
          "id": 6319,
          "name": "Khalikote",
          "district": 54
      },
      {
          "id": 6320,
          "name": "Polasara",
          "district": 54
      },
      {
          "id": 6321,
          "name": "Sanakhemundi",
          "district": 54
      },
      {
          "id": 6322,
          "name": "Sorada",
          "district": 54
      }
  ],
  "55": [
      {
          "id": 6323,
          "name": "Balasore",
          "district": 55
      },
      {
          "id": 6324,
          "name": "Basta",
          "district": 55
      },
      {
          "id": 6325,
          "name": "Bhograi",
          "district": 55
      },
      {
          "id": 6326,
          "name": "Jaleswar",
          "district": 55
      },
      {
          "id": 6327,
          "name": "Nilagiri",
          "district": 55
      },
      {
          "id": 6328,
          "name": "Remuna(SC)",
          "district": 55
      },
      {
          "id": 6329,
          "name": "Simulia",
          "district": 55
      },
      {
          "id": 6330,
          "name": "Soro(SC)",
          "district": 55
      }
  ],
  "56": [
      {
          "id": 6331,
          "name": "Mohana",
          "district": 56
      },
      {
          "id": 6332,
          "name": "Paralakhemundi",
          "district": 56
      }
  ],
  "57": [
      {
          "id": 6333,
          "name": "Birmaharajapur",
          "district": 57
      },
      {
          "id": 6334,
          "name": "Sonepur",
          "district": 57
      }
  ],
  "58": [
      {
          "id": 6335,
          "name": "Kuchinda(ST)",
          "district": 58
      },
      {
          "id": 6336,
          "name": "Rairakhol",
          "district": 58
      },
      {
          "id": 6337,
          "name": "Rengali(SC)",
          "district": 58
      },
      {
          "id": 6338,
          "name": "Sambalpur",
          "district": 58
      }
  ],
  "59": [
      {
          "id": 6339,
          "name": "Jeypore",
          "district": 59
      },
      {
          "id": 6340,
          "name": "Koraput(SC)",
          "district": 59
      },
      {
          "id": 6341,
          "name": "Kotpad(ST)",
          "district": 59
      },
      {
          "id": 6342,
          "name": "Laxmipur",
          "district": 59
      },
      {
          "id": 6343,
          "name": "Pottangi",
          "district": 59
      }
  ],
  "60": [
      {
          "id": 6344,
          "name": "Bbsr central.(M)",
          "district": 60
      },
      {
          "id": 6345,
          "name": "Bbsr north(Uttar)",
          "district": 60
      },
      {
          "id": 6346,
          "name": "Begunia",
          "district": 60
      },
      {
          "id": 6347,
          "name": "Chilika",
          "district": 60
      },
      {
          "id": 6348,
          "name": "Ekamra-bbsr",
          "district": 60
      },
      {
          "id": 6349,
          "name": "Jatni",
          "district": 60
      },
      {
          "id": 6350,
          "name": "Jayadev(S.C)",
          "district": 60
      },
      {
          "id": 6351,
          "name": "Khurda",
          "district": 60
      }
  ],
  "61": [
      {
          "id": 6352,
          "name": "Brahmagiri",
          "district": 61
      },
      {
          "id": 6353,
          "name": "Kakatpur",
          "district": 61
      },
      {
          "id": 6354,
          "name": "Nimapara",
          "district": 61
      },
      {
          "id": 6355,
          "name": "Pipili",
          "district": 61
      },
      {
          "id": 6356,
          "name": "Puri Sadar",
          "district": 61
      },
      {
          "id": 6357,
          "name": "Satyabadi",
          "district": 61
      }
  ],
  "62": [
      {
          "id": 6358,
          "name": "Dhenkanal",
          "district": 62
      },
      {
          "id": 6359,
          "name": "Hindol(SC)",
          "district": 62
      },
      {
          "id": 6360,
          "name": "Kamakhyanagar",
          "district": 62
      },
      {
          "id": 6361,
          "name": "Parjang",
          "district": 62
      }
  ],
  "63": [
      {
          "id": 6362,
          "name": "Deogarh",
          "district": 63
      }
  ],
  "64": [
      {
          "id": 6363,
          "name": "Bissam Cuttack",
          "district": 64
      },
      {
          "id": 6364,
          "name": "Gunupur",
          "district": 64
      },
      {
          "id": 6365,
          "name": "Rayagada",
          "district": 64
      }
  ],
  "65": [
      {
          "id": 6366,
          "name": "Balliguda",
          "district": 65
      },
      {
          "id": 6367,
          "name": "G.Udayagiri",
          "district": 65
      },
      {
          "id": 6368,
          "name": "Phulbani",
          "district": 65
      }
  ],
  "66": [
      {
          "id": 6369,
          "name": "Erasama",
          "district": 66
      },
      {
          "id": 6370,
          "name": "Jagatsinghpur",
          "district": 66
      },
      {
          "id": 6371,
          "name": "Paradeep",
          "district": 66
      },
      {
          "id": 6372,
          "name": "Tirtol",
          "district": 66
      }
  ],
  "67": [
      {
          "id": 6373,
          "name": "Aul",
          "district": 67
      },
      {
          "id": 6374,
          "name": "Kendrapara",
          "district": 67
      },
      {
          "id": 6375,
          "name": "Mahakalapada",
          "district": 67
      },
      {
          "id": 6376,
          "name": "Patkura",
          "district": 67
      },
      {
          "id": 6377,
          "name": "Rajnagar",
          "district": 67
      }
  ],
  "68": [
      {
          "id": 6378,
          "name": "Basudevpur",
          "district": 68
      },
      {
          "id": 6379,
          "name": "Bhadrak",
          "district": 68
      },
      {
          "id": 6380,
          "name": "Bhandaripokhari",
          "district": 68
      },
      {
          "id": 6381,
          "name": "Chandbali",
          "district": 68
      },
      {
          "id": 6382,
          "name": "Dhamnagar(SC)",
          "district": 68
      }
  ],
  "69": [
      {
          "id": 6383,
          "name": "Bhawanipatna",
          "district": 69
      },
      {
          "id": 6384,
          "name": "Dharmagarh",
          "district": 69
      },
      {
          "id": 6385,
          "name": "Junagarh",
          "district": 69
      },
      {
          "id": 6386,
          "name": "Lanjigarh(ST)",
          "district": 69
      },
      {
          "id": 6387,
          "name": "Narla",
          "district": 69
      }
  ],
  "70": [
      {
          "id": 6388,
          "name": "Daspalla",
          "district": 70
      },
      {
          "id": 6389,
          "name": "Khandapada",
          "district": 70
      },
      {
          "id": 6390,
          "name": "Nayagarh",
          "district": 70
      },
      {
          "id": 6391,
          "name": "Ranapur",
          "district": 70
      }
  ],
  "71": [
      {
          "id": 6392,
          "name": "Barachana",
          "district": 71
      },
      {
          "id": 6393,
          "name": "Bari",
          "district": 71
      },
      {
          "id": 6394,
          "name": "Binjharpur",
          "district": 71
      },
      {
          "id": 6395,
          "name": "Dharmasala",
          "district": 71
      },
      {
          "id": 6396,
          "name": "Jajpur",
          "district": 71
      },
      {
          "id": 6397,
          "name": "Korei",
          "district": 71
      },
      {
          "id": 6398,
          "name": "Sukinda",
          "district": 71
      }
  ],
  "72": [
      {
          "id": 6399,
          "name": "Badasahi(SC)",
          "district": 72
      },
      {
          "id": 6400,
          "name": "Bangriposi(ST)",
          "district": 72
      },
      {
          "id": 6401,
          "name": "Baripada(ST)",
          "district": 72
      },
      {
          "id": 6402,
          "name": "Jashipur(ST)",
          "district": 72
      },
      {
          "id": 6403,
          "name": "Karanjia(ST)",
          "district": 72
      },
      {
          "id": 6404,
          "name": "Morada",
          "district": 72
      },
      {
          "id": 6405,
          "name": "Rairangpur(ST)",
          "district": 72
      },
      {
          "id": 6406,
          "name": "Saraskana(ST)",
          "district": 72
      },
      {
          "id": 6407,
          "name": "Udala(ST)",
          "district": 72
      }
  ],
  "73": [
      {
          "id": 6408,
          "name": "Anandapur(SC)",
          "district": 73
      },
      {
          "id": 6409,
          "name": "Champua",
          "district": 73
      },
      {
          "id": 6410,
          "name": "Ghasipura",
          "district": 73
      },
      {
          "id": 6411,
          "name": "Keonjhar(ST)",
          "district": 73
      },
      {
          "id": 6412,
          "name": "Patna(ST)",
          "district": 73
      },
      {
          "id": 6413,
          "name": "Telkoi(ST)",
          "district": 73
      }
  ],
  "74": [
      {
          "id": 6414,
          "name": "Brajarajnagar",
          "district": 74
      },
      {
          "id": 6415,
          "name": "Jharsuguda",
          "district": 74
      }
  ],
  "75": [
      {
          "id": 6416,
          "name": "Khariar",
          "district": 75
      },
      {
          "id": 6417,
          "name": "Nuapada",
          "district": 75
      }
  ],
  "76": [
      {
          "id": 6418,
          "name": "Athagarh",
          "district": 76
      },
      {
          "id": 6419,
          "name": "Badamba",
          "district": 76
      },
      {
          "id": 6420,
          "name": "Banki",
          "district": 76
      },
      {
          "id": 6421,
          "name": "Barabati-cuttack",
          "district": 76
      },
      {
          "id": 6422,
          "name": "Choudwar-cuttack",
          "district": 76
      },
      {
          "id": 6423,
          "name": "Cuttack sadar(SC)",
          "district": 76
      },
      {
          "id": 6424,
          "name": "Mahanga",
          "district": 76
      },
      {
          "id": 6425,
          "name": "Niali",
          "district": 76
      },
      {
          "id": 6426,
          "name": "Salipur",
          "district": 76
      }
  ],
  "77": [
      {
          "id": 6427,
          "name": "Dabugam(S.T)",
          "district": 77
      },
      {
          "id": 6428,
          "name": "Jharigam",
          "district": 77
      },
      {
          "id": 6429,
          "name": "Nabarangpur",
          "district": 77
      },
      {
          "id": 6430,
          "name": "Umerkote(S.T)",
          "district": 77
      }
  ],
  "78": [
      {
          "id": 6431,
          "name": "Chitrakonda",
          "district": 78
      },
      {
          "id": 6432,
          "name": "Malkanagiri",
          "district": 78
      }
  ],
  "79": [
      {
          "id": 6433,
          "name": "Biramitrapur(ST)",
          "district": 79
      },
      {
          "id": 6434,
          "name": "Bonai(ST)",
          "district": 79
      },
      {
          "id": 6435,
          "name": "Raghunathpali(SC)",
          "district": 79
      },
      {
          "id": 6436,
          "name": "Rajgangpur",
          "district": 79
      },
      {
          "id": 6437,
          "name": "Rourkela",
          "district": 79
      },
      {
          "id": 6438,
          "name": "Sundargarh",
          "district": 79
      },
      {
          "id": 6439,
          "name": "Talsara(ST)",
          "district": 79
      }
  ],
  "80": [
      {
          "id": 6440,
          "name": "Attabira",
          "district": 80
      },
      {
          "id": 6441,
          "name": "Bargarh",
          "district": 80
      },
      {
          "id": 6442,
          "name": "Bhatli",
          "district": 80
      },
      {
          "id": 6443,
          "name": "Bijepur",
          "district": 80
      },
      {
          "id": 6444,
          "name": "Padampur",
          "district": 80
      }
  ]
};

const ac_block_map = {
    "6298": [
        {
            "id": 9499,
            "constituency": {
                "id": 6298,
                "name": "Angul",
                "district": 51
            },
            "name": "Angul",
            "is_urban": false
        },
        {
            "id": 9500,
            "constituency": {
                "id": 6298,
                "name": "Angul",
                "district": 51
            },
            "name": "Angul (Municipality)",
            "is_urban": true
        },
        {
            "id": 9501,
            "constituency": {
                "id": 6298,
                "name": "Angul",
                "district": 51
            },
            "name": "Banarpal",
            "is_urban": false
        }
    ],
    "6299": [
        {
            "id": 9502,
            "constituency": {
                "id": 6299,
                "name": "Athamallik",
                "district": 51
            },
            "name": "Angul",
            "is_urban": false
        },
        {
            "id": 9503,
            "constituency": {
                "id": 6299,
                "name": "Athamallik",
                "district": 51
            },
            "name": "Athamallik",
            "is_urban": false
        },
        {
            "id": 9504,
            "constituency": {
                "id": 6299,
                "name": "Athamallik",
                "district": 51
            },
            "name": "Athamallik (N.A.C)",
            "is_urban": true
        },
        {
            "id": 9505,
            "constituency": {
                "id": 6299,
                "name": "Athamallik",
                "district": 51
            },
            "name": "Hindol (N.A.C)",
            "is_urban": true
        },
        {
            "id": 9506,
            "constituency": {
                "id": 6299,
                "name": "Athamallik",
                "district": 51
            },
            "name": "Kishorenagar",
            "is_urban": false
        }
    ],
    "6300": [
        {
            "id": 9507,
            "constituency": {
                "id": 6300,
                "name": "Chhendipada",
                "district": 51
            },
            "name": "Banarpal",
            "is_urban": false
        },
        {
            "id": 9508,
            "constituency": {
                "id": 6300,
                "name": "Chhendipada",
                "district": 51
            },
            "name": "Chhendipada",
            "is_urban": false
        }
    ],
    "6301": [
        {
            "id": 9509,
            "constituency": {
                "id": 6301,
                "name": "Pallahara",
                "district": 51
            },
            "name": "Kaniha",
            "is_urban": false
        },
        {
            "id": 9510,
            "constituency": {
                "id": 6301,
                "name": "Pallahara",
                "district": 51
            },
            "name": "Pallahara",
            "is_urban": false
        }
    ],
    "6302": [
        {
            "id": 9511,
            "constituency": {
                "id": 6302,
                "name": "Talcher",
                "district": 51
            },
            "name": "Kaniha",
            "is_urban": false
        },
        {
            "id": 9512,
            "constituency": {
                "id": 6302,
                "name": "Talcher",
                "district": 51
            },
            "name": "Talcher",
            "is_urban": false
        },
        {
            "id": 9513,
            "constituency": {
                "id": 6302,
                "name": "Talcher",
                "district": 51
            },
            "name": "Talcher (Municipality)",
            "is_urban": true
        }
    ],
    "6303": [
        {
            "id": 9514,
            "constituency": {
                "id": 6303,
                "name": "Balangir",
                "district": 52
            },
            "name": "Balangir",
            "is_urban": false
        },
        {
            "id": 9515,
            "constituency": {
                "id": 6303,
                "name": "Balangir",
                "district": 52
            },
            "name": "Balangir (Municipality)",
            "is_urban": true
        },
        {
            "id": 9516,
            "constituency": {
                "id": 6303,
                "name": "Balangir",
                "district": 52
            },
            "name": "Deogaon",
            "is_urban": false
        }
    ],
    "6304": [
        {
            "id": 9517,
            "constituency": {
                "id": 6304,
                "name": "Kantabanji",
                "district": 52
            },
            "name": "Bangomunda",
            "is_urban": false
        },
        {
            "id": 9518,
            "constituency": {
                "id": 6304,
                "name": "Kantabanji",
                "district": 52
            },
            "name": "Kantabanji",
            "is_urban": true
        },
        {
            "id": 9519,
            "constituency": {
                "id": 6304,
                "name": "Kantabanji",
                "district": 52
            },
            "name": "Muribahal",
            "is_urban": false
        },
        {
            "id": 9520,
            "constituency": {
                "id": 6304,
                "name": "Kantabanji",
                "district": 52
            },
            "name": "Turekela",
            "is_urban": false
        }
    ],
    "6305": [
        {
            "id": 9521,
            "constituency": {
                "id": 6305,
                "name": "Loisingha(S.C)",
                "district": 52
            },
            "name": "Agalpur",
            "is_urban": false
        },
        {
            "id": 9522,
            "constituency": {
                "id": 6305,
                "name": "Loisingha(S.C)",
                "district": 52
            },
            "name": "Loisingha",
            "is_urban": false
        },
        {
            "id": 9523,
            "constituency": {
                "id": 6305,
                "name": "Loisingha(S.C)",
                "district": 52
            },
            "name": "Puintala",
            "is_urban": false
        }
    ],
    "6306": [
        {
            "id": 9524,
            "constituency": {
                "id": 6306,
                "name": "Patnagarh",
                "district": 52
            },
            "name": "Belpara",
            "is_urban": false
        },
        {
            "id": 9525,
            "constituency": {
                "id": 6306,
                "name": "Patnagarh",
                "district": 52
            },
            "name": "Khaprakhol",
            "is_urban": false
        },
        {
            "id": 9526,
            "constituency": {
                "id": 6306,
                "name": "Patnagarh",
                "district": 52
            },
            "name": "Patnagarh",
            "is_urban": false
        },
        {
            "id": 9527,
            "constituency": {
                "id": 6306,
                "name": "Patnagarh",
                "district": 52
            },
            "name": "Patnagarh (N.A.C)",
            "is_urban": true
        }
    ],
    "6307": [
        {
            "id": 9528,
            "constituency": {
                "id": 6307,
                "name": "Titilagarh",
                "district": 52
            },
            "name": "Saintala",
            "is_urban": false
        },
        {
            "id": 9529,
            "constituency": {
                "id": 6307,
                "name": "Titilagarh",
                "district": 52
            },
            "name": "Tentulikhunti (Gudvella)",
            "is_urban": false
        },
        {
            "id": 9530,
            "constituency": {
                "id": 6307,
                "name": "Titilagarh",
                "district": 52
            },
            "name": "Titilagarh",
            "is_urban": false
        },
        {
            "id": 9531,
            "constituency": {
                "id": 6307,
                "name": "Titilagarh",
                "district": 52
            },
            "name": "Titilagarh (N.A.C)",
            "is_urban": true
        },
        {
            "id": 9532,
            "constituency": {
                "id": 6307,
                "name": "Titilagarh",
                "district": 52
            },
            "name": "Titilagarh Municipality",
            "is_urban": true
        },
        {
            "id": 9533,
            "constituency": {
                "id": 6307,
                "name": "Titilagarh",
                "district": 52
            },
            "name": "Tusura NAC",
            "is_urban": true
        }
    ],
    "6308": [
        {
            "id": 9534,
            "constituency": {
                "id": 6308,
                "name": "Boudh",
                "district": 53
            },
            "name": "Boudh",
            "is_urban": false
        },
        {
            "id": 9535,
            "constituency": {
                "id": 6308,
                "name": "Boudh",
                "district": 53
            },
            "name": "Boudhgarh (N.A.C)",
            "is_urban": true
        },
        {
            "id": 9536,
            "constituency": {
                "id": 6308,
                "name": "Boudh",
                "district": 53
            },
            "name": "Harabhanga",
            "is_urban": false
        }
    ],
    "6309": [
        {
            "id": 9537,
            "constituency": {
                "id": 6309,
                "name": "Kantamal",
                "district": 53
            },
            "name": "Boudh",
            "is_urban": false
        },
        {
            "id": 9538,
            "constituency": {
                "id": 6309,
                "name": "Kantamal",
                "district": 53
            },
            "name": "Boudhgarh (N.A.C)",
            "is_urban": true
        },
        {
            "id": 9539,
            "constituency": {
                "id": 6309,
                "name": "Kantamal",
                "district": 53
            },
            "name": "Kantamal",
            "is_urban": false
        }
    ],
    "6310": [
        {
            "id": 9540,
            "constituency": {
                "id": 6310,
                "name": "Aska",
                "district": 54
            },
            "name": "Aska",
            "is_urban": false
        },
        {
            "id": 9541,
            "constituency": {
                "id": 6310,
                "name": "Aska",
                "district": 54
            },
            "name": "Aska (N.A.C)",
            "is_urban": true
        },
        {
            "id": 9542,
            "constituency": {
                "id": 6310,
                "name": "Aska",
                "district": 54
            },
            "name": "K.S.Nagar",
            "is_urban": false
        }
    ],
    "6311": [
        {
            "id": 9543,
            "constituency": {
                "id": 6311,
                "name": "Berhampur",
                "district": 54
            },
            "name": "Berhampur (Municipality)",
            "is_urban": true
        }
    ],
    "6312": [
        {
            "id": 9544,
            "constituency": {
                "id": 6312,
                "name": "Bhanjanagar",
                "district": 54
            },
            "name": "Bhanjanagar",
            "is_urban": false
        },
        {
            "id": 9545,
            "constituency": {
                "id": 6312,
                "name": "Bhanjanagar",
                "district": 54
            },
            "name": "Bhanjanagar (N.A.C)",
            "is_urban": true
        },
        {
            "id": 9546,
            "constituency": {
                "id": 6312,
                "name": "Bhanjanagar",
                "district": 54
            },
            "name": "Jagannathprasad",
            "is_urban": false
        }
    ],
    "6313": [
        {
            "id": 9547,
            "constituency": {
                "id": 6313,
                "name": "Chhatrapur",
                "district": 54
            },
            "name": "Chhatrapur",
            "is_urban": false
        },
        {
            "id": 9548,
            "constituency": {
                "id": 6313,
                "name": "Chhatrapur",
                "district": 54
            },
            "name": "Chhatrapur (N.A.C)",
            "is_urban": true
        },
        {
            "id": 9549,
            "constituency": {
                "id": 6313,
                "name": "Chhatrapur",
                "district": 54
            },
            "name": "Ganjam",
            "is_urban": false
        },
        {
            "id": 9550,
            "constituency": {
                "id": 6313,
                "name": "Chhatrapur",
                "district": 54
            },
            "name": "Ganjam (N.A.C)",
            "is_urban": true
        },
        {
            "id": 9551,
            "constituency": {
                "id": 6313,
                "name": "Chhatrapur",
                "district": 54
            },
            "name": "Rambha (N.A.C)",
            "is_urban": true
        }
    ],
    "6314": [
        {
            "id": 9552,
            "constituency": {
                "id": 6314,
                "name": "Chikiti",
                "district": 54
            },
            "name": "Chikiti",
            "is_urban": false
        },
        {
            "id": 9553,
            "constituency": {
                "id": 6314,
                "name": "Chikiti",
                "district": 54
            },
            "name": "Chikiti (N.A.C)",
            "is_urban": true
        },
        {
            "id": 9554,
            "constituency": {
                "id": 6314,
                "name": "Chikiti",
                "district": 54
            },
            "name": "Patrapur",
            "is_urban": false
        }
    ],
    "6315": [
        {
            "id": 9555,
            "constituency": {
                "id": 6315,
                "name": "Digapahandi",
                "district": 54
            },
            "name": "Digapahandi",
            "is_urban": false
        },
        {
            "id": 9556,
            "constituency": {
                "id": 6315,
                "name": "Digapahandi",
                "district": 54
            },
            "name": "Digapahandi (N.A.C)",
            "is_urban": true
        },
        {
            "id": 9557,
            "constituency": {
                "id": 6315,
                "name": "Digapahandi",
                "district": 54
            },
            "name": "Kukudahandi",
            "is_urban": false
        }
    ],
    "6316": [
        {
            "id": 9558,
            "constituency": {
                "id": 6316,
                "name": "Gopalpur",
                "district": 54
            },
            "name": "Berhampur (Municipality)",
            "is_urban": true
        },
        {
            "id": 9559,
            "constituency": {
                "id": 6316,
                "name": "Gopalpur",
                "district": 54
            },
            "name": "Gopalpur (N.A.C)",
            "is_urban": true
        },
        {
            "id": 9560,
            "constituency": {
                "id": 6316,
                "name": "Gopalpur",
                "district": 54
            },
            "name": "Kukudahandi",
            "is_urban": false
        },
        {
            "id": 9561,
            "constituency": {
                "id": 6316,
                "name": "Gopalpur",
                "district": 54
            },
            "name": "Rangeilunda",
            "is_urban": false
        }
    ],
    "6317": [
        {
            "id": 9562,
            "constituency": {
                "id": 6317,
                "name": "Hinjilicut",
                "district": 54
            },
            "name": "Hinjilicut",
            "is_urban": false
        },
        {
            "id": 9563,
            "constituency": {
                "id": 6317,
                "name": "Hinjilicut",
                "district": 54
            },
            "name": "Hinjilicut (Municipality)",
            "is_urban": true
        },
        {
            "id": 9564,
            "constituency": {
                "id": 6317,
                "name": "Hinjilicut",
                "district": 54
            },
            "name": "Seragada",
            "is_urban": true
        }
    ],
    "6318": [
        {
            "id": 9565,
            "constituency": {
                "id": 6318,
                "name": "K.S.Nagar",
                "district": 54
            },
            "name": "Beguniapada",
            "is_urban": false
        },
        {
            "id": 9566,
            "constituency": {
                "id": 6318,
                "name": "K.S.Nagar",
                "district": 54
            },
            "name": "K.S.Nagar",
            "is_urban": false
        },
        {
            "id": 9567,
            "constituency": {
                "id": 6318,
                "name": "K.S.Nagar",
                "district": 54
            },
            "name": "K.S.Nagar (N.A.C)",
            "is_urban": true
        },
        {
            "id": 9568,
            "constituency": {
                "id": 6318,
                "name": "K.S.Nagar",
                "district": 54
            },
            "name": "Kodala NAC",
            "is_urban": true
        },
        {
            "id": 9569,
            "constituency": {
                "id": 6318,
                "name": "K.S.Nagar",
                "district": 54
            },
            "name": "Purushottampur",
            "is_urban": false
        }
    ],
    "6319": [
        {
            "id": 9570,
            "constituency": {
                "id": 6319,
                "name": "Khalikote",
                "district": 54
            },
            "name": "Khalikote",
            "is_urban": false
        },
        {
            "id": 9571,
            "constituency": {
                "id": 6319,
                "name": "Khalikote",
                "district": 54
            },
            "name": "Khalikote (N.A.C)",
            "is_urban": true
        },
        {
            "id": 9572,
            "constituency": {
                "id": 6319,
                "name": "Khalikote",
                "district": 54
            },
            "name": "Purushottampur",
            "is_urban": false
        },
        {
            "id": 9573,
            "constituency": {
                "id": 6319,
                "name": "Khalikote",
                "district": 54
            },
            "name": "Purushottampur (N.A.C)",
            "is_urban": true
        }
    ],
    "6320": [
        {
            "id": 9574,
            "constituency": {
                "id": 6320,
                "name": "Polasara",
                "district": 54
            },
            "name": "Buguda",
            "is_urban": false
        },
        {
            "id": 9575,
            "constituency": {
                "id": 6320,
                "name": "Polasara",
                "district": 54
            },
            "name": "Buguda (N.A.C)",
            "is_urban": true
        },
        {
            "id": 9576,
            "constituency": {
                "id": 6320,
                "name": "Polasara",
                "district": 54
            },
            "name": "Polasara",
            "is_urban": true
        },
        {
            "id": 9577,
            "constituency": {
                "id": 6320,
                "name": "Polasara",
                "district": 54
            },
            "name": "Polsora (N.A.C)",
            "is_urban": true
        }
    ],
    "6321": [
        {
            "id": 9578,
            "constituency": {
                "id": 6321,
                "name": "Sanakhemundi",
                "district": 54
            },
            "name": "Dharakote",
            "is_urban": false
        },
        {
            "id": 9579,
            "constituency": {
                "id": 6321,
                "name": "Sanakhemundi",
                "district": 54
            },
            "name": "Sanakhemundi",
            "is_urban": false
        }
    ],
    "6322": [
        {
            "id": 9580,
            "constituency": {
                "id": 6322,
                "name": "Sorada",
                "district": 54
            },
            "name": "Belagunatha (N.A.C)",
            "is_urban": true
        },
        {
            "id": 9581,
            "constituency": {
                "id": 6322,
                "name": "Sorada",
                "district": 54
            },
            "name": "Bellaguntha",
            "is_urban": false
        },
        {
            "id": 9582,
            "constituency": {
                "id": 6322,
                "name": "Sorada",
                "district": 54
            },
            "name": "Sorada",
            "is_urban": false
        },
        {
            "id": 9583,
            "constituency": {
                "id": 6322,
                "name": "Sorada",
                "district": 54
            },
            "name": "Sorada (N.A.C)",
            "is_urban": true
        }
    ],
    "6323": [
        {
            "id": 9584,
            "constituency": {
                "id": 6323,
                "name": "Balasore",
                "district": 55
            },
            "name": "Balasore",
            "is_urban": false
        },
        {
            "id": 9585,
            "constituency": {
                "id": 6323,
                "name": "Balasore",
                "district": 55
            },
            "name": "Balasore (Municipality)",
            "is_urban": true
        }
    ],
    "6324": [
        {
            "id": 9586,
            "constituency": {
                "id": 6324,
                "name": "Basta",
                "district": 55
            },
            "name": "Baliapal",
            "is_urban": false
        },
        {
            "id": 9587,
            "constituency": {
                "id": 6324,
                "name": "Basta",
                "district": 55
            },
            "name": "Basta",
            "is_urban": false
        }
    ],
    "6325": [
        {
            "id": 9588,
            "constituency": {
                "id": 6325,
                "name": "Bhograi",
                "district": 55
            },
            "name": "Bhograi",
            "is_urban": false
        }
    ],
    "6326": [
        {
            "id": 9589,
            "constituency": {
                "id": 6326,
                "name": "Jaleswar",
                "district": 55
            },
            "name": "Basta",
            "is_urban": false
        },
        {
            "id": 9590,
            "constituency": {
                "id": 6326,
                "name": "Jaleswar",
                "district": 55
            },
            "name": "Jaleswar",
            "is_urban": false
        },
        {
            "id": 9591,
            "constituency": {
                "id": 6326,
                "name": "Jaleswar",
                "district": 55
            },
            "name": "Jaleswar (Municipality)",
            "is_urban": true
        }
    ],
    "6327": [
        {
            "id": 9592,
            "constituency": {
                "id": 6327,
                "name": "Nilagiri",
                "district": 55
            },
            "name": "Bahanaga",
            "is_urban": false
        },
        {
            "id": 9593,
            "constituency": {
                "id": 6327,
                "name": "Nilagiri",
                "district": 55
            },
            "name": "Nilagiri",
            "is_urban": false
        },
        {
            "id": 9594,
            "constituency": {
                "id": 6327,
                "name": "Nilagiri",
                "district": 55
            },
            "name": "Nilagiri (N.A.C)",
            "is_urban": true
        },
        {
            "id": 9595,
            "constituency": {
                "id": 6327,
                "name": "Nilagiri",
                "district": 55
            },
            "name": "Oupada",
            "is_urban": false
        }
    ],
    "6328": [
        {
            "id": 9596,
            "constituency": {
                "id": 6328,
                "name": "Remuna(SC)",
                "district": 55
            },
            "name": "Balasore",
            "is_urban": false
        },
        {
            "id": 9597,
            "constituency": {
                "id": 6328,
                "name": "Remuna(SC)",
                "district": 55
            },
            "name": "Remuna",
            "is_urban": false
        }
    ],
    "6329": [
        {
            "id": 9598,
            "constituency": {
                "id": 6329,
                "name": "Simulia",
                "district": 55
            },
            "name": "Khaira",
            "is_urban": false
        },
        {
            "id": 9599,
            "constituency": {
                "id": 6329,
                "name": "Simulia",
                "district": 55
            },
            "name": "Simulia",
            "is_urban": false
        }
    ],
    "6330": [
        {
            "id": 9600,
            "constituency": {
                "id": 6330,
                "name": "Soro(SC)",
                "district": 55
            },
            "name": "Bahanaga",
            "is_urban": false
        },
        {
            "id": 9601,
            "constituency": {
                "id": 6330,
                "name": "Soro(SC)",
                "district": 55
            },
            "name": "Soro",
            "is_urban": false
        },
        {
            "id": 9602,
            "constituency": {
                "id": 6330,
                "name": "Soro(SC)",
                "district": 55
            },
            "name": "Soro (N.A.C)",
            "is_urban": true
        }
    ],
    "6331": [
        {
            "id": 9603,
            "constituency": {
                "id": 6331,
                "name": "Mohana",
                "district": 56
            },
            "name": "Mohana",
            "is_urban": false
        },
        {
            "id": 9604,
            "constituency": {
                "id": 6331,
                "name": "Mohana",
                "district": 56
            },
            "name": "Nuagada",
            "is_urban": false
        },
        {
            "id": 9605,
            "constituency": {
                "id": 6331,
                "name": "Mohana",
                "district": 56
            },
            "name": "R. Udaygiri",
            "is_urban": false
        },
        {
            "id": 9606,
            "constituency": {
                "id": 6331,
                "name": "Mohana",
                "district": 56
            },
            "name": "Rayagada",
            "is_urban": false
        }
    ],
    "6332": [
        {
            "id": 9607,
            "constituency": {
                "id": 6332,
                "name": "Paralakhemundi",
                "district": 56
            },
            "name": "Gumma",
            "is_urban": false
        },
        {
            "id": 9608,
            "constituency": {
                "id": 6332,
                "name": "Paralakhemundi",
                "district": 56
            },
            "name": "Kasinagar",
            "is_urban": false
        },
        {
            "id": 9609,
            "constituency": {
                "id": 6332,
                "name": "Paralakhemundi",
                "district": 56
            },
            "name": "Kasinagar (N.A.C)",
            "is_urban": true
        },
        {
            "id": 9610,
            "constituency": {
                "id": 6332,
                "name": "Paralakhemundi",
                "district": 56
            },
            "name": "P.khemundi (Municipality)",
            "is_urban": true
        },
        {
            "id": 9611,
            "constituency": {
                "id": 6332,
                "name": "Paralakhemundi",
                "district": 56
            },
            "name": "Paralakhemundi",
            "is_urban": false
        }
    ],
    "6333": [
        {
            "id": 9612,
            "constituency": {
                "id": 6333,
                "name": "Birmaharajapur",
                "district": 57
            },
            "name": "Binika",
            "is_urban": false
        },
        {
            "id": 9613,
            "constituency": {
                "id": 6333,
                "name": "Birmaharajapur",
                "district": 57
            },
            "name": "Binika (N.A.C)",
            "is_urban": true
        },
        {
            "id": 9614,
            "constituency": {
                "id": 6333,
                "name": "Birmaharajapur",
                "district": 57
            },
            "name": "Birmaharajapur",
            "is_urban": false
        },
        {
            "id": 9615,
            "constituency": {
                "id": 6333,
                "name": "Birmaharajapur",
                "district": 57
            },
            "name": "Ullunda",
            "is_urban": false
        }
    ],
    "6334": [
        {
            "id": 9616,
            "constituency": {
                "id": 6334,
                "name": "Sonepur",
                "district": 57
            },
            "name": "Binka",
            "is_urban": false
        },
        {
            "id": 9617,
            "constituency": {
                "id": 6334,
                "name": "Sonepur",
                "district": 57
            },
            "name": "Dunguripali",
            "is_urban": false
        },
        {
            "id": 9618,
            "constituency": {
                "id": 6334,
                "name": "Sonepur",
                "district": 57
            },
            "name": "Sonepur",
            "is_urban": false
        },
        {
            "id": 9619,
            "constituency": {
                "id": 6334,
                "name": "Sonepur",
                "district": 57
            },
            "name": "Sonepur (Municipality)",
            "is_urban": true
        },
        {
            "id": 9620,
            "constituency": {
                "id": 6334,
                "name": "Sonepur",
                "district": 57
            },
            "name": "Tarva",
            "is_urban": false
        },
        {
            "id": 9621,
            "constituency": {
                "id": 6334,
                "name": "Sonepur",
                "district": 57
            },
            "name": "Tarva (N.A.C)",
            "is_urban": true
        }
    ],
    "6335": [
        {
            "id": 9622,
            "constituency": {
                "id": 6335,
                "name": "Kuchinda(ST)",
                "district": 58
            },
            "name": "Bamra",
            "is_urban": false
        },
        {
            "id": 9623,
            "constituency": {
                "id": 6335,
                "name": "Kuchinda(ST)",
                "district": 58
            },
            "name": "Jamankira",
            "is_urban": false
        },
        {
            "id": 9624,
            "constituency": {
                "id": 6335,
                "name": "Kuchinda(ST)",
                "district": 58
            },
            "name": "Kuchinda",
            "is_urban": false
        },
        {
            "id": 9625,
            "constituency": {
                "id": 6335,
                "name": "Kuchinda(ST)",
                "district": 58
            },
            "name": "Kuchinda (N.A.C)",
            "is_urban": true
        }
    ],
    "6336": [
        {
            "id": 9626,
            "constituency": {
                "id": 6336,
                "name": "Rairakhol",
                "district": 58
            },
            "name": "Jujumura",
            "is_urban": false
        },
        {
            "id": 9627,
            "constituency": {
                "id": 6336,
                "name": "Rairakhol",
                "district": 58
            },
            "name": "Maneswar",
            "is_urban": false
        },
        {
            "id": 9628,
            "constituency": {
                "id": 6336,
                "name": "Rairakhol",
                "district": 58
            },
            "name": "Naktideoul",
            "is_urban": false
        },
        {
            "id": 9629,
            "constituency": {
                "id": 6336,
                "name": "Rairakhol",
                "district": 58
            },
            "name": "Rairakhol",
            "is_urban": false
        },
        {
            "id": 9630,
            "constituency": {
                "id": 6336,
                "name": "Rairakhol",
                "district": 58
            },
            "name": "Rairakhol (N.A.C)",
            "is_urban": true
        }
    ],
    "6337": [
        {
            "id": 9631,
            "constituency": {
                "id": 6337,
                "name": "Rengali(SC)",
                "district": 58
            },
            "name": "Dhankauda",
            "is_urban": false
        },
        {
            "id": 9632,
            "constituency": {
                "id": 6337,
                "name": "Rengali(SC)",
                "district": 58
            },
            "name": "Maneswar",
            "is_urban": false
        },
        {
            "id": 9633,
            "constituency": {
                "id": 6337,
                "name": "Rengali(SC)",
                "district": 58
            },
            "name": "Rengali",
            "is_urban": false
        }
    ],
    "6338": [
        {
            "id": 9634,
            "constituency": {
                "id": 6338,
                "name": "Sambalpur",
                "district": 58
            },
            "name": "Burla (N.A.C)",
            "is_urban": true
        },
        {
            "id": 9635,
            "constituency": {
                "id": 6338,
                "name": "Sambalpur",
                "district": 58
            },
            "name": "Hirakud (N.A.C)",
            "is_urban": true
        },
        {
            "id": 9636,
            "constituency": {
                "id": 6338,
                "name": "Sambalpur",
                "district": 58
            },
            "name": "Sambalpur (Municipality)",
            "is_urban": true
        }
    ],
    "6339": [
        {
            "id": 9637,
            "constituency": {
                "id": 6339,
                "name": "Jeypore",
                "district": 59
            },
            "name": "Borigumma",
            "is_urban": false
        },
        {
            "id": 9638,
            "constituency": {
                "id": 6339,
                "name": "Jeypore",
                "district": 59
            },
            "name": "Jeypore",
            "is_urban": true
        },
        {
            "id": 9639,
            "constituency": {
                "id": 6339,
                "name": "Jeypore",
                "district": 59
            },
            "name": "Jeypore Municipality",
            "is_urban": true
        }
    ],
    "6340": [
        {
            "id": 9640,
            "constituency": {
                "id": 6340,
                "name": "Koraput(SC)",
                "district": 59
            },
            "name": "Baipariguda",
            "is_urban": false
        },
        {
            "id": 9641,
            "constituency": {
                "id": 6340,
                "name": "Koraput(SC)",
                "district": 59
            },
            "name": "Koraput",
            "is_urban": false
        },
        {
            "id": 9642,
            "constituency": {
                "id": 6340,
                "name": "Koraput(SC)",
                "district": 59
            },
            "name": "Koraput (N.A.C)",
            "is_urban": true
        },
        {
            "id": 9643,
            "constituency": {
                "id": 6340,
                "name": "Koraput(SC)",
                "district": 59
            },
            "name": "Lamtaput",
            "is_urban": false
        },
        {
            "id": 9644,
            "constituency": {
                "id": 6340,
                "name": "Koraput(SC)",
                "district": 59
            },
            "name": "Sunabeda (N.A.C)",
            "is_urban": true
        }
    ],
    "6341": [
        {
            "id": 9645,
            "constituency": {
                "id": 6341,
                "name": "Kotpad(ST)",
                "district": 59
            },
            "name": "Baipariguda",
            "is_urban": false
        },
        {
            "id": 9646,
            "constituency": {
                "id": 6341,
                "name": "Kotpad(ST)",
                "district": 59
            },
            "name": "Borigumma",
            "is_urban": false
        },
        {
            "id": 9647,
            "constituency": {
                "id": 6341,
                "name": "Kotpad(ST)",
                "district": 59
            },
            "name": "Kotpad",
            "is_urban": false
        },
        {
            "id": 9648,
            "constituency": {
                "id": 6341,
                "name": "Kotpad(ST)",
                "district": 59
            },
            "name": "Kotpad (N.A.C)",
            "is_urban": true
        },
        {
            "id": 9649,
            "constituency": {
                "id": 6341,
                "name": "Kotpad(ST)",
                "district": 59
            },
            "name": "Kundura",
            "is_urban": false
        }
    ],
    "6342": [
        {
            "id": 9650,
            "constituency": {
                "id": 6342,
                "name": "Laxmipur",
                "district": 59
            },
            "name": "Bandhugam",
            "is_urban": false
        },
        {
            "id": 9651,
            "constituency": {
                "id": 6342,
                "name": "Laxmipur",
                "district": 59
            },
            "name": "Dasamantapur",
            "is_urban": false
        },
        {
            "id": 9652,
            "constituency": {
                "id": 6342,
                "name": "Laxmipur",
                "district": 59
            },
            "name": "Laxmipur",
            "is_urban": false
        },
        {
            "id": 9653,
            "constituency": {
                "id": 6342,
                "name": "Laxmipur",
                "district": 59
            },
            "name": "Narayana Patna",
            "is_urban": false
        }
    ],
    "6343": [
        {
            "id": 9654,
            "constituency": {
                "id": 6343,
                "name": "Pottangi",
                "district": 59
            },
            "name": "Koraput",
            "is_urban": false
        },
        {
            "id": 9655,
            "constituency": {
                "id": 6343,
                "name": "Pottangi",
                "district": 59
            },
            "name": "Nandapur",
            "is_urban": false
        },
        {
            "id": 9656,
            "constituency": {
                "id": 6343,
                "name": "Pottangi",
                "district": 59
            },
            "name": "Pottangi",
            "is_urban": false
        },
        {
            "id": 9657,
            "constituency": {
                "id": 6343,
                "name": "Pottangi",
                "district": 59
            },
            "name": "Semiliguda",
            "is_urban": false
        }
    ],
    "6344": [
        {
            "id": 9658,
            "constituency": {
                "id": 6344,
                "name": "Bbsr central.(M)",
                "district": 60
            },
            "name": "Bbsr (Municipality)",
            "is_urban": true
        }
    ],
    "6345": [
        {
            "id": 9659,
            "constituency": {
                "id": 6345,
                "name": "Bbsr north(Uttar)",
                "district": 60
            },
            "name": "Bbsr (Municipality)",
            "is_urban": true
        },
        {
            "id": 9660,
            "constituency": {
                "id": 6345,
                "name": "Bbsr north(Uttar)",
                "district": 60
            },
            "name": "Bbsr north(Uttar)",
            "is_urban": false
        }
    ],
    "6346": [
        {
            "id": 9661,
            "constituency": {
                "id": 6346,
                "name": "Begunia",
                "district": 60
            },
            "name": "Begunia",
            "is_urban": false
        },
        {
            "id": 9662,
            "constituency": {
                "id": 6346,
                "name": "Begunia",
                "district": 60
            },
            "name": "Bolagarh",
            "is_urban": false
        }
    ],
    "6347": [
        {
            "id": 9663,
            "constituency": {
                "id": 6347,
                "name": "Chilika",
                "district": 60
            },
            "name": "Balugaon (N.A.C)",
            "is_urban": true
        },
        {
            "id": 9664,
            "constituency": {
                "id": 6347,
                "name": "Chilika",
                "district": 60
            },
            "name": "Banapur (N.A.C)",
            "is_urban": true
        },
        {
            "id": 9665,
            "constituency": {
                "id": 6347,
                "name": "Chilika",
                "district": 60
            },
            "name": "Banpur",
            "is_urban": false
        },
        {
            "id": 9666,
            "constituency": {
                "id": 6347,
                "name": "Chilika",
                "district": 60
            },
            "name": "Chilika",
            "is_urban": false
        }
    ],
    "6348": [
        {
            "id": 9667,
            "constituency": {
                "id": 6348,
                "name": "Ekamra-bbsr",
                "district": 60
            },
            "name": "Bbsr (Municipality)",
            "is_urban": true
        },
        {
            "id": 9668,
            "constituency": {
                "id": 6348,
                "name": "Ekamra-bbsr",
                "district": 60
            },
            "name": "Bhubaneswar",
            "is_urban": false
        }
    ],
    "6349": [
        {
            "id": 9669,
            "constituency": {
                "id": 6349,
                "name": "Jatni",
                "district": 60
            },
            "name": "Bhubaneswar",
            "is_urban": false
        },
        {
            "id": 9670,
            "constituency": {
                "id": 6349,
                "name": "Jatni",
                "district": 60
            },
            "name": "Jatni",
            "is_urban": false
        },
        {
            "id": 9671,
            "constituency": {
                "id": 6349,
                "name": "Jatni",
                "district": 60
            },
            "name": "Jatni (Municipality)",
            "is_urban": true
        },
        {
            "id": 9672,
            "constituency": {
                "id": 6349,
                "name": "Jatni",
                "district": 60
            },
            "name": "Khorada",
            "is_urban": false
        }
    ],
    "6350": [
        {
            "id": 9673,
            "constituency": {
                "id": 6350,
                "name": "Jayadev(S.C)",
                "district": 60
            },
            "name": "Balianta",
            "is_urban": false
        },
        {
            "id": 9674,
            "constituency": {
                "id": 6350,
                "name": "Jayadev(S.C)",
                "district": 60
            },
            "name": "Balipatana",
            "is_urban": false
        }
    ],
    "6351": [
        {
            "id": 9675,
            "constituency": {
                "id": 6351,
                "name": "Khurda",
                "district": 60
            },
            "name": "Khurda",
            "is_urban": false
        },
        {
            "id": 9676,
            "constituency": {
                "id": 6351,
                "name": "Khurda",
                "district": 60
            },
            "name": "Khurda (Municipality)",
            "is_urban": true
        },
        {
            "id": 9677,
            "constituency": {
                "id": 6351,
                "name": "Khurda",
                "district": 60
            },
            "name": "Tangi",
            "is_urban": false
        }
    ],
    "6352": [
        {
            "id": 9678,
            "constituency": {
                "id": 6352,
                "name": "Brahmagiri",
                "district": 61
            },
            "name": "Brahmagiri",
            "is_urban": false
        },
        {
            "id": 9679,
            "constituency": {
                "id": 6352,
                "name": "Brahmagiri",
                "district": 61
            },
            "name": "Krushnaprasad",
            "is_urban": false
        },
        {
            "id": 9680,
            "constituency": {
                "id": 6352,
                "name": "Brahmagiri",
                "district": 61
            },
            "name": "Puri Sadar",
            "is_urban": false
        }
    ],
    "6353": [
        {
            "id": 9681,
            "constituency": {
                "id": 6353,
                "name": "Kakatpur",
                "district": 61
            },
            "name": "Astarang",
            "is_urban": false
        },
        {
            "id": 9682,
            "constituency": {
                "id": 6353,
                "name": "Kakatpur",
                "district": 61
            },
            "name": "Gop",
            "is_urban": false
        },
        {
            "id": 9683,
            "constituency": {
                "id": 6353,
                "name": "Kakatpur",
                "district": 61
            },
            "name": "Kakatpur",
            "is_urban": false
        },
        {
            "id": 9684,
            "constituency": {
                "id": 6353,
                "name": "Kakatpur",
                "district": 61
            },
            "name": "Konark (N.A.C)",
            "is_urban": true
        }
    ],
    "6354": [
        {
            "id": 9685,
            "constituency": {
                "id": 6354,
                "name": "Nimapara",
                "district": 61
            },
            "name": "Gop",
            "is_urban": false
        },
        {
            "id": 9686,
            "constituency": {
                "id": 6354,
                "name": "Nimapara",
                "district": 61
            },
            "name": "Nimapara",
            "is_urban": false
        },
        {
            "id": 9687,
            "constituency": {
                "id": 6354,
                "name": "Nimapara",
                "district": 61
            },
            "name": "Nimapara (N.A.C)",
            "is_urban": true
        }
    ],
    "6355": [
        {
            "id": 9688,
            "constituency": {
                "id": 6355,
                "name": "Pipili",
                "district": 61
            },
            "name": "Delang",
            "is_urban": false
        },
        {
            "id": 9689,
            "constituency": {
                "id": 6355,
                "name": "Pipili",
                "district": 61
            },
            "name": "Pipili",
            "is_urban": false
        },
        {
            "id": 9690,
            "constituency": {
                "id": 6355,
                "name": "Pipili",
                "district": 61
            },
            "name": "Pipili (N.A.C)",
            "is_urban": true
        }
    ],
    "6356": [
        {
            "id": 9691,
            "constituency": {
                "id": 6356,
                "name": "Puri Sadar",
                "district": 61
            },
            "name": "Puri (Municipality)",
            "is_urban": true
        },
        {
            "id": 9692,
            "constituency": {
                "id": 6356,
                "name": "Puri Sadar",
                "district": 61
            },
            "name": "Puri Sadar",
            "is_urban": false
        }
    ],
    "6357": [
        {
            "id": 9693,
            "constituency": {
                "id": 6357,
                "name": "Satyabadi",
                "district": 61
            },
            "name": "Kanas",
            "is_urban": false
        },
        {
            "id": 9694,
            "constituency": {
                "id": 6357,
                "name": "Satyabadi",
                "district": 61
            },
            "name": "Satyabadi",
            "is_urban": false
        }
    ],
    "6358": [
        {
            "id": 9695,
            "constituency": {
                "id": 6358,
                "name": "Dhenkanal",
                "district": 62
            },
            "name": "Dhenkanal (Municipality)",
            "is_urban": true
        },
        {
            "id": 9696,
            "constituency": {
                "id": 6358,
                "name": "Dhenkanal",
                "district": 62
            },
            "name": "Dhenkanal Sadar",
            "is_urban": false
        },
        {
            "id": 9697,
            "constituency": {
                "id": 6358,
                "name": "Dhenkanal",
                "district": 62
            },
            "name": "Gondia",
            "is_urban": false
        }
    ],
    "6359": [
        {
            "id": 9698,
            "constituency": {
                "id": 6359,
                "name": "Hindol(SC)",
                "district": 62
            },
            "name": "Hindol",
            "is_urban": false
        },
        {
            "id": 9699,
            "constituency": {
                "id": 6359,
                "name": "Hindol(SC)",
                "district": 62
            },
            "name": "Hindol NAC",
            "is_urban": true
        },
        {
            "id": 9700,
            "constituency": {
                "id": 6359,
                "name": "Hindol(SC)",
                "district": 62
            },
            "name": "Odapada",
            "is_urban": false
        }
    ],
    "6360": [
        {
            "id": 9701,
            "constituency": {
                "id": 6360,
                "name": "Kamakhyanagar",
                "district": 62
            },
            "name": "Bhuban",
            "is_urban": false
        },
        {
            "id": 9702,
            "constituency": {
                "id": 6360,
                "name": "Kamakhyanagar",
                "district": 62
            },
            "name": "Bhuban (N.A.C)",
            "is_urban": true
        },
        {
            "id": 9703,
            "constituency": {
                "id": 6360,
                "name": "Kamakhyanagar",
                "district": 62
            },
            "name": "Dhenkanal Sadar",
            "is_urban": false
        },
        {
            "id": 9704,
            "constituency": {
                "id": 6360,
                "name": "Kamakhyanagar",
                "district": 62
            },
            "name": "Kamakhyanagar",
            "is_urban": false
        },
        {
            "id": 9705,
            "constituency": {
                "id": 6360,
                "name": "Kamakhyanagar",
                "district": 62
            },
            "name": "Kamakhyanagar (N.A.C)",
            "is_urban": true
        }
    ],
    "6361": [
        {
            "id": 9706,
            "constituency": {
                "id": 6361,
                "name": "Parjang",
                "district": 62
            },
            "name": "Kamakhyanagar",
            "is_urban": false
        },
        {
            "id": 9707,
            "constituency": {
                "id": 6361,
                "name": "Parjang",
                "district": 62
            },
            "name": "Kankadahad",
            "is_urban": false
        },
        {
            "id": 9708,
            "constituency": {
                "id": 6361,
                "name": "Parjang",
                "district": 62
            },
            "name": "Parjang",
            "is_urban": false
        }
    ],
    "6362": [
        {
            "id": 9709,
            "constituency": {
                "id": 6362,
                "name": "Deogarh",
                "district": 63
            },
            "name": "Barkote",
            "is_urban": false
        },
        {
            "id": 9710,
            "constituency": {
                "id": 6362,
                "name": "Deogarh",
                "district": 63
            },
            "name": "Deogarh (Municipality)",
            "is_urban": true
        },
        {
            "id": 9711,
            "constituency": {
                "id": 6362,
                "name": "Deogarh",
                "district": 63
            },
            "name": "Reamal",
            "is_urban": false
        },
        {
            "id": 9712,
            "constituency": {
                "id": 6362,
                "name": "Deogarh",
                "district": 63
            },
            "name": "Tileibani",
            "is_urban": false
        }
    ],
    "6363": [
        {
            "id": 9713,
            "constituency": {
                "id": 6363,
                "name": "Bissam Cuttack",
                "district": 64
            },
            "name": "Bissam Cuttack",
            "is_urban": false
        },
        {
            "id": 9714,
            "constituency": {
                "id": 6363,
                "name": "Bissam Cuttack",
                "district": 64
            },
            "name": "K.SINGAPUR",
            "is_urban": false
        },
        {
            "id": 9715,
            "constituency": {
                "id": 6363,
                "name": "Bissam Cuttack",
                "district": 64
            },
            "name": "Kolanara",
            "is_urban": false
        },
        {
            "id": 9716,
            "constituency": {
                "id": 6363,
                "name": "Bissam Cuttack",
                "district": 64
            },
            "name": "Muniguda",
            "is_urban": false
        }
    ],
    "6364": [
        {
            "id": 9717,
            "constituency": {
                "id": 6364,
                "name": "Gunupur",
                "district": 64
            },
            "name": "Chandrapur",
            "is_urban": false
        },
        {
            "id": 9718,
            "constituency": {
                "id": 6364,
                "name": "Gunupur",
                "district": 64
            },
            "name": "Gudari",
            "is_urban": false
        },
        {
            "id": 9719,
            "constituency": {
                "id": 6364,
                "name": "Gunupur",
                "district": 64
            },
            "name": "Gudari (N.A.C)",
            "is_urban": true
        },
        {
            "id": 9720,
            "constituency": {
                "id": 6364,
                "name": "Gunupur",
                "district": 64
            },
            "name": "Gunupur",
            "is_urban": false
        },
        {
            "id": 9721,
            "constituency": {
                "id": 6364,
                "name": "Gunupur",
                "district": 64
            },
            "name": "Gunupur (N.A.C)",
            "is_urban": true
        },
        {
            "id": 9722,
            "constituency": {
                "id": 6364,
                "name": "Gunupur",
                "district": 64
            },
            "name": "Padmapur",
            "is_urban": false
        },
        {
            "id": 9723,
            "constituency": {
                "id": 6364,
                "name": "Gunupur",
                "district": 64
            },
            "name": "Ramanaguda",
            "is_urban": false
        }
    ],
    "6365": [
        {
            "id": 9724,
            "constituency": {
                "id": 6365,
                "name": "Rayagada",
                "district": 64
            },
            "name": "Kashipur",
            "is_urban": false
        },
        {
            "id": 9725,
            "constituency": {
                "id": 6365,
                "name": "Rayagada",
                "district": 64
            },
            "name": "Rayagada",
            "is_urban": false
        },
        {
            "id": 9726,
            "constituency": {
                "id": 6365,
                "name": "Rayagada",
                "district": 64
            },
            "name": "Rayagada (Municipality)",
            "is_urban": true
        }
    ],
    "6366": [
        {
            "id": 9727,
            "constituency": {
                "id": 6366,
                "name": "Balliguda",
                "district": 65
            },
            "name": "Balliguda",
            "is_urban": false
        },
        {
            "id": 9728,
            "constituency": {
                "id": 6366,
                "name": "Balliguda",
                "district": 65
            },
            "name": "Balliguda (N.A.C)",
            "is_urban": true
        },
        {
            "id": 9729,
            "constituency": {
                "id": 6366,
                "name": "Balliguda",
                "district": 65
            },
            "name": "K.Nuagaon",
            "is_urban": false
        },
        {
            "id": 9730,
            "constituency": {
                "id": 6366,
                "name": "Balliguda",
                "district": 65
            },
            "name": "Kotagarh",
            "is_urban": false
        },
        {
            "id": 9731,
            "constituency": {
                "id": 6366,
                "name": "Balliguda",
                "district": 65
            },
            "name": "Tumudibandh",
            "is_urban": false
        }
    ],
    "6367": [
        {
            "id": 9732,
            "constituency": {
                "id": 6367,
                "name": "G.Udayagiri",
                "district": 65
            },
            "name": "Daringbadi",
            "is_urban": false
        },
        {
            "id": 9733,
            "constituency": {
                "id": 6367,
                "name": "G.Udayagiri",
                "district": 65
            },
            "name": "G.Udayagiri",
            "is_urban": false
        },
        {
            "id": 9734,
            "constituency": {
                "id": 6367,
                "name": "G.Udayagiri",
                "district": 65
            },
            "name": "G.udayagiri (N.A.C)",
            "is_urban": true
        },
        {
            "id": 9735,
            "constituency": {
                "id": 6367,
                "name": "G.Udayagiri",
                "district": 65
            },
            "name": "Raikia",
            "is_urban": false
        },
        {
            "id": 9736,
            "constituency": {
                "id": 6367,
                "name": "G.Udayagiri",
                "district": 65
            },
            "name": "Tikabali",
            "is_urban": false
        }
    ],
    "6368": [
        {
            "id": 9737,
            "constituency": {
                "id": 6368,
                "name": "Phulbani",
                "district": 65
            },
            "name": "Chakapad",
            "is_urban": false
        },
        {
            "id": 9738,
            "constituency": {
                "id": 6368,
                "name": "Phulbani",
                "district": 65
            },
            "name": "Khajuripada",
            "is_urban": false
        },
        {
            "id": 9739,
            "constituency": {
                "id": 6368,
                "name": "Phulbani",
                "district": 65
            },
            "name": "Phiringia",
            "is_urban": false
        },
        {
            "id": 9740,
            "constituency": {
                "id": 6368,
                "name": "Phulbani",
                "district": 65
            },
            "name": "Phulbani",
            "is_urban": false
        },
        {
            "id": 9741,
            "constituency": {
                "id": 6368,
                "name": "Phulbani",
                "district": 65
            },
            "name": "Phulbani (Municipality)",
            "is_urban": true
        }
    ],
    "6369": [
        {
            "id": 9742,
            "constituency": {
                "id": 6369,
                "name": "Erasama",
                "district": 66
            },
            "name": "Balikuda",
            "is_urban": false
        },
        {
            "id": 9743,
            "constituency": {
                "id": 6369,
                "name": "Erasama",
                "district": 66
            },
            "name": "Erasama",
            "is_urban": true
        }
    ],
    "6370": [
        {
            "id": 9744,
            "constituency": {
                "id": 6370,
                "name": "Jagatsinghpur",
                "district": 66
            },
            "name": "Jagatsinghpur",
            "is_urban": false
        },
        {
            "id": 9745,
            "constituency": {
                "id": 6370,
                "name": "Jagatsinghpur",
                "district": 66
            },
            "name": "Jagatsinghpur (Municipality)",
            "is_urban": true
        },
        {
            "id": 9746,
            "constituency": {
                "id": 6370,
                "name": "Jagatsinghpur",
                "district": 66
            },
            "name": "Naugaon",
            "is_urban": false
        }
    ],
    "6371": [
        {
            "id": 9747,
            "constituency": {
                "id": 6371,
                "name": "Paradeep",
                "district": 66
            },
            "name": "Kujanga",
            "is_urban": false
        },
        {
            "id": 9748,
            "constituency": {
                "id": 6371,
                "name": "Paradeep",
                "district": 66
            },
            "name": "Paradeep (Municipality)",
            "is_urban": true
        },
        {
            "id": 9749,
            "constituency": {
                "id": 6371,
                "name": "Paradeep",
                "district": 66
            },
            "name": "Tritol",
            "is_urban": false
        }
    ],
    "6372": [
        {
            "id": 9750,
            "constituency": {
                "id": 6372,
                "name": "Tirtol",
                "district": 66
            },
            "name": "Biridi",
            "is_urban": false
        },
        {
            "id": 9751,
            "constituency": {
                "id": 6372,
                "name": "Tirtol",
                "district": 66
            },
            "name": "Paradeep (Municipality)",
            "is_urban": true
        },
        {
            "id": 9752,
            "constituency": {
                "id": 6372,
                "name": "Tirtol",
                "district": 66
            },
            "name": "Raghunathpur",
            "is_urban": false
        },
        {
            "id": 9753,
            "constituency": {
                "id": 6372,
                "name": "Tirtol",
                "district": 66
            },
            "name": "Tirtol",
            "is_urban": false
        }
    ],
    "6373": [
        {
            "id": 9754,
            "constituency": {
                "id": 6373,
                "name": "Aul",
                "district": 67
            },
            "name": "Aul",
            "is_urban": false
        },
        {
            "id": 9755,
            "constituency": {
                "id": 6373,
                "name": "Aul",
                "district": 67
            },
            "name": "Rajkanika",
            "is_urban": false
        }
    ],
    "6374": [
        {
            "id": 9756,
            "constituency": {
                "id": 6374,
                "name": "Kendrapara",
                "district": 67
            },
            "name": "Kendrapara",
            "is_urban": false
        },
        {
            "id": 9757,
            "constituency": {
                "id": 6374,
                "name": "Kendrapara",
                "district": 67
            },
            "name": "Kendrapara (Municipality)",
            "is_urban": true
        },
        {
            "id": 9758,
            "constituency": {
                "id": 6374,
                "name": "Kendrapara",
                "district": 67
            },
            "name": "Pattamundai",
            "is_urban": false
        }
    ],
    "6375": [
        {
            "id": 9759,
            "constituency": {
                "id": 6375,
                "name": "Mahakalapada",
                "district": 67
            },
            "name": "Mahakalapada",
            "is_urban": false
        },
        {
            "id": 9760,
            "constituency": {
                "id": 6375,
                "name": "Mahakalapada",
                "district": 67
            },
            "name": "Marshaghai",
            "is_urban": false
        }
    ],
    "6376": [
        {
            "id": 9761,
            "constituency": {
                "id": 6376,
                "name": "Patkura",
                "district": 67
            },
            "name": "Derabisi",
            "is_urban": false
        },
        {
            "id": 9762,
            "constituency": {
                "id": 6376,
                "name": "Patkura",
                "district": 67
            },
            "name": "Garadpur",
            "is_urban": false
        },
        {
            "id": 9763,
            "constituency": {
                "id": 6376,
                "name": "Patkura",
                "district": 67
            },
            "name": "Marshaghai",
            "is_urban": false
        }
    ],
    "6377": [
        {
            "id": 9764,
            "constituency": {
                "id": 6377,
                "name": "Rajnagar",
                "district": 67
            },
            "name": "Pattamundai",
            "is_urban": false
        },
        {
            "id": 9765,
            "constituency": {
                "id": 6377,
                "name": "Rajnagar",
                "district": 67
            },
            "name": "Pattamundai (N.A.C)",
            "is_urban": true
        },
        {
            "id": 9766,
            "constituency": {
                "id": 6377,
                "name": "Rajnagar",
                "district": 67
            },
            "name": "Rajnagar",
            "is_urban": false
        }
    ],
    "6378": [
        {
            "id": 9767,
            "constituency": {
                "id": 6378,
                "name": "Basudevpur",
                "district": 68
            },
            "name": "Basudevpur",
            "is_urban": false
        },
        {
            "id": 9768,
            "constituency": {
                "id": 6378,
                "name": "Basudevpur",
                "district": 68
            },
            "name": "Basudevpur (N.A.C)",
            "is_urban": true
        },
        {
            "id": 9769,
            "constituency": {
                "id": 6378,
                "name": "Basudevpur",
                "district": 68
            },
            "name": "Tihidi",
            "is_urban": false
        }
    ],
    "6379": [
        {
            "id": 9770,
            "constituency": {
                "id": 6379,
                "name": "Bhadrak",
                "district": 68
            },
            "name": "Bhadrak",
            "is_urban": false
        },
        {
            "id": 9771,
            "constituency": {
                "id": 6379,
                "name": "Bhadrak",
                "district": 68
            },
            "name": "Bhadrak (Municipality)",
            "is_urban": true
        }
    ],
    "6380": [
        {
            "id": 9772,
            "constituency": {
                "id": 6380,
                "name": "Bhandaripokhari",
                "district": 68
            },
            "name": "Bhandaripokhari",
            "is_urban": false
        },
        {
            "id": 9773,
            "constituency": {
                "id": 6380,
                "name": "Bhandaripokhari",
                "district": 68
            },
            "name": "Bonth",
            "is_urban": false
        }
    ],
    "6381": [
        {
            "id": 9774,
            "constituency": {
                "id": 6381,
                "name": "Chandbali",
                "district": 68
            },
            "name": "Chandbali",
            "is_urban": false
        },
        {
            "id": 9775,
            "constituency": {
                "id": 6381,
                "name": "Chandbali",
                "district": 68
            },
            "name": "Chandbali NAC",
            "is_urban": true
        },
        {
            "id": 9776,
            "constituency": {
                "id": 6381,
                "name": "Chandbali",
                "district": 68
            },
            "name": "Tihidi",
            "is_urban": false
        }
    ],
    "6382": [
        {
            "id": 9777,
            "constituency": {
                "id": 6382,
                "name": "Dhamnagar(SC)",
                "district": 68
            },
            "name": "Dhamnagar",
            "is_urban": false
        },
        {
            "id": 9778,
            "constituency": {
                "id": 6382,
                "name": "Dhamnagar(SC)",
                "district": 68
            },
            "name": "Tihidi",
            "is_urban": false
        }
    ],
    "6383": [
        {
            "id": 9779,
            "constituency": {
                "id": 6383,
                "name": "Bhawanipatna",
                "district": 69
            },
            "name": "Bhawanipatna",
            "is_urban": false
        },
        {
            "id": 9780,
            "constituency": {
                "id": 6383,
                "name": "Bhawanipatna",
                "district": 69
            },
            "name": "Bhawanipatna (Municipality)",
            "is_urban": true
        },
        {
            "id": 9781,
            "constituency": {
                "id": 6383,
                "name": "Bhawanipatna",
                "district": 69
            },
            "name": "Kesinga",
            "is_urban": false
        },
        {
            "id": 9782,
            "constituency": {
                "id": 6383,
                "name": "Bhawanipatna",
                "district": 69
            },
            "name": "Kesinga (N.A.C.) ",
            "is_urban": true
        }
    ],
    "6384": [
        {
            "id": 9783,
            "constituency": {
                "id": 6384,
                "name": "Dharmagarh",
                "district": 69
            },
            "name": "Dharmagarh",
            "is_urban": false
        },
        {
            "id": 9784,
            "constituency": {
                "id": 6384,
                "name": "Dharmagarh",
                "district": 69
            },
            "name": "Dharmagarh (N.A.C)",
            "is_urban": true
        },
        {
            "id": 9785,
            "constituency": {
                "id": 6384,
                "name": "Dharmagarh",
                "district": 69
            },
            "name": "Jaipatna",
            "is_urban": false
        },
        {
            "id": 9786,
            "constituency": {
                "id": 6384,
                "name": "Dharmagarh",
                "district": 69
            },
            "name": "Kalampur",
            "is_urban": false
        },
        {
            "id": 9787,
            "constituency": {
                "id": 6384,
                "name": "Dharmagarh",
                "district": 69
            },
            "name": "Kokasara",
            "is_urban": false
        }
    ],
    "6385": [
        {
            "id": 9788,
            "constituency": {
                "id": 6385,
                "name": "Junagarh",
                "district": 69
            },
            "name": "Golamunda",
            "is_urban": false
        },
        {
            "id": 9789,
            "constituency": {
                "id": 6385,
                "name": "Junagarh",
                "district": 69
            },
            "name": "Junagarh",
            "is_urban": false
        },
        {
            "id": 9790,
            "constituency": {
                "id": 6385,
                "name": "Junagarh",
                "district": 69
            },
            "name": "Junagarh (N.A.C)",
            "is_urban": true
        }
    ],
    "6386": [
        {
            "id": 9791,
            "constituency": {
                "id": 6386,
                "name": "Lanjigarh(ST)",
                "district": 69
            },
            "name": "Bhawanipatna",
            "is_urban": false
        },
        {
            "id": 9792,
            "constituency": {
                "id": 6386,
                "name": "Lanjigarh(ST)",
                "district": 69
            },
            "name": "Jaipatna",
            "is_urban": false
        },
        {
            "id": 9793,
            "constituency": {
                "id": 6386,
                "name": "Lanjigarh(ST)",
                "district": 69
            },
            "name": "Lanjigarh",
            "is_urban": false
        },
        {
            "id": 9794,
            "constituency": {
                "id": 6386,
                "name": "Lanjigarh(ST)",
                "district": 69
            },
            "name": "Th.Rampur",
            "is_urban": false
        }
    ],
    "6387": [
        {
            "id": 9795,
            "constituency": {
                "id": 6387,
                "name": "Narla",
                "district": 69
            },
            "name": "Karlamunda",
            "is_urban": false
        },
        {
            "id": 9796,
            "constituency": {
                "id": 6387,
                "name": "Narla",
                "district": 69
            },
            "name": "Kesinga",
            "is_urban": false
        },
        {
            "id": 9797,
            "constituency": {
                "id": 6387,
                "name": "Narla",
                "district": 69
            },
            "name": "M Rampur",
            "is_urban": false
        },
        {
            "id": 9798,
            "constituency": {
                "id": 6387,
                "name": "Narla",
                "district": 69
            },
            "name": "Narla",
            "is_urban": false
        }
    ],
    "6388": [
        {
            "id": 9799,
            "constituency": {
                "id": 6388,
                "name": "Daspalla",
                "district": 70
            },
            "name": "Daspalla",
            "is_urban": true
        },
        {
            "id": 9800,
            "constituency": {
                "id": 6388,
                "name": "Daspalla",
                "district": 70
            },
            "name": "Daspalla (N.A.C)",
            "is_urban": true
        },
        {
            "id": 9801,
            "constituency": {
                "id": 6388,
                "name": "Daspalla",
                "district": 70
            },
            "name": "Gania",
            "is_urban": false
        },
        {
            "id": 9802,
            "constituency": {
                "id": 6388,
                "name": "Daspalla",
                "district": 70
            },
            "name": "Nuagoan",
            "is_urban": false
        }
    ],
    "6389": [
        {
            "id": 9803,
            "constituency": {
                "id": 6389,
                "name": "Khandapada",
                "district": 70
            },
            "name": "Bhapur",
            "is_urban": true
        },
        {
            "id": 9804,
            "constituency": {
                "id": 6389,
                "name": "Khandapada",
                "district": 70
            },
            "name": "Bhapur (N.A.C)",
            "is_urban": true
        },
        {
            "id": 9805,
            "constituency": {
                "id": 6389,
                "name": "Khandapada",
                "district": 70
            },
            "name": "Khandapada",
            "is_urban": false
        },
        {
            "id": 9806,
            "constituency": {
                "id": 6389,
                "name": "Khandapada",
                "district": 70
            },
            "name": "khandapada NAC",
            "is_urban": true
        }
    ],
    "6390": [
        {
            "id": 9807,
            "constituency": {
                "id": 6390,
                "name": "Nayagarh",
                "district": 70
            },
            "name": "Nayagarh",
            "is_urban": false
        },
        {
            "id": 9808,
            "constituency": {
                "id": 6390,
                "name": "Nayagarh",
                "district": 70
            },
            "name": "Nayagarh (N.A.C)",
            "is_urban": true
        },
        {
            "id": 9809,
            "constituency": {
                "id": 6390,
                "name": "Nayagarh",
                "district": 70
            },
            "name": "Odagoan",
            "is_urban": false
        },
        {
            "id": 9810,
            "constituency": {
                "id": 6390,
                "name": "Nayagarh",
                "district": 70
            },
            "name": "Odagoan NAC",
            "is_urban": true
        }
    ],
    "6391": [
        {
            "id": 9811,
            "constituency": {
                "id": 6391,
                "name": "Ranapur",
                "district": 70
            },
            "name": "Odagoan",
            "is_urban": false
        },
        {
            "id": 9812,
            "constituency": {
                "id": 6391,
                "name": "Ranapur",
                "district": 70
            },
            "name": "Ranapur",
            "is_urban": false
        },
        {
            "id": 9813,
            "constituency": {
                "id": 6391,
                "name": "Ranapur",
                "district": 70
            },
            "name": "Ranapur (N.A.C)",
            "is_urban": true
        }
    ],
    "6392": [
        {
            "id": 9814,
            "constituency": {
                "id": 6392,
                "name": "Barachana",
                "district": 71
            },
            "name": "Barachana",
            "is_urban": false
        }
    ],
    "6393": [
        {
            "id": 9815,
            "constituency": {
                "id": 6393,
                "name": "Bari",
                "district": 71
            },
            "name": "Bari",
            "is_urban": false
        },
        {
            "id": 9816,
            "constituency": {
                "id": 6393,
                "name": "Bari",
                "district": 71
            },
            "name": "Jajpur",
            "is_urban": false
        },
        {
            "id": 9817,
            "constituency": {
                "id": 6393,
                "name": "Bari",
                "district": 71
            },
            "name": "Rasulpur",
            "is_urban": false
        }
    ],
    "6394": [
        {
            "id": 9818,
            "constituency": {
                "id": 6394,
                "name": "Binjharpur",
                "district": 71
            },
            "name": "Binjharpur",
            "is_urban": false
        },
        {
            "id": 9819,
            "constituency": {
                "id": 6394,
                "name": "Binjharpur",
                "district": 71
            },
            "name": "Dasarathpur",
            "is_urban": false
        }
    ],
    "6395": [
        {
            "id": 9820,
            "constituency": {
                "id": 6395,
                "name": "Dharmasala",
                "district": 71
            },
            "name": "Dharmasala",
            "is_urban": false
        },
        {
            "id": 9821,
            "constituency": {
                "id": 6395,
                "name": "Dharmasala",
                "district": 71
            },
            "name": "Rasulpur",
            "is_urban": false
        }
    ],
    "6396": [
        {
            "id": 9822,
            "constituency": {
                "id": 6396,
                "name": "Jajpur",
                "district": 71
            },
            "name": "Dasarathpur",
            "is_urban": false
        },
        {
            "id": 9823,
            "constituency": {
                "id": 6396,
                "name": "Jajpur",
                "district": 71
            },
            "name": "Jajpur",
            "is_urban": false
        },
        {
            "id": 9824,
            "constituency": {
                "id": 6396,
                "name": "Jajpur",
                "district": 71
            },
            "name": "Jajpur (Municipality)",
            "is_urban": true
        }
    ],
    "6397": [
        {
            "id": 9825,
            "constituency": {
                "id": 6397,
                "name": "Korei",
                "district": 71
            },
            "name": "Korei",
            "is_urban": false
        },
        {
            "id": 9826,
            "constituency": {
                "id": 6397,
                "name": "Korei",
                "district": 71
            },
            "name": "Rasulpur",
            "is_urban": false
        },
        {
            "id": 9827,
            "constituency": {
                "id": 6397,
                "name": "Korei",
                "district": 71
            },
            "name": "Vyasanagar (Municipality)",
            "is_urban": true
        },
        {
            "id": 9828,
            "constituency": {
                "id": 6397,
                "name": "Korei",
                "district": 71
            },
            "name": "Vyasanagar OG",
            "is_urban": false
        }
    ],
    "6398": [
        {
            "id": 9829,
            "constituency": {
                "id": 6398,
                "name": "Sukinda",
                "district": 71
            },
            "name": "Dangadi",
            "is_urban": true
        },
        {
            "id": 9830,
            "constituency": {
                "id": 6398,
                "name": "Sukinda",
                "district": 71
            },
            "name": "Sukinda",
            "is_urban": false
        }
    ],
    "6399": [
        {
            "id": 9831,
            "constituency": {
                "id": 6399,
                "name": "Badasahi(SC)",
                "district": 72
            },
            "name": "Badasahi",
            "is_urban": false
        },
        {
            "id": 9832,
            "constituency": {
                "id": 6399,
                "name": "Badasahi(SC)",
                "district": 72
            },
            "name": "Betnoti",
            "is_urban": false
        },
        {
            "id": 9833,
            "constituency": {
                "id": 6399,
                "name": "Badasahi(SC)",
                "district": 72
            },
            "name": "Kaptipada",
            "is_urban": false
        }
    ],
    "6400": [
        {
            "id": 9834,
            "constituency": {
                "id": 6400,
                "name": "Bangriposi(ST)",
                "district": 72
            },
            "name": "Bangiriposi",
            "is_urban": false
        },
        {
            "id": 9835,
            "constituency": {
                "id": 6400,
                "name": "Bangriposi(ST)",
                "district": 72
            },
            "name": "Kuliana",
            "is_urban": false
        },
        {
            "id": 9836,
            "constituency": {
                "id": 6400,
                "name": "Bangriposi(ST)",
                "district": 72
            },
            "name": "Shamkhunta",
            "is_urban": false
        }
    ],
    "6401": [
        {
            "id": 9837,
            "constituency": {
                "id": 6401,
                "name": "Baripada(ST)",
                "district": 72
            },
            "name": "Badasahi",
            "is_urban": true
        },
        {
            "id": 9838,
            "constituency": {
                "id": 6401,
                "name": "Baripada(ST)",
                "district": 72
            },
            "name": "Baripada",
            "is_urban": false
        },
        {
            "id": 9839,
            "constituency": {
                "id": 6401,
                "name": "Baripada(ST)",
                "district": 72
            },
            "name": "Baripada (Municipality)",
            "is_urban": true
        },
        {
            "id": 9840,
            "constituency": {
                "id": 6401,
                "name": "Baripada(ST)",
                "district": 72
            },
            "name": "Khunta",
            "is_urban": false
        }
    ],
    "6402": [
        {
            "id": 9841,
            "constituency": {
                "id": 6402,
                "name": "Jashipur(ST)",
                "district": 72
            },
            "name": "Jasipur",
            "is_urban": false
        },
        {
            "id": 9842,
            "constituency": {
                "id": 6402,
                "name": "Jashipur(ST)",
                "district": 72
            },
            "name": "Kusumi",
            "is_urban": false
        },
        {
            "id": 9843,
            "constituency": {
                "id": 6402,
                "name": "Jashipur(ST)",
                "district": 72
            },
            "name": "Raruan",
            "is_urban": false
        },
        {
            "id": 9844,
            "constituency": {
                "id": 6402,
                "name": "Jashipur(ST)",
                "district": 72
            },
            "name": "Sukruli",
            "is_urban": false
        }
    ],
    "6403": [
        {
            "id": 9845,
            "constituency": {
                "id": 6403,
                "name": "Karanjia(ST)",
                "district": 72
            },
            "name": "Kaptipada",
            "is_urban": false
        },
        {
            "id": 9846,
            "constituency": {
                "id": 6403,
                "name": "Karanjia(ST)",
                "district": 72
            },
            "name": "Karanjia",
            "is_urban": false
        },
        {
            "id": 9847,
            "constituency": {
                "id": 6403,
                "name": "Karanjia(ST)",
                "district": 72
            },
            "name": "Karanjia (N.A.C)",
            "is_urban": true
        },
        {
            "id": 9848,
            "constituency": {
                "id": 6403,
                "name": "Karanjia(ST)",
                "district": 72
            },
            "name": "Thakurmunda",
            "is_urban": false
        }
    ],
    "6404": [
        {
            "id": 9849,
            "constituency": {
                "id": 6404,
                "name": "Morada",
                "district": 72
            },
            "name": "Morada",
            "is_urban": false
        },
        {
            "id": 9850,
            "constituency": {
                "id": 6404,
                "name": "Morada",
                "district": 72
            },
            "name": "Rasgobindpur",
            "is_urban": false
        },
        {
            "id": 9851,
            "constituency": {
                "id": 6404,
                "name": "Morada",
                "district": 72
            },
            "name": "Suliapada",
            "is_urban": false
        }
    ],
    "6405": [
        {
            "id": 9852,
            "constituency": {
                "id": 6405,
                "name": "Rairangpur(ST)",
                "district": 72
            },
            "name": "Bahalda",
            "is_urban": false
        },
        {
            "id": 9853,
            "constituency": {
                "id": 6405,
                "name": "Rairangpur(ST)",
                "district": 72
            },
            "name": "Jamda",
            "is_urban": false
        },
        {
            "id": 9854,
            "constituency": {
                "id": 6405,
                "name": "Rairangpur(ST)",
                "district": 72
            },
            "name": "Rairangpur",
            "is_urban": false
        },
        {
            "id": 9855,
            "constituency": {
                "id": 6405,
                "name": "Rairangpur(ST)",
                "district": 72
            },
            "name": "Rairangpur (N.A.C)",
            "is_urban": true
        },
        {
            "id": 9856,
            "constituency": {
                "id": 6405,
                "name": "Rairangpur(ST)",
                "district": 72
            },
            "name": "Tiring",
            "is_urban": false
        }
    ],
    "6406": [
        {
            "id": 9857,
            "constituency": {
                "id": 6406,
                "name": "Saraskana(ST)",
                "district": 72
            },
            "name": "Bijatala",
            "is_urban": false
        },
        {
            "id": 9858,
            "constituency": {
                "id": 6406,
                "name": "Saraskana(ST)",
                "district": 72
            },
            "name": "Bisoi",
            "is_urban": false
        },
        {
            "id": 9859,
            "constituency": {
                "id": 6406,
                "name": "Saraskana(ST)",
                "district": 72
            },
            "name": "Kusumi",
            "is_urban": false
        },
        {
            "id": 9860,
            "constituency": {
                "id": 6406,
                "name": "Saraskana(ST)",
                "district": 72
            },
            "name": "Saraskana",
            "is_urban": false
        }
    ],
    "6407": [
        {
            "id": 9861,
            "constituency": {
                "id": 6407,
                "name": "Udala(ST)",
                "district": 72
            },
            "name": "G.B.Nagar",
            "is_urban": false
        },
        {
            "id": 9862,
            "constituency": {
                "id": 6407,
                "name": "Udala(ST)",
                "district": 72
            },
            "name": "Kaptipada",
            "is_urban": false
        },
        {
            "id": 9863,
            "constituency": {
                "id": 6407,
                "name": "Udala(ST)",
                "district": 72
            },
            "name": "Udala",
            "is_urban": false
        },
        {
            "id": 9864,
            "constituency": {
                "id": 6407,
                "name": "Udala(ST)",
                "district": 72
            },
            "name": "Udala (N.A.C)",
            "is_urban": true
        }
    ],
    "6408": [
        {
            "id": 9865,
            "constituency": {
                "id": 6408,
                "name": "Anandapur(SC)",
                "district": 73
            },
            "name": "Anandapur",
            "is_urban": false
        },
        {
            "id": 9866,
            "constituency": {
                "id": 6408,
                "name": "Anandapur(SC)",
                "district": 73
            },
            "name": "Anandapur (Municipality)",
            "is_urban": true
        },
        {
            "id": 9867,
            "constituency": {
                "id": 6408,
                "name": "Anandapur(SC)",
                "district": 73
            },
            "name": "Hatadihi",
            "is_urban": false
        }
    ],
    "6409": [
        {
            "id": 9868,
            "constituency": {
                "id": 6409,
                "name": "Champua",
                "district": 73
            },
            "name": "Barbil Municipality",
            "is_urban": true
        },
        {
            "id": 9869,
            "constituency": {
                "id": 6409,
                "name": "Champua",
                "district": 73
            },
            "name": "Champua",
            "is_urban": false
        },
        {
            "id": 9870,
            "constituency": {
                "id": 6409,
                "name": "Champua",
                "district": 73
            },
            "name": "Champua NAC",
            "is_urban": true
        },
        {
            "id": 9871,
            "constituency": {
                "id": 6409,
                "name": "Champua",
                "district": 73
            },
            "name": "Joda",
            "is_urban": false
        },
        {
            "id": 9872,
            "constituency": {
                "id": 6409,
                "name": "Champua",
                "district": 73
            },
            "name": "Joda (Municipality)",
            "is_urban": true
        }
    ],
    "6410": [
        {
            "id": 9873,
            "constituency": {
                "id": 6410,
                "name": "Ghasipura",
                "district": 73
            },
            "name": "Anandapur",
            "is_urban": false
        },
        {
            "id": 9874,
            "constituency": {
                "id": 6410,
                "name": "Ghasipura",
                "district": 73
            },
            "name": "Ghasipura",
            "is_urban": false
        },
        {
            "id": 9875,
            "constituency": {
                "id": 6410,
                "name": "Ghasipura",
                "district": 73
            },
            "name": "Ghatagaon",
            "is_urban": false
        }
    ],
    "6411": [
        {
            "id": 9876,
            "constituency": {
                "id": 6411,
                "name": "Keonjhar(ST)",
                "district": 73
            },
            "name": "Banspal",
            "is_urban": false
        },
        {
            "id": 9877,
            "constituency": {
                "id": 6411,
                "name": "Keonjhar(ST)",
                "district": 73
            },
            "name": "Jhumpura",
            "is_urban": false
        },
        {
            "id": 9878,
            "constituency": {
                "id": 6411,
                "name": "Keonjhar(ST)",
                "district": 73
            },
            "name": "Keonjhar",
            "is_urban": false
        },
        {
            "id": 9879,
            "constituency": {
                "id": 6411,
                "name": "Keonjhar(ST)",
                "district": 73
            },
            "name": "Keonjhar garh (Municipality)",
            "is_urban": true
        }
    ],
    "6412": [
        {
            "id": 9880,
            "constituency": {
                "id": 6412,
                "name": "Patna(ST)",
                "district": 73
            },
            "name": "Champua",
            "is_urban": false
        },
        {
            "id": 9881,
            "constituency": {
                "id": 6412,
                "name": "Patna(ST)",
                "district": 73
            },
            "name": "Jhumpura",
            "is_urban": false
        },
        {
            "id": 9882,
            "constituency": {
                "id": 6412,
                "name": "Patna(ST)",
                "district": 73
            },
            "name": "Patna",
            "is_urban": false
        },
        {
            "id": 9883,
            "constituency": {
                "id": 6412,
                "name": "Patna(ST)",
                "district": 73
            },
            "name": "Saharpada",
            "is_urban": false
        }
    ],
    "6413": [
        {
            "id": 9884,
            "constituency": {
                "id": 6413,
                "name": "Telkoi(ST)",
                "district": 73
            },
            "name": "Banspal",
            "is_urban": false
        },
        {
            "id": 9885,
            "constituency": {
                "id": 6413,
                "name": "Telkoi(ST)",
                "district": 73
            },
            "name": "Harichandanpur",
            "is_urban": false
        },
        {
            "id": 9886,
            "constituency": {
                "id": 6413,
                "name": "Telkoi(ST)",
                "district": 73
            },
            "name": "Telkoi",
            "is_urban": false
        }
    ],
    "6414": [
        {
            "id": 9887,
            "constituency": {
                "id": 6414,
                "name": "Brajarajnagar",
                "district": 74
            },
            "name": "Belpahar (Municipality)",
            "is_urban": true
        },
        {
            "id": 9888,
            "constituency": {
                "id": 6414,
                "name": "Brajarajnagar",
                "district": 74
            },
            "name": "Brajarajnagar (Municipality)",
            "is_urban": true
        },
        {
            "id": 9889,
            "constituency": {
                "id": 6414,
                "name": "Brajarajnagar",
                "district": 74
            },
            "name": "Jharsuguda",
            "is_urban": false
        },
        {
            "id": 9890,
            "constituency": {
                "id": 6414,
                "name": "Brajarajnagar",
                "district": 74
            },
            "name": "Lakhanpur",
            "is_urban": false
        }
    ],
    "6415": [
        {
            "id": 9891,
            "constituency": {
                "id": 6415,
                "name": "Jharsuguda",
                "district": 74
            },
            "name": "Jharsuguda",
            "is_urban": false
        },
        {
            "id": 9892,
            "constituency": {
                "id": 6415,
                "name": "Jharsuguda",
                "district": 74
            },
            "name": "Jharsuguda (Municipality)",
            "is_urban": true
        },
        {
            "id": 9893,
            "constituency": {
                "id": 6415,
                "name": "Jharsuguda",
                "district": 74
            },
            "name": "Kirmira",
            "is_urban": false
        },
        {
            "id": 9894,
            "constituency": {
                "id": 6415,
                "name": "Jharsuguda",
                "district": 74
            },
            "name": "Kolabira",
            "is_urban": false
        },
        {
            "id": 9895,
            "constituency": {
                "id": 6415,
                "name": "Jharsuguda",
                "district": 74
            },
            "name": "Laikera",
            "is_urban": false
        }
    ],
    "6416": [
        {
            "id": 9896,
            "constituency": {
                "id": 6416,
                "name": "Khariar",
                "district": 75
            },
            "name": "Boden",
            "is_urban": false
        },
        {
            "id": 9897,
            "constituency": {
                "id": 6416,
                "name": "Khariar",
                "district": 75
            },
            "name": "Khariar",
            "is_urban": false
        },
        {
            "id": 9898,
            "constituency": {
                "id": 6416,
                "name": "Khariar",
                "district": 75
            },
            "name": "Khariar (N.A.C)",
            "is_urban": true
        },
        {
            "id": 9899,
            "constituency": {
                "id": 6416,
                "name": "Khariar",
                "district": 75
            },
            "name": "Sinapali",
            "is_urban": false
        }
    ],
    "6417": [
        {
            "id": 9900,
            "constituency": {
                "id": 6417,
                "name": "Nuapada",
                "district": 75
            },
            "name": "Khariar road (N.A.C)",
            "is_urban": true
        },
        {
            "id": 9901,
            "constituency": {
                "id": 6417,
                "name": "Nuapada",
                "district": 75
            },
            "name": "Komna",
            "is_urban": false
        },
        {
            "id": 9902,
            "constituency": {
                "id": 6417,
                "name": "Nuapada",
                "district": 75
            },
            "name": "Nuapada",
            "is_urban": false
        },
        {
            "id": 9903,
            "constituency": {
                "id": 6417,
                "name": "Nuapada",
                "district": 75
            },
            "name": "Nuapada (N.A.C)",
            "is_urban": true
        }
    ],
    "6418": [
        {
            "id": 9904,
            "constituency": {
                "id": 6418,
                "name": "Athagarh",
                "district": 76
            },
            "name": "Athagarh",
            "is_urban": false
        },
        {
            "id": 9905,
            "constituency": {
                "id": 6418,
                "name": "Athagarh",
                "district": 76
            },
            "name": "Athagarh (N.A.C)",
            "is_urban": true
        },
        {
            "id": 9906,
            "constituency": {
                "id": 6418,
                "name": "Athagarh",
                "district": 76
            },
            "name": "Tangi Chowdwar",
            "is_urban": false
        },
        {
            "id": 9907,
            "constituency": {
                "id": 6418,
                "name": "Athagarh",
                "district": 76
            },
            "name": "Tigiria",
            "is_urban": false
        }
    ],
    "6419": [
        {
            "id": 9908,
            "constituency": {
                "id": 6419,
                "name": "Badamba",
                "district": 76
            },
            "name": "Badamba",
            "is_urban": false
        },
        {
            "id": 9909,
            "constituency": {
                "id": 6419,
                "name": "Badamba",
                "district": 76
            },
            "name": "Narsingpur",
            "is_urban": false
        }
    ],
    "6420": [
        {
            "id": 9910,
            "constituency": {
                "id": 6420,
                "name": "Banki",
                "district": 76
            },
            "name": "Banki",
            "is_urban": false
        },
        {
            "id": 9911,
            "constituency": {
                "id": 6420,
                "name": "Banki",
                "district": 76
            },
            "name": "Banki (N.A.C)",
            "is_urban": true
        },
        {
            "id": 9912,
            "constituency": {
                "id": 6420,
                "name": "Banki",
                "district": 76
            },
            "name": "Banki Dampada",
            "is_urban": false
        },
        {
            "id": 9913,
            "constituency": {
                "id": 6420,
                "name": "Banki",
                "district": 76
            },
            "name": "Baranga",
            "is_urban": false
        }
    ],
    "6421": [
        {
            "id": 9914,
            "constituency": {
                "id": 6421,
                "name": "Barabati-cuttack",
                "district": 76
            },
            "name": "Cuttack (Municipality)",
            "is_urban": true
        }
    ],
    "6422": [
        {
            "id": 9915,
            "constituency": {
                "id": 6422,
                "name": "Choudwar-cuttack",
                "district": 76
            },
            "name": "Charbatia",
            "is_urban": false
        },
        {
            "id": 9916,
            "constituency": {
                "id": 6422,
                "name": "Choudwar-cuttack",
                "district": 76
            },
            "name": "Choudwar",
            "is_urban": false
        },
        {
            "id": 9917,
            "constituency": {
                "id": 6422,
                "name": "Choudwar-cuttack",
                "district": 76
            },
            "name": "Choudwar (Municipality)",
            "is_urban": true
        },
        {
            "id": 9918,
            "constituency": {
                "id": 6422,
                "name": "Choudwar-cuttack",
                "district": 76
            },
            "name": "Cuttack (Municipality)",
            "is_urban": true
        },
        {
            "id": 9919,
            "constituency": {
                "id": 6422,
                "name": "Choudwar-cuttack",
                "district": 76
            },
            "name": "Tangi-choudwar",
            "is_urban": false
        }
    ],
    "6423": [
        {
            "id": 9920,
            "constituency": {
                "id": 6423,
                "name": "Cuttack sadar(SC)",
                "district": 76
            },
            "name": "Cuttack (Municipality)",
            "is_urban": true
        },
        {
            "id": 9921,
            "constituency": {
                "id": 6423,
                "name": "Cuttack sadar(SC)",
                "district": 76
            },
            "name": "Cuttack Sadar",
            "is_urban": false
        },
        {
            "id": 9922,
            "constituency": {
                "id": 6423,
                "name": "Cuttack sadar(SC)",
                "district": 76
            },
            "name": "Nischintakoili",
            "is_urban": false
        }
    ],
    "6424": [
        {
            "id": 9923,
            "constituency": {
                "id": 6424,
                "name": "Mahanga",
                "district": 76
            },
            "name": "Mahanga",
            "is_urban": false
        },
        {
            "id": 9924,
            "constituency": {
                "id": 6424,
                "name": "Mahanga",
                "district": 76
            },
            "name": "Nischintakoili",
            "is_urban": false
        }
    ],
    "6425": [
        {
            "id": 9925,
            "constituency": {
                "id": 6425,
                "name": "Niali",
                "district": 76
            },
            "name": "Baranga",
            "is_urban": false
        },
        {
            "id": 9926,
            "constituency": {
                "id": 6425,
                "name": "Niali",
                "district": 76
            },
            "name": "Kantapada",
            "is_urban": false
        },
        {
            "id": 9927,
            "constituency": {
                "id": 6425,
                "name": "Niali",
                "district": 76
            },
            "name": "Niali",
            "is_urban": false
        }
    ],
    "6426": [
        {
            "id": 9928,
            "constituency": {
                "id": 6426,
                "name": "Salipur",
                "district": 76
            },
            "name": "Salipur",
            "is_urban": false
        },
        {
            "id": 9929,
            "constituency": {
                "id": 6426,
                "name": "Salipur",
                "district": 76
            },
            "name": "Tangi Chowdwar",
            "is_urban": false
        }
    ],
    "6427": [
        {
            "id": 9930,
            "constituency": {
                "id": 6427,
                "name": "Dabugam(S.T)",
                "district": 77
            },
            "name": "Dabugam",
            "is_urban": false
        },
        {
            "id": 9931,
            "constituency": {
                "id": 6427,
                "name": "Dabugam(S.T)",
                "district": 77
            },
            "name": "Kosagumuda",
            "is_urban": false
        },
        {
            "id": 9932,
            "constituency": {
                "id": 6427,
                "name": "Dabugam(S.T)",
                "district": 77
            },
            "name": "Papadahandi",
            "is_urban": false
        }
    ],
    "6428": [
        {
            "id": 9933,
            "constituency": {
                "id": 6428,
                "name": "Jharigam",
                "district": 77
            },
            "name": "Chandahandi",
            "is_urban": false
        },
        {
            "id": 9934,
            "constituency": {
                "id": 6428,
                "name": "Jharigam",
                "district": 77
            },
            "name": "Jharigam",
            "is_urban": false
        },
        {
            "id": 9935,
            "constituency": {
                "id": 6428,
                "name": "Jharigam",
                "district": 77
            },
            "name": "Umerkote",
            "is_urban": false
        }
    ],
    "6429": [
        {
            "id": 9936,
            "constituency": {
                "id": 6429,
                "name": "Nabarangpur",
                "district": 77
            },
            "name": "Kosagumuda",
            "is_urban": false
        },
        {
            "id": 9937,
            "constituency": {
                "id": 6429,
                "name": "Nabarangpur",
                "district": 77
            },
            "name": "Nabarangpur",
            "is_urban": false
        },
        {
            "id": 9938,
            "constituency": {
                "id": 6429,
                "name": "Nabarangpur",
                "district": 77
            },
            "name": "Nabarangpur (Municipality)",
            "is_urban": true
        },
        {
            "id": 9939,
            "constituency": {
                "id": 6429,
                "name": "Nabarangpur",
                "district": 77
            },
            "name": "Nandahandi",
            "is_urban": false
        },
        {
            "id": 9940,
            "constituency": {
                "id": 6429,
                "name": "Nabarangpur",
                "district": 77
            },
            "name": "Tentulikhunti",
            "is_urban": false
        }
    ],
    "6430": [
        {
            "id": 9941,
            "constituency": {
                "id": 6430,
                "name": "Umerkote(S.T)",
                "district": 77
            },
            "name": "Raighar",
            "is_urban": false
        },
        {
            "id": 9942,
            "constituency": {
                "id": 6430,
                "name": "Umerkote(S.T)",
                "district": 77
            },
            "name": "Umerkote",
            "is_urban": false
        },
        {
            "id": 9943,
            "constituency": {
                "id": 6430,
                "name": "Umerkote(S.T)",
                "district": 77
            },
            "name": "Umerkote (Municipality)",
            "is_urban": true
        },
        {
            "id": 9944,
            "constituency": {
                "id": 6430,
                "name": "Umerkote(S.T)",
                "district": 77
            },
            "name": "Umerkote (N.A.C)",
            "is_urban": true
        }
    ],
    "6431": [
        {
            "id": 9945,
            "constituency": {
                "id": 6431,
                "name": "Chitrakonda",
                "district": 78
            },
            "name": "Balimela (N.A.C)",
            "is_urban": true
        },
        {
            "id": 9946,
            "constituency": {
                "id": 6431,
                "name": "Chitrakonda",
                "district": 78
            },
            "name": "Khairput",
            "is_urban": false
        },
        {
            "id": 9947,
            "constituency": {
                "id": 6431,
                "name": "Chitrakonda",
                "district": 78
            },
            "name": "Korukonda (P)",
            "is_urban": false
        },
        {
            "id": 9948,
            "constituency": {
                "id": 6431,
                "name": "Chitrakonda",
                "district": 78
            },
            "name": "Kudumulgumma",
            "is_urban": false
        },
        {
            "id": 9949,
            "constituency": {
                "id": 6431,
                "name": "Chitrakonda",
                "district": 78
            },
            "name": "Mathili",
            "is_urban": false
        }
    ],
    "6432": [
        {
            "id": 9950,
            "constituency": {
                "id": 6432,
                "name": "Malkanagiri",
                "district": 78
            },
            "name": "Kalimela",
            "is_urban": false
        },
        {
            "id": 9951,
            "constituency": {
                "id": 6432,
                "name": "Malkanagiri",
                "district": 78
            },
            "name": "Korukonda",
            "is_urban": false
        },
        {
            "id": 9952,
            "constituency": {
                "id": 6432,
                "name": "Malkanagiri",
                "district": 78
            },
            "name": "Korukonda (P)",
            "is_urban": false
        },
        {
            "id": 9953,
            "constituency": {
                "id": 6432,
                "name": "Malkanagiri",
                "district": 78
            },
            "name": "Malkanagiri",
            "is_urban": false
        },
        {
            "id": 9954,
            "constituency": {
                "id": 6432,
                "name": "Malkanagiri",
                "district": 78
            },
            "name": "Malkanagiri (N.A.C)",
            "is_urban": true
        },
        {
            "id": 9955,
            "constituency": {
                "id": 6432,
                "name": "Malkanagiri",
                "district": 78
            },
            "name": "Malkangiri (N.A.C)",
            "is_urban": true
        },
        {
            "id": 9956,
            "constituency": {
                "id": 6432,
                "name": "Malkanagiri",
                "district": 78
            },
            "name": "Podia",
            "is_urban": false
        }
    ],
    "6433": [
        {
            "id": 9957,
            "constituency": {
                "id": 6433,
                "name": "Biramitrapur(ST)",
                "district": 79
            },
            "name": "Birmitrapur (Municipality)",
            "is_urban": true
        },
        {
            "id": 9958,
            "constituency": {
                "id": 6433,
                "name": "Biramitrapur(ST)",
                "district": 79
            },
            "name": "Bisra",
            "is_urban": false
        },
        {
            "id": 9959,
            "constituency": {
                "id": 6433,
                "name": "Biramitrapur(ST)",
                "district": 79
            },
            "name": "Kuarmunda",
            "is_urban": false
        },
        {
            "id": 9960,
            "constituency": {
                "id": 6433,
                "name": "Biramitrapur(ST)",
                "district": 79
            },
            "name": "Nuagaon",
            "is_urban": false
        }
    ],
    "6434": [
        {
            "id": 9961,
            "constituency": {
                "id": 6434,
                "name": "Bonai(ST)",
                "district": 79
            },
            "name": "Bonaigarh",
            "is_urban": false
        },
        {
            "id": 9962,
            "constituency": {
                "id": 6434,
                "name": "Bonai(ST)",
                "district": 79
            },
            "name": "Gurundia",
            "is_urban": false
        },
        {
            "id": 9963,
            "constituency": {
                "id": 6434,
                "name": "Bonai(ST)",
                "district": 79
            },
            "name": "Koira",
            "is_urban": false
        },
        {
            "id": 9964,
            "constituency": {
                "id": 6434,
                "name": "Bonai(ST)",
                "district": 79
            },
            "name": "Lahunipara",
            "is_urban": false
        }
    ],
    "6435": [
        {
            "id": 9965,
            "constituency": {
                "id": 6435,
                "name": "Raghunathpali(SC)",
                "district": 79
            },
            "name": "Lathikata",
            "is_urban": false
        },
        {
            "id": 9966,
            "constituency": {
                "id": 6435,
                "name": "Raghunathpali(SC)",
                "district": 79
            },
            "name": "Raghunathpali - Steel Township (NAC)",
            "is_urban": true
        }
    ],
    "6436": [
        {
            "id": 9967,
            "constituency": {
                "id": 6436,
                "name": "Rajgangpur",
                "district": 79
            },
            "name": "Kutra",
            "is_urban": false
        },
        {
            "id": 9968,
            "constituency": {
                "id": 6436,
                "name": "Rajgangpur",
                "district": 79
            },
            "name": "Lathikata",
            "is_urban": false
        },
        {
            "id": 9969,
            "constituency": {
                "id": 6436,
                "name": "Rajgangpur",
                "district": 79
            },
            "name": "Rajgangpur",
            "is_urban": false
        },
        {
            "id": 9970,
            "constituency": {
                "id": 6436,
                "name": "Rajgangpur",
                "district": 79
            },
            "name": "Rajgangpur Municipalty",
            "is_urban": true
        }
    ],
    "6437": [
        {
            "id": 9971,
            "constituency": {
                "id": 6437,
                "name": "Rourkela",
                "district": 79
            },
            "name": "Bisra",
            "is_urban": false
        },
        {
            "id": 9972,
            "constituency": {
                "id": 6437,
                "name": "Rourkela",
                "district": 79
            },
            "name": "Rourkela (Municipality)",
            "is_urban": true
        }
    ],
    "6438": [
        {
            "id": 9973,
            "constituency": {
                "id": 6438,
                "name": "Sundargarh",
                "district": 79
            },
            "name": "Hemagiri",
            "is_urban": false
        },
        {
            "id": 9974,
            "constituency": {
                "id": 6438,
                "name": "Sundargarh",
                "district": 79
            },
            "name": "Lephripara",
            "is_urban": false
        },
        {
            "id": 9975,
            "constituency": {
                "id": 6438,
                "name": "Sundargarh",
                "district": 79
            },
            "name": "Sundargarh (Municipality)",
            "is_urban": true
        },
        {
            "id": 9976,
            "constituency": {
                "id": 6438,
                "name": "Sundargarh",
                "district": 79
            },
            "name": "Sundargarh Sadar",
            "is_urban": false
        },
        {
            "id": 9977,
            "constituency": {
                "id": 6438,
                "name": "Sundargarh",
                "district": 79
            },
            "name": "Tangarpali",
            "is_urban": false
        }
    ],
    "6439": [
        {
            "id": 9978,
            "constituency": {
                "id": 6439,
                "name": "Talsara(ST)",
                "district": 79
            },
            "name": "Balisankar",
            "is_urban": false
        },
        {
            "id": 9979,
            "constituency": {
                "id": 6439,
                "name": "Talsara(ST)",
                "district": 79
            },
            "name": "Bargaon",
            "is_urban": false
        },
        {
            "id": 9980,
            "constituency": {
                "id": 6439,
                "name": "Talsara(ST)",
                "district": 79
            },
            "name": "Lephripara",
            "is_urban": false
        },
        {
            "id": 9981,
            "constituency": {
                "id": 6439,
                "name": "Talsara(ST)",
                "district": 79
            },
            "name": "Subdega",
            "is_urban": false
        }
    ],
    "6440": [
        {
            "id": 9982,
            "constituency": {
                "id": 6440,
                "name": "Attabira",
                "district": 80
            },
            "name": "Attabira",
            "is_urban": false
        },
        {
            "id": 9983,
            "constituency": {
                "id": 6440,
                "name": "Attabira",
                "district": 80
            },
            "name": "Attabira (N.A.C)",
            "is_urban": true
        },
        {
            "id": 9984,
            "constituency": {
                "id": 6440,
                "name": "Attabira",
                "district": 80
            },
            "name": "Bheden",
            "is_urban": false
        }
    ],
    "6441": [
        {
            "id": 9985,
            "constituency": {
                "id": 6441,
                "name": "Bargarh",
                "district": 80
            },
            "name": "Bargarh",
            "is_urban": false
        },
        {
            "id": 9986,
            "constituency": {
                "id": 6441,
                "name": "Bargarh",
                "district": 80
            },
            "name": "Bargarh (Municipality)",
            "is_urban": true
        },
        {
            "id": 9987,
            "constituency": {
                "id": 6441,
                "name": "Bargarh",
                "district": 80
            },
            "name": "Barpali",
            "is_urban": false
        }
    ],
    "6442": [
        {
            "id": 9988,
            "constituency": {
                "id": 6442,
                "name": "Bhatli",
                "district": 80
            },
            "name": "Ambabhona",
            "is_urban": false
        },
        {
            "id": 9989,
            "constituency": {
                "id": 6442,
                "name": "Bhatli",
                "district": 80
            },
            "name": "Bhatli",
            "is_urban": false
        },
        {
            "id": 9990,
            "constituency": {
                "id": 6442,
                "name": "Bhatli",
                "district": 80
            },
            "name": "Sohela",
            "is_urban": false
        }
    ],
    "6443": [
        {
            "id": 9991,
            "constituency": {
                "id": 6443,
                "name": "Bijepur",
                "district": 80
            },
            "name": "Barpali",
            "is_urban": false
        },
        {
            "id": 9992,
            "constituency": {
                "id": 6443,
                "name": "Bijepur",
                "district": 80
            },
            "name": "Barpali NAC",
            "is_urban": true
        },
        {
            "id": 9993,
            "constituency": {
                "id": 6443,
                "name": "Bijepur",
                "district": 80
            },
            "name": "Bijepur",
            "is_urban": false
        },
        {
            "id": 9994,
            "constituency": {
                "id": 6443,
                "name": "Bijepur",
                "district": 80
            },
            "name": "Bijepur (N.A.C)",
            "is_urban": true
        },
        {
            "id": 9995,
            "constituency": {
                "id": 6443,
                "name": "Bijepur",
                "district": 80
            },
            "name": "Gaisilet",
            "is_urban": false
        }
    ],
    "6444": [
        {
            "id": 9996,
            "constituency": {
                "id": 6444,
                "name": "Padampur",
                "district": 80
            },
            "name": "Jharbandh",
            "is_urban": false
        },
        {
            "id": 9997,
            "constituency": {
                "id": 6444,
                "name": "Padampur",
                "district": 80
            },
            "name": "Padampur NAC",
            "is_urban": true
        },
        {
            "id": 9998,
            "constituency": {
                "id": 6444,
                "name": "Padampur",
                "district": 80
            },
            "name": "Paikmal",
            "is_urban": false
        },
        {
            "id": 9999,
            "constituency": {
                "id": 6444,
                "name": "Padampur",
                "district": 80
            },
            "name": "Rajborasambar",
            "is_urban": false
        }
    ]
};

const block_to_gp_map = {
    "9499": [
        {
            "id": 7136,
            "name": "angarbandha"
        },
        {
            "id": 7137,
            "name": "Badakantakul"
        },
        {
            "id": 7138,
            "name": "Badakera"
        },
        {
            "id": 7139,
            "name": "Balanga"
        },
        {
            "id": 7140,
            "name": "Balasingha"
        },
        {
            "id": 7141,
            "name": "Baluakata"
        },
        {
            "id": 7142,
            "name": "Bantala"
        },
        {
            "id": 7143,
            "name": "Bargaunia"
        },
        {
            "id": 7144,
            "name": "Basala"
        },
        {
            "id": 7145,
            "name": "Bedasasan"
        },
        {
            "id": 7146,
            "name": "Chheliapada"
        },
        {
            "id": 7147,
            "name": "Dhokuta"
        },
        {
            "id": 7148,
            "name": "Gadatarash"
        },
        {
            "id": 7149,
            "name": "Inkarabandha"
        },
        {
            "id": 7150,
            "name": "Kangula Bentapur"
        },
        {
            "id": 7151,
            "name": "khalari"
        },
        {
            "id": 7152,
            "name": "Khinda"
        },
        {
            "id": 7153,
            "name": "Kumurisingha"
        },
        {
            "id": 7154,
            "name": "Nandapur"
        },
        {
            "id": 7155,
            "name": "Nuamouza"
        },
        {
            "id": 7156,
            "name": "Pokatunga"
        },
        {
            "id": 7157,
            "name": "Rantalei"
        },
        {
            "id": 7158,
            "name": "Sankhapur"
        },
        {
            "id": 7159,
            "name": "Susuda"
        },
        {
            "id": 7160,
            "name": "Talagarh"
        }
    ],
    "9500": [
        {
            "id": 7161,
            "name": "NAC"
        }
    ],
    "9501": [
        {
            "id": 7162,
            "name": "Badahinsar"
        },
        {
            "id": 7163,
            "name": "Balaramprasad"
        },
        {
            "id": 7164,
            "name": "Banarpal"
        },
        {
            "id": 7165,
            "name": "Bauligada"
        },
        {
            "id": 7166,
            "name": "Benagadia"
        },
        {
            "id": 7167,
            "name": "Bhogabareni"
        },
        {
            "id": 7168,
            "name": "Bonda"
        },
        {
            "id": 7169,
            "name": "Budhapanka"
        },
        {
            "id": 7170,
            "name": "Fulapada"
        },
        {
            "id": 7171,
            "name": "Garhasantri"
        },
        {
            "id": 7172,
            "name": "Giranga"
        },
        {
            "id": 7173,
            "name": "Gotamara"
        },
        {
            "id": 7174,
            "name": "Jamunali"
        },
        {
            "id": 7175,
            "name": "jarada"
        },
        {
            "id": 7176,
            "name": "Kulad"
        },
        {
            "id": 7177,
            "name": "Mahidharpur"
        },
        {
            "id": 7178,
            "name": "Nunahata"
        },
        {
            "id": 7179,
            "name": "Ranigoda Jungle"
        },
        {
            "id": 7180,
            "name": "Santarapur (Kha)"
        },
        {
            "id": 7181,
            "name": "Talamulasasan"
        },
        {
            "id": 7182,
            "name": "Talmul"
        },
        {
            "id": 7183,
            "name": "Tulasipal"
        },
        {
            "id": 7184,
            "name": "Turanga"
        }
    ],
    "9502": [
        {
            "id": 7185,
            "name": "Antulia"
        },
        {
            "id": 7186,
            "name": "Jagannathpur"
        },
        {
            "id": 7187,
            "name": "Kothabhuin"
        },
        {
            "id": 7188,
            "name": "Manikjodi"
        },
        {
            "id": 7189,
            "name": "Matiasahi"
        },
        {
            "id": 7190,
            "name": "Purunakote"
        },
        {
            "id": 7191,
            "name": "Saradhapur"
        },
        {
            "id": 7192,
            "name": "Tainsi"
        },
        {
            "id": 7193,
            "name": "Tikarpada"
        }
    ],
    "9503": [
        {
            "id": 7194,
            "name": "Aida"
        },
        {
            "id": 7195,
            "name": "Ambasarmunda"
        },
        {
            "id": 7196,
            "name": "Basudevpur"
        },
        {
            "id": 7197,
            "name": "Jamudoli"
        },
        {
            "id": 7198,
            "name": "Kampala"
        },
        {
            "id": 7199,
            "name": "kandhapada"
        },
        {
            "id": 7200,
            "name": "Kantapada"
        },
        {
            "id": 7201,
            "name": "Kiakata"
        },
        {
            "id": 7202,
            "name": "Krutibaspur"
        },
        {
            "id": 7203,
            "name": "Kudagaon"
        },
        {
            "id": 7204,
            "name": "Kurumtap"
        },
        {
            "id": 7205,
            "name": "Luhasingha"
        },
        {
            "id": 7206,
            "name": "Lunahandi"
        },
        {
            "id": 7207,
            "name": "Madhapur"
        },
        {
            "id": 7208,
            "name": "Maimura"
        },
        {
            "id": 7209,
            "name": "Nagaon"
        },
        {
            "id": 7210,
            "name": "Paikasahi"
        },
        {
            "id": 7211,
            "name": "Pedipathar"
        },
        {
            "id": 7212,
            "name": "Purunamanitri"
        },
        {
            "id": 7213,
            "name": "Sanahula"
        },
        {
            "id": 7214,
            "name": "Sapaghara"
        },
        {
            "id": 7215,
            "name": "Tapadhol"
        },
        {
            "id": 7216,
            "name": "Thakurgarh"
        },
        {
            "id": 7217,
            "name": "Tusar"
        }
    ],
    "9504": [
        {
            "id": 7218,
            "name": "NAC"
        }
    ],
    "9505": [
        {
            "id": 7219,
            "name": "NAC"
        }
    ],
    "9506": [
        {
            "id": 7220,
            "name": "Ambapal"
        },
        {
            "id": 7221,
            "name": "angapada"
        },
        {
            "id": 7222,
            "name": "Bamur"
        },
        {
            "id": 7223,
            "name": "Bileinali"
        },
        {
            "id": 7224,
            "name": "Dhadarpal"
        },
        {
            "id": 7225,
            "name": "Dhaurapali"
        },
        {
            "id": 7226,
            "name": "Ganboinda"
        },
        {
            "id": 7227,
            "name": "Handapa"
        },
        {
            "id": 7228,
            "name": "Himitira"
        },
        {
            "id": 7229,
            "name": "Jairat"
        },
        {
            "id": 7230,
            "name": "Jamunali"
        },
        {
            "id": 7231,
            "name": "Jharaberani"
        },
        {
            "id": 7232,
            "name": "Kadalimunda"
        },
        {
            "id": 7233,
            "name": "Kanteikulia"
        },
        {
            "id": 7234,
            "name": "Katranga"
        },
        {
            "id": 7235,
            "name": "Luhamunda"
        },
        {
            "id": 7236,
            "name": "Nakchi"
        },
        {
            "id": 7237,
            "name": "Nunukapasi"
        },
        {
            "id": 7238,
            "name": "Oskapali"
        },
        {
            "id": 7239,
            "name": "Raniakata"
        },
        {
            "id": 7240,
            "name": "sanjamura"
        },
        {
            "id": 7241,
            "name": "Thelkonali"
        },
        {
            "id": 7242,
            "name": "Urukula"
        }
    ],
    "9507": [
        {
            "id": 7243,
            "name": "Badahinsara"
        },
        {
            "id": 7244,
            "name": "Badakerjanga"
        },
        {
            "id": 7245,
            "name": "Benagadia"
        },
        {
            "id": 7246,
            "name": "Jamunali"
        },
        {
            "id": 7247,
            "name": "Jarada"
        },
        {
            "id": 7248,
            "name": "jarasingha"
        },
        {
            "id": 7249,
            "name": "kandasar"
        },
        {
            "id": 7250,
            "name": "kanjara"
        },
        {
            "id": 7251,
            "name": "Karadagadia"
        },
        {
            "id": 7252,
            "name": "Kukudanga"
        },
        {
            "id": 7253,
            "name": "Kumanda"
        },
        {
            "id": 7254,
            "name": "Kurudol"
        },
        {
            "id": 7255,
            "name": "Ranigoda"
        },
        {
            "id": 7256,
            "name": "Sakosingha"
        },
        {
            "id": 7257,
            "name": "Sankarjang"
        },
        {
            "id": 7258,
            "name": "Sankarjanga"
        },
        {
            "id": 7259,
            "name": "Santrapur (Ka)"
        },
        {
            "id": 7260,
            "name": "Tubey"
        },
        {
            "id": 7261,
            "name": "Turanga"
        }
    ],
    "9508": [
        {
            "id": 7262,
            "name": "Bagedia"
        },
        {
            "id": 7263,
            "name": "Bahalsahi"
        },
        {
            "id": 7264,
            "name": "Balipata"
        },
        {
            "id": 7265,
            "name": "Barapada"
        },
        {
            "id": 7266,
            "name": "Barasahi"
        },
        {
            "id": 7267,
            "name": "Basantapur"
        },
        {
            "id": 7268,
            "name": "Brahmanbil"
        },
        {
            "id": 7269,
            "name": "Changudia"
        },
        {
            "id": 7270,
            "name": "Chhendipada"
        },
        {
            "id": 7271,
            "name": "Durgapur"
        },
        {
            "id": 7272,
            "name": "Jarapada"
        },
        {
            "id": 7273,
            "name": "Jeranga Dehuri Sahi"
        },
        {
            "id": 7274,
            "name": "Kampasala"
        },
        {
            "id": 7275,
            "name": "Kanaloi"
        },
        {
            "id": 7276,
            "name": "Kankarei"
        },
        {
            "id": 7277,
            "name": "Katada"
        },
        {
            "id": 7278,
            "name": "Korada"
        },
        {
            "id": 7279,
            "name": "Kosala"
        },
        {
            "id": 7280,
            "name": "Kukurpeta"
        },
        {
            "id": 7281,
            "name": "Kusakila"
        },
        {
            "id": 7282,
            "name": "Machhakuta"
        },
        {
            "id": 7283,
            "name": "Natada"
        },
        {
            "id": 7284,
            "name": "Nisa"
        },
        {
            "id": 7285,
            "name": "Nuagaon"
        },
        {
            "id": 7286,
            "name": "Ogi"
        },
        {
            "id": 7287,
            "name": "Para"
        },
        {
            "id": 7288,
            "name": "Paranga"
        },
        {
            "id": 7289,
            "name": "Patakamunda"
        },
        {
            "id": 7290,
            "name": "Patrapada"
        },
        {
            "id": 7291,
            "name": "Raijharan"
        },
        {
            "id": 7292,
            "name": "similipal"
        },
        {
            "id": 7293,
            "name": "Tangiri"
        },
        {
            "id": 7294,
            "name": "Tentuloi Kodasahi"
        },
        {
            "id": 7295,
            "name": "Tukuda"
        }
    ],
    "9509": [
        {
            "id": 7296,
            "name": "Arkil"
        },
        {
            "id": 7297,
            "name": "Bajrakote"
        },
        {
            "id": 7298,
            "name": "Balipasi"
        },
        {
            "id": 7299,
            "name": "Biru"
        },
        {
            "id": 7300,
            "name": "Burukuna"
        },
        {
            "id": 7301,
            "name": "dalaka"
        },
        {
            "id": 7302,
            "name": "Deranga"
        },
        {
            "id": 7303,
            "name": "Gadasila"
        },
        {
            "id": 7304,
            "name": "Gandamala"
        },
        {
            "id": 7305,
            "name": "Hanumanpur"
        },
        {
            "id": 7306,
            "name": "Hariharpur"
        },
        {
            "id": 7307,
            "name": "Kakudia"
        },
        {
            "id": 7308,
            "name": "karnapal"
        },
        {
            "id": 7309,
            "name": "Kulei"
        },
        {
            "id": 7310,
            "name": "Kuluma"
        },
        {
            "id": 7311,
            "name": "Parabil"
        },
        {
            "id": 7312,
            "name": "Rengali"
        },
        {
            "id": 7313,
            "name": "Samal"
        },
        {
            "id": 7314,
            "name": "Susuba"
        },
        {
            "id": 7315,
            "name": "Talapada"
        }
    ],
    "9510": [
        {
            "id": 7316,
            "name": "Allori"
        },
        {
            "id": 7317,
            "name": "Badasada"
        },
        {
            "id": 7318,
            "name": "Balipasi"
        },
        {
            "id": 7319,
            "name": "Bandhabhuin"
        },
        {
            "id": 7320,
            "name": "Baradiha"
        },
        {
            "id": 7321,
            "name": "Batisuan"
        },
        {
            "id": 7322,
            "name": "Chasagurujanga"
        },
        {
            "id": 7323,
            "name": "Dimiria"
        },
        {
            "id": 7324,
            "name": "Injidi"
        },
        {
            "id": 7325,
            "name": "Iswarnagar"
        },
        {
            "id": 7326,
            "name": "Jharbeda"
        },
        {
            "id": 7327,
            "name": "Karadapal"
        },
        {
            "id": 7328,
            "name": "Khamar"
        },
        {
            "id": 7329,
            "name": "Khemala"
        },
        {
            "id": 7330,
            "name": "Kunjam"
        },
        {
            "id": 7331,
            "name": "Munduribeda"
        },
        {
            "id": 7332,
            "name": "nagira"
        },
        {
            "id": 7333,
            "name": "Nampasi"
        },
        {
            "id": 7334,
            "name": "Nijigarh"
        },
        {
            "id": 7335,
            "name": "Pabitrapur"
        },
        {
            "id": 7336,
            "name": "Parachhat"
        },
        {
            "id": 7337,
            "name": "Pechamundi"
        },
        {
            "id": 7338,
            "name": "Rajadanga"
        },
        {
            "id": 7339,
            "name": "Rohila"
        },
        {
            "id": 7340,
            "name": "Saida"
        },
        {
            "id": 7341,
            "name": "Sankhamur"
        },
        {
            "id": 7342,
            "name": "Seegarh"
        }
    ],
    "9511": [
        {
            "id": 7343,
            "name": "Badagunduri"
        },
        {
            "id": 7344,
            "name": "Badatribida"
        },
        {
            "id": 7345,
            "name": "Bijigol"
        },
        {
            "id": 7346,
            "name": "Jarada"
        },
        {
            "id": 7347,
            "name": "Kamarei"
        },
        {
            "id": 7348,
            "name": "kaniha"
        },
        {
            "id": 7349,
            "name": "Sanatribida"
        }
    ],
    "9512": [
        {
            "id": 7350,
            "name": "Badajorada"
        },
        {
            "id": 7351,
            "name": "Bantol"
        },
        {
            "id": 7352,
            "name": "Brajanathpur"
        },
        {
            "id": 7353,
            "name": "Danara"
        },
        {
            "id": 7354,
            "name": "Dera"
        },
        {
            "id": 7355,
            "name": "dharampur"
        },
        {
            "id": 7356,
            "name": "Ghantapada"
        },
        {
            "id": 7357,
            "name": "Gobara"
        },
        {
            "id": 7358,
            "name": "Gopalprasad"
        },
        {
            "id": 7359,
            "name": "Gurujanga"
        },
        {
            "id": 7360,
            "name": "Gurujanguli"
        },
        {
            "id": 7361,
            "name": "Hensamul"
        },
        {
            "id": 7362,
            "name": "Jagannathpur"
        },
        {
            "id": 7363,
            "name": "Kalamachhuin"
        },
        {
            "id": 7364,
            "name": "Kandhal"
        },
        {
            "id": 7365,
            "name": "Kankili"
        },
        {
            "id": 7366,
            "name": "karnapur"
        },
        {
            "id": 7367,
            "name": "Kumunda"
        },
        {
            "id": 7368,
            "name": "Padmabatipur"
        },
        {
            "id": 7369,
            "name": "Santhapada"
        },
        {
            "id": 7370,
            "name": "Tentulei"
        }
    ],
    "9513": [
        {
            "id": 7371,
            "name": "NAC"
        }
    ],
    "9514": [
        {
            "id": 7372,
            "name": "Baidipali"
        },
        {
            "id": 7373,
            "name": "Barapudugia"
        },
        {
            "id": 7374,
            "name": "Barkani"
        },
        {
            "id": 7375,
            "name": "Bhundimuhan"
        },
        {
            "id": 7376,
            "name": "Bhutiarbahal"
        },
        {
            "id": 7377,
            "name": "Bidighat"
        },
        {
            "id": 7378,
            "name": "Chandanbhati"
        },
        {
            "id": 7379,
            "name": "Chikalbahal"
        },
        {
            "id": 7380,
            "name": "Chudapali"
        },
        {
            "id": 7381,
            "name": "Jhankarpali"
        },
        {
            "id": 7382,
            "name": "Khaliapali"
        },
        {
            "id": 7383,
            "name": "Khujenpali"
        },
        {
            "id": 7384,
            "name": "Khuntpali"
        },
        {
            "id": 7385,
            "name": "Kudasingha"
        },
        {
            "id": 7386,
            "name": "Kutumdola"
        },
        {
            "id": 7387,
            "name": "Madhiapali"
        },
        {
            "id": 7388,
            "name": "Manhira"
        },
        {
            "id": 7389,
            "name": "Mayabarha"
        },
        {
            "id": 7390,
            "name": "Mirdhapali"
        },
        {
            "id": 7391,
            "name": "Sadeipali"
        },
        {
            "id": 7392,
            "name": "Sahajbahal"
        },
        {
            "id": 7393,
            "name": "Sakma"
        },
        {
            "id": 7394,
            "name": "Sibtala"
        },
        {
            "id": 7395,
            "name": "Sikachhida"
        }
    ],
    "9515": [
        {
            "id": 7396,
            "name": "NAC"
        }
    ],
    "9516": [
        {
            "id": 7397,
            "name": "Arjunpur"
        },
        {
            "id": 7398,
            "name": "Badbahal"
        },
        {
            "id": 7399,
            "name": "Badbandh"
        },
        {
            "id": 7400,
            "name": "Bandhpada"
        },
        {
            "id": 7401,
            "name": "Bramhanijore"
        },
        {
            "id": 7402,
            "name": "Deogaon"
        },
        {
            "id": 7403,
            "name": "Desandh"
        },
        {
            "id": 7404,
            "name": "Dhandamal"
        },
        {
            "id": 7405,
            "name": "Dumerpita"
        },
        {
            "id": 7406,
            "name": "Gaibahal"
        },
        {
            "id": 7407,
            "name": "Gaurgoth"
        },
        {
            "id": 7408,
            "name": "Gudkhapala"
        },
        {
            "id": 7409,
            "name": "Hatisara"
        },
        {
            "id": 7410,
            "name": "Jarasingha"
        },
        {
            "id": 7411,
            "name": "Kultapada"
        },
        {
            "id": 7412,
            "name": "Kuturla"
        },
        {
            "id": 7413,
            "name": "Landapathar"
        },
        {
            "id": 7414,
            "name": "Mahalei"
        },
        {
            "id": 7415,
            "name": "Makundapur"
        },
        {
            "id": 7416,
            "name": "Ramchandrapur"
        },
        {
            "id": 7417,
            "name": "Salepali"
        },
        {
            "id": 7418,
            "name": "Sarasmal"
        },
        {
            "id": 7419,
            "name": "Sikuan"
        },
        {
            "id": 7420,
            "name": "Singhamunda"
        },
        {
            "id": 7421,
            "name": "Udar"
        },
        {
            "id": 7422,
            "name": "Uparjhar"
        }
    ],
    "9517": [
        {
            "id": 7423,
            "name": "Alanda"
        },
        {
            "id": 7424,
            "name": "Arsatula"
        },
        {
            "id": 7425,
            "name": "Baldha"
        },
        {
            "id": 7426,
            "name": "Bangomunda"
        },
        {
            "id": 7427,
            "name": "Belpara"
        },
        {
            "id": 7428,
            "name": "Bhalumunda"
        },
        {
            "id": 7429,
            "name": "Bhursaguda"
        },
        {
            "id": 7430,
            "name": "Bhuslad"
        },
        {
            "id": 7431,
            "name": "Biripali"
        },
        {
            "id": 7432,
            "name": "Chandotora"
        },
        {
            "id": 7433,
            "name": "Chulifunka"
        },
        {
            "id": 7434,
            "name": "Dedgaon"
        },
        {
            "id": 7435,
            "name": "Gandharabanda"
        },
        {
            "id": 7436,
            "name": "Gandharla"
        },
        {
            "id": 7437,
            "name": "Jamkhunta"
        },
        {
            "id": 7438,
            "name": "Jhalap"
        },
        {
            "id": 7439,
            "name": "Jharial"
        },
        {
            "id": 7440,
            "name": "Jurabandh"
        },
        {
            "id": 7441,
            "name": "Kansil"
        },
        {
            "id": 7442,
            "name": "Kapilabhata"
        },
        {
            "id": 7443,
            "name": "Khaira"
        },
        {
            "id": 7444,
            "name": "Kundabutula"
        },
        {
            "id": 7445,
            "name": "Mundpadar"
        },
        {
            "id": 7446,
            "name": "Sindhekela"
        },
        {
            "id": 7447,
            "name": "Themera"
        },
        {
            "id": 7448,
            "name": "Tureikela"
        }
    ],
    "9518": [
        {
            "id": 7449,
            "name": "Kantabanji NAC"
        }
    ],
    "9519": [
        {
            "id": 7450,
            "name": "Badsaimara"
        },
        {
            "id": 7451,
            "name": "Bandhupala"
        },
        {
            "id": 7452,
            "name": "Bankel"
        },
        {
            "id": 7453,
            "name": "Bitabandh"
        },
        {
            "id": 7454,
            "name": "Chalki"
        },
        {
            "id": 7455,
            "name": "Chanabahal"
        },
        {
            "id": 7456,
            "name": "Dangarpada"
        },
        {
            "id": 7457,
            "name": "Gadgadabahal"
        },
        {
            "id": 7458,
            "name": "Ganrei"
        },
        {
            "id": 7459,
            "name": "Goimund"
        },
        {
            "id": 7460,
            "name": "Gudighat"
        },
        {
            "id": 7461,
            "name": "Haldi"
        },
        {
            "id": 7462,
            "name": "Ichhapara"
        },
        {
            "id": 7463,
            "name": "Lakhana"
        },
        {
            "id": 7464,
            "name": "Lebda"
        },
        {
            "id": 7465,
            "name": "Malisira"
        },
        {
            "id": 7466,
            "name": "Muribahal"
        },
        {
            "id": 7467,
            "name": "Patrapali"
        },
        {
            "id": 7468,
            "name": "Patrapali(Kha)"
        },
        {
            "id": 7469,
            "name": "Sirol"
        },
        {
            "id": 7470,
            "name": "Tentelkhunti"
        },
        {
            "id": 7471,
            "name": "Tupaudar"
        }
    ],
    "9520": [
        {
            "id": 7472,
            "name": "Badbanki"
        },
        {
            "id": 7473,
            "name": "Baddakla"
        },
        {
            "id": 7474,
            "name": "Buromal"
        },
        {
            "id": 7475,
            "name": "Chatuanka"
        },
        {
            "id": 7476,
            "name": "Chaulsukha"
        },
        {
            "id": 7477,
            "name": "Dhamandanga"
        },
        {
            "id": 7478,
            "name": "Dholmandal"
        },
        {
            "id": 7479,
            "name": "Ghunesh"
        },
        {
            "id": 7480,
            "name": "Halanbhata"
        },
        {
            "id": 7481,
            "name": "Hial"
        },
        {
            "id": 7482,
            "name": "Jharni"
        },
        {
            "id": 7483,
            "name": "Kameimunda"
        },
        {
            "id": 7484,
            "name": "Kandei"
        },
        {
            "id": 7485,
            "name": "Karuanmunda"
        },
        {
            "id": 7486,
            "name": "Khagsa"
        },
        {
            "id": 7487,
            "name": "Kuibahal"
        },
        {
            "id": 7488,
            "name": "Mahakhand"
        },
        {
            "id": 7489,
            "name": "Mahulpati"
        },
        {
            "id": 7490,
            "name": "Turekela"
        }
    ],
    "9521": [
        {
            "id": 7491,
            "name": "Agalpur"
        },
        {
            "id": 7492,
            "name": "Babufasad"
        },
        {
            "id": 7493,
            "name": "Badtika"
        },
        {
            "id": 7494,
            "name": "Bakti"
        },
        {
            "id": 7495,
            "name": "Bendra"
        },
        {
            "id": 7496,
            "name": "Bharsuja"
        },
        {
            "id": 7497,
            "name": "Budula"
        },
        {
            "id": 7498,
            "name": "Duduka"
        },
        {
            "id": 7499,
            "name": "Jharnipali"
        },
        {
            "id": 7500,
            "name": "Kutasingha"
        },
        {
            "id": 7501,
            "name": "Luhuramunda"
        },
        {
            "id": 7502,
            "name": "Mursundhi"
        },
        {
            "id": 7503,
            "name": "Nagaon (A)"
        },
        {
            "id": 7504,
            "name": "Nagaon (B)"
        },
        {
            "id": 7505,
            "name": "Nuniapali"
        },
        {
            "id": 7506,
            "name": "Patuapali"
        },
        {
            "id": 7507,
            "name": "Rinbachan"
        },
        {
            "id": 7508,
            "name": "Roth"
        },
        {
            "id": 7509,
            "name": "Salebhata"
        },
        {
            "id": 7510,
            "name": "Uparbahal"
        }
    ],
    "9522": [
        {
            "id": 7511,
            "name": "Badibahal"
        },
        {
            "id": 7512,
            "name": "Badimunda"
        },
        {
            "id": 7513,
            "name": "Banipali"
        },
        {
            "id": 7514,
            "name": "Budhipadar"
        },
        {
            "id": 7515,
            "name": "Burda"
        },
        {
            "id": 7516,
            "name": "G.S.Dungripali"
        },
        {
            "id": 7517,
            "name": "Hirapur"
        },
        {
            "id": 7518,
            "name": "Jharmunda"
        },
        {
            "id": 7519,
            "name": "Jogisarda"
        },
        {
            "id": 7520,
            "name": "Kandajuri"
        },
        {
            "id": 7521,
            "name": "Kusang"
        },
        {
            "id": 7522,
            "name": "Kusmel"
        },
        {
            "id": 7523,
            "name": "Kutenpali"
        },
        {
            "id": 7524,
            "name": "Loisingha"
        },
        {
            "id": 7525,
            "name": "Magurbeda"
        },
        {
            "id": 7526,
            "name": "Ratakhandi"
        },
        {
            "id": 7527,
            "name": "Rengali"
        },
        {
            "id": 7528,
            "name": "Sargad"
        },
        {
            "id": 7529,
            "name": "Taliudar"
        },
        {
            "id": 7530,
            "name": "Uparbahal"
        }
    ],
    "9523": [
        {
            "id": 7531,
            "name": "Ainlasari"
        },
        {
            "id": 7532,
            "name": "Ankariapadar"
        },
        {
            "id": 7533,
            "name": "Atgaon"
        },
        {
            "id": 7534,
            "name": "Bahalpadar"
        },
        {
            "id": 7535,
            "name": "Bairasar"
        },
        {
            "id": 7536,
            "name": "Bandhapada"
        },
        {
            "id": 7537,
            "name": "Bhaler"
        },
        {
            "id": 7538,
            "name": "Bileisarda"
        },
        {
            "id": 7539,
            "name": "Bubel"
        },
        {
            "id": 7540,
            "name": "Chhatamakhana"
        },
        {
            "id": 7541,
            "name": "Chhatapipal"
        },
        {
            "id": 7542,
            "name": "Daspur"
        },
        {
            "id": 7543,
            "name": "Durgapali"
        },
        {
            "id": 7544,
            "name": "Gaintala"
        },
        {
            "id": 7545,
            "name": "Jamgaon"
        },
        {
            "id": 7546,
            "name": "Khaliapali"
        },
        {
            "id": 7547,
            "name": "Kurul"
        },
        {
            "id": 7548,
            "name": "Lokapada"
        },
        {
            "id": 7549,
            "name": "Mahimunda"
        },
        {
            "id": 7550,
            "name": "Malmunda"
        },
        {
            "id": 7551,
            "name": "Patharla"
        },
        {
            "id": 7552,
            "name": "Pipirda"
        },
        {
            "id": 7553,
            "name": "Puintala"
        },
        {
            "id": 7554,
            "name": "Randa"
        },
        {
            "id": 7555,
            "name": "Sauntapur"
        }
    ],
    "9524": [
        {
            "id": 7556,
            "name": "Bagdor"
        },
        {
            "id": 7557,
            "name": "Bahabal"
        },
        {
            "id": 7558,
            "name": "Balikhamar"
        },
        {
            "id": 7559,
            "name": "Beheramunda"
        },
        {
            "id": 7560,
            "name": "Belpara"
        },
        {
            "id": 7561,
            "name": "Bharuapali"
        },
        {
            "id": 7562,
            "name": "Darlipali"
        },
        {
            "id": 7563,
            "name": "Dhumabhata"
        },
        {
            "id": 7564,
            "name": "Gambhari"
        },
        {
            "id": 7565,
            "name": "Ghagurli"
        },
        {
            "id": 7566,
            "name": "Juba"
        },
        {
            "id": 7567,
            "name": "Kandhenjhula"
        },
        {
            "id": 7568,
            "name": "Kanut"
        },
        {
            "id": 7569,
            "name": "Kapani"
        },
        {
            "id": 7570,
            "name": "Madhyapur"
        },
        {
            "id": 7571,
            "name": "Mandal"
        },
        {
            "id": 7572,
            "name": "Nunhad"
        },
        {
            "id": 7573,
            "name": "Pandrijore"
        },
        {
            "id": 7574,
            "name": "Parlimal"
        },
        {
            "id": 7575,
            "name": "Salandi"
        },
        {
            "id": 7576,
            "name": "Sarmuhan"
        },
        {
            "id": 7577,
            "name": "Sihini"
        },
        {
            "id": 7578,
            "name": "Sulekela"
        },
        {
            "id": 7579,
            "name": "Tentelmunda"
        }
    ],
    "9525": [
        {
            "id": 7580,
            "name": "Baghmund"
        },
        {
            "id": 7581,
            "name": "Bender"
        },
        {
            "id": 7582,
            "name": "Bhanpur"
        },
        {
            "id": 7583,
            "name": "Changria"
        },
        {
            "id": 7584,
            "name": "Damaipali"
        },
        {
            "id": 7585,
            "name": "Dhandamunda"
        },
        {
            "id": 7586,
            "name": "Ghunsar"
        },
        {
            "id": 7587,
            "name": "Karuanjhar"
        },
        {
            "id": 7588,
            "name": "Khaprakhol"
        },
        {
            "id": 7589,
            "name": "Lathor"
        },
        {
            "id": 7590,
            "name": "Luhasingha"
        },
        {
            "id": 7591,
            "name": "Maharapadar"
        },
        {
            "id": 7592,
            "name": "Malpada"
        },
        {
            "id": 7593,
            "name": "Matiabhata"
        },
        {
            "id": 7594,
            "name": "Nandupala"
        },
        {
            "id": 7595,
            "name": "Odiapali"
        },
        {
            "id": 7596,
            "name": "Padiabahal"
        },
        {
            "id": 7597,
            "name": "Rengali"
        },
        {
            "id": 7598,
            "name": "Sahajpani"
        },
        {
            "id": 7599,
            "name": "Tankapani"
        },
        {
            "id": 7600,
            "name": "Telenpali"
        }
    ],
    "9526": [
        {
            "id": 7601,
            "name": "Balipata"
        },
        {
            "id": 7602,
            "name": "Baneimunda"
        },
        {
            "id": 7603,
            "name": "Barpadar"
        },
        {
            "id": 7604,
            "name": "Batharla"
        },
        {
            "id": 7605,
            "name": "Bhainsa"
        },
        {
            "id": 7606,
            "name": "Bharatbahal"
        },
        {
            "id": 7607,
            "name": "Dangbahal"
        },
        {
            "id": 7608,
            "name": "Deulgaon"
        },
        {
            "id": 7609,
            "name": "Dhatuk"
        },
        {
            "id": 7610,
            "name": "Dhubalpara"
        },
        {
            "id": 7611,
            "name": "Fatamunda"
        },
        {
            "id": 7612,
            "name": "Gangasagar"
        },
        {
            "id": 7613,
            "name": "Gerda"
        },
        {
            "id": 7614,
            "name": "Ghasian"
        },
        {
            "id": 7615,
            "name": "Ghuchepali"
        },
        {
            "id": 7616,
            "name": "Jogimunda"
        },
        {
            "id": 7617,
            "name": "Kendumundi"
        },
        {
            "id": 7618,
            "name": "Kerbeda"
        },
        {
            "id": 7619,
            "name": "Khuntsamalai"
        },
        {
            "id": 7620,
            "name": "Khursel"
        },
        {
            "id": 7621,
            "name": "Larambha"
        },
        {
            "id": 7622,
            "name": "Maruan"
        },
        {
            "id": 7623,
            "name": "Mundomahul"
        },
        {
            "id": 7624,
            "name": "Pandamunda"
        },
        {
            "id": 7625,
            "name": "Salepali"
        },
        {
            "id": 7626,
            "name": "Solbandh"
        },
        {
            "id": 7627,
            "name": "Sunamudi"
        },
        {
            "id": 7628,
            "name": "Tamian"
        },
        {
            "id": 7629,
            "name": "Tendapadar"
        },
        {
            "id": 7630,
            "name": "Ulba"
        }
    ],
    "9527": [
        {
            "id": 7631,
            "name": "NAC"
        }
    ],
    "9528": [
        {
            "id": 7632,
            "name": "Ampali"
        },
        {
            "id": 7633,
            "name": "Belgaon"
        },
        {
            "id": 7634,
            "name": "Bhadra"
        },
        {
            "id": 7635,
            "name": "Bibina"
        },
        {
            "id": 7636,
            "name": "Budabahal"
        },
        {
            "id": 7637,
            "name": "Deng"
        },
        {
            "id": 7638,
            "name": "Dharapgarh"
        },
        {
            "id": 7639,
            "name": "Dungripali"
        },
        {
            "id": 7640,
            "name": "Gandapatrapali"
        },
        {
            "id": 7641,
            "name": "Ghunsar"
        },
        {
            "id": 7642,
            "name": "Jhinkidungri"
        },
        {
            "id": 7643,
            "name": "Jurabandh"
        },
        {
            "id": 7644,
            "name": "Kandhakelgaon"
        },
        {
            "id": 7645,
            "name": "Kansar"
        },
        {
            "id": 7646,
            "name": "Karamtala"
        },
        {
            "id": 7647,
            "name": "Kermeli"
        },
        {
            "id": 7648,
            "name": "Kuikeda"
        },
        {
            "id": 7649,
            "name": "Kumbhari"
        },
        {
            "id": 7650,
            "name": "Saintala"
        },
        {
            "id": 7651,
            "name": "Siskela"
        },
        {
            "id": 7652,
            "name": "Talbahal"
        },
        {
            "id": 7653,
            "name": "Tikrapara"
        }
    ],
    "9529": [
        {
            "id": 7654,
            "name": "Badangomunda"
        },
        {
            "id": 7655,
            "name": "Biramunda"
        },
        {
            "id": 7656,
            "name": "Chinched"
        },
        {
            "id": 7657,
            "name": "Deulgudi"
        },
        {
            "id": 7658,
            "name": "Gambhariguda"
        },
        {
            "id": 7659,
            "name": "Ghuna"
        },
        {
            "id": 7660,
            "name": "Gudvela"
        },
        {
            "id": 7661,
            "name": "Jamut"
        },
        {
            "id": 7662,
            "name": "Madhekela"
        },
        {
            "id": 7663,
            "name": "Nuapada"
        },
        {
            "id": 7664,
            "name": "Rusuda"
        },
        {
            "id": 7665,
            "name": "Samara"
        },
        {
            "id": 7666,
            "name": "Tentulikhunti"
        }
    ],
    "9530": [
        {
            "id": 7667,
            "name": "Adabahal"
        },
        {
            "id": 7668,
            "name": "Bandupala"
        },
        {
            "id": 7669,
            "name": "Banjipadar"
        },
        {
            "id": 7670,
            "name": "Bhalegaon"
        },
        {
            "id": 7671,
            "name": "Bijepur"
        },
        {
            "id": 7672,
            "name": "Binekela"
        },
        {
            "id": 7673,
            "name": "Digsira"
        },
        {
            "id": 7674,
            "name": "Ghantabahali"
        },
        {
            "id": 7675,
            "name": "Ghodar"
        },
        {
            "id": 7676,
            "name": "Jagua"
        },
        {
            "id": 7677,
            "name": "Katarkela"
        },
        {
            "id": 7678,
            "name": "Kholan"
        },
        {
            "id": 7679,
            "name": "Kursud"
        },
        {
            "id": 7680,
            "name": "Kuskela"
        },
        {
            "id": 7681,
            "name": "Luthurbandh"
        },
        {
            "id": 7682,
            "name": "Mahulpada"
        },
        {
            "id": 7683,
            "name": "Manigaon"
        },
        {
            "id": 7684,
            "name": "Marlad"
        },
        {
            "id": 7685,
            "name": "Naren"
        },
        {
            "id": 7686,
            "name": "Parasara"
        },
        {
            "id": 7687,
            "name": "Sagadghat"
        },
        {
            "id": 7688,
            "name": "Sihini"
        },
        {
            "id": 7689,
            "name": "Siluan"
        },
        {
            "id": 7690,
            "name": "Surda"
        }
    ],
    "9531": [
        {
            "id": 7691,
            "name": "NAC"
        }
    ],
    "9532": [
        {
            "id": 7692,
            "name": "NAC"
        }
    ],
    "9533": [
        {
            "id": 7693,
            "name": "NAC"
        }
    ],
    "9534": [
        {
            "id": 7694,
            "name": "Ambajhari"
        },
        {
            "id": 7695,
            "name": "Badhigoan"
        },
        {
            "id": 7696,
            "name": "Baghiapada"
        },
        {
            "id": 7697,
            "name": "Brahmanipali"
        },
        {
            "id": 7698,
            "name": "Kasurbandha"
        },
        {
            "id": 7699,
            "name": "Khaliapali (Purumunda)"
        },
        {
            "id": 7700,
            "name": "Khuntabandha"
        },
        {
            "id": 7701,
            "name": "Laxmiprasad"
        },
        {
            "id": 7702,
            "name": "Mundapada"
        },
        {
            "id": 7703,
            "name": "Mursundhi"
        },
        {
            "id": 7704,
            "name": "Padmanpur"
        },
        {
            "id": 7705,
            "name": "Telibandha"
        },
        {
            "id": 7706,
            "name": "Tikarapada"
        }
    ],
    "9535": [
        {
            "id": 7707,
            "name": "NAC"
        }
    ],
    "9536": [
        {
            "id": 7708,
            "name": "adenigarh"
        },
        {
            "id": 7709,
            "name": "Bamanda"
        },
        {
            "id": 7710,
            "name": "bandhapathar"
        },
        {
            "id": 7711,
            "name": "Banibhusanpur"
        },
        {
            "id": 7712,
            "name": "Birnarsinghpur"
        },
        {
            "id": 7713,
            "name": "Chhatranga"
        },
        {
            "id": 7714,
            "name": "Dhalapur"
        },
        {
            "id": 7715,
            "name": "Harabhanga"
        },
        {
            "id": 7716,
            "name": "Karanjakata"
        },
        {
            "id": 7717,
            "name": "Kusanga"
        },
        {
            "id": 7718,
            "name": "Lunibahal"
        },
        {
            "id": 7719,
            "name": "Mahalikpada"
        },
        {
            "id": 7720,
            "name": "Mathura"
        },
        {
            "id": 7721,
            "name": "Pitambarpur"
        },
        {
            "id": 7722,
            "name": "Purunakatak"
        },
        {
            "id": 7723,
            "name": "Radhanagar"
        },
        {
            "id": 7724,
            "name": "Ramagarh"
        },
        {
            "id": 7725,
            "name": "Sampoch"
        },
        {
            "id": 7726,
            "name": "Sankuloi"
        },
        {
            "id": 7727,
            "name": "Sarasara"
        },
        {
            "id": 7728,
            "name": "Talagoan"
        },
        {
            "id": 7729,
            "name": "Tileswar"
        }
    ],
    "9537": [
        {
            "id": 7730,
            "name": "Manupali"
        },
        {
            "id": 7731,
            "name": "Ainlapali"
        },
        {
            "id": 7732,
            "name": "Bahira"
        },
        {
            "id": 7733,
            "name": "Baunsuni"
        },
        {
            "id": 7734,
            "name": "Gochhapada"
        },
        {
            "id": 7735,
            "name": "Gundulia"
        },
        {
            "id": 7736,
            "name": "Kasura bandha"
        },
        {
            "id": 7737,
            "name": "Khaliapali"
        },
        {
            "id": 7738,
            "name": "Mundipadar"
        },
        {
            "id": 7739,
            "name": "Roxa"
        },
        {
            "id": 7740,
            "name": "Sagada"
        },
        {
            "id": 7741,
            "name": "Talasarada"
        }
    ],
    "9538": [
        {
            "id": 7742,
            "name": "NAC"
        }
    ],
    "9539": [
        {
            "id": 7743,
            "name": "Rengali"
        },
        {
            "id": 7744,
            "name": "Ambagoan"
        },
        {
            "id": 7745,
            "name": "Baragoan"
        },
        {
            "id": 7746,
            "name": "Baragochha"
        },
        {
            "id": 7747,
            "name": "Bilaspur"
        },
        {
            "id": 7748,
            "name": "Dapala"
        },
        {
            "id": 7749,
            "name": "Ghantapada"
        },
        {
            "id": 7750,
            "name": "Gheekundi"
        },
        {
            "id": 7751,
            "name": "Gudvelipadar"
        },
        {
            "id": 7752,
            "name": "Jogindrapur"
        },
        {
            "id": 7753,
            "name": "Kantamal"
        },
        {
            "id": 7754,
            "name": "Khaliapali"
        },
        {
            "id": 7755,
            "name": "Khamanmunda"
        },
        {
            "id": 7756,
            "name": "Khatakhatia"
        },
        {
            "id": 7757,
            "name": "Khuntigora"
        },
        {
            "id": 7758,
            "name": "Kultajore"
        },
        {
            "id": 7759,
            "name": "Lambasary"
        },
        {
            "id": 7760,
            "name": "Manamunda"
        },
        {
            "id": 7761,
            "name": "Narayanprasad"
        },
        {
            "id": 7762,
            "name": "Palsagora"
        },
        {
            "id": 7763,
            "name": "Rundimahul"
        },
        {
            "id": 7764,
            "name": "Similipadar"
        },
        {
            "id": 7765,
            "name": "Sundhipadar"
        },
        {
            "id": 7766,
            "name": "Uma"
        }
    ],
    "9540": [
        {
            "id": 7767,
            "name": "Alipur"
        },
        {
            "id": 7768,
            "name": "Aska(N)"
        },
        {
            "id": 7769,
            "name": "Babanapur"
        },
        {
            "id": 7770,
            "name": "Badakhoili"
        },
        {
            "id": 7771,
            "name": "Balichai"
        },
        {
            "id": 7772,
            "name": "Balisira"
        },
        {
            "id": 7773,
            "name": "Bangarada"
        },
        {
            "id": 7774,
            "name": "Baragan"
        },
        {
            "id": 7775,
            "name": "Benapata"
        },
        {
            "id": 7776,
            "name": "Bhetanai"
        },
        {
            "id": 7777,
            "name": "Chadiapali"
        },
        {
            "id": 7778,
            "name": "Debabhumi"
        },
        {
            "id": 7779,
            "name": "Gahangu"
        },
        {
            "id": 7780,
            "name": "Gangapur"
        },
        {
            "id": 7781,
            "name": "Gunthapada"
        },
        {
            "id": 7782,
            "name": "Haridapadara"
        },
        {
            "id": 7783,
            "name": "Jayapur"
        },
        {
            "id": 7784,
            "name": "Kalasandhapur"
        },
        {
            "id": 7785,
            "name": "Kamagada"
        },
        {
            "id": 7786,
            "name": "Kendupadara"
        },
        {
            "id": 7787,
            "name": "Khandadeuli"
        },
        {
            "id": 7788,
            "name": "Kharia"
        },
        {
            "id": 7789,
            "name": "Mangalapur"
        },
        {
            "id": 7790,
            "name": "Munigadi"
        },
        {
            "id": 7791,
            "name": "Nalabanta"
        },
        {
            "id": 7792,
            "name": "Nimina"
        },
        {
            "id": 7793,
            "name": "Pandiapathara"
        },
        {
            "id": 7794,
            "name": "Sidhanai"
        }
    ],
    "9541": [
        {
            "id": 7795,
            "name": "NAC"
        }
    ],
    "9542": [
        {
            "id": 7796,
            "name": "Ambapua"
        },
        {
            "id": 7797,
            "name": "Baliasara"
        },
        {
            "id": 7798,
            "name": "Barida(A)"
        },
        {
            "id": 7799,
            "name": "Bolasara"
        },
        {
            "id": 7800,
            "name": "Borasingi"
        },
        {
            "id": 7801,
            "name": "K.s.nagar(N)"
        },
        {
            "id": 7802,
            "name": "Kaniary"
        },
        {
            "id": 7803,
            "name": "Nandiagada"
        },
        {
            "id": 7804,
            "name": "Paikajamuna"
        },
        {
            "id": 7805,
            "name": "Sialia"
        },
        {
            "id": 7806,
            "name": "Subalaya"
        },
        {
            "id": 7807,
            "name": "Sunapalli"
        },
        {
            "id": 7808,
            "name": "Sunarijhola"
        }
    ],
    "9543": [
        {
            "id": 7809,
            "name": "NAC"
        }
    ],
    "9544": [
        {
            "id": 7810,
            "name": "Badakodanda"
        },
        {
            "id": 7811,
            "name": "Baibali"
        },
        {
            "id": 7812,
            "name": "Baruda"
        },
        {
            "id": 7813,
            "name": "Baunsalundi"
        },
        {
            "id": 7814,
            "name": "Brahmanpadar"
        },
        {
            "id": 7815,
            "name": "Dadaralunda"
        },
        {
            "id": 7816,
            "name": "Dihapadhala"
        },
        {
            "id": 7817,
            "name": "Domuhani"
        },
        {
            "id": 7818,
            "name": "Dumakumpa"
        },
        {
            "id": 7819,
            "name": "Gallery"
        },
        {
            "id": 7820,
            "name": "Golapada"
        },
        {
            "id": 7821,
            "name": "Gunduribadi"
        },
        {
            "id": 7822,
            "name": "Harigada"
        },
        {
            "id": 7823,
            "name": "Jillundi"
        },
        {
            "id": 7824,
            "name": "Kulada"
        },
        {
            "id": 7825,
            "name": "Lalasingi"
        },
        {
            "id": 7826,
            "name": "Lembei"
        },
        {
            "id": 7827,
            "name": "Mudulipalli"
        },
        {
            "id": 7828,
            "name": "Mujagada"
        },
        {
            "id": 7829,
            "name": "Sanakodanda"
        },
        {
            "id": 7830,
            "name": "Tilisingi"
        },
        {
            "id": 7831,
            "name": "Turumu"
        }
    ],
    "9545": [
        {
            "id": 7832,
            "name": "NAC"
        }
    ],
    "9546": [
        {
            "id": 7833,
            "name": "Adheigan"
        },
        {
            "id": 7834,
            "name": "Alasuguma"
        },
        {
            "id": 7835,
            "name": "Alladi"
        },
        {
            "id": 7836,
            "name": "B.D.Pur"
        },
        {
            "id": 7837,
            "name": "Baragam"
        },
        {
            "id": 7838,
            "name": "Budurungu"
        },
        {
            "id": 7839,
            "name": "Chadheiapalli"
        },
        {
            "id": 7840,
            "name": "Chhamunda"
        },
        {
            "id": 7841,
            "name": "Gandadhar"
        },
        {
            "id": 7842,
            "name": "Gayaganda"
        },
        {
            "id": 7843,
            "name": "Gereda"
        },
        {
            "id": 7844,
            "name": "Jaganath Prasad"
        },
        {
            "id": 7845,
            "name": "Jhadabhumi"
        },
        {
            "id": 7846,
            "name": "Jirabadi"
        },
        {
            "id": 7847,
            "name": "Kadua"
        },
        {
            "id": 7848,
            "name": "Khamarapalli"
        },
        {
            "id": 7849,
            "name": "Khetamundalli"
        },
        {
            "id": 7850,
            "name": "Kokolaba"
        },
        {
            "id": 7851,
            "name": "Kudutai"
        },
        {
            "id": 7852,
            "name": "Kumpapada"
        },
        {
            "id": 7853,
            "name": "Panchabhuti"
        },
        {
            "id": 7854,
            "name": "Pratapur"
        },
        {
            "id": 7855,
            "name": "Rauti"
        },
        {
            "id": 7856,
            "name": "Rudhapadar"
        },
        {
            "id": 7857,
            "name": "Tarasingi"
        }
    ],
    "9547": [
        {
            "id": 7858,
            "name": "Agastinuagan"
        },
        {
            "id": 7859,
            "name": "Arjyapalli"
        },
        {
            "id": 7860,
            "name": "Bada Madhapur"
        },
        {
            "id": 7861,
            "name": "Baulagam"
        },
        {
            "id": 7862,
            "name": "Bhikaripalli"
        },
        {
            "id": 7863,
            "name": "Bipulingi"
        },
        {
            "id": 7864,
            "name": "Chamakhandi"
        },
        {
            "id": 7865,
            "name": "Chhatrapur(N)"
        },
        {
            "id": 7866,
            "name": "Chikalkhandi"
        },
        {
            "id": 7867,
            "name": "Kallipalli"
        },
        {
            "id": 7868,
            "name": "Kanamana"
        },
        {
            "id": 7869,
            "name": "Laxmipur"
        },
        {
            "id": 7870,
            "name": "Mahanadapur"
        },
        {
            "id": 7871,
            "name": "Narendrapur"
        },
        {
            "id": 7872,
            "name": "Podapadara"
        },
        {
            "id": 7873,
            "name": "Potlampur"
        },
        {
            "id": 7874,
            "name": "Sriramachandrapur"
        },
        {
            "id": 7875,
            "name": "Sundarpur"
        },
        {
            "id": 7876,
            "name": "Tanganapalli"
        }
    ],
    "9548": [
        {
            "id": 7877,
            "name": "NAC"
        }
    ],
    "9549": [
        {
            "id": 7878,
            "name": "Humma"
        },
        {
            "id": 7879,
            "name": "Jharedi Radhagobindapur"
        },
        {
            "id": 7880,
            "name": "Kainchapur"
        },
        {
            "id": 7881,
            "name": "Karapada"
        },
        {
            "id": 7882,
            "name": "Khandadeuli"
        },
        {
            "id": 7883,
            "name": "Mallada"
        },
        {
            "id": 7884,
            "name": "Palanga"
        },
        {
            "id": 7885,
            "name": "Palibandha"
        },
        {
            "id": 7886,
            "name": "Poirasi"
        },
        {
            "id": 7887,
            "name": "Rajapur"
        },
        {
            "id": 7888,
            "name": "Ramagada"
        },
        {
            "id": 7889,
            "name": "Sanaramchandrapur"
        },
        {
            "id": 7890,
            "name": "Santoshpur"
        },
        {
            "id": 7891,
            "name": "Subalaya"
        },
        {
            "id": 7892,
            "name": "Umuri Dharmasaranpur"
        }
    ],
    "9550": [
        {
            "id": 7893,
            "name": "NAC"
        }
    ],
    "9551": [
        {
            "id": 7894,
            "name": "NAC"
        }
    ],
    "9552": [
        {
            "id": 7895,
            "name": "Chandapur"
        },
        {
            "id": 7896,
            "name": "Chikiti(N)"
        },
        {
            "id": 7897,
            "name": "Ekasingi"
        },
        {
            "id": 7898,
            "name": "Girisola"
        },
        {
            "id": 7899,
            "name": "Govindanagar"
        },
        {
            "id": 7900,
            "name": "Jhatipadar"
        },
        {
            "id": 7901,
            "name": "K.Nuagam"
        },
        {
            "id": 7902,
            "name": "Kalabada"
        },
        {
            "id": 7903,
            "name": "Katuru"
        },
        {
            "id": 7904,
            "name": "Kotlingi"
        },
        {
            "id": 7905,
            "name": "Lalmenta.L.N.Pur"
        },
        {
            "id": 7906,
            "name": "Nuapada"
        },
        {
            "id": 7907,
            "name": "Pitatali"
        },
        {
            "id": 7908,
            "name": "R.Subani"
        },
        {
            "id": 7909,
            "name": "Ramachandrapur"
        },
        {
            "id": 7910,
            "name": "Rampa"
        },
        {
            "id": 7911,
            "name": "Sonpur"
        },
        {
            "id": 7912,
            "name": "Sorala"
        }
    ],
    "9553": [
        {
            "id": 7913,
            "name": "NAC"
        }
    ],
    "9554": [
        {
            "id": 7914,
            "name": "Ambapur"
        },
        {
            "id": 7915,
            "name": "Ankuli"
        },
        {
            "id": 7916,
            "name": "Badapur"
        },
        {
            "id": 7917,
            "name": "Baranga"
        },
        {
            "id": 7918,
            "name": "Brahman Nuagam"
        },
        {
            "id": 7919,
            "name": "Buratal"
        },
        {
            "id": 7920,
            "name": "Gudipadar"
        },
        {
            "id": 7921,
            "name": "Gundra"
        },
        {
            "id": 7922,
            "name": "Jaradagada"
        },
        {
            "id": 7923,
            "name": "Jayantipur"
        },
        {
            "id": 7924,
            "name": "Kesaripada"
        },
        {
            "id": 7925,
            "name": "Khamarigam"
        },
        {
            "id": 7926,
            "name": "Kharanipada"
        },
        {
            "id": 7927,
            "name": "Khariaguda"
        },
        {
            "id": 7928,
            "name": "Mandarada"
        },
        {
            "id": 7929,
            "name": "Padadigi"
        },
        {
            "id": 7930,
            "name": "Patrapur"
        },
        {
            "id": 7931,
            "name": "Samantiapali"
        },
        {
            "id": 7932,
            "name": "Samantrapur"
        },
        {
            "id": 7933,
            "name": "Surangigada"
        },
        {
            "id": 7934,
            "name": "Tandipur"
        },
        {
            "id": 7935,
            "name": "Tumba Gada"
        },
        {
            "id": 7936,
            "name": "Turubudi"
        }
    ],
    "9555": [
        {
            "id": 7937,
            "name": "Ankoroda"
        },
        {
            "id": 7938,
            "name": "Bada Dumula"
        },
        {
            "id": 7939,
            "name": "Bada Khemundi Turubudi"
        },
        {
            "id": 7940,
            "name": "Basudev Pur"
        },
        {
            "id": 7941,
            "name": "Bhismagiri"
        },
        {
            "id": 7942,
            "name": "Bhramar Pur"
        },
        {
            "id": 7943,
            "name": "BLN Pur"
        },
        {
            "id": 7944,
            "name": "Bomkei"
        },
        {
            "id": 7945,
            "name": "Chasa Nimakhandi"
        },
        {
            "id": 7946,
            "name": "Choudhary Tikara Pada"
        },
        {
            "id": 7947,
            "name": "Gada Govinda Pur"
        },
        {
            "id": 7948,
            "name": "Gokarna Pur"
        },
        {
            "id": 7949,
            "name": "Goudagam"
        },
        {
            "id": 7950,
            "name": "Jakar"
        },
        {
            "id": 7951,
            "name": "Kaithada"
        },
        {
            "id": 7952,
            "name": "Kaithkhandi"
        },
        {
            "id": 7953,
            "name": "Karapada"
        },
        {
            "id": 7954,
            "name": "Khamari Gam"
        },
        {
            "id": 7955,
            "name": "Kotinada"
        },
        {
            "id": 7956,
            "name": "Kusapada"
        },
        {
            "id": 7957,
            "name": "Nima Khandi Pentho"
        },
        {
            "id": 7958,
            "name": "Padma Nav Pur"
        },
        {
            "id": 7959,
            "name": "Phasiguda"
        },
        {
            "id": 7960,
            "name": "Sahadebtikarapada"
        },
        {
            "id": 7961,
            "name": "Sidheswar"
        }
    ],
    "9556": [
        {
            "id": 7962,
            "name": "NAC"
        }
    ],
    "9557": [
        {
            "id": 7963,
            "name": "Anantei"
        },
        {
            "id": 7964,
            "name": "Ankushpur"
        },
        {
            "id": 7965,
            "name": "Baghalati"
        },
        {
            "id": 7966,
            "name": "Ballipada"
        },
        {
            "id": 7967,
            "name": "Banthapalli"
        },
        {
            "id": 7968,
            "name": "Boulajholi"
        },
        {
            "id": 7969,
            "name": "Dakhinapur"
        },
        {
            "id": 7970,
            "name": "Dengapadar"
        },
        {
            "id": 7971,
            "name": "Jagdalpur"
        },
        {
            "id": 7972,
            "name": "Kankia"
        },
        {
            "id": 7973,
            "name": "Kukudakahandi"
        },
        {
            "id": 7974,
            "name": "Lanjia"
        },
        {
            "id": 7975,
            "name": "Lathi"
        },
        {
            "id": 7976,
            "name": "Lochapada"
        },
        {
            "id": 7977,
            "name": "Mohuda"
        },
        {
            "id": 7978,
            "name": "Mosiakhalli"
        },
        {
            "id": 7979,
            "name": "Rohigam"
        },
        {
            "id": 7980,
            "name": "Sihala"
        }
    ],
    "9558": [
        {
            "id": 7981,
            "name": "NAC"
        }
    ],
    "9559": [
        {
            "id": 7982,
            "name": "NAC"
        }
    ],
    "9560": [
        {
            "id": 7983,
            "name": "Borigam"
        },
        {
            "id": 7984,
            "name": "Gurunthi"
        },
        {
            "id": 7985,
            "name": "Hugulapata"
        },
        {
            "id": 7986,
            "name": "Lochapada"
        },
        {
            "id": 7987,
            "name": "Nimakhandi"
        }
    ],
    "9561": [
        {
            "id": 7988,
            "name": "Badakustalli"
        },
        {
            "id": 7989,
            "name": "Bhabinipur"
        },
        {
            "id": 7990,
            "name": "Biswanathapur"
        },
        {
            "id": 7991,
            "name": "Boxipalli"
        },
        {
            "id": 7992,
            "name": "Chandapur"
        },
        {
            "id": 7993,
            "name": "Chikarada"
        },
        {
            "id": 7994,
            "name": "Dura"
        },
        {
            "id": 7995,
            "name": "Golabandha"
        },
        {
            "id": 7996,
            "name": "Golonthora"
        },
        {
            "id": 7997,
            "name": "Gounju"
        },
        {
            "id": 7998,
            "name": "Haladiapadar"
        },
        {
            "id": 7999,
            "name": "Hatipada"
        },
        {
            "id": 8000,
            "name": "Indrakhi"
        },
        {
            "id": 8001,
            "name": "Kamalapur"
        },
        {
            "id": 8002,
            "name": "Karapalli"
        },
        {
            "id": 8003,
            "name": "Keluapalli"
        },
        {
            "id": 8004,
            "name": "Kolathigan"
        },
        {
            "id": 8005,
            "name": "Konisi"
        },
        {
            "id": 8006,
            "name": "Kotharsingi"
        },
        {
            "id": 8007,
            "name": "Loudigam"
        },
        {
            "id": 8008,
            "name": "Luhajhara"
        },
        {
            "id": 8009,
            "name": "Mandrajpur"
        },
        {
            "id": 8010,
            "name": "Mantridi"
        },
        {
            "id": 8011,
            "name": "Markandi"
        },
        {
            "id": 8012,
            "name": "Narayanapur"
        },
        {
            "id": 8013,
            "name": "Padmapur"
        },
        {
            "id": 8014,
            "name": "Phulta"
        },
        {
            "id": 8015,
            "name": "Randha"
        },
        {
            "id": 8016,
            "name": "Rangeilunda"
        },
        {
            "id": 8017,
            "name": "Rangipur"
        },
        {
            "id": 8018,
            "name": "Sasanapadar"
        },
        {
            "id": 8019,
            "name": "Satakutuni"
        }
    ],
    "9562": [
        {
            "id": 8020,
            "name": "Badakhandi"
        },
        {
            "id": 8021,
            "name": "Badi Ambagam"
        },
        {
            "id": 8022,
            "name": "Badiampajan"
        },
        {
            "id": 8023,
            "name": "Bellagam"
        },
        {
            "id": 8024,
            "name": "Bhabandha"
        },
        {
            "id": 8025,
            "name": "Burupada"
        },
        {
            "id": 8026,
            "name": "Chanduli"
        },
        {
            "id": 8027,
            "name": "Darubhadra"
        },
        {
            "id": 8028,
            "name": "Dayapalli"
        },
        {
            "id": 8029,
            "name": "Dhobadi"
        },
        {
            "id": 8030,
            "name": "Durbandha"
        },
        {
            "id": 8031,
            "name": "Gandala"
        },
        {
            "id": 8032,
            "name": "Kanchuru"
        },
        {
            "id": 8033,
            "name": "Kharida"
        },
        {
            "id": 8034,
            "name": "Makarajhola"
        },
        {
            "id": 8035,
            "name": "Nandika"
        },
        {
            "id": 8036,
            "name": "Nimigam"
        },
        {
            "id": 8037,
            "name": "Pochilima"
        },
        {
            "id": 8038,
            "name": "Pundukhandi "
        },
        {
            "id": 8039,
            "name": "Putiapadara"
        },
        {
            "id": 8040,
            "name": "Ralaba"
        },
        {
            "id": 8041,
            "name": "Rangulipalli"
        },
        {
            "id": 8042,
            "name": "Sadasibapur"
        },
        {
            "id": 8043,
            "name": "Sahapur"
        },
        {
            "id": 8044,
            "name": "Saru"
        },
        {
            "id": 8045,
            "name": "Sasanambagam"
        },
        {
            "id": 8046,
            "name": "Sikiri"
        }
    ],
    "9563": [
        {
            "id": 8047,
            "name": "NAC"
        }
    ],
    "9564": [
        {
            "id": 8048,
            "name": "Alarigada"
        },
        {
            "id": 8049,
            "name": "Bandhaguda"
        },
        {
            "id": 8050,
            "name": "Baramundali"
        },
        {
            "id": 8051,
            "name": "Brahmanachhai"
        },
        {
            "id": 8052,
            "name": "Dengapadara"
        },
        {
            "id": 8053,
            "name": "Dhabalapur"
        },
        {
            "id": 8054,
            "name": "Dhanantara"
        },
        {
            "id": 8055,
            "name": "Gobindapur"
        },
        {
            "id": 8056,
            "name": "Gothagam"
        },
        {
            "id": 8057,
            "name": "Karadakana"
        },
        {
            "id": 8058,
            "name": "Khirida"
        },
        {
            "id": 8059,
            "name": "Krushnachhai"
        },
        {
            "id": 8060,
            "name": "Kulagada"
        },
        {
            "id": 8061,
            "name": "Kumarapani"
        },
        {
            "id": 8062,
            "name": "Kurula"
        },
        {
            "id": 8063,
            "name": "Mahupadara"
        },
        {
            "id": 8064,
            "name": "Narendrapur"
        },
        {
            "id": 8065,
            "name": "Pitala"
        },
        {
            "id": 8066,
            "name": "Sheragada"
        },
        {
            "id": 8067,
            "name": "Takarada"
        },
        {
            "id": 8068,
            "name": "Tanhara"
        },
        {
            "id": 8069,
            "name": "Thuruburai"
        }
    ],
    "9565": [
        {
            "id": 8070,
            "name": "Angargam"
        },
        {
            "id": 8071,
            "name": "B.K.Khama"
        },
        {
            "id": 8072,
            "name": "Beguniapada"
        },
        {
            "id": 8073,
            "name": "Beruanbadi"
        },
        {
            "id": 8074,
            "name": "Burujhari"
        },
        {
            "id": 8075,
            "name": "Chandanpur"
        },
        {
            "id": 8076,
            "name": "Chingudikhole"
        },
        {
            "id": 8077,
            "name": "Digapada"
        },
        {
            "id": 8078,
            "name": "Ghodapada"
        },
        {
            "id": 8079,
            "name": "K.Barida"
        },
        {
            "id": 8080,
            "name": "Kalimeghi"
        },
        {
            "id": 8081,
            "name": "Khandianai"
        },
        {
            "id": 8082,
            "name": "Kumbhargaon"
        },
        {
            "id": 8083,
            "name": "Mardakote"
        },
        {
            "id": 8084,
            "name": "Mardamekha"
        },
        {
            "id": 8085,
            "name": "Mathasarasingi"
        },
        {
            "id": 8086,
            "name": "Phasi"
        },
        {
            "id": 8087,
            "name": "S.Chachina"
        },
        {
            "id": 8088,
            "name": "S.Ustapada"
        },
        {
            "id": 8089,
            "name": "Sandhamula"
        },
        {
            "id": 8090,
            "name": "Sankuda"
        },
        {
            "id": 8091,
            "name": "Sumandala"
        },
        {
            "id": 8092,
            "name": "Talasara"
        }
    ],
    "9566": [
        {
            "id": 8093,
            "name": "Athagadapatna"
        },
        {
            "id": 8094,
            "name": "Badamahuri"
        },
        {
            "id": 8095,
            "name": "Baunsia"
        },
        {
            "id": 8096,
            "name": "Budhamba"
        },
        {
            "id": 8097,
            "name": "Gangapur"
        },
        {
            "id": 8098,
            "name": "Gudiali"
        },
        {
            "id": 8099,
            "name": "Jarada"
        },
        {
            "id": 8100,
            "name": "Khajapalli"
        },
        {
            "id": 8101,
            "name": "Risipur"
        }
    ],
    "9567": [
        {
            "id": 8102,
            "name": "NAC"
        }
    ],
    "9568": [
        {
            "id": 8103,
            "name": "NAC"
        }
    ],
    "9569": [
        {
            "id": 8104,
            "name": "Antarigoan"
        },
        {
            "id": 8105,
            "name": "Arakhapur"
        },
        {
            "id": 8106,
            "name": "Badakharida"
        },
        {
            "id": 8107,
            "name": "Bhimpur"
        },
        {
            "id": 8108,
            "name": "Bhutasarasingi"
        },
        {
            "id": 8109,
            "name": "Chingudhai"
        },
        {
            "id": 8110,
            "name": "Chingudighai"
        },
        {
            "id": 8111,
            "name": "Chinuagaan"
        },
        {
            "id": 8112,
            "name": "Gangadeuni"
        },
        {
            "id": 8113,
            "name": "Jamuni"
        },
        {
            "id": 8114,
            "name": "Jhadabai"
        },
        {
            "id": 8115,
            "name": "P.Pur"
        },
        {
            "id": 8116,
            "name": "Pandia"
        },
        {
            "id": 8117,
            "name": "Sama"
        },
        {
            "id": 8118,
            "name": "Sikula"
        },
        {
            "id": 8119,
            "name": "Tankachha"
        }
    ],
    "9570": [
        {
            "id": 8120,
            "name": "Aitipur"
        },
        {
            "id": 8121,
            "name": "B.N.Palli"
        },
        {
            "id": 8122,
            "name": "B.N Pur"
        },
        {
            "id": 8123,
            "name": "Badapalli"
        },
        {
            "id": 8124,
            "name": "Bania"
        },
        {
            "id": 8125,
            "name": "Bhakutagaon"
        },
        {
            "id": 8126,
            "name": "Bhikapada"
        },
        {
            "id": 8127,
            "name": "Bikrampur"
        },
        {
            "id": 8128,
            "name": "Chikili"
        },
        {
            "id": 8129,
            "name": "Danapur"
        },
        {
            "id": 8130,
            "name": "Dimiria"
        },
        {
            "id": 8131,
            "name": "Kairasi"
        },
        {
            "id": 8132,
            "name": "Kanaka"
        },
        {
            "id": 8133,
            "name": "Kanchana"
        },
        {
            "id": 8134,
            "name": "Kanheipur"
        },
        {
            "id": 8135,
            "name": "Keshpur"
        },
        {
            "id": 8136,
            "name": "Khalikote(N)"
        },
        {
            "id": 8137,
            "name": "Khojapalli"
        },
        {
            "id": 8138,
            "name": "Komondo"
        },
        {
            "id": 8139,
            "name": "Langaleswar"
        },
        {
            "id": 8140,
            "name": "Manikapur"
        },
        {
            "id": 8141,
            "name": "Mathura"
        },
        {
            "id": 8142,
            "name": "Naikanipalli"
        },
        {
            "id": 8143,
            "name": "Odiallapur"
        },
        {
            "id": 8144,
            "name": "Pathara"
        },
        {
            "id": 8145,
            "name": "Sabulia"
        },
        {
            "id": 8146,
            "name": "SKS Pur"
        },
        {
            "id": 8147,
            "name": "Talapada"
        },
        {
            "id": 8148,
            "name": "Tentuliapalli"
        },
        {
            "id": 8149,
            "name": "Tulasipur"
        }
    ],
    "9571": [
        {
            "id": 8150,
            "name": "NAC"
        }
    ],
    "9572": [
        {
            "id": 8151,
            "name": "Achuli"
        },
        {
            "id": 8152,
            "name": "Badabaragam"
        },
        {
            "id": 8153,
            "name": "Baghala"
        },
        {
            "id": 8154,
            "name": "Bhatakumarada"
        },
        {
            "id": 8155,
            "name": "Bhimpur"
        },
        {
            "id": 8156,
            "name": "Gangadeuni"
        },
        {
            "id": 8157,
            "name": "Handighar"
        },
        {
            "id": 8158,
            "name": "Jaganathpur"
        },
        {
            "id": 8159,
            "name": "Jamuni"
        },
        {
            "id": 8160,
            "name": "Kama Nalinakhyapur"
        },
        {
            "id": 8161,
            "name": "Pratapur"
        },
        {
            "id": 8162,
            "name": "Raipur"
        },
        {
            "id": 8163,
            "name": "Ranajhali"
        },
        {
            "id": 8164,
            "name": "Solaghar"
        },
        {
            "id": 8165,
            "name": "Sunathar"
        }
    ],
    "9573": [
        {
            "id": 8166,
            "name": "NAC"
        }
    ],
    "9574": [
        {
            "id": 8167,
            "name": "A Karadabadi"
        },
        {
            "id": 8168,
            "name": "Antarapada"
        },
        {
            "id": 8169,
            "name": "Arakhapur"
        },
        {
            "id": 8170,
            "name": "Balipadar"
        },
        {
            "id": 8171,
            "name": "Bhagabanpur"
        },
        {
            "id": 8172,
            "name": "Bhamasiali"
        },
        {
            "id": 8173,
            "name": "Biranchipur"
        },
        {
            "id": 8174,
            "name": "B Karadabadi"
        },
        {
            "id": 8175,
            "name": "Gaudiabarada"
        },
        {
            "id": 8176,
            "name": "Golabandha"
        },
        {
            "id": 8177,
            "name": "Golia"
        },
        {
            "id": 8178,
            "name": "Kalamba"
        },
        {
            "id": 8179,
            "name": "Karachuli"
        },
        {
            "id": 8180,
            "name": "Karasingi"
        },
        {
            "id": 8181,
            "name": "Kholakhali"
        },
        {
            "id": 8182,
            "name": "Manitara"
        },
        {
            "id": 8183,
            "name": "Motabadi"
        },
        {
            "id": 8184,
            "name": "Pangidi"
        },
        {
            "id": 8185,
            "name": "Pochalundi"
        },
        {
            "id": 8186,
            "name": "Sankuru"
        },
        {
            "id": 8187,
            "name": "Takarada"
        }
    ],
    "9575": [
        {
            "id": 8188,
            "name": "NAC"
        }
    ],
    "9576": [
        {
            "id": 8189,
            "name": "Bada Pankalabadi"
        },
        {
            "id": 8190,
            "name": "Balichai"
        },
        {
            "id": 8191,
            "name": "Banthapalli"
        },
        {
            "id": 8192,
            "name": "Bartini"
        },
        {
            "id": 8193,
            "name": "Belagaon"
        },
        {
            "id": 8194,
            "name": "Bhabarada"
        },
        {
            "id": 8195,
            "name": "Budheisuni"
        },
        {
            "id": 8196,
            "name": "Chirikipadasasan"
        },
        {
            "id": 8197,
            "name": "Dhunkapada"
        },
        {
            "id": 8198,
            "name": "Ghodapalana"
        },
        {
            "id": 8199,
            "name": "Gochabadi"
        },
        {
            "id": 8200,
            "name": "Hatiotta"
        },
        {
            "id": 8201,
            "name": "J.Dengapadar"
        },
        {
            "id": 8202,
            "name": "Jakara"
        },
        {
            "id": 8203,
            "name": "Kalamba"
        },
        {
            "id": 8204,
            "name": "Kanachai"
        },
        {
            "id": 8205,
            "name": "Kendubadi"
        },
        {
            "id": 8206,
            "name": "Khanduru"
        },
        {
            "id": 8207,
            "name": "Madhupalli"
        },
        {
            "id": 8208,
            "name": "Mala Tentulia"
        },
        {
            "id": 8209,
            "name": "Mandara"
        },
        {
            "id": 8210,
            "name": "Mathura"
        },
        {
            "id": 8211,
            "name": "Nimina"
        },
        {
            "id": 8212,
            "name": "Pandiripada"
        },
        {
            "id": 8213,
            "name": "Rumagada"
        },
        {
            "id": 8214,
            "name": "Sodaka"
        }
    ],
    "9577": [
        {
            "id": 8215,
            "name": "NAC"
        }
    ],
    "9578": [
        {
            "id": 8216,
            "name": "Baharapur"
        },
        {
            "id": 8217,
            "name": "Balarampur"
        },
        {
            "id": 8218,
            "name": "Baradabilli"
        },
        {
            "id": 8219,
            "name": "Dharakote"
        },
        {
            "id": 8220,
            "name": "Dhaugam"
        },
        {
            "id": 8221,
            "name": "Goladamadarapali"
        },
        {
            "id": 8222,
            "name": "Jagamohan"
        },
        {
            "id": 8223,
            "name": "Jahada"
        },
        {
            "id": 8224,
            "name": "Jhadabandha"
        },
        {
            "id": 8225,
            "name": "Jharapari"
        },
        {
            "id": 8226,
            "name": "Machakote"
        },
        {
            "id": 8227,
            "name": "Manikapur"
        },
        {
            "id": 8228,
            "name": "Mundamarai"
        },
        {
            "id": 8229,
            "name": "Olamba"
        },
        {
            "id": 8230,
            "name": "R.D.Palli"
        },
        {
            "id": 8231,
            "name": "Rugumu"
        },
        {
            "id": 8232,
            "name": "Saba"
        },
        {
            "id": 8233,
            "name": "Saradhapur"
        },
        {
            "id": 8234,
            "name": "Singipur"
        }
    ],
    "9579": [
        {
            "id": 8235,
            "name": "Adapada"
        },
        {
            "id": 8236,
            "name": "Ambagaon"
        },
        {
            "id": 8237,
            "name": "Chanameri"
        },
        {
            "id": 8238,
            "name": "Chudangapur"
        },
        {
            "id": 8239,
            "name": "Dayanidhipur"
        },
        {
            "id": 8240,
            "name": "Dengadi"
        },
        {
            "id": 8241,
            "name": "Errendra"
        },
        {
            "id": 8242,
            "name": "Gangapur"
        },
        {
            "id": 8243,
            "name": "Goutami"
        },
        {
            "id": 8244,
            "name": "Jeura"
        },
        {
            "id": 8245,
            "name": "Kalarapadar"
        },
        {
            "id": 8246,
            "name": "Kansamari"
        },
        {
            "id": 8247,
            "name": "Kaudia"
        },
        {
            "id": 8248,
            "name": "Khallingi"
        },
        {
            "id": 8249,
            "name": "Konkarada"
        },
        {
            "id": 8250,
            "name": "Moulabhanja"
        },
        {
            "id": 8251,
            "name": "Padmapur"
        },
        {
            "id": 8252,
            "name": "Paika Ramacandrapur"
        },
        {
            "id": 8253,
            "name": "Patapur"
        },
        {
            "id": 8254,
            "name": "Pattiguda"
        },
        {
            "id": 8255,
            "name": "Podamari"
        },
        {
            "id": 8256,
            "name": "Radhadeipur"
        },
        {
            "id": 8257,
            "name": "Sahasapur"
        },
        {
            "id": 8258,
            "name": "Sata Pentha Gopalpur"
        },
        {
            "id": 8259,
            "name": "Singipur"
        },
        {
            "id": 8260,
            "name": "Taptapani Govindapur"
        }
    ],
    "9580": [
        {
            "id": 8261,
            "name": "NAC"
        }
    ],
    "9581": [
        {
            "id": 8262,
            "name": "Ambapua"
        },
        {
            "id": 8263,
            "name": "Badaborasingi"
        },
        {
            "id": 8264,
            "name": "Badapada"
        },
        {
            "id": 8265,
            "name": "Banka"
        },
        {
            "id": 8266,
            "name": "Benipalli"
        },
        {
            "id": 8267,
            "name": "Dhumuchai"
        },
        {
            "id": 8268,
            "name": "Gangapur"
        },
        {
            "id": 8269,
            "name": "Gobara"
        },
        {
            "id": 8270,
            "name": "Inginathi"
        },
        {
            "id": 8271,
            "name": "Khairaputi"
        },
        {
            "id": 8272,
            "name": "Khetribarapur"
        },
        {
            "id": 8273,
            "name": "Mangalpur"
        },
        {
            "id": 8274,
            "name": "Nettanga"
        },
        {
            "id": 8275,
            "name": "Nuagon"
        },
        {
            "id": 8276,
            "name": "Pailipada"
        },
        {
            "id": 8277,
            "name": "Sasan"
        },
        {
            "id": 8278,
            "name": "Tanarada"
        },
        {
            "id": 8279,
            "name": "Udhura"
        }
    ],
    "9582": [
        {
            "id": 8280,
            "name": "Amurutulu"
        },
        {
            "id": 8281,
            "name": "Asurabandha"
        },
        {
            "id": 8282,
            "name": "Badabadangi"
        },
        {
            "id": 8283,
            "name": "Badagada"
        },
        {
            "id": 8284,
            "name": "Badagochha"
        },
        {
            "id": 8285,
            "name": "Bhagabanpur"
        },
        {
            "id": 8286,
            "name": "Borada"
        },
        {
            "id": 8287,
            "name": "Borasingi"
        },
        {
            "id": 8288,
            "name": "Ekalapur"
        },
        {
            "id": 8289,
            "name": "G.P.Sasan"
        },
        {
            "id": 8290,
            "name": "Gajalabadi"
        },
        {
            "id": 8291,
            "name": "Gangapur"
        },
        {
            "id": 8292,
            "name": "Genja"
        },
        {
            "id": 8293,
            "name": "Gochha"
        },
        {
            "id": 8294,
            "name": "Goudagotha"
        },
        {
            "id": 8295,
            "name": "Hinjalakuda"
        },
        {
            "id": 8296,
            "name": "Hukuma"
        },
        {
            "id": 8297,
            "name": "Kullangi"
        },
        {
            "id": 8298,
            "name": "Lathipada"
        },
        {
            "id": 8299,
            "name": "Merrikota"
        },
        {
            "id": 8300,
            "name": "Nuagada"
        },
        {
            "id": 8301,
            "name": "Palakata"
        },
        {
            "id": 8302,
            "name": "Raibandha"
        },
        {
            "id": 8303,
            "name": "Sarabadi"
        },
        {
            "id": 8304,
            "name": "Sidhapur"
        },
        {
            "id": 8305,
            "name": "Suramani"
        }
    ],
    "9583": [
        {
            "id": 8306,
            "name": "NAC"
        }
    ],
    "9584": [
        {
            "id": 8307,
            "name": "Alumeda"
        },
        {
            "id": 8308,
            "name": "Anko"
        },
        {
            "id": 8309,
            "name": "Bahabalpur"
        },
        {
            "id": 8310,
            "name": "Baincha"
        },
        {
            "id": 8311,
            "name": "Buanl"
        },
        {
            "id": 8312,
            "name": "Chandipur"
        },
        {
            "id": 8313,
            "name": "Chhanua"
        },
        {
            "id": 8314,
            "name": "Dubulagadi"
        },
        {
            "id": 8315,
            "name": "Gopinathpur"
        },
        {
            "id": 8316,
            "name": "Haldipada"
        },
        {
            "id": 8317,
            "name": "Kasafal"
        },
        {
            "id": 8318,
            "name": "Kasipada"
        },
        {
            "id": 8319,
            "name": "Kuradiha"
        },
        {
            "id": 8320,
            "name": "Nagram"
        },
        {
            "id": 8321,
            "name": "Odangi"
        },
        {
            "id": 8322,
            "name": "Olanda Saragan"
        },
        {
            "id": 8323,
            "name": "Panchapada"
        },
        {
            "id": 8324,
            "name": "Parikhi"
        },
        {
            "id": 8325,
            "name": "Patrapada"
        },
        {
            "id": 8326,
            "name": "Rainsuan"
        },
        {
            "id": 8327,
            "name": "Rasalapur"
        },
        {
            "id": 8328,
            "name": "Rupasamali Hasanpur"
        },
        {
            "id": 8329,
            "name": "Sartha"
        },
        {
            "id": 8330,
            "name": "Sindhia"
        },
        {
            "id": 8331,
            "name": "Srikona"
        },
        {
            "id": 8332,
            "name": "Srirampur"
        }
    ],
    "9585": [
        {
            "id": 8333,
            "name": "NAC"
        }
    ],
    "9586": [
        {
            "id": 8334,
            "name": "Aladiha"
        },
        {
            "id": 8335,
            "name": "Asti"
        },
        {
            "id": 8336,
            "name": "Balarampur"
        },
        {
            "id": 8337,
            "name": "Baliapal"
        },
        {
            "id": 8338,
            "name": "Balikuti"
        },
        {
            "id": 8339,
            "name": "Baniadiha"
        },
        {
            "id": 8340,
            "name": "Betagadia"
        },
        {
            "id": 8341,
            "name": "Bishnupur"
        },
        {
            "id": 8342,
            "name": "Bodas"
        },
        {
            "id": 8343,
            "name": "Bolang"
        },
        {
            "id": 8344,
            "name": "Chaumukh"
        },
        {
            "id": 8345,
            "name": "Dagara"
        },
        {
            "id": 8346,
            "name": "Deula"
        },
        {
            "id": 8347,
            "name": "Devog"
        },
        {
            "id": 8348,
            "name": "Ghantua"
        },
        {
            "id": 8349,
            "name": "Jagatipur"
        },
        {
            "id": 8350,
            "name": "Jambhirai"
        },
        {
            "id": 8351,
            "name": "Jamkunda"
        },
        {
            "id": 8352,
            "name": "Kumbhari"
        },
        {
            "id": 8353,
            "name": "Kunduli"
        },
        {
            "id": 8354,
            "name": "Madhupura"
        },
        {
            "id": 8355,
            "name": "Nikhira"
        },
        {
            "id": 8356,
            "name": "Nuagan"
        },
        {
            "id": 8357,
            "name": "Panchupali"
        },
        {
            "id": 8358,
            "name": "Pratappur"
        },
        {
            "id": 8359,
            "name": "Ratei"
        },
        {
            "id": 8360,
            "name": "Saudi"
        },
        {
            "id": 8361,
            "name": "Srirampur"
        }
    ],
    "9587": [
        {
            "id": 8362,
            "name": "Baharda"
        },
        {
            "id": 8363,
            "name": "Brahmanda"
        },
        {
            "id": 8364,
            "name": "Darada"
        },
        {
            "id": 8365,
            "name": "Gadapada"
        },
        {
            "id": 8366,
            "name": "Irda"
        },
        {
            "id": 8367,
            "name": "Kainagari"
        },
        {
            "id": 8368,
            "name": "Mathani"
        },
        {
            "id": 8369,
            "name": "Mukulisi"
        },
        {
            "id": 8370,
            "name": "Naikudi"
        },
        {
            "id": 8371,
            "name": "Routpada"
        },
        {
            "id": 8372,
            "name": "Sadanandapur"
        },
        {
            "id": 8373,
            "name": "Sahada"
        }
    ],
    "9588": [
        {
            "id": 8374,
            "name": "Analia"
        },
        {
            "id": 8375,
            "name": "Aruhabruti-i"
        },
        {
            "id": 8376,
            "name": "Bajitpur"
        },
        {
            "id": 8377,
            "name": "Balim"
        },
        {
            "id": 8378,
            "name": "Barbatia"
        },
        {
            "id": 8379,
            "name": "Baunsadiha"
        },
        {
            "id": 8380,
            "name": "Bhograi"
        },
        {
            "id": 8381,
            "name": "Dahunda"
        },
        {
            "id": 8382,
            "name": "Daruha"
        },
        {
            "id": 8383,
            "name": "Dehurda"
        },
        {
            "id": 8384,
            "name": "Deula"
        },
        {
            "id": 8385,
            "name": "Durpal"
        },
        {
            "id": 8386,
            "name": "Gabagan"
        },
        {
            "id": 8387,
            "name": "Gachidateghari"
        },
        {
            "id": 8388,
            "name": "Gopinathpur"
        },
        {
            "id": 8389,
            "name": "Gunasartha"
        },
        {
            "id": 8390,
            "name": "Guneibasan"
        },
        {
            "id": 8391,
            "name": "Huguli"
        },
        {
            "id": 8392,
            "name": "Jaganathpur"
        },
        {
            "id": 8393,
            "name": "Jalasoharia"
        },
        {
            "id": 8394,
            "name": "Jaleswarpur"
        },
        {
            "id": 8395,
            "name": "Jayrampur"
        },
        {
            "id": 8396,
            "name": "Kachuadi"
        },
        {
            "id": 8397,
            "name": "Kakhada"
        },
        {
            "id": 8398,
            "name": "Kanthibhaunri"
        },
        {
            "id": 8399,
            "name": "Kasaba Kamarda"
        },
        {
            "id": 8400,
            "name": "Kharid Pipal"
        },
        {
            "id": 8401,
            "name": "Kulida"
        },
        {
            "id": 8402,
            "name": "Kumbhiragadi"
        },
        {
            "id": 8403,
            "name": "Kumbhiramuli"
        },
        {
            "id": 8404,
            "name": "Kusuda"
        },
        {
            "id": 8405,
            "name": "Maheswarpur"
        },
        {
            "id": 8406,
            "name": "Mandarsahi"
        },
        {
            "id": 8407,
            "name": "Mohagob"
        },
        {
            "id": 8408,
            "name": "N.M.Padia"
        },
        {
            "id": 8409,
            "name": "Nachinda"
        },
        {
            "id": 8410,
            "name": "Nahara"
        },
        {
            "id": 8411,
            "name": "Nimatpur"
        },
        {
            "id": 8412,
            "name": "Panisandha"
        },
        {
            "id": 8413,
            "name": "Pasarbindha"
        },
        {
            "id": 8414,
            "name": "Putunia"
        },
        {
            "id": 8415,
            "name": "Rasolpur"
        },
        {
            "id": 8416,
            "name": "Sahabajipur"
        },
        {
            "id": 8417,
            "name": "Sankhari"
        },
        {
            "id": 8418,
            "name": "Sardhapur"
        },
        {
            "id": 8419,
            "name": "Sianrui"
        },
        {
            "id": 8420,
            "name": "Soharia"
        },
        {
            "id": 8421,
            "name": "Sultanpur"
        },
        {
            "id": 8422,
            "name": "Sunadharbasan"
        },
        {
            "id": 8423,
            "name": "T.Hazira"
        }
    ],
    "9589": [
        {
            "id": 8424,
            "name": "Chormara"
        },
        {
            "id": 8425,
            "name": "Kadreyan"
        },
        {
            "id": 8426,
            "name": "Korua"
        },
        {
            "id": 8427,
            "name": "Kulida"
        },
        {
            "id": 8428,
            "name": "Nabara"
        },
        {
            "id": 8429,
            "name": "Natakata"
        },
        {
            "id": 8430,
            "name": "Paunsakuli"
        },
        {
            "id": 8431,
            "name": "Purusottampur"
        },
        {
            "id": 8432,
            "name": "Putura"
        },
        {
            "id": 8433,
            "name": "Raghunathpur"
        },
        {
            "id": 8434,
            "name": "Santoshpur"
        },
        {
            "id": 8435,
            "name": "Singla"
        },
        {
            "id": 8436,
            "name": "Tadada"
        },
        {
            "id": 8437,
            "name": "Velora"
        }
    ],
    "9590": [
        {
            "id": 8438,
            "name": "Aruha"
        },
        {
            "id": 8439,
            "name": "Baiganbadia"
        },
        {
            "id": 8440,
            "name": "Baradiha"
        },
        {
            "id": 8441,
            "name": "Bartana"
        },
        {
            "id": 8442,
            "name": "Chamargan"
        },
        {
            "id": 8443,
            "name": "D.P.Pur"
        },
        {
            "id": 8444,
            "name": "Gabarghanta"
        },
        {
            "id": 8445,
            "name": "Gadasahi Baliapal"
        },
        {
            "id": 8446,
            "name": "Gopimohanpur"
        },
        {
            "id": 8447,
            "name": "Jaleswar(N)"
        },
        {
            "id": 8448,
            "name": "Jhardpipal"
        },
        {
            "id": 8449,
            "name": "K.M.Sahi"
        },
        {
            "id": 8450,
            "name": "Kalama"
        },
        {
            "id": 8451,
            "name": "Kaliko"
        },
        {
            "id": 8452,
            "name": "Khalina"
        },
        {
            "id": 8453,
            "name": "Khuard"
        },
        {
            "id": 8454,
            "name": "Khuluda"
        },
        {
            "id": 8455,
            "name": "Kotasahi"
        },
        {
            "id": 8456,
            "name": "Laxmannath"
        },
        {
            "id": 8457,
            "name": "Mahamadpatana"
        },
        {
            "id": 8458,
            "name": "Mankidia"
        },
        {
            "id": 8459,
            "name": "Nachhimpur"
        },
        {
            "id": 8460,
            "name": "Nampo"
        },
        {
            "id": 8461,
            "name": "Netua"
        },
        {
            "id": 8462,
            "name": "Olmara"
        },
        {
            "id": 8463,
            "name": "Paikasida"
        },
        {
            "id": 8464,
            "name": "Paschimbad"
        },
        {
            "id": 8465,
            "name": "R.R.Pur"
        },
        {
            "id": 8466,
            "name": "Raibania"
        },
        {
            "id": 8467,
            "name": "Rajpur"
        },
        {
            "id": 8468,
            "name": "Sampatia"
        },
        {
            "id": 8469,
            "name": "Sardharbandha"
        },
        {
            "id": 8470,
            "name": "Shyamnagar"
        },
        {
            "id": 8471,
            "name": "Sikharpur"
        },
        {
            "id": 8472,
            "name": "Srirampur"
        },
        {
            "id": 8473,
            "name": "Sugo"
        }
    ],
    "9591": [
        {
            "id": 8474,
            "name": "NAC"
        }
    ],
    "9592": [
        {
            "id": 8475,
            "name": "Chakajaganathpur"
        },
        {
            "id": 8476,
            "name": "Kasabajaypur"
        },
        {
            "id": 8477,
            "name": "Khantapada"
        },
        {
            "id": 8478,
            "name": "Panapana"
        },
        {
            "id": 8479,
            "name": "Patharpentha"
        }
    ],
    "9593": [
        {
            "id": 8480,
            "name": "Ajodhya"
        },
        {
            "id": 8481,
            "name": "Arbandha"
        },
        {
            "id": 8482,
            "name": "Begunia"
        },
        {
            "id": 8483,
            "name": "Berhampur"
        },
        {
            "id": 8484,
            "name": "Bhaunriabad"
        },
        {
            "id": 8485,
            "name": "Chhatrapur"
        },
        {
            "id": 8486,
            "name": "Dharampur"
        },
        {
            "id": 8487,
            "name": "Dhobasila"
        },
        {
            "id": 8488,
            "name": "Garadihi"
        },
        {
            "id": 8489,
            "name": "Jadida"
        },
        {
            "id": 8490,
            "name": "Jamudhia"
        },
        {
            "id": 8491,
            "name": "K.C.Pur"
        },
        {
            "id": 8492,
            "name": "K.P.Lakharaj"
        },
        {
            "id": 8493,
            "name": "Kansa"
        },
        {
            "id": 8494,
            "name": "Machhua"
        },
        {
            "id": 8495,
            "name": "Mahisapata"
        },
        {
            "id": 8496,
            "name": "Matiali"
        },
        {
            "id": 8497,
            "name": "Naranpur"
        },
        {
            "id": 8498,
            "name": "Narasingpur"
        },
        {
            "id": 8499,
            "name": "Pithahata"
        },
        {
            "id": 8500,
            "name": "Radhakishorepur"
        },
        {
            "id": 8501,
            "name": "Sajanagarh"
        },
        {
            "id": 8502,
            "name": "Siadimal"
        },
        {
            "id": 8503,
            "name": "Telipal"
        },
        {
            "id": 8504,
            "name": "Tentulia"
        }
    ],
    "9594": [
        {
            "id": 8505,
            "name": "NAC"
        }
    ],
    "9595": [
        {
            "id": 8506,
            "name": "Aghirapada"
        },
        {
            "id": 8507,
            "name": "Badapokhari"
        },
        {
            "id": 8508,
            "name": "Baunsabania"
        },
        {
            "id": 8509,
            "name": "D.N.Pur"
        },
        {
            "id": 8510,
            "name": "Fatepur"
        },
        {
            "id": 8511,
            "name": "Gadasahi"
        },
        {
            "id": 8512,
            "name": "Iswarpur"
        },
        {
            "id": 8513,
            "name": "Kandagaradi"
        },
        {
            "id": 8514,
            "name": "R.R.Pur"
        },
        {
            "id": 8515,
            "name": "S.S.Pur"
        },
        {
            "id": 8516,
            "name": "Talakia"
        }
    ],
    "9596": [
        {
            "id": 8517,
            "name": "Bardhanpur"
        },
        {
            "id": 8518,
            "name": "Genguti"
        },
        {
            "id": 8519,
            "name": "Gudu"
        },
        {
            "id": 8520,
            "name": "Hidigan"
        },
        {
            "id": 8521,
            "name": "Jayadev Kasaba"
        },
        {
            "id": 8522,
            "name": "Khannagar"
        },
        {
            "id": 8523,
            "name": "Padampur"
        },
        {
            "id": 8524,
            "name": "Ranasahi"
        },
        {
            "id": 8525,
            "name": "Rasalapur"
        },
        {
            "id": 8526,
            "name": "Sargan"
        },
        {
            "id": 8527,
            "name": "Sasanga"
        }
    ],
    "9597": [
        {
            "id": 8528,
            "name": "Badapal"
        },
        {
            "id": 8529,
            "name": "Balia"
        },
        {
            "id": 8530,
            "name": "Barunsing"
        },
        {
            "id": 8531,
            "name": "Bhimpura"
        },
        {
            "id": 8532,
            "name": "Biruan"
        },
        {
            "id": 8533,
            "name": "Chasakhanda"
        },
        {
            "id": 8534,
            "name": "Dahapada"
        },
        {
            "id": 8535,
            "name": "Durgadevi"
        },
        {
            "id": 8536,
            "name": "Gandarda"
        },
        {
            "id": 8537,
            "name": "Ganeshwarpur"
        },
        {
            "id": 8538,
            "name": "Ganipur"
        },
        {
            "id": 8539,
            "name": "Gududa"
        },
        {
            "id": 8540,
            "name": "Haripur"
        },
        {
            "id": 8541,
            "name": "Inchudi"
        },
        {
            "id": 8542,
            "name": "Kalayanpur"
        },
        {
            "id": 8543,
            "name": "Karanjia"
        },
        {
            "id": 8544,
            "name": "Kharid Mukhura"
        },
        {
            "id": 8545,
            "name": "Kuligan"
        },
        {
            "id": 8546,
            "name": "Kuruda"
        },
        {
            "id": 8547,
            "name": "Maharajpur"
        },
        {
            "id": 8548,
            "name": "Mandarpur"
        },
        {
            "id": 8549,
            "name": "Mangalpur"
        },
        {
            "id": 8550,
            "name": "Naraharipur"
        },
        {
            "id": 8551,
            "name": "Nizampur"
        },
        {
            "id": 8552,
            "name": "Nuagan"
        },
        {
            "id": 8553,
            "name": "Nuapadhi"
        },
        {
            "id": 8554,
            "name": "Patripal"
        },
        {
            "id": 8555,
            "name": "Remuna"
        },
        {
            "id": 8556,
            "name": "Saraswatipur"
        },
        {
            "id": 8557,
            "name": "Sergarh"
        },
        {
            "id": 8558,
            "name": "Srijang"
        },
        {
            "id": 8559,
            "name": "Srikrushnapur"
        },
        {
            "id": 8560,
            "name": "Sutei"
        },
        {
            "id": 8561,
            "name": "Talapada"
        },
        {
            "id": 8562,
            "name": "Tentulida"
        },
        {
            "id": 8563,
            "name": "Tundra"
        }
    ],
    "9598": [
        {
            "id": 8564,
            "name": "Achyutipur"
        },
        {
            "id": 8565,
            "name": "Amaria Agnipur"
        },
        {
            "id": 8566,
            "name": "Antara"
        },
        {
            "id": 8567,
            "name": "Badajaypur"
        },
        {
            "id": 8568,
            "name": "Badanuagan"
        },
        {
            "id": 8569,
            "name": "Barada"
        },
        {
            "id": 8570,
            "name": "Bartana"
        },
        {
            "id": 8571,
            "name": "Baunsagadia"
        },
        {
            "id": 8572,
            "name": "Dagarpada"
        },
        {
            "id": 8573,
            "name": "Dalanga-ii"
        },
        {
            "id": 8574,
            "name": "Dungura"
        },
        {
            "id": 8575,
            "name": "Gadapokhari"
        },
        {
            "id": 8576,
            "name": "Gangadhuli"
        },
        {
            "id": 8577,
            "name": "Garsankha"
        },
        {
            "id": 8578,
            "name": "Goliha"
        },
        {
            "id": 8579,
            "name": "Govindapur"
        },
        {
            "id": 8580,
            "name": "Haripur"
        },
        {
            "id": 8581,
            "name": "Jalada"
        },
        {
            "id": 8582,
            "name": "Jhinkiria"
        },
        {
            "id": 8583,
            "name": "Kaithagadia"
        },
        {
            "id": 8584,
            "name": "Khaira"
        },
        {
            "id": 8585,
            "name": "Kupari"
        },
        {
            "id": 8586,
            "name": "Kurunta"
        },
        {
            "id": 8587,
            "name": "Mahatipur"
        },
        {
            "id": 8588,
            "name": "Makhanpur"
        },
        {
            "id": 8589,
            "name": "Manitri"
        },
        {
            "id": 8590,
            "name": "Nahanga"
        },
        {
            "id": 8591,
            "name": "Nandoor"
        },
        {
            "id": 8592,
            "name": "Nayakhandi"
        },
        {
            "id": 8593,
            "name": "Palasa"
        },
        {
            "id": 8594,
            "name": "Rapeya"
        },
        {
            "id": 8595,
            "name": "Ratina"
        },
        {
            "id": 8596,
            "name": "S.S.Pur"
        },
        {
            "id": 8597,
            "name": "Sardang"
        },
        {
            "id": 8598,
            "name": "Sarkona"
        },
        {
            "id": 8599,
            "name": "Sarugan"
        },
        {
            "id": 8600,
            "name": "Sundiragandibed"
        }
    ],
    "9599": [
        {
            "id": 8601,
            "name": "Abjhuna"
        },
        {
            "id": 8602,
            "name": "Ada"
        },
        {
            "id": 8603,
            "name": "Anandapur"
        },
        {
            "id": 8604,
            "name": "Andrei"
        },
        {
            "id": 8605,
            "name": "Bangalpur"
        },
        {
            "id": 8606,
            "name": "Bangar Khandigadia"
        },
        {
            "id": 8607,
            "name": "Bari"
        },
        {
            "id": 8608,
            "name": "Bati"
        },
        {
            "id": 8609,
            "name": "Bauripada"
        },
        {
            "id": 8610,
            "name": "Chalunigaon"
        },
        {
            "id": 8611,
            "name": "Iswarpur"
        },
        {
            "id": 8612,
            "name": "Jamujhadi"
        },
        {
            "id": 8613,
            "name": "Kanchapada"
        },
        {
            "id": 8614,
            "name": "Kanheibindha"
        },
        {
            "id": 8615,
            "name": "Khirkona"
        },
        {
            "id": 8616,
            "name": "Maitapur"
        },
        {
            "id": 8617,
            "name": "Markona"
        },
        {
            "id": 8618,
            "name": "Muruna"
        },
        {
            "id": 8619,
            "name": "Pahitarana"
        },
        {
            "id": 8620,
            "name": "Purusottampur"
        },
        {
            "id": 8621,
            "name": "S.S. Pur"
        },
        {
            "id": 8622,
            "name": "Sabanga"
        }
    ],
    "9600": [
        {
            "id": 8623,
            "name": "Anji"
        },
        {
            "id": 8624,
            "name": "Aruhabad"
        },
        {
            "id": 8625,
            "name": "Avana"
        },
        {
            "id": 8626,
            "name": "Bahanaga"
        },
        {
            "id": 8627,
            "name": "Baripada"
        },
        {
            "id": 8628,
            "name": "Bishnupur"
        },
        {
            "id": 8629,
            "name": "Chittol"
        },
        {
            "id": 8630,
            "name": "Dandaharipur"
        },
        {
            "id": 8631,
            "name": "Gopalpur"
        },
        {
            "id": 8632,
            "name": "Kalyani"
        },
        {
            "id": 8633,
            "name": "Kharasahapur"
        },
        {
            "id": 8634,
            "name": "Kochiakoili"
        },
        {
            "id": 8635,
            "name": "Kuruda"
        },
        {
            "id": 8636,
            "name": "Nuapur"
        },
        {
            "id": 8637,
            "name": "Odasal"
        },
        {
            "id": 8638,
            "name": "Pandasuni"
        },
        {
            "id": 8639,
            "name": "Sahaspura"
        },
        {
            "id": 8640,
            "name": "Soud"
        },
        {
            "id": 8641,
            "name": "Talakurunia"
        }
    ],
    "9601": [
        {
            "id": 8642,
            "name": "Anantapur"
        },
        {
            "id": 8643,
            "name": "Attapur"
        },
        {
            "id": 8644,
            "name": "Balanga"
        },
        {
            "id": 8645,
            "name": "Dahisada"
        },
        {
            "id": 8646,
            "name": "Gopinathpur"
        },
        {
            "id": 8647,
            "name": "Gud"
        },
        {
            "id": 8648,
            "name": "Jadida"
        },
        {
            "id": 8649,
            "name": "Kedarpur"
        },
        {
            "id": 8650,
            "name": "Kesharpur"
        },
        {
            "id": 8651,
            "name": "Madhusudanpur"
        },
        {
            "id": 8652,
            "name": "Manatri"
        },
        {
            "id": 8653,
            "name": "Manipur"
        },
        {
            "id": 8654,
            "name": "Muhumuhan"
        },
        {
            "id": 8655,
            "name": "Mulisingh"
        },
        {
            "id": 8656,
            "name": "Nadigan"
        },
        {
            "id": 8657,
            "name": "Natapada"
        },
        {
            "id": 8658,
            "name": "Nischintapur"
        },
        {
            "id": 8659,
            "name": "Pakhar"
        },
        {
            "id": 8660,
            "name": "Sabira"
        },
        {
            "id": 8661,
            "name": "Sajanpur"
        },
        {
            "id": 8662,
            "name": "Sarasankha"
        },
        {
            "id": 8663,
            "name": "Singakhunta"
        },
        {
            "id": 8664,
            "name": "Talanagar"
        },
        {
            "id": 8665,
            "name": "Tentei"
        }
    ],
    "9602": [
        {
            "id": 8666,
            "name": "NAC"
        }
    ],
    "9603": [
        {
            "id": 8667,
            "name": "Adava"
        },
        {
            "id": 8668,
            "name": "Alliganda"
        },
        {
            "id": 8669,
            "name": "Antaraba"
        },
        {
            "id": 8670,
            "name": "Badakhani"
        },
        {
            "id": 8671,
            "name": "Badasindhiba"
        },
        {
            "id": 8672,
            "name": "Badeguda"
        },
        {
            "id": 8673,
            "name": "Baghamari"
        },
        {
            "id": 8674,
            "name": "Birikote"
        },
        {
            "id": 8675,
            "name": "Chandiput"
        },
        {
            "id": 8676,
            "name": "Chandragiri"
        },
        {
            "id": 8677,
            "name": "Chudangapur"
        },
        {
            "id": 8678,
            "name": "Damadua"
        },
        {
            "id": 8679,
            "name": "Dambaguda"
        },
        {
            "id": 8680,
            "name": "Dengaskhal"
        },
        {
            "id": 8681,
            "name": "Dhadiamba"
        },
        {
            "id": 8682,
            "name": "Dhepaguda"
        },
        {
            "id": 8683,
            "name": "Garadama"
        },
        {
            "id": 8684,
            "name": "Govindapur"
        },
        {
            "id": 8685,
            "name": "Guluba"
        },
        {
            "id": 8686,
            "name": "Gurujhalli"
        },
        {
            "id": 8687,
            "name": "Jarrow"
        },
        {
            "id": 8688,
            "name": "Jeerango"
        },
        {
            "id": 8689,
            "name": "Juba"
        },
        {
            "id": 8690,
            "name": "Karachabadi"
        },
        {
            "id": 8691,
            "name": "Kattama"
        },
        {
            "id": 8692,
            "name": "Kesriguda"
        },
        {
            "id": 8693,
            "name": "Khariguda"
        },
        {
            "id": 8694,
            "name": "Liliguda"
        },
        {
            "id": 8695,
            "name": "Lobarsing"
        },
        {
            "id": 8696,
            "name": "Luhagudi"
        },
        {
            "id": 8697,
            "name": "Malasapadar"
        },
        {
            "id": 8698,
            "name": "Mandimara"
        },
        {
            "id": 8699,
            "name": "Manikapur"
        },
        {
            "id": 8700,
            "name": "Merapalli"
        },
        {
            "id": 8701,
            "name": "Mohana"
        },
        {
            "id": 8702,
            "name": "Paniganda"
        },
        {
            "id": 8703,
            "name": "Pindiki"
        },
        {
            "id": 8704,
            "name": "Raipanka"
        },
        {
            "id": 8705,
            "name": "Sikulipadara"
        }
    ],
    "9604": [
        {
            "id": 8706,
            "name": "Anuguru"
        },
        {
            "id": 8707,
            "name": "Attarasingi"
        },
        {
            "id": 8708,
            "name": "Badapada"
        },
        {
            "id": 8709,
            "name": "Betarsingi"
        },
        {
            "id": 8710,
            "name": "K.JHALARSING"
        },
        {
            "id": 8711,
            "name": "Keradang"
        },
        {
            "id": 8712,
            "name": "Khajuripada"
        },
        {
            "id": 8713,
            "name": "Kirama"
        },
        {
            "id": 8714,
            "name": "Luhangar"
        },
        {
            "id": 8715,
            "name": "Nuagada"
        },
        {
            "id": 8716,
            "name": "PAIKA ANTARADA"
        },
        {
            "id": 8717,
            "name": "Parimala"
        },
        {
            "id": 8718,
            "name": "Putrupada"
        },
        {
            "id": 8719,
            "name": "Sambalpur"
        },
        {
            "id": 8720,
            "name": "Sundardang"
        },
        {
            "id": 8721,
            "name": "Tabarada"
        },
        {
            "id": 8722,
            "name": "Tangili"
        },
        {
            "id": 8723,
            "name": "Titisingi"
        },
        {
            "id": 8724,
            "name": "Udayapur"
        }
    ],
    "9605": [
        {
            "id": 8725,
            "name": "Abarsing"
        },
        {
            "id": 8726,
            "name": "Anagha"
        },
        {
            "id": 8727,
            "name": "Bastriguda"
        },
        {
            "id": 8728,
            "name": "Chellagada"
        },
        {
            "id": 8729,
            "name": "Chheligada"
        },
        {
            "id": 8730,
            "name": "Dabiriguda"
        },
        {
            "id": 8731,
            "name": "Mahendragada"
        },
        {
            "id": 8732,
            "name": "Mangarajpur"
        },
        {
            "id": 8733,
            "name": "Parisal"
        },
        {
            "id": 8734,
            "name": "Puranipani"
        },
        {
            "id": 8735,
            "name": "R.UDAYAGIRI"
        },
        {
            "id": 8736,
            "name": "Ramagiri"
        },
        {
            "id": 8737,
            "name": "Randiva"
        },
        {
            "id": 8738,
            "name": "Sabarapalli"
        },
        {
            "id": 8739,
            "name": "Sialilati"
        },
        {
            "id": 8740,
            "name": "Subalada"
        },
        {
            "id": 8741,
            "name": "Tabarsingi"
        }
    ],
    "9606": [
        {
            "id": 8742,
            "name": "Ameda"
        },
        {
            "id": 8743,
            "name": "Dambala"
        },
        {
            "id": 8744,
            "name": "Engrasing"
        },
        {
            "id": 8745,
            "name": "Gandahati"
        },
        {
            "id": 8746,
            "name": "Gangabada"
        },
        {
            "id": 8747,
            "name": "Jalango"
        },
        {
            "id": 8748,
            "name": "Jeerango"
        },
        {
            "id": 8749,
            "name": "Karadasingi"
        },
        {
            "id": 8750,
            "name": "Kerandi"
        },
        {
            "id": 8751,
            "name": "Koinpur"
        },
        {
            "id": 8752,
            "name": "Kumolasing"
        },
        {
            "id": 8753,
            "name": "Lailai"
        },
        {
            "id": 8754,
            "name": "Laxmipur"
        },
        {
            "id": 8755,
            "name": "Loba"
        },
        {
            "id": 8756,
            "name": "Marlaba"
        },
        {
            "id": 8757,
            "name": "Narayanpur"
        },
        {
            "id": 8758,
            "name": "Putara"
        },
        {
            "id": 8759,
            "name": "Rayagada"
        },
        {
            "id": 8760,
            "name": "Sanatundi"
        },
        {
            "id": 8761,
            "name": "SOURA KARADASINGI"
        },
        {
            "id": 8762,
            "name": "Talamunda"
        }
    ],
    "9607": [
        {
            "id": 8763,
            "name": "Ajayagada"
        },
        {
            "id": 8764,
            "name": "Badakalakote"
        },
        {
            "id": 8765,
            "name": "Baijhal"
        },
        {
            "id": 8766,
            "name": "Bhubani"
        },
        {
            "id": 8767,
            "name": "Brushava"
        },
        {
            "id": 8768,
            "name": "Dambada"
        },
        {
            "id": 8769,
            "name": "Gaiba"
        },
        {
            "id": 8770,
            "name": "Gumma"
        },
        {
            "id": 8771,
            "name": "Jeeba"
        },
        {
            "id": 8772,
            "name": "Jhami"
        },
        {
            "id": 8773,
            "name": "Kujasingi"
        },
        {
            "id": 8774,
            "name": "Munisingi"
        },
        {
            "id": 8775,
            "name": "Namangada"
        },
        {
            "id": 8776,
            "name": "Porida"
        },
        {
            "id": 8777,
            "name": "Ragidi"
        },
        {
            "id": 8778,
            "name": "Serango"
        },
        {
            "id": 8779,
            "name": "Tarangada"
        },
        {
            "id": 8780,
            "name": "Tarava"
        },
        {
            "id": 8781,
            "name": "Tumula"
        },
        {
            "id": 8782,
            "name": "Ukhara"
        }
    ],
    "9608": [
        {
            "id": 8783,
            "name": "Allada"
        },
        {
            "id": 8784,
            "name": "Budura"
        },
        {
            "id": 8785,
            "name": "Goribandha"
        },
        {
            "id": 8786,
            "name": "Hadubhangi"
        },
        {
            "id": 8787,
            "name": "K.SITAPUR"
        },
        {
            "id": 8788,
            "name": "Khandava"
        },
        {
            "id": 8789,
            "name": "Kharada"
        },
        {
            "id": 8790,
            "name": "Kidigam"
        },
        {
            "id": 8791,
            "name": "Parthada"
        },
        {
            "id": 8792,
            "name": "Ranipeta"
        },
        {
            "id": 8793,
            "name": "Siali"
        },
        {
            "id": 8794,
            "name": "Sidhamadanga"
        }
    ],
    "9609": [
        {
            "id": 8795,
            "name": "NAC"
        }
    ],
    "9610": [
        {
            "id": 8796,
            "name": "NAC"
        }
    ],
    "9611": [
        {
            "id": 8797,
            "name": "Agarakhandi"
        },
        {
            "id": 8798,
            "name": "Bagasala"
        },
        {
            "id": 8799,
            "name": "Bhuskudi"
        },
        {
            "id": 8800,
            "name": "Bomika"
        },
        {
            "id": 8801,
            "name": "Garabandha"
        },
        {
            "id": 8802,
            "name": "Ghoroni"
        },
        {
            "id": 8803,
            "name": "Gosani"
        },
        {
            "id": 8804,
            "name": "Gurandi"
        },
        {
            "id": 8805,
            "name": "Kantragada"
        },
        {
            "id": 8806,
            "name": "Katalakaitha"
        },
        {
            "id": 8807,
            "name": "Kerandi"
        },
        {
            "id": 8808,
            "name": "Kharasanda"
        },
        {
            "id": 8809,
            "name": "Labanyagada"
        },
        {
            "id": 8810,
            "name": "Machamara"
        },
        {
            "id": 8811,
            "name": "Madhusudhanpur"
        },
        {
            "id": 8812,
            "name": "Nadakaturu"
        },
        {
            "id": 8813,
            "name": "Rampa"
        },
        {
            "id": 8814,
            "name": "Sardhapur"
        },
        {
            "id": 8815,
            "name": "Sobara"
        },
        {
            "id": 8816,
            "name": "Tatipati"
        },
        {
            "id": 8817,
            "name": "Upalada"
        }
    ],
    "9612": [
        {
            "id": 8818,
            "name": "Babupali"
        },
        {
            "id": 8819,
            "name": "Bankigiridi"
        },
        {
            "id": 8820,
            "name": "Baunsuni"
        },
        {
            "id": 8821,
            "name": "Bhandar"
        },
        {
            "id": 8822,
            "name": "Charda"
        },
        {
            "id": 8823,
            "name": "Kaintara"
        },
        {
            "id": 8824,
            "name": "Kuhibahal"
        },
        {
            "id": 8825,
            "name": "Mahadevpali"
        },
        {
            "id": 8826,
            "name": "Meghala"
        },
        {
            "id": 8827,
            "name": "Sankara"
        },
        {
            "id": 8828,
            "name": "Seledi"
        },
        {
            "id": 8829,
            "name": "Silati"
        },
        {
            "id": 8830,
            "name": "Sindurpur"
        },
        {
            "id": 8831,
            "name": "Singhjuba"
        }
    ],
    "9613": [
        {
            "id": 8832,
            "name": "NAC"
        }
    ],
    "9614": [
        {
            "id": 8833,
            "name": "Bagbar"
        },
        {
            "id": 8834,
            "name": "Bahalpadar"
        },
        {
            "id": 8835,
            "name": "Champamal"
        },
        {
            "id": 8836,
            "name": "Durjantaila"
        },
        {
            "id": 8837,
            "name": "Hilunga"
        },
        {
            "id": 8838,
            "name": "Jatesingha"
        },
        {
            "id": 8839,
            "name": "Kamira"
        },
        {
            "id": 8840,
            "name": "Kenjhariapalli"
        },
        {
            "id": 8841,
            "name": "Khandahata"
        },
        {
            "id": 8842,
            "name": "Luturpank"
        },
        {
            "id": 8843,
            "name": "Mursundhi"
        },
        {
            "id": 8844,
            "name": "Pitamahul"
        },
        {
            "id": 8845,
            "name": "Sangrampur"
        },
        {
            "id": 8846,
            "name": "Subalaya"
        },
        {
            "id": 8847,
            "name": "Ufula"
        }
    ],
    "9615": [
        {
            "id": 8848,
            "name": "Bisipada"
        },
        {
            "id": 8849,
            "name": "Chadeipanka"
        },
        {
            "id": 8850,
            "name": "Jaloi"
        },
        {
            "id": 8851,
            "name": "Jaunrabhaunra"
        },
        {
            "id": 8852,
            "name": "Kadodar"
        },
        {
            "id": 8853,
            "name": "Kalapathar"
        },
        {
            "id": 8854,
            "name": "Khemaloi"
        },
        {
            "id": 8855,
            "name": "Kotasamalai"
        },
        {
            "id": 8856,
            "name": "Lastala"
        },
        {
            "id": 8857,
            "name": "Mahada"
        },
        {
            "id": 8858,
            "name": "Mundoghat"
        },
        {
            "id": 8859,
            "name": "Nimna"
        },
        {
            "id": 8860,
            "name": "Panchamahala"
        },
        {
            "id": 8861,
            "name": "Roxa"
        },
        {
            "id": 8862,
            "name": "Sindhol"
        },
        {
            "id": 8863,
            "name": "S Patrapali"
        },
        {
            "id": 8864,
            "name": "Ulunda"
        }
    ],
    "9616": [
        {
            "id": 8865,
            "name": "Julunda"
        },
        {
            "id": 8866,
            "name": "Mahada"
        }
    ],
    "9617": [
        {
            "id": 8867,
            "name": "Andharibanji"
        },
        {
            "id": 8868,
            "name": "Badkarley"
        },
        {
            "id": 8869,
            "name": "Baidupali"
        },
        {
            "id": 8870,
            "name": "Bandhapali"
        },
        {
            "id": 8871,
            "name": "Bankipali"
        },
        {
            "id": 8872,
            "name": "Beheramal"
        },
        {
            "id": 8873,
            "name": "Bhimtikira"
        },
        {
            "id": 8874,
            "name": "Cherupali"
        },
        {
            "id": 8875,
            "name": "Chinajuri"
        },
        {
            "id": 8876,
            "name": "Digsira"
        },
        {
            "id": 8877,
            "name": "Dunguripali"
        },
        {
            "id": 8878,
            "name": "Gajabandha"
        },
        {
            "id": 8879,
            "name": "Ichhapur"
        },
        {
            "id": 8880,
            "name": "Kapasira"
        },
        {
            "id": 8881,
            "name": "Karlajuri"
        },
        {
            "id": 8882,
            "name": "Lingimarni"
        },
        {
            "id": 8883,
            "name": "Mayabarha"
        },
        {
            "id": 8884,
            "name": "Pandakital-naikenpali"
        },
        {
            "id": 8885,
            "name": "Rampur"
        },
        {
            "id": 8886,
            "name": "Sahala"
        },
        {
            "id": 8887,
            "name": "Samalaichuan"
        },
        {
            "id": 8888,
            "name": "Sargul"
        },
        {
            "id": 8889,
            "name": "Sukha"
        },
        {
            "id": 8890,
            "name": "Sunapali"
        },
        {
            "id": 8891,
            "name": "Tamamura"
        },
        {
            "id": 8892,
            "name": "Tangarkarle"
        }
    ],
    "9618": [
        {
            "id": 8893,
            "name": "Balladi"
        },
        {
            "id": 8894,
            "name": "Bisimunda"
        },
        {
            "id": 8895,
            "name": "Chhokermal"
        },
        {
            "id": 8896,
            "name": "Haradakhol"
        },
        {
            "id": 8897,
            "name": "Janmura"
        },
        {
            "id": 8898,
            "name": "Kalapathar"
        },
        {
            "id": 8899,
            "name": "Khaliapali"
        },
        {
            "id": 8900,
            "name": "Khari"
        },
        {
            "id": 8901,
            "name": "Kharjhura"
        },
        {
            "id": 8902,
            "name": "Lachhipur"
        },
        {
            "id": 8903,
            "name": "Mallikmunda"
        },
        {
            "id": 8904,
            "name": "Mayurudan"
        },
        {
            "id": 8905,
            "name": "Narayanpur"
        },
        {
            "id": 8906,
            "name": "Rengali"
        }
    ],
    "9619": [
        {
            "id": 8907,
            "name": "NAC"
        }
    ],
    "9620": [
        {
            "id": 8908,
            "name": "Badvairo"
        },
        {
            "id": 8909,
            "name": "Baghia"
        },
        {
            "id": 8910,
            "name": "Balikhamar"
        },
        {
            "id": 8911,
            "name": "Brahmani"
        },
        {
            "id": 8912,
            "name": "Charvata"
        },
        {
            "id": 8913,
            "name": "Deulpadar"
        },
        {
            "id": 8914,
            "name": "Dubula"
        },
        {
            "id": 8915,
            "name": "Jarajaring"
        },
        {
            "id": 8916,
            "name": "Jhartarbha"
        },
        {
            "id": 8917,
            "name": "Kamasara"
        },
        {
            "id": 8918,
            "name": "Katapali"
        },
        {
            "id": 8919,
            "name": "Kumbharmunda"
        },
        {
            "id": 8920,
            "name": "Maraduguchha"
        },
        {
            "id": 8921,
            "name": "Menda"
        },
        {
            "id": 8922,
            "name": "Paikbahal"
        },
        {
            "id": 8923,
            "name": "Pua"
        },
        {
            "id": 8924,
            "name": "Ranisarda"
        },
        {
            "id": 8925,
            "name": "Sargaj"
        },
        {
            "id": 8926,
            "name": "Sibtula"
        },
        {
            "id": 8927,
            "name": "Singhari"
        },
        {
            "id": 8928,
            "name": "Tulunda"
        }
    ],
    "9621": [
        {
            "id": 8929,
            "name": "NAC"
        }
    ],
    "9622": [
        {
            "id": 8930,
            "name": "Babunikitimal"
        },
        {
            "id": 8931,
            "name": "Baunsalaga"
        },
        {
            "id": 8932,
            "name": "Garposh"
        },
        {
            "id": 8933,
            "name": "Govindpur"
        },
        {
            "id": 8934,
            "name": "Jarabaga"
        },
        {
            "id": 8935,
            "name": "Kabribahal"
        },
        {
            "id": 8936,
            "name": "Keseibahal"
        },
        {
            "id": 8937,
            "name": "Kinabaga"
        },
        {
            "id": 8938,
            "name": "Kutarimal"
        },
        {
            "id": 8939,
            "name": "Lariapali"
        },
        {
            "id": 8940,
            "name": "Mahulpali"
        },
        {
            "id": 8941,
            "name": "Pindapathar"
        },
        {
            "id": 8942,
            "name": "Rangiatikira"
        },
        {
            "id": 8943,
            "name": "Rebga"
        },
        {
            "id": 8944,
            "name": "Sagara"
        },
        {
            "id": 8945,
            "name": "Turei"
        },
        {
            "id": 8946,
            "name": "Uchhakapat"
        }
    ],
    "9623": [
        {
            "id": 8947,
            "name": "Ardabahal"
        },
        {
            "id": 8948,
            "name": "Badmal"
        },
        {
            "id": 8949,
            "name": "Badrama"
        },
        {
            "id": 8950,
            "name": "Bhojpur"
        },
        {
            "id": 8951,
            "name": "Chakuliabahal"
        },
        {
            "id": 8952,
            "name": "Dhudipali"
        },
        {
            "id": 8953,
            "name": "Dimirimunda"
        },
        {
            "id": 8954,
            "name": "Fasimal"
        },
        {
            "id": 8955,
            "name": "Gunduruchuan"
        },
        {
            "id": 8956,
            "name": "Jamankira"
        },
        {
            "id": 8957,
            "name": "Kasada"
        },
        {
            "id": 8958,
            "name": "Katangapani"
        },
        {
            "id": 8959,
            "name": "Kenadhipa"
        },
        {
            "id": 8960,
            "name": "Kharsanmal"
        },
        {
            "id": 8961,
            "name": "Kulundi"
        },
        {
            "id": 8962,
            "name": "Mahada"
        },
        {
            "id": 8963,
            "name": "Mundhenpali"
        },
        {
            "id": 8964,
            "name": "Sarda"
        },
        {
            "id": 8965,
            "name": "Sirid"
        },
        {
            "id": 8966,
            "name": "Subarnapali"
        },
        {
            "id": 8967,
            "name": "Tikiba"
        },
        {
            "id": 8968,
            "name": "Tikilipada"
        }
    ],
    "9624": [
        {
            "id": 8969,
            "name": "Ardabahal"
        },
        {
            "id": 8970,
            "name": "Bankey"
        },
        {
            "id": 8971,
            "name": "Bauriguda"
        },
        {
            "id": 8972,
            "name": "Boxama"
        },
        {
            "id": 8973,
            "name": "Chandanimal"
        },
        {
            "id": 8974,
            "name": "Gochhara"
        },
        {
            "id": 8975,
            "name": "Hadipali"
        },
        {
            "id": 8976,
            "name": "K.Jamankira"
        },
        {
            "id": 8977,
            "name": "Khandokata"
        },
        {
            "id": 8978,
            "name": "Kuleigarh"
        },
        {
            "id": 8979,
            "name": "Kuntara"
        },
        {
            "id": 8980,
            "name": "Kusumi"
        },
        {
            "id": 8981,
            "name": "Kuturachuan"
        },
        {
            "id": 8982,
            "name": "Paruabhadi"
        },
        {
            "id": 8983,
            "name": "Salebhadi"
        },
        {
            "id": 8984,
            "name": "Tainsar"
        },
        {
            "id": 8985,
            "name": "Telitileimal"
        }
    ],
    "9625": [
        {
            "id": 8986,
            "name": "NAC"
        }
    ],
    "9626": [
        {
            "id": 8987,
            "name": "Baham"
        },
        {
            "id": 8988,
            "name": "Birsinghgarh"
        },
        {
            "id": 8989,
            "name": "Chhamunda"
        },
        {
            "id": 8990,
            "name": "Damgarpada"
        },
        {
            "id": 8991,
            "name": "Garloisingha"
        },
        {
            "id": 8992,
            "name": "Ghenupali"
        },
        {
            "id": 8993,
            "name": "Jayantpur"
        },
        {
            "id": 8994,
            "name": "Jhankarpali"
        },
        {
            "id": 8995,
            "name": "Jujumura"
        },
        {
            "id": 8996,
            "name": "Kabrapalli"
        },
        {
            "id": 8997,
            "name": "Kansar"
        },
        {
            "id": 8998,
            "name": "Kayakud"
        },
        {
            "id": 8999,
            "name": "Kesapali"
        },
        {
            "id": 9000,
            "name": "Kukudapali"
        },
        {
            "id": 9001,
            "name": "Lipinda"
        },
        {
            "id": 9002,
            "name": "Meghpal"
        },
        {
            "id": 9003,
            "name": "Nuabarangmal"
        },
        {
            "id": 9004,
            "name": "Tampergarh"
        }
    ],
    "9627": [
        {
            "id": 9005,
            "name": "Baduapali"
        },
        {
            "id": 9006,
            "name": "Bargaon"
        },
        {
            "id": 9007,
            "name": "Dakara"
        },
        {
            "id": 9008,
            "name": "Deogaon"
        },
        {
            "id": 9009,
            "name": "Dhama"
        },
        {
            "id": 9010,
            "name": "Huma"
        },
        {
            "id": 9011,
            "name": "Sahaspur"
        }
    ],
    "9628": [
        {
            "id": 9012,
            "name": "Ballam"
        },
        {
            "id": 9013,
            "name": "Batgaon"
        },
        {
            "id": 9014,
            "name": "Daincha"
        },
        {
            "id": 9015,
            "name": "Ghosaramal"
        },
        {
            "id": 9016,
            "name": "Girishchandrapur"
        },
        {
            "id": 9017,
            "name": "Gogua"
        },
        {
            "id": 9018,
            "name": "Jamjori"
        },
        {
            "id": 9019,
            "name": "Kisinda"
        },
        {
            "id": 9020,
            "name": "Naktideoul"
        },
        {
            "id": 9021,
            "name": "Panimura"
        },
        {
            "id": 9022,
            "name": "Sahebi"
        },
        {
            "id": 9023,
            "name": "Salebhata"
        },
        {
            "id": 9024,
            "name": "Sarapali"
        },
        {
            "id": 9025,
            "name": "Similipal"
        }
    ],
    "9629": [
        {
            "id": 9026,
            "name": "Badbahal"
        },
        {
            "id": 9027,
            "name": "Badmal"
        },
        {
            "id": 9028,
            "name": "Bansajal"
        },
        {
            "id": 9029,
            "name": "Bhaliakata"
        },
        {
            "id": 9030,
            "name": "Bhartapur"
        },
        {
            "id": 9031,
            "name": "Burda"
        },
        {
            "id": 9032,
            "name": "Charmal"
        },
        {
            "id": 9033,
            "name": "Gargadbahal"
        },
        {
            "id": 9034,
            "name": "Kadligarh"
        },
        {
            "id": 9035,
            "name": "Kukudabahali"
        },
        {
            "id": 9036,
            "name": "Luhapank"
        },
        {
            "id": 9037,
            "name": "Mochibahal"
        },
        {
            "id": 9038,
            "name": "Rengali"
        },
        {
            "id": 9039,
            "name": "Tribanpur"
        }
    ],
    "9630": [
        {
            "id": 9040,
            "name": "NAC"
        }
    ],
    "9631": [
        {
            "id": 9041,
            "name": "Basantapur"
        },
        {
            "id": 9042,
            "name": "Bisalkhinda"
        },
        {
            "id": 9043,
            "name": "K.Gunderpur"
        },
        {
            "id": 9044,
            "name": "Kalamati"
        },
        {
            "id": 9045,
            "name": "Kankhinda"
        },
        {
            "id": 9046,
            "name": "Kardola"
        },
        {
            "id": 9047,
            "name": "Kilasama"
        },
        {
            "id": 9048,
            "name": "Sason"
        },
        {
            "id": 9049,
            "name": "Talab"
        }
    ],
    "9632": [
        {
            "id": 9050,
            "name": "Batemura"
        },
        {
            "id": 9051,
            "name": "Bhikampur"
        },
        {
            "id": 9052,
            "name": "Nuatihura"
        },
        {
            "id": 9053,
            "name": "Parmanpur"
        },
        {
            "id": 9054,
            "name": "Tabala"
        }
    ],
    "9633": [
        {
            "id": 9055,
            "name": "Bomaloi"
        },
        {
            "id": 9056,
            "name": "Ghichamura"
        },
        {
            "id": 9057,
            "name": "Jangala"
        },
        {
            "id": 9058,
            "name": "Jhankarpali"
        },
        {
            "id": 9059,
            "name": "Katarbaga"
        },
        {
            "id": 9060,
            "name": "Khinda"
        },
        {
            "id": 9061,
            "name": "Kinaloi"
        },
        {
            "id": 9062,
            "name": "Laida"
        },
        {
            "id": 9063,
            "name": "Lapanga"
        },
        {
            "id": 9064,
            "name": "Nishanbhanga"
        },
        {
            "id": 9065,
            "name": "Rengali"
        },
        {
            "id": 9066,
            "name": "Rengloi"
        },
        {
            "id": 9067,
            "name": "Salad"
        },
        {
            "id": 9068,
            "name": "Tabadabahal"
        },
        {
            "id": 9069,
            "name": "Tamperkala"
        },
        {
            "id": 9070,
            "name": "Thelkoloi"
        }
    ],
    "9634": [
        {
            "id": 9071,
            "name": "NAC"
        }
    ],
    "9635": [
        {
            "id": 9072,
            "name": "NAC"
        }
    ],
    "9636": [
        {
            "id": 9073,
            "name": "NAC"
        }
    ],
    "9637": [
        {
            "id": 9074,
            "name": "B.Singhpur"
        },
        {
            "id": 9075,
            "name": "Benagam"
        },
        {
            "id": 9076,
            "name": "Benasur"
        },
        {
            "id": 9077,
            "name": "Bodigam"
        },
        {
            "id": 9078,
            "name": "Borigumma"
        },
        {
            "id": 9079,
            "name": "Champapadar"
        },
        {
            "id": 9080,
            "name": "Gujuniguda"
        },
        {
            "id": 9081,
            "name": "Gumuda"
        },
        {
            "id": 9082,
            "name": "Hordaguda"
        },
        {
            "id": 9083,
            "name": "Nuagam"
        },
        {
            "id": 9084,
            "name": "Ranspur"
        },
        {
            "id": 9085,
            "name": "Sorgiguda"
        }
    ],
    "9638": [
        {
            "id": 9086,
            "name": "Ambaguda"
        },
        {
            "id": 9087,
            "name": "Anta"
        },
        {
            "id": 9088,
            "name": "Badajiuna"
        },
        {
            "id": 9089,
            "name": "Balia"
        },
        {
            "id": 9090,
            "name": "Bariniput"
        },
        {
            "id": 9091,
            "name": "Dangarchinchi"
        },
        {
            "id": 9092,
            "name": "Dhanpur"
        },
        {
            "id": 9093,
            "name": "Ekamba"
        },
        {
            "id": 9094,
            "name": "Gadapadar"
        },
        {
            "id": 9095,
            "name": "Hadia"
        },
        {
            "id": 9096,
            "name": "Jamunda"
        },
        {
            "id": 9097,
            "name": "Jayantgiri"
        },
        {
            "id": 9098,
            "name": "Kaliagaon"
        },
        {
            "id": 9099,
            "name": "Kebidi"
        },
        {
            "id": 9100,
            "name": "Konga"
        },
        {
            "id": 9101,
            "name": "Kumuliput"
        },
        {
            "id": 9102,
            "name": "Municipalty"
        },
        {
            "id": 9103,
            "name": "Phampuni"
        },
        {
            "id": 9104,
            "name": "Pujariput"
        },
        {
            "id": 9105,
            "name": "Randapalli"
        },
        {
            "id": 9106,
            "name": "Ranigada"
        },
        {
            "id": 9107,
            "name": "Tankua"
        },
        {
            "id": 9108,
            "name": "Umuri"
        }
    ],
    "9639": [
        {
            "id": 9109,
            "name": "NAC"
        }
    ],
    "9640": [
        {
            "id": 9110,
            "name": "Bodaput"
        },
        {
            "id": 9111,
            "name": "Boipariguda"
        },
        {
            "id": 9112,
            "name": "Chandrapoda"
        },
        {
            "id": 9113,
            "name": "Chipakur"
        },
        {
            "id": 9114,
            "name": "Dabuguda"
        },
        {
            "id": 9115,
            "name": "Dasmantapur"
        },
        {
            "id": 9116,
            "name": "Doraguda"
        },
        {
            "id": 9117,
            "name": "Kenduguda"
        },
        {
            "id": 9118,
            "name": "Koolar"
        },
        {
            "id": 9119,
            "name": "Mahuli"
        }
    ],
    "9641": [
        {
            "id": 9120,
            "name": "Bada Keranga"
        },
        {
            "id": 9121,
            "name": "Bada Suku"
        },
        {
            "id": 9122,
            "name": "Badigada"
        },
        {
            "id": 9123,
            "name": "Devighat"
        },
        {
            "id": 9124,
            "name": "Dumriput"
        },
        {
            "id": 9125,
            "name": "Kendar"
        },
        {
            "id": 9126,
            "name": "Keranga"
        },
        {
            "id": 9127,
            "name": "Koraput"
        },
        {
            "id": 9128,
            "name": "Lamtaput"
        },
        {
            "id": 9129,
            "name": "Lankaput"
        },
        {
            "id": 9130,
            "name": "Mahadeiput"
        },
        {
            "id": 9131,
            "name": "Manbar"
        },
        {
            "id": 9132,
            "name": "Mastiput"
        },
        {
            "id": 9133,
            "name": "Padampur"
        },
        {
            "id": 9134,
            "name": "Suku"
        },
        {
            "id": 9135,
            "name": "Umuri"
        }
    ],
    "9642": [
        {
            "id": 9136,
            "name": "NAC"
        }
    ],
    "9643": [
        {
            "id": 9137,
            "name": "Badasagar"
        },
        {
            "id": 9138,
            "name": "Badigada"
        },
        {
            "id": 9139,
            "name": "Ballel"
        },
        {
            "id": 9140,
            "name": "Banamaliput"
        },
        {
            "id": 9141,
            "name": "Chikenput"
        },
        {
            "id": 9142,
            "name": "Dabuguda"
        },
        {
            "id": 9143,
            "name": "Godihanjar"
        },
        {
            "id": 9144,
            "name": "Guneipada"
        },
        {
            "id": 9145,
            "name": "Jalahanjar"
        },
        {
            "id": 9146,
            "name": "Kumaragandhana"
        },
        {
            "id": 9147,
            "name": "Lamtaput"
        },
        {
            "id": 9148,
            "name": "Machkund"
        },
        {
            "id": 9149,
            "name": "Onkadelli"
        },
        {
            "id": 9150,
            "name": "Peta"
        },
        {
            "id": 9151,
            "name": "Thusuba"
        },
        {
            "id": 9152,
            "name": "Tikarapara"
        },
        {
            "id": 9153,
            "name": "Umbel"
        }
    ],
    "9644": [
        {
            "id": 9154,
            "name": "NAC"
        }
    ],
    "9645": [
        {
            "id": 9155,
            "name": "Baligam"
        },
        {
            "id": 9156,
            "name": "Cherka"
        },
        {
            "id": 9157,
            "name": "Dandabadi"
        },
        {
            "id": 9158,
            "name": "Haldikunda"
        },
        {
            "id": 9159,
            "name": "Kathopoda"
        },
        {
            "id": 9160,
            "name": "Majhiguda"
        },
        {
            "id": 9161,
            "name": "Mathapoda"
        },
        {
            "id": 9162,
            "name": "Pendapada"
        },
        {
            "id": 9163,
            "name": "Pujariguda"
        },
        {
            "id": 9164,
            "name": "Ramgiri"
        },
        {
            "id": 9165,
            "name": "Tentuligumma"
        }
    ],
    "9646": [
        {
            "id": 9166,
            "name": "Anchala"
        },
        {
            "id": 9167,
            "name": "Aunli"
        },
        {
            "id": 9168,
            "name": "Bijapur"
        },
        {
            "id": 9169,
            "name": "Dengapadar"
        },
        {
            "id": 9170,
            "name": "Jujhari"
        },
        {
            "id": 9171,
            "name": "Kamara"
        },
        {
            "id": 9172,
            "name": "Kanagam"
        },
        {
            "id": 9173,
            "name": "Malda"
        },
        {
            "id": 9174,
            "name": "Pondasguda"
        },
        {
            "id": 9175,
            "name": "Sanaparia"
        },
        {
            "id": 9176,
            "name": "Soshahandi"
        }
    ],
    "9647": [
        {
            "id": 9177,
            "name": "Batasana"
        },
        {
            "id": 9178,
            "name": "Bhonsoli"
        },
        {
            "id": 9179,
            "name": "Bobbeya"
        },
        {
            "id": 9180,
            "name": "Chandili"
        },
        {
            "id": 9181,
            "name": "Chattarla"
        },
        {
            "id": 9182,
            "name": "Chitra"
        },
        {
            "id": 9183,
            "name": "Dhamanahandi"
        },
        {
            "id": 9184,
            "name": "Ghumar"
        },
        {
            "id": 9185,
            "name": "Girla"
        },
        {
            "id": 9186,
            "name": "Guali"
        },
        {
            "id": 9187,
            "name": "Gumuda"
        },
        {
            "id": 9188,
            "name": "Kusumi"
        },
        {
            "id": 9189,
            "name": "Murtahandi"
        },
        {
            "id": 9190,
            "name": "S.B.Nuagaon"
        },
        {
            "id": 9191,
            "name": "Sadaranga"
        },
        {
            "id": 9192,
            "name": "Sargiguda"
        },
        {
            "id": 9193,
            "name": "Sutipadar"
        }
    ],
    "9648": [
        {
            "id": 9194,
            "name": "NAC"
        }
    ],
    "9649": [
        {
            "id": 9195,
            "name": "Asana"
        },
        {
            "id": 9196,
            "name": "Bagderi"
        },
        {
            "id": 9197,
            "name": "Banuaguda"
        },
        {
            "id": 9198,
            "name": "Bhusangaguda"
        },
        {
            "id": 9199,
            "name": "Dangarapaunsi"
        },
        {
            "id": 9200,
            "name": "Digapur"
        },
        {
            "id": 9201,
            "name": "Ghumar"
        },
        {
            "id": 9202,
            "name": "Gundala"
        },
        {
            "id": 9203,
            "name": "Kermity"
        },
        {
            "id": 9204,
            "name": "Kundra"
        },
        {
            "id": 9205,
            "name": "Lima"
        },
        {
            "id": 9206,
            "name": "Mosigam"
        },
        {
            "id": 9207,
            "name": "Pakhanaguda"
        },
        {
            "id": 9208,
            "name": "Phulabhata"
        },
        {
            "id": 9209,
            "name": "Phupugam"
        },
        {
            "id": 9210,
            "name": "Raniguda"
        }
    ],
    "9650": [
        {
            "id": 9211,
            "name": "Almonda"
        },
        {
            "id": 9212,
            "name": "Bandhugam"
        },
        {
            "id": 9213,
            "name": "Boda Soropolli"
        },
        {
            "id": 9214,
            "name": "Garidi"
        },
        {
            "id": 9215,
            "name": "Jaguguda"
        },
        {
            "id": 9216,
            "name": "Kabribadi"
        },
        {
            "id": 9217,
            "name": "Kanagam"
        },
        {
            "id": 9218,
            "name": "Kapaloda"
        },
        {
            "id": 9219,
            "name": "Kumbhaganda"
        },
        {
            "id": 9220,
            "name": "Kumbhariput"
        },
        {
            "id": 9221,
            "name": "Kutrabeda"
        },
        {
            "id": 9222,
            "name": "Nilabadi"
        },
        {
            "id": 9223,
            "name": "Pedawalda"
        }
    ],
    "9651": [
        {
            "id": 9224,
            "name": "Balipeta"
        },
        {
            "id": 9225,
            "name": "Chanabada"
        },
        {
            "id": 9226,
            "name": "Chikambo"
        },
        {
            "id": 9227,
            "name": "Dasmantapur"
        },
        {
            "id": 9228,
            "name": "Duarisuni"
        },
        {
            "id": 9229,
            "name": "Dumbaguda"
        },
        {
            "id": 9230,
            "name": "Gadiaguda"
        },
        {
            "id": 9231,
            "name": "Giriligumma"
        },
        {
            "id": 9232,
            "name": "Lulla"
        },
        {
            "id": 9233,
            "name": "Malkangiri"
        },
        {
            "id": 9234,
            "name": "Mujnaga"
        },
        {
            "id": 9235,
            "name": "Murkar"
        },
        {
            "id": 9236,
            "name": "Nandigaon"
        },
        {
            "id": 9237,
            "name": "Padapadar"
        },
        {
            "id": 9238,
            "name": "Paikphulobeda"
        },
        {
            "id": 9239,
            "name": "Parajabedapadar"
        },
        {
            "id": 9240,
            "name": "Pindapadar"
        },
        {
            "id": 9241,
            "name": "Podagada"
        }
    ],
    "9652": [
        {
            "id": 9242,
            "name": "Bhitaragada"
        },
        {
            "id": 9243,
            "name": "Burja"
        },
        {
            "id": 9244,
            "name": "Champi"
        },
        {
            "id": 9245,
            "name": "Goudaguda"
        },
        {
            "id": 9246,
            "name": "Kakirigumma"
        },
        {
            "id": 9247,
            "name": "Kusumguda"
        },
        {
            "id": 9248,
            "name": "Kuttinga"
        },
        {
            "id": 9249,
            "name": "Laxmipur"
        },
        {
            "id": 9250,
            "name": "Odiapentha"
        },
        {
            "id": 9251,
            "name": "Panchada"
        },
        {
            "id": 9252,
            "name": "Pipalpadar"
        },
        {
            "id": 9253,
            "name": "Toyaput"
        },
        {
            "id": 9254,
            "name": "Tunpar"
        }
    ],
    "9653": [
        {
            "id": 9255,
            "name": "Balipeta"
        },
        {
            "id": 9256,
            "name": "Bijaghati"
        },
        {
            "id": 9257,
            "name": "Borigi"
        },
        {
            "id": 9258,
            "name": "Kumbhari"
        },
        {
            "id": 9259,
            "name": "Langalbeda"
        },
        {
            "id": 9260,
            "name": "Narayanpatna"
        },
        {
            "id": 9261,
            "name": "Podapadar"
        },
        {
            "id": 9262,
            "name": "Talagumandi"
        },
        {
            "id": 9263,
            "name": "Tentulipadar"
        }
    ],
    "9654": [
        {
            "id": 9264,
            "name": "Dumuripadar"
        },
        {
            "id": 9265,
            "name": "Litiguda"
        },
        {
            "id": 9266,
            "name": "Marichamala"
        },
        {
            "id": 9267,
            "name": "Mastiput"
        },
        {
            "id": 9268,
            "name": "Mathalput"
        }
    ],
    "9655": [
        {
            "id": 9269,
            "name": "Atanda"
        },
        {
            "id": 9270,
            "name": "Badel"
        },
        {
            "id": 9271,
            "name": "Balda"
        },
        {
            "id": 9272,
            "name": "Bheja"
        },
        {
            "id": 9273,
            "name": "Bilaput"
        },
        {
            "id": 9274,
            "name": "Chatua"
        },
        {
            "id": 9275,
            "name": "Goluru"
        },
        {
            "id": 9276,
            "name": "Hatibari"
        },
        {
            "id": 9277,
            "name": "Hikimput"
        },
        {
            "id": 9278,
            "name": "Kasandi"
        },
        {
            "id": 9279,
            "name": "Khemunduguda"
        },
        {
            "id": 9280,
            "name": "Khinbar"
        },
        {
            "id": 9281,
            "name": "Khurji"
        },
        {
            "id": 9282,
            "name": "Kulabiri"
        },
        {
            "id": 9283,
            "name": "Kularsing"
        },
        {
            "id": 9284,
            "name": "Malibelgam"
        },
        {
            "id": 9285,
            "name": "Nandaka"
        },
        {
            "id": 9286,
            "name": "Nandapur"
        },
        {
            "id": 9287,
            "name": "P.Badapada"
        },
        {
            "id": 9288,
            "name": "Padua"
        },
        {
            "id": 9289,
            "name": "Pantalung"
        },
        {
            "id": 9290,
            "name": "Raising"
        },
        {
            "id": 9291,
            "name": "Thuba"
        }
    ],
    "9656": [
        {
            "id": 9292,
            "name": "Ampavalli"
        },
        {
            "id": 9293,
            "name": "Chandaka"
        },
        {
            "id": 9294,
            "name": "Deo Pottangi"
        },
        {
            "id": 9295,
            "name": "Gangrajpur"
        },
        {
            "id": 9296,
            "name": "Kotiya"
        },
        {
            "id": 9297,
            "name": "Maliput"
        },
        {
            "id": 9298,
            "name": "Nuagam"
        },
        {
            "id": 9299,
            "name": "Petru"
        },
        {
            "id": 9300,
            "name": "Pottangi"
        },
        {
            "id": 9301,
            "name": "Pukali"
        },
        {
            "id": 9302,
            "name": "Rallegada"
        },
        {
            "id": 9303,
            "name": "Sombai"
        },
        {
            "id": 9304,
            "name": "sunki"
        },
        {
            "id": 9305,
            "name": "Tologoluru"
        }
    ],
    "9657": [
        {
            "id": 9306,
            "name": "Charangul"
        },
        {
            "id": 9307,
            "name": "Daleiguda"
        },
        {
            "id": 9308,
            "name": "Dudhari"
        },
        {
            "id": 9309,
            "name": "Gunthaput"
        },
        {
            "id": 9310,
            "name": "Hataguda"
        },
        {
            "id": 9311,
            "name": "Khudi"
        },
        {
            "id": 9312,
            "name": "Kunduli"
        },
        {
            "id": 9313,
            "name": "Pakjhola"
        },
        {
            "id": 9314,
            "name": "Parajamuthai"
        },
        {
            "id": 9315,
            "name": "Pitaguda"
        },
        {
            "id": 9316,
            "name": "Rajpalma"
        },
        {
            "id": 9317,
            "name": "Rajput"
        },
        {
            "id": 9318,
            "name": "Renga"
        },
        {
            "id": 9319,
            "name": "Sadam"
        },
        {
            "id": 9320,
            "name": "Sorishpadar"
        },
        {
            "id": 9321,
            "name": "Subai"
        },
        {
            "id": 9322,
            "name": "Sunabeda"
        },
        {
            "id": 9323,
            "name": "Upper Kanti"
        }
    ],
    "9658": [
        {
            "id": 9324,
            "name": "NAC"
        }
    ],
    "9659": [
        {
            "id": 9325,
            "name": "NAC"
        }
    ],
    "9660": [
        {
            "id": 9326,
            "name": "Barimunda"
        },
        {
            "id": 9327,
            "name": "Dadha"
        },
        {
            "id": 9328,
            "name": "Kalarahanga"
        },
        {
            "id": 9329,
            "name": "Kalyanpur"
        },
        {
            "id": 9330,
            "name": "Raghunathpur"
        }
    ],
    "9661": [
        {
            "id": 9331,
            "name": "Badaberera"
        },
        {
            "id": 9332,
            "name": "Baghamari"
        },
        {
            "id": 9333,
            "name": "Begunia"
        },
        {
            "id": 9334,
            "name": "Botalama"
        },
        {
            "id": 9335,
            "name": "Deuli"
        },
        {
            "id": 9336,
            "name": "Dingar"
        },
        {
            "id": 9337,
            "name": "Durgapur"
        },
        {
            "id": 9338,
            "name": "Gadamanitri"
        },
        {
            "id": 9339,
            "name": "Gobindapur"
        },
        {
            "id": 9340,
            "name": "Haja"
        },
        {
            "id": 9341,
            "name": "Hirapur"
        },
        {
            "id": 9342,
            "name": "Kantabada"
        },
        {
            "id": 9343,
            "name": "Kunjuri"
        },
        {
            "id": 9344,
            "name": "Pangarsingh"
        },
        {
            "id": 9345,
            "name": "Podadiha"
        },
        {
            "id": 9346,
            "name": "Radhakantapur"
        },
        {
            "id": 9347,
            "name": "Rautapada"
        },
        {
            "id": 9348,
            "name": "Sagadabhanga"
        },
        {
            "id": 9349,
            "name": "Sarua"
        },
        {
            "id": 9350,
            "name": "Siko"
        },
        {
            "id": 9351,
            "name": "Simore"
        }
    ],
    "9662": [
        {
            "id": 9352,
            "name": "Arikama"
        },
        {
            "id": 9353,
            "name": "Asaraladala"
        },
        {
            "id": 9354,
            "name": "Badakumari"
        },
        {
            "id": 9355,
            "name": "Bankoidesh"
        },
        {
            "id": 9356,
            "name": "Bolagarh"
        },
        {
            "id": 9357,
            "name": "Dabaradhuapatna"
        },
        {
            "id": 9358,
            "name": "Daleisahi"
        },
        {
            "id": 9359,
            "name": "Dhalapathar"
        },
        {
            "id": 9360,
            "name": "Fasioda"
        },
        {
            "id": 9361,
            "name": "Gediapalli"
        },
        {
            "id": 9362,
            "name": "Gopalpur"
        },
        {
            "id": 9363,
            "name": "Kadaba"
        },
        {
            "id": 9364,
            "name": "Kalanga"
        },
        {
            "id": 9365,
            "name": "Khanguria"
        },
        {
            "id": 9366,
            "name": "Manibandha"
        },
        {
            "id": 9367,
            "name": "Patapursasan"
        },
        {
            "id": 9368,
            "name": "Pichukoli"
        },
        {
            "id": 9369,
            "name": "Sagargaon"
        },
        {
            "id": 9370,
            "name": "Sanapadar"
        }
    ],
    "9663": [
        {
            "id": 9371,
            "name": "NAC"
        }
    ],
    "9664": [
        {
            "id": 9372,
            "name": "NAC"
        }
    ],
    "9665": [
        {
            "id": 9373,
            "name": "Ayatpur"
        },
        {
            "id": 9374,
            "name": "Bhabanipur"
        },
        {
            "id": 9375,
            "name": "Bheteswar"
        },
        {
            "id": 9376,
            "name": "Bishnudiha"
        },
        {
            "id": 9377,
            "name": "Damiabarabara"
        },
        {
            "id": 9378,
            "name": "Deogaon"
        },
        {
            "id": 9379,
            "name": "Galua"
        },
        {
            "id": 9380,
            "name": "Gambharimunda"
        },
        {
            "id": 9381,
            "name": "Kulei"
        },
        {
            "id": 9382,
            "name": "Kumarangasasan"
        },
        {
            "id": 9383,
            "name": "Moramori"
        },
        {
            "id": 9384,
            "name": "Nachuni"
        },
        {
            "id": 9385,
            "name": "Nandapur"
        },
        {
            "id": 9386,
            "name": "Narendrapur"
        },
        {
            "id": 9387,
            "name": "Niladriprasad"
        },
        {
            "id": 9388,
            "name": "Torasingh"
        },
        {
            "id": 9389,
            "name": "Tumuraputsasan"
        }
    ],
    "9666": [
        {
            "id": 9390,
            "name": "Ankula"
        },
        {
            "id": 9391,
            "name": "Athrabatia"
        },
        {
            "id": 9392,
            "name": "Badakul"
        },
        {
            "id": 9393,
            "name": "Baulabandha"
        },
        {
            "id": 9394,
            "name": "Biribadi"
        },
        {
            "id": 9395,
            "name": "Chandeswar"
        },
        {
            "id": 9396,
            "name": "Dungamal"
        },
        {
            "id": 9397,
            "name": "Haripur"
        },
        {
            "id": 9398,
            "name": "Hatabaradiha"
        },
        {
            "id": 9399,
            "name": "Jaripada"
        },
        {
            "id": 9400,
            "name": "Kalakaleswar"
        },
        {
            "id": 9401,
            "name": "Kumandalpatna"
        },
        {
            "id": 9402,
            "name": "Mansingpur"
        },
        {
            "id": 9403,
            "name": "Nimikheta"
        },
        {
            "id": 9404,
            "name": "Sananairi"
        },
        {
            "id": 9405,
            "name": "Singheswar"
        },
        {
            "id": 9406,
            "name": "Soran"
        }
    ],
    "9667": [
        {
            "id": 9407,
            "name": "NAC"
        }
    ],
    "9668": [
        {
            "id": 9408,
            "name": "Basuaghai"
        },
        {
            "id": 9409,
            "name": "Dhauli"
        },
        {
            "id": 9410,
            "name": "Itipur"
        },
        {
            "id": 9411,
            "name": "Lingipur"
        },
        {
            "id": 9412,
            "name": "Sisupalgarh"
        },
        {
            "id": 9413,
            "name": "Tikarapada"
        }
    ],
    "9669": [
        {
            "id": 9414,
            "name": "Andharua"
        },
        {
            "id": 9415,
            "name": "Chandaka"
        },
        {
            "id": 9416,
            "name": "Daruthenga"
        },
        {
            "id": 9417,
            "name": "Kantabada"
        },
        {
            "id": 9418,
            "name": "Malipada"
        },
        {
            "id": 9419,
            "name": "Mendhasal"
        },
        {
            "id": 9420,
            "name": "Nanput"
        },
        {
            "id": 9421,
            "name": "Ranasinghpur"
        },
        {
            "id": 9422,
            "name": "Tamando"
        }
    ],
    "9670": [
        {
            "id": 9423,
            "name": "Angarapada"
        },
        {
            "id": 9424,
            "name": "Benapanjari"
        },
        {
            "id": 9425,
            "name": "Chhanaghar"
        },
        {
            "id": 9426,
            "name": "Chhatabara"
        },
        {
            "id": 9427,
            "name": "Gangapada"
        },
        {
            "id": 9428,
            "name": "Haripur"
        },
        {
            "id": 9429,
            "name": "Jamukoli"
        },
        {
            "id": 9430,
            "name": "Janla"
        },
        {
            "id": 9431,
            "name": "Kantia"
        },
        {
            "id": 9432,
            "name": "Madanpur"
        },
        {
            "id": 9433,
            "name": "Padanpur"
        },
        {
            "id": 9434,
            "name": "Padhanasahi"
        },
        {
            "id": 9435,
            "name": "Panchupalli"
        },
        {
            "id": 9436,
            "name": "Taraboi"
        },
        {
            "id": 9437,
            "name": "Tirimal"
        }
    ],
    "9671": [
        {
            "id": 9438,
            "name": "NAC"
        }
    ],
    "9672": [
        {
            "id": 9439,
            "name": "Barasahi"
        },
        {
            "id": 9440,
            "name": "Garh-haladia"
        },
        {
            "id": 9441,
            "name": "Keranga"
        },
        {
            "id": 9442,
            "name": "Mallipur"
        },
        {
            "id": 9443,
            "name": "Nallipada-arjunpur"
        },
        {
            "id": 9444,
            "name": "Pubushai"
        },
        {
            "id": 9445,
            "name": "Tangiapada"
        }
    ],
    "9673": [
        {
            "id": 9446,
            "name": "Bainchua"
        },
        {
            "id": 9447,
            "name": "Balianta"
        },
        {
            "id": 9448,
            "name": "Bentapur"
        },
        {
            "id": 9449,
            "name": "Benupur"
        },
        {
            "id": 9450,
            "name": "Bhinarpur"
        },
        {
            "id": 9451,
            "name": "Jaganathpur"
        },
        {
            "id": 9452,
            "name": "Jayadeb"
        },
        {
            "id": 9453,
            "name": "Jhintisasan"
        },
        {
            "id": 9454,
            "name": "Kakarudrapur"
        },
        {
            "id": 9455,
            "name": "Prataprudrapur"
        },
        {
            "id": 9456,
            "name": "Pratapsasan"
        },
        {
            "id": 9457,
            "name": "Puranpradhan"
        },
        {
            "id": 9458,
            "name": "Purohitapur"
        },
        {
            "id": 9459,
            "name": "Sarakana"
        },
        {
            "id": 9460,
            "name": "Satyabhamapur"
        },
        {
            "id": 9461,
            "name": "Umadeibrhmapur"
        }
    ],
    "9674": [
        {
            "id": 9462,
            "name": "Alisisasan"
        },
        {
            "id": 9463,
            "name": "Amanakuda"
        },
        {
            "id": 9464,
            "name": "Athantara"
        },
        {
            "id": 9465,
            "name": "Bhakarashai"
        },
        {
            "id": 9466,
            "name": "Bhapur"
        },
        {
            "id": 9467,
            "name": "Deulidharpur"
        },
        {
            "id": 9468,
            "name": "Garedipanchan"
        },
        {
            "id": 9469,
            "name": "Guapur"
        },
        {
            "id": 9470,
            "name": "Kurunjipur"
        },
        {
            "id": 9471,
            "name": "Majhihara"
        },
        {
            "id": 9472,
            "name": "Marthapur"
        },
        {
            "id": 9473,
            "name": "Mukundadaspur"
        },
        {
            "id": 9474,
            "name": "Nariso"
        },
        {
            "id": 9475,
            "name": "Pampado"
        },
        {
            "id": 9476,
            "name": "Rajas"
        },
        {
            "id": 9477,
            "name": "Somanasasan"
        },
        {
            "id": 9478,
            "name": "Turintira"
        }
    ],
    "9675": [
        {
            "id": 9479,
            "name": "Bajapur"
        },
        {
            "id": 9480,
            "name": "Bangida"
        },
        {
            "id": 9481,
            "name": "Barasahi"
        },
        {
            "id": 9482,
            "name": "Brajamohanpur"
        },
        {
            "id": 9483,
            "name": "Dhaulimuhan"
        },
        {
            "id": 9484,
            "name": "Godipada"
        },
        {
            "id": 9485,
            "name": "Golbaisasan"
        },
        {
            "id": 9486,
            "name": "Jankia"
        },
        {
            "id": 9487,
            "name": "Kaipadar"
        },
        {
            "id": 9488,
            "name": "Kanpur"
        },
        {
            "id": 9489,
            "name": "Kuradhamalla"
        },
        {
            "id": 9490,
            "name": "Nandapur"
        },
        {
            "id": 9491,
            "name": "Narangarh"
        },
        {
            "id": 9492,
            "name": "Nijigarh-tapanga"
        },
        {
            "id": 9493,
            "name": "Orabarasingh"
        },
        {
            "id": 9494,
            "name": "Pallatotapada"
        }
    ],
    "9676": [
        {
            "id": 9495,
            "name": "NAC"
        }
    ],
    "9677": [
        {
            "id": 9496,
            "name": "Achyutapur"
        },
        {
            "id": 9497,
            "name": "Badapari"
        },
        {
            "id": 9498,
            "name": "Badapokharia"
        },
        {
            "id": 9499,
            "name": "Balipatapur"
        },
        {
            "id": 9500,
            "name": "Bhusundapur"
        },
        {
            "id": 9501,
            "name": "Chhanagiri"
        },
        {
            "id": 9502,
            "name": "Dia"
        },
        {
            "id": 9503,
            "name": "Kamaguru"
        },
        {
            "id": 9504,
            "name": "Kantalbai"
        },
        {
            "id": 9505,
            "name": "Kuhudi"
        },
        {
            "id": 9506,
            "name": "Kunjuri"
        },
        {
            "id": 9507,
            "name": "Lendo"
        },
        {
            "id": 9508,
            "name": "Mangalajodi"
        },
        {
            "id": 9509,
            "name": "Nirakarpur"
        },
        {
            "id": 9510,
            "name": "Nuagarh"
        },
        {
            "id": 9511,
            "name": "Olasingh"
        },
        {
            "id": 9512,
            "name": "Pariorada"
        },
        {
            "id": 9513,
            "name": "Ramachandrapur"
        },
        {
            "id": 9514,
            "name": "Rameswar"
        },
        {
            "id": 9515,
            "name": "Ratanpur"
        },
        {
            "id": 9516,
            "name": "Sanarautapada"
        },
        {
            "id": 9517,
            "name": "Sarapari"
        },
        {
            "id": 9518,
            "name": "Sundarpur"
        },
        {
            "id": 9519,
            "name": "Tangi"
        },
        {
            "id": 9520,
            "name": "Tankol"
        },
        {
            "id": 9521,
            "name": "Ujjalagopinathpur"
        }
    ],
    "9678": [
        {
            "id": 9522,
            "name": "Amabapada"
        },
        {
            "id": 9523,
            "name": "Badabenakudi"
        },
        {
            "id": 9524,
            "name": "Balikerapachhipala (New)"
        },
        {
            "id": 9525,
            "name": "Bentapur"
        },
        {
            "id": 9526,
            "name": "Bhubanpur (New)"
        },
        {
            "id": 9527,
            "name": "Brahmagiri"
        },
        {
            "id": 9528,
            "name": "Brahmunia"
        },
        {
            "id": 9529,
            "name": "Chapamanika (New)"
        },
        {
            "id": 9530,
            "name": "Dharanikudi"
        },
        {
            "id": 9531,
            "name": "Dimirisena"
        },
        {
            "id": 9532,
            "name": "Garhrodang"
        },
        {
            "id": 9533,
            "name": "Ghatakudi (New)"
        },
        {
            "id": 9534,
            "name": "Goruala"
        },
        {
            "id": 9535,
            "name": "Kadajit (New)"
        },
        {
            "id": 9536,
            "name": "Kahalpada"
        },
        {
            "id": 9537,
            "name": "Kandagada"
        },
        {
            "id": 9538,
            "name": "Kapileswarpur"
        },
        {
            "id": 9539,
            "name": "Kusubenti"
        },
        {
            "id": 9540,
            "name": "Manapada"
        },
        {
            "id": 9541,
            "name": "Niajapur (New)"
        },
        {
            "id": 9542,
            "name": "Palanka (New)"
        },
        {
            "id": 9543,
            "name": "Rahadmalla"
        },
        {
            "id": 9544,
            "name": "Raibidhar"
        },
        {
            "id": 9545,
            "name": "Rebananuagaon"
        },
        {
            "id": 9546,
            "name": "Talamala"
        }
    ],
    "9679": [
        {
            "id": 9547,
            "name": "Alanda"
        },
        {
            "id": 9548,
            "name": "Arakhakuda"
        },
        {
            "id": 9549,
            "name": "Bada-anala"
        },
        {
            "id": 9550,
            "name": "Badajhada"
        },
        {
            "id": 9551,
            "name": "Bajrakota"
        },
        {
            "id": 9552,
            "name": "Berhampur"
        },
        {
            "id": 9553,
            "name": "Budhibar"
        },
        {
            "id": 9554,
            "name": "Gambhari"
        },
        {
            "id": 9555,
            "name": "Gomundia"
        },
        {
            "id": 9556,
            "name": "Gopinathapur (New)"
        },
        {
            "id": 9557,
            "name": "Krushnaprasad"
        },
        {
            "id": 9558,
            "name": "Maluda"
        },
        {
            "id": 9559,
            "name": "Manika"
        },
        {
            "id": 9560,
            "name": "Nuapada"
        },
        {
            "id": 9561,
            "name": "Panasapada"
        },
        {
            "id": 9562,
            "name": "Pirijipur"
        },
        {
            "id": 9563,
            "name": "Ramalenka"
        },
        {
            "id": 9564,
            "name": "Satapada"
        },
        {
            "id": 9565,
            "name": "Siala"
        },
        {
            "id": 9566,
            "name": "Siandi"
        },
        {
            "id": 9567,
            "name": "Titipa"
        }
    ],
    "9680": [
        {
            "id": 9568,
            "name": "Basudevpur"
        },
        {
            "id": 9569,
            "name": "Beladala"
        },
        {
            "id": 9570,
            "name": "Bhailipur"
        },
        {
            "id": 9571,
            "name": "Bijay Ramchandrapur"
        },
        {
            "id": 9572,
            "name": "Bira Balabhadrapur"
        },
        {
            "id": 9573,
            "name": "Bira Narasinghpur"
        },
        {
            "id": 9574,
            "name": "Goudakera"
        },
        {
            "id": 9575,
            "name": "Hantuka"
        },
        {
            "id": 9576,
            "name": "Kanhei Bidyadharpur"
        },
        {
            "id": 9577,
            "name": "Kerandipur"
        },
        {
            "id": 9578,
            "name": "Pratap Purusottompur"
        },
        {
            "id": 9579,
            "name": "Pratap Ramachandrapur"
        },
        {
            "id": 9580,
            "name": "Samangara"
        }
    ],
    "9681": [
        {
            "id": 9581,
            "name": "Alangapur"
        },
        {
            "id": 9582,
            "name": "Alasahi"
        },
        {
            "id": 9583,
            "name": "Astarang"
        },
        {
            "id": 9584,
            "name": "Chhuriana"
        },
        {
            "id": 9585,
            "name": "Jhadling (New)"
        },
        {
            "id": 9586,
            "name": "Kendrapati"
        },
        {
            "id": 9587,
            "name": "Korana"
        },
        {
            "id": 9588,
            "name": "Manduki"
        },
        {
            "id": 9589,
            "name": "Nagar"
        },
        {
            "id": 9590,
            "name": "Naiguan"
        },
        {
            "id": 9591,
            "name": "Nuagarh"
        },
        {
            "id": 9592,
            "name": "Patalada"
        },
        {
            "id": 9593,
            "name": "Saripur"
        },
        {
            "id": 9594,
            "name": "Sisua"
        },
        {
            "id": 9595,
            "name": "Talada"
        }
    ],
    "9682": [
        {
            "id": 9596,
            "name": "Achyutapur"
        },
        {
            "id": 9597,
            "name": "Andhar-ichhapur"
        },
        {
            "id": 9598,
            "name": "Badagaon"
        },
        {
            "id": 9599,
            "name": "Badatara"
        },
        {
            "id": 9600,
            "name": "Banakhandi"
        },
        {
            "id": 9601,
            "name": "Baniasahi"
        },
        {
            "id": 9602,
            "name": "Bantaligaon"
        },
        {
            "id": 9603,
            "name": "Baulanga"
        },
        {
            "id": 9604,
            "name": "Bedapur"
        },
        {
            "id": 9605,
            "name": "Birtunga"
        },
        {
            "id": 9606,
            "name": "Chhaitana"
        },
        {
            "id": 9607,
            "name": "Davara (New)"
        },
        {
            "id": 9608,
            "name": "Desthali"
        },
        {
            "id": 9609,
            "name": "Dhumalo"
        },
        {
            "id": 9610,
            "name": "Erabanga"
        },
        {
            "id": 9611,
            "name": "Gadavingura"
        },
        {
            "id": 9612,
            "name": "Ganeswarpur"
        },
        {
            "id": 9613,
            "name": "Gop"
        },
        {
            "id": 9614,
            "name": "Ishanpur (New)"
        },
        {
            "id": 9615,
            "name": "Itibhuan"
        },
        {
            "id": 9616,
            "name": "Jangalbori"
        },
        {
            "id": 9617,
            "name": "Kuanpada"
        },
        {
            "id": 9618,
            "name": "Mahalapada"
        },
        {
            "id": 9619,
            "name": "Nagapur"
        },
        {
            "id": 9620,
            "name": "Nuakholamara"
        },
        {
            "id": 9621,
            "name": "Payara"
        },
        {
            "id": 9622,
            "name": "Rahangorada"
        },
        {
            "id": 9623,
            "name": "Simili"
        },
        {
            "id": 9624,
            "name": "Sorava"
        },
        {
            "id": 9625,
            "name": "Sutan"
        },
        {
            "id": 9626,
            "name": "Tarakor"
        }
    ],
    "9683": [
        {
            "id": 9627,
            "name": "Abadan"
        },
        {
            "id": 9628,
            "name": "Bangurigaon"
        },
        {
            "id": 9629,
            "name": "Bhandaghar (New)"
        },
        {
            "id": 9630,
            "name": "Jaleswarpada"
        },
        {
            "id": 9631,
            "name": "Kaduanuagaon"
        },
        {
            "id": 9632,
            "name": "Kakatapur"
        },
        {
            "id": 9633,
            "name": "Kantapada"
        },
        {
            "id": 9634,
            "name": "Kotakana"
        },
        {
            "id": 9635,
            "name": "Kundhei"
        },
        {
            "id": 9636,
            "name": "Kurujang"
        },
        {
            "id": 9637,
            "name": "Lataharan"
        },
        {
            "id": 9638,
            "name": "Nasikesar"
        },
        {
            "id": 9639,
            "name": "Othaka"
        },
        {
            "id": 9640,
            "name": "Patasundarpur"
        },
        {
            "id": 9641,
            "name": "Suhagpur"
        },
        {
            "id": 9642,
            "name": "Suhanpur (New)"
        }
    ],
    "9684": [
        {
            "id": 9643,
            "name": "NAC"
        }
    ],
    "9685": [
        {
            "id": 9644,
            "name": "Andaraichapur"
        },
        {
            "id": 9645,
            "name": "Badatara"
        },
        {
            "id": 9646,
            "name": "Baniasahi"
        },
        {
            "id": 9647,
            "name": "Bantaligram"
        },
        {
            "id": 9648,
            "name": "Bedapur"
        },
        {
            "id": 9649,
            "name": "Davar"
        },
        {
            "id": 9650,
            "name": "Erabanga"
        },
        {
            "id": 9651,
            "name": "Ganeswarpur"
        },
        {
            "id": 9652,
            "name": "Gop"
        },
        {
            "id": 9653,
            "name": "Kuanpada"
        },
        {
            "id": 9654,
            "name": "Nagapur"
        },
        {
            "id": 9655,
            "name": "Nuakholamara"
        },
        {
            "id": 9656,
            "name": "Payara"
        },
        {
            "id": 9657,
            "name": "Rahangorada"
        }
    ],
    "9686": [
        {
            "id": 9658,
            "name": "Alanda"
        },
        {
            "id": 9659,
            "name": "Antuar"
        },
        {
            "id": 9660,
            "name": "Arisandha"
        },
        {
            "id": 9661,
            "name": "Badamachhapur (New)"
        },
        {
            "id": 9662,
            "name": "Badasiribila"
        },
        {
            "id": 9663,
            "name": "Baharana"
        },
        {
            "id": 9664,
            "name": "Balanga"
        },
        {
            "id": 9665,
            "name": "Bamanal"
        },
        {
            "id": 9666,
            "name": "Bhiligram"
        },
        {
            "id": 9667,
            "name": "Bhodara"
        },
        {
            "id": 9668,
            "name": "Bhogasalada"
        },
        {
            "id": 9669,
            "name": "Chanrapada"
        },
        {
            "id": 9670,
            "name": "Chhanijanga"
        },
        {
            "id": 9671,
            "name": "Denua"
        },
        {
            "id": 9672,
            "name": "Dhaleswar"
        },
        {
            "id": 9673,
            "name": "Dhanua"
        },
        {
            "id": 9674,
            "name": "Gadapadanpur (New)"
        },
        {
            "id": 9675,
            "name": "Gopinathapur (New)"
        },
        {
            "id": 9676,
            "name": "Haripur"
        },
        {
            "id": 9677,
            "name": "Kalapanchana"
        },
        {
            "id": 9678,
            "name": "Kotakosanga"
        },
        {
            "id": 9679,
            "name": "Miteipur"
        },
        {
            "id": 9680,
            "name": "Nuasantha"
        },
        {
            "id": 9681,
            "name": "Ratilo (New)"
        },
        {
            "id": 9682,
            "name": "Renchasasan"
        },
        {
            "id": 9683,
            "name": "Sagada"
        },
        {
            "id": 9684,
            "name": "Sainsasasan"
        },
        {
            "id": 9685,
            "name": "Salanga"
        },
        {
            "id": 9686,
            "name": "Tampalo"
        },
        {
            "id": 9687,
            "name": "Terundia"
        },
        {
            "id": 9688,
            "name": "Tulasipur"
        },
        {
            "id": 9689,
            "name": "Uchhupur"
        }
    ],
    "9687": [
        {
            "id": 9690,
            "name": "NAC"
        }
    ],
    "9688": [
        {
            "id": 9691,
            "name": "Abhayamukhi"
        },
        {
            "id": 9692,
            "name": "Arisal"
        },
        {
            "id": 9693,
            "name": "Beraboi"
        },
        {
            "id": 9694,
            "name": "Chainpur"
        },
        {
            "id": 9695,
            "name": "Delanga-kothabada"
        },
        {
            "id": 9696,
            "name": "Dhanakera"
        },
        {
            "id": 9697,
            "name": "Garh-motari"
        },
        {
            "id": 9698,
            "name": "Ghanipur (New)"
        },
        {
            "id": 9699,
            "name": "Godiputmatiapada"
        },
        {
            "id": 9700,
            "name": "Gualipada"
        },
        {
            "id": 9701,
            "name": "Harirajpur"
        },
        {
            "id": 9702,
            "name": "Jenapur"
        },
        {
            "id": 9703,
            "name": "Kalyanpur"
        },
        {
            "id": 9704,
            "name": "Khelaur (New)"
        },
        {
            "id": 9705,
            "name": "Muninda"
        },
        {
            "id": 9706,
            "name": "Rench (New)"
        },
        {
            "id": 9707,
            "name": "Rengal"
        },
        {
            "id": 9708,
            "name": "Sauria"
        },
        {
            "id": 9709,
            "name": "Singh-berhampur"
        },
        {
            "id": 9710,
            "name": "Sripurusattampur"
        },
        {
            "id": 9711,
            "name": "Sujanpur"
        }
    ],
    "9689": [
        {
            "id": 9712,
            "name": "Bharatipur"
        },
        {
            "id": 9713,
            "name": "Birapurusotampur (New)"
        },
        {
            "id": 9714,
            "name": "Chandradeipur"
        },
        {
            "id": 9715,
            "name": "Dandamukundapur"
        },
        {
            "id": 9716,
            "name": "Durgadaspur (New)"
        },
        {
            "id": 9717,
            "name": "Gobindapur"
        },
        {
            "id": 9718,
            "name": "Hatasahi"
        },
        {
            "id": 9719,
            "name": "Jagannathpur"
        },
        {
            "id": 9720,
            "name": "Jasuapur"
        },
        {
            "id": 9721,
            "name": "Kanti"
        },
        {
            "id": 9722,
            "name": "Kulasekharpatana (New)"
        },
        {
            "id": 9723,
            "name": "Laxminarayanpur"
        },
        {
            "id": 9724,
            "name": "Mangalpur"
        },
        {
            "id": 9725,
            "name": "Nuasasan"
        },
        {
            "id": 9726,
            "name": "Orakala"
        },
        {
            "id": 9727,
            "name": "Pamasara"
        },
        {
            "id": 9728,
            "name": "Panidola (New)"
        },
        {
            "id": 9729,
            "name": "Poporanga"
        },
        {
            "id": 9730,
            "name": "Pubasasan"
        },
        {
            "id": 9731,
            "name": "Rathapurusotampur (New)"
        },
        {
            "id": 9732,
            "name": "Rupadeipur"
        },
        {
            "id": 9733,
            "name": "Sahajpur"
        },
        {
            "id": 9734,
            "name": "Sampur (New)"
        },
        {
            "id": 9735,
            "name": "Saraswatipur"
        },
        {
            "id": 9736,
            "name": "Teisipur"
        }
    ],
    "9690": [
        {
            "id": 9737,
            "name": "NAC"
        }
    ],
    "9691": [
        {
            "id": 9738,
            "name": "NAC"
        }
    ],
    "9692": [
        {
            "id": 9739,
            "name": "Baliguali"
        },
        {
            "id": 9740,
            "name": "Balipada"
        },
        {
            "id": 9741,
            "name": "Baliput"
        },
        {
            "id": 9742,
            "name": "Basudevpur"
        },
        {
            "id": 9743,
            "name": "Beladala (New)"
        },
        {
            "id": 9744,
            "name": "Bhailipur"
        },
        {
            "id": 9745,
            "name": "Bijay Ram Ch. Pur"
        },
        {
            "id": 9746,
            "name": "Bira Balabhadra Pur"
        },
        {
            "id": 9747,
            "name": "BiraHarekrushna Pur"
        },
        {
            "id": 9748,
            "name": "Bira Narasingh Pur"
        },
        {
            "id": 9749,
            "name": "Bira Pratap Pur"
        },
        {
            "id": 9750,
            "name": "Chalishibatia"
        },
        {
            "id": 9751,
            "name": "Chandanpur"
        },
        {
            "id": 9752,
            "name": "Chhaitana"
        },
        {
            "id": 9753,
            "name": "Gadabhingura"
        },
        {
            "id": 9754,
            "name": "Gada Mrugasira"
        },
        {
            "id": 9755,
            "name": "Ganganarayanpur (New)"
        },
        {
            "id": 9756,
            "name": "Gopinathpur"
        },
        {
            "id": 9757,
            "name": "Goudakera"
        },
        {
            "id": 9758,
            "name": "Hantuka"
        },
        {
            "id": 9759,
            "name": "Itibhuan"
        },
        {
            "id": 9760,
            "name": "Jamarasuan"
        },
        {
            "id": 9761,
            "name": "Kanhei Bidyadhar Pur"
        },
        {
            "id": 9762,
            "name": "Kasiharipur (New)"
        },
        {
            "id": 9763,
            "name": "Kerandipur"
        },
        {
            "id": 9764,
            "name": "Malatipatpur"
        },
        {
            "id": 9765,
            "name": "Pratap Purusottom Pur"
        },
        {
            "id": 9766,
            "name": "Pratap Rama Ch. Pur"
        },
        {
            "id": 9767,
            "name": "Raigorada"
        },
        {
            "id": 9768,
            "name": "Samanga"
        },
        {
            "id": 9769,
            "name": "Samangara (New)"
        },
        {
            "id": 9770,
            "name": "Sasan Damodar Pur"
        },
        {
            "id": 9771,
            "name": "Talajanga"
        }
    ],
    "9693": [
        {
            "id": 9772,
            "name": "Alibad"
        },
        {
            "id": 9773,
            "name": "Andarsingh (New)"
        },
        {
            "id": 9774,
            "name": "Anlajodi (New)"
        },
        {
            "id": 9775,
            "name": "Badal"
        },
        {
            "id": 9776,
            "name": "Badas"
        },
        {
            "id": 9777,
            "name": "Baku"
        },
        {
            "id": 9778,
            "name": "Bijipur"
        },
        {
            "id": 9779,
            "name": "Bindhan (New)"
        },
        {
            "id": 9780,
            "name": "Chupuring"
        },
        {
            "id": 9781,
            "name": "Deipur"
        },
        {
            "id": 9782,
            "name": "Dibyasinghpur"
        },
        {
            "id": 9783,
            "name": "Dochhian (New)"
        },
        {
            "id": 9784,
            "name": "Dokanda"
        },
        {
            "id": 9785,
            "name": "Gadabadaput"
        },
        {
            "id": 9786,
            "name": "Gadabalabhadrapur"
        },
        {
            "id": 9787,
            "name": "Gadakharada (New)"
        },
        {
            "id": 9788,
            "name": "Gadasahi"
        },
        {
            "id": 9789,
            "name": "Gadasanaput"
        },
        {
            "id": 9790,
            "name": "Gadisagoda (New)"
        },
        {
            "id": 9791,
            "name": "Gopinathapur"
        },
        {
            "id": 9792,
            "name": "Jamalgoda"
        },
        {
            "id": 9793,
            "name": "Kadua"
        },
        {
            "id": 9794,
            "name": "Kanas"
        },
        {
            "id": 9795,
            "name": "Khandahat"
        },
        {
            "id": 9796,
            "name": "Pandiakera"
        },
        {
            "id": 9797,
            "name": "Sahupada"
        },
        {
            "id": 9798,
            "name": "Sirei"
        },
        {
            "id": 9799,
            "name": "Trilochanpur"
        }
    ],
    "9694": [
        {
            "id": 9800,
            "name": "Alagum"
        },
        {
            "id": 9801,
            "name": "Alisha (New)"
        },
        {
            "id": 9802,
            "name": "Balapur"
        },
        {
            "id": 9803,
            "name": "Balarampur-garh"
        },
        {
            "id": 9804,
            "name": "Bidyadharpur"
        },
        {
            "id": 9805,
            "name": "Biraramchandrapur"
        },
        {
            "id": 9806,
            "name": "Biswanathpur"
        },
        {
            "id": 9807,
            "name": "Dasabidyadharpur"
        },
        {
            "id": 9808,
            "name": "Dugul"
        },
        {
            "id": 9809,
            "name": "Jayapur"
        },
        {
            "id": 9810,
            "name": "Kadua"
        },
        {
            "id": 9811,
            "name": "Ketakipatana"
        },
        {
            "id": 9812,
            "name": "Madhuban"
        },
        {
            "id": 9813,
            "name": "Mahura"
        },
        {
            "id": 9814,
            "name": "Mula-alasa"
        },
        {
            "id": 9815,
            "name": "Narendrapur"
        },
        {
            "id": 9816,
            "name": "Nuasomeswarpur"
        },
        {
            "id": 9817,
            "name": "Parajapada"
        },
        {
            "id": 9818,
            "name": "Penthapada"
        },
        {
            "id": 9819,
            "name": "Raikhandi"
        },
        {
            "id": 9820,
            "name": "Sandhara-sasan"
        },
        {
            "id": 9821,
            "name": "Sarangjodi (New)"
        },
        {
            "id": 9822,
            "name": "Sri Ramchandrapur"
        },
        {
            "id": 9823,
            "name": "Sukala"
        }
    ],
    "9695": [
        {
            "id": 9824,
            "name": "NAC"
        }
    ],
    "9696": [
        {
            "id": 9825,
            "name": "Baladiabandha"
        },
        {
            "id": 9826,
            "name": "Balyamba"
        },
        {
            "id": 9827,
            "name": "Bbkateni"
        },
        {
            "id": 9828,
            "name": "Beltikiri"
        },
        {
            "id": 9829,
            "name": "Bhapur"
        },
        {
            "id": 9830,
            "name": "Chandasekharprasad"
        },
        {
            "id": 9831,
            "name": "Dhirapatana"
        },
        {
            "id": 9832,
            "name": "Govindpur"
        },
        {
            "id": 9833,
            "name": "Kaimati"
        },
        {
            "id": 9834,
            "name": "Kakudibhag"
        },
        {
            "id": 9835,
            "name": "Kankadahad"
        },
        {
            "id": 9836,
            "name": "Kankadapal"
        },
        {
            "id": 9837,
            "name": "Madhusahupatana"
        },
        {
            "id": 9838,
            "name": "Mangalpur"
        },
        {
            "id": 9839,
            "name": "Manipur"
        },
        {
            "id": 9840,
            "name": "Nadiali"
        },
        {
            "id": 9841,
            "name": "Nagiapasi"
        },
        {
            "id": 9842,
            "name": "Nuagaon"
        },
        {
            "id": 9843,
            "name": "Radhadeipur"
        },
        {
            "id": 9844,
            "name": "Sankarpur"
        },
        {
            "id": 9845,
            "name": "Saptasajya"
        },
        {
            "id": 9846,
            "name": "Suakhaikateni"
        },
        {
            "id": 9847,
            "name": "Talabarakote"
        }
    ],
    "9697": [
        {
            "id": 9848,
            "name": "Bainsia"
        },
        {
            "id": 9849,
            "name": "Barada"
        },
        {
            "id": 9850,
            "name": "Bega"
        },
        {
            "id": 9851,
            "name": "Bidharpur"
        },
        {
            "id": 9852,
            "name": "Dasamanapatana"
        },
        {
            "id": 9853,
            "name": "Deogaon"
        },
        {
            "id": 9854,
            "name": "Digambarpur"
        },
        {
            "id": 9855,
            "name": "Gondia"
        },
        {
            "id": 9856,
            "name": "Gundurapasi"
        },
        {
            "id": 9857,
            "name": "Joranda"
        },
        {
            "id": 9858,
            "name": "Kabera"
        },
        {
            "id": 9859,
            "name": "Kaluria"
        },
        {
            "id": 9860,
            "name": "Karamula"
        },
        {
            "id": 9861,
            "name": "Kashipur"
        },
        {
            "id": 9862,
            "name": "Khandabandha"
        },
        {
            "id": 9863,
            "name": "Khankira"
        },
        {
            "id": 9864,
            "name": "Laulai"
        },
        {
            "id": 9865,
            "name": "Letheka"
        },
        {
            "id": 9866,
            "name": "Mandar"
        },
        {
            "id": 9867,
            "name": "Mathatentulia"
        },
        {
            "id": 9868,
            "name": "Neulapoi"
        },
        {
            "id": 9869,
            "name": "Nihalprasad"
        },
        {
            "id": 9870,
            "name": "Pingua"
        },
        {
            "id": 9871,
            "name": "Poruhakhoj"
        },
        {
            "id": 9872,
            "name": "Raitala"
        },
        {
            "id": 9873,
            "name": "Ratanpur"
        },
        {
            "id": 9874,
            "name": "Sadangi"
        },
        {
            "id": 9875,
            "name": "Santhapur"
        },
        {
            "id": 9876,
            "name": "Sorisiapada"
        }
    ],
    "9698": [
        {
            "id": 9877,
            "name": "Asarada"
        },
        {
            "id": 9878,
            "name": "Babandha"
        },
        {
            "id": 9879,
            "name": "Bampa"
        },
        {
            "id": 9880,
            "name": "Bausapokhari"
        },
        {
            "id": 9881,
            "name": "Buhalipal"
        },
        {
            "id": 9882,
            "name": "Chitalpur"
        },
        {
            "id": 9883,
            "name": "Dandiri"
        },
        {
            "id": 9884,
            "name": "Dudurakote"
        },
        {
            "id": 9885,
            "name": "Galapada"
        },
        {
            "id": 9886,
            "name": "Gandanali"
        },
        {
            "id": 9887,
            "name": "Giridharprasad"
        },
        {
            "id": 9888,
            "name": "Gulei"
        },
        {
            "id": 9889,
            "name": "Hatura"
        },
        {
            "id": 9890,
            "name": "Jarada"
        },
        {
            "id": 9891,
            "name": "Kadla"
        },
        {
            "id": 9892,
            "name": "Kalinga"
        },
        {
            "id": 9893,
            "name": "Kansara"
        },
        {
            "id": 9894,
            "name": "Kantamila"
        },
        {
            "id": 9895,
            "name": "Kantimili"
        },
        {
            "id": 9896,
            "name": "Karanda"
        },
        {
            "id": 9897,
            "name": "Khaliborei"
        },
        {
            "id": 9898,
            "name": "Kukuta"
        },
        {
            "id": 9899,
            "name": "Kunua"
        },
        {
            "id": 9900,
            "name": "Kutunia"
        },
        {
            "id": 9901,
            "name": "Madhapur"
        },
        {
            "id": 9902,
            "name": "Mahalunda"
        },
        {
            "id": 9903,
            "name": "Nabakishorepur"
        },
        {
            "id": 9904,
            "name": "Nuabaga"
        },
        {
            "id": 9905,
            "name": "Nuagaon"
        },
        {
            "id": 9906,
            "name": "P.P. Kote"
        },
        {
            "id": 9907,
            "name": "Patala"
        },
        {
            "id": 9908,
            "name": "Ranjagola"
        },
        {
            "id": 9909,
            "name": "Rasol"
        },
        {
            "id": 9910,
            "name": "Sanjapada"
        },
        {
            "id": 9911,
            "name": "Thokar"
        }
    ],
    "9699": [
        {
            "id": 9912,
            "name": "NAC"
        }
    ],
    "9700": [
        {
            "id": 9913,
            "name": "Balaramprasad"
        },
        {
            "id": 9914,
            "name": "Balarampur"
        },
        {
            "id": 9915,
            "name": "Bangursingh"
        },
        {
            "id": 9916,
            "name": "Bido"
        },
        {
            "id": 9917,
            "name": "Bodalo"
        },
        {
            "id": 9918,
            "name": "Gadasila"
        },
        {
            "id": 9919,
            "name": "Ghatipiri"
        },
        {
            "id": 9920,
            "name": "Gobindprasad"
        },
        {
            "id": 9921,
            "name": "Gundichapada"
        },
        {
            "id": 9922,
            "name": "Gunudei"
        },
        {
            "id": 9923,
            "name": "Indupur"
        },
        {
            "id": 9924,
            "name": "Kalanga"
        },
        {
            "id": 9925,
            "name": "Kamalanga"
        },
        {
            "id": 9926,
            "name": "Kandabindha"
        },
        {
            "id": 9927,
            "name": "Kasiadihi"
        },
        {
            "id": 9928,
            "name": "Khadagprasad"
        },
        {
            "id": 9929,
            "name": "Khuntujhari"
        },
        {
            "id": 9930,
            "name": "Kottam"
        },
        {
            "id": 9931,
            "name": "Kusupanga"
        },
        {
            "id": 9932,
            "name": "Mangalapur"
        },
        {
            "id": 9933,
            "name": "Motanga"
        },
        {
            "id": 9934,
            "name": "Nadhara"
        },
        {
            "id": 9935,
            "name": "Nayabhagirathipur"
        },
        {
            "id": 9936,
            "name": "Nimidha"
        },
        {
            "id": 9937,
            "name": "Odapada"
        },
        {
            "id": 9938,
            "name": "Sadashibapur"
        },
        {
            "id": 9939,
            "name": "Shibapur"
        },
        {
            "id": 9940,
            "name": "Siminai"
        }
    ],
    "9701": [
        {
            "id": 9941,
            "name": "Arakhapal"
        },
        {
            "id": 9942,
            "name": "Balibo"
        },
        {
            "id": 9943,
            "name": "Baruan(B)"
        },
        {
            "id": 9944,
            "name": "Bhusal"
        },
        {
            "id": 9945,
            "name": "Dayanabil"
        },
        {
            "id": 9946,
            "name": "Dhalapada"
        },
        {
            "id": 9947,
            "name": "Dighi"
        },
        {
            "id": 9948,
            "name": "Ekatali"
        },
        {
            "id": 9949,
            "name": "Gadanrusinghprasad"
        },
        {
            "id": 9950,
            "name": "Jamunakote"
        },
        {
            "id": 9951,
            "name": "Jiral"
        },
        {
            "id": 9952,
            "name": "Kanapal(Mahulpal)"
        },
        {
            "id": 9953,
            "name": "Kunida"
        },
        {
            "id": 9954,
            "name": "Marthapur"
        },
        {
            "id": 9955,
            "name": "Mathakoragola"
        },
        {
            "id": 9956,
            "name": "Mrudanga"
        },
        {
            "id": 9957,
            "name": "Odisha"
        },
        {
            "id": 9958,
            "name": "Surapratapur"
        }
    ],
    "9702": [
        {
            "id": 9959,
            "name": "NAC"
        }
    ],
    "9703": [
        {
            "id": 9960,
            "name": "Banasingh"
        },
        {
            "id": 9961,
            "name": "Barada"
        },
        {
            "id": 9962,
            "name": "Chaulia"
        },
        {
            "id": 9963,
            "name": "Gengutia"
        },
        {
            "id": 9964,
            "name": "Sankulei"
        },
        {
            "id": 9965,
            "name": "Sogarpasi"
        },
        {
            "id": 9966,
            "name": "Tarava"
        }
    ],
    "9704": [
        {
            "id": 9967,
            "name": "Anlabereni"
        },
        {
            "id": 9968,
            "name": "Badasuanla"
        },
        {
            "id": 9969,
            "name": "Baisinga"
        },
        {
            "id": 9970,
            "name": "Baligorada"
        },
        {
            "id": 9971,
            "name": "Bankuala"
        },
        {
            "id": 9972,
            "name": "Baruan(K)"
        },
        {
            "id": 9973,
            "name": "Baunspal"
        },
        {
            "id": 9974,
            "name": "Bhairpur"
        },
        {
            "id": 9975,
            "name": "Budibili"
        },
        {
            "id": 9976,
            "name": "Jagannathpur"
        },
        {
            "id": 9977,
            "name": "Kadua"
        },
        {
            "id": 9978,
            "name": "Kanpura"
        },
        {
            "id": 9979,
            "name": "Kotagara"
        },
        {
            "id": 9980,
            "name": "Mahulpal"
        },
        {
            "id": 9981,
            "name": "Rainarasingapur"
        },
        {
            "id": 9982,
            "name": "Saruali"
        },
        {
            "id": 9983,
            "name": "Sogar"
        }
    ],
    "9705": [
        {
            "id": 9984,
            "name": "NAC"
        }
    ],
    "9706": [
        {
            "id": 9985,
            "name": "Anlabereni"
        },
        {
            "id": 9986,
            "name": "Kantapal"
        },
        {
            "id": 9987,
            "name": "Kantiokateni"
        },
        {
            "id": 9988,
            "name": "Kantioputasahi"
        },
        {
            "id": 9989,
            "name": "Kusumjodi"
        },
        {
            "id": 9990,
            "name": "Tumusinga"
        }
    ],
    "9707": [
        {
            "id": 9991,
            "name": "Balikuma"
        },
        {
            "id": 9992,
            "name": "Bam"
        },
        {
            "id": 9993,
            "name": "Batagaon"
        },
        {
            "id": 9994,
            "name": "Birasal"
        },
        {
            "id": 9995,
            "name": "Biribolei"
        },
        {
            "id": 9996,
            "name": "Chandpur"
        },
        {
            "id": 9997,
            "name": "Dasipur"
        },
        {
            "id": 9998,
            "name": "Gadapalasuni"
        },
        {
            "id": 9999,
            "name": "Ghagarmunda"
        },
        {
            "id": 10000,
            "name": "Jhilli"
        },
        {
            "id": 10001,
            "name": "Kankadahad"
        },
        {
            "id": 10002,
            "name": "Kantapal"
        },
        {
            "id": 10003,
            "name": "Kantol"
        },
        {
            "id": 10004,
            "name": "Karagola"
        },
        {
            "id": 10005,
            "name": "Kerjoli"
        },
        {
            "id": 10006,
            "name": "Kuturia"
        },
        {
            "id": 10007,
            "name": "Mahabirod"
        },
        {
            "id": 10008,
            "name": "Makuakateni"
        },
        {
            "id": 10009,
            "name": "Maruabil"
        },
        {
            "id": 10010,
            "name": "Pangatira"
        },
        {
            "id": 10011,
            "name": "Raibol"
        }
    ],
    "9708": [
        {
            "id": 10012,
            "name": "Akhuapal"
        },
        {
            "id": 10013,
            "name": "Ambapalasa"
        },
        {
            "id": 10014,
            "name": "Badajhara"
        },
        {
            "id": 10015,
            "name": "Barihapur"
        },
        {
            "id": 10016,
            "name": "Basoi"
        },
        {
            "id": 10017,
            "name": "Basulei"
        },
        {
            "id": 10018,
            "name": "Chandapur"
        },
        {
            "id": 10019,
            "name": "Damala"
        },
        {
            "id": 10020,
            "name": "Gadaparjang"
        },
        {
            "id": 10021,
            "name": "Gengutia"
        },
        {
            "id": 10022,
            "name": "Jayapurakateni"
        },
        {
            "id": 10023,
            "name": "Kalada"
        },
        {
            "id": 10024,
            "name": "Kandarsinga"
        },
        {
            "id": 10025,
            "name": "Kankadasoda"
        },
        {
            "id": 10026,
            "name": "Kankili"
        },
        {
            "id": 10027,
            "name": "Kantor"
        },
        {
            "id": 10028,
            "name": "Kualo"
        },
        {
            "id": 10029,
            "name": "Kumusi"
        },
        {
            "id": 10030,
            "name": "Lodhani"
        },
        {
            "id": 10031,
            "name": "Manikamara"
        },
        {
            "id": 10032,
            "name": "Muktapasi"
        },
        {
            "id": 10033,
            "name": "Mundeilo"
        },
        {
            "id": 10034,
            "name": "Patarapada"
        },
        {
            "id": 10035,
            "name": "Patharkhamba"
        },
        {
            "id": 10036,
            "name": "Pitiri"
        },
        {
            "id": 10037,
            "name": "Renthapat"
        },
        {
            "id": 10038,
            "name": "Roda"
        },
        {
            "id": 10039,
            "name": "Sanda"
        },
        {
            "id": 10040,
            "name": "Saranga"
        }
    ],
    "9709": [
        {
            "id": 10041,
            "name": "Balanda"
        },
        {
            "id": 10042,
            "name": "Ballam"
        },
        {
            "id": 10043,
            "name": "Bamparda"
        },
        {
            "id": 10044,
            "name": "Basaloi"
        },
        {
            "id": 10045,
            "name": "Bijay Nagar"
        },
        {
            "id": 10046,
            "name": "Dandasingha"
        },
        {
            "id": 10047,
            "name": "Danra"
        },
        {
            "id": 10048,
            "name": "Dantaribahal"
        },
        {
            "id": 10049,
            "name": "Golabandha"
        },
        {
            "id": 10050,
            "name": "Gurusang"
        },
        {
            "id": 10051,
            "name": "Jharakandhal"
        },
        {
            "id": 10052,
            "name": "Kadopada"
        },
        {
            "id": 10053,
            "name": "Kaliapal"
        },
        {
            "id": 10054,
            "name": "Kalla"
        },
        {
            "id": 10055,
            "name": "Kandhal"
        },
        {
            "id": 10056,
            "name": "Kantapali"
        },
        {
            "id": 10057,
            "name": "Kaunsibahal"
        },
        {
            "id": 10058,
            "name": "Kelda"
        },
        {
            "id": 10059,
            "name": "Madhyapur"
        },
        {
            "id": 10060,
            "name": "Raitabahal"
        },
        {
            "id": 10061,
            "name": "Rambhei"
        },
        {
            "id": 10062,
            "name": "Saida"
        },
        {
            "id": 10063,
            "name": "Saruali"
        },
        {
            "id": 10064,
            "name": "Singhsal"
        }
    ],
    "9710": [
        {
            "id": 10065,
            "name": "NAC"
        }
    ],
    "9711": [
        {
            "id": 10066,
            "name": "adas"
        },
        {
            "id": 10067,
            "name": "baghabar"
        },
        {
            "id": 10068,
            "name": "budhapal"
        },
        {
            "id": 10069,
            "name": "budido"
        },
        {
            "id": 10070,
            "name": "chadeimara"
        },
        {
            "id": 10071,
            "name": "gundiapali"
        },
        {
            "id": 10072,
            "name": "kantabahal"
        },
        {
            "id": 10073,
            "name": "karlaga"
        },
        {
            "id": 10074,
            "name": "khilei"
        },
        {
            "id": 10075,
            "name": "Kolhabasa"
        },
        {
            "id": 10076,
            "name": "kundheigola"
        },
        {
            "id": 10077,
            "name": "lulang"
        },
        {
            "id": 10078,
            "name": "malehipada"
        },
        {
            "id": 10079,
            "name": "naulipada"
        },
        {
            "id": 10080,
            "name": "nuadihi"
        },
        {
            "id": 10081,
            "name": "Nuapada"
        },
        {
            "id": 10082,
            "name": "palsama"
        },
        {
            "id": 10083,
            "name": "para"
        },
        {
            "id": 10084,
            "name": "reamal"
        },
        {
            "id": 10085,
            "name": "tabada"
        },
        {
            "id": 10086,
            "name": "tarang"
        },
        {
            "id": 10087,
            "name": "Tentalabahal"
        },
        {
            "id": 10088,
            "name": "tinkbir"
        },
        {
            "id": 10089,
            "name": "tuhilamal"
        },
        {
            "id": 10090,
            "name": "Tungamal"
        },
        {
            "id": 10091,
            "name": "utunia"
        }
    ],
    "9712": [
        {
            "id": 10092,
            "name": "Badchhapal"
        },
        {
            "id": 10093,
            "name": "Baniakilinda"
        },
        {
            "id": 10094,
            "name": "Chhepilipali"
        },
        {
            "id": 10095,
            "name": "Dholpada"
        },
        {
            "id": 10096,
            "name": "Dimirikuda"
        },
        {
            "id": 10097,
            "name": "Dudhianali"
        },
        {
            "id": 10098,
            "name": "Gandam"
        },
        {
            "id": 10099,
            "name": "Jharamunda"
        },
        {
            "id": 10100,
            "name": "Jhargogua"
        },
        {
            "id": 10101,
            "name": "Kalanda"
        },
        {
            "id": 10102,
            "name": "Kansar"
        },
        {
            "id": 10103,
            "name": "Kendeijuri"
        },
        {
            "id": 10104,
            "name": "Laimura"
        },
        {
            "id": 10105,
            "name": "Palkudar"
        },
        {
            "id": 10106,
            "name": "Parposi"
        },
        {
            "id": 10107,
            "name": "Sado"
        },
        {
            "id": 10108,
            "name": "Suguda"
        },
        {
            "id": 10109,
            "name": "Tainsar"
        },
        {
            "id": 10110,
            "name": "Talkundi"
        },
        {
            "id": 10111,
            "name": "Tileibani"
        }
    ],
    "9713": [
        {
            "id": 10112,
            "name": "B.CUTTACK"
        },
        {
            "id": 10113,
            "name": "Betiapada"
        },
        {
            "id": 10114,
            "name": "Bhatapur"
        },
        {
            "id": 10115,
            "name": "Chanchadaguda"
        },
        {
            "id": 10116,
            "name": "Chatikana"
        },
        {
            "id": 10117,
            "name": "Daliakuji"
        },
        {
            "id": 10118,
            "name": "Dukum"
        },
        {
            "id": 10119,
            "name": "Dumaraneli"
        },
        {
            "id": 10120,
            "name": "Durgi"
        },
        {
            "id": 10121,
            "name": "Hajaridanga"
        },
        {
            "id": 10122,
            "name": "Hatamuniguda"
        },
        {
            "id": 10123,
            "name": "Jigidi"
        },
        {
            "id": 10124,
            "name": "Kanabae"
        },
        {
            "id": 10125,
            "name": "Kankubadi"
        },
        {
            "id": 10126,
            "name": "Kumaradhamuni"
        },
        {
            "id": 10127,
            "name": "Kurli"
        },
        {
            "id": 10128,
            "name": "Kutraguda"
        },
        {
            "id": 10129,
            "name": "PAEKA DAKULA GUDA"
        },
        {
            "id": 10130,
            "name": "Rasakola"
        },
        {
            "id": 10131,
            "name": "Sahada"
        },
        {
            "id": 10132,
            "name": "Thuapadi"
        }
    ],
    "9714": [
        {
            "id": 10133,
            "name": "Budaguda"
        },
        {
            "id": 10134,
            "name": "Dhamunipanga"
        },
        {
            "id": 10135,
            "name": "K.SINGAPUR"
        },
        {
            "id": 10136,
            "name": "Kandakatipadu"
        },
        {
            "id": 10137,
            "name": "Karapa"
        },
        {
            "id": 10138,
            "name": "Majhiguda"
        },
        {
            "id": 10139,
            "name": "Narayanapur"
        },
        {
            "id": 10140,
            "name": "Parsali"
        },
        {
            "id": 10141,
            "name": "Polama"
        },
        {
            "id": 10142,
            "name": "Pujariguda"
        },
        {
            "id": 10143,
            "name": "Serigumma"
        },
        {
            "id": 10144,
            "name": "Sikarapae"
        },
        {
            "id": 10145,
            "name": "Singari"
        },
        {
            "id": 10146,
            "name": "Sunakhandi"
        }
    ],
    "9715": [
        {
            "id": 10147,
            "name": "Badakhilapadara"
        },
        {
            "id": 10148,
            "name": "Bankili"
        },
        {
            "id": 10149,
            "name": "Bhoimada"
        },
        {
            "id": 10150,
            "name": "Dumuriguda"
        },
        {
            "id": 10151,
            "name": "Dunduli"
        },
        {
            "id": 10152,
            "name": "Gatiseshakhala"
        },
        {
            "id": 10153,
            "name": "Jhoradi"
        },
        {
            "id": 10154,
            "name": "Kartikaguda"
        },
        {
            "id": 10155,
            "name": "Katikana"
        },
        {
            "id": 10156,
            "name": "Kaylasapur"
        },
        {
            "id": 10157,
            "name": "Keliguda"
        },
        {
            "id": 10158,
            "name": "Khedapada"
        },
        {
            "id": 10159,
            "name": "Kolanara"
        },
        {
            "id": 10160,
            "name": "Mukundapur"
        },
        {
            "id": 10161,
            "name": "Rekhapadara"
        },
        {
            "id": 10162,
            "name": "Suri"
        },
        {
            "id": 10163,
            "name": "Therubali"
        }
    ],
    "9716": [
        {
            "id": 10164,
            "name": "Agulo"
        },
        {
            "id": 10165,
            "name": "Ambadola"
        },
        {
            "id": 10166,
            "name": "Amlabhatta"
        },
        {
            "id": 10167,
            "name": "Bhairabgada"
        },
        {
            "id": 10168,
            "name": "Dimiriguda"
        },
        {
            "id": 10169,
            "name": "Ghamatguda"
        },
        {
            "id": 10170,
            "name": "Ichhapur"
        },
        {
            "id": 10171,
            "name": "Jagdalpur"
        },
        {
            "id": 10172,
            "name": "Kumudabali"
        },
        {
            "id": 10173,
            "name": "Muniguda"
        },
        {
            "id": 10174,
            "name": "Munikhol"
        },
        {
            "id": 10175,
            "name": "P.RANIPINDA"
        },
        {
            "id": 10176,
            "name": "Patraguda"
        },
        {
            "id": 10177,
            "name": "Raghubari"
        },
        {
            "id": 10178,
            "name": "Saradhapur"
        },
        {
            "id": 10179,
            "name": "Sibapadar"
        },
        {
            "id": 10180,
            "name": "Telengapadar"
        }
    ],
    "9717": [
        {
            "id": 10181,
            "name": "Bijapur"
        },
        {
            "id": 10182,
            "name": "Budubali"
        },
        {
            "id": 10183,
            "name": "Chandrapur"
        },
        {
            "id": 10184,
            "name": "Dangasorada"
        },
        {
            "id": 10185,
            "name": "Hanumantpur"
        },
        {
            "id": 10186,
            "name": "Piskapanga"
        },
        {
            "id": 10187,
            "name": "Sarikima"
        },
        {
            "id": 10188,
            "name": "Turiguda"
        }
    ],
    "9718": [
        {
            "id": 10189,
            "name": "Asada"
        },
        {
            "id": 10190,
            "name": "Kadama"
        },
        {
            "id": 10191,
            "name": "Karlaghati"
        },
        {
            "id": 10192,
            "name": "Khariguda"
        },
        {
            "id": 10193,
            "name": "M.K RAI"
        },
        {
            "id": 10194,
            "name": "Madhubana"
        },
        {
            "id": 10195,
            "name": "Pendili"
        },
        {
            "id": 10196,
            "name": "Sanahuma"
        },
        {
            "id": 10197,
            "name": "Siriguda"
        }
    ],
    "9719": [
        {
            "id": 10198,
            "name": "NAC"
        }
    ],
    "9720": [
        {
            "id": 10199,
            "name": "Abada"
        },
        {
            "id": 10200,
            "name": "Bagasala"
        },
        {
            "id": 10201,
            "name": "Bhimpur"
        },
        {
            "id": 10202,
            "name": "Chalakamba"
        },
        {
            "id": 10203,
            "name": "Chinasari"
        },
        {
            "id": 10204,
            "name": "Dambasara"
        },
        {
            "id": 10205,
            "name": "Gadiakhala"
        },
        {
            "id": 10206,
            "name": "Ghanntri"
        },
        {
            "id": 10207,
            "name": "Gothalpadar"
        },
        {
            "id": 10208,
            "name": "Jagannthpur"
        },
        {
            "id": 10209,
            "name": "Jaltar"
        },
        {
            "id": 10210,
            "name": "Kulusing"
        },
        {
            "id": 10211,
            "name": "Marama"
        },
        {
            "id": 10212,
            "name": "Puttasing"
        },
        {
            "id": 10213,
            "name": "Regeda"
        },
        {
            "id": 10214,
            "name": "Sagada"
        },
        {
            "id": 10215,
            "name": "Sirijholi"
        },
        {
            "id": 10216,
            "name": "Talana"
        },
        {
            "id": 10217,
            "name": "Titimiri"
        }
    ],
    "9721": [
        {
            "id": 10218,
            "name": "NAC"
        }
    ],
    "9722": [
        {
            "id": 10219,
            "name": "Akusi"
        },
        {
            "id": 10220,
            "name": "Derigao"
        },
        {
            "id": 10221,
            "name": "Endupur"
        },
        {
            "id": 10222,
            "name": "Gudiabanda"
        },
        {
            "id": 10223,
            "name": "Guluguda"
        },
        {
            "id": 10224,
            "name": "Jatili"
        },
        {
            "id": 10225,
            "name": "Khamapadara"
        },
        {
            "id": 10226,
            "name": "Kilapadara"
        },
        {
            "id": 10227,
            "name": "Likitipadara"
        },
        {
            "id": 10228,
            "name": "Naira"
        },
        {
            "id": 10229,
            "name": "Nuagada"
        },
        {
            "id": 10230,
            "name": "Padmapur"
        },
        {
            "id": 10231,
            "name": "Tembaguda"
        }
    ],
    "9723": [
        {
            "id": 10232,
            "name": "Bhamini"
        },
        {
            "id": 10233,
            "name": "Buthing"
        },
        {
            "id": 10234,
            "name": "Gogupadu"
        },
        {
            "id": 10235,
            "name": "Gosaigulumunda"
        },
        {
            "id": 10236,
            "name": "Gulumunda"
        },
        {
            "id": 10237,
            "name": "Gulunthi"
        },
        {
            "id": 10238,
            "name": "Kujendri"
        },
        {
            "id": 10239,
            "name": "Nilamguda"
        },
        {
            "id": 10240,
            "name": "Parikhiti"
        },
        {
            "id": 10241,
            "name": "Penkam"
        },
        {
            "id": 10242,
            "name": "Sundhidhamuni"
        },
        {
            "id": 10243,
            "name": "Ukkamba"
        }
    ],
    "9724": [
        {
            "id": 10244,
            "name": "Adajore"
        },
        {
            "id": 10245,
            "name": "Bankamba"
        },
        {
            "id": 10246,
            "name": "Chandragiri"
        },
        {
            "id": 10247,
            "name": "Dangasila"
        },
        {
            "id": 10248,
            "name": "Dudukabahala"
        },
        {
            "id": 10249,
            "name": "Godibali"
        },
        {
            "id": 10250,
            "name": "Gorakhapur"
        },
        {
            "id": 10251,
            "name": "Hadiguda"
        },
        {
            "id": 10252,
            "name": "Kashipur"
        },
        {
            "id": 10253,
            "name": "Khuriga"
        },
        {
            "id": 10254,
            "name": "Kodipari"
        },
        {
            "id": 10255,
            "name": "Kucheipadara"
        },
        {
            "id": 10256,
            "name": "Maikancha"
        },
        {
            "id": 10257,
            "name": "Mandibisi"
        },
        {
            "id": 10258,
            "name": "Manusagaa"
        },
        {
            "id": 10259,
            "name": "Nakatiguda"
        },
        {
            "id": 10260,
            "name": "Podapadi"
        },
        {
            "id": 10261,
            "name": "Renga Kaloni"
        },
        {
            "id": 10262,
            "name": "Sankarada"
        },
        {
            "id": 10263,
            "name": "Sinduraghati"
        },
        {
            "id": 10264,
            "name": "Siripae"
        },
        {
            "id": 10265,
            "name": "Sungera"
        },
        {
            "id": 10266,
            "name": "Talajhiri"
        },
        {
            "id": 10267,
            "name": "Tikiri"
        }
    ],
    "9725": [
        {
            "id": 10268,
            "name": "Badaalubadi"
        },
        {
            "id": 10269,
            "name": "Badairukubadi"
        },
        {
            "id": 10270,
            "name": "Baesingi"
        },
        {
            "id": 10271,
            "name": "Bourigahalua"
        },
        {
            "id": 10272,
            "name": "Chandili"
        },
        {
            "id": 10273,
            "name": "DANGA LODI"
        },
        {
            "id": 10274,
            "name": "Durgapadu"
        },
        {
            "id": 10275,
            "name": "Gajigaa"
        },
        {
            "id": 10276,
            "name": "Guma"
        },
        {
            "id": 10277,
            "name": "Halua"
        },
        {
            "id": 10278,
            "name": "Hatasesakala"
        },
        {
            "id": 10279,
            "name": "JAMEDEI PENTA"
        },
        {
            "id": 10280,
            "name": "Kampamaligao"
        },
        {
            "id": 10281,
            "name": "Karlakana"
        },
        {
            "id": 10282,
            "name": "Karubai"
        },
        {
            "id": 10283,
            "name": "Kerada"
        },
        {
            "id": 10284,
            "name": "KOTA PETA"
        },
        {
            "id": 10285,
            "name": "Kuli"
        },
        {
            "id": 10286,
            "name": "Kumbikota"
        },
        {
            "id": 10287,
            "name": "Kumitipeta"
        },
        {
            "id": 10288,
            "name": "Kutuli"
        },
        {
            "id": 10289,
            "name": "Matikana"
        },
        {
            "id": 10290,
            "name": "Mirabali"
        },
        {
            "id": 10291,
            "name": "Nakiti"
        },
        {
            "id": 10292,
            "name": "Penta"
        },
        {
            "id": 10293,
            "name": "Pipalaguda"
        },
        {
            "id": 10294,
            "name": "Pitamahal"
        },
        {
            "id": 10295,
            "name": "Tadama"
        }
    ],
    "9726": [
        {
            "id": 10296,
            "name": "NAC"
        }
    ],
    "9727": [
        {
            "id": 10297,
            "name": "Barakhama"
        },
        {
            "id": 10298,
            "name": "Bataguda"
        },
        {
            "id": 10299,
            "name": "Brudukia"
        },
        {
            "id": 10300,
            "name": "Dadakangia"
        },
        {
            "id": 10301,
            "name": "Khamankhol"
        },
        {
            "id": 10302,
            "name": "Kutikia"
        },
        {
            "id": 10303,
            "name": "Landagan"
        },
        {
            "id": 10304,
            "name": "Midiakia"
        },
        {
            "id": 10305,
            "name": "Parampanga"
        },
        {
            "id": 10306,
            "name": "Rebingia"
        },
        {
            "id": 10307,
            "name": "Rutungia"
        },
        {
            "id": 10308,
            "name": "Salaguda"
        },
        {
            "id": 10309,
            "name": "Sindhrigan"
        },
        {
            "id": 10310,
            "name": "Sudra"
        }
    ],
    "9728": [
        {
            "id": 10311,
            "name": "NAC"
        }
    ],
    "9729": [
        {
            "id": 10312,
            "name": "Adimaha"
        },
        {
            "id": 10313,
            "name": "Balligada"
        },
        {
            "id": 10314,
            "name": "Bandaguda"
        },
        {
            "id": 10315,
            "name": "Chanchedi"
        },
        {
            "id": 10316,
            "name": "Gandrigan"
        },
        {
            "id": 10317,
            "name": "Gunjibadi"
        },
        {
            "id": 10318,
            "name": "Jiridikia"
        },
        {
            "id": 10319,
            "name": "Kanjamendi"
        },
        {
            "id": 10320,
            "name": "Kudutuli"
        },
        {
            "id": 10321,
            "name": "Lettingia"
        },
        {
            "id": 10322,
            "name": "Mahasingi"
        },
        {
            "id": 10323,
            "name": "Sainipada"
        },
        {
            "id": 10324,
            "name": "Sarangada"
        },
        {
            "id": 10325,
            "name": "Sirtiguda"
        }
    ],
    "9730": [
        {
            "id": 10326,
            "name": "Dharakot"
        },
        {
            "id": 10327,
            "name": "Durgapanga"
        },
        {
            "id": 10328,
            "name": "Gugurumaha"
        },
        {
            "id": 10329,
            "name": "Jadabali"
        },
        {
            "id": 10330,
            "name": "Jubaguda"
        },
        {
            "id": 10331,
            "name": "Kotagarh"
        },
        {
            "id": 10332,
            "name": "Madaguda"
        },
        {
            "id": 10333,
            "name": "Marlang"
        },
        {
            "id": 10334,
            "name": "Ora"
        },
        {
            "id": 10335,
            "name": "Pakari"
        },
        {
            "id": 10336,
            "name": "Parigada"
        },
        {
            "id": 10337,
            "name": "Ranipanga"
        },
        {
            "id": 10338,
            "name": "Sartul"
        },
        {
            "id": 10339,
            "name": "Srirampur"
        },
        {
            "id": 10340,
            "name": "Subarnagiri"
        }
    ],
    "9731": [
        {
            "id": 10341,
            "name": "Belghar"
        },
        {
            "id": 10342,
            "name": "Bhandarangi"
        },
        {
            "id": 10343,
            "name": "Bilamala"
        },
        {
            "id": 10344,
            "name": "Guma"
        },
        {
            "id": 10345,
            "name": "Jhiripani"
        },
        {
            "id": 10346,
            "name": "Karkudupa"
        },
        {
            "id": 10347,
            "name": "Kurtamgada"
        },
        {
            "id": 10348,
            "name": "Lankagada"
        },
        {
            "id": 10349,
            "name": "Mundigada"
        },
        {
            "id": 10350,
            "name": "Sirla"
        },
        {
            "id": 10351,
            "name": "Tumudibandh"
        }
    ],
    "9732": [
        {
            "id": 10352,
            "name": "Badabanga"
        },
        {
            "id": 10353,
            "name": "Bamunigaon"
        },
        {
            "id": 10354,
            "name": "Bhramarbadi"
        },
        {
            "id": 10355,
            "name": "Buadaguda"
        },
        {
            "id": 10356,
            "name": "Danekbadi"
        },
        {
            "id": 10357,
            "name": "Daringbadi"
        },
        {
            "id": 10358,
            "name": "Dasingbadi"
        },
        {
            "id": 10359,
            "name": "Gadapur"
        },
        {
            "id": 10360,
            "name": "Greenbadi"
        },
        {
            "id": 10361,
            "name": "Gumikia"
        },
        {
            "id": 10362,
            "name": "Hatimunda"
        },
        {
            "id": 10363,
            "name": "Jhinjiriguda"
        },
        {
            "id": 10364,
            "name": "Katinga"
        },
        {
            "id": 10365,
            "name": "Kirikuti"
        },
        {
            "id": 10366,
            "name": "Kumbharigaon"
        },
        {
            "id": 10367,
            "name": "Partamaha"
        },
        {
            "id": 10368,
            "name": "Pliheri"
        },
        {
            "id": 10369,
            "name": "Saramuli"
        },
        {
            "id": 10370,
            "name": "Siangbali"
        },
        {
            "id": 10371,
            "name": "Simanbadi"
        },
        {
            "id": 10372,
            "name": "Sirkabarga"
        },
        {
            "id": 10373,
            "name": "Sonpur"
        },
        {
            "id": 10374,
            "name": "Sraniketa"
        },
        {
            "id": 10375,
            "name": "Tajungia"
        },
        {
            "id": 10376,
            "name": "Tilori"
        }
    ],
    "9733": [
        {
            "id": 10377,
            "name": "Gresingia"
        },
        {
            "id": 10378,
            "name": "Kalinga"
        },
        {
            "id": 10379,
            "name": "Katingia"
        },
        {
            "id": 10380,
            "name": "Lingagada"
        },
        {
            "id": 10381,
            "name": "Malikpadi"
        },
        {
            "id": 10382,
            "name": "Raikala"
        },
        {
            "id": 10383,
            "name": "Rantingia"
        },
        {
            "id": 10384,
            "name": "Talarimaha"
        }
    ],
    "9734": [
        {
            "id": 10385,
            "name": "NAC"
        }
    ],
    "9735": [
        {
            "id": 10386,
            "name": "Badabarba"
        },
        {
            "id": 10387,
            "name": "Beredakia"
        },
        {
            "id": 10388,
            "name": "Dadingia"
        },
        {
            "id": 10389,
            "name": "Gumamaha"
        },
        {
            "id": 10390,
            "name": "Indragada"
        },
        {
            "id": 10391,
            "name": "Karada"
        },
        {
            "id": 10392,
            "name": "Mandakia"
        },
        {
            "id": 10393,
            "name": "Manikeswar"
        },
        {
            "id": 10394,
            "name": "Petapanga"
        },
        {
            "id": 10395,
            "name": "Raikia"
        },
        {
            "id": 10396,
            "name": "Ranaba"
        },
        {
            "id": 10397,
            "name": "Sugadabadi"
        }
    ],
    "9736": [
        {
            "id": 10398,
            "name": "Badimunda"
        },
        {
            "id": 10399,
            "name": "Burbinaju"
        },
        {
            "id": 10400,
            "name": "Gadaguda"
        },
        {
            "id": 10401,
            "name": "Gardingia"
        },
        {
            "id": 10402,
            "name": "Gutingia"
        },
        {
            "id": 10403,
            "name": "Kaijhar"
        },
        {
            "id": 10404,
            "name": "Katimaha"
        },
        {
            "id": 10405,
            "name": "Paburia"
        },
        {
            "id": 10406,
            "name": "Padangi"
        },
        {
            "id": 10407,
            "name": "Pikaradi"
        },
        {
            "id": 10408,
            "name": "Tikabali"
        }
    ],
    "9737": [
        {
            "id": 10409,
            "name": "Arabaka"
        },
        {
            "id": 10410,
            "name": "Bapalmendi-b"
        },
        {
            "id": 10411,
            "name": "Bapalmendi-l"
        },
        {
            "id": 10412,
            "name": "Bastingia"
        },
        {
            "id": 10413,
            "name": "Beheragan"
        },
        {
            "id": 10414,
            "name": "Brhmanpad"
        },
        {
            "id": 10415,
            "name": "Chahali"
        },
        {
            "id": 10416,
            "name": "Chakapad"
        },
        {
            "id": 10417,
            "name": "Kakharujhol"
        },
        {
            "id": 10418,
            "name": "Kusumendi"
        },
        {
            "id": 10419,
            "name": "Pasara"
        },
        {
            "id": 10420,
            "name": "Sankarakhol"
        }
    ],
    "9738": [
        {
            "id": 10421,
            "name": "Adasipada"
        },
        {
            "id": 10422,
            "name": "Arapaju"
        },
        {
            "id": 10423,
            "name": "Balaskumpa"
        },
        {
            "id": 10424,
            "name": "Bilabadi"
        },
        {
            "id": 10425,
            "name": "Dalapada"
        },
        {
            "id": 10426,
            "name": "Dutimendi"
        },
        {
            "id": 10427,
            "name": "Dutipada"
        },
        {
            "id": 10428,
            "name": "Gudari"
        },
        {
            "id": 10429,
            "name": "Khajuripada"
        },
        {
            "id": 10430,
            "name": "Pirikudi"
        },
        {
            "id": 10431,
            "name": "R.Nuagaon"
        },
        {
            "id": 10432,
            "name": "Sudreju"
        },
        {
            "id": 10433,
            "name": "Sudrukumpa"
        },
        {
            "id": 10434,
            "name": "Titrapanga"
        }
    ],
    "9739": [
        {
            "id": 10435,
            "name": "Balandapada"
        },
        {
            "id": 10436,
            "name": "Bandhagada"
        },
        {
            "id": 10437,
            "name": "Bhrungijodi"
        },
        {
            "id": 10438,
            "name": "Dimiriguda"
        },
        {
            "id": 10439,
            "name": "Dindiragaon"
        },
        {
            "id": 10440,
            "name": "Gochhapada"
        },
        {
            "id": 10441,
            "name": "Jajespanga"
        },
        {
            "id": 10442,
            "name": "Kasinipadar"
        },
        {
            "id": 10443,
            "name": "Kelapada"
        },
        {
            "id": 10444,
            "name": "Krandibali"
        },
        {
            "id": 10445,
            "name": "Kuermandu"
        },
        {
            "id": 10446,
            "name": "Luising"
        },
        {
            "id": 10447,
            "name": "Nuapadar"
        },
        {
            "id": 10448,
            "name": "Pabingia"
        },
        {
            "id": 10449,
            "name": "Pahiraju"
        },
        {
            "id": 10450,
            "name": "Pakari"
        },
        {
            "id": 10451,
            "name": "Pangauparsahi"
        },
        {
            "id": 10452,
            "name": "Phiringia"
        },
        {
            "id": 10453,
            "name": "Polaburdi"
        },
        {
            "id": 10454,
            "name": "Ratanga"
        },
        {
            "id": 10455,
            "name": "Sadingia"
        },
        {
            "id": 10456,
            "name": "Salaguda"
        },
        {
            "id": 10457,
            "name": "Samarbandha"
        },
        {
            "id": 10458,
            "name": "Taladandikia"
        }
    ],
    "9740": [
        {
            "id": 10459,
            "name": "Alami"
        },
        {
            "id": 10460,
            "name": "Bisipada"
        },
        {
            "id": 10461,
            "name": "Dadaki"
        },
        {
            "id": 10462,
            "name": "Duduki"
        },
        {
            "id": 10463,
            "name": "Ganjuguda"
        },
        {
            "id": 10464,
            "name": "Gumagada"
        },
        {
            "id": 10465,
            "name": "Jamajhari"
        },
        {
            "id": 10466,
            "name": "Katringia"
        },
        {
            "id": 10467,
            "name": "Keredi"
        },
        {
            "id": 10468,
            "name": "Minia"
        },
        {
            "id": 10469,
            "name": "Tudipaju"
        }
    ],
    "9741": [
        {
            "id": 10470,
            "name": "NAC"
        }
    ],
    "9742": [
        {
            "id": 10471,
            "name": "Adhanga"
        },
        {
            "id": 10472,
            "name": "Alabol"
        },
        {
            "id": 10473,
            "name": "Alavar"
        },
        {
            "id": 10474,
            "name": "Ambasala"
        },
        {
            "id": 10475,
            "name": "Anantapur"
        },
        {
            "id": 10476,
            "name": "Apandara"
        },
        {
            "id": 10477,
            "name": "Arilo"
        },
        {
            "id": 10478,
            "name": "Badagaon"
        },
        {
            "id": 10479,
            "name": "Baharana"
        },
        {
            "id": 10480,
            "name": "Balikuda"
        },
        {
            "id": 10481,
            "name": "Baramundali"
        },
        {
            "id": 10482,
            "name": "Borikina"
        },
        {
            "id": 10483,
            "name": "Garama"
        },
        {
            "id": 10484,
            "name": "Ichhapur"
        },
        {
            "id": 10485,
            "name": "Kalio"
        },
        {
            "id": 10486,
            "name": "Khalagaon"
        },
        {
            "id": 10487,
            "name": "Krushnadaspur"
        },
        {
            "id": 10488,
            "name": "Kusupur"
        },
        {
            "id": 10489,
            "name": "Machhagaon"
        },
        {
            "id": 10490,
            "name": "Marichipur"
        },
        {
            "id": 10491,
            "name": "Mulugaon"
        },
        {
            "id": 10492,
            "name": "Nagapur"
        },
        {
            "id": 10493,
            "name": "Naharana"
        },
        {
            "id": 10494,
            "name": "Osakana"
        },
        {
            "id": 10495,
            "name": "Rahan"
        },
        {
            "id": 10496,
            "name": "Sangrampur"
        },
        {
            "id": 10497,
            "name": "Tandikul"
        },
        {
            "id": 10498,
            "name": "Tarasahi"
        },
        {
            "id": 10499,
            "name": "Thailo"
        },
        {
            "id": 10500,
            "name": "Titira"
        }
    ],
    "9743": [
        {
            "id": 10501,
            "name": "Ambiki"
        },
        {
            "id": 10502,
            "name": "Baleipur"
        },
        {
            "id": 10503,
            "name": "Balitutha"
        },
        {
            "id": 10504,
            "name": "Bamadeipur"
        },
        {
            "id": 10505,
            "name": "Bhitarandhari"
        },
        {
            "id": 10506,
            "name": "Chatua"
        },
        {
            "id": 10507,
            "name": "Dhinkia"
        },
        {
            "id": 10508,
            "name": "Erasama"
        },
        {
            "id": 10509,
            "name": "Gadabisunupur"
        },
        {
            "id": 10510,
            "name": "Gadaharishpur"
        },
        {
            "id": 10511,
            "name": "Gadakujanga"
        },
        {
            "id": 10512,
            "name": "Goda"
        },
        {
            "id": 10513,
            "name": "Janakadeipur"
        },
        {
            "id": 10514,
            "name": "Japa"
        },
        {
            "id": 10515,
            "name": "Jireilo"
        },
        {
            "id": 10516,
            "name": "Krushnachandrapur"
        },
        {
            "id": 10517,
            "name": "Kunjakothi"
        },
        {
            "id": 10518,
            "name": "Nardia"
        },
        {
            "id": 10519,
            "name": "Nuadihi"
        },
        {
            "id": 10520,
            "name": "Nuagaon"
        },
        {
            "id": 10521,
            "name": "Padmapur"
        },
        {
            "id": 10522,
            "name": "Paida"
        },
        {
            "id": 10523,
            "name": "Pallikanta"
        },
        {
            "id": 10524,
            "name": "Pokhariapada"
        },
        {
            "id": 10525,
            "name": "Sompur"
        }
    ],
    "9744": [
        {
            "id": 10526,
            "name": "Alasudha"
        },
        {
            "id": 10527,
            "name": "Alipingal"
        },
        {
            "id": 10528,
            "name": "Alla"
        },
        {
            "id": 10529,
            "name": "Ayar"
        },
        {
            "id": 10530,
            "name": "Balarampur"
        },
        {
            "id": 10531,
            "name": "Jahanpur"
        },
        {
            "id": 10532,
            "name": "Jankoti"
        },
        {
            "id": 10533,
            "name": "Jasobantapur"
        },
        {
            "id": 10534,
            "name": "Kaduapada"
        },
        {
            "id": 10535,
            "name": "Kantilo"
        },
        {
            "id": 10536,
            "name": "Khairanga"
        },
        {
            "id": 10537,
            "name": "Mandasahi"
        },
        {
            "id": 10538,
            "name": "Mspur sashan"
        },
        {
            "id": 10539,
            "name": "Mudupur"
        },
        {
            "id": 10540,
            "name": "Odiso"
        },
        {
            "id": 10541,
            "name": "Palasol"
        },
        {
            "id": 10542,
            "name": "Pally"
        },
        {
            "id": 10543,
            "name": "Patasara"
        },
        {
            "id": 10544,
            "name": "Patenigaon"
        },
        {
            "id": 10545,
            "name": "Piteipur"
        },
        {
            "id": 10546,
            "name": "Punanga"
        },
        {
            "id": 10547,
            "name": "Rambhadeipur"
        },
        {
            "id": 10548,
            "name": "Salajanga"
        },
        {
            "id": 10549,
            "name": "Samanga"
        },
        {
            "id": 10550,
            "name": "Sampur"
        },
        {
            "id": 10551,
            "name": "Savamul"
        },
        {
            "id": 10552,
            "name": "Singarpur"
        },
        {
            "id": 10553,
            "name": "Taradapada"
        },
        {
            "id": 10554,
            "name": "Tartanga"
        },
        {
            "id": 10555,
            "name": "Tumbaandeisahi"
        }
    ],
    "9745": [
        {
            "id": 10556,
            "name": "NAC"
        }
    ],
    "9746": [
        {
            "id": 10557,
            "name": "Alana"
        },
        {
            "id": 10558,
            "name": "Bachhalo"
        },
        {
            "id": 10559,
            "name": "Banso"
        },
        {
            "id": 10560,
            "name": "Dabar"
        },
        {
            "id": 10561,
            "name": "Deriki"
        },
        {
            "id": 10562,
            "name": "Gajarajpur"
        },
        {
            "id": 10563,
            "name": "Galadhari"
        },
        {
            "id": 10564,
            "name": "Ghodansho"
        },
        {
            "id": 10565,
            "name": "Jamugaon"
        },
        {
            "id": 10566,
            "name": "Korua"
        },
        {
            "id": 10567,
            "name": "Naugaon"
        },
        {
            "id": 10568,
            "name": "Nuagaon"
        },
        {
            "id": 10569,
            "name": "Oskana"
        },
        {
            "id": 10570,
            "name": "Rohia"
        },
        {
            "id": 10571,
            "name": "Sikhar"
        },
        {
            "id": 10572,
            "name": "Tiruna"
        }
    ],
    "9747": [
        {
            "id": 10573,
            "name": "Agapal"
        },
        {
            "id": 10574,
            "name": "Badabalikani"
        },
        {
            "id": 10575,
            "name": "Bagadia"
        },
        {
            "id": 10576,
            "name": "bagoi"
        },
        {
            "id": 10577,
            "name": "Balia"
        },
        {
            "id": 10578,
            "name": "Banito"
        },
        {
            "id": 10579,
            "name": "Bhutamundei"
        },
        {
            "id": 10580,
            "name": "Biswali"
        },
        {
            "id": 10581,
            "name": "Fatepur"
        },
        {
            "id": 10582,
            "name": "Gandakipur"
        },
        {
            "id": 10583,
            "name": "Garei"
        },
        {
            "id": 10584,
            "name": "Gopiakuda"
        },
        {
            "id": 10585,
            "name": "Gorada"
        },
        {
            "id": 10586,
            "name": "Hansura"
        },
        {
            "id": 10587,
            "name": "Kathakota"
        },
        {
            "id": 10588,
            "name": "Kolar"
        },
        {
            "id": 10589,
            "name": "Kothi"
        },
        {
            "id": 10590,
            "name": "Kujanga"
        },
        {
            "id": 10591,
            "name": "Mallasahi"
        },
        {
            "id": 10592,
            "name": "Mangarajpur"
        },
        {
            "id": 10593,
            "name": "Nuagarh"
        },
        {
            "id": 10594,
            "name": "Pandua"
        },
        {
            "id": 10595,
            "name": "Pankapal"
        },
        {
            "id": 10596,
            "name": "Paradeepgarh"
        },
        {
            "id": 10597,
            "name": "Patapur"
        },
        {
            "id": 10598,
            "name": "Potanai"
        },
        {
            "id": 10599,
            "name": "Sailo"
        },
        {
            "id": 10600,
            "name": "Talapada"
        },
        {
            "id": 10601,
            "name": "Zillanasi"
        }
    ],
    "9748": [
        {
            "id": 10602,
            "name": "NAC"
        }
    ],
    "9749": [
        {
            "id": 10603,
            "name": "Amberi"
        },
        {
            "id": 10604,
            "name": "Bodhei"
        },
        {
            "id": 10605,
            "name": "Jadatira"
        },
        {
            "id": 10606,
            "name": "Kolar"
        },
        {
            "id": 10607,
            "name": "Manijanga"
        },
        {
            "id": 10608,
            "name": "Pippalamadhab"
        },
        {
            "id": 10609,
            "name": "Poragadei"
        },
        {
            "id": 10610,
            "name": "Samantarapur"
        }
    ],
    "9750": [
        {
            "id": 10611,
            "name": "Adhangagarh"
        },
        {
            "id": 10612,
            "name": "Adhangamajurai"
        },
        {
            "id": 10613,
            "name": "Alando"
        },
        {
            "id": 10614,
            "name": "Arana"
        },
        {
            "id": 10615,
            "name": "Badakhandeita"
        },
        {
            "id": 10616,
            "name": "Bagalpur"
        },
        {
            "id": 10617,
            "name": "Baredia"
        },
        {
            "id": 10618,
            "name": "Basandara"
        },
        {
            "id": 10619,
            "name": "Batimira"
        },
        {
            "id": 10620,
            "name": "Budhapada"
        },
        {
            "id": 10621,
            "name": "Daradagrama"
        },
        {
            "id": 10622,
            "name": "Gopinathpur"
        },
        {
            "id": 10623,
            "name": "Hajipur"
        },
        {
            "id": 10624,
            "name": "Kulakaijanga"
        },
        {
            "id": 10625,
            "name": "Manguli"
        },
        {
            "id": 10626,
            "name": "Purana"
        },
        {
            "id": 10627,
            "name": "Sadheisashan"
        },
        {
            "id": 10628,
            "name": "Sankhapur"
        },
        {
            "id": 10629,
            "name": "Sibapur"
        },
        {
            "id": 10630,
            "name": "Ukundara"
        },
        {
            "id": 10631,
            "name": "Uttarsashan"
        }
    ],
    "9751": [
        {
            "id": 10632,
            "name": "NAC"
        }
    ],
    "9752": [
        {
            "id": 10633,
            "name": "Adheikul"
        },
        {
            "id": 10634,
            "name": "Baragol"
        },
        {
            "id": 10635,
            "name": "Bidyadharpur"
        },
        {
            "id": 10636,
            "name": "Chhapada"
        },
        {
            "id": 10637,
            "name": "Chikinia"
        },
        {
            "id": 10638,
            "name": "Dharadharpur"
        },
        {
            "id": 10639,
            "name": "Eradanga"
        },
        {
            "id": 10640,
            "name": "Gualipur"
        },
        {
            "id": 10641,
            "name": "Jagannathpur"
        },
        {
            "id": 10642,
            "name": "Jaisol"
        },
        {
            "id": 10643,
            "name": "Puranaodapada"
        },
        {
            "id": 10644,
            "name": "Purunabasanta"
        },
        {
            "id": 10645,
            "name": "Radhanga"
        },
        {
            "id": 10646,
            "name": "Raghunathpur"
        },
        {
            "id": 10647,
            "name": "Redhua"
        },
        {
            "id": 10648,
            "name": "Sadeipur"
        },
        {
            "id": 10649,
            "name": "Tanara"
        },
        {
            "id": 10650,
            "name": "Tarapur"
        },
        {
            "id": 10651,
            "name": "Tarikund"
        }
    ],
    "9753": [
        {
            "id": 10652,
            "name": "Amberi"
        },
        {
            "id": 10653,
            "name": "Biritol"
        },
        {
            "id": 10654,
            "name": "Bisunpur"
        },
        {
            "id": 10655,
            "name": "Bodhei"
        },
        {
            "id": 10656,
            "name": "Dainlo"
        },
        {
            "id": 10657,
            "name": "Garama"
        },
        {
            "id": 10658,
            "name": "Gobindpur"
        },
        {
            "id": 10659,
            "name": "Gopalpur"
        },
        {
            "id": 10660,
            "name": "Gopalpur(S)"
        },
        {
            "id": 10661,
            "name": "Ibrising"
        },
        {
            "id": 10662,
            "name": "Jadatira"
        },
        {
            "id": 10663,
            "name": "Jagannathpur"
        },
        {
            "id": 10664,
            "name": "Kanakpur"
        },
        {
            "id": 10665,
            "name": "Kanimul"
        },
        {
            "id": 10666,
            "name": "Katara"
        },
        {
            "id": 10667,
            "name": "Kolar"
        },
        {
            "id": 10668,
            "name": "Kostimalikapur"
        },
        {
            "id": 10669,
            "name": "Krushnanandapur"
        },
        {
            "id": 10670,
            "name": "Manijanga"
        },
        {
            "id": 10671,
            "name": "Mulisingh"
        },
        {
            "id": 10672,
            "name": "Patilo"
        },
        {
            "id": 10673,
            "name": "Pippalmadhab"
        },
        {
            "id": 10674,
            "name": "Poragadei"
        },
        {
            "id": 10675,
            "name": "Samantarapur"
        },
        {
            "id": 10676,
            "name": "Sanara"
        },
        {
            "id": 10677,
            "name": "Tarajanga"
        },
        {
            "id": 10678,
            "name": "Tentulipada"
        },
        {
            "id": 10679,
            "name": "Tirtol"
        },
        {
            "id": 10680,
            "name": "Tulanga"
        }
    ],
    "9754": [
        {
            "id": 10681,
            "name": "Areikana"
        },
        {
            "id": 10682,
            "name": "Argal"
        },
        {
            "id": 10683,
            "name": "Atala"
        },
        {
            "id": 10684,
            "name": "Badambila"
        },
        {
            "id": 10685,
            "name": "Balakati"
        },
        {
            "id": 10686,
            "name": "Batipada"
        },
        {
            "id": 10687,
            "name": "Bhuinpur"
        },
        {
            "id": 10688,
            "name": "Chandiagari"
        },
        {
            "id": 10689,
            "name": "Dasipur"
        },
        {
            "id": 10690,
            "name": "Demal"
        },
        {
            "id": 10691,
            "name": "Desahi"
        },
        {
            "id": 10692,
            "name": "Dimiripal"
        },
        {
            "id": 10693,
            "name": "Ekmania"
        },
        {
            "id": 10694,
            "name": "Eradanga"
        },
        {
            "id": 10695,
            "name": "Gobindpur"
        },
        {
            "id": 10696,
            "name": "Juania"
        },
        {
            "id": 10697,
            "name": "Kalashpur"
        },
        {
            "id": 10698,
            "name": "Ketuapal"
        },
        {
            "id": 10699,
            "name": "Kolidhia"
        },
        {
            "id": 10700,
            "name": "Mahu"
        },
        {
            "id": 10701,
            "name": "Manikpatana"
        },
        {
            "id": 10702,
            "name": "Mendhapur"
        },
        {
            "id": 10703,
            "name": "Narendrapur"
        },
        {
            "id": 10704,
            "name": "Niala"
        },
        {
            "id": 10705,
            "name": "Nuapada"
        },
        {
            "id": 10706,
            "name": "Padanipal"
        },
        {
            "id": 10707,
            "name": "Palimi"
        },
        {
            "id": 10708,
            "name": "Patrapur"
        },
        {
            "id": 10709,
            "name": "Petapada"
        },
        {
            "id": 10710,
            "name": "Sahira"
        },
        {
            "id": 10711,
            "name": "Sanamanga"
        },
        {
            "id": 10712,
            "name": "Sansidha"
        },
        {
            "id": 10713,
            "name": "Singiri"
        },
        {
            "id": 10714,
            "name": "Tunga"
        }
    ],
    "9755": [
        {
            "id": 10715,
            "name": "Abdulpur"
        },
        {
            "id": 10716,
            "name": "Akhulipada"
        },
        {
            "id": 10717,
            "name": "B.Domanda"
        },
        {
            "id": 10718,
            "name": "Baghabuda"
        },
        {
            "id": 10719,
            "name": "Balarampur"
        },
        {
            "id": 10720,
            "name": "Baradia"
        },
        {
            "id": 10721,
            "name": "Baruna"
        },
        {
            "id": 10722,
            "name": "Barunadiha"
        },
        {
            "id": 10723,
            "name": "Bharigada"
        },
        {
            "id": 10724,
            "name": "Charpada"
        },
        {
            "id": 10725,
            "name": "Deulatara"
        },
        {
            "id": 10726,
            "name": "Hatsahi"
        },
        {
            "id": 10727,
            "name": "Jagulaipada"
        },
        {
            "id": 10728,
            "name": "Jayanagar"
        },
        {
            "id": 10729,
            "name": "Kantapada"
        },
        {
            "id": 10730,
            "name": "Katanabania"
        },
        {
            "id": 10731,
            "name": "Koilipur"
        },
        {
            "id": 10732,
            "name": "Koranda"
        },
        {
            "id": 10733,
            "name": "Nahuni"
        },
        {
            "id": 10734,
            "name": "Namatara"
        },
        {
            "id": 10735,
            "name": "Olavar"
        },
        {
            "id": 10736,
            "name": "P.Habalaganda"
        },
        {
            "id": 10737,
            "name": "Padhanpada"
        },
        {
            "id": 10738,
            "name": "Panasaganda"
        },
        {
            "id": 10739,
            "name": "Pegarpada"
        },
        {
            "id": 10740,
            "name": "Sihopada"
        },
        {
            "id": 10741,
            "name": "Siko"
        },
        {
            "id": 10742,
            "name": "Taras"
        },
        {
            "id": 10743,
            "name": "Trailokyapur"
        }
    ],
    "9756": [
        {
            "id": 10744,
            "name": "Ayeba"
        },
        {
            "id": 10745,
            "name": "Bagada"
        },
        {
            "id": 10746,
            "name": "Baro"
        },
        {
            "id": 10747,
            "name": "Bhagabatpur"
        },
        {
            "id": 10748,
            "name": "Bharatpur"
        },
        {
            "id": 10749,
            "name": "Chakroda"
        },
        {
            "id": 10750,
            "name": "Chandanpur"
        },
        {
            "id": 10751,
            "name": "Charigaon"
        },
        {
            "id": 10752,
            "name": "Dhola"
        },
        {
            "id": 10753,
            "name": "Dhumat"
        },
        {
            "id": 10754,
            "name": "Gangapada"
        },
        {
            "id": 10755,
            "name": "Ghagara"
        },
        {
            "id": 10756,
            "name": "Gulnagar"
        },
        {
            "id": 10757,
            "name": "Indupur"
        },
        {
            "id": 10758,
            "name": "Jamadhar"
        },
        {
            "id": 10759,
            "name": "Kalapada"
        },
        {
            "id": 10760,
            "name": "Kansar"
        },
        {
            "id": 10761,
            "name": "Kapaleswar"
        },
        {
            "id": 10762,
            "name": "Keshpur"
        },
        {
            "id": 10763,
            "name": "Kora"
        },
        {
            "id": 10764,
            "name": "Kutaranga"
        },
        {
            "id": 10765,
            "name": "Nikirai"
        },
        {
            "id": 10766,
            "name": "Ostapur"
        },
        {
            "id": 10767,
            "name": "Palasingha"
        },
        {
            "id": 10768,
            "name": "Purusottampur"
        },
        {
            "id": 10769,
            "name": "Sanamantia"
        },
        {
            "id": 10770,
            "name": "Shyamsundarpur"
        }
    ],
    "9757": [
        {
            "id": 10771,
            "name": "NAC"
        }
    ],
    "9758": [
        {
            "id": 10772,
            "name": "Alapua"
        },
        {
            "id": 10773,
            "name": "Amrutamanohi"
        },
        {
            "id": 10774,
            "name": "Andara"
        },
        {
            "id": 10775,
            "name": "Badamulabasanta"
        },
        {
            "id": 10776,
            "name": "Balipatna"
        },
        {
            "id": 10777,
            "name": "Baluria"
        },
        {
            "id": 10778,
            "name": "Dihapada"
        },
        {
            "id": 10779,
            "name": "Kakharuni"
        },
        {
            "id": 10780,
            "name": "Narsinghpur"
        },
        {
            "id": 10781,
            "name": "Nilakantahpur"
        },
        {
            "id": 10782,
            "name": "Penthpala"
        },
        {
            "id": 10783,
            "name": "Singhagaon"
        },
        {
            "id": 10784,
            "name": "Taradipal"
        }
    ],
    "9759": [
        {
            "id": 10785,
            "name": "Alailo"
        },
        {
            "id": 10786,
            "name": "Ameipala"
        },
        {
            "id": 10787,
            "name": "Badakul"
        },
        {
            "id": 10788,
            "name": "Badhi"
        },
        {
            "id": 10789,
            "name": "Balipala"
        },
        {
            "id": 10790,
            "name": "Baradanga"
        },
        {
            "id": 10791,
            "name": "Barakandha"
        },
        {
            "id": 10792,
            "name": "Batighara"
        },
        {
            "id": 10793,
            "name": "Baulakani"
        },
        {
            "id": 10794,
            "name": "Bijayanagar"
        },
        {
            "id": 10795,
            "name": "Deulapara"
        },
        {
            "id": 10796,
            "name": "Gadaromita"
        },
        {
            "id": 10797,
            "name": "Gogua"
        },
        {
            "id": 10798,
            "name": "Jadupur"
        },
        {
            "id": 10799,
            "name": "Jamboo"
        },
        {
            "id": 10800,
            "name": "Jayachandrapur"
        },
        {
            "id": 10801,
            "name": "Kansar Bada Dandua"
        },
        {
            "id": 10802,
            "name": "Karanja"
        },
        {
            "id": 10803,
            "name": "Kharinasi"
        },
        {
            "id": 10804,
            "name": "Khurusia"
        },
        {
            "id": 10805,
            "name": "Mahakalpara"
        },
        {
            "id": 10806,
            "name": "Mangalpur"
        },
        {
            "id": 10807,
            "name": "Nanjura"
        },
        {
            "id": 10808,
            "name": "Patalipanka"
        },
        {
            "id": 10809,
            "name": "Petchhela"
        },
        {
            "id": 10810,
            "name": "Rajagada"
        },
        {
            "id": 10811,
            "name": "Ramanagar"
        },
        {
            "id": 10812,
            "name": "Suniti"
        },
        {
            "id": 10813,
            "name": "Teragaon"
        },
        {
            "id": 10814,
            "name": "Tikarpanga"
        },
        {
            "id": 10815,
            "name": "Tikhiri"
        }
    ],
    "9760": [
        {
            "id": 10816,
            "name": "Akhuadakhini"
        },
        {
            "id": 10817,
            "name": "Angulai"
        },
        {
            "id": 10818,
            "name": "Antei"
        },
        {
            "id": 10819,
            "name": "Batira"
        },
        {
            "id": 10820,
            "name": "Beruhan"
        },
        {
            "id": 10821,
            "name": "Dasipur"
        },
        {
            "id": 10822,
            "name": "Dumuka"
        },
        {
            "id": 10823,
            "name": "Garajanga"
        },
        {
            "id": 10824,
            "name": "Hindulia"
        },
        {
            "id": 10825,
            "name": "Kuhudi"
        },
        {
            "id": 10826,
            "name": "Mangarajpur"
        },
        {
            "id": 10827,
            "name": "Manikunda"
        },
        {
            "id": 10828,
            "name": "Marshaghai"
        },
        {
            "id": 10829,
            "name": "Parakula"
        },
        {
            "id": 10830,
            "name": "Raghabpur"
        },
        {
            "id": 10831,
            "name": "Silipur"
        },
        {
            "id": 10832,
            "name": "Talasanga"
        }
    ],
    "9761": [
        {
            "id": 10833,
            "name": "Balia"
        },
        {
            "id": 10834,
            "name": "Barimul"
        },
        {
            "id": 10835,
            "name": "Basupur"
        },
        {
            "id": 10836,
            "name": "Benipur"
        },
        {
            "id": 10837,
            "name": "Chandol"
        },
        {
            "id": 10838,
            "name": "CHATARA CHAKODA"
        },
        {
            "id": 10839,
            "name": "Chhoti"
        },
        {
            "id": 10840,
            "name": "Danpur"
        },
        {
            "id": 10841,
            "name": "Ender"
        },
        {
            "id": 10842,
            "name": "Golarhat"
        },
        {
            "id": 10843,
            "name": "Gualsingh"
        },
        {
            "id": 10844,
            "name": "Harianka"
        },
        {
            "id": 10845,
            "name": "Indol"
        },
        {
            "id": 10846,
            "name": "J.B.MUL"
        },
        {
            "id": 10847,
            "name": "Kaupada"
        },
        {
            "id": 10848,
            "name": "Khamol"
        },
        {
            "id": 10849,
            "name": "Kurujanga"
        },
        {
            "id": 10850,
            "name": "Kusiapal"
        },
        {
            "id": 10851,
            "name": "L.N.Pur"
        },
        {
            "id": 10852,
            "name": "Mangalpur"
        },
        {
            "id": 10853,
            "name": "Mukundapur"
        },
        {
            "id": 10854,
            "name": "Nahanga"
        },
        {
            "id": 10855,
            "name": "Narasinghpur"
        },
        {
            "id": 10856,
            "name": "Palei"
        },
        {
            "id": 10857,
            "name": "R.K.SAHI"
        },
        {
            "id": 10858,
            "name": "Raghudeipur"
        }
    ],
    "9762": [
        {
            "id": 10859,
            "name": "Badabetara"
        },
        {
            "id": 10860,
            "name": "Bangalpur"
        },
        {
            "id": 10861,
            "name": "Bantala"
        },
        {
            "id": 10862,
            "name": "Bedari"
        },
        {
            "id": 10863,
            "name": "Bhagabanpur"
        },
        {
            "id": 10864,
            "name": "Garadpur"
        },
        {
            "id": 10865,
            "name": "Kalabuda"
        },
        {
            "id": 10866,
            "name": "Madhusasan"
        },
        {
            "id": 10867,
            "name": "Nadiabarai"
        },
        {
            "id": 10868,
            "name": "Padmapur"
        },
        {
            "id": 10869,
            "name": "Pakhada"
        },
        {
            "id": 10870,
            "name": "Palasudha"
        },
        {
            "id": 10871,
            "name": "Patkura"
        },
        {
            "id": 10872,
            "name": "Ramcharandrapur"
        },
        {
            "id": 10873,
            "name": "Sana-adhanga"
        },
        {
            "id": 10874,
            "name": "Talakusuma"
        },
        {
            "id": 10875,
            "name": "Tikanpur"
        },
        {
            "id": 10876,
            "name": "Tyendakuda"
        }
    ],
    "9763": [
        {
            "id": 10877,
            "name": "Aitipur"
        },
        {
            "id": 10878,
            "name": "Bachharai"
        },
        {
            "id": 10879,
            "name": "Bandhakata"
        },
        {
            "id": 10880,
            "name": "Jalapoka"
        },
        {
            "id": 10881,
            "name": "Jamapara"
        },
        {
            "id": 10882,
            "name": "Karilopatna"
        },
        {
            "id": 10883,
            "name": "Mehendinagar"
        }
    ],
    "9764": [
        {
            "id": 10884,
            "name": "Aradapalli"
        },
        {
            "id": 10885,
            "name": "Bachhara"
        },
        {
            "id": 10886,
            "name": "Badamohanpur"
        },
        {
            "id": 10887,
            "name": "Badapada"
        },
        {
            "id": 10888,
            "name": "Balabhadrapur"
        },
        {
            "id": 10889,
            "name": "Bilikana"
        },
        {
            "id": 10890,
            "name": "Chandanagar"
        },
        {
            "id": 10891,
            "name": "Damarpur"
        },
        {
            "id": 10892,
            "name": "Dihudipur"
        },
        {
            "id": 10893,
            "name": "Dosia"
        },
        {
            "id": 10894,
            "name": "Gangarampur"
        },
        {
            "id": 10895,
            "name": "Kakharuni"
        },
        {
            "id": 10896,
            "name": "Khadianta"
        },
        {
            "id": 10897,
            "name": "Khanata"
        },
        {
            "id": 10898,
            "name": "Oupada"
        },
        {
            "id": 10899,
            "name": "Sanjaria"
        },
        {
            "id": 10900,
            "name": "Sansarfal"
        },
        {
            "id": 10901,
            "name": "Sasan"
        },
        {
            "id": 10902,
            "name": "Srirampur"
        }
    ],
    "9765": [
        {
            "id": 10903,
            "name": "NAC"
        }
    ],
    "9766": [
        {
            "id": 10904,
            "name": "Baghamari"
        },
        {
            "id": 10905,
            "name": "Balisahipatana"
        },
        {
            "id": 10906,
            "name": "Bandhapada"
        },
        {
            "id": 10907,
            "name": "Belpal"
        },
        {
            "id": 10908,
            "name": "Bhitargarh"
        },
        {
            "id": 10909,
            "name": "Brahmanasahi"
        },
        {
            "id": 10910,
            "name": "Chandibaunsamula"
        },
        {
            "id": 10911,
            "name": "Dangamala"
        },
        {
            "id": 10912,
            "name": "Dera"
        },
        {
            "id": 10913,
            "name": "Ghadiamal"
        },
        {
            "id": 10914,
            "name": "Gupti"
        },
        {
            "id": 10915,
            "name": "Hatina"
        },
        {
            "id": 10916,
            "name": "Iswarpur"
        },
        {
            "id": 10917,
            "name": "Kandira"
        },
        {
            "id": 10918,
            "name": "Keradagarh"
        },
        {
            "id": 10919,
            "name": "Keruapal"
        },
        {
            "id": 10920,
            "name": "Khamarsahi"
        },
        {
            "id": 10921,
            "name": "Kolipur"
        },
        {
            "id": 10922,
            "name": "Krushannagar"
        },
        {
            "id": 10923,
            "name": "Kurunti"
        },
        {
            "id": 10924,
            "name": "Mahisasura"
        },
        {
            "id": 10925,
            "name": "Mahulia"
        },
        {
            "id": 10926,
            "name": "Ostia"
        },
        {
            "id": 10927,
            "name": "Rajnagar"
        },
        {
            "id": 10928,
            "name": "Rajpur"
        },
        {
            "id": 10929,
            "name": "Rangani"
        },
        {
            "id": 10930,
            "name": "Sanabada Gopalpur"
        },
        {
            "id": 10931,
            "name": "Satabhaya"
        },
        {
            "id": 10932,
            "name": "Talachua"
        }
    ],
    "9767": [
        {
            "id": 10933,
            "name": "Adhuan"
        },
        {
            "id": 10934,
            "name": "Andola"
        },
        {
            "id": 10935,
            "name": "Arandua"
        },
        {
            "id": 10936,
            "name": "Artung"
        },
        {
            "id": 10937,
            "name": "Aruha"
        },
        {
            "id": 10938,
            "name": "Balimeda"
        },
        {
            "id": 10939,
            "name": "Balimunda"
        },
        {
            "id": 10940,
            "name": "Barandua"
        },
        {
            "id": 10941,
            "name": "Basudevpur"
        },
        {
            "id": 10942,
            "name": "Bideipur"
        },
        {
            "id": 10943,
            "name": "Binobhabenagar"
        },
        {
            "id": 10944,
            "name": "Birasa"
        },
        {
            "id": 10945,
            "name": "Brahmanigaon"
        },
        {
            "id": 10946,
            "name": "Chudamani"
        },
        {
            "id": 10947,
            "name": "Edtal"
        },
        {
            "id": 10948,
            "name": "Eram"
        },
        {
            "id": 10949,
            "name": "Gobindpur"
        },
        {
            "id": 10950,
            "name": "Gopabandhunagar"
        },
        {
            "id": 10951,
            "name": "Guagadia"
        },
        {
            "id": 10952,
            "name": "Jagannathprasad"
        },
        {
            "id": 10953,
            "name": "Jannathpur"
        },
        {
            "id": 10954,
            "name": "Karanjadia"
        },
        {
            "id": 10955,
            "name": "Kharidabinayakpur"
        },
        {
            "id": 10956,
            "name": "Kismatkrushnapur"
        },
        {
            "id": 10957,
            "name": "Kumarpur"
        },
        {
            "id": 10958,
            "name": "Laxmidaspur"
        },
        {
            "id": 10959,
            "name": "Lunga"
        },
        {
            "id": 10960,
            "name": "Naikanidihi"
        },
        {
            "id": 10961,
            "name": "Narasingpur"
        },
        {
            "id": 10962,
            "name": "Nuagaon"
        },
        {
            "id": 10963,
            "name": "Padhuan"
        },
        {
            "id": 10964,
            "name": "Padmapur"
        },
        {
            "id": 10965,
            "name": "Rajgharpokhari"
        },
        {
            "id": 10966,
            "name": "Sanakrushnapur"
        },
        {
            "id": 10967,
            "name": "Shashubhuasuni"
        },
        {
            "id": 10968,
            "name": "Sudarshanpur"
        },
        {
            "id": 10969,
            "name": "Sugo"
        }
    ],
    "9768": [
        {
            "id": 10970,
            "name": "NAC"
        }
    ],
    "9769": [
        {
            "id": 10971,
            "name": "Bahabalpur"
        },
        {
            "id": 10972,
            "name": "Bhatapada"
        },
        {
            "id": 10973,
            "name": "Kolha"
        },
        {
            "id": 10974,
            "name": "Maharampur"
        },
        {
            "id": 10975,
            "name": "Mukundapur"
        },
        {
            "id": 10976,
            "name": "Saya"
        },
        {
            "id": 10977,
            "name": "Sindol"
        },
        {
            "id": 14273,
            "name": "Mangalpur"
        },
        {
            "id": 14274,
            "name": "Fatepur"
        }
    ],
    "9770": [
        {
            "id": 10978,
            "name": "Ambroli"
        },
        {
            "id": 10979,
            "name": "Andheipally"
        },
        {
            "id": 10980,
            "name": "Arnapal"
        },
        {
            "id": 10981,
            "name": "Bahudarada"
        },
        {
            "id": 10982,
            "name": "Bandhagaon"
        },
        {
            "id": 10983,
            "name": "Bania"
        },
        {
            "id": 10984,
            "name": "Banitia"
        },
        {
            "id": 10985,
            "name": "Baudpur"
        },
        {
            "id": 10986,
            "name": "Bental"
        },
        {
            "id": 10987,
            "name": "Berhampur"
        },
        {
            "id": 10988,
            "name": "Betara"
        },
        {
            "id": 10989,
            "name": "Bishnupurbindha"
        },
        {
            "id": 10990,
            "name": "Chandigaon"
        },
        {
            "id": 10991,
            "name": "Charampa"
        },
        {
            "id": 10992,
            "name": "Daising"
        },
        {
            "id": 10993,
            "name": "Erada"
        },
        {
            "id": 10994,
            "name": "Erein"
        },
        {
            "id": 10995,
            "name": "Gelpur"
        },
        {
            "id": 10996,
            "name": "Geltua"
        },
        {
            "id": 10997,
            "name": "Goramati"
        },
        {
            "id": 10998,
            "name": "Gujidarada"
        },
        {
            "id": 10999,
            "name": "Haripur"
        },
        {
            "id": 11000,
            "name": "Jagdalpur"
        },
        {
            "id": 11001,
            "name": "Kalei"
        },
        {
            "id": 11002,
            "name": "Kaupur"
        },
        {
            "id": 11003,
            "name": "Kedarpur"
        },
        {
            "id": 11004,
            "name": "Khandatada"
        },
        {
            "id": 11005,
            "name": "Kodabaruan"
        },
        {
            "id": 11006,
            "name": "Korkora"
        },
        {
            "id": 11007,
            "name": "Olanga"
        },
        {
            "id": 11008,
            "name": "Parsinga"
        },
        {
            "id": 11009,
            "name": "Rahanja"
        },
        {
            "id": 11010,
            "name": "Rambhila"
        },
        {
            "id": 11011,
            "name": "Randia"
        },
        {
            "id": 11012,
            "name": "Sabaranga"
        },
        {
            "id": 11013,
            "name": "Sahada"
        }
    ],
    "9771": [
        {
            "id": 11014,
            "name": "NAC"
        }
    ],
    "9772": [
        {
            "id": 11015,
            "name": "Balipokhari"
        },
        {
            "id": 11016,
            "name": "Bandalo-madhiali"
        },
        {
            "id": 11017,
            "name": "Bayabanapur"
        },
        {
            "id": 11018,
            "name": "Bishnupur"
        },
        {
            "id": 11019,
            "name": "Dakhinabad"
        },
        {
            "id": 11020,
            "name": "Jalamandua"
        },
        {
            "id": 11021,
            "name": "Jhutuna"
        },
        {
            "id": 11022,
            "name": "Kurigaon"
        },
        {
            "id": 11023,
            "name": "Malada"
        },
        {
            "id": 11024,
            "name": "Naami"
        },
        {
            "id": 11025,
            "name": "Naguan"
        },
        {
            "id": 11026,
            "name": "Napang"
        },
        {
            "id": 11027,
            "name": "Nerada"
        },
        {
            "id": 11028,
            "name": "Nirgundi"
        },
        {
            "id": 11029,
            "name": "Patuli"
        },
        {
            "id": 11030,
            "name": "Rahania"
        },
        {
            "id": 11031,
            "name": "Rajendrapur"
        },
        {
            "id": 11032,
            "name": "Ramchandrapur"
        },
        {
            "id": 11033,
            "name": "Ranjit"
        },
        {
            "id": 11034,
            "name": "Sanasasana"
        },
        {
            "id": 11035,
            "name": "Sarasada"
        },
        {
            "id": 11036,
            "name": "Solampur"
        },
        {
            "id": 11037,
            "name": "Tesinga"
        }
    ],
    "9773": [
        {
            "id": 11038,
            "name": "Adia"
        },
        {
            "id": 11039,
            "name": "Anijo"
        },
        {
            "id": 11040,
            "name": "B.B. Kayan"
        },
        {
            "id": 11041,
            "name": "B.M. Gotha"
        },
        {
            "id": 11042,
            "name": "B.T. Pur"
        },
        {
            "id": 11043,
            "name": "Basantia"
        },
        {
            "id": 11044,
            "name": "Bonth"
        },
        {
            "id": 11045,
            "name": "Charigaon"
        },
        {
            "id": 11046,
            "name": "Chhayalsingh"
        },
        {
            "id": 11047,
            "name": "Ganijang"
        },
        {
            "id": 11048,
            "name": "Gopinathpur"
        },
        {
            "id": 11049,
            "name": "Govindpur"
        },
        {
            "id": 11050,
            "name": "Kadabarang"
        },
        {
            "id": 11051,
            "name": "Kenduapada"
        },
        {
            "id": 11052,
            "name": "Mohantypada"
        },
        {
            "id": 11053,
            "name": "Nilok"
        },
        {
            "id": 11054,
            "name": "Odang"
        },
        {
            "id": 11055,
            "name": "Padhanpada"
        },
        {
            "id": 11056,
            "name": "Purusandha"
        },
        {
            "id": 11057,
            "name": "R.C. Pur"
        },
        {
            "id": 11058,
            "name": "R.K. Pur"
        },
        {
            "id": 11059,
            "name": "Rampur"
        },
        {
            "id": 11060,
            "name": "Sendhatira"
        },
        {
            "id": 11061,
            "name": "Tillo"
        },
        {
            "id": 11062,
            "name": "Todagaon"
        }
    ],
    "9774": [
        {
            "id": 11063,
            "name": "Aradi"
        },
        {
            "id": 11064,
            "name": "Badaostia"
        },
        {
            "id": 11065,
            "name": "Bajarapur"
        },
        {
            "id": 11066,
            "name": "Bansada"
        },
        {
            "id": 11067,
            "name": "Bhatapada"
        },
        {
            "id": 11068,
            "name": "Bhuinbruti"
        },
        {
            "id": 11069,
            "name": "Bijaynagar"
        },
        {
            "id": 11070,
            "name": "Chardia"
        },
        {
            "id": 11071,
            "name": "Dhamara"
        },
        {
            "id": 11072,
            "name": "Dosinga"
        },
        {
            "id": 11073,
            "name": "Ghatapur"
        },
        {
            "id": 11074,
            "name": "Gopinathpur"
        },
        {
            "id": 11075,
            "name": "Jagula"
        },
        {
            "id": 11076,
            "name": "Jaleswarpur"
        },
        {
            "id": 11077,
            "name": "Jashipur"
        },
        {
            "id": 11078,
            "name": "Kaithakhola"
        },
        {
            "id": 11079,
            "name": "Kandagaradi"
        },
        {
            "id": 11080,
            "name": "Karanapalli"
        },
        {
            "id": 11081,
            "name": "Karanjamal"
        },
        {
            "id": 11082,
            "name": "Kheranga"
        },
        {
            "id": 11083,
            "name": "Khodalpokhari"
        },
        {
            "id": 11084,
            "name": "Madhapur"
        },
        {
            "id": 11085,
            "name": "Mahulia"
        },
        {
            "id": 11086,
            "name": "Mausudha"
        },
        {
            "id": 11087,
            "name": "Motto"
        },
        {
            "id": 11088,
            "name": "Nalagohira"
        },
        {
            "id": 11089,
            "name": "Nalgunda"
        },
        {
            "id": 11090,
            "name": "Nandapur"
        },
        {
            "id": 11091,
            "name": "Nuagan"
        },
        {
            "id": 11092,
            "name": "Ologa"
        },
        {
            "id": 11093,
            "name": "Orasahi"
        },
        {
            "id": 11094,
            "name": "Panchutikri"
        },
        {
            "id": 11095,
            "name": "Sundarpur"
        },
        {
            "id": 11096,
            "name": "Tentulidihi"
        },
        {
            "id": 11097,
            "name": "Totapada"
        },
        {
            "id": 11098,
            "name": "Uttukuda"
        }
    ],
    "9775": [
        {
            "id": 11099,
            "name": "NAC"
        }
    ],
    "9776": [
        {
            "id": 11100,
            "name": "Bamanbindha"
        },
        {
            "id": 11101,
            "name": "Barsar"
        },
        {
            "id": 11102,
            "name": "Daulatpur"
        },
        {
            "id": 11103,
            "name": "Gobindapur"
        },
        {
            "id": 11104,
            "name": "Harsinghpur"
        },
        {
            "id": 11105,
            "name": "Jamjodi"
        },
        {
            "id": 11106,
            "name": "Narendrapur"
        },
        {
            "id": 11107,
            "name": "Rajnagar"
        },
        {
            "id": 11108,
            "name": "Sahapur"
        }
    ],
    "9777": [
        {
            "id": 11109,
            "name": "Anandapur"
        },
        {
            "id": 11110,
            "name": "Arjunpur"
        },
        {
            "id": 11111,
            "name": "Asurali"
        },
        {
            "id": 11112,
            "name": "Bamakura"
        },
        {
            "id": 11113,
            "name": "Bayangdihi"
        },
        {
            "id": 11114,
            "name": "Bhagabanpur"
        },
        {
            "id": 11115,
            "name": "Bhattasahi"
        },
        {
            "id": 11116,
            "name": "Daipur"
        },
        {
            "id": 11117,
            "name": "Dalanga"
        },
        {
            "id": 11118,
            "name": "Dhamnagar(N)"
        },
        {
            "id": 11119,
            "name": "Dhusuri"
        },
        {
            "id": 11120,
            "name": "Dobal"
        },
        {
            "id": 11121,
            "name": "Fatepur"
        },
        {
            "id": 11122,
            "name": "Gadiali"
        },
        {
            "id": 11123,
            "name": "Gobindpur"
        },
        {
            "id": 11124,
            "name": "Hasanabad"
        },
        {
            "id": 11125,
            "name": "Kalyani"
        },
        {
            "id": 11126,
            "name": "Karada"
        },
        {
            "id": 11127,
            "name": "Kashimpur"
        },
        {
            "id": 11128,
            "name": "Katasahi"
        },
        {
            "id": 11129,
            "name": "Khadipada"
        },
        {
            "id": 11130,
            "name": "Khaparpada"
        },
        {
            "id": 11131,
            "name": "Kothar"
        },
        {
            "id": 11132,
            "name": "Mustafpur"
        },
        {
            "id": 11133,
            "name": "Nadigaon"
        },
        {
            "id": 11134,
            "name": "Padhani"
        },
        {
            "id": 11135,
            "name": "Palasahi"
        },
        {
            "id": 11136,
            "name": "Raipur"
        },
        {
            "id": 11137,
            "name": "R B Pur"
        },
        {
            "id": 11138,
            "name": "Sahaspur"
        },
        {
            "id": 11139,
            "name": "S Chudakuti"
        },
        {
            "id": 11140,
            "name": "Sohada"
        }
    ],
    "9778": [
        {
            "id": 11141,
            "name": "Achak"
        },
        {
            "id": 11142,
            "name": "Baro"
        },
        {
            "id": 11143,
            "name": "Bilana"
        },
        {
            "id": 11144,
            "name": "Bodak"
        },
        {
            "id": 11145,
            "name": "Dolasahi"
        },
        {
            "id": 11146,
            "name": "Fatepur"
        },
        {
            "id": 11147,
            "name": "Guamal"
        },
        {
            "id": 11148,
            "name": "Harsinghpur"
        },
        {
            "id": 11149,
            "name": "Kanpada"
        },
        {
            "id": 11150,
            "name": "Kubera"
        },
        {
            "id": 11151,
            "name": "Mangalpur"
        },
        {
            "id": 11152,
            "name": "Narendrapur"
        },
        {
            "id": 11153,
            "name": "Nayananda"
        },
        {
            "id": 11154,
            "name": "Paliabindha"
        },
        {
            "id": 11155,
            "name": "Shyamsundarpur"
        },
        {
            "id": 11156,
            "name": "Talagopabindha"
        },
        {
            "id": 11157,
            "name": "Talapada"
        }
    ],
    "9779": [
        {
            "id": 11158,
            "name": "Artal"
        },
        {
            "id": 11159,
            "name": "Borbhatta"
        },
        {
            "id": 11160,
            "name": "Borda"
        },
        {
            "id": 11161,
            "name": "Chahagaon"
        },
        {
            "id": 11162,
            "name": "Chheliamal"
        },
        {
            "id": 11163,
            "name": "Dadpur"
        },
        {
            "id": 11164,
            "name": "Deypur"
        },
        {
            "id": 11165,
            "name": "Dumuria"
        },
        {
            "id": 11166,
            "name": "Gandbarjhola"
        },
        {
            "id": 11167,
            "name": "Gudialipadar"
        },
        {
            "id": 11168,
            "name": "Gurjung"
        },
        {
            "id": 11169,
            "name": "Kalam"
        },
        {
            "id": 11170,
            "name": "Kamthana"
        },
        {
            "id": 11171,
            "name": "Karlaguda"
        },
        {
            "id": 11172,
            "name": "Karlapada"
        },
        {
            "id": 11173,
            "name": "Kendupati"
        },
        {
            "id": 11174,
            "name": "Kuliamal"
        },
        {
            "id": 11175,
            "name": "Laxmipur"
        },
        {
            "id": 11176,
            "name": "Madiguda"
        },
        {
            "id": 11177,
            "name": "Matia"
        },
        {
            "id": 11178,
            "name": "Medinipur"
        },
        {
            "id": 11179,
            "name": "Palna"
        },
        {
            "id": 11180,
            "name": "Palsijharan"
        },
        {
            "id": 11181,
            "name": "Seinpur"
        },
        {
            "id": 11182,
            "name": "Sikuan"
        },
        {
            "id": 11183,
            "name": "Sripur"
        },
        {
            "id": 11184,
            "name": "Thuapadar"
        },
        {
            "id": 11185,
            "name": "Udepur"
        }
    ],
    "9780": [
        {
            "id": 11186,
            "name": "NAC"
        }
    ],
    "9781": [
        {
            "id": 11187,
            "name": "Adhamunda"
        },
        {
            "id": 11188,
            "name": "Balsi"
        },
        {
            "id": 11189,
            "name": "Boria"
        },
        {
            "id": 11190,
            "name": "Chancher"
        },
        {
            "id": 11191,
            "name": "Deogaon"
        },
        {
            "id": 11192,
            "name": "Gaigaon"
        },
        {
            "id": 11193,
            "name": "Gokuleswar"
        },
        {
            "id": 11194,
            "name": "Kandel"
        },
        {
            "id": 11195,
            "name": "Kikia"
        },
        {
            "id": 11196,
            "name": "Kundabandh"
        },
        {
            "id": 11197,
            "name": "Laitara"
        },
        {
            "id": 11198,
            "name": "Nashigaon"
        },
        {
            "id": 11199,
            "name": "Pastikudi"
        },
        {
            "id": 11200,
            "name": "Patharla"
        },
        {
            "id": 11201,
            "name": "Phatkamal"
        },
        {
            "id": 11202,
            "name": "Sirol"
        },
        {
            "id": 11203,
            "name": "Utkela"
        }
    ],
    "9782": [
        {
            "id": 11204,
            "name": "NAC"
        }
    ],
    "9783": [
        {
            "id": 11205,
            "name": "Badbasul"
        },
        {
            "id": 11206,
            "name": "Badfurla"
        },
        {
            "id": 11207,
            "name": "Bad Ghumer"
        },
        {
            "id": 11208,
            "name": "Bagad"
        },
        {
            "id": 11209,
            "name": "Behera"
        },
        {
            "id": 11210,
            "name": "Boden"
        },
        {
            "id": 11211,
            "name": "Brahman Chhendia"
        },
        {
            "id": 11212,
            "name": "Chhanchanbahali"
        },
        {
            "id": 11213,
            "name": "Chhilpa"
        },
        {
            "id": 11214,
            "name": "Dhanrpur"
        },
        {
            "id": 11215,
            "name": "Dumerguda"
        },
        {
            "id": 11216,
            "name": "Gadiajore"
        },
        {
            "id": 11217,
            "name": "Jayantpur"
        },
        {
            "id": 11218,
            "name": "Kadalimunda"
        },
        {
            "id": 11219,
            "name": "Kanagaon"
        },
        {
            "id": 11220,
            "name": "Kankeri"
        },
        {
            "id": 11221,
            "name": "Khairpadar"
        },
        {
            "id": 11222,
            "name": "Nandagaon"
        },
        {
            "id": 11223,
            "name": "Parla"
        },
        {
            "id": 11224,
            "name": "Sandhikulihari"
        },
        {
            "id": 11225,
            "name": "Tambachhada"
        },
        {
            "id": 11226,
            "name": "Tarapur"
        },
        {
            "id": 11227,
            "name": "Tendapali"
        },
        {
            "id": 11228,
            "name": "Tipiguda"
        }
    ],
    "9784": [
        {
            "id": 11229,
            "name": "NAC"
        }
    ],
    "9785": [
        {
            "id": 11230,
            "name": "Bandigaon"
        },
        {
            "id": 11231,
            "name": "Bhejiguda"
        },
        {
            "id": 11232,
            "name": "Ranmal"
        },
        {
            "id": 11233,
            "name": "Temri"
        }
    ],
    "9786": [
        {
            "id": 11234,
            "name": "Badkarlakote"
        },
        {
            "id": 11235,
            "name": "Badkutru"
        },
        {
            "id": 11236,
            "name": "Balagaon"
        },
        {
            "id": 11237,
            "name": "Bandhakana"
        },
        {
            "id": 11238,
            "name": "Bijmara"
        },
        {
            "id": 11239,
            "name": "Depur"
        },
        {
            "id": 11240,
            "name": "Haramal"
        },
        {
            "id": 11241,
            "name": "Kalampur"
        },
        {
            "id": 11242,
            "name": "Mandal"
        },
        {
            "id": 11243,
            "name": "Mingur"
        },
        {
            "id": 11244,
            "name": "Nuagaon"
        },
        {
            "id": 11245,
            "name": "Pandigaon"
        }
    ],
    "9787": [
        {
            "id": 11246,
            "name": "Ampani"
        },
        {
            "id": 11247,
            "name": "Badpodaguda"
        },
        {
            "id": 11248,
            "name": "Baradanga"
        },
        {
            "id": 11249,
            "name": "Dahagaon"
        },
        {
            "id": 11250,
            "name": "Dalguma"
        },
        {
            "id": 11251,
            "name": "Dudkathenga"
        },
        {
            "id": 11252,
            "name": "Gambhariguda"
        },
        {
            "id": 11253,
            "name": "Gotamunda"
        },
        {
            "id": 11254,
            "name": "Kandhabutara"
        },
        {
            "id": 11255,
            "name": "Kashibahal"
        },
        {
            "id": 11256,
            "name": "Kaudola"
        },
        {
            "id": 11257,
            "name": "Khuntia"
        },
        {
            "id": 11258,
            "name": "Koksara"
        },
        {
            "id": 11259,
            "name": "Ladugaon"
        },
        {
            "id": 11260,
            "name": "Mahima"
        },
        {
            "id": 11261,
            "name": "Majhiguda"
        },
        {
            "id": 11262,
            "name": "Moter"
        },
        {
            "id": 11263,
            "name": "Mushapali"
        },
        {
            "id": 11264,
            "name": "Phupgaon"
        },
        {
            "id": 11265,
            "name": "Podaguda"
        },
        {
            "id": 11266,
            "name": "Rengalpali"
        },
        {
            "id": 11267,
            "name": "Temra"
        }
    ],
    "9788": [
        {
            "id": 11268,
            "name": "Badchergaon"
        },
        {
            "id": 11269,
            "name": "Bordi"
        },
        {
            "id": 11270,
            "name": "Borguda"
        },
        {
            "id": 11271,
            "name": "Brundabahal"
        },
        {
            "id": 11272,
            "name": "Chahaka"
        },
        {
            "id": 11273,
            "name": "Chapria"
        },
        {
            "id": 11274,
            "name": "Chichia"
        },
        {
            "id": 11275,
            "name": "Daspur"
        },
        {
            "id": 11276,
            "name": "Dhamanpur"
        },
        {
            "id": 11277,
            "name": "Farang"
        },
        {
            "id": 11278,
            "name": "Funda"
        },
        {
            "id": 11279,
            "name": "Gandamer"
        },
        {
            "id": 11280,
            "name": "Golamunda"
        },
        {
            "id": 11281,
            "name": "Kegaon"
        },
        {
            "id": 11282,
            "name": "Khaliakani"
        },
        {
            "id": 11283,
            "name": "Khaliapali"
        },
        {
            "id": 11284,
            "name": "Khamarhaldi"
        },
        {
            "id": 11285,
            "name": "Kuhura"
        },
        {
            "id": 11286,
            "name": "Leter"
        },
        {
            "id": 11287,
            "name": "Mahaling"
        },
        {
            "id": 11288,
            "name": "Manjhari"
        },
        {
            "id": 11289,
            "name": "Naktikani"
        },
        {
            "id": 11290,
            "name": "Nuagaon"
        },
        {
            "id": 11291,
            "name": "Rengsapali"
        },
        {
            "id": 11292,
            "name": "Sinapali"
        },
        {
            "id": 11293,
            "name": "Temri"
        },
        {
            "id": 11294,
            "name": "Uchhala"
        },
        {
            "id": 11295,
            "name": "Udeshrunga"
        }
    ],
    "9789": [
        {
            "id": 11296,
            "name": "Atigaon"
        },
        {
            "id": 11297,
            "name": "Baldiamal"
        },
        {
            "id": 11298,
            "name": "Banijara"
        },
        {
            "id": 11299,
            "name": "Bankapalash"
        },
        {
            "id": 11300,
            "name": "Baxi Tulsipali"
        },
        {
            "id": 11301,
            "name": "Bhainriguda"
        },
        {
            "id": 11302,
            "name": "Budhidar"
        },
        {
            "id": 11303,
            "name": "Charbahal"
        },
        {
            "id": 11304,
            "name": "Charbhati"
        },
        {
            "id": 11305,
            "name": "Chhuriagarh"
        },
        {
            "id": 11306,
            "name": "Chicheiguda"
        },
        {
            "id": 11307,
            "name": "Chingudisar"
        },
        {
            "id": 11308,
            "name": "Dasigaon"
        },
        {
            "id": 11309,
            "name": "Dedar"
        },
        {
            "id": 11310,
            "name": "Dundelmal"
        },
        {
            "id": 11311,
            "name": "Goudchhendia"
        },
        {
            "id": 11312,
            "name": "Habaspur"
        },
        {
            "id": 11313,
            "name": "Kaleigaon"
        },
        {
            "id": 11314,
            "name": "Kaliakundal"
        },
        {
            "id": 11315,
            "name": "Kalopala"
        },
        {
            "id": 11316,
            "name": "Kendupati"
        },
        {
            "id": 11317,
            "name": "Mahichala"
        },
        {
            "id": 11318,
            "name": "Maliguda"
        },
        {
            "id": 11319,
            "name": "Matigaon"
        },
        {
            "id": 11320,
            "name": "Meriabandhali"
        },
        {
            "id": 11321,
            "name": "Mundraguda"
        },
        {
            "id": 11322,
            "name": "Naktiguda"
        },
        {
            "id": 11323,
            "name": "Nandol"
        },
        {
            "id": 11324,
            "name": "Palas"
        },
        {
            "id": 11325,
            "name": "Pilikia"
        },
        {
            "id": 11326,
            "name": "Rajpur"
        },
        {
            "id": 11327,
            "name": "Sanyasikundamal"
        },
        {
            "id": 11328,
            "name": "Taljaring"
        },
        {
            "id": 11329,
            "name": "Talmala"
        }
    ],
    "9790": [
        {
            "id": 11330,
            "name": "NAC"
        }
    ],
    "9791": [
        {
            "id": 11331,
            "name": "Chancher"
        },
        {
            "id": 11332,
            "name": "Duarsuni"
        },
        {
            "id": 11333,
            "name": "Jugsaipatna"
        },
        {
            "id": 11334,
            "name": "Kutrukhamar"
        },
        {
            "id": 11335,
            "name": "Malgaon"
        },
        {
            "id": 11336,
            "name": "Risigaon"
        },
        {
            "id": 11337,
            "name": "Sagada"
        },
        {
            "id": 11338,
            "name": "Talbelgaon"
        }
    ],
    "9792": [
        {
            "id": 11339,
            "name": "Ainlabhata"
        },
        {
            "id": 11340,
            "name": "Badatemri"
        },
        {
            "id": 11341,
            "name": "Badkarlakote"
        },
        {
            "id": 11342,
            "name": "Badpujhariguda"
        },
        {
            "id": 11343,
            "name": "Baner"
        },
        {
            "id": 11344,
            "name": "Bhainripali"
        },
        {
            "id": 11345,
            "name": "Bhejiguda"
        },
        {
            "id": 11346,
            "name": "Dhansuli"
        },
        {
            "id": 11347,
            "name": "Hirapur"
        },
        {
            "id": 11348,
            "name": "Jaipatna"
        },
        {
            "id": 11349,
            "name": "Khaliabhata"
        },
        {
            "id": 11350,
            "name": "Kuchagaon"
        },
        {
            "id": 11351,
            "name": "Mangalpur"
        },
        {
            "id": 11352,
            "name": "Mukhiguda"
        },
        {
            "id": 11353,
            "name": "Paikkendumundi"
        },
        {
            "id": 11354,
            "name": "Pratappur"
        },
        {
            "id": 11355,
            "name": "Rengalpali"
        },
        {
            "id": 11356,
            "name": "Sargiguda"
        },
        {
            "id": 11357,
            "name": "Sindhikaguda"
        },
        {
            "id": 11358,
            "name": "Uchhala"
        }
    ],
    "9793": [
        {
            "id": 11359,
            "name": "Bandhapari"
        },
        {
            "id": 11360,
            "name": "Basantapada"
        },
        {
            "id": 11361,
            "name": "Baterlima"
        },
        {
            "id": 11362,
            "name": "Bedagaon"
        },
        {
            "id": 11363,
            "name": "Bengaon"
        },
        {
            "id": 11364,
            "name": "Bhatangpadar"
        },
        {
            "id": 11365,
            "name": "Bhurtigarh"
        },
        {
            "id": 11366,
            "name": "Bijepur"
        },
        {
            "id": 11367,
            "name": "Biswanathpur"
        },
        {
            "id": 11368,
            "name": "Champadeipur"
        },
        {
            "id": 11369,
            "name": "Chhatrapur"
        },
        {
            "id": 11370,
            "name": "Gobardhanpur"
        },
        {
            "id": 11371,
            "name": "Gundri"
        },
        {
            "id": 11372,
            "name": "Jagannathpur"
        },
        {
            "id": 11373,
            "name": "Kadamguda"
        },
        {
            "id": 11374,
            "name": "Kamardha"
        },
        {
            "id": 11375,
            "name": "Kankutru"
        },
        {
            "id": 11376,
            "name": "Kumkhal"
        },
        {
            "id": 11377,
            "name": "Lakhabahali"
        },
        {
            "id": 11378,
            "name": "Lanjee"
        },
        {
            "id": 11379,
            "name": "Lanjigarh"
        },
        {
            "id": 11380,
            "name": "Lanjigarh-road"
        },
        {
            "id": 11381,
            "name": "Malijubang"
        },
        {
            "id": 11382,
            "name": "Pahadpadar"
        },
        {
            "id": 11383,
            "name": "Pokharibandha"
        },
        {
            "id": 11384,
            "name": "Trilochanpur"
        }
    ],
    "9794": [
        {
            "id": 11385,
            "name": "Badchhatrang"
        },
        {
            "id": 11386,
            "name": "Bagdiani"
        },
        {
            "id": 11387,
            "name": "Birikot"
        },
        {
            "id": 11388,
            "name": "Dumerpadar"
        },
        {
            "id": 11389,
            "name": "Gopalpur"
        },
        {
            "id": 11390,
            "name": "Gopinathpur"
        },
        {
            "id": 11391,
            "name": "Gunpur"
        },
        {
            "id": 11392,
            "name": "Kaniguma"
        },
        {
            "id": 11393,
            "name": "Karlapat"
        },
        {
            "id": 11394,
            "name": "Kerpai"
        },
        {
            "id": 11395,
            "name": "Kiapadar"
        },
        {
            "id": 11396,
            "name": "Mahulpatna"
        },
        {
            "id": 11397,
            "name": "Maligaon"
        },
        {
            "id": 11398,
            "name": "Nakrundi"
        },
        {
            "id": 11399,
            "name": "Odri"
        },
        {
            "id": 11400,
            "name": "Padepadar"
        },
        {
            "id": 11401,
            "name": "Podapadar(B)"
        },
        {
            "id": 11402,
            "name": "Silet"
        },
        {
            "id": 11403,
            "name": "Sindhipadar"
        },
        {
            "id": 11404,
            "name": "Tala-ambpadar"
        },
        {
            "id": 11405,
            "name": "Talajhapi"
        },
        {
            "id": 11406,
            "name": "Talnagi"
        },
        {
            "id": 11407,
            "name": "Th.Rampur"
        },
        {
            "id": 11408,
            "name": "Thuamul"
        }
    ],
    "9795": [
        {
            "id": 11409,
            "name": "Borpadar"
        },
        {
            "id": 11410,
            "name": "Gajabahal"
        },
        {
            "id": 11411,
            "name": "Joradobra"
        },
        {
            "id": 11412,
            "name": "Karlamunda"
        },
        {
            "id": 11413,
            "name": "Pourkela"
        },
        {
            "id": 11414,
            "name": "Rajpur"
        },
        {
            "id": 11415,
            "name": "Regeda"
        },
        {
            "id": 11416,
            "name": "Rinja"
        },
        {
            "id": 11417,
            "name": "Risida"
        },
        {
            "id": 11418,
            "name": "Saplahara"
        },
        {
            "id": 11419,
            "name": "Sargulmalpada"
        },
        {
            "id": 11420,
            "name": "Teresinga"
        }
    ],
    "9796": [
        {
            "id": 11421,
            "name": "Belkhandi"
        },
        {
            "id": 11422,
            "name": "Hatikhoj"
        },
        {
            "id": 11423,
            "name": "Kantesir"
        },
        {
            "id": 11424,
            "name": "Kasrupada"
        },
        {
            "id": 11425,
            "name": "Nunmath"
        },
        {
            "id": 11426,
            "name": "Paralsinga"
        },
        {
            "id": 11427,
            "name": "Sirjapali"
        },
        {
            "id": 11428,
            "name": "Tundla"
        },
        {
            "id": 11429,
            "name": "Turlakhaman"
        }
    ],
    "9797": [
        {
            "id": 11430,
            "name": "Alatara"
        },
        {
            "id": 11431,
            "name": "Bamak"
        },
        {
            "id": 11432,
            "name": "Barabandha"
        },
        {
            "id": 11433,
            "name": "Damakarlakhunta"
        },
        {
            "id": 11434,
            "name": "Dedsuli"
        },
        {
            "id": 11435,
            "name": "Dudukaranja"
        },
        {
            "id": 11436,
            "name": "Gochadangen"
        },
        {
            "id": 11437,
            "name": "Lubengad"
        },
        {
            "id": 11438,
            "name": "M.rampur"
        },
        {
            "id": 11439,
            "name": "Madanpur"
        },
        {
            "id": 11440,
            "name": "Manikera"
        },
        {
            "id": 11441,
            "name": "Mohangiri"
        },
        {
            "id": 11442,
            "name": "Muding"
        },
        {
            "id": 11443,
            "name": "Nunpur"
        },
        {
            "id": 11444,
            "name": "Pandakamal"
        },
        {
            "id": 11445,
            "name": "Saidalanga"
        },
        {
            "id": 11446,
            "name": "Salepali"
        },
        {
            "id": 11447,
            "name": "Singpur"
        },
        {
            "id": 11448,
            "name": "Urladani"
        }
    ],
    "9798": [
        {
            "id": 11449,
            "name": "Baddharpur"
        },
        {
            "id": 11450,
            "name": "Badharpur"
        },
        {
            "id": 11451,
            "name": "Bagpur"
        },
        {
            "id": 11452,
            "name": "Balisinga"
        },
        {
            "id": 11453,
            "name": "Balsinga"
        },
        {
            "id": 11454,
            "name": "Bhanpur"
        },
        {
            "id": 11455,
            "name": "Bhimkela"
        },
        {
            "id": 11456,
            "name": "Gadebandh"
        },
        {
            "id": 11457,
            "name": "Ghantamal"
        },
        {
            "id": 11458,
            "name": "Ghodabandh"
        },
        {
            "id": 11459,
            "name": "Ghodabandha"
        },
        {
            "id": 11460,
            "name": "Gigina"
        },
        {
            "id": 11461,
            "name": "Karmegaon"
        },
        {
            "id": 11462,
            "name": "Kurmel"
        },
        {
            "id": 11463,
            "name": "Mandel"
        },
        {
            "id": 11464,
            "name": "Muskuti"
        },
        {
            "id": 11465,
            "name": "Narla"
        },
        {
            "id": 11466,
            "name": "Nishanpur"
        },
        {
            "id": 11467,
            "name": "Palam"
        },
        {
            "id": 11468,
            "name": "Rakshi"
        },
        {
            "id": 11469,
            "name": "Raksi"
        },
        {
            "id": 11470,
            "name": "Rupra"
        },
        {
            "id": 11471,
            "name": "Rupra-road"
        },
        {
            "id": 11472,
            "name": "Rupra road"
        },
        {
            "id": 11473,
            "name": "Santpur"
        },
        {
            "id": 11474,
            "name": "Sargiguda"
        },
        {
            "id": 11475,
            "name": "Saria"
        },
        {
            "id": 11476,
            "name": "Sarian"
        },
        {
            "id": 11477,
            "name": "Sergarh"
        },
        {
            "id": 11478,
            "name": "Takarla"
        },
        {
            "id": 11479,
            "name": "Tulapada"
        },
        {
            "id": 11480,
            "name": "Ulikupa"
        }
    ],
    "9799": [
        {
            "id": 11481,
            "name": "Banigochha"
        },
        {
            "id": 11482,
            "name": "Bhogabadi"
        },
        {
            "id": 11483,
            "name": "Chadeyapalli"
        },
        {
            "id": 11484,
            "name": "Dihagaon"
        },
        {
            "id": 11485,
            "name": "Duda"
        },
        {
            "id": 11486,
            "name": "Gholahandi"
        },
        {
            "id": 11487,
            "name": "Ghugudipada"
        },
        {
            "id": 11488,
            "name": "Kalasakhamana"
        },
        {
            "id": 11489,
            "name": "Khamarasahi"
        },
        {
            "id": 11490,
            "name": "Kujamendhi"
        },
        {
            "id": 11491,
            "name": "Kulurukumpa"
        },
        {
            "id": 11492,
            "name": "Madhyakhanda"
        },
        {
            "id": 11493,
            "name": "Nachhipur"
        },
        {
            "id": 11494,
            "name": "Nuagaon"
        },
        {
            "id": 11495,
            "name": "Pokharigochha"
        },
        {
            "id": 11496,
            "name": "Sariganda"
        },
        {
            "id": 11497,
            "name": "Similisahi"
        },
        {
            "id": 11498,
            "name": "Takera"
        },
        {
            "id": 11499,
            "name": "Tendabadi"
        },
        {
            "id": 11500,
            "name": "Tumandi"
        }
    ],
    "9800": [
        {
            "id": 11501,
            "name": "NAC"
        }
    ],
    "9801": [
        {
            "id": 11502,
            "name": "Adakata"
        },
        {
            "id": 11503,
            "name": "Badasilinga"
        },
        {
            "id": 11504,
            "name": "Belapadapatna"
        },
        {
            "id": 11505,
            "name": "Chhamundia"
        },
        {
            "id": 11506,
            "name": "Gania"
        },
        {
            "id": 11507,
            "name": "Karadapada"
        },
        {
            "id": 11508,
            "name": "Kishoreprasad"
        },
        {
            "id": 11509,
            "name": "Rasanga"
        }
    ],
    "9802": [
        {
            "id": 11510,
            "name": "Badagotha"
        },
        {
            "id": 11511,
            "name": "Bahadajhola"
        },
        {
            "id": 11512,
            "name": "Beruhanbari"
        },
        {
            "id": 11513,
            "name": "Chahali"
        },
        {
            "id": 11514,
            "name": "Dhenkana"
        },
        {
            "id": 11515,
            "name": "Durudura"
        },
        {
            "id": 11516,
            "name": "Gateri"
        },
        {
            "id": 11517,
            "name": "Gumi"
        },
        {
            "id": 11518,
            "name": "Haripur"
        },
        {
            "id": 11519,
            "name": "Jakeda"
        },
        {
            "id": 11520,
            "name": "Kaptapalli"
        },
        {
            "id": 11521,
            "name": "Khandugaon"
        },
        {
            "id": 11522,
            "name": "Korada"
        },
        {
            "id": 11523,
            "name": "Mahipur"
        },
        {
            "id": 11524,
            "name": "Maichheli"
        },
        {
            "id": 11525,
            "name": "Malisahi"
        },
        {
            "id": 11526,
            "name": "Nuagaon"
        },
        {
            "id": 11527,
            "name": "Paradhipi"
        },
        {
            "id": 11528,
            "name": "Sikrida"
        },
        {
            "id": 11529,
            "name": "Singarpalli"
        },
        {
            "id": 11530,
            "name": "Sorada"
        },
        {
            "id": 11531,
            "name": "Udaypur"
        }
    ],
    "9803": [
        {
            "id": 11532,
            "name": "Badasahara"
        },
        {
            "id": 11533,
            "name": "Baghuapali"
        },
        {
            "id": 11534,
            "name": "Baigunia"
        },
        {
            "id": 11535,
            "name": "Baunsbati"
        },
        {
            "id": 11536,
            "name": "Bhagabanpur"
        },
        {
            "id": 11537,
            "name": "Bhapur"
        },
        {
            "id": 11538,
            "name": "Bijipur"
        },
        {
            "id": 11539,
            "name": "Ckakradharprasad"
        },
        {
            "id": 11540,
            "name": "Dhanachangada"
        },
        {
            "id": 11541,
            "name": "Fategarh"
        },
        {
            "id": 11542,
            "name": "Golapokhari"
        },
        {
            "id": 11543,
            "name": "Kainfulia"
        },
        {
            "id": 11544,
            "name": "Karabar"
        },
        {
            "id": 11545,
            "name": "Kendupalli"
        },
        {
            "id": 11546,
            "name": "Nimani"
        },
        {
            "id": 11547,
            "name": "Padmabati"
        },
        {
            "id": 11548,
            "name": "Patharchakada"
        },
        {
            "id": 11549,
            "name": "Rakama"
        },
        {
            "id": 11550,
            "name": "Salapada"
        },
        {
            "id": 11551,
            "name": "Sasan"
        }
    ],
    "9804": [
        {
            "id": 11552,
            "name": "NAC"
        }
    ],
    "9805": [
        {
            "id": 11553,
            "name": "Bada Banapur"
        },
        {
            "id": 11554,
            "name": "Balabhadra prasad"
        },
        {
            "id": 11555,
            "name": "Banamalipur"
        },
        {
            "id": 11556,
            "name": "Benagadia"
        },
        {
            "id": 11557,
            "name": "Bodasa"
        },
        {
            "id": 11558,
            "name": "Gadiasahi"
        },
        {
            "id": 11559,
            "name": "Guthuni"
        },
        {
            "id": 11560,
            "name": "Jagannathprasad"
        },
        {
            "id": 11561,
            "name": "Jogiapalli"
        },
        {
            "id": 11562,
            "name": "Kantilo"
        },
        {
            "id": 11563,
            "name": "Khalisahi"
        },
        {
            "id": 11564,
            "name": "Kiajhar"
        },
        {
            "id": 11565,
            "name": "Kosaka"
        },
        {
            "id": 11566,
            "name": "Kumbarapara"
        },
        {
            "id": 11567,
            "name": "Mardarajapur"
        },
        {
            "id": 11568,
            "name": "Ranichheli"
        },
        {
            "id": 11569,
            "name": "Ranipada"
        },
        {
            "id": 11570,
            "name": "Rayatdholamara"
        },
        {
            "id": 11571,
            "name": "Salajharia"
        },
        {
            "id": 11572,
            "name": "Sidhamula"
        },
        {
            "id": 11573,
            "name": "Sikharpur"
        },
        {
            "id": 11574,
            "name": "Singhapada"
        }
    ],
    "9806": [
        {
            "id": 11575,
            "name": "NAC"
        }
    ],
    "9807": [
        {
            "id": 11576,
            "name": "Badapandusar"
        },
        {
            "id": 11577,
            "name": "Balugaon"
        },
        {
            "id": 11578,
            "name": "Baunsiapada"
        },
        {
            "id": 11579,
            "name": "Bhatasahi"
        },
        {
            "id": 11580,
            "name": "Biruda"
        },
        {
            "id": 11581,
            "name": "Champatipur"
        },
        {
            "id": 11582,
            "name": "Chandibasta"
        },
        {
            "id": 11583,
            "name": "Gadadharprasad"
        },
        {
            "id": 11584,
            "name": "Gambharidihi"
        },
        {
            "id": 11585,
            "name": "Ghadual"
        },
        {
            "id": 11586,
            "name": "Ikiri"
        },
        {
            "id": 11587,
            "name": "Itamati"
        },
        {
            "id": 11588,
            "name": "Jemadeipurpatna"
        },
        {
            "id": 11589,
            "name": "Kalikaprasad"
        },
        {
            "id": 11590,
            "name": "Kendudhipi"
        },
        {
            "id": 11591,
            "name": "Khuntubandha"
        },
        {
            "id": 11592,
            "name": "Kridaspur"
        },
        {
            "id": 11593,
            "name": "Lakhmiprasad"
        },
        {
            "id": 11594,
            "name": "Lathipada"
        },
        {
            "id": 11595,
            "name": "Lenkudipada"
        },
        {
            "id": 11596,
            "name": "Machhipada"
        },
        {
            "id": 11597,
            "name": "Muthagadia"
        },
        {
            "id": 11598,
            "name": "Nabaghanpur"
        },
        {
            "id": 11599,
            "name": "Nadiali"
        },
        {
            "id": 11600,
            "name": "Natugaon"
        },
        {
            "id": 11601,
            "name": "Notar"
        },
        {
            "id": 11602,
            "name": "Saradhapur"
        },
        {
            "id": 11603,
            "name": "Sarankul"
        },
        {
            "id": 11604,
            "name": "Sinduria"
        }
    ],
    "9808": [
        {
            "id": 11605,
            "name": "NAC"
        }
    ],
    "9809": [
        {
            "id": 11606,
            "name": "Adakata"
        },
        {
            "id": 11607,
            "name": "Arada"
        },
        {
            "id": 11608,
            "name": "Banthapur"
        },
        {
            "id": 11609,
            "name": "Bhadikila"
        },
        {
            "id": 11610,
            "name": "Bhaliadihi"
        },
        {
            "id": 11611,
            "name": "Giridipali"
        },
        {
            "id": 11612,
            "name": "Golagoan"
        },
        {
            "id": 11613,
            "name": "Goudaput"
        },
        {
            "id": 11614,
            "name": "Haridabandha"
        },
        {
            "id": 11615,
            "name": "Komanda"
        },
        {
            "id": 11616,
            "name": "Korapitha"
        },
        {
            "id": 11617,
            "name": "Kurala"
        },
        {
            "id": 11618,
            "name": "Nandighara"
        },
        {
            "id": 11619,
            "name": "Panderipada"
        },
        {
            "id": 11620,
            "name": "Pantikhari"
        },
        {
            "id": 11621,
            "name": "Rabera"
        },
        {
            "id": 11622,
            "name": "Rabigadia"
        },
        {
            "id": 11623,
            "name": "Ranganipatna"
        },
        {
            "id": 11624,
            "name": "Rohibank"
        },
        {
            "id": 11625,
            "name": "Sakiri"
        },
        {
            "id": 11626,
            "name": "Saradhapur"
        },
        {
            "id": 11627,
            "name": "Sunamuhin"
        }
    ],
    "9810": [
        {
            "id": 11628,
            "name": "NAC"
        }
    ],
    "9811": [
        {
            "id": 11629,
            "name": "Angisingi"
        },
        {
            "id": 11630,
            "name": "Badagorada"
        },
        {
            "id": 11631,
            "name": "Barasahi"
        },
        {
            "id": 11632,
            "name": "Bhaliadihi"
        },
        {
            "id": 11633,
            "name": "Dimisara"
        },
        {
            "id": 11634,
            "name": "Godipada"
        },
        {
            "id": 11635,
            "name": "Godipalli"
        },
        {
            "id": 11636,
            "name": "Gotisahi"
        },
        {
            "id": 11637,
            "name": "Haridabanda"
        },
        {
            "id": 11638,
            "name": "Hariharpur"
        },
        {
            "id": 11639,
            "name": "Kajalaipali"
        },
        {
            "id": 11640,
            "name": "Magarbandha"
        },
        {
            "id": 11641,
            "name": "Panchiridamanpur"
        },
        {
            "id": 11642,
            "name": "Panchumu"
        },
        {
            "id": 11643,
            "name": "Saranakul"
        },
        {
            "id": 11644,
            "name": "Sikharpur"
        },
        {
            "id": 11645,
            "name": "Solapata"
        },
        {
            "id": 11646,
            "name": "Volcano"
        }
    ],
    "9812": [
        {
            "id": 11647,
            "name": "Bajrakota"
        },
        {
            "id": 11648,
            "name": "Balabhadrapur"
        },
        {
            "id": 11649,
            "name": "Bandhamunda"
        },
        {
            "id": 11650,
            "name": "Baunsgarh"
        },
        {
            "id": 11651,
            "name": "Brajarajapur"
        },
        {
            "id": 11652,
            "name": "Brundabanpur"
        },
        {
            "id": 11653,
            "name": "Champagarh"
        },
        {
            "id": 11654,
            "name": "Champapedi"
        },
        {
            "id": 11655,
            "name": "Chandapur"
        },
        {
            "id": 11656,
            "name": "Damasahi"
        },
        {
            "id": 11657,
            "name": "Darpanarayanpur"
        },
        {
            "id": 11658,
            "name": "Gadabanikilo"
        },
        {
            "id": 11659,
            "name": "Gopalpur"
        },
        {
            "id": 11660,
            "name": "Gourangapur"
        },
        {
            "id": 11661,
            "name": "Hansara"
        },
        {
            "id": 11662,
            "name": "Jankia"
        },
        {
            "id": 11663,
            "name": "Jhadapada"
        },
        {
            "id": 11664,
            "name": "Kandapara"
        },
        {
            "id": 11665,
            "name": "Kandhanayagarh"
        },
        {
            "id": 11666,
            "name": "Kasanda"
        },
        {
            "id": 11667,
            "name": "Kerandatangi"
        },
        {
            "id": 11668,
            "name": "Khairpalli"
        },
        {
            "id": 11669,
            "name": "Khatia"
        },
        {
            "id": 11670,
            "name": "Kulasara"
        },
        {
            "id": 11671,
            "name": "Lodhachua"
        },
        {
            "id": 11672,
            "name": "Mahatapala"
        },
        {
            "id": 11673,
            "name": "Majhiakhanda"
        },
        {
            "id": 11674,
            "name": "Mayurjhalia"
        },
        {
            "id": 11675,
            "name": "Narasinghpur"
        },
        {
            "id": 11676,
            "name": "Narendrapur"
        },
        {
            "id": 11677,
            "name": "Patia"
        },
        {
            "id": 11678,
            "name": "Pimpala"
        },
        {
            "id": 11679,
            "name": "Raipada"
        },
        {
            "id": 11680,
            "name": "Rajasunakhala"
        },
        {
            "id": 11681,
            "name": "Rankadeuli"
        },
        {
            "id": 11682,
            "name": "Surukabadi"
        },
        {
            "id": 11683,
            "name": "Talakani"
        }
    ],
    "9813": [
        {
            "id": 11684,
            "name": "NAC"
        }
    ],
    "9814": [
        {
            "id": 11685,
            "name": "Achhutabasanta"
        },
        {
            "id": 11686,
            "name": "Anaka"
        },
        {
            "id": 11687,
            "name": "Arakhpur"
        },
        {
            "id": 11688,
            "name": "Badabalikuda"
        },
        {
            "id": 11689,
            "name": "Badaghumuri"
        },
        {
            "id": 11690,
            "name": "Balichandrapur"
        },
        {
            "id": 11691,
            "name": "Balipadia"
        },
        {
            "id": 11692,
            "name": "Bandalo"
        },
        {
            "id": 11693,
            "name": "Bantala"
        },
        {
            "id": 11694,
            "name": "Barada"
        },
        {
            "id": 11695,
            "name": "Barchana"
        },
        {
            "id": 11696,
            "name": "Barpada"
        },
        {
            "id": 11697,
            "name": "Bharatpur"
        },
        {
            "id": 11698,
            "name": "Bhusandapur"
        },
        {
            "id": 11699,
            "name": "Bikramtiran"
        },
        {
            "id": 11700,
            "name": "Byree"
        },
        {
            "id": 11701,
            "name": "Champapur"
        },
        {
            "id": 11702,
            "name": "Chandital"
        },
        {
            "id": 11703,
            "name": "Charinangal"
        },
        {
            "id": 11704,
            "name": "Chhatia"
        },
        {
            "id": 11705,
            "name": "Darpan"
        },
        {
            "id": 11706,
            "name": "Dhanamandal"
        },
        {
            "id": 11707,
            "name": "Gopalpur"
        },
        {
            "id": 11708,
            "name": "Kaimatia"
        },
        {
            "id": 11709,
            "name": "Khaira"
        },
        {
            "id": 11710,
            "name": "Kolangiri"
        },
        {
            "id": 11711,
            "name": "Kundal"
        },
        {
            "id": 11712,
            "name": "Majhipada"
        },
        {
            "id": 11713,
            "name": "Manduka"
        },
        {
            "id": 11714,
            "name": "Nalipur"
        },
        {
            "id": 11715,
            "name": "Nelia"
        },
        {
            "id": 11716,
            "name": "Palei"
        },
        {
            "id": 11717,
            "name": "Paria"
        },
        {
            "id": 11718,
            "name": "Patarajpur"
        },
        {
            "id": 11719,
            "name": "Radhadeipur"
        },
        {
            "id": 11720,
            "name": "Raipur"
        },
        {
            "id": 11721,
            "name": "Salapada"
        },
        {
            "id": 11722,
            "name": "Samia"
        },
        {
            "id": 11723,
            "name": "Santipur"
        },
        {
            "id": 11724,
            "name": "Saudia"
        },
        {
            "id": 11725,
            "name": "Siha"
        },
        {
            "id": 11726,
            "name": "Solar"
        },
        {
            "id": 11727,
            "name": "Sunguda"
        }
    ],
    "9815": [
        {
            "id": 11728,
            "name": "Allipur"
        },
        {
            "id": 11729,
            "name": "Amathpur"
        },
        {
            "id": 11730,
            "name": "Angalo"
        },
        {
            "id": 11731,
            "name": "Anyasipur"
        },
        {
            "id": 11732,
            "name": "Atira"
        },
        {
            "id": 11733,
            "name": "Aurangabad"
        },
        {
            "id": 11734,
            "name": "Bainsiria"
        },
        {
            "id": 11735,
            "name": "Balia"
        },
        {
            "id": 11736,
            "name": "Balibil"
        },
        {
            "id": 11737,
            "name": "Bari"
        },
        {
            "id": 11738,
            "name": "Bhanara"
        },
        {
            "id": 11739,
            "name": "Bodua"
        },
        {
            "id": 11740,
            "name": "Chandanpur"
        },
        {
            "id": 11741,
            "name": "Dharpur"
        },
        {
            "id": 11742,
            "name": "Gamu"
        },
        {
            "id": 11743,
            "name": "Golakunda"
        },
        {
            "id": 11744,
            "name": "Ishanpur"
        },
        {
            "id": 11745,
            "name": "Kalamatia"
        },
        {
            "id": 11746,
            "name": "Kimbhiriapal"
        },
        {
            "id": 11747,
            "name": "Madhusudanpur"
        },
        {
            "id": 11748,
            "name": "Mahakalapada"
        },
        {
            "id": 11749,
            "name": "Mandari"
        },
        {
            "id": 11750,
            "name": "Rampa"
        },
        {
            "id": 11751,
            "name": "Ratalang"
        },
        {
            "id": 11752,
            "name": "Ratnagiri"
        },
        {
            "id": 11753,
            "name": "Sahupada"
        },
        {
            "id": 11754,
            "name": "Serapur"
        },
        {
            "id": 11755,
            "name": "Swainkhanda"
        },
        {
            "id": 11756,
            "name": "Udayanathpur"
        }
    ],
    "9816": [
        {
            "id": 11757,
            "name": "Badasuar"
        },
        {
            "id": 11758,
            "name": "Chhatisdebil"
        },
        {
            "id": 11759,
            "name": "Erbank"
        },
        {
            "id": 11760,
            "name": "Jhalapada"
        },
        {
            "id": 11761,
            "name": "Rudrapur"
        },
        {
            "id": 11762,
            "name": "Sahaspur"
        },
        {
            "id": 11763,
            "name": "Sanasuar"
        },
        {
            "id": 11764,
            "name": "Sujanpur"
        }
    ],
    "9817": [
        {
            "id": 11765,
            "name": "Arthangan"
        },
        {
            "id": 11766,
            "name": "Atalapur"
        },
        {
            "id": 11767,
            "name": "Badakainchi"
        },
        {
            "id": 11768,
            "name": "Bahadalapur"
        },
        {
            "id": 11769,
            "name": "Bandhadiha"
        },
        {
            "id": 11770,
            "name": "Bhotaka"
        },
        {
            "id": 11771,
            "name": "Brahmabarada"
        },
        {
            "id": 11772,
            "name": "Gandhan"
        },
        {
            "id": 11773,
            "name": "Laxminagar"
        },
        {
            "id": 11774,
            "name": "Maheswarpur"
        },
        {
            "id": 11775,
            "name": "Mangarajpur"
        },
        {
            "id": 11776,
            "name": "Mugupal"
        },
        {
            "id": 11777,
            "name": "Narsinghpur"
        },
        {
            "id": 11778,
            "name": "Pahanga"
        },
        {
            "id": 11779,
            "name": "Prathamakhandi"
        },
        {
            "id": 11780,
            "name": "Rajendrapur"
        },
        {
            "id": 11781,
            "name": "Routarapur"
        },
        {
            "id": 11782,
            "name": "Samantarapur"
        },
        {
            "id": 11783,
            "name": "Tikarapada"
        }
    ],
    "9818": [
        {
            "id": 11784,
            "name": "Alakunda"
        },
        {
            "id": 11785,
            "name": "Arei"
        },
        {
            "id": 11786,
            "name": "Aunri"
        },
        {
            "id": 11787,
            "name": "Bachhal"
        },
        {
            "id": 11788,
            "name": "Bangara"
        },
        {
            "id": 11789,
            "name": "Barapada"
        },
        {
            "id": 11790,
            "name": "Binjharpur"
        },
        {
            "id": 11791,
            "name": "Bitana"
        },
        {
            "id": 11792,
            "name": "Chandramuhan"
        },
        {
            "id": 11793,
            "name": "Chikana"
        },
        {
            "id": 11794,
            "name": "Fatepur"
        },
        {
            "id": 11795,
            "name": "Ghantiali"
        },
        {
            "id": 11796,
            "name": "Guhali"
        },
        {
            "id": 11797,
            "name": "Haladidiha"
        },
        {
            "id": 11798,
            "name": "Jari"
        },
        {
            "id": 11799,
            "name": "K.Raghunathpur"
        },
        {
            "id": 11800,
            "name": "Kalyanpur"
        },
        {
            "id": 11801,
            "name": "Kapila"
        },
        {
            "id": 11802,
            "name": "Mashara"
        },
        {
            "id": 11803,
            "name": "Oleichandanpur"
        },
        {
            "id": 11804,
            "name": "Pritipur"
        },
        {
            "id": 11805,
            "name": "Ranapur"
        },
        {
            "id": 11806,
            "name": "Sasanda"
        },
        {
            "id": 11807,
            "name": "Sayedpur"
        },
        {
            "id": 11808,
            "name": "Singhpur"
        },
        {
            "id": 11809,
            "name": "Tina"
        },
        {
            "id": 11810,
            "name": "Udranga"
        },
        {
            "id": 11811,
            "name": "Uttangara"
        },
        {
            "id": 11812,
            "name": "Uttarkul"
        }
    ],
    "9819": [
        {
            "id": 11813,
            "name": "Asanapur"
        },
        {
            "id": 11814,
            "name": "Bihari"
        },
        {
            "id": 11815,
            "name": "Birajapur"
        },
        {
            "id": 11816,
            "name": "Biripata"
        },
        {
            "id": 11817,
            "name": "Biruan"
        },
        {
            "id": 11818,
            "name": "Chasakhanda"
        },
        {
            "id": 11819,
            "name": "Dahanihata"
        },
        {
            "id": 11820,
            "name": "Duttapur"
        },
        {
            "id": 11821,
            "name": "G.Nandipur"
        },
        {
            "id": 11822,
            "name": "Izapur"
        },
        {
            "id": 11823,
            "name": "Jafarpur"
        },
        {
            "id": 11824,
            "name": "Jayantara"
        },
        {
            "id": 11825,
            "name": "Kamardihi"
        },
        {
            "id": 11826,
            "name": "Kayan"
        },
        {
            "id": 11827,
            "name": "Khhannagar"
        },
        {
            "id": 11828,
            "name": "Nizampur"
        },
        {
            "id": 11829,
            "name": "Palatpur"
        },
        {
            "id": 11830,
            "name": "Rahasoi"
        },
        {
            "id": 11831,
            "name": "Susua"
        },
        {
            "id": 11832,
            "name": "Thalkudi"
        }
    ],
    "9820": [
        {
            "id": 11833,
            "name": "Abhayapur"
        },
        {
            "id": 11834,
            "name": "Anjira"
        },
        {
            "id": 11835,
            "name": "Antia"
        },
        {
            "id": 11836,
            "name": "Arabal"
        },
        {
            "id": 11837,
            "name": "Areikana"
        },
        {
            "id": 11838,
            "name": "Aruha"
        },
        {
            "id": 11839,
            "name": "Badakaima"
        },
        {
            "id": 11840,
            "name": "Bhabanipur"
        },
        {
            "id": 11841,
            "name": "Brundadeipur"
        },
        {
            "id": 11842,
            "name": "Chahata"
        },
        {
            "id": 11843,
            "name": "Chakradharpur"
        },
        {
            "id": 11844,
            "name": "Choramuhan"
        },
        {
            "id": 11845,
            "name": "Deoda"
        },
        {
            "id": 11846,
            "name": "Endalba"
        },
        {
            "id": 11847,
            "name": "Gangadharpur"
        },
        {
            "id": 11848,
            "name": "Haridaspur"
        },
        {
            "id": 11849,
            "name": "Jaraka"
        },
        {
            "id": 11850,
            "name": "Jenapur"
        },
        {
            "id": 11851,
            "name": "Kabatabandha"
        },
        {
            "id": 11852,
            "name": "Kadampal"
        },
        {
            "id": 11853,
            "name": "Kamagarh"
        },
        {
            "id": 11854,
            "name": "Kantigadia"
        },
        {
            "id": 11855,
            "name": "Khetrapal"
        },
        {
            "id": 11856,
            "name": "Kotapur"
        },
        {
            "id": 11857,
            "name": "Kumari"
        },
        {
            "id": 11858,
            "name": "Madhupurgarh"
        },
        {
            "id": 11859,
            "name": "Madhusudanpur"
        },
        {
            "id": 11860,
            "name": "Mahisara"
        },
        {
            "id": 11861,
            "name": "Mangalpur"
        },
        {
            "id": 11862,
            "name": "Marjitapur"
        },
        {
            "id": 11863,
            "name": "Mirjapur"
        },
        {
            "id": 11864,
            "name": "Neulapur"
        },
        {
            "id": 11865,
            "name": "Pakhara"
        },
        {
            "id": 11866,
            "name": "Patapur"
        },
        {
            "id": 11867,
            "name": "Patunia"
        },
        {
            "id": 11868,
            "name": "Purunaboulamal"
        },
        {
            "id": 11869,
            "name": "Raichhanda"
        },
        {
            "id": 11870,
            "name": "Rekhideipur"
        },
        {
            "id": 11871,
            "name": "Sahanidiha"
        },
        {
            "id": 11872,
            "name": "Samparu"
        },
        {
            "id": 11873,
            "name": "Sundaria"
        },
        {
            "id": 11874,
            "name": "Talajanga"
        },
        {
            "id": 11875,
            "name": "Taranjia"
        },
        {
            "id": 11876,
            "name": "Thanual"
        },
        {
            "id": 11877,
            "name": "Utterpratap"
        }
    ],
    "9821": [
        {
            "id": 11878,
            "name": "Barabati"
        },
        {
            "id": 11879,
            "name": "Jabara"
        },
        {
            "id": 11880,
            "name": "Kalan"
        },
        {
            "id": 11881,
            "name": "Nathuabara"
        },
        {
            "id": 11882,
            "name": "Odisso"
        },
        {
            "id": 11883,
            "name": "Rahamba"
        },
        {
            "id": 11884,
            "name": "Rasulpur"
        },
        {
            "id": 11885,
            "name": "Singapur"
        },
        {
            "id": 11886,
            "name": "Sribantapur"
        }
    ],
    "9822": [
        {
            "id": 11887,
            "name": "Akarapada"
        },
        {
            "id": 11888,
            "name": "Birajapur"
        },
        {
            "id": 11889,
            "name": "Champeipal"
        },
        {
            "id": 11890,
            "name": "Chhanchina"
        },
        {
            "id": 11891,
            "name": "Chittalo"
        },
        {
            "id": 11892,
            "name": "Dahanihata"
        },
        {
            "id": 11893,
            "name": "Dasarathpur"
        },
        {
            "id": 11894,
            "name": "Duduranta"
        },
        {
            "id": 11895,
            "name": "Gopalpur"
        },
        {
            "id": 11896,
            "name": "Izapur"
        },
        {
            "id": 11897,
            "name": "Jayantara"
        },
        {
            "id": 11898,
            "name": "Kanikapada"
        },
        {
            "id": 11899,
            "name": "Kaspa"
        },
        {
            "id": 11900,
            "name": "Katikata"
        },
        {
            "id": 11901,
            "name": "Khandara"
        },
        {
            "id": 11902,
            "name": "Mallikapur"
        },
        {
            "id": 11903,
            "name": "Mangalpur"
        },
        {
            "id": 11904,
            "name": "Susua"
        },
        {
            "id": 11905,
            "name": "Taliha"
        },
        {
            "id": 11906,
            "name": "Tarapada"
        }
    ],
    "9823": [
        {
            "id": 11907,
            "name": "Banapur"
        },
        {
            "id": 11908,
            "name": "Basudevpur"
        },
        {
            "id": 11909,
            "name": "Beruda"
        },
        {
            "id": 11910,
            "name": "Bhubaneswarpur"
        },
        {
            "id": 11911,
            "name": "Bhuinpur"
        },
        {
            "id": 11912,
            "name": "Bichitrapur"
        },
        {
            "id": 11913,
            "name": "Chainpur"
        },
        {
            "id": 11914,
            "name": "Jafarpur"
        },
        {
            "id": 11915,
            "name": "Jahanpur"
        },
        {
            "id": 11916,
            "name": "Jamdhar"
        },
        {
            "id": 11917,
            "name": "Khairabad"
        },
        {
            "id": 11918,
            "name": "Maheswarpur"
        },
        {
            "id": 11919,
            "name": "Malandapur"
        },
        {
            "id": 11920,
            "name": "Markandpur"
        },
        {
            "id": 11921,
            "name": "Nathasahi"
        },
        {
            "id": 11922,
            "name": "Panasa"
        },
        {
            "id": 11923,
            "name": "Shyamdaspur"
        },
        {
            "id": 11924,
            "name": "Similia"
        },
        {
            "id": 11925,
            "name": "Upper Baruhan"
        }
    ],
    "9824": [
        {
            "id": 11926,
            "name": "NAC"
        }
    ],
    "9825": [
        {
            "id": 11927,
            "name": "Amrutia"
        },
        {
            "id": 11928,
            "name": "Andhari"
        },
        {
            "id": 11929,
            "name": "Asanjhar"
        },
        {
            "id": 11930,
            "name": "Badabiruhan"
        },
        {
            "id": 11931,
            "name": "Bandalo"
        },
        {
            "id": 11932,
            "name": "Barundei"
        },
        {
            "id": 11933,
            "name": "Dhaneswar"
        },
        {
            "id": 11934,
            "name": "Goleipur"
        },
        {
            "id": 11935,
            "name": "Gourapur"
        },
        {
            "id": 11936,
            "name": "Haladigadia"
        },
        {
            "id": 11937,
            "name": "Janha"
        },
        {
            "id": 11938,
            "name": "Kacharasahi"
        },
        {
            "id": 11939,
            "name": "Kantore"
        },
        {
            "id": 11940,
            "name": "Karada"
        },
        {
            "id": 11941,
            "name": "Khaman"
        },
        {
            "id": 11942,
            "name": "Makundapur"
        },
        {
            "id": 11943,
            "name": "Mulapal"
        },
        {
            "id": 11944,
            "name": "Pachhikote"
        },
        {
            "id": 11945,
            "name": "Panikoili"
        },
        {
            "id": 11946,
            "name": "Pataranga"
        },
        {
            "id": 11947,
            "name": "Patharapada"
        },
        {
            "id": 11948,
            "name": "Ranapur"
        },
        {
            "id": 11949,
            "name": "Sadakpur"
        },
        {
            "id": 11950,
            "name": "Taharpur"
        },
        {
            "id": 11951,
            "name": "Talagarh"
        },
        {
            "id": 11952,
            "name": "Tandara"
        },
        {
            "id": 11953,
            "name": "Tarakote"
        },
        {
            "id": 11954,
            "name": "Tulati"
        }
    ],
    "9826": [
        {
            "id": 11955,
            "name": "Badakainchi"
        },
        {
            "id": 11956,
            "name": "Bahadalapur"
        },
        {
            "id": 11957,
            "name": "Bhotaka"
        },
        {
            "id": 11958,
            "name": "Gandhan"
        },
        {
            "id": 11959,
            "name": "Laxminagar"
        },
        {
            "id": 11960,
            "name": "Mugupal"
        },
        {
            "id": 11961,
            "name": "Narsinghpur"
        },
        {
            "id": 11962,
            "name": "Pahanga"
        },
        {
            "id": 11963,
            "name": "Tikarapada"
        }
    ],
    "9827": [
        {
            "id": 11964,
            "name": "NAC"
        }
    ],
    "9828": [
        {
            "id": 11965,
            "name": "Vyasanagar OG"
        }
    ],
    "9829": [
        {
            "id": 11966,
            "name": "Bangarkota"
        },
        {
            "id": 11967,
            "name": "Chandia"
        },
        {
            "id": 11968,
            "name": "Chitri"
        },
        {
            "id": 11969,
            "name": "Deulakan"
        },
        {
            "id": 11970,
            "name": "Dhuligarh"
        },
        {
            "id": 11971,
            "name": "Gobardhanpur"
        },
        {
            "id": 11972,
            "name": "Jakhapura"
        },
        {
            "id": 11973,
            "name": "Khapuriapada"
        },
        {
            "id": 11974,
            "name": "Kiajhar"
        },
        {
            "id": 11975,
            "name": "Kumbhiragadia"
        },
        {
            "id": 11976,
            "name": "Manatira"
        },
        {
            "id": 11977,
            "name": "Mangalpur"
        },
        {
            "id": 11978,
            "name": "Nachhipura"
        },
        {
            "id": 11979,
            "name": "Nadiabhanga"
        },
        {
            "id": 11980,
            "name": "Ollala"
        },
        {
            "id": 11981,
            "name": "Ostapal"
        },
        {
            "id": 11982,
            "name": "Pankapal"
        },
        {
            "id": 11983,
            "name": "Rachhipur"
        },
        {
            "id": 11984,
            "name": "Ranagundi"
        },
        {
            "id": 11985,
            "name": "Ranipada"
        },
        {
            "id": 11986,
            "name": "Salijanga"
        },
        {
            "id": 11987,
            "name": "Trijanga"
        },
        {
            "id": 11988,
            "name": "Uchhabali"
        }
    ],
    "9830": [
        {
            "id": 11989,
            "name": "Ampolba"
        },
        {
            "id": 11990,
            "name": "Atta"
        },
        {
            "id": 11991,
            "name": "Bandhagaon"
        },
        {
            "id": 11992,
            "name": "Baragadia"
        },
        {
            "id": 11993,
            "name": "Bartanda"
        },
        {
            "id": 11994,
            "name": "Chingudipal"
        },
        {
            "id": 11995,
            "name": "Duburi"
        },
        {
            "id": 11996,
            "name": "Dudhujori"
        },
        {
            "id": 11997,
            "name": "Gobarghati"
        },
        {
            "id": 11998,
            "name": "Haripur"
        },
        {
            "id": 11999,
            "name": "Hatibari"
        },
        {
            "id": 12000,
            "name": "Kaliapani"
        },
        {
            "id": 12001,
            "name": "Kankadapal"
        },
        {
            "id": 12002,
            "name": "Kansa"
        },
        {
            "id": 12003,
            "name": "Kharadi"
        },
        {
            "id": 12004,
            "name": "Kuhika"
        },
        {
            "id": 12005,
            "name": "Lembo"
        },
        {
            "id": 12006,
            "name": "Mangarajpur"
        },
        {
            "id": 12007,
            "name": "Olia"
        },
        {
            "id": 12008,
            "name": "Pimpudia"
        },
        {
            "id": 12009,
            "name": "Pingal"
        },
        {
            "id": 12010,
            "name": "Ransol"
        },
        {
            "id": 12011,
            "name": "Sansailo"
        },
        {
            "id": 12012,
            "name": "Sarangapur"
        },
        {
            "id": 12013,
            "name": "Sukindagarh"
        }
    ],
    "9831": [
        {
            "id": 12014,
            "name": "Badasahi"
        },
        {
            "id": 12015,
            "name": "Balabhadrapur"
        },
        {
            "id": 12016,
            "name": "Bhimda"
        },
        {
            "id": 12017,
            "name": "Bireswarpur"
        },
        {
            "id": 12018,
            "name": "Chandanpur"
        },
        {
            "id": 12019,
            "name": "Chhelia-(A)"
        },
        {
            "id": 12020,
            "name": "Deulia"
        },
        {
            "id": 12021,
            "name": "Durgapur"
        },
        {
            "id": 12022,
            "name": "Joginuagan"
        },
        {
            "id": 12023,
            "name": "Kendudiha"
        },
        {
            "id": 12024,
            "name": "Kochilakhunta"
        },
        {
            "id": 12025,
            "name": "Madhpur"
        },
        {
            "id": 12026,
            "name": "Manatri"
        },
        {
            "id": 12027,
            "name": "Mangobindpur"
        },
        {
            "id": 12028,
            "name": "Patisari"
        },
        {
            "id": 12029,
            "name": "Paunsia"
        },
        {
            "id": 12030,
            "name": "Pratappur"
        },
        {
            "id": 12031,
            "name": "Salgan"
        },
        {
            "id": 12032,
            "name": "Sialighaty"
        },
        {
            "id": 12033,
            "name": "Suhagpur"
        },
        {
            "id": 12034,
            "name": "Talpada"
        }
    ],
    "9832": [
        {
            "id": 12035,
            "name": "Agria"
        },
        {
            "id": 12036,
            "name": "Ambagadia"
        },
        {
            "id": 12037,
            "name": "Anla"
        },
        {
            "id": 12038,
            "name": "Baisinga"
        },
        {
            "id": 12039,
            "name": "Betnoti"
        },
        {
            "id": 12040,
            "name": "Chhanchipada"
        },
        {
            "id": 12041,
            "name": "Dahikoti"
        },
        {
            "id": 12042,
            "name": "Gaddeulia"
        },
        {
            "id": 12043,
            "name": "Hatijhuri"
        },
        {
            "id": 12044,
            "name": "Jugal"
        },
        {
            "id": 12045,
            "name": "Kalama"
        },
        {
            "id": 12046,
            "name": "Kendua"
        },
        {
            "id": 12047,
            "name": "Mahisasole"
        },
        {
            "id": 12048,
            "name": "Merda"
        },
        {
            "id": 12049,
            "name": "Muktapur"
        },
        {
            "id": 12050,
            "name": "Nadpur"
        },
        {
            "id": 12051,
            "name": "Patalipura"
        },
        {
            "id": 12052,
            "name": "Purinda"
        },
        {
            "id": 12053,
            "name": "Purunia"
        },
        {
            "id": 12054,
            "name": "Raghupur"
        },
        {
            "id": 12055,
            "name": "S.S.NAHANDASOLE"
        },
        {
            "id": 12056,
            "name": "Saitpur"
        },
        {
            "id": 12057,
            "name": "Santara"
        },
        {
            "id": 12058,
            "name": "Sathilo"
        }
    ],
    "9833": [
        {
            "id": 12059,
            "name": "Badabisol"
        },
        {
            "id": 12060,
            "name": "Badagudugudia"
        },
        {
            "id": 12061,
            "name": "Badakhaladi"
        },
        {
            "id": 12062,
            "name": "Chakradharpur"
        },
        {
            "id": 12063,
            "name": "Debala"
        },
        {
            "id": 12064,
            "name": "J.S JAMDIHA"
        },
        {
            "id": 12065,
            "name": "Jadida"
        },
        {
            "id": 12066,
            "name": "Jambani"
        },
        {
            "id": 12067,
            "name": "Jhinkpada"
        },
        {
            "id": 12068,
            "name": "Kaptipada"
        },
        {
            "id": 12069,
            "name": "Koliolam"
        },
        {
            "id": 12070,
            "name": "Majhigadia"
        },
        {
            "id": 12071,
            "name": "Mankadpada"
        },
        {
            "id": 12072,
            "name": "Nududiha"
        },
        {
            "id": 12073,
            "name": "Pedagadi"
        },
        {
            "id": 12074,
            "name": "Pingu"
        },
        {
            "id": 12075,
            "name": "R.C PUR"
        },
        {
            "id": 12076,
            "name": "Salchua"
        }
    ],
    "9834": [
        {
            "id": 12077,
            "name": "Badagaon"
        },
        {
            "id": 12078,
            "name": "Banakati"
        },
        {
            "id": 12079,
            "name": "Bangriposi"
        },
        {
            "id": 12080,
            "name": "Bhuasuni"
        },
        {
            "id": 12081,
            "name": "Brahmangaon"
        },
        {
            "id": 12082,
            "name": "Budhikhamari"
        },
        {
            "id": 12083,
            "name": "Chakidi"
        },
        {
            "id": 12084,
            "name": "Chandanpur"
        },
        {
            "id": 12085,
            "name": "Dhabanisole"
        },
        {
            "id": 12086,
            "name": "Dighi"
        },
        {
            "id": 12087,
            "name": "Golamundhakata"
        },
        {
            "id": 12088,
            "name": "Kalabadia"
        },
        {
            "id": 12089,
            "name": "Kumbharmundhakata"
        },
        {
            "id": 12090,
            "name": "Kusumbandh"
        },
        {
            "id": 12091,
            "name": "Nafri"
        },
        {
            "id": 12092,
            "name": "Nischinta"
        },
        {
            "id": 12093,
            "name": "Pathuri"
        },
        {
            "id": 12094,
            "name": "Shyamsundarpur"
        },
        {
            "id": 12095,
            "name": "Sorisopal"
        }
    ],
    "9835": [
        {
            "id": 12096,
            "name": "Baiganbadia"
        },
        {
            "id": 12097,
            "name": "Bhagabanpur"
        },
        {
            "id": 12098,
            "name": "Chandua"
        },
        {
            "id": 12099,
            "name": "Dumurdiha"
        },
        {
            "id": 12100,
            "name": "Gangraj"
        },
        {
            "id": 12101,
            "name": "Gendapokhari"
        },
        {
            "id": 12102,
            "name": "Goudruma"
        },
        {
            "id": 12103,
            "name": "Keutunimari"
        },
        {
            "id": 12104,
            "name": "Kuabuda"
        },
        {
            "id": 12105,
            "name": "Kuchei"
        },
        {
            "id": 12106,
            "name": "Kuliana"
        },
        {
            "id": 12107,
            "name": "Marangtandi"
        },
        {
            "id": 12108,
            "name": "Nodhana"
        },
        {
            "id": 12109,
            "name": "Parulia"
        },
        {
            "id": 12110,
            "name": "Patihinja"
        },
        {
            "id": 12111,
            "name": "Purunapani"
        },
        {
            "id": 12112,
            "name": "Sargachhida"
        }
    ],
    "9836": [
        {
            "id": 12113,
            "name": "Balidhia"
        },
        {
            "id": 12114,
            "name": "Baunsabilla"
        },
        {
            "id": 12115,
            "name": "Bhaluki"
        },
        {
            "id": 12116,
            "name": "Gundihudi"
        },
        {
            "id": 12117,
            "name": "Kalapathara"
        },
        {
            "id": 12118,
            "name": "Kendua"
        },
        {
            "id": 12119,
            "name": "Khandia"
        },
        {
            "id": 12120,
            "name": "Kuchilaghati"
        },
        {
            "id": 12121,
            "name": "Mahulia"
        },
        {
            "id": 12122,
            "name": "Paikabasa"
        },
        {
            "id": 12123,
            "name": "Rangamatia"
        },
        {
            "id": 12124,
            "name": "Shamakhunta"
        },
        {
            "id": 12125,
            "name": "Sinduragaura"
        },
        {
            "id": 12126,
            "name": "Sirishbani"
        }
    ],
    "9837": [
        {
            "id": 12127,
            "name": "Gudialbandh"
        },
        {
            "id": 12128,
            "name": "Jadunathpur"
        },
        {
            "id": 12129,
            "name": "Jarkani"
        },
        {
            "id": 12130,
            "name": "Khanua"
        },
        {
            "id": 12131,
            "name": "Naupal"
        },
        {
            "id": 12132,
            "name": "Purnachandrapur"
        },
        {
            "id": 12133,
            "name": "Sankerko"
        },
        {
            "id": 12134,
            "name": "Tangasole"
        },
        {
            "id": 12135,
            "name": "Uthaninuagan"
        }
    ],
    "9838": [
        {
            "id": 12136,
            "name": "B.C. PUR"
        },
        {
            "id": 12137,
            "name": "Badjode"
        },
        {
            "id": 12138,
            "name": "Bankisole"
        },
        {
            "id": 12139,
            "name": "Betna"
        },
        {
            "id": 12140,
            "name": "Budhikhamari"
        },
        {
            "id": 12141,
            "name": "Chandanpur"
        },
        {
            "id": 12142,
            "name": "Hatikote"
        },
        {
            "id": 12143,
            "name": "Khadisole"
        },
        {
            "id": 12144,
            "name": "Laxmiposi"
        },
        {
            "id": 12145,
            "name": "Rajabasa"
        },
        {
            "id": 12146,
            "name": "Sankhabhanga"
        }
    ],
    "9839": [
        {
            "id": 12147,
            "name": "NAC"
        }
    ],
    "9840": [
        {
            "id": 12148,
            "name": "B.C.PUR"
        },
        {
            "id": 12149,
            "name": "Badfeni"
        },
        {
            "id": 12150,
            "name": "Badpathara"
        },
        {
            "id": 12151,
            "name": "Bahanada"
        },
        {
            "id": 12152,
            "name": "Bangara"
        },
        {
            "id": 12153,
            "name": "Basipitha"
        },
        {
            "id": 12154,
            "name": "Bholagadia"
        },
        {
            "id": 12155,
            "name": "Dengam"
        },
        {
            "id": 12156,
            "name": "Dhanghera"
        },
        {
            "id": 12157,
            "name": "Dukura"
        },
        {
            "id": 12158,
            "name": "Gadigan"
        },
        {
            "id": 12159,
            "name": "Karkachia"
        },
        {
            "id": 12160,
            "name": "Laxmansahi"
        },
        {
            "id": 12161,
            "name": "Sapanchua"
        }
    ],
    "9841": [
        {
            "id": 12162,
            "name": "Astakunar"
        },
        {
            "id": 12163,
            "name": "Barehipani"
        },
        {
            "id": 12164,
            "name": "Baunsanali"
        },
        {
            "id": 12165,
            "name": "Begunia"
        },
        {
            "id": 12166,
            "name": "Chakidi"
        },
        {
            "id": 12167,
            "name": "Dholabani"
        },
        {
            "id": 12168,
            "name": "Durdura"
        },
        {
            "id": 12169,
            "name": "Ektali"
        },
        {
            "id": 12170,
            "name": "Gudgudia"
        },
        {
            "id": 12171,
            "name": "Jamdasahi"
        },
        {
            "id": 12172,
            "name": "Jamukeswar"
        },
        {
            "id": 12173,
            "name": "Jashipur"
        },
        {
            "id": 12174,
            "name": "Mahardapalsa"
        },
        {
            "id": 12175,
            "name": "Manada"
        },
        {
            "id": 12176,
            "name": "Matiagarh"
        },
        {
            "id": 12177,
            "name": "Moudi"
        },
        {
            "id": 12178,
            "name": "Pantho"
        },
        {
            "id": 12179,
            "name": "Podagarh"
        },
        {
            "id": 12180,
            "name": "Rugudi"
        },
        {
            "id": 12181,
            "name": "Tangabilla"
        }
    ],
    "9842": [
        {
            "id": 12182,
            "name": "Aharbandh"
        },
        {
            "id": 12183,
            "name": "Badahatnabeda"
        },
        {
            "id": 12184,
            "name": "Bhutkabadi"
        },
        {
            "id": 12185,
            "name": "Chuapani"
        },
        {
            "id": 12186,
            "name": "Dhangidimuta"
        },
        {
            "id": 12187,
            "name": "Hatbadra"
        },
        {
            "id": 12188,
            "name": "Jarada"
        },
        {
            "id": 12189,
            "name": "Jhipabandh"
        },
        {
            "id": 12190,
            "name": "Talapati"
        },
        {
            "id": 12191,
            "name": "Talokpokhari"
        },
        {
            "id": 12192,
            "name": "Uparbeda"
        }
    ],
    "9843": [
        {
            "id": 12193,
            "name": "Angarpada"
        },
        {
            "id": 12194,
            "name": "Baidyanath"
        },
        {
            "id": 12195,
            "name": "Bhanjakia"
        },
        {
            "id": 12196,
            "name": "Budamara"
        },
        {
            "id": 12197,
            "name": "Ghagarbeda"
        },
        {
            "id": 12198,
            "name": "Godapalasa"
        },
        {
            "id": 12199,
            "name": "Narasandha"
        },
        {
            "id": 12200,
            "name": "Niunty"
        },
        {
            "id": 12201,
            "name": "Nuagaon"
        },
        {
            "id": 12202,
            "name": "Panpatria"
        },
        {
            "id": 12203,
            "name": "Raruan"
        },
        {
            "id": 12204,
            "name": "Tilakuti"
        }
    ],
    "9844": [
        {
            "id": 12205,
            "name": "Arjunbilla"
        },
        {
            "id": 12206,
            "name": "Baria"
        },
        {
            "id": 12207,
            "name": "Chaturanjalli"
        },
        {
            "id": 12208,
            "name": "Galusahi"
        },
        {
            "id": 12209,
            "name": "Haladia"
        },
        {
            "id": 12210,
            "name": "Jamunti"
        },
        {
            "id": 12211,
            "name": "Jhadghosda"
        },
        {
            "id": 12212,
            "name": "Khiching"
        },
        {
            "id": 12213,
            "name": "Sukruli"
        },
        {
            "id": 12214,
            "name": "Teranti"
        }
    ],
    "9845": [
        {
            "id": 12215,
            "name": "Dewanbahali"
        },
        {
            "id": 12216,
            "name": "Kalamgadia"
        },
        {
            "id": 12217,
            "name": "L.D PUR"
        },
        {
            "id": 12218,
            "name": "Noto"
        },
        {
            "id": 12219,
            "name": "Padampokhari"
        },
        {
            "id": 12220,
            "name": "R.C PUR"
        },
        {
            "id": 12221,
            "name": "Ranipokhari"
        },
        {
            "id": 12222,
            "name": "Saradiha"
        },
        {
            "id": 12223,
            "name": "Sarat"
        }
    ],
    "9846": [
        {
            "id": 12224,
            "name": "Badadeuli"
        },
        {
            "id": 12225,
            "name": "Badagoan"
        },
        {
            "id": 12226,
            "name": "Bala"
        },
        {
            "id": 12227,
            "name": "Batpalasa"
        },
        {
            "id": 12228,
            "name": "Chitraposi"
        },
        {
            "id": 12229,
            "name": "Dori"
        },
        {
            "id": 12230,
            "name": "Dudhiani"
        },
        {
            "id": 12231,
            "name": "Ghosda"
        },
        {
            "id": 12232,
            "name": "Kerkera"
        },
        {
            "id": 12233,
            "name": "Kuliposi"
        },
        {
            "id": 12234,
            "name": "Miriginendi"
        },
        {
            "id": 12235,
            "name": "Patbil"
        },
        {
            "id": 12236,
            "name": "Rasamtala"
        },
        {
            "id": 12237,
            "name": "Sarangagada"
        },
        {
            "id": 12238,
            "name": "Tato"
        }
    ],
    "9847": [
        {
            "id": 12239,
            "name": "NAC"
        }
    ],
    "9848": [
        {
            "id": 12240,
            "name": "Bhaliadal"
        },
        {
            "id": 12241,
            "name": "Bharandia"
        },
        {
            "id": 12242,
            "name": "Champajhar"
        },
        {
            "id": 12243,
            "name": "Dhatikidiha"
        },
        {
            "id": 12244,
            "name": "Digdhar"
        },
        {
            "id": 12245,
            "name": "Hatigoda"
        },
        {
            "id": 12246,
            "name": "Jarak"
        },
        {
            "id": 12247,
            "name": "Kendujiani"
        },
        {
            "id": 12248,
            "name": "Keshdiha"
        },
        {
            "id": 12249,
            "name": "Khandabandh"
        },
        {
            "id": 12250,
            "name": "Mahuldiha"
        },
        {
            "id": 12251,
            "name": "Padiabeda"
        },
        {
            "id": 12252,
            "name": "Salachua"
        },
        {
            "id": 12253,
            "name": "Saleibeda"
        },
        {
            "id": 12254,
            "name": "Satkosia"
        },
        {
            "id": 12255,
            "name": "Talapada"
        },
        {
            "id": 12256,
            "name": "Taramara"
        },
        {
            "id": 12257,
            "name": "Thakurmunda"
        }
    ],
    "9849": [
        {
            "id": 12258,
            "name": "Barkand"
        },
        {
            "id": 12259,
            "name": "Bhaliadiha"
        },
        {
            "id": 12260,
            "name": "Chadheigan"
        },
        {
            "id": 12261,
            "name": "Chikitamatia"
        },
        {
            "id": 12262,
            "name": "Chitrada"
        },
        {
            "id": 12263,
            "name": "Dantiamuhan"
        },
        {
            "id": 12264,
            "name": "Dhadasahi"
        },
        {
            "id": 12265,
            "name": "Durgapur"
        },
        {
            "id": 12266,
            "name": "Gadia"
        },
        {
            "id": 12267,
            "name": "Gadigan"
        },
        {
            "id": 12268,
            "name": "Ghalmuhan"
        },
        {
            "id": 12269,
            "name": "Haldipal"
        },
        {
            "id": 12270,
            "name": "Jualibhanga"
        },
        {
            "id": 12271,
            "name": "Kohi"
        },
        {
            "id": 12272,
            "name": "Morada"
        },
        {
            "id": 12273,
            "name": "Nuagan"
        },
        {
            "id": 12274,
            "name": "Nuhajhalia"
        },
        {
            "id": 12275,
            "name": "Palasmunduli"
        },
        {
            "id": 12276,
            "name": "Sanmundhabani"
        },
        {
            "id": 12277,
            "name": "Sureidihi"
        }
    ],
    "9850": [
        {
            "id": 12278,
            "name": "Amarda"
        },
        {
            "id": 12279,
            "name": "Badampur"
        },
        {
            "id": 12280,
            "name": "Bhaduasole"
        },
        {
            "id": 12281,
            "name": "Chhatna"
        },
        {
            "id": 12282,
            "name": "Debsole"
        },
        {
            "id": 12283,
            "name": "Gadigaty"
        },
        {
            "id": 12284,
            "name": "Jhatiada"
        },
        {
            "id": 12285,
            "name": "Kamardiha"
        },
        {
            "id": 12286,
            "name": "Khuntapal"
        },
        {
            "id": 12287,
            "name": "Nakichua"
        },
        {
            "id": 12288,
            "name": "Nalagaja"
        },
        {
            "id": 12289,
            "name": "Raghabpur"
        },
        {
            "id": 12290,
            "name": "Rasgobindpur"
        },
        {
            "id": 12291,
            "name": "SAN MANIDA"
        },
        {
            "id": 12292,
            "name": "Sarumula"
        },
        {
            "id": 12293,
            "name": "Totapada"
        }
    ],
    "9851": [
        {
            "id": 12294,
            "name": "Anlakuda"
        },
        {
            "id": 12295,
            "name": "Badbhalia"
        },
        {
            "id": 12296,
            "name": "Baghada"
        },
        {
            "id": 12297,
            "name": "Chuhat"
        },
        {
            "id": 12298,
            "name": "Deuli"
        },
        {
            "id": 12299,
            "name": "Dhatika"
        },
        {
            "id": 12300,
            "name": "Fania"
        },
        {
            "id": 12301,
            "name": "Jhaliamara"
        },
        {
            "id": 12302,
            "name": "Kanimohuli"
        },
        {
            "id": 12303,
            "name": "Kantisahi"
        },
        {
            "id": 12304,
            "name": "Kostha"
        },
        {
            "id": 12305,
            "name": "Kujidihi"
        },
        {
            "id": 12306,
            "name": "Mohabilla"
        },
        {
            "id": 12307,
            "name": "Nuagaon"
        },
        {
            "id": 12308,
            "name": "Paktia"
        },
        {
            "id": 12309,
            "name": "Ufalgadia"
        }
    ],
    "9852": [
        {
            "id": 12310,
            "name": "Anlajodi"
        },
        {
            "id": 12311,
            "name": "Asana"
        },
        {
            "id": 12312,
            "name": "Badpalsa"
        },
        {
            "id": 12313,
            "name": "Bahalda"
        },
        {
            "id": 12314,
            "name": "Basingi"
        },
        {
            "id": 12315,
            "name": "Bhitaramada"
        },
        {
            "id": 12316,
            "name": "Gambharia"
        },
        {
            "id": 12317,
            "name": "Gidighaty"
        },
        {
            "id": 12318,
            "name": "Jashipur"
        },
        {
            "id": 12319,
            "name": "Jharadihi"
        },
        {
            "id": 12320,
            "name": "Kanki"
        },
        {
            "id": 12321,
            "name": "Soso"
        }
    ],
    "9853": [
        {
            "id": 12322,
            "name": "Bad-dundu"
        },
        {
            "id": 12323,
            "name": "Badkuleibira"
        },
        {
            "id": 12324,
            "name": "Hensda"
        },
        {
            "id": 12325,
            "name": "Jamda"
        },
        {
            "id": 12326,
            "name": "Jarkani"
        },
        {
            "id": 12327,
            "name": "Moranda"
        },
        {
            "id": 12328,
            "name": "Pasna"
        },
        {
            "id": 12329,
            "name": "Talagaon"
        },
        {
            "id": 12330,
            "name": "Tarana"
        },
        {
            "id": 12331,
            "name": "Tendra"
        }
    ],
    "9854": [
        {
            "id": 12332,
            "name": "Badmouda"
        },
        {
            "id": 12333,
            "name": "Bhalubasa"
        },
        {
            "id": 12334,
            "name": "Guhaldangri"
        },
        {
            "id": 12335,
            "name": "Halda"
        },
        {
            "id": 12336,
            "name": "Hatia"
        },
        {
            "id": 12337,
            "name": "Kuleisila"
        },
        {
            "id": 12338,
            "name": "Purunapani"
        },
        {
            "id": 12339,
            "name": "Sanpakhana"
        },
        {
            "id": 12340,
            "name": "Sudarsanpur"
        }
    ],
    "9855": [
        {
            "id": 12341,
            "name": "NAC"
        }
    ],
    "9856": [
        {
            "id": 12342,
            "name": "Baddalima"
        },
        {
            "id": 12343,
            "name": "Badnarini"
        },
        {
            "id": 12344,
            "name": "Gitilata"
        },
        {
            "id": 12345,
            "name": "Kulughutu"
        },
        {
            "id": 12346,
            "name": "Lupung"
        },
        {
            "id": 12347,
            "name": "Naranpur"
        },
        {
            "id": 12348,
            "name": "Pandupani"
        },
        {
            "id": 12349,
            "name": "Rengalbeda"
        },
        {
            "id": 12350,
            "name": "Sanbhundu"
        },
        {
            "id": 12351,
            "name": "Tiring"
        }
    ],
    "9857": [
        {
            "id": 12352,
            "name": "Badajharan"
        },
        {
            "id": 12353,
            "name": "Bankati"
        },
        {
            "id": 12354,
            "name": "Bijatala"
        },
        {
            "id": 12355,
            "name": "Chadheipahadi"
        },
        {
            "id": 12356,
            "name": "Khanta"
        },
        {
            "id": 12357,
            "name": "Luhasila"
        },
        {
            "id": 12358,
            "name": "Madheikacha"
        },
        {
            "id": 12359,
            "name": "Manikpur"
        },
        {
            "id": 12360,
            "name": "Mundathakura"
        },
        {
            "id": 12361,
            "name": "Nusadihi"
        },
        {
            "id": 12362,
            "name": "Raihari"
        },
        {
            "id": 12363,
            "name": "Saragoda"
        }
    ],
    "9858": [
        {
            "id": 12364,
            "name": "Asana"
        },
        {
            "id": 12365,
            "name": "Badmanada"
        },
        {
            "id": 12366,
            "name": "Baneikala"
        },
        {
            "id": 12367,
            "name": "Bautibeda"
        },
        {
            "id": 12368,
            "name": "Bhatchhatar"
        },
        {
            "id": 12369,
            "name": "Bisoi"
        },
        {
            "id": 12370,
            "name": "Jagannathpur"
        },
        {
            "id": 12371,
            "name": "Jodia"
        },
        {
            "id": 12372,
            "name": "Khadambeda"
        },
        {
            "id": 12373,
            "name": "Luhakani"
        },
        {
            "id": 12374,
            "name": "N.B.POKHARIA"
        },
        {
            "id": 12375,
            "name": "Nuagaon"
        },
        {
            "id": 12376,
            "name": "Sanpurunapani"
        }
    ],
    "9859": [
        {
            "id": 12377,
            "name": "Aharbandh"
        },
        {
            "id": 12378,
            "name": "Bhutkabadi"
        },
        {
            "id": 12379,
            "name": "Jaipur"
        },
        {
            "id": 12380,
            "name": "Kusumi"
        },
        {
            "id": 12381,
            "name": "Mayurdar"
        }
    ],
    "9860": [
        {
            "id": 12382,
            "name": "Bagbuda"
        },
        {
            "id": 12383,
            "name": "Bhursani"
        },
        {
            "id": 12384,
            "name": "Dhangirisole"
        },
        {
            "id": 12385,
            "name": "Jalda"
        },
        {
            "id": 12386,
            "name": "Jharpokhoria"
        },
        {
            "id": 12387,
            "name": "Joka"
        },
        {
            "id": 12388,
            "name": "Murunia"
        },
        {
            "id": 12389,
            "name": "Paktia"
        },
        {
            "id": 12390,
            "name": "Pandara"
        },
        {
            "id": 12391,
            "name": "Pokhardiha"
        },
        {
            "id": 12392,
            "name": "Pokharia"
        },
        {
            "id": 12393,
            "name": "Rajaloka"
        },
        {
            "id": 12394,
            "name": "Sankhabhanga"
        },
        {
            "id": 12395,
            "name": "Sarasakana"
        },
        {
            "id": 12396,
            "name": "Silphodi"
        },
        {
            "id": 12397,
            "name": "Sirsa"
        },
        {
            "id": 12398,
            "name": "Umadeipur"
        }
    ],
    "9861": [
        {
            "id": 12399,
            "name": "Arapata"
        },
        {
            "id": 12400,
            "name": "Baradihi"
        },
        {
            "id": 12401,
            "name": "Bhandagan"
        },
        {
            "id": 12402,
            "name": "Jaypur"
        },
        {
            "id": 12403,
            "name": "Kuamara"
        },
        {
            "id": 12404,
            "name": "Kushalda"
        },
        {
            "id": 12405,
            "name": "Pasuda"
        },
        {
            "id": 12406,
            "name": "PURUNA BARIPADA"
        },
        {
            "id": 12407,
            "name": "Ranibandha"
        },
        {
            "id": 12408,
            "name": "Sainkula"
        },
        {
            "id": 12409,
            "name": "Sankhunta"
        },
        {
            "id": 12410,
            "name": "Silaghaty"
        },
        {
            "id": 12411,
            "name": "Titia"
        }
    ],
    "9862": [
        {
            "id": 12412,
            "name": "Badabisol"
        },
        {
            "id": 12413,
            "name": "Badagudugudia"
        },
        {
            "id": 12414,
            "name": "Badakhaladi"
        },
        {
            "id": 12415,
            "name": "Chakradharpur"
        },
        {
            "id": 12416,
            "name": "Debala"
        },
        {
            "id": 12417,
            "name": "Jadida"
        },
        {
            "id": 12418,
            "name": "Jambani"
        },
        {
            "id": 12419,
            "name": "Jayantipatta Samil Jamudiha"
        },
        {
            "id": 12420,
            "name": "Jhinkpada"
        },
        {
            "id": 12421,
            "name": "Kaptipada"
        },
        {
            "id": 12422,
            "name": "Koliolam"
        },
        {
            "id": 12423,
            "name": "Majhigadia"
        },
        {
            "id": 12424,
            "name": "Mankadpada"
        },
        {
            "id": 12425,
            "name": "Nududiha"
        },
        {
            "id": 12426,
            "name": "Pedagadi"
        },
        {
            "id": 12427,
            "name": "Pingu"
        },
        {
            "id": 12428,
            "name": "Salchua"
        }
    ],
    "9863": [
        {
            "id": 12429,
            "name": "Badhisil"
        },
        {
            "id": 12430,
            "name": "Badkhalati"
        },
        {
            "id": 12431,
            "name": "Badkhaman"
        },
        {
            "id": 12432,
            "name": "Badsingaria"
        },
        {
            "id": 12433,
            "name": "Bahubandh"
        },
        {
            "id": 12434,
            "name": "Bhimtali"
        },
        {
            "id": 12435,
            "name": "Dugudha"
        },
        {
            "id": 12436,
            "name": "Jadida"
        },
        {
            "id": 12437,
            "name": "Khaladi"
        },
        {
            "id": 12438,
            "name": "Kochiladiha"
        },
        {
            "id": 12439,
            "name": "Kundabai"
        },
        {
            "id": 12440,
            "name": "Nuagan"
        },
        {
            "id": 12441,
            "name": "Patsanipur"
        },
        {
            "id": 12442,
            "name": "Radho"
        },
        {
            "id": 12443,
            "name": "Sridamchandrapur"
        }
    ],
    "9864": [
        {
            "id": 12444,
            "name": "NAC"
        }
    ],
    "9865": [
        {
            "id": 12445,
            "name": "Bailo"
        },
        {
            "id": 12446,
            "name": "Baunsagarh"
        },
        {
            "id": 12447,
            "name": "Belabahali"
        },
        {
            "id": 12448,
            "name": "Budhikud"
        },
        {
            "id": 12449,
            "name": "Gayalmunda"
        },
        {
            "id": 12450,
            "name": "Haridapal"
        },
        {
            "id": 12451,
            "name": "Jalasuan"
        },
        {
            "id": 12452,
            "name": "Kantipal"
        },
        {
            "id": 12453,
            "name": "Kathakata"
        },
        {
            "id": 12454,
            "name": "Kodapada"
        },
        {
            "id": 12455,
            "name": "Manoharpur"
        },
        {
            "id": 12456,
            "name": "Mochinda"
        },
        {
            "id": 12457,
            "name": "Panchupalli"
        },
        {
            "id": 12458,
            "name": "Pansadiha"
        },
        {
            "id": 12459,
            "name": "Salabani"
        },
        {
            "id": 12460,
            "name": "Tartara"
        }
    ],
    "9866": [
        {
            "id": 12461,
            "name": "NAC"
        }
    ],
    "9867": [
        {
            "id": 12462,
            "name": "Aitipur"
        },
        {
            "id": 12463,
            "name": "Akarua"
        },
        {
            "id": 12464,
            "name": "Ambo"
        },
        {
            "id": 12465,
            "name": "Anara"
        },
        {
            "id": 12466,
            "name": "Badarampas"
        },
        {
            "id": 12467,
            "name": "Balibarei"
        },
        {
            "id": 12468,
            "name": "Bancho"
        },
        {
            "id": 12469,
            "name": "Bangor"
        },
        {
            "id": 12470,
            "name": "Danar"
        },
        {
            "id": 12471,
            "name": "Dhanurjaypur"
        },
        {
            "id": 12472,
            "name": "Dhenka"
        },
        {
            "id": 12473,
            "name": "Gedma"
        },
        {
            "id": 12474,
            "name": "Habeleswar"
        },
        {
            "id": 12475,
            "name": "Hadagarh"
        },
        {
            "id": 12476,
            "name": "Hatadihi"
        },
        {
            "id": 12477,
            "name": "Inchol"
        },
        {
            "id": 12478,
            "name": "Jhambira"
        },
        {
            "id": 12479,
            "name": "Kaligaon"
        },
        {
            "id": 12480,
            "name": "Kanleibalipal"
        },
        {
            "id": 12481,
            "name": "Mareigaon"
        },
        {
            "id": 12482,
            "name": "Orali"
        },
        {
            "id": 12483,
            "name": "Padando"
        },
        {
            "id": 12484,
            "name": "Padhiharipalli"
        },
        {
            "id": 12485,
            "name": "Panchugochhia"
        },
        {
            "id": 12486,
            "name": "Sadanga"
        },
        {
            "id": 12487,
            "name": "Sadha"
        },
        {
            "id": 12488,
            "name": "Salania"
        },
        {
            "id": 12489,
            "name": "Samana"
        },
        {
            "id": 12490,
            "name": "Sasanga"
        },
        {
            "id": 12491,
            "name": "Soso"
        },
        {
            "id": 12492,
            "name": "Sulana"
        }
    ],
    "9868": [
        {
            "id": 12493,
            "name": "NAC"
        }
    ],
    "9869": [
        {
            "id": 12494,
            "name": "Badanai"
        },
        {
            "id": 12495,
            "name": "Bhanda"
        },
        {
            "id": 12496,
            "name": "Chandrasekharpur"
        },
        {
            "id": 12497,
            "name": "Jamudalak"
        },
        {
            "id": 12498,
            "name": "Kalikaprasad"
        },
        {
            "id": 12499,
            "name": "Karanjia"
        },
        {
            "id": 12500,
            "name": "Kasipal"
        },
        {
            "id": 12501,
            "name": "Kodagadia"
        },
        {
            "id": 12502,
            "name": "Kutariposi"
        },
        {
            "id": 12503,
            "name": "Padua"
        },
        {
            "id": 12504,
            "name": "Rajia"
        },
        {
            "id": 12505,
            "name": "Rangamatia"
        },
        {
            "id": 12506,
            "name": "Rimuli"
        },
        {
            "id": 12507,
            "name": "Sarei"
        },
        {
            "id": 12508,
            "name": "Sunaposi"
        },
        {
            "id": 12509,
            "name": "Ucchabali"
        }
    ],
    "9870": [
        {
            "id": 12510,
            "name": "NAC"
        }
    ],
    "9871": [
        {
            "id": 12511,
            "name": "Anseikala"
        },
        {
            "id": 12512,
            "name": "Badakalimati"
        },
        {
            "id": 12513,
            "name": "Balada"
        },
        {
            "id": 12514,
            "name": "Balagoda"
        },
        {
            "id": 12515,
            "name": "Bhadrasahi"
        },
        {
            "id": 12516,
            "name": "Bhuyanroida"
        },
        {
            "id": 12517,
            "name": "Birikala"
        },
        {
            "id": 12518,
            "name": "Bolani"
        },
        {
            "id": 12519,
            "name": "Chamakapur"
        },
        {
            "id": 12520,
            "name": "Deojhar"
        },
        {
            "id": 12521,
            "name": "Guali"
        },
        {
            "id": 12522,
            "name": "Jajanga"
        },
        {
            "id": 12523,
            "name": "Jalahari"
        },
        {
            "id": 12524,
            "name": "Jurudi"
        },
        {
            "id": 12525,
            "name": "Kandara"
        },
        {
            "id": 12526,
            "name": "Karakhendra"
        },
        {
            "id": 12527,
            "name": "Noidapada"
        },
        {
            "id": 12528,
            "name": "Palasa"
        },
        {
            "id": 12529,
            "name": "Serenda"
        }
    ],
    "9872": [
        {
            "id": 12530,
            "name": "NAC"
        }
    ],
    "9873": [
        {
            "id": 12531,
            "name": "Dhokatha"
        },
        {
            "id": 12532,
            "name": "Kolimati"
        }
    ],
    "9874": [
        {
            "id": 12533,
            "name": "Alati"
        },
        {
            "id": 12534,
            "name": "Balarampur"
        },
        {
            "id": 12535,
            "name": "Baripal"
        },
        {
            "id": 12536,
            "name": "Batto"
        },
        {
            "id": 12537,
            "name": "Bhandaridiha"
        },
        {
            "id": 12538,
            "name": "Biragovindapur"
        },
        {
            "id": 12539,
            "name": "Daradipal"
        },
        {
            "id": 12540,
            "name": "Deogaon"
        },
        {
            "id": 12541,
            "name": "Gadabandhagoda"
        },
        {
            "id": 12542,
            "name": "Ganpur"
        },
        {
            "id": 12543,
            "name": "Gohira"
        },
        {
            "id": 12544,
            "name": "Keshadurapal"
        },
        {
            "id": 12545,
            "name": "Khalana"
        },
        {
            "id": 12546,
            "name": "Khaliamenta"
        },
        {
            "id": 12547,
            "name": "Machhalo"
        },
        {
            "id": 12548,
            "name": "Madanpur"
        },
        {
            "id": 12549,
            "name": "Pandua"
        },
        {
            "id": 12550,
            "name": "Purunabandhagoda"
        },
        {
            "id": 12551,
            "name": "Radhikadeipur"
        },
        {
            "id": 12552,
            "name": "Ramachandrapur"
        },
        {
            "id": 12553,
            "name": "Sainkul"
        },
        {
            "id": 12554,
            "name": "Saladei"
        },
        {
            "id": 12555,
            "name": "Samukanendi"
        },
        {
            "id": 12556,
            "name": "Tarimul"
        },
        {
            "id": 12557,
            "name": "Taruan"
        }
    ],
    "9875": [
        {
            "id": 12558,
            "name": "Badajamuposi"
        },
        {
            "id": 12559,
            "name": "Badamasinabilla"
        },
        {
            "id": 12560,
            "name": "Badapichula"
        },
        {
            "id": 12561,
            "name": "Balabhadrapur"
        },
        {
            "id": 12562,
            "name": "Balipokhari"
        },
        {
            "id": 12563,
            "name": "Barahatipura"
        },
        {
            "id": 12564,
            "name": "Basantapur"
        },
        {
            "id": 12565,
            "name": "Bholbeda"
        },
        {
            "id": 12566,
            "name": "Binida"
        },
        {
            "id": 12567,
            "name": "Dhenkikote"
        },
        {
            "id": 12568,
            "name": "Gadadharpur"
        },
        {
            "id": 12569,
            "name": "Khantagahar"
        },
        {
            "id": 12570,
            "name": "Manoharpur"
        },
        {
            "id": 12571,
            "name": "Muktapur"
        },
        {
            "id": 12572,
            "name": "Mukundapurpatna"
        },
        {
            "id": 12573,
            "name": "Paidapatna"
        },
        {
            "id": 12574,
            "name": "Pandapada"
        },
        {
            "id": 12575,
            "name": "Patilo"
        },
        {
            "id": 12576,
            "name": "Pipilia"
        },
        {
            "id": 12577,
            "name": "Purumuda"
        },
        {
            "id": 12578,
            "name": "Rutisila"
        },
        {
            "id": 12579,
            "name": "Santrapur"
        },
        {
            "id": 12580,
            "name": "Sarasposi"
        },
        {
            "id": 12581,
            "name": "Sunatangiri"
        },
        {
            "id": 12582,
            "name": "Tara"
        },
        {
            "id": 12583,
            "name": "Toranipokhari"
        },
        {
            "id": 12584,
            "name": "Upperdiha"
        }
    ],
    "9876": [
        {
            "id": 12585,
            "name": "Kodipasa"
        },
        {
            "id": 12586,
            "name": "Kumundi"
        },
        {
            "id": 12587,
            "name": "Suakati"
        },
        {
            "id": 12588,
            "name": "Tala Champei"
        },
        {
            "id": 12589,
            "name": "Talakainsari"
        }
    ],
    "9877": [
        {
            "id": 12590,
            "name": "Arsala"
        },
        {
            "id": 12591,
            "name": "Badadumuria"
        },
        {
            "id": 12592,
            "name": "Balibandha"
        },
        {
            "id": 12593,
            "name": "Basantapur"
        },
        {
            "id": 12594,
            "name": "Gumura"
        },
        {
            "id": 12595,
            "name": "Jhumpura"
        },
        {
            "id": 12596,
            "name": "Khendra"
        },
        {
            "id": 12597,
            "name": "Kutugaon"
        },
        {
            "id": 12598,
            "name": "Malda"
        },
        {
            "id": 12599,
            "name": "Nahabeda"
        },
        {
            "id": 12600,
            "name": "Naradapur"
        },
        {
            "id": 12601,
            "name": "Nayagarh"
        },
        {
            "id": 12602,
            "name": "Nishchintapur"
        }
    ],
    "9878": [
        {
            "id": 12603,
            "name": "Baradapal"
        },
        {
            "id": 12604,
            "name": "Bauripada"
        },
        {
            "id": 12605,
            "name": "Bodapalasa"
        },
        {
            "id": 12606,
            "name": "Dimbo"
        },
        {
            "id": 12607,
            "name": "Gobardhan"
        },
        {
            "id": 12608,
            "name": "Gopinathpur"
        },
        {
            "id": 12609,
            "name": "Handibhanga"
        },
        {
            "id": 12610,
            "name": "Janardanpur"
        },
        {
            "id": 12611,
            "name": "Kandaraposi"
        },
        {
            "id": 12612,
            "name": "Kathabari"
        },
        {
            "id": 12613,
            "name": "Kaunrikala"
        },
        {
            "id": 12614,
            "name": "Mahadeijoda"
        },
        {
            "id": 12615,
            "name": "Maidankhel"
        },
        {
            "id": 12616,
            "name": "Mandua"
        },
        {
            "id": 12617,
            "name": "Naranpur"
        },
        {
            "id": 12618,
            "name": "Nelung"
        },
        {
            "id": 12619,
            "name": "Nuagaon"
        },
        {
            "id": 12620,
            "name": "Padmapur"
        },
        {
            "id": 12621,
            "name": "Palaspanga"
        },
        {
            "id": 12622,
            "name": "Parjanpur"
        },
        {
            "id": 12623,
            "name": "Raghunathpur"
        },
        {
            "id": 12624,
            "name": "Raikala"
        },
        {
            "id": 12625,
            "name": "Raisuan"
        },
        {
            "id": 12626,
            "name": "Sankiri"
        },
        {
            "id": 12627,
            "name": "Sirispal"
        }
    ],
    "9879": [
        {
            "id": 12628,
            "name": "NAC"
        }
    ],
    "9880": [
        {
            "id": 12629,
            "name": "Bhuinpur"
        },
        {
            "id": 12630,
            "name": "Jajaposi"
        },
        {
            "id": 12631,
            "name": "Jally"
        },
        {
            "id": 12632,
            "name": "Jyotipur"
        },
        {
            "id": 12633,
            "name": "Parsala"
        },
        {
            "id": 12634,
            "name": "Sadangi"
        }
    ],
    "9881": [
        {
            "id": 12635,
            "name": "Asanpat"
        },
        {
            "id": 12636,
            "name": "Badadumuria"
        },
        {
            "id": 12637,
            "name": "Badaneuli"
        },
        {
            "id": 12638,
            "name": "Baria"
        },
        {
            "id": 12639,
            "name": "Chauthia"
        },
        {
            "id": 12640,
            "name": "Dhanurjaypur"
        },
        {
            "id": 12641,
            "name": "Gundunia"
        },
        {
            "id": 12642,
            "name": "Khuntapada"
        },
        {
            "id": 12643,
            "name": "Tukudiha"
        },
        {
            "id": 12644,
            "name": "Ukhunda"
        }
    ],
    "9882": [
        {
            "id": 12645,
            "name": "Badapatna"
        },
        {
            "id": 12646,
            "name": "Baunsuli"
        },
        {
            "id": 12647,
            "name": "Budhikapudi"
        },
        {
            "id": 12648,
            "name": "Chankundapal"
        },
        {
            "id": 12649,
            "name": "Chemana"
        },
        {
            "id": 12650,
            "name": "Chinamaliposi"
        },
        {
            "id": 12651,
            "name": "Dumuria"
        },
        {
            "id": 12652,
            "name": "Erendei"
        },
        {
            "id": 12653,
            "name": "Jamunaposi"
        },
        {
            "id": 12654,
            "name": "Kantiapada"
        },
        {
            "id": 12655,
            "name": "Kendeiposi"
        },
        {
            "id": 12656,
            "name": "Khereitangiri"
        },
        {
            "id": 12657,
            "name": "Mirigikhoji"
        },
        {
            "id": 12658,
            "name": "Murusuan"
        },
        {
            "id": 12659,
            "name": "Musakhori"
        },
        {
            "id": 12660,
            "name": "Palanghati"
        },
        {
            "id": 12661,
            "name": "Rajnagar"
        },
        {
            "id": 12662,
            "name": "Saraskela"
        },
        {
            "id": 12663,
            "name": "Tangarpada"
        },
        {
            "id": 12664,
            "name": "Turumunga"
        }
    ],
    "9883": [
        {
            "id": 12665,
            "name": "Badabil"
        },
        {
            "id": 12666,
            "name": "Baikala"
        },
        {
            "id": 12667,
            "name": "Dalapaka"
        },
        {
            "id": 12668,
            "name": "Damahuda"
        },
        {
            "id": 12669,
            "name": "Digiposi"
        },
        {
            "id": 12670,
            "name": "Gandabeda"
        },
        {
            "id": 12671,
            "name": "Goras"
        },
        {
            "id": 12672,
            "name": "Kapundi"
        },
        {
            "id": 12673,
            "name": "Khadikapada"
        },
        {
            "id": 12674,
            "name": "Khajirapat"
        },
        {
            "id": 12675,
            "name": "Khuntapada"
        },
        {
            "id": 12676,
            "name": "Kundala"
        },
        {
            "id": 12677,
            "name": "Macchgarh"
        },
        {
            "id": 12678,
            "name": "Malharpada"
        },
        {
            "id": 12679,
            "name": "Raidiha"
        },
        {
            "id": 12680,
            "name": "Saharpada"
        },
        {
            "id": 12681,
            "name": "Sankhua"
        },
        {
            "id": 12682,
            "name": "Silipada"
        },
        {
            "id": 12683,
            "name": "Tendra"
        },
        {
            "id": 12684,
            "name": "Udaypur"
        }
    ],
    "9884": [
        {
            "id": 12685,
            "name": "Banspal"
        },
        {
            "id": 12686,
            "name": "Barahgarh"
        },
        {
            "id": 12687,
            "name": "Bayakumutia"
        },
        {
            "id": 12688,
            "name": "Fuljhar"
        },
        {
            "id": 12689,
            "name": "Gonasika"
        },
        {
            "id": 12690,
            "name": "Jatra"
        },
        {
            "id": 12691,
            "name": "Kalanda(Kha)"
        },
        {
            "id": 12692,
            "name": "Karangadihi"
        },
        {
            "id": 12693,
            "name": "Kunar"
        },
        {
            "id": 12694,
            "name": "Nayakote"
        },
        {
            "id": 12695,
            "name": "Saharpur"
        },
        {
            "id": 12696,
            "name": "Singpur"
        },
        {
            "id": 12697,
            "name": "Talakadakala"
        },
        {
            "id": 12698,
            "name": "Tana"
        },
        {
            "id": 12699,
            "name": "Taramakanta"
        },
        {
            "id": 12700,
            "name": "Upper Raigoda"
        }
    ],
    "9885": [
        {
            "id": 12701,
            "name": "Badapalasapal"
        },
        {
            "id": 12702,
            "name": "Bareigoda"
        },
        {
            "id": 12703,
            "name": "Bhagamunda"
        },
        {
            "id": 12704,
            "name": "Bhanarpur"
        },
        {
            "id": 12705,
            "name": "Budhakhaman"
        },
        {
            "id": 12706,
            "name": "Buxibarigaon"
        },
        {
            "id": 12707,
            "name": "Daitari"
        },
        {
            "id": 12708,
            "name": "Dhurudiamba"
        },
        {
            "id": 12709,
            "name": "Gounighasa"
        },
        {
            "id": 12710,
            "name": "Harichandanpur"
        },
        {
            "id": 12711,
            "name": "Hunda"
        },
        {
            "id": 12712,
            "name": "Jamujodi"
        },
        {
            "id": 12713,
            "name": "Jhangira"
        },
        {
            "id": 12714,
            "name": "Jirang"
        },
        {
            "id": 12715,
            "name": "Junga"
        },
        {
            "id": 12716,
            "name": "Manoharpur"
        },
        {
            "id": 12717,
            "name": "Pitapiti"
        },
        {
            "id": 12718,
            "name": "Pithagola"
        },
        {
            "id": 12719,
            "name": "Rasol"
        },
        {
            "id": 12720,
            "name": "Revenapalaspal"
        },
        {
            "id": 12721,
            "name": "Sagadapata"
        },
        {
            "id": 12722,
            "name": "Sunapetha"
        },
        {
            "id": 12723,
            "name": "Tangiriapal"
        },
        {
            "id": 12724,
            "name": "Tentalaposi"
        },
        {
            "id": 12725,
            "name": "Thakurpada"
        }
    ],
    "9886": [
        {
            "id": 12726,
            "name": "Akul"
        },
        {
            "id": 12727,
            "name": "Balabhadrapur"
        },
        {
            "id": 12728,
            "name": "Bhimkand"
        },
        {
            "id": 12729,
            "name": "Bimala"
        },
        {
            "id": 12730,
            "name": "Binjhabahal"
        },
        {
            "id": 12731,
            "name": "Chhamunda"
        },
        {
            "id": 12732,
            "name": "Deuladiha"
        },
        {
            "id": 12733,
            "name": "Dubulapal"
        },
        {
            "id": 12734,
            "name": "Jagamohanpur"
        },
        {
            "id": 12735,
            "name": "Kaliahata"
        },
        {
            "id": 12736,
            "name": "Karadangi"
        },
        {
            "id": 12737,
            "name": "Karamangi"
        },
        {
            "id": 12738,
            "name": "Khuntapada"
        },
        {
            "id": 12739,
            "name": "Oriabirida"
        },
        {
            "id": 12740,
            "name": "Podanga"
        },
        {
            "id": 12741,
            "name": "Purujoda"
        },
        {
            "id": 12742,
            "name": "Raisuan"
        },
        {
            "id": 12743,
            "name": "Saleikena"
        },
        {
            "id": 12744,
            "name": "Sibanarayanpurgoda"
        },
        {
            "id": 12745,
            "name": "Sirigida"
        },
        {
            "id": 12746,
            "name": "Talapada"
        },
        {
            "id": 12747,
            "name": "Telkoi"
        }
    ],
    "9887": [
        {
            "id": 12748,
            "name": "NAC"
        }
    ],
    "9888": [
        {
            "id": 12749,
            "name": "NAC"
        }
    ],
    "9889": [
        {
            "id": 12750,
            "name": "Chandnimal"
        },
        {
            "id": 12751,
            "name": "Goudmal"
        },
        {
            "id": 12752,
            "name": "Kudopali"
        },
        {
            "id": 12753,
            "name": "Loisingh"
        },
        {
            "id": 12754,
            "name": "Rajpur"
        }
    ],
    "9890": [
        {
            "id": 12755,
            "name": "Attabira"
        },
        {
            "id": 12756,
            "name": "Badimal"
        },
        {
            "id": 12757,
            "name": "Baghmunda"
        },
        {
            "id": 12758,
            "name": "Bandhbahal"
        },
        {
            "id": 12759,
            "name": "Banjari"
        },
        {
            "id": 12760,
            "name": "Bhikampali"
        },
        {
            "id": 12761,
            "name": "Bhournkhol"
        },
        {
            "id": 12762,
            "name": "Charpali"
        },
        {
            "id": 12763,
            "name": "Dalgaon"
        },
        {
            "id": 12764,
            "name": "Jamgaon"
        },
        {
            "id": 12765,
            "name": "Kadamdihi"
        },
        {
            "id": 12766,
            "name": "Kanaktora"
        },
        {
            "id": 12767,
            "name": "Kandheikela"
        },
        {
            "id": 12768,
            "name": "Katarbaga"
        },
        {
            "id": 12769,
            "name": "Kudaloi"
        },
        {
            "id": 12770,
            "name": "Kumbharbandh"
        },
        {
            "id": 12771,
            "name": "Kushraloi"
        },
        {
            "id": 12772,
            "name": "Lakhanpur"
        },
        {
            "id": 12773,
            "name": "Machida"
        },
        {
            "id": 12774,
            "name": "Palsada"
        },
        {
            "id": 12775,
            "name": "Panchgaon"
        },
        {
            "id": 12776,
            "name": "Pandri"
        },
        {
            "id": 12777,
            "name": "Piplikani"
        },
        {
            "id": 12778,
            "name": "Piplimal"
        },
        {
            "id": 12779,
            "name": "Pithinda"
        },
        {
            "id": 12780,
            "name": "Rampella"
        },
        {
            "id": 12781,
            "name": "Remenda"
        },
        {
            "id": 12782,
            "name": "Remta"
        },
        {
            "id": 12783,
            "name": "Samarbaga"
        },
        {
            "id": 12784,
            "name": "Sarandamal"
        },
        {
            "id": 12785,
            "name": "Sunari"
        },
        {
            "id": 12786,
            "name": "Telenpali"
        },
        {
            "id": 12787,
            "name": "Tilia"
        }
    ],
    "9891": [
        {
            "id": 12788,
            "name": "Badmal"
        },
        {
            "id": 12789,
            "name": "Dalki"
        },
        {
            "id": 12790,
            "name": "Durlaga"
        },
        {
            "id": 12791,
            "name": "H.Katapali"
        },
        {
            "id": 12792,
            "name": "Hirma"
        },
        {
            "id": 12793,
            "name": "Jamera"
        },
        {
            "id": 12794,
            "name": "Katikela"
        },
        {
            "id": 12795,
            "name": "Malda"
        },
        {
            "id": 12796,
            "name": "Marakuta"
        },
        {
            "id": 12797,
            "name": "Patrapali"
        },
        {
            "id": 12798,
            "name": "Sripura"
        },
        {
            "id": 12799,
            "name": "Talpatia"
        }
    ],
    "9892": [
        {
            "id": 12800,
            "name": "NAC"
        }
    ],
    "9893": [
        {
            "id": 12801,
            "name": "Arda"
        },
        {
            "id": 12802,
            "name": "Bandhapali"
        },
        {
            "id": 12803,
            "name": "Bhimjore"
        },
        {
            "id": 12804,
            "name": "Goudpanpali"
        },
        {
            "id": 12805,
            "name": "Jharmunda"
        },
        {
            "id": 12806,
            "name": "Kirmira"
        },
        {
            "id": 12807,
            "name": "Naxapali"
        },
        {
            "id": 12808,
            "name": "Sulehi"
        }
    ],
    "9894": [
        {
            "id": 12809,
            "name": "Jhirlapali"
        },
        {
            "id": 12810,
            "name": "Kelendamal"
        },
        {
            "id": 12811,
            "name": "Kolabira"
        },
        {
            "id": 12812,
            "name": "Kulihamal"
        },
        {
            "id": 12813,
            "name": "Parmanpur"
        },
        {
            "id": 12814,
            "name": "Pokhrasale"
        },
        {
            "id": 12815,
            "name": "Raghunathpali"
        },
        {
            "id": 12816,
            "name": "Samasingha"
        },
        {
            "id": 12817,
            "name": "Sodamal"
        }
    ],
    "9895": [
        {
            "id": 12818,
            "name": "Babuchhipidihi"
        },
        {
            "id": 12819,
            "name": "Bhatlaida"
        },
        {
            "id": 12820,
            "name": "Jammal"
        },
        {
            "id": 12821,
            "name": "Khuntamal"
        },
        {
            "id": 12822,
            "name": "Kulemura"
        },
        {
            "id": 12823,
            "name": "Laikera"
        },
        {
            "id": 12824,
            "name": "Niktimal"
        },
        {
            "id": 12825,
            "name": "Pakelpada"
        },
        {
            "id": 12826,
            "name": "Sahaspur"
        },
        {
            "id": 12827,
            "name": "Sarangloi"
        },
        {
            "id": 12828,
            "name": "Tileimal"
        }
    ],
    "9896": [
        {
            "id": 12829,
            "name": "Babebir"
        },
        {
            "id": 12830,
            "name": "Bhainsadani"
        },
        {
            "id": 12831,
            "name": "Boden"
        },
        {
            "id": 12832,
            "name": "Boirgaon"
        },
        {
            "id": 12833,
            "name": "Domjhar"
        },
        {
            "id": 12834,
            "name": "Farsara"
        },
        {
            "id": 12835,
            "name": "Karangamal"
        },
        {
            "id": 12836,
            "name": "Karlakot"
        },
        {
            "id": 12837,
            "name": "Khaira"
        },
        {
            "id": 12838,
            "name": "Larka"
        },
        {
            "id": 12839,
            "name": "Latisargi"
        },
        {
            "id": 12840,
            "name": "Nagpada"
        },
        {
            "id": 12841,
            "name": "Palsada"
        },
        {
            "id": 12842,
            "name": "Rokal"
        },
        {
            "id": 12843,
            "name": "Sunapur"
        }
    ],
    "9897": [
        {
            "id": 12844,
            "name": "Areda"
        },
        {
            "id": 12845,
            "name": "BAD DEHOL"
        },
        {
            "id": 12846,
            "name": "Badi"
        },
        {
            "id": 12847,
            "name": "Bargaon"
        },
        {
            "id": 12848,
            "name": "Bhojpur"
        },
        {
            "id": 12849,
            "name": "BHULIA SIKUAN"
        },
        {
            "id": 12850,
            "name": "Birighat"
        },
        {
            "id": 12851,
            "name": "Chanabeda"
        },
        {
            "id": 12852,
            "name": "Chindaguda"
        },
        {
            "id": 12853,
            "name": "Dabri"
        },
        {
            "id": 12854,
            "name": "Dohelpada"
        },
        {
            "id": 12855,
            "name": "Duajhar"
        },
        {
            "id": 12856,
            "name": "Gadramunda"
        },
        {
            "id": 12857,
            "name": "Kendupati"
        },
        {
            "id": 12858,
            "name": "Khasbahal"
        },
        {
            "id": 12859,
            "name": "Khudpej"
        },
        {
            "id": 12860,
            "name": "Kirkita"
        },
        {
            "id": 12861,
            "name": "Lanji"
        },
        {
            "id": 12862,
            "name": "Mandosil"
        },
        {
            "id": 12863,
            "name": "Nehena"
        },
        {
            "id": 12864,
            "name": "Ranimunda"
        },
        {
            "id": 12865,
            "name": "Risigaon"
        },
        {
            "id": 12866,
            "name": "Sanmaheswar"
        },
        {
            "id": 12867,
            "name": "Sardhapur"
        },
        {
            "id": 12868,
            "name": "SUNARI SIKUAN"
        },
        {
            "id": 12869,
            "name": "Tukla"
        }
    ],
    "9898": [
        {
            "id": 12870,
            "name": "NAC"
        }
    ],
    "9899": [
        {
            "id": 12871,
            "name": "Bargaon"
        },
        {
            "id": 12872,
            "name": "Bharuamunda"
        },
        {
            "id": 12873,
            "name": "Chatiguda"
        },
        {
            "id": 12874,
            "name": "Gandabahali"
        },
        {
            "id": 12875,
            "name": "Ghantiguda"
        },
        {
            "id": 12876,
            "name": "Ghatmal"
        },
        {
            "id": 12877,
            "name": "Godal"
        },
        {
            "id": 12878,
            "name": "Gorla"
        },
        {
            "id": 12879,
            "name": "Guchaguda"
        },
        {
            "id": 12880,
            "name": "Hatibandha"
        },
        {
            "id": 12881,
            "name": "Jharbandh"
        },
        {
            "id": 12882,
            "name": "Kaintpadar"
        },
        {
            "id": 12883,
            "name": "Karanbahal"
        },
        {
            "id": 12884,
            "name": "Kendumunda"
        },
        {
            "id": 12885,
            "name": "Khairpadar"
        },
        {
            "id": 12886,
            "name": "Kharsel"
        },
        {
            "id": 12887,
            "name": "Kusumjor"
        },
        {
            "id": 12888,
            "name": "Litiguda"
        },
        {
            "id": 12889,
            "name": "Makhapadar"
        },
        {
            "id": 12890,
            "name": "Nangalboard"
        },
        {
            "id": 12891,
            "name": "Niljee"
        },
        {
            "id": 12892,
            "name": "Nuamalpada"
        },
        {
            "id": 12893,
            "name": "Nuapada"
        },
        {
            "id": 12894,
            "name": "Ranimunda"
        },
        {
            "id": 12895,
            "name": "Sinapali"
        },
        {
            "id": 12896,
            "name": "Singjhar"
        },
        {
            "id": 12897,
            "name": "Timanpur"
        }
    ],
    "9900": [
        {
            "id": 12898,
            "name": "NAC"
        }
    ],
    "9901": [
        {
            "id": 12899,
            "name": "Aareri"
        },
        {
            "id": 12900,
            "name": "Agren"
        },
        {
            "id": 12901,
            "name": "Bhela"
        },
        {
            "id": 12902,
            "name": "Budhikomna"
        },
        {
            "id": 12903,
            "name": "Darlipada"
        },
        {
            "id": 12904,
            "name": "Deodhar"
        },
        {
            "id": 12905,
            "name": "Dhorlamunda"
        },
        {
            "id": 12906,
            "name": "Gandamer"
        },
        {
            "id": 12907,
            "name": "Jadamunda"
        },
        {
            "id": 12908,
            "name": "Jalgarh"
        },
        {
            "id": 12909,
            "name": "Jatgarh"
        },
        {
            "id": 12910,
            "name": "Jhagrahi"
        },
        {
            "id": 12911,
            "name": "Kandetara"
        },
        {
            "id": 12912,
            "name": "Komna"
        },
        {
            "id": 12913,
            "name": "Konabira"
        },
        {
            "id": 12914,
            "name": "Kureswar"
        },
        {
            "id": 12915,
            "name": "Kurumpuri"
        },
        {
            "id": 12916,
            "name": "Lakhana"
        },
        {
            "id": 12917,
            "name": "Maniguda"
        },
        {
            "id": 12918,
            "name": "Michhapali"
        },
        {
            "id": 12919,
            "name": "Mundapala"
        },
        {
            "id": 12920,
            "name": "Nuagaon"
        },
        {
            "id": 12921,
            "name": "Palasipani"
        },
        {
            "id": 12922,
            "name": "Palsipani"
        },
        {
            "id": 12923,
            "name": "Pendrawan"
        },
        {
            "id": 12924,
            "name": "Poinr"
        },
        {
            "id": 12925,
            "name": "Rajana"
        },
        {
            "id": 12926,
            "name": "Samarsing"
        },
        {
            "id": 12927,
            "name": "Sialati"
        },
        {
            "id": 12928,
            "name": "Silva"
        },
        {
            "id": 12929,
            "name": "Sosena"
        },
        {
            "id": 12930,
            "name": "Sunabeda"
        },
        {
            "id": 12931,
            "name": "Tarbod"
        },
        {
            "id": 12932,
            "name": "Thakpali"
        },
        {
            "id": 12933,
            "name": "Thikpali"
        },
        {
            "id": 12934,
            "name": "Tikrapada"
        }
    ],
    "9902": [
        {
            "id": 12935,
            "name": "Amanara"
        },
        {
            "id": 12936,
            "name": "Amasena"
        },
        {
            "id": 12937,
            "name": "Beltukuri"
        },
        {
            "id": 12938,
            "name": "Bhainsatal"
        },
        {
            "id": 12939,
            "name": "Bhaleswar"
        },
        {
            "id": 12940,
            "name": "Bhanpur"
        },
        {
            "id": 12941,
            "name": "Bharuamunda"
        },
        {
            "id": 12942,
            "name": "Biromal"
        },
        {
            "id": 12943,
            "name": "Bisora"
        },
        {
            "id": 12944,
            "name": "Boirbhadi"
        },
        {
            "id": 12945,
            "name": "Budhipali"
        },
        {
            "id": 12946,
            "name": "Chulabhat"
        },
        {
            "id": 12947,
            "name": "Darlimunda"
        },
        {
            "id": 12948,
            "name": "Dharambandha"
        },
        {
            "id": 12949,
            "name": "Dumerpani"
        },
        {
            "id": 12950,
            "name": "Godfula"
        },
        {
            "id": 12951,
            "name": "Jampani"
        },
        {
            "id": 12952,
            "name": "Kadomeri"
        },
        {
            "id": 12953,
            "name": "Kendubahara"
        },
        {
            "id": 12954,
            "name": "Kermeli"
        },
        {
            "id": 12955,
            "name": "Khairani"
        },
        {
            "id": 12956,
            "name": "Khutbanbhera"
        },
        {
            "id": 12957,
            "name": "Kodomeri"
        },
        {
            "id": 12958,
            "name": "Kotenchuan"
        },
        {
            "id": 12959,
            "name": "Kuliabandha"
        },
        {
            "id": 12960,
            "name": "Motanuapada"
        },
        {
            "id": 12961,
            "name": "Parkod"
        },
        {
            "id": 12962,
            "name": "Sahipala"
        },
        {
            "id": 12963,
            "name": "Saliha"
        },
        {
            "id": 12964,
            "name": "Sarabong"
        },
        {
            "id": 12965,
            "name": "Tanwat"
        }
    ],
    "9903": [
        {
            "id": 12966,
            "name": "NAC"
        }
    ],
    "9904": [
        {
            "id": 12967,
            "name": "Anantapur"
        },
        {
            "id": 12968,
            "name": "Badabhuin"
        },
        {
            "id": 12969,
            "name": "Bentapada"
        },
        {
            "id": 12970,
            "name": "Bhogara"
        },
        {
            "id": 12971,
            "name": "Chhagaon"
        },
        {
            "id": 12972,
            "name": "Dalabhaga"
        },
        {
            "id": 12973,
            "name": "Dhaipur"
        },
        {
            "id": 12974,
            "name": "Dhurusia"
        },
        {
            "id": 12975,
            "name": "Dorada"
        },
        {
            "id": 12976,
            "name": "Ghantikhal"
        },
        {
            "id": 12977,
            "name": "Gobara"
        },
        {
            "id": 12978,
            "name": "Gurudijhatia"
        },
        {
            "id": 12979,
            "name": "Ichhapur"
        },
        {
            "id": 12980,
            "name": "Jenapadadesh"
        },
        {
            "id": 12981,
            "name": "Joranda"
        },
        {
            "id": 12982,
            "name": "Kandarei"
        },
        {
            "id": 12983,
            "name": "Kandarpur"
        },
        {
            "id": 12984,
            "name": "Katikiasahi"
        },
        {
            "id": 12985,
            "name": "Khuntakata"
        },
        {
            "id": 12986,
            "name": "Khuntuni"
        },
        {
            "id": 12987,
            "name": "Kulailo"
        },
        {
            "id": 12988,
            "name": "Kumarpur"
        },
        {
            "id": 12989,
            "name": "Kuspangi"
        },
        {
            "id": 12990,
            "name": "Mahakalabasta"
        },
        {
            "id": 12991,
            "name": "Mancheswar"
        },
        {
            "id": 12992,
            "name": "Megha"
        },
        {
            "id": 12993,
            "name": "Oranda"
        },
        {
            "id": 12994,
            "name": "Radhagobindpur"
        },
        {
            "id": 12995,
            "name": "Radhakishorepur"
        },
        {
            "id": 12996,
            "name": "Radhakrushnapur"
        },
        {
            "id": 12997,
            "name": "Rajanagar"
        },
        {
            "id": 12998,
            "name": "Samsarpur"
        },
        {
            "id": 12999,
            "name": "Sathilo"
        },
        {
            "id": 13000,
            "name": "Tarding"
        }
    ],
    "9905": [
        {
            "id": 13001,
            "name": "NAC"
        }
    ],
    "9906": [
        {
            "id": 13002,
            "name": "Badasamantarapur"
        },
        {
            "id": 13003,
            "name": "Banipada"
        },
        {
            "id": 13004,
            "name": "Bhatimunda"
        },
        {
            "id": 13005,
            "name": "Brahmpur"
        },
        {
            "id": 13006,
            "name": "Garudagaon"
        },
        {
            "id": 13007,
            "name": "Gobindpur"
        },
        {
            "id": 13008,
            "name": "Harianta"
        },
        {
            "id": 13009,
            "name": "Jaripada"
        },
        {
            "id": 13010,
            "name": "Kakhadi"
        },
        {
            "id": 13011,
            "name": "Kanheipur"
        },
        {
            "id": 13012,
            "name": "Karanji"
        },
        {
            "id": 13013,
            "name": "Kotsahi"
        },
        {
            "id": 13014,
            "name": "Maguradhanmandal"
        },
        {
            "id": 13015,
            "name": "Mahinsalanda"
        },
        {
            "id": 13016,
            "name": "Mangarajpur"
        },
        {
            "id": 13017,
            "name": "Napang"
        },
        {
            "id": 13018,
            "name": "Safa"
        },
        {
            "id": 13019,
            "name": "Salagaon"
        },
        {
            "id": 13020,
            "name": "Sankarpuer"
        },
        {
            "id": 13021,
            "name": "Uchapada"
        }
    ],
    "9907": [
        {
            "id": 13022,
            "name": "Achalkot"
        },
        {
            "id": 13023,
            "name": "Badanauput"
        },
        {
            "id": 13024,
            "name": "Baliput"
        },
        {
            "id": 13025,
            "name": "Bhiruda"
        },
        {
            "id": 13026,
            "name": "Bhogoda"
        },
        {
            "id": 13027,
            "name": "Bindhanima"
        },
        {
            "id": 13028,
            "name": "Gadadharpur"
        },
        {
            "id": 13029,
            "name": "Hatamal"
        },
        {
            "id": 13030,
            "name": "Jemadeipur"
        },
        {
            "id": 13031,
            "name": "Nizigarh"
        },
        {
            "id": 13032,
            "name": "Nuapatna"
        },
        {
            "id": 13033,
            "name": "Panchagaon"
        },
        {
            "id": 13034,
            "name": "Puruna Tigiria"
        },
        {
            "id": 13035,
            "name": "Sompada"
        }
    ],
    "9908": [
        {
            "id": 13036,
            "name": "Abhimanpur"
        },
        {
            "id": 13037,
            "name": "Badabarsingh"
        },
        {
            "id": 13038,
            "name": "Badakambilo"
        },
        {
            "id": 13039,
            "name": "Banamalipur"
        },
        {
            "id": 13040,
            "name": "Bangerisingha"
        },
        {
            "id": 13041,
            "name": "Barambagarh"
        },
        {
            "id": 13042,
            "name": "Beliapal"
        },
        {
            "id": 13043,
            "name": "Bhatarika"
        },
        {
            "id": 13044,
            "name": "Dasarathipur"
        },
        {
            "id": 13045,
            "name": "Diniary"
        },
        {
            "id": 13046,
            "name": "Gadapokhari"
        },
        {
            "id": 13047,
            "name": "Gopalpur"
        },
        {
            "id": 13048,
            "name": "Gopamathura"
        },
        {
            "id": 13049,
            "name": "Gopapur"
        },
        {
            "id": 13050,
            "name": "Gopinathpur"
        },
        {
            "id": 13051,
            "name": "Janisahi"
        },
        {
            "id": 13052,
            "name": "Jhajia"
        },
        {
            "id": 13053,
            "name": "Jodumu"
        },
        {
            "id": 13054,
            "name": "Kankadajodi"
        },
        {
            "id": 13055,
            "name": "Karadibandha"
        },
        {
            "id": 13056,
            "name": "Kasikiary"
        },
        {
            "id": 13057,
            "name": "Khuntakata"
        },
        {
            "id": 13058,
            "name": "Koleswar"
        },
        {
            "id": 13059,
            "name": "KRUSHNA CHANDRAPUR"
        },
        {
            "id": 13060,
            "name": "Kuanarpal"
        },
        {
            "id": 13061,
            "name": "Mahulia"
        },
        {
            "id": 13062,
            "name": "Malati"
        },
        {
            "id": 13063,
            "name": "Manapur"
        },
        {
            "id": 13064,
            "name": "Mangarajpur"
        },
        {
            "id": 13065,
            "name": "Maniabandha"
        },
        {
            "id": 13066,
            "name": "Mugagahir"
        },
        {
            "id": 13067,
            "name": "Parajapada"
        },
        {
            "id": 13068,
            "name": "Ragadipada"
        },
        {
            "id": 13069,
            "name": "Ratapat"
        },
        {
            "id": 13070,
            "name": "Sanbarsingh"
        },
        {
            "id": 13071,
            "name": "Sankhameri"
        },
        {
            "id": 13072,
            "name": "Santrabali"
        },
        {
            "id": 13073,
            "name": "Talamundali"
        }
    ],
    "9909": [
        {
            "id": 13074,
            "name": "Adheigundi"
        },
        {
            "id": 13075,
            "name": "Alara"
        },
        {
            "id": 13076,
            "name": "Badabhuin"
        },
        {
            "id": 13077,
            "name": "Balijhari"
        },
        {
            "id": 13078,
            "name": "Balisahi"
        },
        {
            "id": 13079,
            "name": "Bandhahuda"
        },
        {
            "id": 13080,
            "name": "Basantapur"
        },
        {
            "id": 13081,
            "name": "Baselihata"
        },
        {
            "id": 13082,
            "name": "Bokoda"
        },
        {
            "id": 13083,
            "name": "Chakamunda"
        },
        {
            "id": 13084,
            "name": "Champeswar"
        },
        {
            "id": 13085,
            "name": "Debabhuin"
        },
        {
            "id": 13086,
            "name": "Ekdal"
        },
        {
            "id": 13087,
            "name": "Fulpada"
        },
        {
            "id": 13088,
            "name": "Godibandha"
        },
        {
            "id": 13089,
            "name": "Jaymangal"
        },
        {
            "id": 13090,
            "name": "Judum"
        },
        {
            "id": 13091,
            "name": "Kakudia"
        },
        {
            "id": 13092,
            "name": "Kamaladiha"
        },
        {
            "id": 13093,
            "name": "Kanjiapada"
        },
        {
            "id": 13094,
            "name": "Kanpur"
        },
        {
            "id": 13095,
            "name": "Kathakhunta"
        },
        {
            "id": 13096,
            "name": "Kokalaba"
        },
        {
            "id": 13097,
            "name": "Nimasahi"
        },
        {
            "id": 13098,
            "name": "Nizigarh"
        },
        {
            "id": 13099,
            "name": "Nuapatna"
        },
        {
            "id": 13100,
            "name": "Nukhapada"
        },
        {
            "id": 13101,
            "name": "Olaba"
        },
        {
            "id": 13102,
            "name": "Padamal"
        },
        {
            "id": 13103,
            "name": "PAIKA BARABATI"
        },
        {
            "id": 13104,
            "name": "Paikapadapatana"
        },
        {
            "id": 13105,
            "name": "Ransingpur"
        },
        {
            "id": 13106,
            "name": "Regeda"
        },
        {
            "id": 13107,
            "name": "Rusipada"
        },
        {
            "id": 13108,
            "name": "Sagar"
        },
        {
            "id": 13109,
            "name": "Saradhapur"
        },
        {
            "id": 13110,
            "name": "Siaria"
        }
    ],
    "9910": [
        {
            "id": 13111,
            "name": "Anuary"
        },
        {
            "id": 13112,
            "name": "Baideswar"
        },
        {
            "id": 13113,
            "name": "BALABHADRA PUR"
        },
        {
            "id": 13114,
            "name": "Bandalo"
        },
        {
            "id": 13115,
            "name": "Baraput"
        },
        {
            "id": 13116,
            "name": "Baunsaput"
        },
        {
            "id": 13117,
            "name": "Brahmapura"
        },
        {
            "id": 13118,
            "name": "Golaganda"
        },
        {
            "id": 13119,
            "name": "Jagannathpur"
        },
        {
            "id": 13120,
            "name": "Kadalibadi"
        },
        {
            "id": 13121,
            "name": "Kalapathar"
        },
        {
            "id": 13122,
            "name": "Kalipoi"
        },
        {
            "id": 13123,
            "name": "KANTA PAHANRA"
        },
        {
            "id": 13124,
            "name": "Kendupalli"
        },
        {
            "id": 13125,
            "name": "Kiapalla"
        },
        {
            "id": 13126,
            "name": "Nuagaon"
        },
        {
            "id": 13127,
            "name": "Ostia"
        },
        {
            "id": 13128,
            "name": "Puincha"
        },
        {
            "id": 13129,
            "name": "RATAGADA GOBINDAPUR"
        },
        {
            "id": 13130,
            "name": "Subarnapur"
        }
    ],
    "9911": [
        {
            "id": 13131,
            "name": "NAC"
        }
    ],
    "9912": [
        {
            "id": 13132,
            "name": "Banara"
        },
        {
            "id": 13133,
            "name": "Bhagipur"
        },
        {
            "id": 13134,
            "name": "Bilipada"
        },
        {
            "id": 13135,
            "name": "Dampada"
        },
        {
            "id": 13136,
            "name": "Dhansar"
        },
        {
            "id": 13137,
            "name": "Dulanapur"
        },
        {
            "id": 13138,
            "name": "Durgapur"
        },
        {
            "id": 13139,
            "name": "Gopalapur"
        },
        {
            "id": 13140,
            "name": "Govindapur"
        },
        {
            "id": 13141,
            "name": "Harirajpur"
        },
        {
            "id": 13142,
            "name": "Kusupangi"
        },
        {
            "id": 13143,
            "name": "Naraganga"
        },
        {
            "id": 13144,
            "name": "Pathapur"
        },
        {
            "id": 13145,
            "name": "Ragadi"
        },
        {
            "id": 13146,
            "name": "Similipur"
        },
        {
            "id": 13147,
            "name": "Talabasta"
        },
        {
            "id": 13148,
            "name": "Tulasipur"
        }
    ],
    "9913": [
        {
            "id": 13149,
            "name": "Belagachhia"
        },
        {
            "id": 13150,
            "name": "Dadhapatna"
        },
        {
            "id": 13151,
            "name": "Kunheipada"
        },
        {
            "id": 13152,
            "name": "Madhupur"
        },
        {
            "id": 13153,
            "name": "Mundali"
        },
        {
            "id": 13154,
            "name": "N. MARTHAPUR"
        },
        {
            "id": 13155,
            "name": "Ramdaspur"
        },
        {
            "id": 13156,
            "name": "Sribantapur"
        }
    ],
    "9914": [
        {
            "id": 13157,
            "name": "NAC"
        }
    ],
    "9915": [
        {
            "id": 13158,
            "name": "C.T"
        }
    ],
    "9916": [
        {
            "id": 13159,
            "name": "O.G"
        }
    ],
    "9917": [
        {
            "id": 13160,
            "name": "NAC"
        }
    ],
    "9918": [
        {
            "id": 13161,
            "name": "NAC"
        }
    ],
    "9919": [
        {
            "id": 13162,
            "name": "Agrahat"
        },
        {
            "id": 13163,
            "name": "Indranipatna"
        },
        {
            "id": 13164,
            "name": "Kayalapada"
        },
        {
            "id": 13165,
            "name": "Nakhara"
        },
        {
            "id": 14272,
            "name": "Banipada"
        }
    ],
    "9920": [
        {
            "id": 13166,
            "name": "NAC"
        }
    ],
    "9921": [
        {
            "id": 13167,
            "name": "Amana"
        },
        {
            "id": 13168,
            "name": "Arilo"
        },
        {
            "id": 13169,
            "name": "Ayatpur"
        },
        {
            "id": 13170,
            "name": "Barda"
        },
        {
            "id": 13171,
            "name": "Bentkar"
        },
        {
            "id": 13172,
            "name": "Berhampur"
        },
        {
            "id": 13173,
            "name": "Bodhapur"
        },
        {
            "id": 13174,
            "name": "Dadhibamanpur"
        },
        {
            "id": 13175,
            "name": "Dharina"
        },
        {
            "id": 13176,
            "name": "Fakirpada"
        },
        {
            "id": 13177,
            "name": "Gatiroutpatna"
        },
        {
            "id": 13178,
            "name": "Jasapada"
        },
        {
            "id": 13179,
            "name": "Jharakata"
        },
        {
            "id": 13180,
            "name": "Kalapada"
        },
        {
            "id": 13181,
            "name": "Kandarpur"
        },
        {
            "id": 13182,
            "name": "Khandaeta"
        },
        {
            "id": 13183,
            "name": "Kishorenagar"
        },
        {
            "id": 13184,
            "name": "Kulasarichuan"
        },
        {
            "id": 13185,
            "name": "Nimeisapur"
        },
        {
            "id": 13186,
            "name": "Paramahansha"
        },
        {
            "id": 13187,
            "name": "Praharjpur"
        },
        {
            "id": 13188,
            "name": "Somepur"
        },
        {
            "id": 13189,
            "name": "Urali"
        }
    ],
    "9922": [
        {
            "id": 13190,
            "name": "Babujanga"
        },
        {
            "id": 13191,
            "name": "Bandhupur"
        },
        {
            "id": 13192,
            "name": "Isaniberhampur"
        },
        {
            "id": 13193,
            "name": "Janardanpur"
        },
        {
            "id": 13194,
            "name": "Kalamisri"
        },
        {
            "id": 13195,
            "name": "Mahammadpur"
        },
        {
            "id": 13196,
            "name": "Manapur"
        },
        {
            "id": 13197,
            "name": "Manijanga"
        },
        {
            "id": 13198,
            "name": "Nemalo"
        },
        {
            "id": 13199,
            "name": "Palada"
        },
        {
            "id": 13200,
            "name": "Santapur"
        },
        {
            "id": 13201,
            "name": "Sirlo"
        },
        {
            "id": 13202,
            "name": "Tilakana"
        },
        {
            "id": 13203,
            "name": "Uttarkul"
        }
    ],
    "9923": [
        {
            "id": 13204,
            "name": "Anandapur"
        },
        {
            "id": 13205,
            "name": "Bajapura"
        },
        {
            "id": 13206,
            "name": "Barahipur"
        },
        {
            "id": 13207,
            "name": "Basudevpur"
        },
        {
            "id": 13208,
            "name": "Bhadreswar"
        },
        {
            "id": 13209,
            "name": "Bhaunria"
        },
        {
            "id": 13210,
            "name": "Bheda"
        },
        {
            "id": 13211,
            "name": "Chahapada"
        },
        {
            "id": 13212,
            "name": "Gokana"
        },
        {
            "id": 13213,
            "name": "Gopalpur"
        },
        {
            "id": 13214,
            "name": "Gotara"
        },
        {
            "id": 13215,
            "name": "Haladia"
        },
        {
            "id": 13216,
            "name": "Jaleswarpur"
        },
        {
            "id": 13217,
            "name": "Kaitha"
        },
        {
            "id": 13218,
            "name": "Koliatha"
        },
        {
            "id": 13219,
            "name": "Kuhunda"
        },
        {
            "id": 13220,
            "name": "Kundi"
        },
        {
            "id": 13221,
            "name": "Kurujanga"
        },
        {
            "id": 13222,
            "name": "Kusupur"
        },
        {
            "id": 13223,
            "name": "Lalitagiri"
        },
        {
            "id": 13224,
            "name": "Madhupur"
        },
        {
            "id": 13225,
            "name": "Mahanga"
        },
        {
            "id": 13226,
            "name": "Mouda"
        },
        {
            "id": 13227,
            "name": "Mulabasanta"
        },
        {
            "id": 13228,
            "name": "Nahanga"
        },
        {
            "id": 13229,
            "name": "Nurtanga"
        },
        {
            "id": 13230,
            "name": "Osanga"
        },
        {
            "id": 13231,
            "name": "Paikarapur"
        },
        {
            "id": 13232,
            "name": "Pallisahi"
        },
        {
            "id": 13233,
            "name": "Panaspur"
        },
        {
            "id": 13234,
            "name": "Podamarai"
        },
        {
            "id": 13235,
            "name": "Rahania"
        },
        {
            "id": 13236,
            "name": "Sahapur"
        },
        {
            "id": 13237,
            "name": "Samsarpur"
        },
        {
            "id": 13238,
            "name": "Srikrushnapur"
        },
        {
            "id": 13239,
            "name": "Umar"
        },
        {
            "id": 13240,
            "name": "Usuma"
        }
    ],
    "9924": [
        {
            "id": 13241,
            "name": "Asureswar"
        },
        {
            "id": 13242,
            "name": "Badakhir"
        },
        {
            "id": 13243,
            "name": "Baliapada"
        },
        {
            "id": 13244,
            "name": "Bandhakatia"
        },
        {
            "id": 13245,
            "name": "Barado"
        },
        {
            "id": 13246,
            "name": "Buhalo"
        },
        {
            "id": 13247,
            "name": "Daudpur"
        },
        {
            "id": 13248,
            "name": "Fogal"
        },
        {
            "id": 13249,
            "name": "Jairampur"
        },
        {
            "id": 13250,
            "name": "Jamara"
        },
        {
            "id": 13251,
            "name": "Jhadeswarpur"
        },
        {
            "id": 13252,
            "name": "Jignipur"
        },
        {
            "id": 13253,
            "name": "Katarpada"
        },
        {
            "id": 13254,
            "name": "Katikata"
        },
        {
            "id": 13255,
            "name": "Kendupatna"
        },
        {
            "id": 13256,
            "name": "Kentala"
        },
        {
            "id": 13257,
            "name": "Kerilo"
        },
        {
            "id": 13258,
            "name": "Kolanpur"
        },
        {
            "id": 13259,
            "name": "Nagespur"
        },
        {
            "id": 13260,
            "name": "Narendrapur"
        },
        {
            "id": 13261,
            "name": "Natakai"
        },
        {
            "id": 13262,
            "name": "Nischintkoili"
        },
        {
            "id": 13263,
            "name": "Orti"
        },
        {
            "id": 13264,
            "name": "RAMAKRUSHNA PUR"
        },
        {
            "id": 13265,
            "name": "Sarapada"
        },
        {
            "id": 13266,
            "name": "Sukarpada"
        },
        {
            "id": 13267,
            "name": "Taratsasan"
        }
    ],
    "9925": [
        {
            "id": 13268,
            "name": "Harianta"
        },
        {
            "id": 13269,
            "name": "Khalarda"
        },
        {
            "id": 13270,
            "name": "Korkara"
        },
        {
            "id": 13271,
            "name": "KURANGA SASAN"
        },
        {
            "id": 13272,
            "name": "KURANG PADHAN"
        },
        {
            "id": 13273,
            "name": "Nagari"
        },
        {
            "id": 13274,
            "name": "Sainso"
        },
        {
            "id": 13275,
            "name": "Usuma"
        }
    ],
    "9926": [
        {
            "id": 13276,
            "name": "Adaspur"
        },
        {
            "id": 13277,
            "name": "Badapatasundarpur"
        },
        {
            "id": 13278,
            "name": "Bagalpur"
        },
        {
            "id": 13279,
            "name": "Brahmanbati"
        },
        {
            "id": 13280,
            "name": "Brahmansailo"
        },
        {
            "id": 13281,
            "name": "Dimiri"
        },
        {
            "id": 13282,
            "name": "Govindpur"
        },
        {
            "id": 13283,
            "name": "Jharpada"
        },
        {
            "id": 13284,
            "name": "Kantapada"
        },
        {
            "id": 13285,
            "name": "Nahalpur"
        },
        {
            "id": 13286,
            "name": "Nuagaongram"
        },
        {
            "id": 13287,
            "name": "Postal"
        },
        {
            "id": 13288,
            "name": "Uradha"
        },
        {
            "id": 13289,
            "name": "Uttaran"
        }
    ],
    "9927": [
        {
            "id": 13290,
            "name": "Alana"
        },
        {
            "id": 13291,
            "name": "Anlo"
        },
        {
            "id": 13292,
            "name": "Baharana"
        },
        {
            "id": 13293,
            "name": "Bilasuni"
        },
        {
            "id": 13294,
            "name": "Binishpur"
        },
        {
            "id": 13295,
            "name": "Ekaberuan"
        },
        {
            "id": 13296,
            "name": "Erancha"
        },
        {
            "id": 13297,
            "name": "Jallarpur"
        },
        {
            "id": 13298,
            "name": "K.Prasad"
        },
        {
            "id": 13299,
            "name": "Kapasi"
        },
        {
            "id": 13300,
            "name": "Kasarda"
        },
        {
            "id": 13301,
            "name": "Madhab"
        },
        {
            "id": 13302,
            "name": "Niali"
        },
        {
            "id": 13303,
            "name": "Nivarana"
        },
        {
            "id": 13304,
            "name": "Nuagaon"
        },
        {
            "id": 13305,
            "name": "Pahanga"
        },
        {
            "id": 13306,
            "name": "Pokharigaon"
        },
        {
            "id": 13307,
            "name": "Raniola"
        },
        {
            "id": 13308,
            "name": "Ratanpur"
        },
        {
            "id": 13309,
            "name": "Sadansa"
        },
        {
            "id": 13310,
            "name": "Sagadailo"
        },
        {
            "id": 13311,
            "name": "Sithalo"
        },
        {
            "id": 13312,
            "name": "Tihudi"
        }
    ],
    "9928": [
        {
            "id": 13313,
            "name": "Bahugram"
        },
        {
            "id": 13314,
            "name": "Balisahi"
        },
        {
            "id": 13315,
            "name": "Barigol"
        },
        {
            "id": 13316,
            "name": "Bhimdaspur"
        },
        {
            "id": 13317,
            "name": "Champati"
        },
        {
            "id": 13318,
            "name": "Chandradeipur"
        },
        {
            "id": 13319,
            "name": "Chhanipur"
        },
        {
            "id": 13320,
            "name": "Gopinathpur"
        },
        {
            "id": 13321,
            "name": "Gunjarpur"
        },
        {
            "id": 13322,
            "name": "Katarapa"
        },
        {
            "id": 13323,
            "name": "Madhyakachha"
        },
        {
            "id": 13324,
            "name": "Mahajanpur"
        },
        {
            "id": 13325,
            "name": "Mahan"
        },
        {
            "id": 13326,
            "name": "Malasasan"
        },
        {
            "id": 13327,
            "name": "Mirzapur"
        },
        {
            "id": 13328,
            "name": "Mutarifa"
        },
        {
            "id": 13329,
            "name": "Naiguan"
        },
        {
            "id": 13330,
            "name": "Nandol"
        },
        {
            "id": 13331,
            "name": "Narada"
        },
        {
            "id": 13332,
            "name": "Odasingh"
        },
        {
            "id": 13333,
            "name": "Paschimakachha"
        },
        {
            "id": 13334,
            "name": "Pikol"
        },
        {
            "id": 13335,
            "name": "Purunahat"
        },
        {
            "id": 13336,
            "name": "Raisunguda"
        },
        {
            "id": 13337,
            "name": "Ramakrishnapur"
        },
        {
            "id": 13338,
            "name": "Rameswar"
        },
        {
            "id": 13339,
            "name": "Ratilo"
        },
        {
            "id": 13340,
            "name": "Satyabhamapur"
        },
        {
            "id": 13341,
            "name": "Sauri"
        },
        {
            "id": 13342,
            "name": "Sidho"
        },
        {
            "id": 13343,
            "name": "Sisua"
        },
        {
            "id": 13344,
            "name": "Sudukhanda"
        },
        {
            "id": 13345,
            "name": "Tarito"
        },
        {
            "id": 13346,
            "name": "Tentol"
        },
        {
            "id": 13347,
            "name": "Trilochanpur"
        }
    ],
    "9929": [
        {
            "id": 13348,
            "name": "Bhatimunda"
        },
        {
            "id": 13349,
            "name": "Garudagaon"
        },
        {
            "id": 13350,
            "name": "Gobindpur"
        },
        {
            "id": 13351,
            "name": "Harianta"
        },
        {
            "id": 13352,
            "name": "Jaripada"
        },
        {
            "id": 13353,
            "name": "Kanheipur"
        },
        {
            "id": 13354,
            "name": "Karanji"
        },
        {
            "id": 13355,
            "name": "Kotasahi"
        },
        {
            "id": 13356,
            "name": "Magura-Dhanmandal"
        },
        {
            "id": 13357,
            "name": "Napanga"
        },
        {
            "id": 13358,
            "name": "Safa"
        },
        {
            "id": 13359,
            "name": "Salagaon"
        },
        {
            "id": 13360,
            "name": "Uchapada"
        }
    ],
    "9930": [
        {
            "id": 13361,
            "name": "Anchala"
        },
        {
            "id": 13362,
            "name": "Bada Olma"
        },
        {
            "id": 13363,
            "name": "Borigam"
        },
        {
            "id": 13364,
            "name": "Chacharaguda"
        },
        {
            "id": 13365,
            "name": "Dabugam"
        },
        {
            "id": 13366,
            "name": "Dongriguda"
        },
        {
            "id": 13367,
            "name": "Ghodakhunta"
        },
        {
            "id": 13368,
            "name": "Jabaguda"
        },
        {
            "id": 13369,
            "name": "Junapani"
        },
        {
            "id": 13370,
            "name": "Koilary"
        },
        {
            "id": 13371,
            "name": "Medana"
        },
        {
            "id": 13372,
            "name": "Nandapura"
        }
    ],
    "9931": [
        {
            "id": 13373,
            "name": "Attigam"
        },
        {
            "id": 13374,
            "name": "Badagumuda"
        },
        {
            "id": 13375,
            "name": "Balenga"
        },
        {
            "id": 13376,
            "name": "Bankuli"
        },
        {
            "id": 13377,
            "name": "Buti Majhi Semla"
        },
        {
            "id": 13378,
            "name": "Ekori"
        },
        {
            "id": 13379,
            "name": "Karchamal"
        },
        {
            "id": 13380,
            "name": "Kodinga"
        },
        {
            "id": 13381,
            "name": "Kosagumuda"
        },
        {
            "id": 13382,
            "name": "Majhidhanua"
        },
        {
            "id": 13383,
            "name": "Motigam"
        },
        {
            "id": 13384,
            "name": "Motigam Keragam"
        },
        {
            "id": 13385,
            "name": "Pharsaguda"
        },
        {
            "id": 13386,
            "name": "Santoshpur"
        },
        {
            "id": 13387,
            "name": "Taragam"
        },
        {
            "id": 13388,
            "name": "Temera"
        }
    ],
    "9932": [
        {
            "id": 13389,
            "name": "Chandrapur"
        },
        {
            "id": 13390,
            "name": "Cherchata"
        },
        {
            "id": 13391,
            "name": "Dengaguda"
        },
        {
            "id": 13392,
            "name": "Dongra"
        },
        {
            "id": 13393,
            "name": "Gadabakotara"
        },
        {
            "id": 13394,
            "name": "Ghataguda"
        },
        {
            "id": 13395,
            "name": "Hatibeda"
        },
        {
            "id": 13396,
            "name": "Jatabal"
        },
        {
            "id": 13397,
            "name": "Kantamal"
        },
        {
            "id": 13398,
            "name": "Khutubai"
        },
        {
            "id": 13399,
            "name": "Maidalapur"
        },
        {
            "id": 13400,
            "name": "Majhiguda"
        },
        {
            "id": 13401,
            "name": "Manigam"
        },
        {
            "id": 13402,
            "name": "Mokiya"
        },
        {
            "id": 13403,
            "name": "Naktiguda"
        },
        {
            "id": 13404,
            "name": "Nuakote"
        },
        {
            "id": 13405,
            "name": "Pandikote"
        },
        {
            "id": 13406,
            "name": "Papadahandi"
        },
        {
            "id": 13407,
            "name": "Patri"
        },
        {
            "id": 13408,
            "name": "Sanabarli"
        },
        {
            "id": 13409,
            "name": "Semla"
        },
        {
            "id": 13410,
            "name": "Sirisi"
        },
        {
            "id": 13411,
            "name": "Tumberlla"
        }
    ],
    "9933": [
        {
            "id": 13412,
            "name": "Anakabeda"
        },
        {
            "id": 13413,
            "name": "Anchala"
        },
        {
            "id": 13414,
            "name": "Badabharandi"
        },
        {
            "id": 13415,
            "name": "Badakumari"
        },
        {
            "id": 13416,
            "name": "Bakoda"
        },
        {
            "id": 13417,
            "name": "Beheda"
        },
        {
            "id": 13418,
            "name": "Beheramunda"
        },
        {
            "id": 13419,
            "name": "Benora"
        },
        {
            "id": 13420,
            "name": "Bhamini"
        },
        {
            "id": 13421,
            "name": "Bhandariguda"
        },
        {
            "id": 13422,
            "name": "Burja"
        },
        {
            "id": 13423,
            "name": "Chandahandi"
        },
        {
            "id": 13424,
            "name": "Chikalapadar"
        },
        {
            "id": 13425,
            "name": "Dalabedha"
        },
        {
            "id": 13426,
            "name": "Dhodipani"
        },
        {
            "id": 13427,
            "name": "Gombhariguda"
        },
        {
            "id": 13428,
            "name": "Hirapur"
        },
        {
            "id": 13429,
            "name": "Jamadarpada"
        },
        {
            "id": 13430,
            "name": "Jamuranda"
        },
        {
            "id": 13431,
            "name": "Karagam"
        },
        {
            "id": 13432,
            "name": "Khanda"
        },
        {
            "id": 13433,
            "name": "Koilimunda"
        },
        {
            "id": 13434,
            "name": "Kopena"
        },
        {
            "id": 13435,
            "name": "Kuhudi"
        },
        {
            "id": 13436,
            "name": "Kursi"
        },
        {
            "id": 13437,
            "name": "Malgam"
        },
        {
            "id": 13438,
            "name": "Mohora"
        },
        {
            "id": 13439,
            "name": "Mundiguda"
        },
        {
            "id": 13440,
            "name": "Murtuma"
        },
        {
            "id": 13441,
            "name": "Nehura"
        },
        {
            "id": 13442,
            "name": "Patakhalia"
        },
        {
            "id": 13443,
            "name": "Phatiki"
        },
        {
            "id": 13444,
            "name": "Rajpur"
        },
        {
            "id": 13445,
            "name": "Sardhapur"
        },
        {
            "id": 13446,
            "name": "Semela"
        },
        {
            "id": 13447,
            "name": "Singisari"
        },
        {
            "id": 13448,
            "name": "Sukhigam"
        },
        {
            "id": 13449,
            "name": "Sunabeda"
        },
        {
            "id": 13450,
            "name": "Tohara"
        },
        {
            "id": 13451,
            "name": "Torenga"
        }
    ],
    "9934": [
        {
            "id": 13452,
            "name": "Badatemera"
        },
        {
            "id": 13453,
            "name": "Bakadabeda"
        },
        {
            "id": 13454,
            "name": "Banuaguda"
        },
        {
            "id": 13455,
            "name": "Belagam"
        },
        {
            "id": 13456,
            "name": "bhalukanadi"
        },
        {
            "id": 13457,
            "name": "Bhikya"
        },
        {
            "id": 13458,
            "name": "Chacha"
        },
        {
            "id": 13459,
            "name": "chakalapadar"
        },
        {
            "id": 13460,
            "name": "Chatiguda"
        },
        {
            "id": 13461,
            "name": "Chitabeda"
        },
        {
            "id": 13462,
            "name": "Dhamanaguda"
        },
        {
            "id": 13463,
            "name": "Dhodra"
        },
        {
            "id": 13464,
            "name": "Dongariguda"
        },
        {
            "id": 13465,
            "name": "ekamba"
        },
        {
            "id": 13466,
            "name": "Gurusingha"
        },
        {
            "id": 13467,
            "name": "ichhapur"
        },
        {
            "id": 13468,
            "name": "Jharigam"
        },
        {
            "id": 13469,
            "name": "Kanakote"
        },
        {
            "id": 13470,
            "name": "Kutrichhapar"
        },
        {
            "id": 13471,
            "name": "palia"
        },
        {
            "id": 13472,
            "name": "Phupugam"
        },
        {
            "id": 13473,
            "name": "Ratakhandiguda"
        },
        {
            "id": 13474,
            "name": "Telnadigam"
        }
    ],
    "9935": [
        {
            "id": 13475,
            "name": "Badabharandi"
        },
        {
            "id": 13476,
            "name": "Badakumari"
        },
        {
            "id": 13477,
            "name": "Benora"
        },
        {
            "id": 13478,
            "name": "Bhamini"
        },
        {
            "id": 13479,
            "name": "Bhandariguda"
        },
        {
            "id": 13480,
            "name": "Chikalapadar"
        },
        {
            "id": 13481,
            "name": "Dhodra"
        },
        {
            "id": 13482,
            "name": "Hirapur"
        },
        {
            "id": 13483,
            "name": "Karagam"
        },
        {
            "id": 13484,
            "name": "Khanda"
        },
        {
            "id": 13485,
            "name": "Mundiguda"
        },
        {
            "id": 13486,
            "name": "Nehura"
        },
        {
            "id": 13487,
            "name": "Rajpur"
        },
        {
            "id": 13488,
            "name": "Singisari"
        },
        {
            "id": 13489,
            "name": "Toranga"
        }
    ],
    "9936": [
        {
            "id": 13490,
            "name": "Bada Ambada"
        },
        {
            "id": 13491,
            "name": "Bamuni"
        },
        {
            "id": 13492,
            "name": "Binjili"
        },
        {
            "id": 13493,
            "name": "Chirma"
        },
        {
            "id": 13494,
            "name": "Karchamal"
        },
        {
            "id": 13495,
            "name": "Kukudi Semla"
        },
        {
            "id": 13496,
            "name": "Modeigam"
        },
        {
            "id": 13497,
            "name": "Ponduguda"
        },
        {
            "id": 13498,
            "name": "Rajoda"
        },
        {
            "id": 13499,
            "name": "Sana Ambada"
        },
        {
            "id": 13500,
            "name": "Ukiapali"
        }
    ],
    "9937": [
        {
            "id": 13501,
            "name": "Agnipur"
        },
        {
            "id": 13502,
            "name": "badakumuli"
        },
        {
            "id": 13503,
            "name": "Badamasigam"
        },
        {
            "id": 13504,
            "name": "Baghasiuni"
        },
        {
            "id": 13505,
            "name": "Basini"
        },
        {
            "id": 13506,
            "name": "Bhatrasiuni"
        },
        {
            "id": 13507,
            "name": "Bikrampur"
        },
        {
            "id": 13508,
            "name": "Chatahandi"
        },
        {
            "id": 13509,
            "name": "Mantriguda"
        },
        {
            "id": 13510,
            "name": "pujariguda"
        },
        {
            "id": 13511,
            "name": "Sanamasigam"
        },
        {
            "id": 13512,
            "name": "Sindhigam"
        },
        {
            "id": 13513,
            "name": "Taragam"
        }
    ],
    "9938": [
        {
            "id": 13514,
            "name": "NAC"
        }
    ],
    "9939": [
        {
            "id": 13515,
            "name": "B.Maliguda"
        },
        {
            "id": 13516,
            "name": "Dahana"
        },
        {
            "id": 13517,
            "name": "Dangarbheja"
        },
        {
            "id": 13518,
            "name": "Dhandra"
        },
        {
            "id": 13519,
            "name": "Jagannathpur"
        },
        {
            "id": 13520,
            "name": "Jhadabandhguda"
        },
        {
            "id": 13521,
            "name": "Mentry"
        },
        {
            "id": 13522,
            "name": "Nandahandi"
        },
        {
            "id": 13523,
            "name": "Nishnahandi"
        },
        {
            "id": 13524,
            "name": "Podalguda"
        },
        {
            "id": 13525,
            "name": "Sindhiguda"
        }
    ],
    "9940": [
        {
            "id": 13526,
            "name": "Amalabhatta"
        },
        {
            "id": 13527,
            "name": "Anchalgumma"
        },
        {
            "id": 13528,
            "name": "Digi"
        },
        {
            "id": 13529,
            "name": "GOUD DEOPALLI"
        },
        {
            "id": 13530,
            "name": "Jharigumma"
        },
        {
            "id": 13531,
            "name": "Kamata"
        },
        {
            "id": 13532,
            "name": "Kantagam"
        },
        {
            "id": 13533,
            "name": "Kongra"
        },
        {
            "id": 13534,
            "name": "Kukudabai"
        },
        {
            "id": 13535,
            "name": "Lamtaguda"
        },
        {
            "id": 13536,
            "name": "Monchagam"
        },
        {
            "id": 13537,
            "name": "P.B.Padar"
        },
        {
            "id": 13538,
            "name": "Patraput"
        },
        {
            "id": 13539,
            "name": "Pujariguda"
        },
        {
            "id": 13540,
            "name": "Tentulikhunti"
        }
    ],
    "9941": [
        {
            "id": 13541,
            "name": "Bharsundi"
        },
        {
            "id": 13542,
            "name": "Bobei"
        },
        {
            "id": 13543,
            "name": "Chelidongri"
        },
        {
            "id": 13544,
            "name": "Chhatabeda"
        },
        {
            "id": 13545,
            "name": "Debagam"
        },
        {
            "id": 13546,
            "name": "Deobharandi "
        },
        {
            "id": 13547,
            "name": "Ganjapara"
        },
        {
            "id": 13548,
            "name": "Gona"
        },
        {
            "id": 13549,
            "name": "Haladi"
        },
        {
            "id": 13550,
            "name": "Hatabharandi"
        },
        {
            "id": 13551,
            "name": "Hatigam"
        },
        {
            "id": 13552,
            "name": "Jalangpara"
        },
        {
            "id": 13553,
            "name": "Jodinga"
        },
        {
            "id": 13554,
            "name": "Kacharapara"
        },
        {
            "id": 13555,
            "name": "Kacharapara-iii"
        },
        {
            "id": 13556,
            "name": "Kaudola"
        },
        {
            "id": 13557,
            "name": "Khadanga"
        },
        {
            "id": 13558,
            "name": "Khuduk"
        },
        {
            "id": 13559,
            "name": "Koskonga"
        },
        {
            "id": 13560,
            "name": "Kumuli"
        },
        {
            "id": 13561,
            "name": "Kundei"
        },
        {
            "id": 13562,
            "name": "Kurabeda"
        },
        {
            "id": 13563,
            "name": "Mohand"
        },
        {
            "id": 13564,
            "name": "Mundibeda"
        },
        {
            "id": 13565,
            "name": "Naktisemuda"
        },
        {
            "id": 13566,
            "name": "Pandaripakhana"
        },
        {
            "id": 13567,
            "name": "Parchipara"
        },
        {
            "id": 13568,
            "name": "Parua"
        },
        {
            "id": 13569,
            "name": "Raighar"
        },
        {
            "id": 13570,
            "name": "Sarguli"
        },
        {
            "id": 13571,
            "name": "Sonpur"
        },
        {
            "id": 13572,
            "name": "Timanpur"
        },
        {
            "id": 13573,
            "name": "Turudihi"
        }
    ],
    "9942": [
        {
            "id": 13574,
            "name": "Adhikariguda"
        },
        {
            "id": 13575,
            "name": "Anchala"
        },
        {
            "id": 13576,
            "name": "Badabasini"
        },
        {
            "id": 13577,
            "name": "Bakoda"
        },
        {
            "id": 13578,
            "name": "Beheda"
        },
        {
            "id": 13579,
            "name": "Burja"
        },
        {
            "id": 13580,
            "name": "Ekamba"
        },
        {
            "id": 13581,
            "name": "Kopena"
        },
        {
            "id": 13582,
            "name": "Kursi"
        },
        {
            "id": 13583,
            "name": "Murtuma"
        },
        {
            "id": 13584,
            "name": "Semela"
        },
        {
            "id": 13585,
            "name": "Sirliguda"
        },
        {
            "id": 13586,
            "name": "Sukigam"
        },
        {
            "id": 13587,
            "name": "Sunabeda"
        },
        {
            "id": 13588,
            "name": "Tohara"
        },
        {
            "id": 13589,
            "name": "Umerkote"
        }
    ],
    "9943": [
        {
            "id": 13590,
            "name": "NAC"
        }
    ],
    "9944": [
        {
            "id": 13591,
            "name": "NAC"
        }
    ],
    "9945": [
        {
            "id": 13592,
            "name": "NAC"
        }
    ],
    "9946": [
        {
            "id": 13593,
            "name": "Andrahal"
        },
        {
            "id": 13594,
            "name": "Badadural"
        },
        {
            "id": 13595,
            "name": "Govindapalli"
        },
        {
            "id": 13596,
            "name": "Kadamguda"
        },
        {
            "id": 13597,
            "name": "Khairput"
        },
        {
            "id": 13598,
            "name": "Kudumuluguma"
        },
        {
            "id": 13599,
            "name": "Madkapadar"
        },
        {
            "id": 13600,
            "name": "Mudulipoda"
        },
        {
            "id": 13601,
            "name": "Parkanmala"
        },
        {
            "id": 13602,
            "name": "Podaghat"
        },
        {
            "id": 13603,
            "name": "Rasabeda"
        }
    ],
    "9947": [
        {
            "id": 13604,
            "name": "Chitapari-iii"
        },
        {
            "id": 13605,
            "name": "Chitrakonda"
        },
        {
            "id": 13606,
            "name": "Doraguda"
        },
        {
            "id": 13607,
            "name": "Dudameta"
        },
        {
            "id": 13608,
            "name": "Gunthawada"
        },
        {
            "id": 13609,
            "name": "Kamwada"
        },
        {
            "id": 13610,
            "name": "Kapatuti"
        },
        {
            "id": 13611,
            "name": "Mandapali"
        },
        {
            "id": 13612,
            "name": "Mariwada"
        },
        {
            "id": 13613,
            "name": "Nilakamberu"
        },
        {
            "id": 13614,
            "name": "Nuagauda"
        },
        {
            "id": 13615,
            "name": "Old Chimitapali"
        },
        {
            "id": 13616,
            "name": "Potrel"
        },
        {
            "id": 13617,
            "name": "Tarlakota"
        },
        {
            "id": 13618,
            "name": "Tunnel-camp"
        }
    ],
    "9948": [
        {
            "id": 13619,
            "name": "Andrapalli"
        },
        {
            "id": 13620,
            "name": "Badapada"
        },
        {
            "id": 13621,
            "name": "Badapadar"
        },
        {
            "id": 13622,
            "name": "Jodamba"
        },
        {
            "id": 13623,
            "name": "Kudumulgumma"
        },
        {
            "id": 13624,
            "name": "Nakamamudi"
        },
        {
            "id": 13625,
            "name": "Panasput"
        },
        {
            "id": 13626,
            "name": "Papermetla"
        },
        {
            "id": 13627,
            "name": "Parkhanmalla"
        },
        {
            "id": 13628,
            "name": "Relegada"
        },
        {
            "id": 13629,
            "name": "Somnathpur"
        }
    ],
    "9949": [
        {
            "id": 13630,
            "name": "Ambaguda"
        },
        {
            "id": 13631,
            "name": "B.Pangam"
        },
        {
            "id": 13632,
            "name": "Bhejaguda"
        },
        {
            "id": 13633,
            "name": "Chedenga"
        },
        {
            "id": 13634,
            "name": "Choulamendi"
        },
        {
            "id": 13635,
            "name": "Dalapatiguda"
        },
        {
            "id": 13636,
            "name": "Dhungiaput"
        },
        {
            "id": 13637,
            "name": "Doraguda"
        },
        {
            "id": 13638,
            "name": "Kamarpalli"
        },
        {
            "id": 13639,
            "name": "Kartanpalli"
        },
        {
            "id": 13640,
            "name": "Katapalli"
        },
        {
            "id": 13641,
            "name": "Kiango"
        },
        {
            "id": 13642,
            "name": "Kutunipalli"
        },
        {
            "id": 13643,
            "name": "Mahupodar"
        },
        {
            "id": 13644,
            "name": "Mathili"
        },
        {
            "id": 13645,
            "name": "Mecca"
        },
        {
            "id": 13646,
            "name": "Nayakguda"
        },
        {
            "id": 13647,
            "name": "Pangam"
        },
        {
            "id": 13648,
            "name": "Salimi"
        },
        {
            "id": 13649,
            "name": "Temurpalli"
        },
        {
            "id": 13650,
            "name": "Udulibeda"
        }
    ],
    "9950": [
        {
            "id": 13651,
            "name": "Badili"
        },
        {
            "id": 13652,
            "name": "Bhejangiwada"
        },
        {
            "id": 13653,
            "name": "Bhubanpalli"
        },
        {
            "id": 13654,
            "name": "Bodigeta"
        },
        {
            "id": 13655,
            "name": "Chintalwada"
        },
        {
            "id": 13656,
            "name": "Chitrangpalli"
        },
        {
            "id": 13657,
            "name": "Gompakonda"
        },
        {
            "id": 13658,
            "name": "Gumuka"
        },
        {
            "id": 13659,
            "name": "Kalimela"
        },
        {
            "id": 13660,
            "name": "Kangurukonda"
        },
        {
            "id": 13661,
            "name": "Koimetla"
        },
        {
            "id": 13662,
            "name": "Lugel"
        },
        {
            "id": 13663,
            "name": "Maharajpalli"
        },
        {
            "id": 13664,
            "name": "Malavaram"
        },
        {
            "id": 13665,
            "name": "Mandpalli"
        },
        {
            "id": 13666,
            "name": "Manyamkonda"
        },
        {
            "id": 13667,
            "name": "Motu"
        },
        {
            "id": 13668,
            "name": "Nalagunthi"
        },
        {
            "id": 13669,
            "name": "Pusuguda"
        },
        {
            "id": 13670,
            "name": "Tandabai"
        },
        {
            "id": 13671,
            "name": "Telerai"
        },
        {
            "id": 13672,
            "name": "Tigal"
        },
        {
            "id": 13673,
            "name": "Venkatpallam"
        }
    ],
    "9951": [
        {
            "id": 13674,
            "name": "Chitapari-iii"
        },
        {
            "id": 13675,
            "name": "Dudameta"
        },
        {
            "id": 13676,
            "name": "Kamwada"
        },
        {
            "id": 13677,
            "name": "Korukonda"
        },
        {
            "id": 13678,
            "name": "Mariwada"
        },
        {
            "id": 13679,
            "name": "Nakamamudi"
        },
        {
            "id": 13680,
            "name": "Nilakamberu"
        },
        {
            "id": 13681,
            "name": "Old Chimtapalli"
        },
        {
            "id": 13682,
            "name": "Potrel"
        },
        {
            "id": 13683,
            "name": "Somanathpur"
        },
        {
            "id": 13684,
            "name": "Tarlakota"
        },
        {
            "id": 13685,
            "name": "Tumusapalli"
        }
    ],
    "9952": [
        {
            "id": 13686,
            "name": "Badili"
        },
        {
            "id": 13687,
            "name": "Chalanguda"
        },
        {
            "id": 13688,
            "name": "Gorakhunta"
        },
        {
            "id": 13689,
            "name": "Korukonda"
        },
        {
            "id": 13690,
            "name": "Matapaka"
        },
        {
            "id": 13691,
            "name": "Nakamamudi"
        },
        {
            "id": 13692,
            "name": "Sikhapalli"
        },
        {
            "id": 13693,
            "name": "Somanathpur"
        },
        {
            "id": 13694,
            "name": "Tandapali"
        },
        {
            "id": 13695,
            "name": "Tankidi"
        },
        {
            "id": 13696,
            "name": "Tumusapalli"
        }
    ],
    "9953": [
        {
            "id": 13697,
            "name": "B.L.Pur"
        },
        {
            "id": 13698,
            "name": "Challanguda"
        },
        {
            "id": 13699,
            "name": "Gangala"
        },
        {
            "id": 13700,
            "name": "Goudaguda"
        },
        {
            "id": 13701,
            "name": "Gurakhunta"
        },
        {
            "id": 13702,
            "name": "Jharapalli"
        },
        {
            "id": 13703,
            "name": "Markapalli"
        },
        {
            "id": 13704,
            "name": "Matapaka"
        },
        {
            "id": 13705,
            "name": "Padmagiri"
        },
        {
            "id": 13706,
            "name": "Pandripani"
        },
        {
            "id": 13707,
            "name": "Pedakonda"
        },
        {
            "id": 13708,
            "name": "Pedawada"
        },
        {
            "id": 13709,
            "name": "Serpalli"
        },
        {
            "id": 13710,
            "name": "Sikhapalli"
        },
        {
            "id": 13711,
            "name": "Sindhrimal"
        },
        {
            "id": 13712,
            "name": "Tamasa"
        },
        {
            "id": 13713,
            "name": "Tandapalli"
        },
        {
            "id": 13714,
            "name": "Tandiki"
        },
        {
            "id": 13715,
            "name": "Udupa"
        }
    ],
    "9954": [
        {
            "id": 13716,
            "name": "NAC"
        }
    ],
    "9955": [
        {
            "id": 13717,
            "name": "NAC"
        }
    ],
    "9956": [
        {
            "id": 13718,
            "name": "Bapanpalli"
        },
        {
            "id": 13719,
            "name": "Girkanpalli"
        },
        {
            "id": 13720,
            "name": "Kaldapali"
        },
        {
            "id": 13721,
            "name": "Materu"
        },
        {
            "id": 13722,
            "name": "Niliguda"
        },
        {
            "id": 13723,
            "name": "Podia"
        },
        {
            "id": 13724,
            "name": "Similibancha"
        },
        {
            "id": 13725,
            "name": "Undrukonda"
        }
    ],
    "9957": [
        {
            "id": 13726,
            "name": "NAC"
        }
    ],
    "9958": [
        {
            "id": 13727,
            "name": "badbambua"
        },
        {
            "id": 13728,
            "name": "Bandomunda"
        },
        {
            "id": 13729,
            "name": "Bhalulata"
        },
        {
            "id": 13730,
            "name": "bisra"
        },
        {
            "id": 13731,
            "name": "Dereikela"
        },
        {
            "id": 13732,
            "name": "Jamsera"
        },
        {
            "id": 13733,
            "name": "Jereikela"
        },
        {
            "id": 13734,
            "name": "Jhirpani"
        },
        {
            "id": 13735,
            "name": "Kapatmunda"
        },
        {
            "id": 13736,
            "name": "manko"
        },
        {
            "id": 13737,
            "name": "Santoshpur"
        },
        {
            "id": 13738,
            "name": "Tareikela"
        },
        {
            "id": 13739,
            "name": "Thetheiposh"
        },
        {
            "id": 13740,
            "name": "Tulsikani"
        },
        {
            "id": 13741,
            "name": "Udusu"
        }
    ],
    "9959": [
        {
            "id": 13742,
            "name": "Andali"
        },
        {
            "id": 13743,
            "name": "Andhari"
        },
        {
            "id": 13744,
            "name": "Dalki"
        },
        {
            "id": 13745,
            "name": "Dumerjore"
        },
        {
            "id": 13746,
            "name": "Jalangbira"
        },
        {
            "id": 13747,
            "name": "Jaydega"
        },
        {
            "id": 13748,
            "name": "Jharbeda"
        },
        {
            "id": 13749,
            "name": "Jhunmur"
        },
        {
            "id": 13750,
            "name": "Kacharu"
        },
        {
            "id": 13751,
            "name": "Kadobahal"
        },
        {
            "id": 13752,
            "name": "Kalosihira"
        },
        {
            "id": 13753,
            "name": "Khukundubahal"
        },
        {
            "id": 13754,
            "name": "Kuarmunda"
        },
        {
            "id": 13755,
            "name": "Kumjharia"
        },
        {
            "id": 13756,
            "name": "Majhapara"
        },
        {
            "id": 13757,
            "name": "Padampur"
        },
        {
            "id": 13758,
            "name": "Pratapur"
        },
        {
            "id": 13759,
            "name": "Putrikhaman"
        },
        {
            "id": 13760,
            "name": "Raibaga"
        },
        {
            "id": 13761,
            "name": "salangabahal"
        }
    ],
    "9960": [
        {
            "id": 13762,
            "name": "Ankurpali"
        },
        {
            "id": 13763,
            "name": "Badjojoda"
        },
        {
            "id": 13764,
            "name": "Bagdega"
        },
        {
            "id": 13765,
            "name": "Barilepta"
        },
        {
            "id": 13766,
            "name": "Chitapedi"
        },
        {
            "id": 13767,
            "name": "Hatibari"
        },
        {
            "id": 13768,
            "name": "Jahaditoli"
        },
        {
            "id": 13769,
            "name": "Kanderkela"
        },
        {
            "id": 13770,
            "name": "Kardega"
        },
        {
            "id": 13771,
            "name": "Karkatnasa"
        },
        {
            "id": 13772,
            "name": "Khuntgaon"
        },
        {
            "id": 13773,
            "name": "kokorama"
        },
        {
            "id": 13774,
            "name": "Limida"
        },
        {
            "id": 13775,
            "name": "Lukumbeda"
        },
        {
            "id": 13776,
            "name": "Menmena"
        },
        {
            "id": 13777,
            "name": "nuagaon"
        },
        {
            "id": 13778,
            "name": "purunapani"
        },
        {
            "id": 13779,
            "name": "Sorda"
        },
        {
            "id": 13780,
            "name": "Teterkala"
        },
        {
            "id": 13781,
            "name": "Urmai"
        }
    ],
    "9961": [
        {
            "id": 13782,
            "name": "bai-gogua"
        },
        {
            "id": 13783,
            "name": "Bhalupani"
        },
        {
            "id": 13784,
            "name": "Bonaigarh"
        },
        {
            "id": 13785,
            "name": "bonaikela"
        },
        {
            "id": 13786,
            "name": "Govindpur"
        },
        {
            "id": 13787,
            "name": "Jakeikala"
        },
        {
            "id": 13788,
            "name": "Jhirdapali"
        },
        {
            "id": 13789,
            "name": "Kasda"
        },
        {
            "id": 13790,
            "name": "Kenaveta"
        },
        {
            "id": 13791,
            "name": "Kendrikela"
        },
        {
            "id": 13792,
            "name": "Pithachora"
        },
        {
            "id": 13793,
            "name": "Ruguda"
        },
        {
            "id": 13794,
            "name": "SarsaraBalang"
        }
    ],
    "9962": [
        {
            "id": 13795,
            "name": "Banki"
        },
        {
            "id": 13796,
            "name": "Bhaludunguri"
        },
        {
            "id": 13797,
            "name": "Bonaikela"
        },
        {
            "id": 13798,
            "name": "chandiposh"
        },
        {
            "id": 13799,
            "name": "Gurundia"
        },
        {
            "id": 13800,
            "name": "Jarda"
        },
        {
            "id": 13801,
            "name": "kucheita"
        },
        {
            "id": 13802,
            "name": "kundheidiha"
        },
        {
            "id": 13803,
            "name": "Narendra"
        },
        {
            "id": 13804,
            "name": "pankadihi"
        },
        {
            "id": 13805,
            "name": "Sole"
        },
        {
            "id": 13806,
            "name": "tamda"
        },
        {
            "id": 13807,
            "name": "tamperkela"
        }
    ],
    "9963": [
        {
            "id": 13808,
            "name": "Bimlagarh"
        },
        {
            "id": 13809,
            "name": "Chordhara"
        },
        {
            "id": 13810,
            "name": "Dengula"
        },
        {
            "id": 13811,
            "name": "Gopna"
        },
        {
            "id": 13812,
            "name": "Jamudihi"
        },
        {
            "id": 13813,
            "name": "K.Balang"
        },
        {
            "id": 13814,
            "name": "Kalta"
        },
        {
            "id": 13815,
            "name": "Kasira"
        },
        {
            "id": 13816,
            "name": "Koira"
        },
        {
            "id": 13817,
            "name": "Malda"
        },
        {
            "id": 13818,
            "name": "Patmunda"
        },
        {
            "id": 13819,
            "name": "Relhatu"
        },
        {
            "id": 13820,
            "name": "San Roxy"
        },
        {
            "id": 13821,
            "name": "Soyamba"
        },
        {
            "id": 13822,
            "name": "Tensa"
        }
    ],
    "9964": [
        {
            "id": 13823,
            "name": "Bad-Purnapani"
        },
        {
            "id": 13824,
            "name": "bhutuda"
        },
        {
            "id": 13825,
            "name": "Daleisara"
        },
        {
            "id": 13826,
            "name": "darjing"
        },
        {
            "id": 13827,
            "name": "Fuljhar"
        },
        {
            "id": 13828,
            "name": "Haldikudar"
        },
        {
            "id": 13829,
            "name": "Kaleiposh"
        },
        {
            "id": 13830,
            "name": "Khuntgaon"
        },
        {
            "id": 13831,
            "name": "Kudheikela"
        },
        {
            "id": 13832,
            "name": "Kuliposh"
        },
        {
            "id": 13833,
            "name": "kurda"
        },
        {
            "id": 13834,
            "name": "Lahunipara"
        },
        {
            "id": 13835,
            "name": "Mahulpada"
        },
        {
            "id": 13836,
            "name": "Rajamunda"
        },
        {
            "id": 13837,
            "name": "Sankhaposh"
        },
        {
            "id": 13838,
            "name": "Saskela"
        },
        {
            "id": 13839,
            "name": "Talbahali"
        }
    ],
    "9965": [
        {
            "id": 13840,
            "name": "Balani"
        },
        {
            "id": 13841,
            "name": "jalda"
        },
        {
            "id": 13842,
            "name": "Lathikata"
        },
        {
            "id": 13843,
            "name": "Lungel"
        },
        {
            "id": 13844,
            "name": "Mundajore"
        },
        {
            "id": 13845,
            "name": "Ramjodi"
        },
        {
            "id": 13846,
            "name": "Suidihi"
        }
    ],
    "9966": [
        {
            "id": 13847,
            "name": "NAC"
        }
    ],
    "9967": [
        {
            "id": 13848,
            "name": "Amgova"
        },
        {
            "id": 13849,
            "name": "Biringatali"
        },
        {
            "id": 13850,
            "name": "Gangajal"
        },
        {
            "id": 13851,
            "name": "Gyanpali"
        },
        {
            "id": 13852,
            "name": "jharbeda"
        },
        {
            "id": 13853,
            "name": "Kandeimunda"
        },
        {
            "id": 13854,
            "name": "Katang"
        },
        {
            "id": 13855,
            "name": "Khatkurbahal"
        },
        {
            "id": 13856,
            "name": "Kiringsira"
        },
        {
            "id": 13857,
            "name": "Kutra"
        },
        {
            "id": 13858,
            "name": "nuagaon"
        },
        {
            "id": 13859,
            "name": "Panchara"
        },
        {
            "id": 13860,
            "name": "Purkapali"
        },
        {
            "id": 13861,
            "name": "Rajabasa"
        },
        {
            "id": 13862,
            "name": "Tarkera"
        },
        {
            "id": 13863,
            "name": "Tunmura"
        }
    ],
    "9968": [
        {
            "id": 13864,
            "name": "Bad-Dalki"
        },
        {
            "id": 13865,
            "name": "Balanda"
        },
        {
            "id": 13866,
            "name": "Birda"
        },
        {
            "id": 13867,
            "name": "Birkera"
        },
        {
            "id": 13868,
            "name": "Chikatmati"
        },
        {
            "id": 13869,
            "name": "Garjan"
        },
        {
            "id": 13870,
            "name": "Jadakudar"
        },
        {
            "id": 13871,
            "name": "Kalunga A"
        },
        {
            "id": 13872,
            "name": "Karunga B"
        },
        {
            "id": 13873,
            "name": "Lungei"
        },
        {
            "id": 13874,
            "name": "Tainsar"
        }
    ],
    "9969": [
        {
            "id": 13875,
            "name": "Alanda"
        },
        {
            "id": 13876,
            "name": "Buchukupara"
        },
        {
            "id": 13877,
            "name": "Budhaam"
        },
        {
            "id": 13878,
            "name": "chhatam"
        },
        {
            "id": 13879,
            "name": "Chungimati"
        },
        {
            "id": 13880,
            "name": "dhelua"
        },
        {
            "id": 13881,
            "name": "Garvana"
        },
        {
            "id": 13882,
            "name": "Jareikela"
        },
        {
            "id": 13883,
            "name": "jaurumal"
        },
        {
            "id": 13884,
            "name": "Jhagarpur"
        },
        {
            "id": 13885,
            "name": "Kesramal"
        },
        {
            "id": 13886,
            "name": "Kukuda"
        },
        {
            "id": 13887,
            "name": "Kukudamunda"
        },
        {
            "id": 13888,
            "name": "kunmuru"
        },
        {
            "id": 13889,
            "name": "Kutunia"
        },
        {
            "id": 13890,
            "name": "Laing"
        },
        {
            "id": 13891,
            "name": "Malidihi"
        },
        {
            "id": 13892,
            "name": "Panposh"
        },
        {
            "id": 13893,
            "name": "Raikakachhar"
        },
        {
            "id": 13894,
            "name": "Sagijore"
        }
    ],
    "9970": [
        {
            "id": 13895,
            "name": "NAC"
        }
    ],
    "9971": [
        {
            "id": 13896,
            "name": "Jagada"
        },
        {
            "id": 13897,
            "name": "Jhirpani"
        }
    ],
    "9972": [
        {
            "id": 13898,
            "name": "NAC"
        }
    ],
    "9973": [
        {
            "id": 13899,
            "name": "Ankelbira"
        },
        {
            "id": 13900,
            "name": "Balinga"
        },
        {
            "id": 13901,
            "name": "Duduka"
        },
        {
            "id": 13902,
            "name": "Durubaga"
        },
        {
            "id": 13903,
            "name": "Garjanjore"
        },
        {
            "id": 13904,
            "name": "gopalpur"
        },
        {
            "id": 13905,
            "name": "Hemgir"
        },
        {
            "id": 13906,
            "name": "Jharpalam"
        },
        {
            "id": 13907,
            "name": "julumbahal"
        },
        {
            "id": 13908,
            "name": "kanaktura"
        },
        {
            "id": 13909,
            "name": "kanika"
        },
        {
            "id": 13910,
            "name": "Kendudihi"
        },
        {
            "id": 13911,
            "name": "kuchedega"
        },
        {
            "id": 13912,
            "name": "Laikera"
        },
        {
            "id": 13913,
            "name": "Munderkhet"
        },
        {
            "id": 13914,
            "name": "sanghumunda"
        },
        {
            "id": 13915,
            "name": "Sumura"
        },
        {
            "id": 13916,
            "name": "taparia"
        },
        {
            "id": 13917,
            "name": "Tumulia"
        }
    ],
    "9974": [
        {
            "id": 13918,
            "name": "Bad-banga"
        },
        {
            "id": 13919,
            "name": "Chhatenpali"
        },
        {
            "id": 13920,
            "name": "Darlipali"
        },
        {
            "id": 13921,
            "name": "Jhurimal"
        },
        {
            "id": 13922,
            "name": "raibaga"
        },
        {
            "id": 13923,
            "name": "Raidihi"
        },
        {
            "id": 13924,
            "name": "sargipali"
        }
    ],
    "9975": [
        {
            "id": 13925,
            "name": "NAC"
        }
    ],
    "9976": [
        {
            "id": 13926,
            "name": "Baragarh"
        },
        {
            "id": 13927,
            "name": "bhadabahal"
        },
        {
            "id": 13928,
            "name": "Bhasma"
        },
        {
            "id": 13929,
            "name": "Birbira"
        },
        {
            "id": 13930,
            "name": "Deuli"
        },
        {
            "id": 13931,
            "name": "karla"
        },
        {
            "id": 13932,
            "name": "Kinjirma"
        },
        {
            "id": 13933,
            "name": "Kirei"
        },
        {
            "id": 13934,
            "name": "Kulta"
        },
        {
            "id": 13935,
            "name": "kundukela"
        },
        {
            "id": 13936,
            "name": "Lahandabud"
        },
        {
            "id": 13937,
            "name": "Lankahuda"
        },
        {
            "id": 13938,
            "name": "Ledhimang"
        },
        {
            "id": 13939,
            "name": "majhapara"
        },
        {
            "id": 13940,
            "name": "Masnikani"
        },
        {
            "id": 13941,
            "name": "Salepali"
        }
    ],
    "9977": [
        {
            "id": 13942,
            "name": "Gambharidihi"
        },
        {
            "id": 13943,
            "name": "Jogimal"
        },
        {
            "id": 13944,
            "name": "Kiripsira"
        },
        {
            "id": 13945,
            "name": "mahulpali"
        },
        {
            "id": 13946,
            "name": "mangaspur"
        },
        {
            "id": 13947,
            "name": "Megdega"
        },
        {
            "id": 13948,
            "name": "Nialipali"
        },
        {
            "id": 13949,
            "name": "Patrapali"
        },
        {
            "id": 13950,
            "name": "Ratanpur"
        },
        {
            "id": 13951,
            "name": "Remenda"
        },
        {
            "id": 13952,
            "name": "Tangarpali"
        },
        {
            "id": 13953,
            "name": "tasladihi"
        },
        {
            "id": 13954,
            "name": "Ujalpur"
        }
    ],
    "9978": [
        {
            "id": 13955,
            "name": "B-karuabahal"
        },
        {
            "id": 13956,
            "name": "Balisankara"
        },
        {
            "id": 13957,
            "name": "bandega"
        },
        {
            "id": 13958,
            "name": "bandhabahal"
        },
        {
            "id": 13959,
            "name": "Beheradihi"
        },
        {
            "id": 13960,
            "name": "birkaldihi"
        },
        {
            "id": 13961,
            "name": "Deobhubanpur"
        },
        {
            "id": 13962,
            "name": "dhubendand"
        },
        {
            "id": 13963,
            "name": "kinjirkela"
        },
        {
            "id": 13964,
            "name": "Kurei"
        },
        {
            "id": 13965,
            "name": "kusumura"
        },
        {
            "id": 13966,
            "name": "Lulukidihi"
        },
        {
            "id": 13967,
            "name": "Rampur"
        },
        {
            "id": 13968,
            "name": "Sagbahal"
        },
        {
            "id": 13969,
            "name": "Saunamura"
        },
        {
            "id": 13970,
            "name": "Talsara"
        },
        {
            "id": 13971,
            "name": "tildega"
        },
        {
            "id": 13972,
            "name": "Tiliekani"
        },
        {
            "id": 13973,
            "name": "Tumulia"
        }
    ],
    "9979": [
        {
            "id": 13974,
            "name": "Bamdera"
        },
        {
            "id": 13975,
            "name": "Barangakachhar"
        },
        {
            "id": 13976,
            "name": "bargaon"
        },
        {
            "id": 13977,
            "name": "Bhoipali"
        },
        {
            "id": 13978,
            "name": "Dandjamira"
        },
        {
            "id": 13979,
            "name": "Ekma"
        },
        {
            "id": 13980,
            "name": "Fulbari"
        },
        {
            "id": 13981,
            "name": "Itma"
        },
        {
            "id": 13982,
            "name": "Jarangloi"
        },
        {
            "id": 13983,
            "name": "Pamra"
        },
        {
            "id": 13984,
            "name": "Rungaon"
        },
        {
            "id": 13985,
            "name": "Sahajbahal"
        },
        {
            "id": 13986,
            "name": "Tikilipara"
        },
        {
            "id": 13987,
            "name": "timna"
        },
        {
            "id": 13988,
            "name": "Tudalaga"
        }
    ],
    "9980": [
        {
            "id": 13989,
            "name": "Alapaka"
        },
        {
            "id": 13990,
            "name": "Dumabahal"
        },
        {
            "id": 13991,
            "name": "Giringkela"
        },
        {
            "id": 13992,
            "name": "gundiadihi"
        },
        {
            "id": 13993,
            "name": "Hurmei"
        },
        {
            "id": 13994,
            "name": "Kulabira"
        },
        {
            "id": 13995,
            "name": "Lephripara"
        },
        {
            "id": 13996,
            "name": "Masabira"
        },
        {
            "id": 13997,
            "name": "Rajbahal"
        },
        {
            "id": 13998,
            "name": "Sarafgarh"
        }
    ],
    "9981": [
        {
            "id": 13999,
            "name": "Damkuda"
        },
        {
            "id": 14000,
            "name": "Deogaon"
        },
        {
            "id": 14001,
            "name": "hamiripur"
        },
        {
            "id": 14002,
            "name": "Jaisore"
        },
        {
            "id": 14003,
            "name": "jamuna"
        },
        {
            "id": 14004,
            "name": "Karamdihi"
        },
        {
            "id": 14005,
            "name": "Kiralaga"
        },
        {
            "id": 14006,
            "name": "kukaridihi"
        },
        {
            "id": 14007,
            "name": "Kulba"
        },
        {
            "id": 14008,
            "name": "Kurumkel"
        },
        {
            "id": 14009,
            "name": "Rajpur"
        },
        {
            "id": 14010,
            "name": "subalaya"
        },
        {
            "id": 14011,
            "name": "Subdega"
        },
        {
            "id": 14012,
            "name": "Tangargaon"
        }
    ],
    "9982": [
        {
            "id": 14013,
            "name": "Amlipali"
        },
        {
            "id": 14014,
            "name": "Bugbuga"
        },
        {
            "id": 14015,
            "name": "Chakuli"
        },
        {
            "id": 14016,
            "name": "Dulampur"
        },
        {
            "id": 14017,
            "name": "Godbhaga"
        },
        {
            "id": 14018,
            "name": "Hirlipali"
        },
        {
            "id": 14019,
            "name": "Janged"
        },
        {
            "id": 14020,
            "name": "Janhapada"
        },
        {
            "id": 14021,
            "name": "Jhiliminda"
        },
        {
            "id": 14022,
            "name": "Kadobahal"
        },
        {
            "id": 14023,
            "name": "Kharmunda"
        },
        {
            "id": 14024,
            "name": "Kultatukura"
        },
        {
            "id": 14025,
            "name": "Kulunda"
        },
        {
            "id": 14026,
            "name": "Kumelsingha"
        },
        {
            "id": 14027,
            "name": "Lachida"
        },
        {
            "id": 14028,
            "name": "Lahanda"
        },
        {
            "id": 14029,
            "name": "Larambha"
        },
        {
            "id": 14030,
            "name": "Larasara"
        },
        {
            "id": 14031,
            "name": "Manapada"
        },
        {
            "id": 14032,
            "name": "Paharsrigida"
        },
        {
            "id": 14033,
            "name": "Silet"
        },
        {
            "id": 14034,
            "name": "Tamparsara"
        },
        {
            "id": 14035,
            "name": "Tangarpali"
        },
        {
            "id": 14036,
            "name": "Tope"
        }
    ],
    "9983": [
        {
            "id": 14037,
            "name": "NAC"
        }
    ],
    "9984": [
        {
            "id": 14038,
            "name": "Ainlapali"
        },
        {
            "id": 14039,
            "name": "Areigudi"
        },
        {
            "id": 14040,
            "name": "Arjunda"
        },
        {
            "id": 14041,
            "name": "Bakti"
        },
        {
            "id": 14042,
            "name": "Bheden"
        },
        {
            "id": 14043,
            "name": "Chichinda"
        },
        {
            "id": 14044,
            "name": "Dalab"
        },
        {
            "id": 14045,
            "name": "Deshbhatli"
        },
        {
            "id": 14046,
            "name": "Kubedega"
        },
        {
            "id": 14047,
            "name": "Luhakhandi"
        },
        {
            "id": 14048,
            "name": "Lupursingha"
        },
        {
            "id": 14049,
            "name": "Mahulpali"
        },
        {
            "id": 14050,
            "name": "Manpur"
        },
        {
            "id": 14051,
            "name": "Papanga"
        },
        {
            "id": 14052,
            "name": "Remenda"
        },
        {
            "id": 14053,
            "name": "Resham"
        },
        {
            "id": 14054,
            "name": "Rusuda"
        },
        {
            "id": 14055,
            "name": "Sankrida"
        },
        {
            "id": 14056,
            "name": "Sialkhandhata"
        },
        {
            "id": 14057,
            "name": "Sunalarambha"
        },
        {
            "id": 14058,
            "name": "Talmenda"
        }
    ],
    "9985": [
        {
            "id": 14059,
            "name": "Adgaon"
        },
        {
            "id": 14060,
            "name": "Banda"
        },
        {
            "id": 14061,
            "name": "Bardol"
        },
        {
            "id": 14062,
            "name": "Bargaon"
        },
        {
            "id": 14063,
            "name": "Barhaguda"
        },
        {
            "id": 14064,
            "name": "Birmal"
        },
        {
            "id": 14065,
            "name": "Chakarkend"
        },
        {
            "id": 14066,
            "name": "Deogaon"
        },
        {
            "id": 14067,
            "name": "Dhanger"
        },
        {
            "id": 14068,
            "name": "Gaisama"
        },
        {
            "id": 14069,
            "name": "Gobindpur"
        },
        {
            "id": 14070,
            "name": "Gudesira"
        },
        {
            "id": 14071,
            "name": "Jamurda"
        },
        {
            "id": 14072,
            "name": "Kalapani"
        },
        {
            "id": 14073,
            "name": "Katapali"
        },
        {
            "id": 14074,
            "name": "Khaliapali"
        },
        {
            "id": 14075,
            "name": "Khandahata"
        },
        {
            "id": 14076,
            "name": "Khuntpalli"
        },
        {
            "id": 14077,
            "name": "Kuruan"
        },
        {
            "id": 14078,
            "name": "Nileswar"
        },
        {
            "id": 14079,
            "name": "Nuniajampali"
        },
        {
            "id": 14080,
            "name": "Patharla"
        },
        {
            "id": 14081,
            "name": "S. Dumberpali"
        },
        {
            "id": 14082,
            "name": "Sarsara"
        },
        {
            "id": 14083,
            "name": "Talsirgida"
        },
        {
            "id": 14084,
            "name": "Tora"
        },
        {
            "id": 14085,
            "name": "Tumgaon"
        }
    ],
    "9986": [
        {
            "id": 14086,
            "name": "NAC"
        }
    ],
    "9987": [
        {
            "id": 14087,
            "name": "Bagbadi"
        },
        {
            "id": 14088,
            "name": "Baramkela"
        },
        {
            "id": 14089,
            "name": "Kanbar"
        },
        {
            "id": 14090,
            "name": "Katapali"
        },
        {
            "id": 14091,
            "name": "Kusanpuri"
        },
        {
            "id": 14092,
            "name": "Lenda"
        },
        {
            "id": 14093,
            "name": "Mahulpali"
        }
    ],
    "9988": [
        {
            "id": 14094,
            "name": "Ambabhona"
        },
        {
            "id": 14095,
            "name": "Banjipali"
        },
        {
            "id": 14096,
            "name": "Bhainatara"
        },
        {
            "id": 14097,
            "name": "Bhukta"
        },
        {
            "id": 14098,
            "name": "Bungapali"
        },
        {
            "id": 14099,
            "name": "Duari"
        },
        {
            "id": 14100,
            "name": "Dungri"
        },
        {
            "id": 14101,
            "name": "Kandapala"
        },
        {
            "id": 14102,
            "name": "Kapasira"
        },
        {
            "id": 14103,
            "name": "Karla"
        },
        {
            "id": 14104,
            "name": "Khola"
        },
        {
            "id": 14105,
            "name": "Kumbho"
        },
        {
            "id": 14106,
            "name": "Lakhanpur"
        },
        {
            "id": 14107,
            "name": "Ruchida"
        },
        {
            "id": 14108,
            "name": "Uttam"
        }
    ],
    "9989": [
        {
            "id": 14109,
            "name": "Bhadigaon"
        },
        {
            "id": 14110,
            "name": "Bhatli"
        },
        {
            "id": 14111,
            "name": "Chadeigaon"
        },
        {
            "id": 14112,
            "name": "Dumalpali"
        },
        {
            "id": 14113,
            "name": "Halupali"
        },
        {
            "id": 14114,
            "name": "Hatisar"
        },
        {
            "id": 14115,
            "name": "Kamgaon"
        },
        {
            "id": 14116,
            "name": "Kelendapali"
        },
        {
            "id": 14117,
            "name": "Kesaipali"
        },
        {
            "id": 14118,
            "name": "Kharmunda"
        },
        {
            "id": 14119,
            "name": "Mulbar"
        },
        {
            "id": 14120,
            "name": "Narangapur"
        },
        {
            "id": 14121,
            "name": "Nuagarh"
        },
        {
            "id": 14122,
            "name": "Sukuda"
        },
        {
            "id": 14123,
            "name": "Tejagula"
        },
        {
            "id": 14124,
            "name": "Urduna"
        }
    ],
    "9990": [
        {
            "id": 14125,
            "name": "Baunsenmura"
        },
        {
            "id": 14126,
            "name": "Beherapali"
        },
        {
            "id": 14127,
            "name": "Bijapali"
        },
        {
            "id": 14128,
            "name": "Birjam"
        },
        {
            "id": 14129,
            "name": "Chhuriapali"
        },
        {
            "id": 14130,
            "name": "Dumerpali"
        },
        {
            "id": 14131,
            "name": "Garvana"
        },
        {
            "id": 14132,
            "name": "Ghens"
        },
        {
            "id": 14133,
            "name": "Jatla"
        },
        {
            "id": 14134,
            "name": "Jhar"
        },
        {
            "id": 14135,
            "name": "Kalangapali"
        },
        {
            "id": 14136,
            "name": "Kanapali"
        },
        {
            "id": 14137,
            "name": "Kangaon"
        },
        {
            "id": 14138,
            "name": "Karandola"
        },
        {
            "id": 14139,
            "name": "Kuchipali"
        },
        {
            "id": 14140,
            "name": "Lebidi"
        },
        {
            "id": 14141,
            "name": "Pandakipali"
        },
        {
            "id": 14142,
            "name": "Panimora"
        },
        {
            "id": 14143,
            "name": "Petupali"
        },
        {
            "id": 14144,
            "name": "Rengali"
        },
        {
            "id": 14145,
            "name": "Salepali"
        },
        {
            "id": 14146,
            "name": "Sanimal"
        },
        {
            "id": 14147,
            "name": "Sarkanda"
        },
        {
            "id": 14148,
            "name": "Sohela"
        },
        {
            "id": 14149,
            "name": "Srigida"
        },
        {
            "id": 14150,
            "name": "Tabada"
        },
        {
            "id": 14151,
            "name": "Tungibandhali"
        }
    ],
    "9991": [
        {
            "id": 14152,
            "name": "Agalpur"
        },
        {
            "id": 14153,
            "name": "Bandhapali"
        },
        {
            "id": 14154,
            "name": "Baraguda"
        },
        {
            "id": 14155,
            "name": "Bhatigaon"
        },
        {
            "id": 14156,
            "name": "Gopaipali"
        },
        {
            "id": 14157,
            "name": "Kainsir"
        },
        {
            "id": 14158,
            "name": "Khemasara"
        },
        {
            "id": 14159,
            "name": "Kumbhari"
        },
        {
            "id": 14160,
            "name": "Mahada"
        },
        {
            "id": 14161,
            "name": "Patakulunda"
        },
        {
            "id": 14162,
            "name": "Raksa"
        },
        {
            "id": 14163,
            "name": "Remta"
        },
        {
            "id": 14164,
            "name": "Satlama"
        },
        {
            "id": 14165,
            "name": "Tileimal"
        },
        {
            "id": 14166,
            "name": "Tinkani"
        },
        {
            "id": 14167,
            "name": "Tulandi"
        }
    ],
    "9992": [
        {
            "id": 14168,
            "name": "NAC"
        }
    ],
    "9993": [
        {
            "id": 14169,
            "name": "Badabaunsen"
        },
        {
            "id": 14170,
            "name": "Badbrahmani"
        },
        {
            "id": 14171,
            "name": "Bairakhapali"
        },
        {
            "id": 14172,
            "name": "Barmunda"
        },
        {
            "id": 14173,
            "name": "Beniachal"
        },
        {
            "id": 14174,
            "name": "Bhatigaon"
        },
        {
            "id": 14175,
            "name": "Budapali"
        },
        {
            "id": 14176,
            "name": "Charpali"
        },
        {
            "id": 14177,
            "name": "Ganthiapali"
        },
        {
            "id": 14178,
            "name": "Jaring"
        },
        {
            "id": 14179,
            "name": "Jokhipali"
        },
        {
            "id": 14180,
            "name": "Kharmunda"
        },
        {
            "id": 14181,
            "name": "Laumunda"
        },
        {
            "id": 14182,
            "name": "M. Srigida"
        },
        {
            "id": 14183,
            "name": "Pada"
        },
        {
            "id": 14184,
            "name": "Pahandi"
        },
        {
            "id": 14185,
            "name": "Patharla"
        },
        {
            "id": 14186,
            "name": "Saipali"
        },
        {
            "id": 14187,
            "name": "Samaleipadar"
        },
        {
            "id": 14188,
            "name": "Sanbausen"
        },
        {
            "id": 14189,
            "name": "Surubali"
        },
        {
            "id": 14190,
            "name": "T.Gandpali"
        },
        {
            "id": 14191,
            "name": "Talpadar"
        }
    ],
    "9994": [
        {
            "id": 14192,
            "name": "NAC"
        }
    ],
    "9995": [
        {
            "id": 14193,
            "name": "Buromunda"
        },
        {
            "id": 14194,
            "name": "Chantipali"
        },
        {
            "id": 14195,
            "name": "Chikhili"
        },
        {
            "id": 14196,
            "name": "Dangbahal"
        },
        {
            "id": 14197,
            "name": "Gaisilet"
        },
        {
            "id": 14198,
            "name": "Ganiapali"
        },
        {
            "id": 14199,
            "name": "Gaurenmunda"
        },
        {
            "id": 14200,
            "name": "Guderpali"
        },
        {
            "id": 14201,
            "name": "Jagalpat"
        },
        {
            "id": 14202,
            "name": "Jamutpali"
        },
        {
            "id": 14203,
            "name": "Kandagad"
        },
        {
            "id": 14204,
            "name": "Katabahal"
        },
        {
            "id": 14205,
            "name": "Kathaumal"
        },
        {
            "id": 14206,
            "name": "Kundakhai"
        },
        {
            "id": 14207,
            "name": "Phiringimal"
        },
        {
            "id": 14208,
            "name": "Raisalpadar"
        },
        {
            "id": 14209,
            "name": "Sandhibahal"
        },
        {
            "id": 14210,
            "name": "Saradhapali"
        },
        {
            "id": 14211,
            "name": "Talpali"
        }
    ],
    "9996": [
        {
            "id": 14212,
            "name": "Amthi"
        },
        {
            "id": 14213,
            "name": "Bhainsadarha"
        },
        {
            "id": 14214,
            "name": "Bhandarpuri"
        },
        {
            "id": 14215,
            "name": "Bilaspur"
        },
        {
            "id": 14216,
            "name": "Chandibhata"
        },
        {
            "id": 14217,
            "name": "Chhotanki"
        },
        {
            "id": 14218,
            "name": "Dava"
        },
        {
            "id": 14219,
            "name": "Gothaguda"
        },
        {
            "id": 14220,
            "name": "Jagdalpur"
        },
        {
            "id": 14221,
            "name": "Jharbandha"
        },
        {
            "id": 14222,
            "name": "Kandadangar"
        },
        {
            "id": 14223,
            "name": "Kumir"
        },
        {
            "id": 14224,
            "name": "Kurlupali"
        },
        {
            "id": 14225,
            "name": "Laudidarah"
        }
    ],
    "9997": [
        {
            "id": 14226,
            "name": "NAC"
        }
    ],
    "9998": [
        {
            "id": 14227,
            "name": "Badikata"
        },
        {
            "id": 14228,
            "name": "Bartunda"
        },
        {
            "id": 14229,
            "name": "Bhengrajpur"
        },
        {
            "id": 14230,
            "name": "Bhubaneswarpur"
        },
        {
            "id": 14231,
            "name": "Bukramunda"
        },
        {
            "id": 14232,
            "name": "Charadapali"
        },
        {
            "id": 14233,
            "name": "Chhetgaon"
        },
        {
            "id": 14234,
            "name": "Chhindeikela"
        },
        {
            "id": 14235,
            "name": "Jamseth"
        },
        {
            "id": 14236,
            "name": "Jharbandh"
        },
        {
            "id": 14237,
            "name": "Jhitiki"
        },
        {
            "id": 14238,
            "name": "Kansada"
        },
        {
            "id": 14239,
            "name": "Kechhodadar"
        },
        {
            "id": 14240,
            "name": "Kermelabahal"
        },
        {
            "id": 14241,
            "name": "Lakhamara"
        },
        {
            "id": 14242,
            "name": "Mandiadhipa"
        },
        {
            "id": 14243,
            "name": "Mandosil"
        },
        {
            "id": 14244,
            "name": "Mithapali"
        },
        {
            "id": 14245,
            "name": "Paikamal"
        },
        {
            "id": 14246,
            "name": "Palsada"
        },
        {
            "id": 14247,
            "name": "Saraikela"
        },
        {
            "id": 14248,
            "name": "Temri"
        }
    ],
    "9999": [
        {
            "id": 14249,
            "name": "Barihapali"
        },
        {
            "id": 14250,
            "name": "Barikel"
        },
        {
            "id": 14251,
            "name": "Bheunria"
        },
        {
            "id": 14252,
            "name": "Budamal"
        },
        {
            "id": 14253,
            "name": "Buden"
        },
        {
            "id": 14254,
            "name": "Charpali"
        },
        {
            "id": 14255,
            "name": "Dahigaon"
        },
        {
            "id": 14256,
            "name": "Dahita"
        },
        {
            "id": 14257,
            "name": "Dangaghat"
        },
        {
            "id": 14258,
            "name": "Gyan"
        },
        {
            "id": 14259,
            "name": "Jamala"
        },
        {
            "id": 14260,
            "name": "Jamartala"
        },
        {
            "id": 14261,
            "name": "Kansar"
        },
        {
            "id": 14262,
            "name": "Kansingha"
        },
        {
            "id": 14263,
            "name": "Khaliapali"
        },
        {
            "id": 14264,
            "name": "Loharapali"
        },
        {
            "id": 14265,
            "name": "Mahulpali"
        },
        {
            "id": 14266,
            "name": "Melchhamunda"
        },
        {
            "id": 14267,
            "name": "Palsapali"
        },
        {
            "id": 14268,
            "name": "Purena"
        },
        {
            "id": 14269,
            "name": "Sargibahal"
        },
        {
            "id": 14270,
            "name": "Tal (Diptipur)"
        }
    ]
};

async function getGenderStats(constituency, block, village, pollingStation) {
  try {
    const resp = await axiosInstance.get(`/state/${STATE.slug}/stats/gender?${constituency != null ? `constituency=${constituency}` : ''}${block != null ? `&block=${block}` : ''}${village != null ? `&village=${village}` : ''}${pollingStation != null ? `&polling_station=${pollingStation}` : ''}`);
    return resp.data;
  } catch (err) {
      throw err;
  }
}


async function getSurveyStats(constituency, block, village, pollingStation) {
  try {
    const resp = await axiosInstance.get(`/state/${STATE.slug}/stats/survey?${constituency != null ? `constituency=${constituency}` : ''}${block != null ? `&block=${block}` : ''}${village != null ? `&village=${village}` : ''}${pollingStation != null ? `&polling_station=${pollingStation}` : ''}`);
    return resp.data;
  } catch (err) {
      console.error(err);
      throw err;
  }
}


async function getOpinionStats(constituency, block, village, pollingStation) {
  try {
    const resp = await axiosInstance.get(`/state/${STATE.slug}/stats/opinion?${constituency != null ? `constituency=${constituency}` : ''}${block != null ? `&block=${block}` : ''}${village != null ? `&village=${village}` : ''}${pollingStation != null ? `&polling_station=${pollingStation}` : ''}`);
    return resp.data;
  } catch (err) {
      throw err;
  }
}

async function getAgeStats(constituency, block, village, pollingStation) {
  try {
    const resp = await axiosInstance.get(`/state/${STATE.slug}/stats/age?${constituency != null ? `constituency=${constituency}` : ''}${block != null ? `&block=${block}` : ''}${village != null ? `&village=${village}` : ''}${pollingStation != null ? `&polling_station=${pollingStation}` : ''}`);
    return resp.data;
  } catch (err) {
      throw err;
  }
}


async function getReligionStats(constituency, block, village, pollingStation) {
  try {
    const resp = await axiosInstance.get(`/state/${STATE.slug}/stats/religion?${constituency != null ? `constituency=${constituency}` : ''}${block != null ? `&block=${block}` : ''}${village != null ? `&village=${village}` : ''}${pollingStation != null ? `&polling_station=${pollingStation}` : ''}`);
    return resp.data;
  } catch (err) {
      throw err;
  }
}

async function getCasteStats(constituency, block, village, pollingStation) {
  try {
    const resp = await axiosInstance.get(`/state/${STATE.slug}/stats/caste?${constituency != null ? `constituency=${constituency}` : ''}${block != null ? `&block=${block}` : ''}${village != null ? `&village=${village}` : ''}${pollingStation != null ? `&polling_station=${pollingStation}` : ''}`);
    return resp.data;
  } catch (err) {
      throw err;
  }
}


async function getStateConstituencies(districtId) {
    try {
        const resp = await axiosInstance.get(`/state/${STATE.slug}/acs/`);
        return resp?.data;
    } catch (err) {
        console.error(err);
        throw err;
    }
}

async function getConstituencies(districtId) {
  try {
      const resp = await axiosInstance.get(`/district/${districtId}/acs/`);
      return resp.data;
  } catch (err) {
      console.error(err);
      throw err;
  }
}

async function getBlocks(selectedConstituency) {
    try {
      const resp = await axiosInstance.get(`/state/${STATE.slug}/blocks/?` + (selectedConstituency != null ? `constituency=${selectedConstituency}` : ''));
      return resp.data;
    } catch (err) {
        throw err;
    }
}

async function getVillages(selectedBlock) {

  try {
    const resp = await axiosInstance.get(`/state/telangana/villages/?` + (selectedBlock != null ? `block=${selectedBlock}` : ''));
    return resp.data;
  } catch (err) {
      console.error(err);
      throw err;
  }

}

async function getPollingStationIncharges(constituency, block, village, pageNumber) {
  try {
    const resp = await axios.get( `${baseURL}PollingStationInchargesData${pageNumber}.json` );
    return resp.data;
  } catch ( err ) {
      console.error( err );
      throw err;
  }
}

async function getHouseVisitedCount(constituencie, block, village, pageNumber, pageSize) {
  try {
    const resp = await axios.get(`${baseURL}housevisitedcount${pageNumber}.json`);
    return resp.data;
  } catch (err) {
      console.error(err);
      throw err;
  }
}


async function getCommentsOfVolunteer(constituencie, block, village, pageNumber) {
  try {
    const resp = await axios.get(`${baseURL}commentsofvolunteer${pageNumber}.json`);
    return resp.data;
  } catch (err) {
      console.error(err);
      throw err;
  }
}


async function getImportantVoters(constituency, block, village, pollingStation, pageNumber, pageSize) {
  try {
    const resp = await axiosInstance.get(`/state/${STATE.slug}/voters/${pageNumber ? `?page=${pageNumber}` : ''}${pageSize ? `&page_size=${pageSize}` : ''}${constituency ? `&constituency=${constituency}` : ''}${block ? `&block=${block}` : ''}${village ? `&village=${village}` : ''}${pollingStation ? `&polling_station=${pollingStation}` : ''}&influential_voter=true`);
    return resp.data;
  } catch (err) {
      console.error(err);
      throw err;
  }
}

async function getVoterData(voterId) {
  try {
    const resp = await axiosInstance.get(`/voter/${voterId}/`);
    return resp?.data;
  } catch (err) {
      throw err;
  }
}


function getUpdateVoterData() {
  let allVoterData = 
    {
      "name": "Sai Ram",
      "fathername": "Ramulu",
      "gender": "Male",
      "address": "Masjid banda circle, Kondapur",
      "voterid": "YLK2096758",
      "pollingStationNo": "125",
      "pollingStationlocation": "Zphs, Kondapur",
      "serialno": "10",
      "age": "28",
      "houseno": "2-52"
    }

  return allVoterData;
}

async function getPollingStations(village) {
  try {
    const resp = await axiosInstance.get(`/state/telangana/polling-stations/?` + (village != null ? `village=${village}` : ''));
    return resp.data;
  } catch (err) {
      console.error(err);
      throw err;
  }
}

const filterFields = {
  'constituency':{
    label: 'Constituency',
    placeholder: 'All Constituencies',
    labelStatus: true,
    defaultValue: 86,
    colmd: 3,
    collg: 3,
    col: 12
  },
  'block':{
    label: 'Block',
    placeholder: 'All Blocks',
    labelStatus: true,
    defaultValue: '',
    colmd: 3,
    collg: 3,
    col: 12

  },
  'village':{
    label: 'Village',
    placeholder: 'All Village',
    labelStatus: true,
    defaultValue: '',
    colmd: 3,
    collg: 3,
    col: 12
  },
  'pollingStation':{
    label: 'Polling Station',
    placeholder: 'All Polling Station',
    labelStatus: true,
    defaultValue: '',
    colmd: 3,
    collg: 3,
    col: 12
  },
  'houseNo':{
    label: 'House No',
    placeholder: 'House No',
    labelStatus: true,
    defaultValue: '',
    col: 2
  },
  'incharge':{
    label: 'Incharge',
    placeholder: 'Incharge',
    labelStatus: true,
    defaultValue: '',
    col: 2
  },
  'designation':{
    label: 'Designation',
    placeholder: 'Designation',
    labelStatus: true,
    defaultValue: '',
    colmd: 3,
    collg: 3,
    col: 12
  }
}

const voterFilterFields = {
  'constituency':{
    label: 'Constituency',
    placeholder: 'All Constituencies',
    labelStatus: true,
    defaultValue: 1,
    col: 12
  },
  'block':{
    label: 'Block',
    placeholder: 'All Blocks',
    labelStatus: true,
    defaultValue: '',
    col: 12
  },
  'village':{
    label: 'Village',
    placeholder: 'All Villages',
    labelStatus: true,
    defaultValue: '',
    col: 12
  },
  'pollingStation':{
    label: 'Polling Station',
    placeholder: 'All Polling Stations',
    labelStatus: true,
    defaultValue: '',
    col: 12
  },
  'houseNo':{
    label: 'House No',
    placeholder: 'House No',
    labelStatus: true,
    defaultValue: '',
    col: 12
  },
  'incharge':{
    label: 'Incharge',
    placeholder: 'Incharge',
    labelStatus: true,
    defaultValue: '',
    col: 12
  },
  'designation':{
    label: 'Designation',
    placeholder: 'Designation',
    labelStatus: true,
    defaultValue: '',
    col: 12
  }
}
const psInchargeFields = {
  'constituency':{
    label: 'Constituency',
    placeholder: 'All Constituencies',
    labelStatus: false,
    defaultValue: '',
    col: 12
  },
  'block':{
    label: 'Block',
    placeholder: 'All Block',
    labelStatus: false,
    defaultValue: '',
    col: 12
  },
  'village':{
    label: 'Village',
    placeholder: 'All Villages',
    labelStatus: false,
    defaultValue: '',
    col: 12
  },
  'pollingStation':{
    label: 'Polling Station',
    placeholder: 'All Polling Station',
    labelStatus: false,
    defaultValue: '',
    col: 12
  }
}


async function getHouseIncharges(constituency, block, village, pollingStation, pageNumber) {
  try {
    const resp = await axios.get(`${baseURL}houseincharges${pageNumber ? pageNumber : ''}.json`);
    return resp.data;
  } catch (err) {
      console.error(err);
      throw err;
  }
}

async function getDesignation() {
  try {
    const resp = await axios.get(`${baseURL}designation.json`);
    return resp.data;
  } catch (err) {
      console.error(err);
      throw err;
  }
}

const NewVolunteerFieldsProperties = {
  "constituencies": {
    label: "Assembly Constituency",
    placeholder: "Select",
    labelStatus: false,
    col: '',
    defaultValue: ''
  },
  "blocks": {
    lebal: "Block",
    placeholder: "Select",
    labelStatus: false,
    col: '',
    defaultValue: ''
  },
  "villages": {
    label: "Village",
    placeholder: "Select",
    labelStatus: false,
    col: '',
    defaultValue: ''
  },
  "pollingStations": {
    label: "PollingStation",
    placeholder: "Select",
    labelStatus: false,
    col: '',
    defaultValue: ''
  },
  "designation": {
    label: "Designation",
    placeholder: "Select",
    labelStatus: false,
    col: '',
    defaultValue: ''
  },
  "reportingto": {
    label: "Reporting To",
    placeholder: "Select",
    labelStatus: false,
    col: '',
    defaultValue: ''
  },
  "district": {
    label: "District",
    placeholder: "Select",
    labelStatus: false,
    col: '',
    defaultValue: ''
  },
  "town": {
    label: "Town",
    placeholder: "Select",
    labelStatus: false,
    col: '',
    defaultValue: ''
  }
}

async function getDistricts() {
  try {
    const resp = await axiosInstance.get(`/state/${STATE.slug}/districts`);
    return resp.data;
  } catch (err) {
      console.error(err);
      throw err;
  }
}

async function getTowns(district) {
  try {
    const resp = await axios.get(`${baseURL}towns.json`);
    return resp.data[district];
  } catch (err) {
      console.error(err);
      throw err;
  }
}

async function getReportingTo() {
  try {
    const resp = await axios.get(`${baseURL}reportingto.json`);
    return resp.data;
  } catch (err) {
      console.error(err);
      throw err;
  }
}

async function getIncharges() {
  try {
    const resp = await axios.get(`${baseURL}incharges.json`);
    return resp.data;
  } catch (err) {
      console.error(err);
      throw err;
  }
}

async function getVolunteer() {
  try {
    const resp = await axios.get(`${baseURL}elections.json`);
    return resp.data;
  } catch (err) {
      console.error(err);
      throw err;
  }
}

async function getAllVillages() {
  try {
    const resp = await axiosInstance.get(`/state/${STATE.slug}/villages/`);
    return resp.data;
  } catch (err) {
      throw err;
  }
}

async function searchVillage(data) {
  try {
    const resp = await axiosInstance.get(`/search/village?q=${data}`);
    return resp.data;
  } catch(err) {
    throw err;
  }
}

async function getDemographicsData(village) {
  try {
    const resp = await axios.get(`${baseURL}demographics.json`);
    return resp.data[village];
  } catch (err) {
      console.error(err);
      throw err;
  }
}

async function getVillageCategory() {
  try {
    const resp = await axios.get(`${baseURL}villagecategory.json`);
    return resp.data;
  } catch (err) {
      console.error(err);
      throw err;
  }
}


async function getVoterDetails(pageNumber, pageSize, data) {
  try {
    const resp = await axiosInstance.get(`/state/${STATE.slug}/voters?${pageNumber ? `page=${pageNumber}` : ''}${pageSize ? `&page_size=${pageSize}` : ''}${data?.constituency ? `&constituency=${data?.constituency}` : ''}${data?.block ? `&block=${data.block}` : ''}${data?.village ? `&village=${data.village}` : ''}${data?.pollingStation ? `&polling_station=${data.pollingStation}` : ''}${data?.religion ? `&religion=${data.religion}` : ''}${data?.caste ? `&caste=${data.caste}` : ''}${data?.subCaste ? `&subcaste=${data.subCaste}` : ''}${data?.gender ? `&gender=${data.gender}` : ''}${data?.opinion ? `&opinion=${data.opinion}` : ''}${data?.minAge ? `&age_min=${data.minAge}` : ''}${data?.maxAge ? `&age_max=${data.maxAge}` : ''}${data?.influentialVoter ? `&influential_voter=${data.influentialVoter}` : ''}${data?.benefitedByGovernmentScheme ? `&government_beneficiary=${data.benefitedByGovernmentScheme}` : ''}${data?.surveyCompleted ? `&survey_completed=${data?.surveyCompleted}` : ''}${data?.textVal ? `&q=${data?.textVal}` : ''}`);
    return resp.data;
  } catch (voterDetailserr) {
      throw voterDetailserr;
  }
}

async function getVoterDetailsMobileWise(pageNumber, pageSize, data) {
  try {
    const resp = await axiosInstance.get(`/state/${STATE.slug}/voters-group?${pageNumber ? `page=${pageNumber}` : ''}${pageSize ? `&page_size=${pageSize}` : ''}${data?.constituency ? `&constituency=${data?.constituency}` : ''}${data?.block ? `&block=${data.block}` : ''}${data?.village ? `&village=${data.village}` : ''}${data?.pollingStation ? `&polling_station=${data.pollingStation}` : ''}${data?.religion ? `&religion=${data.religion}` : ''}${data?.caste ? `&caste=${data.caste}` : ''}${data?.subCaste ? `&subcaste=${data.subcaste}` : ''}${data?.gender ? `&gender=${data.gender}` : ''}${data?.opinion ? `&opinion=${data.opinion}` : ''}${data?.minAge ? `&age_min=${data.minAge}` : ''}${data?.maxAge ? `&age_max=${data.maxAge}` : ''}${data?.influentialVoter ? `&influential_voter=${data.influentialVoter}` : ''}${data?.benefitedByGovernmentScheme ? `&government_beneficiary=${data.benefitedByGovernmentScheme}` : ''}`);
    return resp.data;
  } catch (voterDetailserr) {
      throw voterDetailserr;
  }
}

async function getUniqueMobileNumbers(pageNumber, pageSize, data) {
  try {
    const resp = await axiosInstance.get(`/state/${STATE.slug}/voters-group?${pageNumber ? `page=${pageNumber}` : ''}${pageSize ? `&page_size=${pageSize}` : ''}${data?.constituency ? `&constituency=${data?.constituency}` : ''}${data?.block ? `&block=${data.block}` : ''}${data?.village ? `&village=${data.village}` : ''}${data?.pollingStation ? `&polling_station=${data.pollingStation}` : ''}${data?.religion ? `&religion=${data.religion}` : ''}${data?.caste ? `&caste=${data.caste}` : ''}${data?.subCaste ? `&subcaste=${data.subcaste}` : ''}${data?.gender ? `&gender=${data.gender}` : ''}${data?.opinion ? `&opinion=${data.opinion}` : ''}${data?.minAge ? `&age_min=${data.minAge}` : ''}${data?.maxAge ? `&age_max=${data.maxAge}` : ''}${data?.influentialVoter ? `&influential_voter=${data.influentialVoter}` : ''}${data?.benefitedByGovernmentScheme ? `&government_beneficiary=${data.benefitedByGovernmentScheme}` : ''}`);
    return resp.data;
  } catch (voterDetailserr) {
      throw voterDetailserr;
  }
}

async function getVoterSummary(fieldName, sortOrder, pageNumber) {
  try {
    const resp = await axios.get(`${baseURL}VoterSummary${fieldName}${sortOrder}${pageNumber}.json`);
    return resp.data;
  } catch (err) {
      console.error(err);
      throw err;
  }
}

async function getCasteList() {
  try {
    const resp = await axiosInstance.get(`/caste/`);
    return resp.data;
  } catch (err) {
    throw err;
  }
}

async function getSubCaste(casteId) {
  try {
    const resp = await axiosInstance.get(`/subcaste/${casteId ? `?caste=${casteId}` : ''}`);
    return resp.data;
  } catch (err) {
    throw err;
  }
}

function getGenderName(id) {
  let data = {
    'M': 'Male',
    'F': 'Female',
    'O': 'Other'
  }

  return data[id]
}

async function getReligion() {
  try {
    const resp = await axiosInstance.get(`/religion/`);
    return resp.data;
  } catch(err) {
    throw err;
  }
}

async function getOpinion() {
  try {
    const resp = await axiosInstance.get(`/political-party/`);
    return resp.data;
  } catch(err) {
    throw err;
  }
}

async function updateVoterData(data) {
  try {
    const resp = await axiosInstance.put(`/voter/${data.id_card_number}/`, data);
    return resp.data;
  } catch(err) {
    throw err;
  }
}

function getRelationTypeName(id) {
  let data = {
    'F': 'Father',
    'M': 'Mother',
    'H': 'Husband',
    'W': 'Wife',
    'O': 'Other',
    '-': 'NA'
  }
  
  return data[id];
}

function getNameFromList(list, id) {
  for(let i=0;i<list.length;i++) {
      if(list[i].id == id) {
        let Name = list[i].name;
        return Name;
      }
  }
}

async function getVillageProfileList() {
  try {
    const resp = await axiosInstance.get(`/get-village-profile-list/`);
    return resp.data;
  } catch(err) {
    throw err;
  }
}

async function getVillageProfile(id) {
  try {
    const resp = await axiosInstance.get(`/village-Profile/${id}`);
    return resp.data;
  } catch(err) {
    throw err;
  }
}

// Voter Survey
async function addVoterSurvey(data) {
  try {
    const resp = await axiosInstance.post(`/survey/`, data);
    return resp.data;
  } catch(err) { 
    throw err;
  }
}

async function getVoterSurveyList() {
  try {
    const resp = await axiosInstance.get(`/survey/`);
    return resp.data;
  } catch(err) {
    throw err;
  }
}

async function getVoterSurveyQuestionsList(id) {
  try {
    const resp = await axiosInstance.get(`/survey/${id}`);
    return resp.data;
  } catch(err) {
    throw err;
  }
}

async function getVoterSurveyResultByGender(id){
  try {
    const resp = await axiosInstance.get(`/survey-result-by-gender/${id}`);
    return resp.data;
  } catch(err) {
    throw err;
  }
}

async function getVoterSurveyResultByAge(id){
  try {
    const resp = await axiosInstance.get(`/survey-result-by-age/${id}`);
    return resp.data;
  } catch(err) {
    throw err;
  }
}

async function postVoterSurveyResponse(surveyId, data) {
  try {
    const resp = await axiosInstance.post(`/survey/${surveyId}/response/`, data);
    return resp.data;
  } catch(err) {
    throw err;
  }
}

async function putVoterSurveyActive(surveyId, data) {
  try {
    const resp = await axiosInstance.put(`/survey/${surveyId}/`, data);
    return resp.data;
  } catch(err) {
    throw err;
  }
}

async function getSearchVoter(voterNumber) {
  try {
    const resp = await axiosInstance.get(`/search/voter/?q=${voterNumber}`);
    return resp.data;
  } catch(err) {
    throw err;
  }
}

async function getInfluentialPosition() {
  try {
    const resp = await axiosInstance.get(`/influential-position/`);
    return resp.data;
  } catch(err) {
    throw err;
  }
}


async function getGovernmentScheme() {
  try {
    const resp = await axiosInstance.get(`/government-scheme/`);
    return resp.data;
  } catch(err) {
    throw err;
  }
}

async function postVolunteerComment(data) {
  try {
    const resp = await axiosInstance.post(`/comment/`, data);
    return resp.data;
  } catch(err) {
    throw err;
  }
}

async function getVolunteerComments(pageNumber, pageSize) {
  try {
    const resp = await axiosInstance.get(`/comment/${pageSize ? `?page_size=${pageSize}`: ''}${pageNumber ? `&page=${pageNumber}` : ''}`);
    return resp.data;
  } catch(err) {
    throw err;
  }
}

async function getCommentsFromRole(pageNumber, pageSize) {
  try {
    const resp = await axiosInstance.get(`/get-comment-from-role/${pageSize ? `?page_size=${pageSize}`: ''}${pageNumber ? `&page=${pageNumber}` : ''}`);
    return resp.data;
  } catch(err) {
    throw err;
  }
}

async function getSearchUser(data) {
  try {
    const resp = await axiosInstance.get(`/search/user/?q=${data}`);
    return resp.data;
  } catch(err) {
    throw err;
  }
}

async function getUserProfile() {
  try{
    const resp = await axiosInstance.get(`/profile/`);
    return resp.data;
  } catch(err) {
    throw err;
  }
}

async function getPollingStationHouses(pollingStation) {
  try{
    const resp = await axiosInstance.get(``);
    return resp.data;
  } catch(err) {
    throw err;
  }
}

const formFilterFields = {
  'constituency':{
    placeholder: 'All Constituencies',
    labelStatus: false
  },
  'block':{
    placeholder: 'All Blocks',
    labelStatus: false
  },
  'village':{
    placeholder: 'All Villages',
    labelStatus: false
  },
  'pollingStation':{
    placeholder: 'All Polling Stations',
    labelStatus: false
  },
  'houseNo':{
    placeholder: 'House No',
    labelStatus: false
  }
}

async function getVillageSmsTemplate() {
  try{
    const resp = await axiosInstance.get(`/sms-templates/`);
    return resp.data;
  } catch(err) {
    throw err;
  }
}

async function addSmsTemplate(data) {
  try {
    const resp = await axiosInstance.post(`/sms-templates/`, data);
    return resp.data;
  } catch(err) { 
    throw err;
  }
}

async function getSmsTemplate(data) {
  try {
    const resp = await axiosInstance.get(`/sms-templates/`);
    return resp.data;
  } catch(err) { 
    throw err;
  }
}

async function changePassword(data) {
  try {
    const resp = await axiosInstance.put(`/change-password/`, data);
    return resp.data;
  } catch(err) { 
    throw err;
  }
}

async function getSettings(group) {
  try{
    const resp = await axiosInstance.get(`/app-settings/${group}/`);
    return resp.data;
  } catch(err) { 
    throw err;
  }
}

async function updateSettings(group, data) {
  try{
    const resp = await axiosInstance.put(`/app-settings/${group}/`, data);
    return resp.data;
  } catch(err) { 
    throw err;
  }
}


async function getSmsSpecificTemplate(data) {
  try {
    const resp = await axiosInstance.get(`/sms-template/${data}`);
    return resp?.data;
  } catch(err) { 
    throw err;
  }
}

async function updateSmsTemplate(id,data) {
  try {
    const resp = await axiosInstance.put(`/sms-template/${id}/`,data);
    return resp.data;
  } catch(err) { 
    throw err;
  }
}

async function deleteSmsTemplate(id) {
  try {
    const resp = await axiosInstance.delete(`/sms-template/${id}/`);
    return resp.data;
  } catch(err) { 
    throw err;
  }
}


async function getSubstitutionOptions() {
  try{
    const resp = await axiosInstance.get(`/substitution-options/`);
    return resp.data;
    } catch(err) {
      throw err;
    }
  }


async function createSendSMS(data) {
  try {
    const resp = await axiosInstance.post(`/send-sms/`, data);
    return resp.data;
  } catch(err) {
    throw err;
  }
}


async function emailSubmitPasswordReset(data){
  try{
    const resp = await axiosInstance.post(`/password-reset/`,data);
    return resp;
  } catch(err) {
    throw err;
  }
}

async function passwordReset(data){
  try{
    const resp = await axiosInstance.post(`/password-reset/confirm/`,data);
    return resp;
  } catch(err) {
    throw err;
  }
}

async function tokenValidationForReset(data){
  try{
    const resp = await axiosInstance.post(`/password-reset/validate_token/`,data);
    return resp;
  } catch(err) {
    throw err;
  }
}

async function getAssignedHouses(pageNumber, pageSize, search) {
  try {
    const resp = await axiosInstance.get(`/state/${STATE.slug}/houses/assigned/${pageNumber ? `?page=${pageNumber}` : ''}${pageSize ? `&page_size=${pageSize}` : ''}${search != null || search != '' ? `&q=${search}` : ''}`);
    return resp.data;
  } catch (err) {
      console.error(err);
      throw err;
  }
}

async function getHouseDetails(houseId) {
  try {
    const resp = await axiosInstance.get(`/state/${STATE.slug}/houses/${houseId}`);
    return resp.data;
  } catch (err) {
    throw err;
  }
}

async function getLevelOfInfluence() {
  const levelOfInfluence = [
    {id: 1, name: 'State Level'},
    {id: 2, name: 'Constituency Level'},
    {id: 3, name: 'Block Level'},
    {id: 4, name: 'Village Level'}
  ];
  return levelOfInfluence;
}

async function getUserRoles() {
  try {
    const resp = await axiosInstance.get(`/user-creation-roles/`);
    return resp.data;
  } catch(err) {
    throw err;
  }
}

async function postCreateUser(data) {
  try {
    const resp = await axiosInstance.post(`/app-user/`, data);
    return resp.data;
  } catch(err) {
    throw err;
  }
}

async function getUserDetails(id) {
  try {
    const resp = await axiosInstance.get(`/app-user-details/${id}/`);
    return resp.data;
  } catch(err) {
    throw err;
  }
}


async function getPollingStationsForRoles(constituency, block, village, includeAssigned, pageNumber, pageSize) {
  try {
    const resp = await axiosInstance.get(`/state/${STATE.slug}/polling-stations/?include_assigned=${includeAssigned}${constituency ? `&constituency=${constituency}` : ''}${block ? `&block=${block}` : ''}${village ? `&village=${village}` : ''}${pageNumber ? `&page=${pageNumber}` : ''}${pageSize ? `&page_size=${pageSize}` : ''}`);
    return resp.data;
  } catch(err) {
    throw err;
  }
}

async function getBlocksForRoles(constituency, block, village, includeAssigned, pageNumber, pageSize) {
  try {
    const resp = await axiosInstance.get(`/state/${STATE.slug}/blocks/?include_assigned=${includeAssigned}${constituency ? `&constituency=${constituency}` : ''}${block ? `&block=${block}` : ''}${village ? `&village=${village}` : ''}${pageNumber ? `&page=${pageNumber}` : ''}${pageSize ? `&page_size=${pageSize}` : ''}`);
    return resp.data;
  } catch(err) {
    throw err;
  }
}

async function getAssemblyConstituenciesForRoles(includeAssigned, pageNumber, pageSize) {
  try {
    const resp = await axiosInstance.get(`/state/${STATE.slug}/acs/?include_assigned=${includeAssigned}${pageNumber ? `&page=${pageNumber}` : ''}${pageSize ? `&page_size=${pageSize}` : ''}`);
    return resp.data;
  } catch(err) {
    throw err;
  }
}

async function getHousesForRoles(constituency, block, village, pollingStation, includeAssigned, pageNumber, pageSize) {
  try {
    const resp = await axiosInstance.get(`/state/${STATE.slug}/houses-lite/?include_assigned=${includeAssigned}${constituency ? `&constituency=${constituency}` : ''}${block ? `&block=${block}` : ''}${village ? `&village=${village}` : ''}${pollingStation ? `&polling_station=${pollingStation}` : ''}${pageNumber ? `&page=${pageNumber}` : ''}${pageSize ? `&page_size=${pageSize}` : ''}`);
    return resp.data;
  } catch(err) {
    throw err;
  }
}

async function getBirthdaySettings(group, data) {
  try{
    const resp = await axiosInstance.get(`/app-settings-preset/${group}/`);
    return resp.data;
  } catch(err) { 
      throw err;
  }
}
async function getVoterCount(constituency, block, village, pollingStation) {
  try {
    const resp = await axiosInstance.get(`/state/${STATE.slug}/stats/voter-count/?${constituency != null ? `constituency=${constituency}` : ''}${block ? `&block=${block}` : ''}${village ? `&village=${village}` : ''}${pollingStation ? `&polling_station=${pollingStation}` : ''}`);
    return resp.data;
  } catch(err) {
    throw err;
  }
}

async function updateBirthdaySettings(group, data) {
  try{
    const resp = await axiosInstance.put(`/app-settings-preset/${group}/`, data);
    return resp.data;
  } catch(err) { 
      throw err;
  }
}
async function getHousesCount(constituency, block, village, pollingStation) {
  try {
    const resp = await axiosInstance.get(`/state/${STATE.slug}/stats/house-count/?${constituency != null ? `constituency=${constituency}` : ''}${block ? `&block=${block}` : ''}${village ? `&village=${village}` : ''}${pollingStation ? `&polling_station=${pollingStation}` : ''}`);
    return resp.data;
  } catch(err) {
     throw err;
   }
}

async function getCalendarEvent(title) {
  try {
    const resp = await axiosInstance.get(`/calendar-events-by-month/${title}`);
    return resp.data;
  } catch (err) {
      console.error(err);
      throw err;
  }
}

async function addCalendarEvent(data) {
  try {
    const resp = await axiosInstance.post(`/calendar-events/`, data);
    return resp.data;
  } catch (err) {
      console.error(err);
      throw err;
  }
}

async function updateCalendarEvent(id,data) {
  try {
    const resp = await axiosInstance.put(`/calendar-event-update-delete/${id}/`, data);
    return resp.data;
  } catch (err) {
      console.error(err);
      throw err;
  }
}

async function deleteCalendarEvent(id){
  try {
    const resp = await axiosInstance.delete(`/calendar-event-update-delete/${id}/`);
    return resp.data;
  } catch (err) {
      console.error(err);
      throw err;
  }
}

async function getHousesList(constituency, block, village, pollingStation, pageNumber, pageSize) {
  try {
    const resp = await axiosInstance.get(`/state/${STATE.slug}/houses/${pageNumber ? `?page=${pageNumber}` : ''}${pageSize ? `&page_size=${pageSize}` : ''}${constituency ? `&constituency=${constituency}` : ''}${block ? `&block=${block}` : ''}${village ? `&village=${village}` : ''}${pollingStation ? `&${pollingStation}` : ''}`);
    return resp.data;
  } catch(err) {
    throw err;
  }
}

async function getUsers(constituency, block, village, pollingStation, selectedRole) {
  try {
    const resp = await axiosInstance.get(`/app-user/${constituency ? `?constituency=${constituency}` : ''}${block ? `&block=${block}` : ''}${village ? `&village=${village}` : ''}${pollingStation ? `&polling_station=${pollingStation}` : ''}${selectedRole ? `&role=${selectedRole.value}` : ''}`);
    return resp.data;
  } catch(err) {
    throw err;
  }
}

async function putAssignRoles(id, data) {
  try {
    const resp = await axiosInstance.put(`/assigned-roles/${id}/`, data);
    return resp.data;
  } catch(err) {
    throw err;
  }
}

async function getUserAssemblyAssignedEntities(id, pageNumber, pageSize) {
  try {
    const resp = await axiosInstance.get(`/user-ac-assigned-entities/${id}/${pageSize ? `?page_size=${pageSize}` : ''}${pageNumber ? `&page=${pageNumber}` : ''}`);
    return resp.data;
  } catch(err) {
    throw err;
  }
}


async function getUserBlockAssignedEntities(id, pageNumber, pageSize, Constituency) {
  try {
    const resp = await axiosInstance.get(`/user-block-assigned-entities/${id}/${pageSize ? `?page_size=${pageSize}` : ''}${pageNumber ? `&page=${pageNumber}` : ''}${Constituency ? `&constituency=${Constituency}` : ''}`);
    return resp.data;
  } catch(err) {
    throw err;
  }
}

async function getUserPollingStationAssignedEntities(id, pageNumber, pageSize, constituency, block, village) {
  try {
    const resp = await axiosInstance.get(`/user-ps-assigned-entities/${id}/${pageSize ? `?page_size=${pageSize}` : ''}${pageNumber ? `&page=${pageNumber}` : ''}${constituency ? `&constituency=${constituency}` : ''}${block ? `&block=${block}` : ''}${village ? `&village=${village}` : ''}`);
    return resp.data;
  } catch(err) {
    throw err;
  }
}

async function getUserHouseAssignedEntities(id, pageNumber, pageSize, constituency, block, village, pollingStation) {
  try {
    const resp = await axiosInstance.get(`/user-houses-assigned-entities/${id}/${pageSize ? `?page_size=${pageSize}` : ''}${pageNumber ? `&page=${pageNumber}` : ''}${constituency ? `&constituency=${constituency}` : ''}${block ? `&block=${block}` : ''}${village ? `&village=${village}` : ''}${pollingStation ? `&polling_station=${pollingStation}` : ''}`);
    return resp.data;
  } catch(err) {
    throw err;
  }
}

async function putRemoveAssignedRole(id, data) {
  try {
    const resp = await axiosInstance.put(`/user-assigned-roles-remove/${id}/`, data);
    return resp.data;
  } catch(err) {
    throw err;
  }
}

async function getGlobelSettings() {
  try {
    const resp = await axiosInstance.get();
    return resp.data;
  } catch(err) {
    throw err;
  }
}

async function villageProfileStepOne(data) {
  try {
    const resp = await axiosInstance.post(`/village-profile-step-one/`, data);
    return resp.data;
  } catch(err) { 
    throw err;
  }
}

async function villageProfileStepTwo(data) {
  try {
    const resp = await axiosInstance.post(`/village-profile-step-two/`, data);
    return resp.data;
  } catch(err) { 
    throw err;
  }
}

async function villageProfileStepThree(trendone,trendtwo,profile) {
  var data ={}
  data = {
    'TraditionalVotingTrend':trendone,
    'PresentVotingTrend':trendtwo,
    'profileId':profile
  }
  try {
    const resp = await axiosInstance.post(`/village-profile-step-three/`,data);
    return resp.data;
  } catch(err) { 
    throw err;
  }
}

async function villageProfileStepFour(data) {
  try {
    const resp = await axiosInstance.post(`/village-profile-step-four/`, data);
    return resp.data;
  } catch(err) { 
    throw err;
  }
}

async function villageProfileStepFive(data) {
  try {
    const resp = await axiosInstance.post(`/village-profile-step-five/`, data);
    return resp.data;
  } catch(err) { 
    throw err;
  }
}

async function villageProfileStepSix(data) {
  try {
    const resp = await axiosInstance.post(`/village-profile-step-six/`, data);
    return resp.data;
  } catch(err) { 
    throw err;
  }
}

async function villageProfileStepSeven(data) {
  try {
    const resp = await axiosInstance.post(`/village-profile-step-seven/`, data);
    return resp.data;
  } catch(err) { 
    throw err;
  }
}

async function villageProfileStepEight(data) {
  try {
    const resp = await axiosInstance.post(`/village-profile-step-eight/`, data);
    return resp.data;
  } catch(err) { 
    throw err;
  }
}

async function villageProfileStepOneUpdate(id,data) {
  try {
    const resp = await axiosInstance.put(`/village-profile-step-one-update/${id}/`, data);
    return resp.data;
  } catch(err) { 
    throw err;
  }
}


async function villageProfileStepTwoUpdate(id,data) {
  try {
    const resp = await axiosInstance.put(`/village-profile-step-two-update/${id}/`, data);
    return resp.data;
  } catch(err) {
    throw err;
  }
}

async function villageProfileStepThreeUpdate(id,trendone,trendtwo,profile) {
  var data ={}
  data = {
    'TraditionalVotingTrend':trendone,
    'PresentVotingTrend':trendtwo,
    'profileId':profile
  }
  try {
    const resp = await axiosInstance.put(`/village-profile-step-three-update/${id}/`,data);
    return resp.data;
  } catch(err) { 
    throw err;
  }
}

async function villageProfileStepFourUpdate(id,data) {
  try {
    const resp = await axiosInstance.put(`/village-profile-step-four-update/${id}/`, data);
    return resp.data;
  } catch(err) {
    throw err;
  }
}

async function villageProfileStepFiveUpdate(id,data) {
  try {
    const resp = await axiosInstance.put(`/village-profile-step-five-update/${id}/`, data);
    return resp.data;
  } catch(err) {
    throw err;
  }
}

async function villageProfileStepSixUpdate(id,data) {
  try {
    const resp = await axiosInstance.put(`/village-profile-step-six-update/${id}/`, data);
    return resp.data;
  } catch(err) {
    throw err;
  }
}

async function villageProfileStepSevenUpdate(id,data) {
  try {
    const resp = await axiosInstance.put(`/village-profile-step-seven-update/${id}/`, data);
    return resp.data;
  } catch(err) { 
    throw err;
  }
}

async function villageProfileStepEightUpdate(id,data) {
  try {
    const resp = await axiosInstance.put(`/village-profile-step-eight-update/${id}/`, data);
    return resp.data;
  } catch(err) { 
    throw err;
  }
}

async function getDistrictsList() {
  try {
    const resp = await axiosInstance.get(`/state/${STATE.slug}/districtlist/`);
    return resp.data;
  } catch (err) {
      console.error(err);
      throw err;
  }
}

async function getAssemblyConstituencyList(district) {
  try {
    return district_ac_map[district];
  } catch (err) {
      console.error(err);
      throw err;
  }
}

async function getBlockList(constituency) {
  try {
    return ac_block_map[constituency];
  } catch (err) {
      console.error(err);
      throw err;
  }
}

async function getGramPanchayatList(block) {
  try {
    return block_to_gp_map[block];
  } catch (err) {
      console.error(err);
      throw err;
  }
}

async function postMemberShipData(data) {
  try {
    const resp = await axiosInstance.post(`/state/${STATE.slug}/votermembership/`, data);
    return resp.data;
  } catch (err) {
      throw err;
  }
}


async function SendOTP(mobileNumber) {
  try {
    const resp = await axiosInstance.post(`/send-otp/`, mobileNumber);
    return resp.data;
  } catch (err) {
      throw err;
  }
}

async function loginSendOTP(mobileNumber) {
  try {
    const resp = await axiosInstance.post(`/login-send-otp/`, mobileNumber);
    return resp.data;
  } catch (err) {
      console.error(err);
      throw err;
  }
}

async function loginOTPVerification(mobileNumber) {
  try {
    const resp = await axiosInstance.post(`/login/`, mobileNumber);
    return resp.data;
  } catch (err) {
      console.error(err);
      throw err;
  }
}

async function getVerifyOTP(otpVerifyObj) {
  try {
    const resp = await axiosInstance.post(`/verify-otp/`, otpVerifyObj);
    return resp.data;
  } catch (err) {
      console.error(err);
      throw err;
  }
}

async function profielImageUpload(data, callback) {
  try {
    const formData = new FormData();
    for (let i = 0; i < data.length; i++) {
        formData.append('files', data[i]);
    }
    const resp = await axiosInstance.post('/upload/', formData,{
      headers: {
          'Content-Type': 'multipart/form-data'
      },
      onUploadProgress: (progressEvent) => {
        const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
        callback(percentCompleted);
      }
    });
    return resp.data;
    } catch(err) {
        throw err;
    }
}

async function getSelfVoterList() {
  try {
    const resp = await axiosInstance.post(`/`);
    return resp.data;
  } catch (err) {
      console.error(err);
      throw err;
  }
}


async function getSelfDetails() {
  try {
    const resp = await axiosInstance.get(`/my-membership/`);
    return resp.data;
  } catch (err) {
      console.error(err);
      throw err;
  }
}


async function postPrimaryMembership(data) {
  try {
    const resp = await axiosInstance.post(`/primary-member/`, data);
    return resp.data;
  } catch (err) {
      console.error(err);
      throw err;
  }
}

async function getPrimaryMembershipList() {
  try {
    const resp = await axiosInstance.get(`/primary-member/`);
    return resp.data;
  } catch (err) {
      console.error(err);
      throw err;
  }
}

async function getPrimaryMembershipDetails() {
  try {
    const resp = await axiosInstance.get(`/primary-member/`);
    return resp.data;
  } catch (err) {
      console.error(err);
      throw err;
  }
}

export {getGenderStats, getSurveyStats, getOpinionStats, getAgeStats, getReligionStats, 
  getCasteStats, getStateConstituencies, getConstituencies, getBlocks, getVillages, 
  getPollingStationIncharges, getHouseVisitedCount, getCommentsOfVolunteer, getImportantVoters, 
  getVoterData, getUpdateVoterData, getPollingStations, filterFields, getHouseIncharges, 
  getDesignation, getDistricts, getTowns, NewVolunteerFieldsProperties, getReportingTo, 
  getIncharges, getVolunteer, getAllVillages, getDemographicsData, getVillageCategory, 
  getVoterDetails, getVoterSummary, voterFilterFields, getCasteList, getSubCaste, getGenderName, 
  getReligion, getOpinion, getRelationTypeName, updateVoterData, getNameFromList, getVillageProfileList, 
  getVillageProfile, addVoterSurvey, getVoterSurveyList, getVoterSurveyQuestionsList, getVoterSurveyResultByGender, getVoterSurveyResultByAge, postVoterSurveyResponse,
  putVoterSurveyActive, getSearchVoter, getInfluentialPosition, getGovernmentScheme, getUserProfile, postVolunteerComment,
  getSearchUser, getVolunteerComments, psInchargeFields, getPollingStationHouses, getVoterDetailsMobileWise, getUniqueMobileNumbers,
  formFilterFields, getVillageSmsTemplate, addSmsTemplate ,getSmsTemplate,changePassword, getSettings, getSmsSpecificTemplate,
  updateSmsTemplate, deleteSmsTemplate, updateSettings, getSubstitutionOptions, createSendSMS, searchVillage, getAssignedHouses,
  getHouseDetails, emailSubmitPasswordReset, passwordReset, tokenValidationForReset, getLevelOfInfluence, getUserRoles, getUserDetails,
  postCreateUser, getPollingStationsForRoles, getBlocksForRoles, getAssemblyConstituenciesForRoles, getHousesForRoles, 
  getVoterCount, getHousesCount, getHousesList, getUsers, putAssignRoles, getGlobelSettings, deleteCalendarEvent, addCalendarEvent,
  getCalendarEvent, updateCalendarEvent, getBirthdaySettings, updateBirthdaySettings, getUserAssemblyAssignedEntities, 
  putRemoveAssignedRole, getUserBlockAssignedEntities, getUserHouseAssignedEntities, getUserPollingStationAssignedEntities,getCommentsFromRole,
  villageProfileStepOne,villageProfileStepTwo,villageProfileStepThree,villageProfileStepFour,villageProfileStepFive,villageProfileStepSix,
  villageProfileStepSeven,villageProfileStepEight,villageProfileStepOneUpdate,villageProfileStepTwoUpdate,villageProfileStepFourUpdate,villageProfileStepFiveUpdate,
  villageProfileStepSixUpdate,villageProfileStepThreeUpdate,villageProfileStepSevenUpdate,villageProfileStepEightUpdate, getDistrictsList, getAssemblyConstituencyList, getBlockList, getGramPanchayatList, postMemberShipData, SendOTP, getVerifyOTP, profielImageUpload, loginSendOTP, loginOTPVerification, getSelfVoterList, getSelfDetails, postPrimaryMembership, getPrimaryMembershipList, getPrimaryMembershipDetails, mediaURL};

