import React from "react";
import AddNewComment from "./AddNewComment";
import { getVolunteerComments } from '../../services/Voters';
import RotatingLinesLoader from '../RotatingLineLoader';
import Error from '../error';
import Pagination from '../Pagination';

class VolunteerComments extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			commentsList: [],
			error: '',
			loading: false,
			pageSize: 10
		};
		this.reloadComments = this.reloadComments.bind(this);
		this.onChangePage = this.onChangePage.bind(this);
	}

	onChangePage(event) {
		this.reloadComments(event, this.state.pageSize);
	}

	reloadComments(pageNumber, pageSize) {
		this.setState({loading: true});
		getVolunteerComments(pageNumber, pageSize).then((commentsList) => {
			this.setState({commentsList, loading: false});
		}).catch((error) => {
			this.setState({error});
		})
	}

	componentDidMount() {
		this.reloadComments(this.state.pageNumber, this.state.pageSize);
	}

	render() {
		return(
			<>
				<AddNewComment reloadComments={this.reloadComments} />
				<div className="card">
					<div className="card-body">
						<div className="row">
							<div className="col-12 fw-700 fs-5 text-center p-3">Comments</div>
						</div>
							{ this.state.commentsList != '' ? 
							<div className="overflow-x">
								<table className="table table-striped">
									<thead>
										<tr className="bg-primary">
											<th className="w-6 text-center">Sl. No.</th>
											<th>Comments</th>
										</tr>
									</thead>
									<tbody>
									{
										this.state.commentsList?.data?.map((comments, index) => 
											<tr key={comments.id}>
												<td className="text-center align-middle fw-600">{index + 1}</td>
												<td>
													<div>{comments.text}</div>
													<div>{comments.created_on}</div>
												</td>
											</tr>
										)
									}
									</tbody>
								</table>
								<div className="text-center">
									{
										this.state.commentsList?.count >= this.state.pageSize ? 
										<Pagination pageCount={this.state.commentsList?.total_pages} currentPage={this.state.commentsList?.current_page - 1} pageSize={this.state.pageSize} onChangePage={this.onChangePage} /> : ''
									}
								</div>
							</div>
						 : this.state.loading == true ? 
						<RotatingLinesLoader /> :
						this.state.error != '' ? 
							<Error /> :
							<div className="text-center fw-500 fs-6 text-black-50">No comments found</div> 
				}
					</div>
				</div>
			</>
		)
	}
}

export default VolunteerComments;