import {React, useState,useEffect } from 'react';
import Combobox from 'react-widgets/Combobox';
import { getSmsSpecificTemplate, getVillageSmsTemplate, getSubstitutionOptions } from '../../services/Voters';
import {Operations} from '../toast/operations';
import StatusToast from "../toast/StatusToast";
import { getBirthdaySettings,updateBirthdaySettings } from '../../services/Voters';
import { useNavigate} from "react-router-dom";


function ViewBirthdayAlert(props){

	const [templateId, setTemplateId] = useState();
	const [smsTemplate, setSmsTemplate] = useState();
	const [smsTemplates,setSmsTemplates] = useState();
	const [smsVar , setSmsVar] = useState([]);
	const [substitutionOptions ,setSubstitutionOptions] = useState([]);
  	const [variables, setVariables] = useState({});
  	const [defaultSettings, setDefaultSettings] = useState({});
	const [preloadSmsTemplate, setPreloadSmsTemplate] = useState([]);
	const [selectValue, setSelectValue] = useState();

	let navigate = useNavigate();

	useEffect(() =>{

		getBirthdaySettings(props.group).then((settings) => {
			setDefaultSettings(settings);

			if(settings.settings.template_id.value == null || settings.settings.template_id.value == ''){
				return;
			}
			getSmsSpecificTemplate(settings.settings.template_id.value).then((preloadSmsTemplate) => {

				setSelectValue(preloadSmsTemplate.id);
				setPreloadSmsTemplate(preloadSmsTemplate);
				let config = {};
				let obj = settings.settings.config.value;
				config = obj;
				
				setSmsVar(JSON.parse(config));
				}).catch((err) => {
					console.log(err);
				})

            }).catch((error) => {
                console.log(error,'error');
      		})

		getVillageSmsTemplate().then((smsTemplate) => {
			setSmsTemplates(smsTemplate);
    	}).catch((smsError) => {
      		console.log(smsError);
    	})

    	getSubstitutionOptions().then((substitutionOptions) => {
      		setSubstitutionOptions(substitutionOptions);
    	}).catch((err) => {
      		console.log(err);
    	})
	},[]);

	const handleSmsTemplate = (event) => {

	    let str = event.message;
	    let regex = /\{\#[a-zA-Z0-9]+\#\}/g;
	    let result = str.match(regex);

	    setTemplateId(event.id);
	    setSmsTemplate(event);
		let vars = {};
		result.forEach((variable) => {
			vars[variable] = "";
		}); 
	    setSmsVar(vars);
  	}

  	const handleVaribleValue = (obj, variableId) => {
    	setVariables({...variables, [variableId]: obj.id});
  	}

  	const handleSave = () => {

			let settings = defaultSettings;
			settings.settings.config.value = JSON.stringify(variables);
			settings.settings.template_id.value = templateId;

		updateBirthdaySettings(props.group, settings).then((updateStatus) => {
				getBirthdaySettings(props.group).then((settings) => {
            	setDefaultSettings({...settings});
				navigate(`/view-birthday-alert?op=${Operations.BIRTHDAY_SMS_TEMPLATE}&status=success`);
            }).catch((error) => {
                console.log(error,'error');
            })
        }).catch((updateError) => {
            console.log(updateError ,'update error');
        });

  	}

	const handleSelectBox = (event) =>{
		setSelectValue(event.target.value);
		getSmsSpecificTemplate(event.target.value).then((preloadSmsTemplate) => {
		setPreloadSmsTemplate(preloadSmsTemplate);
		let str = preloadSmsTemplate.message;
	    let regex = /\{\#[a-zA-Z0-9]+\#\}/g;
	    let result = str.match(regex);

	    setTemplateId(preloadSmsTemplate.id);
	    setSmsTemplate(preloadSmsTemplate);
		let vars = {};
		result.forEach((variable) => {
			vars[variable] = "";
		}); 
	    setSmsVar(vars);
			
		}).catch((error) => {
			console.log(error,'error');
		})
	}

	return(
		<>
			<StatusToast status='Voter Survey' delayTime="3000"/>
			<div className="col-md-8 col-lg-8 col-sm-12 m-auto">
				<div className='card mt-4'>
					<div className="card-header">
						<h4 className='d-inline'>Birthday Alert Template</h4>
					</div>
					<div className='card-body'>
					<div className='me-2'>

					<select className='form-select' onSelect={handleSmsTemplate} onChange={handleSelectBox} value={selectValue} defaultValue={{ label: "Select Dept", value: 0 }}>
                            <option value=''>Select Template</option>
                            {smsTemplates?.map(item => 
                              <option key={item.id} value={item.id}>{item.template_id ? item.template_id : ''}</option>
                            )}
                    </select>
					<textarea className='form-control mt-2' name="meassage" id="meassage" value={preloadSmsTemplate != '' ? preloadSmsTemplate?.message : smsTemplate?.message} rows="5" disabled></textarea>
					{
                        smsTemplate != null || preloadSmsTemplate != null ? 
                        <>
                          <div className='me-auto'>
                          {
                            smsVar != null || smsVar != "" ? 
                            <table className='table table-borderless'>
                              <thead>
                                <tr className='text-center'>
                                  <th>Variable</th>
                                  <th>Substitute With</th>
                                </tr>
                              </thead>
                              <tbody>
                              {
                               	Object.keys(smsVar).map((item_name) => {
									let value = smsVar[item_name];
									return <tr key={item_name}>
									<td>{item_name}:</td>
									<td>
									  <Combobox placeholder={`Select Variable.`} className='ms-2' data={substitutionOptions} options={substitutionOptions} dataKey='id' textField='name' onSelect={(event) => handleVaribleValue(event, item_name)}  defaultValue={value}/>
									</td>
								  </tr>;
								})
                              }
                              </tbody>
                            </table> : ''
                          }
                          </div>
                          <button className="btn btn-success" onClick={handleSave}>Save</button>
                        </> : ''
          			}
					</div>
				</div>
			</div>
		</div>
		</>
	);

}
export default ViewBirthdayAlert;