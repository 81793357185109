import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus, faMinus } from "@fortawesome/free-solid-svg-icons";

class UpdatePerceptionAnalysis extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      traditionalVotingArray: this.props.traditionalVotingList,
      presentVotingArray: this.props.presentVotingList,
      traditionalVotingTrendError: '',
      presentVotingTrendError: ''
    };
    this.onClickTraditionalVoting = this.onClickTraditionalVoting.bind(this);
    this.traditionalVotingTrend = React.createRef();
    this.removeTraditionalVoting = this.removeTraditionalVoting.bind(this);
    this.onClickPresentVotingTrend = this.onClickPresentVotingTrend.bind(this);
    this.presentVotingTrend = React.createRef();
    this.removePresentVotingTrend = this.removePresentVotingTrend.bind(this);
    this.handleTraditionalVotingTrend = this.handleTraditionalVotingTrend.bind(this);
    this.handlePresentVotingTrend = this.handlePresentVotingTrend.bind(this);
    this.handleOnChangeForTraditionalVotingTable = this.handleOnChangeForTraditionalVotingTable.bind(this);
    this.handleOnChangeForPresentVotingTable = this.handleOnChangeForPresentVotingTable.bind(this);
  }

  handleTraditionalVotingTrend() {
    this.setState({traditionalVotingTrendError: ''});
  }

  handleOnChangeForTraditionalVotingTable(event){
    this.state.traditionalVotingArray[event.target.dataset.index][event.target.name] = event.target.value;
    let traditionalVotingArray = this.state.traditionalVotingArray;
    this.props.traditionalVoting(traditionalVotingArray);
    this.setState({traditionalVotingArray:traditionalVotingArray});
  }

  handleOnChangeForPresentVotingTable(event){
    this.state.presentVotingArray[event.target.dataset.index][event.target.name] = event.target.value;
    let presentVotingArray = this.state.presentVotingArray;
    this.props.presentVotingArray(presentVotingArray);
    this.setState({presentVotingArray:presentVotingArray});
  }

  handlePresentVotingTrend() {
    this.setState({presentVotingTrendError: ''});
  }

  onClickTraditionalVoting() {
    let traditionalVotingTrend = this.traditionalVotingTrend.current.value;
    traditionalVotingTrend && this.setState({traditionalVotingTrendError: ''});
    if(traditionalVotingTrend == '') {
      this.setState({traditionalVotingTrendError: 'Please enter traditional voting trend.'});
    }
    else {
      var traditionalVotingObject = {}
      traditionalVotingObject.traditionalvotingtrend = traditionalVotingTrend;
      let traditionalVotingArray = this.state.traditionalVotingArray;
      traditionalVotingArray = [ ...traditionalVotingArray, traditionalVotingObject ];
      this.traditionalVotingTrend.current.value = '';
      this.props.traditionalVoting(traditionalVotingArray);
      this.setState({ traditionalVotingArray });
    }
  }

  removeTraditionalVoting(event) {
    const traditionalVotingArray = this.state.traditionalVotingArray;
    let newTraditionalVotingArray = [];
    for(let i=0;i<traditionalVotingArray.length;i++) {
      if(i !== event.index) {
        newTraditionalVotingArray = [ ...newTraditionalVotingArray, traditionalVotingArray[i] ];
      }
    }
    this.props.traditionalVoting(newTraditionalVotingArray);
    this.setState({ traditionalVotingArray: newTraditionalVotingArray });
  }

  onClickPresentVotingTrend() {
    let presentVotingTrend = this.presentVotingTrend.current.value;
    if(presentVotingTrend == '') {
      this.setState({presentVotingTrendError: 'Please enter present voting trend.'});
    }
    else {
      var presentVotingObject = {}
      presentVotingObject.presentvotingtrend = presentVotingTrend;
      let presentVotingArray = this.state.presentVotingArray;
      presentVotingArray = [ ...presentVotingArray, presentVotingObject ];
      this.presentVotingTrend.current.value = '';
      this.props.presentVotingArray(presentVotingArray);
      this.setState({ presentVotingArray });
    }
  }

  removePresentVotingTrend(event) {
    const presentVotingArray = this.state.presentVotingArray;
    let newPresentVotingArray = [];
    for(let i=0;i<presentVotingArray.length;i++) {
      if(i !== event.index) {
        newPresentVotingArray = [ ...newPresentVotingArray, presentVotingArray[i] ];
      }
    }
    this.props.presentVotingArray(newPresentVotingArray);
    this.setState({ presentVotingArray: newPresentVotingArray });
  }

  componentDidUpdate(prevProps) {
    if(this.props.updateInfo != prevProps?.updateInfo){
      let traditionalVotingArray = [];
      this.props?.updateInfo?.map((traditionalVoting) => 
        traditionalVotingArray = [ ...traditionalVotingArray, traditionalVoting ]
      );
      this.setState({ traditionalVotingArray: traditionalVotingArray});
      this.props.traditionalVoting(traditionalVotingArray);

      let presentVotingArray = [];
      this.props?.updateInfo?.map((presentVoting) => 
      presentVotingArray = [ ...presentVotingArray, presentVoting ]
      );
      this.setState({ presentVotingArray: presentVotingArray});
      this.props.presentVotingArray(presentVotingArray);
    }
  }

  render() {
    return(
      <table className="table">
        <thead>
          <tr>
            <th colSpan={3} className="text-center bg-dark bg-opacity-50">Perception Analysis</th>
          </tr>
        </thead>
        <tbody>
          <tr className="bg-dark bg-opacity-10 text-dark">
            <td className="text-center fw-700 w-5 align-middle">Sl. No.</td>
            <td colSpan={3} className="text-center fw-700 align-middle">Traditional voting trend in the village</td>
          </tr>
          <tr>
            <td className="text-center" scope="row" data-label=""></td>
            <td scope="row" data-label="Religion">
              <textarea className="form-control" id="traditionalvotingtrend" name="traditionalvotingtrend" placeholder="Traditional Voting Trend in the village" ref={ this.traditionalVotingTrend } ></textarea>
              <span className="text-danger fw-600">{this.state.traditionalVotingTrendError && this.state.traditionalVotingTrendError}</span>
            </td>
            <td className="align-middle text-center w-5"><FontAwesomeIcon icon={ faPlus } className="btn btn-success" onClick={ this.onClickTraditionalVoting } /></td>
          </tr>
          {
           this.state.traditionalVotingArray?.map((traditionalVoting, index) => 
            <tr key={ index }>
              <td className="text-center align-middle">{ index +1 }</td>
              <td>
                <textarea data-index={index} className="form-control" id={`traditionalvotingtrend-${index}-${this.state.traditionalVotingArray.length}`} key={`traditionalvotingtrend-${index}-${this.state.traditionalVotingArray.length}`} name='traditionalvotingtrend' placeholder="Traditional Voting Trend in the village" defaultValue={traditionalVoting?.text} onChange={this.handleOnChangeForTraditionalVotingTable} ></textarea>
              </td>
              <td className="text-center align-middle">
                <FontAwesomeIcon icon={ faMinus } className="btn btn-danger" onClick={ () => this.removeTraditionalVoting({ index }) } />
              </td>
            </tr>
            )
          }
          <tr className="bg-dark bg-opacity-10 text-dark">
            <td className="text-center align-middle w-5 fw-700 align-middle">Sl. No.</td>
            <td colSpan={3} className="text-center fw-700 align-middle">Present voting trend in the village</td>
          </tr>
          <tr>
            <td></td>
            <td>
              <textarea className="form-control" id="presentvotingtrend" name="presentvotingtrend" placeholder="Present Voting Trend in the village" ref={this.presentVotingTrend} onChange={this.handlePresentVotingTrend}></textarea>
              <span className="text-danger fw-600">{this.state.presentVotingTrendError && this.state.presentVotingTrendError}</span>
            </td>
            <td className="align-middle text-center w-5"><FontAwesomeIcon icon={ faPlus } className="btn btn-success" onClick={ this.onClickPresentVotingTrend } /></td>
          </tr>
          {
            this.state.presentVotingArray?.map((presentVoting, index) => 
            <tr key={ index }>
              <td className="text-center align-middle">{ index +1 }</td>
              <td>
                <textarea data-index={index} className="form-control" id={`presentvotingtrend-${index}-${this.state.presentVotingArray.length}`} key={`presentvotingtrend-${index}-${this.state.presentVotingArray.length}`} name="presentvotingtrend" placeholder="Present Voting Trend in the village" defaultValue={ presentVoting?.text } onChange={this.handleOnChangeForPresentVotingTable}></textarea>
              </td>
              <td className="text-center align-middle">
                <FontAwesomeIcon icon={ faMinus } onClick={ () => this.removePresentVotingTrend({ index }) } className="btn btn-danger" />
              </td>
            </tr>
            )
          }
        </tbody>
      </table>
    )
  }
}

export default UpdatePerceptionAnalysis;