import React from 'react';
import '../assets/css/Sidebar-Style.css';

class Footer extends React.Component {
  render() {
    return(
      <footer className="main-footer ms-0">
        <div className="float-right d-none d-sm-block">
          <b>Version</b> 1.0.0
        </div>
        <strong>Copyright © 2024 <a href="https://joinbjd.in/">Join BJD</a>.</strong> All rights reserved.
      </footer>
    );
  }
}

export default Footer;