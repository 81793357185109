import React from "react";
import '../../assets/css/HouseIncharges-style.css'; 
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUser, faLocationDot, faCloudDownloadAlt } from "@fortawesome/free-solid-svg-icons";
import Error from '../error';
import RotatingLinesLoader from '../RotatingLineLoader';
import Pagination from '../Pagination';
import Tooltip from 'rc-tooltip';
import 'rc-tooltip/assets/bootstrap_white.css';
import { Link } from 'react-router-dom';


class InchargesCards extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      error: '',
    }
    this.onChangePage = this.onChangePage.bind(this);
  }

  onChangePage(event) {
    this.props.onChangeHousePage(event);
  }

  render() {
    return(
      <>
        {
          this.props.houses != null && this.props.houses?.data?.length != 0 ?
          <div>
          <div className="row pt-5">
          {this.props.houses?.data.map(record => 
            <div className="col-md-4" key={ `${record.length}-${record?.house.id}` }>
              <div className="card rounded-rem-1 house-incharge-card">
                <div className="card-header border border-0 bg-white rounded-top-rem-1">
                  <h5>
                    <Link to={`/house-view/houses/${record?.house.id}`}>{ record?.house?.house_no }{ record?.house?.society ? ` - ${record?.house?.society}` : '' }</Link>
                  </h5>
                  <p><span className={`${record?.house?.incharge?.name != null ? 'text-success' : 'text-danger'} pe-2`}><FontAwesomeIcon icon={ faUser } /></span> { record?.house?.incharge?.name != null ? record?.house?.incharge?.name : 'Not Assigned' }</p>
                  <p><span className="text-success pe-2"><FontAwesomeIcon icon={ faLocationDot } /></span> { record?.house.polling_station?.part_number } - { record?.house?.polling_station?.name }, { record?.house?.village } </p>
                </div>
                <div className="card-body">
                  <p>Voters in this house</p>
                  <div className="overflow-x">
                    <ul className="list-group list-group-horizontal ps-3">
                    {
                      record?.voters?.map(voter => 
                        <Tooltip
                        placement="top"
                        overlayClassName={`house-voters`}
                        overlay={`${voter?.first_name != null ? voter?.first_name : '' } ${voter?.last_name != null ? voter?.last_name : ''} ( ${voter?.gender} )`}
                        key={voter.id_card_number}>
                        <li className="list-group-item rounded-circle bg-success border border-white house-incharge-card-voter ms-nagative-10" >{voter?.first_name ? voter?.first_name[0] : ''}</li>
                      </Tooltip>
                      )
                    }
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          )}
          </div>
          <div className="text-center">
            <Pagination pageCount={ this.props.houses?.total_pages } currentPage={this.props.houses?.current_page - 1} pageSize={this.props.pageSize} onChangePage={ this.onChangePage } />
          </div>
          </div>
           : this.props.housesLoader == true ? <RotatingLinesLoader /> : this.state.error != '' ? 
              <Error /> : <div className="text-center fw-600 text-dark-50 p-5">No data found</div>
        }
      </>
    )
  }
}

export default InchargesCards;