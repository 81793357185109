import React from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { Link } from "react-router-dom";

class DashboardMembershipNoData extends React.Component {
    constructor(props) {
        super(props);
        this.state = {

        };
    }

    render() {
        return(
            <>
                <div className="text-center m-auto">
                    <p>Please Add Members here.</p>
                    <Link to={`/primarymembershipform`} className="btn rounded-pill border shadow brand-header-color text-white icon-color-hover"><FontAwesomeIcon icon={ faPlus } className='fs-6 text-white' /> Add Primary Member</Link>
                </div>
            </>
        )
    }
}

export default DashboardMembershipNoData;