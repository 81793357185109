const STATE = {
    name: 'Odisha',
    slug: 'odisha'
}

const CONSTITUENCY = {id: 86, name: 'Munugode'};

class Roles {
    static get SUPER_ADMIN() { return {name: 'Super Admin', value: 'SUPER_ADMIN'}; };
    static get STATE_INCHARGE() { return {name: 'State Incharge', value: 'STATE_INCHARGE'}; };
    static get AC_INCHARGE() { return {name: 'Assembly Constituency Incharge', value: 'AC_INCHARGE'}; };
    static get PC_INCHARGE() { return {name: 'Parliament Constituency Incharge', value: 'PC_INCHARGE'}; };
    static get BLOCK_INCHARGE() { return {name: 'Block Incharge', value: 'BLOCK_INCHARGE'}; };
    static get POLLING_STATION_INCHARGE() { return {name: 'Polling Station Incharge', value: 'POLLING_STATION_INCHARGE'}; };
    static get HOUSE_INCHARGE() { return {name: 'House Incharge', value: 'HOUSE_INCHARGE'}; };

    static get(value) {
        switch(value) {
            case 'SUPER_ADMIN': return this.SUPER_ADMIN;
            case 'STATE_INCHARGE': return this.STATE_INCHARGE;
            case 'AC_INCHARGE': return this.AC_INCHARGE;
            case 'PC_INCHARGE': return this.PC_INCHARGE;
            case 'BLOCK_INCHARGE': return this.BLOCK_INCHARGE;
            case 'POLLING_STATION_INCHARGE': return this.POLLING_STATION_INCHARGE;
            case 'HOUSE_INCHARGE': return this.HOUSE_INCHARGE;
            default: return null;
        }
    }
};

const BRANDING_TITLE = 'JOIN BJD';

const BRANDING_HEADER_TITLE = 'BJD Digital Membership';

const BRANDING_INNER_HEADER_TITLE = 'Active Member Dashboard';

export {
    STATE,
    Roles,
    CONSTITUENCY,
    BRANDING_TITLE,
    BRANDING_HEADER_TITLE,
    BRANDING_INNER_HEADER_TITLE
};