import React, { createRef } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus, faMinus } from "@fortawesome/free-solid-svg-icons";
import { getNameFromList } from '../../services/Voters';
import Multiselect from "react-widgets/Multiselect";

class UpdateCasteAnalysis extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      casteArray: this.props.casteAnalysisList,
      casteNameError: '',
      voterpercentageError: '',
      topLeadersError: '',
      religionError: '',
      politicalPartiesError: '',
      politicalParties: null
    };
    this.onClickCasteAnalysis = this.onClickCasteAnalysis.bind(this);
    this.casteName = React.createRef();
    this.voterPercentage = React.createRef();
    this.topLeaders = React.createRef();
    this.religion = React.createRef();
    this.removeCasteAnalysis = this.removeCasteAnalysis.bind(this);
    this.handleCaste = this.handleCaste.bind(this);
    this.handleVoterPercentage = this.handleVoterPercentage.bind(this);
    this.handleTopLeaders = this.handleTopLeaders.bind(this);
    this.handleReligion = this.handleReligion.bind(this);
    this.handlePoliticalParties = this.handlePoliticalParties.bind(this);
    this.handleOnChangeForTable = this.handleOnChangeForTable.bind(this);
    this.handleSelectboxUpdate = this.handleSelectboxUpdate.bind(this);
  }


  handlePoliticalParties(event, value) {
    var casteArray = this.state.casteArray;
    let newArray = []; 
    casteArray[event]['present_inclination'] = value;
    this.setState({politicalPartiesError: '', politicalParties: newArray});
  }

  handleCaste() {
    this.setState({casteNameError: ''});
  }

  handleVoterPercentage() {
    this.setState({voterpercentageError: ''});
  }

  handleTopLeaders() {
    this.setState({topLeadersError: ''});
  }

  handleReligion() {
    this.setState({religionError: ''});
  }

  handleOnChangeForTable(event){
    this.state.casteArray[event.target.dataset.index][event.target.name] = event.target.value;
    let casteArray = this.state.casteArray;
    this.props.casteAnalysis(casteArray);
    this.setState({casteArray:casteArray});
  }

  handleSelectboxUpdate(event) {
    var religion = {}
    var casteName = {}
    religion.id = event.target.value;
    casteName.id = event.target.value;
    if(event.target.name == 'religion'){
      this.state.casteArray[event.target.dataset.index][event.target.name] = religion;
    }
    if(event.target.name == 'casteName'){
      this.state.casteArray[event.target.dataset.index][event.target.name] = casteName;
    }
    let casteArray = this.state.casteArray;
    this.props.casteAnalysis(casteArray);
    this.setState({casteArray:casteArray});
  }

  onClickCasteAnalysis() {
    let religion = this.religion.current.value;
    let casteName = this.casteName.current.value;
    let voterPercentage = this.voterPercentage.current.value;
    let politicalParties = this.state.politicalParties;
    let topLeaders = this.topLeaders.current.value;
    if(religion == '') {
      this.setState({religionError: 'Please select religion.'});
    }
    if(casteName == '') {
      this.setState({casteNameError: 'Please select caste.'});
    }
    if(voterPercentage == '') {
      this.setState({voterpercentageError: 'Please enter voter percentage.'});
    }
    if(politicalParties == null || politicalParties?.length == 0) {
      this.setState({politicalPartiesError: 'Please select political party.'});
    }
    if(topLeaders == '') {
      this.setState({topLeadersError: 'Please enter top leaders.'});
    }
    if(religion != '' && casteName != '' && voterPercentage != '' && politicalParties != null && politicalParties?.length != 0 && topLeaders != '') {
      let casteObject = {'religion': religion, 'casteName': casteName, 'voter_percentage': voterPercentage, 'politicalParties': politicalParties,  'top_leaders': topLeaders, 'profileId':this.props.profileId };
      let casteArray = this.state.casteArray;
      casteArray = [ ...casteArray, casteObject ];
      this.religion.current.value = '';
      this.casteName.current.value = '';
      this.voterPercentage.current.value = '';
      this.topLeaders.current.value = '';
      this.props.casteAnalysis(casteArray);
      this.setState({ casteArray, politicalParties: null });
    }
  }

  removeCasteAnalysis(event) {
    const casteArray = this.state.casteArray;
    let newCasteArray = [];
    for(let i=0;i<casteArray.length;i++) {
      if(i !== event.index) {
        newCasteArray = [ ...newCasteArray, casteArray[i] ];
      }
    }
    this.props.casteAnalysis(newCasteArray);
    this.setState({ casteArray: newCasteArray });
  }

  componentDidUpdate(prevProps) {
    if(this.props.updateInfo != prevProps?.updateInfo){
      let casteArray = [];
      this.props?.updateInfo?.map((casteAnalysis) => 
      casteArray = [ ...casteArray, casteAnalysis ]
      );
      this.setState({ casteArray: casteArray});
      this.props.casteAnalysis(casteArray);
    }
  }

  render() {
   return(
      <table className="table custom-table">
        <thead> 
          <tr>
            <th colSpan={8} className="text-center bg-dark bg-opacity-50 custom-table-th-desktop">Caste Analysis</th>
            <th className="text-center bg-dark bg-opacity-50 custom-table-th-mobile">Caste Analysis</th>
          </tr>
          <tr className="custom-table-tr-desktop">
            <th className="text-center align-middle w-5 d-xs-none" scope="col">Sl. No</th>
            <th scope="col" className="align-middle">Religion</th>
            <th scope="col" className="align-middle">Caste</th>
            <th scope="col" className="align-middle">% of voters</th>
            <th scope="col" className="align-middle">Political Parties</th>
            <th scope="col" className="align-middle">Top Leaders</th>
            <th className="text-center align-middle" scope="col">Action</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td scope="row" data-label="Religion"></td>
            <td>
            <select name="religionName" id="ReligionName" ref={this.religion} className='form-select' onChange={this.handleReligion} >
            <option value=''>Select Religion</option>
            {this.props.religionList?.map(religion => <option value={religion.id} key={religion.id}>{religion.name}</option>)}
            </select>
            <span className="text-danger fw-600">{this.state.religionError && this.state.religionError}</span>
            </td>
            <td data-label="Caste">
            <select name="casteName" id="CasteName" ref={this.casteName} className='form-select' onChange={this.handleCaste} >
            <option value=''>Select Caste</option>
            {this.props.subCasteList?.map(caste => <option value={caste.id} key={caste.id}>{caste.name}</option>)}
            </select>
            <span className="text-danger fw-600">{this.state.casteNameError && this.state.casteNameError}</span>
            </td>
            <td data-label="% of voters">
              <input className="form-control" type="number" id="voter_percentage" name="voter_percentage" placeholder="% of voter" ref={ this.voterPercentage } onChange={this.handleVoterPercentage} />
              <span className="text-danger fw-600">{this.state.voterpercentageError && this.state.voterpercentageError}</span>
            </td>
            <td data-label="Political Parties">
                <Multiselect data={this.props.opinionList ? this.props.opinionList : []} id="political_parties" name="political_parties" dataKey='id' textField="name" placeholder="Political Parties" onChange={this.handlePoliticalParties} value={this.state.politicalParties != null ? this.state.politicalParties : []} />
                <span className="text-danger fw-600">{this.state.politicalPartiesError && this.state.politicalPartiesError}</span>
            </td>
            <td data-label="Top Leaders">
              <input className="form-control" type="text" id="topleaders" name="topleaders" placeholder="Top Leaders" ref={ this.topLeaders } onChange={this.handleTopLeaders} />
              <span className="text-danger fw-600">{this.state.topLeadersError && this.state.topLeadersError}</span>
            </td>
            <td className="text-center align-middle"><FontAwesomeIcon icon={ faPlus } onClick={ this.onClickCasteAnalysis } className="btn btn-success" /></td>
          </tr>
          {
            this.state.casteArray?.map((caste, index) => 
              <tr key={ index }>
                <td className="text-center align-middle" scope="row" data-label="Sl. No.">{ index +1 }</td>
                <td data-label="Religion">
                  <select data-index={index} name="religion" id={`religion-${index}-${this.state.casteArray?.length}`} key={`religion-${index}-${this.state.casteArray?.length}`} ref={this.religion} className='form-select' onChange={this.handleSelectboxUpdate} value={caste.religion.id} >
                    <option value=''>Select Religion</option>
                    {this.props.religionList?.map(religion => <option value={religion.id} key={religion.id}>{religion.name}</option>)}
                  </select>
              </td>
              <td data-label="Caste">
                {/* <input className="form-control" type="text" id={`castename-${index}-${this.state.casteArray.length}`} key={`castename-${index}-${this.state.casteArray.length}`} name={`castename${index}`} placeholder="Caste Name" defaultValue={ getNameFromList(this.props.subCasteList, caste.casteName) } readOnly/> */}
                <select data-index={index} name="casteName" id={`castename-${index}-${this.state.casteArray?.length}`} key={`castename-${index}-${this.state.casteArray?.length}`} ref={this.casteName} className='form-select' onChange={this.handleSelectboxUpdate} value={caste.caste.id}>
                  <option value=''>Select Caste</option>
                  {this.props.subCasteList?.map(caste => <option value={caste.id} key={caste.id}>{caste.name}</option>)}
                </select>
              </td>
              <td data-label="% of voters">
                <input data-index={index} className="form-control" type="number" id={`voter_percentage-${index}-${this.state.casteArray?.length}`} key={`voterPercentage${index}-${this.state.casteArray?.length}`} name="voter_percentage" placeholder="% of voter" defaultValue={ caste.voter_percentage } onChange={this.handleOnChangeForTable} />
              </td>
              <td data-label="Political Parties">
                <Multiselect data-index={index} data={this.props.opinionList ? this.props.opinionList : []} id={`political_parties-${index}-${this.state.casteArray?.length}`} name="political_parties" dataKey='id' textField="name" placeholder="Political Parties" onChange={(value) => {this.handlePoliticalParties(index, value)}} value={caste.present_inclination != null ? caste.present_inclination : []} />
              </td>
              <td data-label="Top Leaders">
                <input data-index={index} className="form-control" type="text" id={`topleaders-${index}-${this.state.casteArray.length}`} key={`topleaders-${index}-${this.state.casteArray.length}`} name="top_leaders" placeholder="Top Leaders" defaultValue={ caste.top_leaders } onChange={this.handleOnChangeForTable} />
              </td>
              <td className="text-center align-middle">
                <FontAwesomeIcon icon={ faMinus } className="btn btn-danger" onClick={ () => this.removeCasteAnalysis({ index }) } />
              </td>
              </tr>
            )
          }
        </tbody>
      </table>
    )
  }
}

export default UpdateCasteAnalysis;