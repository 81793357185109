import React from "react";
import { getVoterSurveyQuestionsList, postVoterSurveyResponse,getSearchVoter,getGenderName } from '../../services/Voters';
import withParams from '../GetParams';
import { RotatingLines } from  'react-loader-spinner';
import Error from '../error';
import {Operations} from '../toast/operations';
import Combobox from "react-widgets/Combobox";

class VoterSurveySubmit extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			voterSurvey: [],
			error: '',
			response: {},
			responseStatus: '',
			err: '',
			voterList: [],
			voterId: '',
			voterError: null,
			voterDetails:[],
			check: 'table-row',
			voterIdComboBox:''
		};
		this.handleSubmit = this.handleSubmit.bind(this);
		this.handleVillage = this.handleVillage.bind(this);
		this.handleSelectVillage = this.handleSelectVillage.bind(this);
		this.handleClearVoter = this.handleClearVoter.bind(this);
		this.handleCheckBox = this.handleCheckBox.bind(this);
	}

	handleClearVoter(event) {
		if(event.key == 'Backspace') {
			this.setState({voterDetails: []});
		}
	}

	componentDidMount() {
		let { id } = this.props.params;
		getVoterSurveyQuestionsList(id).then((voterSurvey) => {
			let response = {};
			voterSurvey.survey.questions.forEach(question => {
				response[question.id] = question.options[0].id;	
			});
			this.setState({voterSurvey, response});
		}).catch((error) => {
			this.setState({error});
		})
	}

	handleResponses(questionId, optionId) {
		let resp = this.state.response;
		resp[questionId] = optionId;
		this.setState({response: resp});
	}

	handleSubmit() {

		if(this.state.check == 'table-row'){

			if(this.state.voterDetails.id == '' || this.state.voterDetails.length == 0) {
				this.setState({voterError: 'Please select voter.'});
				window.scrollTo({top: 0, behavior: 'smooth'});
			}
			else
			{
				let response = [];
				Object.keys(this.state.response).forEach((key, value) => {
					response.push({question: key, option: this.state.response[key]});
				});
				postVoterSurveyResponse(this.state.voterSurvey.survey.id, {voter: this.state.voterDetails.id, response}).then((responseStatus) => {
					this.props.navigate(`/voter-survey-list?op=${Operations.SUBMIT_SURVEY_RESPONSE}&status=success`);
				}).catch((err) => {
					this.props.navigate(`/voter-survey-list?op=${Operations.SUBMIT_SURVEY_RESPONSE}&status=error`);
				});
			}
		}
		else{
			let response = [];
			Object.keys(this.state.response).forEach((key, value) => {
				response.push({question: key, option: this.state.response[key]});
			});
			postVoterSurveyResponse(this.state.voterSurvey.survey.id, {voter: this.state.voterDetails.id, response}).then((responseStatus) => {
				this.props.navigate(`/voter-survey-list?op=${Operations.SUBMIT_SURVEY_RESPONSE}&status=success`);
			}).catch((err) => {
				this.props.navigate(`/voter-survey-list?op=${Operations.SUBMIT_SURVEY_RESPONSE}&status=error`);
			});
		}	
	}

	handleVillage(event) {
		this.setState({voterIdComboBox:event});
		let eventValue = '';
	    if(typeof event === 'object' && event !== null) {
	      // eventValue = event.id_card_number;
	    }
	    else {
	      eventValue = event;
	    }
	    getSearchVoter(eventValue).then((voterList) => {
	      this.setState({voterList});
	    }).catch((err) => {
	      throw err;
	    })
	}

	handleSelectVillage(event) {
    	this.setState({voterDetails: event, voterError: null});
		this.setState({voterIdComboBox:event.id_card_number});
  	}

	handleCheckBox(event){
		if(event.target.checked == true){
			this.setState({check:'none',voterDetails:[],voterIdComboBox:''});
		}
		else{
			this.setState({check:'table-row'});
		}
	}

	render() {
		return(
			<div className="card">
				<div className="card-body">
					{
						this.state.voterSurvey != '' ?
						<div>
							<div className="text-center fw-700 fs-5 py-3">Voter Survey</div>
							<input type="checkbox" id="topping" name="topping" value="Paneer" onChange={this.handleCheckBox} />
							<label>&nbsp;Anonymous</label>
							<table className="table">
								<tbody>
									<tr style={{display:this.state.check}}>
										<th>Voter</th>
										<td>
											<Combobox data={this.state.voterList} options={this.state.voterList} dataKey='id' textField='id_card_number' onChange={this.handleVillage} onKeyDown={this.handleClearVoter} onSelect={this.handleSelectVillage} autoFocus value={this.state.voterIdComboBox} />
											<span className="text-danger fw-600">{this.state.voterError && this.state.voterError}</span>
										</td>
									</tr>

									<tr style={{display:this.state.check}}>
										<th className="w-50">Name</th>
										<td>{this.state.voterDetails?.first_name} {this.state.voterDetails?.last_name}</td>
									</tr>
									<tr style={{display:this.state.check}}>
										<th className="w-50">Mobile Number</th>
										<td>{this.state.voterDetails?.mobile_number}</td>
									</tr>
									<tr style={{display:this.state.check}}>
										<th className="w-50">Email</th>
										<td>{this.state.voterDetails?.email_address }</td>
									</tr>
									<tr style={{display:this.state.check}}>
										<th className="w-50">Gender</th>
										<td>{getGenderName(this.state.voterDetails?.gender)}</td>
									</tr>

									<tr style={{display:this.state.check}}>
										<th className="w-50">Age</th>
										<td>{this.state.voterDetails?.age}</td>
									</tr>
									<tr style={{display:this.state.check}}>
										<th className="w-50">Constituency</th>
										<td>{this.state.voterDetails?.polling_station?.village?.block?.constituency?.name}</td>
									</tr>
									<tr style={{display:this.state.check}}>
										<th className="w-50">village</th>
										<td>{this.state.voterDetails?.polling_station?.village?.name}</td>
									</tr>
									<tr style={{display:this.state.check}}>
										<th className="w-50">district</th>
										<td>{this.state.voterDetails?.polling_station?.village?.block?.district?.name}</td>
									</tr>
									<tr style={{display:this.state.check}}>
										<th className="w-50">Polling Station</th>
										<td>{this.state.voterDetails?.polling_station?.name}</td>
									</tr>


									<tr>
										<th className="w-50">Survey Name</th>
										<td>{this.state.voterSurvey.survey?.name}</td>
									</tr>
									<tr>
										<th>Created on</th>
										<td>{this.state.voterSurvey.survey?.created_on}</td>
									</tr>
								</tbody>
							</table>
							<div className="container-qa mt-sm-5 my-1">
								<div className="question ml-sm-5 pl-sm-5 pt-2">
									{
										this.state.voterSurvey.survey?.questions.map((question, index) =>
											<div key={question.id}>
												<div>
													<div className="py-2 h5"><b>Q {index + 1}. {question.text}</b></div>
													<div className="ml-sm-3  pt-sm-0 pt-3" id="options">
														{
															question.options.map((option, optionIndex) =>
																<label className="options" key={option.id} >
																	<input type="radio" name={`response-${question.id}`} id={`response-${question.id}`} onClick={() => this.handleResponses(question.id, option.id)} defaultChecked={optionIndex === 0 ? 'checked' : ''} tabIndex={option.id}/>
																	{option.text}
																	<span className="checkmark"></span>
																</label>
															)
														}
													</div>
												</div>
											</div>
										)
									}
								</div>
							</div>
							<div className="text-center">
								<button onClick={this.handleSubmit} className="btn btn-success" >Submit</button>
							</div>
						</div> : this.state.error != '' ?
						<Error /> :
						<div className='data-loader text-center m-5'>
							<RotatingLines strokeColor="grey"
			                  strokeWidth="5"
			                  animationDuration="0.75"
			                  width="96"
			                  visible={true} />
						</div>
					}
				</div>
			</div>
		)
	}
}

export default withParams(VoterSurveySubmit);