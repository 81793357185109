import React from "react";
import { Link } from "react-router-dom";

class MembershipFrontPage extends React.Component {
    constructor(props) {
        super(props);
        this.state = {

        };
    }

    render() {
        return(
            <>
            <div className="container">
                <div className="row">
                    <div className="col-12 pt-5">
                        <div className="brand-image-banner mx-auto">
                            <img src={ '/bjd-logo.webp' } alt="" width={`100%`} height={`100%`} />
                        </div>
                    </div>
                </div>
                <div className="row pt-5">
                    <div className="col-12 col-md-6">
                        <Link to={`/membershipform`} className="text-decoration-none">
                            <div className="card shadow">
                                <div className="card-body m-auto py-5">
                                    <h3>New Registration</h3>
                                </div>
                            </div>
                        </Link>
                    </div>
                    <div className="col-12 col-md-6">
                        <Link to={`/login`} className="text-decoration-none">
                            <div className="card shadow">
                                <div className="card-body m-auto py-5">
                                    <h3>Already Registered Login</h3>
                                </div>
                            </div>
                        </Link>
                    </div>
                </div>
            </div>
            </>
        )
    }
}

export default MembershipFrontPage;