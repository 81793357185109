import React from "react";
import RotatingLinesLoader from "./RotatingLineLoader";
import Pagination from './Pagination';
import { putAssignRoles } from '../services/Voters';
import withParams from "./GetParams";

class EntityAssignmentSelection extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			selectedList: {},
			selectAll: false,
			assignedError: null
		}
		this.handleAssignRecord = this.handleAssignRecord.bind(this);
		this.handleSelectAll = this.handleSelectAll.bind(this);
		this.handleRemoveRecord = this.handleRemoveRecord.bind(this);
		this.handleIncludeAssigned = this.handleIncludeAssigned.bind(this);
		this.onChangePage = this.onChangePage.bind(this);
		this.handleSubmitAssign = this.handleSubmitAssign.bind(this);
	}

	handleSubmitAssign() {
		const assignedList = this.state.selectedList;
		if(Object.keys(assignedList).length > 0) {
			let assignedArray = [];
			Object.keys(assignedList).map(id => 
				assignedArray = [...assignedArray, {id: parseInt(id)}]
			)
				this.props.onSubmit(this.props.profile.id, assignedArray);
		}
		else {
			this.setState({assignedError: 'Please select aleast one option.'});
		}
	}

	onChangePage(event) {
		this.props.onChangePageNumber(event);
	}

	handleSelectAll(data) {
		if(this.state.selectAll == false) {
			let newSelectedList = {};
			for(let i=0;i<data.length;i++) {
				newSelectedList = {...newSelectedList, [data[i].id] : data[i]};
			}
			this.setState({selectedList: newSelectedList, selectAll: true});
		}
		else {
			this.setState({selectedList: {}, selectAll: false});
		}
	}

	handleAssignRecord(record) {
		let selectedList = this.state.selectedList;
		selectedList = {...selectedList, [record.id] : record};
		this.setState({selectedList, assignedError: null});
	}

	handleRemoveRecord(record) {
		let selectedList = this.state.selectedList;
		let newSelectedList = {};
		Object.keys(selectedList).map(record_id => {
			let newRecord = selectedList[record_id];
			if(record_id != record.id) {
				newSelectedList = {...newSelectedList, [record_id] : newRecord};
			}
		})
		this.setState({selectedList: newSelectedList});
	}

	handleIncludeAssigned(event) {
		this.props.includeAssigned(!event);
	}

	componentDidUpdate(prevProps, prevState) {
		if(JSON.stringify(prevProps.entitiesList) != JSON.stringify(this.props.entitiesList))
			this.setState({selectedList: this.props.entitiesList})
	}

	render() {
		let data = null;
		if(this.props.list?.count != undefined) {
			data = this.props.list?.data;
		}
		else {
			data = this.props.data;
		}
		return(
			<div>
			{
				data != null ? 
				<div>
					<div className="row">
						<div className="col-md-6 col-12">
							<span className="text-danger fw-700">{this.state.assignedError && this.state.assignedError}</span>
						</div>
						<div className="col-md-6 col-12">
							<input type="button" value="Assign" className="btn btn-success float-right mb-2" onClick={this.handleSubmitAssign} />
						</div>
					</div>
					<table className="table">
						<thead>
							<tr>
								<th className="w-5 text-center align-middle">
									<input type="checkbox" id="selectAll" name="selectAll" defaultChecked={this.state.selectAll} onChange={() => (this.handleSelectAll(data))} />
								</th>
								<th className="align-middle">Select All <span className="float-right"><input defaultChecked={this.props.includeAssignedJob == true ? true : false} type="checkbox" name="includessigned" id="includessigned" onChange={ () => {this.handleIncludeAssigned(this.props.includeAssignedJob)}} /> Include Asigned</span></th>
							</tr>
						</thead>
						<tbody>
						{
							data?.filter(obj => this.state.selectedList[obj.id] != null).map(record_obj => {
								let record = this.state.selectedList[record_obj.id];
								return <tr key={`${this.state.selectedList?.length}-${record_obj?.id}`}>
									<td className="text-center align-middle">
										<input checked={true} type="checkbox" id={`check-${this.state.selectedList?.length}-${record_obj?.id}`} name={`check-${this.state.selectedList?.length}-${record_obj?.id}`} onChange={ () => {this.handleRemoveRecord(record_obj)}} />
									</td>
									<td className="align-middle">
									{
										this.props.profile.role == this.props.roles.POLLING_STATION_INCHARGE.value ? 
											`${record_obj?.part_number} - ${record_obj?.name}, ${record_obj?.location}` : 
										this.props.profile.role == this.props.roles.HOUSE_INCHARGE.value ? 
											`${record_obj?.house_no} - ${record_obj.polling_station?.name}, ${record_obj?.polling_station?.location}, ${record_obj?.polling_station?.village?.name}, ${record_obj?.polling_station?.village?.block?.name}, ${record_obj?.polling_station?.constituency?.name}` : 
										this.props.profile.role == this.props.roles.BLOCK_INCHARGE.value ? 
											`${record_obj?.name}` : 
										this.props.profile.role == this.props.roles.AC_INCHARGE.value ? 
											`${record_obj?.number} - ${record_obj?.name}` :
											''
									}
									</td>
								</tr>;
							})
						}
						{
							data?.filter(obj => this.state.selectedList[obj.id] == null).map(record => {
								return <tr key={`${data?.length}-${record?.id}`}>
									<td className="text-center align-middle">
										<input type="checkbox" id={`check-${data?.length}-${record.id}`} name={`check-${data?.length}-${record.id}`} onChange={ () => {this.handleAssignRecord(record)}} />
									</td>
									<td className="align-middle">
									{
										this.props.profile.role == this.props.roles.POLLING_STATION_INCHARGE.value ? 
											`${record?.part_number} - ${record?.name}, ${record?.location}` : 
										this.props.profile.role == this.props.roles.HOUSE_INCHARGE.value ? 
											`${record?.house_no} - ${record.polling_station?.name}, ${record?.polling_station?.location}, ${record?.polling_station?.village?.name}, ${record?.polling_station?.village?.block?.name}, ${record?.polling_station?.constituency?.name}` : 
										this.props.profile.role == this.props.roles.BLOCK_INCHARGE.value ? 
											`${record?.name}` : 
										this.props.profile.role == this.props.roles.AC_INCHARGE.value ? 
											`${record?.number} - ${record?.name}` :
											''
									}
									</td>
								</tr>
							}
							)
						}
						</tbody>
					</table>
					{
						this.props.list?.count != undefined ? 
							<div className="text-center">
								<Pagination pageCount={this.props.list?.total_pages} currentPage={this.props.list?.current_page - 1} pageSize={10} onChangePage={this.onChangePage} />
							</div> : ''
					}
				</div>
				 : this.props.loaderStatus == true ? <RotatingLinesLoader /> : ''
			}
			</div>
		)
	}
}
export default withParams(EntityAssignmentSelection);