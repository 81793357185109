import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus, faMinus } from "@fortawesome/free-solid-svg-icons";
import { getNameFromList } from '../../services/Voters';
import { handleInputTelNumber } from '../helper/Validations';
import Multiselect from "react-widgets/Multiselect";


class NeutralInfluencers extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      neutralInfluencersArray: [],
      nameError: '',
      phoneNumberError: '',
      casteError: '',
      levelOfInfluenceError: '',
      influentialPosition: null,
      influentialPositionError: null,
    };
    this.name = React.createRef();
    this.phoneNumber = React.createRef();
    this.caste = React.createRef();
    this.religion = React.createRef();
    this.levelOfInfluence = React.createRef();
    this.onClickNeutralInfluencers = this.onClickNeutralInfluencers.bind(this);
    this.handleName = this.handleName.bind(this);
    this.handlePhoneNumber = this.handlePhoneNumber.bind(this);
    this.handleCaste = this.handleCaste.bind(this);
    this.handleLevelOfInfluence = this.handleLevelOfInfluence.bind(this);
    this.handleInfluentialPositionList = this.handleInfluentialPositionList.bind(this);
  }

  handleInfluentialPositionList(event) {
    let newArray = [];
    for(let i=0;i<event?.length;i++) {
      newArray = [ ...newArray, {id: event[i].id} ]
    }
    this.setState({influentialPositionError: '', influentialPosition: newArray});
  }

  handleName() {
    this.setState({nameError: ''});
  }

  handlePhoneNumber() {
    this.setState({phoneNumberError: ''});
  }

  handleCaste() {
    this.setState({casteError: ''});
  }

  handleLevelOfInfluence() {
    this.setState({levelOfInfluenceError: ''});
  }

  onClickNeutralInfluencers() {
    let name = this.name.current.value;
    let phoneNumber = this.phoneNumber.current.value;
    let caste = this.caste.current.value;
    let religion = this.religion.current.value;
    let influentialPosition = this.state.influentialPosition;
    let levelOfInfluence = this.levelOfInfluence.current.value;
    if(name == '') {
      this.setState({nameError: 'Please enter name.'});
    }
    else if(phoneNumber == '') {
      this.setState({phoneNumberError: 'Please enter phone number.'});
    }
    else if(caste == '') {
      this.setState({casteError: 'Please select caste.'});
    }
    else if(influentialPosition == null || influentialPosition.length == 0) {
      this.setState({influentialPositionError: 'Please enter influential position.'});
    }
    else if(levelOfInfluence == '') {
      this.setState({levelOfInfluenceError: 'Please enter level of influence.'});
    }
    else {
      let neutralInfluencersObject = { 'name': name, 'phoneNumber': phoneNumber, 'religion': religion, 'caste': caste, 'influentialPosition': influentialPosition, 'levelOfInfluence': levelOfInfluence, 'profileId':this.props.profileId };
      let neutralInfluencersArray = this.state.neutralInfluencersArray;
      neutralInfluencersArray = [ ...neutralInfluencersArray, neutralInfluencersObject ];
      this.name.current.value = '';
      this.phoneNumber.current.value = '';
      this.religion.current.value = '';
      this.caste.current.value = '';
      this.levelOfInfluence.current.value = '';
      this.props.neutralInfluencers(neutralInfluencersArray);
      this.setState({ neutralInfluencersArray, influentialPosition: null });
    }
  }

  removeNeutralInfluencers(event) {
    const neutralInfluencersArray = this.state.neutralInfluencersArray;
    let newNeutralInfluencersArray = [];
    for(let i=0;i<neutralInfluencersArray.length;i++) {
      if(i !== event.index) {
        newNeutralInfluencersArray = [ ...newNeutralInfluencersArray, neutralInfluencersArray[i] ];
      }
    }
    this.props.neutralInfluencers(newNeutralInfluencersArray);
    this.setState({ neutralInfluencersArray: newNeutralInfluencersArray });
  }

  render() {
    return(
      <table className="table custom-table">
        <thead>
          <tr>
            <th colSpan={8} className="text-center bg-dark bg-opacity-50 custom-table-th-desktop">Neutral Influencers</th>
            <th className="text-center bg-dark bg-opacity-50 custom-table-th-mobile">Neutral Influencers</th>
          </tr>
          <tr className="align-middle custom-table-tr-desktop">
            <th className="text-center" scope="col">Sl. No.</th>
            <th scope="col">Name</th>
            <th scope="col">Phone number</th>
            <th scope="col">Religion</th>
            <th scope="col">Caste</th>
            <th scope="col">Influential Position</th>
            <th scope="col">Level of Influence</th>
            <th className="text-center" scope="col">Action</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td scope="row" data-label="" className="align-middle"></td>
            <td data-label="Name" className="align-middle">
              <input className="form-control" type="text" id="name" name="name" placeholder="Name" ref={ this.name } onChange={this.handleName} />
              <span className="text-danger fw-600">{this.state.nameError && this.state.nameError}</span>
            </td>
            <td data-label="Phone Number" className="align-middle">
              <input className="form-control" type="tel" id="phonenumber" name="phonenumber" placeholder="Phone Number" ref={ this.phoneNumber } onChange={this.handlePhoneNumber} onKeyPress={(event) => { handleInputTelNumber(event) }} />
              <span className="text-danger fw-600">{this.state.phoneNumberError && this.state.phoneNumberError}</span>
            </td>
            <td data-label="Religion" className="align-middle">
            <select name="religion" id="" ref={ this.religion } className='form-select'>
              <option value="">Select religion</option>
              { this.props.religionList?.map(religion => <option value={religion.id} key={religion.id} >{religion.name}</option>)}
            </select>
            </td>
            <td data-label="Caste" className="align-middle">
            <select name="caste" id="caste" ref={ this.caste } className='form-select' onChange={this.handleCaste}>
              <option value="">Select Caste</option>
              { this.props.subCasteList?.map(caste => <option value={caste.id} key={caste.id} >{caste.name}</option>)}
            </select>
            <span className="text-danger fw-600">{this.state.casteError && this.state.casteError}</span>
            </td>
            <td data-label="Influential Position" className="align-middle">
              <Multiselect data={this.props.influentialPositionList ? this.props.influentialPositionList : []} dataKey='id' textField="name" placeholder="Influential Position" onChange={this.handleInfluentialPositionList} value={this.state.influentialPosition != null ? this.state.influentialPosition : []} />
              <span className="text-danger fw-600">{this.state.influentialPositionError && this.state.influentialPositionError}</span>
            </td>
            <td data-label="Level of influence" className="align-middle">
              <select name="levelofinfluence" id="levelofinfluence" className="form-select" onChange={this.handleLevelOfInfluence} ref={ this.levelOfInfluence }>
                {this.props.levelOfInfluence?.map(level => 
                  <option key={level.id} value={level.id} >{level.name}</option>
                )} 
              </select>
              <span className="text-danger fw-600">{this.state.levelOfInfluenceError && this.state.levelOfInfluenceError}</span>
            </td>
            <td className="text-center align-middle" data-label="Action">
              <FontAwesomeIcon icon={ faPlus } className="btn btn-success" onClick={ this.onClickNeutralInfluencers } />
            </td>
          </tr>
          {
            this.props.neutralInfluencersList?.map((neutralInfluencers, index) => 
              <tr key={ index } >
                <td className="text-center align-middle" scope="row" data-label="Sl. No.">{ index +1 }</td>
                <td data-label="Name" className="align-middle">
                  <input className="form-control" type="text" id={`name-${ index }-${ this.state.neutralInfluencersArray.length }`} key={`name-${ index }-${ this.state.neutralInfluencersArray.length }`} name="name" placeholder="Name" defaultValue={ neutralInfluencers.name } readOnly/>
                </td>
                <td data-label="Phone Number" className="align-middle">
                  <input className="form-control" type="number" id={`phonenumber-${ index }-${ this.state.neutralInfluencersArray.length }`} key={`phonenumber-${ index }-${ this.state.neutralInfluencersArray.length }`} name="phonenumber" placeholder="Phone Number" defaultValue={ neutralInfluencers.phoneNumber } readOnly/>
                </td>
                <td data-label="Religion" className="align-middle">
                  <input className="form-control" type="text" id={`religion-${index}-${this.state.neutralInfluencersArray.length}`} key={`religion-${index}-${this.state.neutralInfluencersArray.length}`} name="religion" placeholder="Religion" defaultValue={ getNameFromList(this.props.religionList, neutralInfluencers.religion) } readOnly/> 
                </td>
                <td data-label="Caste" className="align-middle">
                  <input className="form-control" type="text" id={`caste-${ index }-${ this.state.neutralInfluencersArray.length }`} key={`caste-${ index }-${ this.state.neutralInfluencersArray.length }`} name="caste" placeholder="Caste" defaultValue={ getNameFromList(this.props.subCasteList, neutralInfluencers.caste) } readOnly/>
                </td>
                <td data-label="Influential Position" className="align-middle">
                  <textarea className="form-control" type="text" id={`influentialPosition-${ index }-${ this.state.neutralInfluencersArray.length }`} key={`influentialPosition-${ index }-${ this.state.neutralInfluencersArray.length }`} name="influentialPosition" placeholder="Influential Position" defaultValue={ neutralInfluencers.influentialPosition.map((position, index) => index == 0 ? getNameFromList(this.props.influentialPositionList, position.id) : ` ${getNameFromList(this.props.influentialPositionList, position.id)}` ) } readOnly></textarea>
                </td>
                <td data-label="Level of influence" className="align-middle">
                  <input className="form-control" type="text" id={`levelofinfluence-${ index }-${ this.state.neutralInfluencersArray.length }`} key={`levelofinfluence-${ index }-${ this.state.neutralInfluencersArray.length }`} name="levelofinfluence" placeholder="Level of Influence" defaultValue={ neutralInfluencers.levelOfInfluence ? getNameFromList(this.props.levelOfInfluence, neutralInfluencers.levelOfInfluence) : `` } readOnly/>
                </td>
                <td className="text-center align-middle" data-label="Action">
                  <FontAwesomeIcon icon={ faMinus } className="btn btn-danger" onClick={ () => this.removeNeutralInfluencers({ index }) } />
                </td>
            </tr>
            )
          }
        </tbody>
      </table>
    )
  }
}

export default NeutralInfluencers;