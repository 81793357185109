import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import '../../assets/css/Voters.css';
import { faDownload, faEye, faEdit } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Pagination from '../../components/Pagination';
import { Link  } from 'react-router-dom';
import { RotatingLines } from  'react-loader-spinner';
import Error from '../error';
import { getGenderName } from '../../services/Voters';

class VoterTable extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      pageSize: 50,
      search:'',
      pageCount:'',
      voterTableDetails:[]
    }

    this.pageCounter = React.createRef();

    this.onChangePage = this.onChangePage.bind(this);
    this.handlePageSize = this.handlePageSize.bind(this);
    this.handleSearch = this.handleSearch.bind(this);
    this.handlePageCountInput = this.handlePageCountInput.bind(this);
    this.handlePageChangeButton = this.handlePageChangeButton.bind(this);
  }

  onChangePage(event) {
    this.pageCounter.current.value = '';
    this.props.onChangePagenation(event);
  }

  handlePageSize(event) {
    this.setState({pageSize: event.target.value});
    this.props.onChangePageSize(event.target.value);
  }

  handleSearch(event){
    let filterdValue = [];
    if(event.target.value == ''){
      this.setState({voterTableDetails:this.props.voterTableDetails.results});
    }
    else{
      this.setState({search:event.target.value.toLowerCase()});
      filterdValue = this.props.voterTableDetails.results.filter((item) => item.first_name?.toLowerCase().includes(this.state.search) || item.last_name?.toLowerCase().includes(this.state.search) || item.mobile_number?.toLowerCase().includes(this.state.search) || item.id_card_number?.toLowerCase().includes(this.state.search));
      this.setState({voterTableDetails:filterdValue});
    }
  }

  handlePageChangeButton(event){
    this.props.onChangePageCounter(this.state.pageCount);
  }

  handlePageCountInput(event){
    this.setState({pageCount:event.target.value});
  }
  
  render() {
    let column = this.props.filterShowing ? 9 : 12;
      return(
        <div className={ `col-md-${column} col-lg-${column} col-sm-12 voter-table` }>
          <div className='card shadow mt-4 me-2'>
          <div className='card-body'>
          <div className="row">
            <div className="col-12 px-4 pt-3">
            <div className='header-font d-inline'>
              Show 
              <select name="pageSize" id="pageSize" className='ms-2' onChange={this.handlePageSize}>
                <option value="50">50</option>
                <option value="100">100</option>
                <option value="250">250</option>
                <option value="500">500</option>
              </select>
            </div>
            <div className='header-font d-inline ml-5'> 
              <input type="text" className="form-control voterSearch d-inline" onChange={this.handleSearch} placeholder="Search..." />
            </div>

            <div className='header-font d-inline ml-5'> 
                  <input className='form-control w-10 d-inline' type="number" onChange={this.handlePageCountInput} ref={ this.pageCounter }/>
                  <button className='d-inline btn btn-success btn-sm mx-3' onClick={this.handlePageChangeButton}>Goto</button>
            </div>

            <div className='float-right header-font d-inline'>
            {this.props.voterTableDetails != '' ? this.props.voterTableDetails.count : 0 } records <FontAwesomeIcon icon={faDownload} />
            </div>
            </div>
          </div>
            <div className='p-3 overflow-x'>
            {this.props.voterTableDetails != '' ?
            <div>
              <table className='table border-top table-responsive-sm table-striped'>
                <thead>
                  <tr className='fw-600 bg-primary'>
                    <td className='align-middle'>#</td>
                    <td className='align-middle'>Name</td>
                    <td className='align-middle'>Mobile</td>
                    <td className='align-middle'>Voter ID</td>
                    <td className='align-middle'>Age</td>
                    <td className='align-middle'>Gender</td>
                    <td className='align-middle'>H.No.</td>
                    <td className='align-middle'>PollingStation</td>
                    <td className='align-middle'>Village</td>
                    <td className='align-middle'>Constituency</td>
                    <td className='align-middle'>Opinion</td>
                    <td className='align-middle'>Survey</td>
                  </tr>
                </thead>
                <tbody>
                { this.state.voterTableDetails != '' ?
                 this.state.voterTableDetails && this.state.voterTableDetails.map((items) => (
                    <tr key={items?.first_name} data-key={items?.first_name}>
                      <td>{items?.serial_number}</td>
                      <td>{items?.first_name} {items?.last_name}</td>
                      <td>{items?.mobile_number}</td>
                      <td>{items?.id_card_number}</td>
                      <td>{items?.age}</td>
                      <td>{items?.gender ? getGenderName(items?.gender) : ''}</td>
                      <td>{items?.house_no}</td>
                      <td>{items?.polling_station?.part_number} ({items?.polling_station?.name})</td>
                      <td>{items?.polling_station?.village?.name}</td>
                      <td>{items?.polling_station?.constituency?.name}</td>
                      <td>{items?.opinion?.name}</td>
                      <td><Link to={`/Voter-details-view/${items?.id_card_number}`} ><FontAwesomeIcon icon={faEye} className='align-middle' /></Link>
                      <Link to={`/update-voter-details/${items?.id_card_number}`}><FontAwesomeIcon icon={faEdit} className='float-right pe-2 align-middle' /></Link></td>
                    </tr>
                  ))
                 :
                  this.props.voterTableDetails.results && this.props.voterTableDetails.results.map((items) => (
                    <tr key={items?.first_name} data-key={items?.first_name}>
                      <td className='align-middle'>{items?.serial_number}</td>
                      <td className='align-middle'>{items?.first_name} {items?.last_name}</td>
                      <td className='align-middle'>{items?.mobile_number}</td>
                      <td className='align-middle'>{items?.id_card_number}</td>
                      <td className='align-middle'>{items?.age}</td>
                      <td className='align-middle'>{getGenderName(items?.gender)}</td>
                      <td className='align-middle'>{items?.house_no}</td>
                      <td className='align-middle'>{items?.polling_station?.part_number} ({items?.polling_station?.name})</td>
                      <td className='align-middle'>{items?.polling_station?.village?.name}</td>
                      <td className='align-middle'>{items?.polling_station?.constituency?.name}</td>
                      <td className='align-middle'>{items?.opinion?.name}</td>
                      <td className='align-middle'><Link to={`/Voter-details-view/voters/${items?.id_card_number}`} ><FontAwesomeIcon icon={faEye} /></Link>
                      <Link to={`/update-voter-details/voters/${items?.id_card_number}`}><FontAwesomeIcon icon={faEdit} className='ms-2' /></Link></td>
                    </tr>
                  ))}
                </tbody>
              </table>
              <div className='text-center'>
              <Pagination pageCount={this.props.voterTableDetails?.total_pages} currentPage={this.props.voterTableDetails.current_page - 1} pageSize={this.state.pageSize} onChangePage={ this.onChangePage } />
            </div>
            </div>: this.props.voterTableLoaderStatus == true ? 
            <div className='text-center m-5'>
                <RotatingLines strokeColor="grey"
                  strokeWidth="5"
                  animationDuration="0.75"
                  width="96"
                  visible={true} />
              </div> : this.props.voterDetailserr != '' ? 
              <Error /> :
                    <div className='text-center fw-600 fs-6 text-black-50'>No data found</div>
               }
          </div>
        </div>
      </div>
    </div>
      );
  }
}

export default VoterTable;