import React from "react";
import { getImportantVoters } from '../../services/Voters';
import Pagination from "../Pagination";
import { RotatingLines } from  'react-loader-spinner';
import Error from '../error';

class ImportantVoters extends React.Component {
  constructor(props) {
	super(props);
	this.state = {
	  importantVoters: [],
	  error:'',
	  loader: false,
	  pageNumber: 1,
	  pageSize: 10
	};
	this.onChangePage = this.onChangePage.bind(this);
	this.handlePageSize = this.handlePageSize.bind(this);
  }

	handlePageSize(event) {
		this.setState({importantVoters: [], loader: true})
		getImportantVoters('', '', '', '', this.state.pageNumber, event.target.value).then((importantVoters) => {
			this.setState({importantVoters, loader: false, pageSize: event.target.value});
		}).catch((error) => {
			this.setState({error, loader: false});
		})
	}

  componentDidMount() {
	this.setState({importantVoters: [], loader: true});
	getImportantVoters('', '', '', '', this.state.pageNumber, this.state.pageSize).then((importantVoters) => {
	  this.setState({importantVoters, loader: false});
	}).catch((error) => {
	  this.setState({error, loader: false});
	})
  }

  onChangePage(event) {
	this.setState({importantVoters: [], loader: true});
	getImportantVoters('', '', '', '', event, this.state.pageSize).then((importantVoters) => {
	  this.setState({importantVoters, loader: false, pageNumber: event});
	}).catch((error) => {
	  this.setState({error, loader: false});
	})
  }

  render() {
	return(
		<>
			<div className="mb-4">
				<div>
					<h3>Important Voters</h3>
				</div>
			</div>
			<div className="card">
				<div className="card-body">
					<div className="">
						show <select onChange={this.handlePageSize}>
							<option value="50">50</option>
							<option value="100">100</option>
							<option value="250">250</option>
							<option value="500">500</option>
						</select>
					</div>
					{ this.state.importantVoters?.results?.length != 0 ? 
					<table className="table">
						<thead>
							<tr>
								<th>Name</th>
								<th>Mobile</th>
								<th>Voter Id</th>
								<th>Village</th>
								<th>Position</th>
								<th>Demands</th>
								<th>Extra info</th>
								<th>Complaints</th>
							</tr>
						</thead>
						<tbody>
							{this.state.importantVoters.results && this.state.importantVoters.results.map(importantVoter => 
							<tr key={ importantVoter.id_card_number }>
								<td className="align-middle">{ importantVoter.first_name } {importantVoter.last_name}</td>
								<td className="align-middle">{ importantVoter.mobile_number }</td>
								<td className="align-middle">{ importantVoter.id_card_number }</td>
								<td className="align-middle">{ importantVoter.polling_station.village.name }</td>
								<td className="align-middle">
								{ importantVoter.influential_position.length > 0 ? importantVoter.influential_position.map((position, i, arr) => <span key={position.id}>{position.name}{i != (arr.length-1) ? ', ' : '.'}</span>) : <div className="text-black-50">N/A</div> }
								</td>
								<td className="align-middle">{ importantVoter.demands }</td>
								<td className="align-middle">{ importantVoter.extra_info }</td>
								<td className="align-middle">{ importantVoter.complaints }</td>
							</tr>
							)}
							<tr>
								<td colSpan={8} className='text-center align-middle'>
									<Pagination pageCount={this.state.importantVoters?.total_pages} currentPage={this.state.importantVoters?.current_page ? this.state.importantVoters?.current_page - 1 : -1} pageSize={this.state.pageSize} onChangePage={this.onChangePage} />
								</td>
							</tr>
						</tbody>
					</table> : this.state.loader ? 
					<div className='data-loader text-center'>
						<RotatingLines strokeColor="grey"
								strokeWidth="5"
								animationDuration="0.75"
								width="96"
								visible={true} />
					</div> :
					this.state.error != '' ? 
					<Error /> : <div className="text-center fw-600 fs-6 text-black-50 my-5">No data found</div>
					}
				</div>
			</div>
		</>
	)
  }
}

export default ImportantVoters;