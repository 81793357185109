import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import '../../assets/css/Dashboard-Style.css';
import Chart from "react-apexcharts";

class DashboardPrimaryMembershipChart extends React.Component {
    constructor(props) {
        super(props);
        this.state = {

        }
    }

    render() {
        return(
            <>
                <div className="app">
                    <div className="row">
                        <div className="mixed-chart">
                            <p className='fs-5 fw-600 text-center'>{this.props.title}</p>
                            <Chart options={ this.props.horizontalBarData?.options } series={ this.props.horizontalBarData?.series } type="radialBar" width="100%" />
                        </div>
                    </div>
                </div>
            </>
        )
    }
}

export default DashboardPrimaryMembershipChart;