import React from "react";
import Combobox from "react-widgets/Combobox";
import { getDistrictsList, getAssemblyConstituencyList, getBlockList, getGramPanchayatList, postMemberShipData, SendOTP, getVerifyOTP, profielImageUpload } from './../services/Voters';
import { Link , useNavigate} from "react-router-dom";
import withParams from './GetParams';
import $ from "jquery";

class MemberShipForm extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            districts: [
                {
                  "id": 51,
                  "number": null,
                  "name": "Angul",
                  "state": 1,
                  "slug": null
                },
                {
                  "id": 55,
                  "number": null,
                  "name": "Balasore",
                  "state": 1,
                  "slug": null
                },
                {
                  "id": 80,
                  "number": null,
                  "name": "Bargarh",
                  "state": 1,
                  "slug": null
                },
                {
                  "id": 68,
                  "number": null,
                  "name": "Bhadrak",
                  "state": 1,
                  "slug": null
                },
                {
                  "id": 52,
                  "number": null,
                  "name": "Bolangir",
                  "state": 1,
                  "slug": null
                },
                {
                  "id": 53,
                  "number": null,
                  "name": "Boudh",
                  "state": 1,
                  "slug": null
                },
                {
                  "id": 76,
                  "number": null,
                  "name": "Cuttack",
                  "state": 1,
                  "slug": null
                },
                {
                  "id": 63,
                  "number": null,
                  "name": "Deogarh",
                  "state": 1,
                  "slug": null
                },
                {
                  "id": 62,
                  "number": null,
                  "name": "Dhenkanal",
                  "state": 1,
                  "slug": null
                },
                {
                  "id": 56,
                  "number": null,
                  "name": "Gajapati",
                  "state": 1,
                  "slug": null
                },
                {
                  "id": 54,
                  "number": null,
                  "name": "Ganjam",
                  "state": 1,
                  "slug": null
                },
                {
                  "id": 66,
                  "number": null,
                  "name": "Jagatsinghpur",
                  "state": 1,
                  "slug": null
                },
                {
                  "id": 71,
                  "number": null,
                  "name": "Jajpur",
                  "state": 1,
                  "slug": null
                },
                {
                  "id": 74,
                  "number": null,
                  "name": "Jharsuguda",
                  "state": 1,
                  "slug": null
                },
                {
                  "id": 69,
                  "number": null,
                  "name": "Kalahandi",
                  "state": 1,
                  "slug": null
                },
                {
                  "id": 65,
                  "number": null,
                  "name": "Kandhamal",
                  "state": 1,
                  "slug": null
                },
                {
                  "id": 67,
                  "number": null,
                  "name": "Kendrapara",
                  "state": 1,
                  "slug": null
                },
                {
                  "id": 73,
                  "number": null,
                  "name": "Keonjhar",
                  "state": 1,
                  "slug": null
                },
                {
                  "id": 60,
                  "number": null,
                  "name": "Khurda",
                  "state": 1,
                  "slug": null
                },
                {
                  "id": 59,
                  "number": null,
                  "name": "Koraput",
                  "state": 1,
                  "slug": null
                },
                {
                  "id": 78,
                  "number": null,
                  "name": "Malkanagiri",
                  "state": 1,
                  "slug": null
                },
                {
                  "id": 72,
                  "number": null,
                  "name": "Mayurbhanj",
                  "state": 1,
                  "slug": null
                },
                {
                  "id": 77,
                  "number": null,
                  "name": "Nabarangpur",
                  "state": 1,
                  "slug": null
                },
                {
                  "id": 70,
                  "number": null,
                  "name": "Nayagarh",
                  "state": 1,
                  "slug": null
                },
                {
                  "id": 75,
                  "number": null,
                  "name": "Nuapada",
                  "state": 1,
                  "slug": null
                },
                {
                  "id": 61,
                  "number": null,
                  "name": "Puri",
                  "state": 1,
                  "slug": null
                },
                {
                  "id": 64,
                  "number": null,
                  "name": "Rayagada",
                  "state": 1,
                  "slug": null
                },
                {
                  "id": 58,
                  "number": null,
                  "name": "Sambalpur",
                  "state": 1,
                  "slug": null
                },
                {
                  "id": 57,
                  "number": null,
                  "name": "Subarnpur",
                  "state": 1,
                  "slug": null
                },
                {
                  "id": 79,
                  "number": null,
                  "name": "Sundargarh",
                  "state": 1,
                  "slug": null
                }
              ],
            districtInputValue: null,
            constituencyList: [],
            constituencyInputValue: null,
            grampanchayatList: [],
            grampanchayatInputValue: null,
            blockList: [],
            blockInputValue: null,
            firstName: null,
            fathersName: null,
            age: null,
            gender: null,
            wardNumber: null,
            voterCardNumber: null,
            whatsAppNumber: null,
            submitStatus: false,
            occupation: null,
            otpSentStatus: null,
            OTPVerificationStatus: false,
            OTPNumber: null,
            setUploadProgress: 0,
            mobileNumberErrorMessage: null,
            primaryDNDCheck: true,
            whatsAppNumberErrorMessage: null,
            formError: null,
            isLoading: false,
            isUrban: false,
            studentCollegeName: '',
            studentCollegeNameErrorMessage: null
        };
        this.handleDistrictChange = this.handleDistrictChange.bind(this);
        this.handleDistrictSelect = this.handleDistrictSelect.bind(this);
        this.handleConstituencyChange = this.handleConstituencyChange.bind(this);
        this.handleConstituencySelect = this.handleConstituencySelect.bind(this);
        this.handleBlockChange = this.handleBlockChange.bind(this);
        this.handleBlockSelect = this.handleBlockSelect.bind(this);
        this.handleGramPanchayatChange = this.handleGramPanchayatChange.bind(this);
        this.handleGramPanchayatSelect = this.handleGramPanchayatSelect.bind(this);
        this.handleFirstName = this.handleFirstName.bind(this);
        this.handleFathersName = this.handleFathersName.bind(this);
        this.handleAge = this.handleAge.bind(this);
        this.handleGender = this.handleGender.bind(this);
        this.handleWardNumber = this.handleWardNumber.bind(this);
        this.handleVoterCardNumber = this.handleVoterCardNumber.bind(this);
        this.handleMobileNumber = this.handleMobileNumber.bind(this);
        this.handleWhatsAppNumber = this.handleWhatsAppNumber.bind(this);
        this.handleMemberShipSubmit = this.handleMemberShipSubmit.bind(this);
        this.handleOccupation = this.handleOccupation.bind(this);
        this.handleProfilePhotos = this.handleProfilePhotos.bind(this);
        this.handleSendOTP = this.handleSendOTP.bind(this);
        this.handleOTPVerify = this.handleOTPVerify.bind(this);
        this.handleOTPVerifyNumber = this.handleOTPVerifyNumber.bind(this);
        this.handlePrimaryDND = this.handlePrimaryDND.bind(this);
        this.handleStudentCollegeName = this.handleStudentCollegeName.bind(this);
    }

    scrollTo(elementId) {
        $('html, body').animate({
            scrollTop: $(elementId).offset().top - 100
        }, 50);
    }

    handleStudentCollegeName(event) {
        this.setState({studentCollegeName: event?.target?.value, studentCollegeNameErrorMessage: ''});
    }

    handlePrimaryDND(event) {
        this.setState({primaryDNDCheck: event.target.checked});
    }

    handleOTPVerifyNumber(event) {
        this.setState({otpVerifyNumber: event?.target?.value, otpVerifyNumberErrorMessage: null});
    }

    handleOTPVerify() {
        if(this.state.otpVerifyNumber?.length != 6) {
            this.setState({otpVerifyNumberErrorMessage: 'Please enter a valid otp.'});
        } else {
            const otpVerifyObj = {
                'mobile_number': this.state.mobileNumber,
                'otp': this.state.otpVerifyNumber
            };
            getVerifyOTP(otpVerifyObj).then((data) => {
                if(data?.success == false) {
                    this.setState({otpVerifyNumberErrorMessage: 'Please enter a valid otp.'});
                }
                this.setState({OTPVerificationStatus: data?.success});
            }).catch((err) => {
                this.setState({error: err});
            })
        }
    }

    handleSendOTP() {
        this.setState({otpSentStatus: false, otpVerifyNumber: '', OTPVerificationStatus: false});
        if(this.state.mobileNumber?.length == 10) {
            const mobile_json = {'mobile_number': this.state.mobileNumber};
            SendOTP(mobile_json).then((data) => {
                this.setState({otpSentStatus: data?.success, mobileNumberErrorMessage: data?.message});
            }).catch((err) => {
                this.setState({error: err});
            })
        } else {
            this.setState({mobileNumberErrorMessage: 'Please enter a valid mobile number.'});
        }
    }

    handleProfilePhotos(event) {
        const updateUploadProgress = (value) => {
            this.setState({setUploadProgress: value, profilePhotosErrorMessage: null});
        }
    
        const files = event.target.files;
        profielImageUpload(files, updateUploadProgress).then((data) => {
            this.setState({profilePhotos: data.file_urls});
        }).catch((err) => {
            this.setState({error: err});
        })
    }

    handleFirstName(event) {
        this.setState({firstName: event?.target?.value, firstNameErrorMessage: null});
    }

    handleFathersName(event) {
        this.setState({fathersName: event?.target?.value, fathersNameErrorMessage: null});
    }

    handleAge(event) {
        const age = event?.target?.value;
        if(age < 0 || age > 130) {
            this.setState({agerErrorMessage: 'Please enter a valid age.'})
        } else {
            this.setState({age: event?.target?.value, agerErrorMessage: null});
        }
    }

    handleGender(event) {
        this.setState({gender: event?.target?.value, genderErrorMessage: null});
    }

    handleOccupation(event) {
        this.setState({occupation: event?.target?.value, occupationErrorMessage: null});
        if(event?.target?.value == 'Student') {
            this.setState({voterCardNumber: null});
        } else {
            this.setState({studentCollegeName: ''});
        }
    }

    handleWhatsAppNumber(event) {
        this.setState({whatsAppNumber: event?.target?.value, whatsAppNumberErrorMessage: null});
    }

    handleDistrictChange(event) {
        this.setState({districtInputValue: event, districtInputValueErrorMessage: null});
    }

    handleDistrictSelect(event) {
        let districtId = event.target.value;
        if(districtId == '') {
            this.setState({constituencyList: [], districtInputValue: districtId, blockInputValue: null, blockList: [], grampanchayatInputValue: null, grampanchayatList: [], constituencyInputValue: null, districtInputValueErrorMessage: null});
            return;
        }
        getAssemblyConstituencyList(districtId).then((data) => {
            this.setState({constituencyList: data, districtInputValue: districtId, blockInputValue: null, blockList: [], grampanchayatInputValue: null, grampanchayatList: [], constituencyInputValue: null, districtInputValueErrorMessage: null});
        }).catch((err) => {
            this.setState({error: err});
        })
    }

    handleConstituencyChange(event) {
        this.setState({constituencyInputValue: event, constituencyInputValueErrorMessage: null});
    }

    handleConstituencySelect(event) {
        let constituencyId = event.target.value;
        if(constituencyId == '') {
            this.setState({blockList: [], constituencyInputValue: constituencyId, blockInputValue: null, grampanchayatInputValue: null, grampanchayatList: [], constituencyInputValueErrorMessage: null});
            return;
        }
        getBlockList(constituencyId).then((data) => {
            this.setState({blockList: data, constituencyInputValue: constituencyId, blockInputValue: null, grampanchayatInputValue: null, grampanchayatList: [], constituencyInputValueErrorMessage: null});
        }).catch((err) => {
            this.setState({error: err});
        })
    }

    handleBlockChange(event) {
        this.setState({blockInputValue: event, blockInputValueErrorMessage: null});
    }

    handleBlockSelect(event) {
        let select = event.currentTarget;
        let blockId = select.value;
        if(blockId == '') {
            this.setState({blockInputValue: blockId, grampanchayatList: [], grampanchayatInputValue: null, blockInputValueErrorMessage: null, isUrban: false});
            return;
        }
        let isUrban = select[select.selectedIndex].attributes.getNamedItem('data-isurban').value == 'true';        
        getGramPanchayatList(blockId).then((data) => {
            this.setState({blockInputValue: blockId, grampanchayatList: data, grampanchayatInputValue: null, blockInputValueErrorMessage: null, isUrban: isUrban});
        }).catch((err) => {
            this.setState({error:err});
        })
    }

    handleGramPanchayatChange(event) {
        this.setState({grampanchayatInputValue: event, grampanchayatInputValueErrorMessage: null});
    }

    handleGramPanchayatSelect(event) {
        let gpId = event.target.value;
        this.setState({grampanchayatInputValue: gpId, grampanchayatInputValueErrorMessage: null});
    }

    handleWardNumber(event) {
        this.setState({wardNumber: event?.target?.value, wardNumberErrorMessage: null});
    }

    handleVoterCardNumber(event) {
        this.setState({voterCardNumber: event?.target?.value, voterCardNumberErrorMessage: null});
    }

    handleMobileNumber(event) {
        this.setState({mobileNumber: event?.target?.value, mobileNumberErrorMessage: null});
    }

    handleMemberShipSubmit() {
        const firstName = this.state.firstName;
        const fathersName = this.state.fathersName;
        const age = this.state.age;
        const gender = this.state.gender;
        const voterCardNumber = this.state.voterCardNumber;
        const profilePhotos = this.state.profilePhotos;
        const occupation = this.state.occupation;
        const mobileNumber = this.state.mobileNumber;
        const districtInputValue = this.state.districtInputValue;
        const constituencyInputValue = this.state.constituencyInputValue;
        const blockInputValue = this.state.blockInputValue;
        let grampanchayatInputValue = this.state.grampanchayatInputValue;
        const wardNumber = this.state.wardNumber;
        const whatsAppNumber = this.state.whatsAppNumber;
        let studentCollegeName = this.state.studentCollegeName;

        if(firstName == null || firstName == '') {
            this.setState({firstNameErrorMessage: "Please enter your name."});
            this.scrollTo("#firstname");
            return false;
        }

        if(fathersName == null || fathersName == '') {
            this.setState({fathersNameErrorMessage: "Please enter your father's / husband's name."});
            this.scrollTo("#fathersname");
            return false;
        }

        if(age == null || age == '') {
            this.setState({agerErrorMessage: "Please enter your age."});
            this.scrollTo("#age");
            return false;
        } else if(age > 130) {
            this.setState({agerErrorMessage: "Please enter a valid age."});
            this.scrollTo("#age");
            return false;
        }

        if(gender == null || gender == '') {
            this.setState({genderErrorMessage: "Please select a gender."});
            this.scrollTo("#gender");
            return false;
        }

        if(profilePhotos == null || profilePhotos == '') {
            this.setState({profilePhotosErrorMessage: "Please upload your photo."});
            this.scrollTo("#profilephotos");
            return false;
        }

        if(occupation == null || occupation == '') {
            this.setState({occupationErrorMessage: "Please select an occupation."});
            this.scrollTo("#occupation");
            return false;
        }

        if((voterCardNumber == null || voterCardNumber == '') && (occupation != 'Student')) {
            this.setState({voterCardNumberErrorMessage: 'Please enter your Voter ID / EPIC number'});
            this.scrollTo("#votercardnumber");
            return false;
        }

        if(mobileNumber == null || mobileNumber == '') {
            this.setState({mobileNumberErrorMessage: "Please enter your mobile number."});
            this.scrollTo("#mobilenumber");
            return false;
        } else if(mobileNumber?.length != 10) {
            this.setState({mobileNumberErrorMessage: "Please enter a valid mobile number."});
            this.scrollTo("#mobilenumber");
            return false;
        }

        if(whatsAppNumber?.length > 0) {
            if(whatsAppNumber?.length != 10) {
                this.setState({whatsAppNumberErrorMessage: 'Please enter a valid whatsapp number.'});
                this.scrollTo("#whatsappnumber");
                return false;
            }
        }

        if(districtInputValue == null || districtInputValue == '') {
            this.setState({districtInputValueErrorMessage: "Please select a district."});
            this.scrollTo("#district");
            return false;
        } else if(constituencyInputValue == null || constituencyInputValue == '') {
            this.setState({constituencyInputValueErrorMessage: "Please select a constituency."});
            this.scrollTo("#assemblyconstituency");
            return false;
        } else if(blockInputValue == null || blockInputValue  == '') {
            this.setState({blockInputValueErrorMessage: 'Please select a Block'});
            this.scrollTo("#blocknacmunicipality");
            return false;
        } else if((grampanchayatInputValue == null || grampanchayatInputValue == '') && (this.state.isUrban == false)) {
            this.setState({grampanchayatInputValueErrorMessage: 'Please select a gram panchayat.'});
            this.scrollTo("#grampanchayat");
            return false;
        }

        if(wardNumber == null || wardNumber == '') {
            this.setState({wardNumberErrorMessage: 'Please enter your ward number.'});
            this.scrollTo("#wardnumber");
            return false;
        }

        if(occupation === 'Student' && (studentCollegeName === '' || studentCollegeName === null)) {
            this.setState({studentCollegeNameErrorMessage: "Please enter your college name."});
            studentCollegeName = '';
            this.scrollTo("#studentcollegename");
            return false;
        }

        if(this.state.OTPVerificationStatus == true && firstName != null && firstName != '' && fathersName != null && fathersName != '' && age != null && age != '' && gender != null && gender != '' && districtInputValue != null && districtInputValue != '' && constituencyInputValue != null && constituencyInputValue != '' && blockInputValue != null && blockInputValue != '' && wardNumber != null && wardNumber != '' && mobileNumber != null && mobileNumber != '' ) {
            const finalData = {
                'first_name': this.state.firstName,
                'fathers_name': this.state.fathersName,
                'age': this.state.age,
                'gender': this.state.gender,
                'whatsapp_mobile_number': this.state.whatsAppNumber,
                'district': this.state.districtInputValue,
                'assembly_constituency': this.state.constituencyInputValue,
                'block': this.state.blockInputValue,
                'gram_panchayat': this.state.grampanchayatInputValue ? this.state.grampanchayatInputValue : null,
                'ward_number': this.state.wardNumber,
                'voter_card_number': this.state.voterCardNumber,
                'mobile_number': this.state.mobileNumber,
                'occupation': this.state.occupation,
                'profile_image': this.state.profilePhotos,
                'dnd_consent': this.state.primaryDNDCheck,
                'student_college_name': this.state.studentCollegeName
            };
    
            postMemberShipData(finalData).then((data) => {
                this.setState({submitData: data, firstName: null, fathersName: null, age: null, gender: null, whatsAppNumber: null, districtInputValue: null, constituencyInputValue: null, blockInputValue: null, grampanchayatInputValue: null, wardNumber: null, voterCardNumber: null, mobileNumber: null, occupation: null, profilePhotos: null});
                this.props.navigate(`/activemembership?id=${data.id}`);
            }).catch((err) => {
                if(err?.response?.status == 400) {
                    this.setState({formError: err?.response?.data, isLoading: false});
                } else {
                    this.setState({formError: 'Something went wrong. Please try again.', isLoading: false});
                }
            });
        }
    }

    render() {
        return(
            <>
                <div className="container">
                    <div className="card mt-5 w-50 w-sm-100 mx-auto">
                        <div className="card-header">
                            <h3 className="text-center">Membership Form 2024</h3>
                        </div>
                        <div className="card-body">
                            <form action="#" method="post">
                                <div className="row">
                                    <div className="col-12">
                                        <div className="form-group">
                                            <label htmlFor="name">Name <span className="text-danger fw-600">*</span></label>
                                            <input type="text" name="firstname" id="firstname" className="form-control" placeholder="Name" onChange={this.handleFirstName} value={this.state.firstName ? this.state.firstName : ''} />
                                            <span className="text-danger fw-600">{ this.state.firstNameErrorMessage ? this.state.firstNameErrorMessage : '' }</span>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="form-group">
                                            <label htmlFor="fathersname">Father / Husband Name <span className="text-danger fw-600">*</span></label>
                                            <input type="text" name="fathersname" id="fathersname" className="form-control" placeholder="Father / Husband Name" onChange={this.handleFathersName} value={this.state.fathersName ? this.state.fathersName : ''} />
                                            <span className="text-danger fw-600">{ this.state.fathersNameErrorMessage ? this.state.fathersNameErrorMessage : '' }</span>
                                        </div>
                                    </div>
                                    <div className="col-12 col-md-4">
                                        <div className="form-group">
                                            <label htmlFor="age">Age <span className="text-danger fw-600">*</span></label>
                                            <input type="number" name="age" id="age" className="form-control" placeholder="Age" onChange={this.handleAge} value={this.state.age ? this.state.age : ''} minLength={3} maxLength={3} />
                                            <span className="text-danger fw-600">{ this.state.agerErrorMessage ? this.state.agerErrorMessage : '' }</span>
                                        </div>
                                    </div>
                                    <div className="col-12 col-md-8">
                                        <div className="form-group">
                                            <label htmlFor="gender">Gender <span className="text-danger fw-600">*</span></label>
                                            <select name="gender" id="gender" className="form-select" onChange={this.handleGender} >
                                                <option value="" defaultValue={this.state.gender == '' ? 'selected' : ''}>Select</option>
                                                <option value="M" defaultValue={this.state.gender == 'M' ? 'selected' : ''} >Male</option>
                                                <option value="F" defaultValue={this.state.gender == 'F' ? 'selected' : ''}>Female</option>
                                                <option value="O" defaultValue={this.state.gender == 'O' ? 'selected' : ''}>Other</option>
                                            </select>
                                            <span className="text-danger fw-600">{ this.state.genderErrorMessage ? this.state.genderErrorMessage : '' }</span>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="form-group">
                                            <label htmlFor="profilephotos">Upload Photo <span className="text-danger fw-600">*</span></label>
                                            <input type="file" name="profilephotos" id="profilephotos" className="form-control" onChange={this.handleProfilePhotos} capture/>
                                            <span className="text-danger fw-600">{ this.state.profilePhotosErrorMessage ? this.state.profilePhotosErrorMessage : '' }</span>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="form-group">
                                            <label htmlFor="occupation">Occupation <span className="text-danger fw-600">*</span></label>
                                            <select name="occupation" id="occupation" className="form-select" onChange={this.handleOccupation}>
                                                <option value="" defaultValue={this.state.occupation == '' ? 'selected' : ''}>Select</option>
                                                <option value="Farmer" defaultValue={this.state.occupation == 'Farmer' ? 'selected' : ''}>Farmer</option>
                                                <option value="Student" defaultValue={this.state.occupation == 'Student' ? 'selected' : ''}>Student</option>
                                                <option value="Professional" defaultValue={this.state.occupation == 'Professional' ? 'selected' : ''}>Professional</option>
                                                <option value="Private Service" defaultValue={this.state.occupation == 'Private Service' ? 'selected' : ''}>Private Service</option>
                                                <option value="Homemaker" defaultValue={this.state.occupation == 'Homemaker' ? 'selected' : ''}>Homemaker</option>
                                                <option value="Business" defaultValue={this.state.occupation == 'Business' ? 'selected' : ''}>Business</option>
                                                <option value="Labourer" defaultValue={this.state.occupation == 'Labourer' ? 'selected' : ''}>Labourer</option>
                                                <option value="Other" defaultValue={this.state.occupation == 'Other' ? 'selected' : ''}>Other</option>
                                            </select>
                                            <span className="text-danger fw-600">{ this.state.occupationErrorMessage ? this.state.occupationErrorMessage : '' }</span>
                                        </div>
                                    </div>
                                    <div className={`col-12 ${this.state.occupation == 'Student' ? `d-block` : `d-none`}`}>
                                        <div className="form-group">
                                            <label htmlFor="studentcollegename">Your College Name <span className="text-danger fw-600">*</span></label>
                                            <input type="text" name="studentcollegename" id="studentcollegename" placeholder="Your College Name" onChange={this.handleStudentCollegeName} value={ this.state.studentCollegeName ? this.state.studentCollegeName : '' } className="form-control" />
                                            <span className="text-danger fw-600">{ this.state.studentCollegeNameErrorMessage ? this.state.studentCollegeNameErrorMessage : '' }</span>
                                        </div>
                                    </div>
                                    <div className={`col-12 ${this.state.occupation == 'Student' ? `d-none` : `d-block`}`}>
                                        <div className="form-group">
                                            <label htmlFor="votercardnumber">Voter ID / EPIC Number <span className="text-danger fw-600">*</span></label>
                                            <input type="text" name="votercardnumber" id="votercardnumber" className="form-control" placeholder="Voter Card Number" onChange={this.handleVoterCardNumber} value={this.state.voterCardNumber ? this.state.voterCardNumber : ''} />
                                            <span className="text-danger fw-600">{ this.state.voterCardNumberErrorMessage ? this.state.voterCardNumberErrorMessage : '' }</span>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="form-group">
                                            <label htmlFor="district">District <span className="text-danger fw-600">*</span></label>
                                            <select name="district" id="district" className="form-select" onChange={this.handleDistrictSelect}>
                                                <option value="">Select</option>
                                                {
                                                    this.state.districts.map((district) => (
                                                        <option key={district.id} value={district.id}>{district.name}</option>
                                                    ))
                                                }
                                            </select>
                                            <span className="text-danger fw-600">{ this.state.districtInputValueErrorMessage ? this.state.districtInputValueErrorMessage : '' }</span>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="form-group">
                                            <label htmlFor="assemblyconstituency">Assembly Constituency <span className="text-danger fw-600">*</span></label>
                                            <select name="assemblyconstituency" id="assemblyconstituency" className="form-select" onChange={this.handleConstituencySelect}>
                                                <option value="" defaultValue={this.state.occupation == '' ? 'selected' : ''}>Select</option>
                                                {
                                                    this.state.constituencyList.map((constituency) => (
                                                        <option key={constituency.id} value={constituency.id}>{constituency.name}</option>
                                                    ))
                                                }
                                            </select>
                                            <span className="text-danger fw-600">{ this.state.constituencyInputValueErrorMessage ? this.state.constituencyInputValueErrorMessage : '' }</span>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="form-group">
                                            <label htmlFor="blocknacmunicipality">Block / NAC / Municipality <span className="text-danger fw-600">*</span></label>
                                            <select name="blocknacmunicipality" id="blocknacmunicipality" className="form-select" onChange={this.handleBlockSelect}>
                                                <option value="">Select</option>
                                                {
                                                    this.state.blockList.map((block) => (
                                                        <option key={block.id} value={block.id} data-isurban={block.is_urban}>{block.name}</option>
                                                    ))
                                                }
                                            </select>
                                            <span className="text-danger fw-600">{ this.state.blockInputValueErrorMessage ? this.state.blockInputValueErrorMessage : '' }</span>
                                        </div>
                                    </div>
                                    <div className="col-12 col-md-8">
                                        <div className="form-group">
                                            <label htmlFor="grampanchayat">Gram Panchayat <span className="text-danger fw-600">*</span></label>
                                            <select name="grampanchayat" id="grampanchayat" className="form-select" onChange={this.handleGramPanchayatSelect} disabled={this.state.isUrban ? this.state.isUrban : false }>
                                                <option value="">Select</option>
                                                {
                                                    this.state.grampanchayatList.map((gp) => (
                                                        <option key={gp.id} value={gp.id}>{gp.name}</option>
                                                    ))
                                                }
                                            </select>
                                            <span className="text-danger fw-600">{ this.state.grampanchayatInputValueErrorMessage ? this.state.grampanchayatInputValueErrorMessage : '' }</span>
                                        </div>
                                    </div>
                                    <div className="col-12 col-md-4">
                                        <div className="form-group">
                                            <label htmlFor="wardnumber">Ward No. <span className="text-danger fw-600">*</span></label>
                                            <input type="text" name="wardnumber" id="wardnumber" className="form-control" placeholder="Ward No." onChange={this.handleWardNumber} value={this.state.wardNumber ? this.state.wardNumber : ''} />
                                            <span className="text-danger fw-600">{ this.state.wardNumberErrorMessage ? this.state.wardNumberErrorMessage : '' }</span>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="form-group">
                                            <label htmlFor="whatsappnumber">WhatsApp No.</label>
                                            <input type="tel" name="whatsappnumber" id="whatsappnumber" className="form-control" placeholder="WhatsApp No." maxLength={10} minLength={10} onChange={this.handleWhatsAppNumber} value={this.state.whatsAppNumber ? this.state.whatsAppNumber : ''} />
                                            <span className="text-danger fw-600">{ this.state.whatsAppNumberErrorMessage != null ? this.state.whatsAppNumberErrorMessage : '' }</span>
                                        </div>
                                    </div>
                                    <div className="col-8">
                                        <div className="form-group">
                                            <label htmlFor="mobilenumber">Mobile No. <span className="text-danger fw-600">*</span></label>
                                            <input type="tel" name="mobilenumber" id="mobilenumber" readOnly={this.state.otpSentStatus == true ? true : false} maxLength={10} minLength={10} className="form-control" placeholder="Mobile Number" onChange={this.handleMobileNumber} value={this.state.mobileNumber ? this.state.mobileNumber : ''} />
                                        </div>
                                    </div>
                                    <div className="col-4 mt-auto px-0">
                                        <div className={`form-group`}>
                                            <button type="button" className="btn btn-success px-1" onClick={this.handleSendOTP}>{ this.state.otpSentStatus == true ? 'Resend OTP' : 'Send OTP' } </button>
                                        </div>
                                    </div>
                                    <span className="text-danger fw-600">{ this.state.mobileNumberErrorMessage != null ? this.state.mobileNumberErrorMessage : '' }</span>
                                    <div className={`col-8 ${this.state.otpSentStatus == true ? 'd-block' : 'd-none'}`}>
                                        <div className="form-group">
                                            <label htmlFor="otpverify">OTP Verify</label>
                                            <input type="tel" name="otpverify" id="otpverify" placeholder="OTP Verify" className="form-control" onChange={this.handleOTPVerifyNumber} maxLength={6} minLength={6} readOnly={ this.state.OTPVerificationStatus == true ? true : false } value={this.state.otpVerifyNumber ? this.state.otpVerifyNumber : ''} />
                                        </div>
                                    </div>
                                    <div className={`col-4 mt-auto px-0 ${this.state.otpSentStatus == true ? 'd-block' : 'd-none'}`}>
                                        <div className="form-group">
                                            <button type="button" className="btn btn-success px-1" disabled={this.state.OTPVerificationStatus ? true : false} onClick={this.handleOTPVerify}>Verify OTP</button>
                                        </div>
                                    </div>
                                    {
                                        this.state.otpVerifyNumberErrorMessage ? <div className="col-12">
                                        <span className="text-danger fw-600 d-block">{this.state.otpVerifyNumberErrorMessage ? this.state.otpVerifyNumberErrorMessage : ''}</span>
                                    </div> : ''
                                    }
                                    <div className="col-12">
                                        <div className="form-check">
                                            <input type="checkbox" name="termsandconditions" id="termsandconditions" className="form-check-input border border-dark" onClick={this.handlePrimaryDND} defaultChecked={ this.state.primaryDNDCheck ? true : false } />
                                            <label htmlFor="termsandconditions" className="form-check-label">I allow BJD to contact me through Call, SMS, Email and WhatsApp.</label>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className={`form-group text-center ${this.state.OTPVerificationStatus ? 'd-block' : 'd-none'}`} >
                                            <input type="button" value={`${this.state.isLoading ? 'Loading...' : 'Submit'}`} className="btn btn-success fs-5" onClick={this.handleMemberShipSubmit} />
                                            <span className="text-danger fw-600 d-block">{this.state.formError ? this.state.formError : ''}</span>
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </>
        );
    }
}

export default withParams(MemberShipForm);