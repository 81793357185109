import React from "react";
import { postVolunteerComment, getVolunteerComments } from '../../services/Voters';

class AddNewComment extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			comment: '',
			resp: '',
			error: '',
			commentError:''
		};
		this.handleVolunteerComment = this.handleVolunteerComment.bind(this);
		this.handleSubmit = this.handleSubmit.bind(this);
	}

	handleVolunteerComment(event) {
		let comment = event.target.value;
		this.setState({comment});
	}

	handleSubmit() {

		this.setState({commentError:''});
		if(this.state.comment == '')
		{
			this.setState({commentError: 'Please add comment.'});
		}
		else{
			let data = {text: this.state.comment};
			postVolunteerComment(data).then((resp) => {
				this.props.reloadComments();
				this.setState({resp, comment: ''});
			}).catch((error) => {
				this.setState({error});
			})
		}
	}

	render() {
		return(
			<div className="card">
				<div className="card-body">
					<div className="row">
						<div className="col-12">
							<div className="form-group">
								<textarea name="volunteerComment" id="volunteerComment" rows="5" className="form-control" placeholder="Please use this space to post your comments." value={this.state.comment} onChange={this.handleVolunteerComment}></textarea>
								<span className="text-danger fw-600">{this.state.commentError && this.state.commentError}</span>
							</div>
							<div className="form-group text-center">
								<button type="submit" className="btn btn-success" onClick={this.handleSubmit}>Add Comment</button>
							</div>
						</div>
					</div>
				</div>
			</div>
		)
	}
}

export default AddNewComment;