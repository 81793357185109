import React from "react";
import { BRANDING_HEADER_TITLE } from '../config/defaults';

class LayoutOuterHeader extends React.Component {
    constructor(props) {
        super(props);
        this.state = {

        }
    }

    render() {
        return(
            <>
                <nav className="main-header navbar navbar-expand navbar-white navbar-light ms-0 brand-header-color">
                <a href="/" className="navbar-brand ps-3">
                    <img src={'/bjd-logo.webp'} alt="AdminLTE Logo" className="brand-image img-circle elevation-3" width={`80px`} height={`80px`} />
                </a>
                    <div className="container text-center">
                        <span className="navbar-brand mx-auto fs-2 text-white text-wrap-auto">BJD Digital Membership</span>
                    </div>
                </nav>
            </>
        )
    }
}

export default LayoutOuterHeader;