import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import React, { useEffect } from 'react';
import {useState} from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import './App.css';
import './assets/css/style.css';
import Footer from "components/Footer";
import Layout from "./components/Layout";
import Dashboard from "./components/dashboard/Dashboard";
import Voters from './components/voter/Voters';
import VoterSummary from "components/voter/VoterSummary";
import HouseIncharges from './components/housesincharges/HouseIncharges';
import UpdateVoterDetails from './components/voter/UpdateVoterDetails';
import VoterDetailsView from "./components/voter/VoterDetailsView";
import AddIncharge from './components/housesincharges/AddIncharges';
import PollingStationIncharges from './components/dashboard/PollingStationIncharges';
import HouseVisitedCount from './components/dashboard/HouseVisitedCount';
import CommentsOfVolunteer from "./components/dashboard/CommentsofVolunteer";
import ImportantVoters from "./components/dashboard/ImportantVoters";
import Houses from './components/houses/Houses';
import Elections from './components/elections/Elections';
import NewVolunteer from "./components/elections/NewVolunteer";
import ViewIncharges from './components/housesincharges/ViewIncharge';
import VillageProfile from './components/VillageProfile/VillageProfile';
import VillageProfileList from './components/VillageProfile/VillageProfileList';
import VillageProfileView from './components/VillageProfile/VillageProfileView';
import VillageProfileUpdate from './components/VillageProfile/VillageProfileUpdate';
import VoterSurvey from './components/VoterSurvey/VoterSurvey';
import VoterSurveyList from './components/VoterSurvey/VoterSurveyList';
import VoterSurveyView from './components/VoterSurvey/VoterSurveyView';
import VoterSurveyResponses from './components/VoterSurvey/VoterSurveyResponses';
import VoterSurveySubmit from './components/VoterSurvey/VoterSurveySubmit';
import VolunteerComments from "./components/VolunteerComments/VolunteerComments";
import AddPollingStationIncharge from './components/PollingStationIncharge/AddNewPollingStationIncharge';
import AddNewHouseIncharge from "./components/houses/AddNewHouseIncharge";
import SmsVoters from "./components/Sms/SmsVoters";
import SmsVoterDetailsView from './components/Sms/SmsVoterDetailsView';
import SmsUpdateVoterDetails from './components/Sms/SmsUpdateVoterDetails';
import SmsTemplate from './components/Sms/SmsTemplate';
import ViewBirthdayAlert from './components/birthdayAlerts/ViewBirthdayAlert';
import Login from './components/account/Login';
import AccessDenied from './components/AccessDenied';
import Settings from './components/Settings/Settings';
import NotFound from './components/NotFound';
import ServerIssue from './components/ServerIssue';
import { getAccessToken, STORAGE_KEY } from "axios-jwt";
import UserProfile from './components/account/UserProfile';
import ChangePassword from './components/account/ChangePassword';
import PasswordReset from './components/account/PasswordReset';
import PasswordRestConfirm from './components/account/PasswordRestConfirm';
import { axiosInstance, logout } from './services/Auth';
import {Operations} from './components/toast/operations';
import CacheSettings from "./components/Settings/CacheSettings/CacheSettings";
import HousesListTable from "./components/houses/HousesListTable";
import HouseView from "./components/houses/HouseView";
import EventCalendar from "./components/Calendar/EventCalendar";
import UserAssignEntity from "./components/elections/UserAssignEntity";
import { getUserProfile, getGlobelSettings } from './services/Voters';
import { Roles } from "./config/defaults";
import RotatingLinesLoader from "./components/RotatingLineLoader";
import UserAssignView from './components/elections/UserAssignView';
import MemberShipForm from './components/MemberShipForm';
import MembershipFrontPage from "./components/MembershipFrontPage";
import LayoutOuterHeader from "./components/LayoutOuterHeader";
import PrimaryMembershipForm from './components/PrimaryMembershipForm';
import ActiveMembershipNumber from './components/MembershipThankYou';

const userRole = 'MLA';
function App() {

  const ADMIN = Roles.SUPER_ADMIN.value;
  const AC = Roles.AC_INCHARGE.value;
  const BLOCK = Roles.BLOCK_INCHARGE.value;
  const PS = Roles.POLLING_STATION_INCHARGE.value;
  const HOUSE = Roles.HOUSE_INCHARGE.value;


  axiosInstance.interceptors.response.use(
  response => response,
  error => {
    if(error.message.includes('Got 401')){
        logout();
        onLogout();
    }
    throw error;
  });
  
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [isUserLoaded, setUserLoaded] = useState(false);
  const [userProfile, setUserProfile] = useState(null);
  const [error, setError] = useState(null);
  const [globelSettings, setGlobelSettings] = useState(null);
  const [serverIssueError, setServerError] = useState('');
  const onLogin = () => {
    setIsLoggedIn(true);
    getUserProfile().then((userProfile) => {
      setUserProfile(userProfile);
      setUserLoaded(true);
    }).catch((error) => {
      setError(error);
    })

    getGlobelSettings().then((globelSettings) => {
      setGlobelSettings(globelSettings);
    }).catch((error) => {
      setError(error);
    })
  };

  const onLogout = () => {
    setIsLoggedIn(false);
    setUserProfile(null);
  };

  useEffect(() => {
    const token = getAccessToken();
    if(token) {
      setIsLoggedIn(true);
      getUserProfile().then((userProfile) => {
        setUserProfile(userProfile);
        setUserLoaded(true);
      }).catch((error) => {
        setError(error);
        if(error?.response?.status === 401) {
          localStorage.removeItem(STORAGE_KEY);
        } else if(error?.response?.status === 500) {
          setServerError('Something went wrong. Please try again.');
        } else if(error?.response?.status === 404) {
          setServerError('Something went wrong. Please try again.');
        } else if(error?.response?.status === 0) {
          setServerError('Something went wrong. Please try again.');
        }
      })
    } 
  }, []);

  const token = getAccessToken();

  return (
    <div className="App">
      <div className="wrapper">
        {!token && (
          <BrowserRouter>
          <LayoutOuterHeader />
            <Routes>
              <Route path='*' element={<Navigate to="/" />} />
              <Route path="/" element={<MembershipFrontPage />} />
              <Route path="/membershipform" element={ <MemberShipForm /> } />
              <Route path="/login" element={<Login onLogin={onLogin} onLogout={onLogout}/>} />
              <Route path="/activemembership" element={ <ActiveMembershipNumber /> } />
            </Routes>
          </BrowserRouter>
        )}
        {isUserLoaded && token && (
          <BrowserRouter>
            <Layout onLogout={onLogout} globelSettings={globelSettings} />
            <div className="content-wrapper floating-feed-container ms-0">
              <div className='container-fluid py-4'>
                <Routes>
                  <Route path='*' element={<NotFound />} />
                  {
                    userProfile?.user?.role == ADMIN || userProfile?.user?.role == AC || userProfile?.user?.role == BLOCK || userProfile?.user?.role == PS? 
                      <Route path='/login' element={<Navigate to="/" />}/> : 
                    userProfile?.user?.role == Roles.HOUSE_INCHARGE.value ? 
                      <Route path='/' element={<Navigate to="/houses-list-table" />}/> : <Route path='/login' element={<Navigate to="/" />}/>
                  }
                  <Route path="/" element={<Dashboard />} />
                  <Route path="dashboard" element={ <Dashboard /> } />
                  <Route path="primarymembershipform" element={ <PrimaryMembershipForm /> } />
                  <Route path="/not-found" component={ <NotFound /> } />
                </Routes>
              </div>
            </div>
            <Footer />
          </BrowserRouter>
        )}
        {!isUserLoaded && token && serverIssueError === '' && (
          <RotatingLinesLoader />
        )}
        {
          !isUserLoaded && token && serverIssueError !== '' && (
            <BrowserRouter>
            <Layout onLogout={onLogout} globelSettings={globelSettings} />
            <div className="content-wrapper floating-feed-container ms-0">
              <div className='container-fluid py-4'>
                <Routes>
                  <Route path='*' element={ <ServerIssue error={serverIssueError} /> } />
                </Routes>
              </div>
            </div>
            <Footer />
            </BrowserRouter>
          )
        }
      </div>
    </div>
  );
}

export default App;
