import React from "react";
import { Link } from "react-router-dom";
import { getPrimaryMembershipDetails } from '../services/Voters';
import withParams from "./GetParams";

class ActiveMembershipNumber extends React.Component {
    constructor(props) {
        super(props);
        this.state ={
            membershipDetails: null,
            membershipNumber: this.props.searchParams.get('id')
        };
    }

    render() {
        return(
            <>
                <div className="container">
                    <div className="row pt-5 px-3">
                        <div className="card jbjd-w-md-50 m-auto shadow">
                            <div className="card-body p-md-5 py-5 px-3">
                                <div className="col-12 text-center">
                                    <h3>Your membership number is { this.state.membershipNumber ? this.state.membershipNumber : '' }</h3>
                                </div>
                                <div className="col-12 text-center pt-3">
                                    <h3>Log In to become an Active Member</h3>
                                </div>
                                <div className="col-12 text-center pt-3">
                                    <Link to={`/login`} className="btn btn-success fs-5">Log in</Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        )
    }
}

export default withParams(ActiveMembershipNumber);