import React from 'react';
import Header from './Header';
import Sidebar from './Sidebar';
import { getAccessToken } from "axios-jwt";
import { faHandPointUp, faHouseChimneyUser, faVoteYea, faComments, faMessage, faGears, faGear, faHouse, faTreeCity, faSquarePollHorizontal, faCircle } from '@fortawesome/free-solid-svg-icons';
import { faNoteSticky } from '@fortawesome/free-regular-svg-icons';
import { Roles } from '../config/defaults';
import { getUserProfile } from '../services/Voters';

  const superUserSideBar = [
      {
        name:'Dashboard',
        value: 'dashboard',
        url: '/dashboard',
        children:[]
      },

      {
        name: 'Voter',
        value: 'voters',
        url: '/voters',
        children: [
          {
            name: 'Voter List',
            value: 'voters',
            url: '/voters',
            children: []
          },
          // {
          //   name: 'Voter Summary',
          //   value: 'voter-summary',
          //   url: '/voter-summary',
          //   children: []
          // },
        ]
      },

      {
        name: 'House Management',
        value: 'house-management',
        url: '/house-incharges',
        children: [
          // {
          //   name: 'House Incharges',
          //   value: 'house-incharges',
          //   children: [
          //     {
          //       name: 'Add Incharges',
          //       value: 'add-incharge',
          //       url: '/add-incharge',
          //       children: []
          //     },
          //     {
          //       name: 'List Incharges',
          //       value: 'house-incharges',
          //       url: '/house-incharges',
          //       children: []
          //     }
          //   ]
          // },
          {
            name: 'Houses',
            value: 'houses',
            url: '/houses',
            children: []
          }
        ]
      },

      {
        name: 'Elections',
        value: 'elections',
        url: '/elections',
        children: [
          {
            name: 'Add Incharges',
            value: 'new-volunteer',
            url: '/new-volunteer',
            children: []
          },
          {
            name:'View Incharges',
            value:'elections',
            url:'/elections',
            children: []
          }
        ]
      },

      {
        name:'Village Profile List',
        value:'village-profile-list',
        url:'/village-profile-list',
        children:[
           {
              name:'View Village Profile',
              value:'village-profile-list',
              url:'/village-profile-list',
              children:[],
            },
            {
              name:'Add Village Profile',
              value:'village-profile',
              url:'/village-profile',
              children:[]
            }
        ]

      },

      {
        name: 'Voter Survey',
        value:'voter-survey-list',
        url: '/voter-survey-list',
        children:[
          {
            name:'View Voter Survey',
            value: 'voter-survey-list',
            url: '/voter-survey-list',
            children:[]
          },
          {
            name:'Add Voter Survey',
            value:'voter-survey',
            url:'/voter-survey',
            children:[]
          }

        ]
      },

      {
        name: 'My comments',
        value: 'volunteerComment',
        url: '/volunteer-comments',
        children:[]
      },

      {
        name: 'SMS',
        value: 'sms-voters',
        children:[
          {
            name: 'Send SMS',
            value: 'sms-voters',
            url: '/sms-voters',
            children:[]
          },
          {
            name: 'SMS Template',
            value: 'sms-template',
            url: '/sms-template',
            children:[]
          },
          {
             name: 'SMS Settings',
             value: 'sms-settings',
             url: '/sms-settings',
             children:[]
          }
        ]
      },
      // {
      //   name:'Birthday Alert',
      //   value:'birthday-alert',
      //   children:[
      //     {
      //       name: 'Birthday Alerts Template',
      //       value: 'view-birthday-alert',
      //       url: '/view-birthday-alert',
      //       children:[], 
      //     },
      //     {
      //       name: 'Birthday Alert Settings',
      //       value: 'birthday-alert-settings',
      //       url: '/birthday-alert-settings',
      //       children:[],
      //     },
      //   ]
      // },
      {
        name:'Settings',
        value:'settings',
        url: '/settings',
        children:[]
      },
      // {
      //   name: 'Calendar',
      //   value: 'calendar',
      //   url: '/event-calendar',
      //   children:[]
      // },
];


const acUserSideBar = [
  {
    name:'Dashboard',
    value: 'dashboard',
    url: '/dashboard',
    children:[]
  },

  {
    name: 'Voter',
    value: 'voters',
    url: '/voters',
    children: [
      {
        name: 'Voter List',
        value: 'voters',
        url: '/voters',
        children: []
      },
      // {
      //   name: 'Voter Summary',
      //   value: 'voter-summary',
      //   url: '/voter-summary',
      //   children: []
      // },
    ]
  },

  {
    name: 'House Management',
    value: 'house-management',
    url: '/house-incharges',
    children: [
      // {
      //   name: 'House Incharges',
      //   value: 'house-incharges',
      //   children: [
      //     {
      //       name: 'Add Incharges',
      //       value: 'add-incharge',
      //       url: '/add-incharge',
      //       children: []
      //     },
      //     {
      //       name: 'List Incharges',
      //       value: 'house-incharges',
      //       url: '/house-incharges',
      //       children: []
      //     }
      //   ]
      // },
      {
        name: 'Houses',
        value: 'houses',
        url: '/houses',
        children: []
      }
    ]
  },

  {
    name: 'Elections',
    value: 'elections',
    url: '/elections',
    children: [
      {
        name: 'Add Incharges',
        value: 'new-volunteer',
        url: '/new-volunteer',
        children: []
      },
      {
        name:'View Incharges',
        value:'elections',
        url:'/elections',
        children: []
      }
    ]
  },

  {
    name:'Village Profile List',
    value:'village-profile-list',
    url:'/village-profile-list',
    children:[
       {
          name:'View Village Profile',
          value:'village-profile-list',
          url:'/village-profile-list',
          children:[],
        },
        {
          name:'Add Village Profile',
          value:'village-profile',
          url:'/village-profile',
          children:[]
        }
    ]

  },

  {
    name: 'Voter Survey',
    value:'voter-survey-list',
    url: '/voter-survey-list',
    children:[
      {
        name:'View Voter Survey',
        value: 'voter-survey-list',
        url: '/voter-survey-list',
        children:[]
      },
      {
        name:'Add Voter Survey',
        value:'voter-survey',
        url:'/voter-survey',
        children:[]
      }

    ]
  },

  {
    name: 'My comments',
    value: 'volunteerComment',
    url: '/volunteer-comments',
    children:[]
  },

  {
    name: 'SMS',
    value: 'sms-voters',
    children:[
      {
        name: 'Send SMS',
        value: 'sms-voters',
        url: '/sms-voters',
        children:[]
      },
      {
        name: 'SMS Template',
        value: 'sms-template',
        url: '/sms-template',
        children:[]
      },
      {
         name: 'SMS Settings',
         value: 'sms-settings',
         url: '/sms-settings',
         children:[]
      }
    ]
  },
  // {
  //   name:'Birthday Alert',
  //   value:'birthday-alert',
  //   children:[
  //     {
  //       name: 'Birthday Alerts Template',
  //       value: 'view-birthday-alert',
  //       url: '/view-birthday-alert',
  //       children:[], 
  //     },
  //     {
  //       name: 'Birthday Alert Settings',
  //       value: 'birthday-alert-settings',
  //       url: '/birthday-alert-settings',
  //       children:[],
  //     },
  //   ]
  // },
  {
    name:'Settings',
    value:'settings',
    url: '/settings',
    children:[]
  },
  // {
  //   name: 'Calendar',
  //   value: 'calendar',
  //   url: '/event-calendar',
  //   children:[]
  // },
];


const UserSideBar = [
  {
    name:'Dashboard',
    value: 'dashboard',
    url: '/dashboard',
    children:[]
  },

  {
    name: 'Voter',
    value: 'voters',
    url: '/voters',
    children: [
      {
        name: 'Voter List',
        value: 'voters',
        url: '/voters',
        children: []
      },
      // {
      //   name: 'Voter Summary',
      //   value: 'voter-summary',
      //   url: '/voter-summary',
      //   children: []
      // },
    ]
  },

  {
    name: 'House Management',
    value: 'house-management',
    url: '/house-incharges',
    children: [
      // {
      //   name: 'House Incharges',
      //   value: 'house-incharges',
      //   children: [
      //     {
      //       name: 'Add Incharges',
      //       value: 'add-incharge',
      //       url: '/add-incharge',
      //       children: []
      //     },
      //     {
      //       name: 'List Incharges',
      //       value: 'house-incharges',
      //       url: '/house-incharges',
      //       children: []
      //     }
      //   ]
      // },
      {
        name: 'Houses',
        value: 'houses',
        url: '/houses',
        children: []
      }
    ]
  },

  {
    name: 'Elections',
    value: 'elections',
    url: '/elections',
    children: [
      {
        name: 'Add Incharges',
        value: 'new-volunteer',
        url: '/new-volunteer',
        children: []
      },
      {
        name:'View Incharges',
        value:'elections',
        url:'/elections',
        children: []
      }
    ]
  },

  // {
  //   name:'Village Profile List',
  //   value:'village-profile-list',
  //   url:'/village-profile-list',
  //   children:[
  //      {
  //         name:'View Village Profile',
  //         value:'village-profile-list',
  //         url:'/village-profile-list',
  //         children:[],
  //       },
  //       {
  //         name:'Add Village Profile',
  //         value:'village-profile',
  //         url:'/village-profile',
  //         children:[]
  //       }
  //   ]

  // },

  {
    name: 'Voter Survey',
    value:'voter-survey-list',
    url: '/voter-survey-list',
    children:[
      {
        name:'View Voter Survey',
        value: 'voter-survey-list',
        url: '/voter-survey-list',
        children:[]
      }
    ]
  },

  {
    name: 'My comments',
    value: 'volunteerComment',
    url: '/volunteer-comments',
    children:[]
  },

  {
    name: 'SMS',
    value: 'sms-voters',
    children:[
      {
        name: 'Send SMS',
        value: 'sms-voters',
        url: '/sms-voters',
        children:[]
      },
      {
        name: 'SMS Template',
        value: 'sms-template',
        url: '/sms-template',
        children:[]
      },
      {
         name: 'SMS Settings',
         value: 'sms-settings',
         url: '/sms-settings',
         children:[]
      }
    ]
  },
  // {
  //   name:'Birthday Alert',
  //   value:'birthday-alert',
  //   children:[
  //     {
  //       name: 'Birthday Alerts Template',
  //       value: 'view-birthday-alert',
  //       url: '/view-birthday-alert',
  //       children:[], 
  //     },
  //     {
  //       name: 'Birthday Alert Settings',
  //       value: 'birthday-alert-settings',
  //       url: '/birthday-alert-settings',
  //       children:[],
  //     },
  //   ]
  // },
  {
    name:'Settings',
    value:'settings',
    url: '/settings',
    children:[]
  },
  // {
  //   name: 'Calendar',
  //   value: 'calendar',
  //   url: '/event-calendar',
  //   children:[]
  // },
];

const houseInchargeSideBar = [
  {
    name: 'Houses List',
    value: 'houses-list-table',
    url: '/houses-list-table',
    children:[],
  }
];

const acInchargeSideBar = acUserSideBar.filter((menu) => {
  return !["Birthday Alert", "SMS"].includes(menu.name);
});

const blockInchargeSideBar = UserSideBar.filter((menu) => {
  return !["Birthday Alert", "SMS"].includes(menu.name);
});

const psInchargeSideBar = UserSideBar.filter((menu) => {
  return !["Birthday Alert", "SMS"].includes(menu.name);
});

const superUserSideBarMenu = acUserSideBar.filter((menu) => {
  return !["Birthday Alert", "SMS"].includes(menu.children.map(obj => obj.name));
});

class Layout extends React.Component {
  constructor(props) {
    super(props);
    let token = getAccessToken();
    this.state = {
      loggedIn: token ? true : false,
      collepsStatus: '',
      userProfile: null
    };

    this.onCollepaseClick = this.onCollepaseClick.bind(this);
  }
  onCollepaseClick(state)
  {
    this.setState({collepsStatus:state});
  }

  componentDidMount() {
    getUserProfile().then((userProfile) => {
      this.setState({userProfile});
    }).catch((error) => {
      this.setState({error});
    })
  }

  render() {
    if(!this.state.loggedIn) return <></>;
    let data = {};
    if(this.state.userProfile?.user?.role == Roles.SUPER_ADMIN.value) {
      data = superUserSideBarMenu;
    }
    else if(this.state.userProfile?.user?.role == Roles.HOUSE_INCHARGE.value) {
      data = houseInchargeSideBar;
    } else if(this.state.userProfile?.user?.role == Roles.AC_INCHARGE.value) {
      data = acInchargeSideBar;
    } else if(this.state.userProfile?.user?.role == Roles.BLOCK_INCHARGE.value) {
      data = blockInchargeSideBar;
    } else if(this.state.userProfile?.user?.role == Roles.POLLING_STATION_INCHARGE.value) {
      data = psInchargeSideBar;
    }
    return(
      <>
      <Header sideBar={this.state.sideBarOption} onLogout={this.props.onLogout} onCollepaseClick={this.onCollepaseClick} />
      </>
    );
  }
}

export default Layout;
