import React from "react";
import { getHouseDetails, getGenderName } from '../../services/Voters';
import withParams from "../GetParams";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit, faEye } from "@fortawesome/free-solid-svg-icons";
import RotatingLinesLoader from "components/RotatingLineLoader";
import Error from '../error';
import { Link } from 'react-router-dom';
import { Roles } from "../../config/defaults";

class HouseView extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			houseDetails: null,
			error: null,
			loaderStatus: false,
			prevPath: null
		};
	}

	componentDidMount() {
		this.setState({loaderStatus: true});
		let { id } = this.props.params;
		const { path } = this.props.params;
		this.setState({prevPath: path});
		getHouseDetails(id).then((houseDetails) => {
			this.setState({houseDetails, loaderStatus: false});
		}).catch((error) => {
			this.setState({error, loaderStatus: false});
		})
	}

	render() {
		const houseDetails = this.state.houseDetails;
		return(
			<div>
				<div className="card">
					<div className="card-body">
						{
							this.state.houseDetails != null || this.state.houseDetails != undefined ? 
							<div>
								<div className="text-center">
									<h4>House View</h4>
								</div>
								<table className="table">
									<tbody>
										<tr>
											<td className="fw-600">House No.</td>
											<td className="w-50">{houseDetails?.house?.house_no ? houseDetails?.house?.house_no : ''}{houseDetails?.house?.society ? houseDetails?.house?.society : ''}</td>
										</tr>
										<tr>
											<td className="fw-600">Polling Station</td>
											<td>
											( {houseDetails?.house?.polling_station?.part_number ? houseDetails?.house?.polling_station?.part_number : ''} )
											{houseDetails?.house?.polling_station?.name ? ` - ${houseDetails?.house?.polling_station?.name}` : `` }{houseDetails?.house?.polling_station?.village?.name ? `, ${ houseDetails?.house?.polling_station?.village?.name }` : `` }
											{houseDetails?.house?.polling_station?.village?.block?.name ? `, ${ houseDetails?.house?.polling_station?.village?.block?.name }` : '' }
											{ houseDetails?.house?.polling_station?.constituency?.name ? `, ${houseDetails?.house?.polling_station?.constituency?.name}` : '' }</td>
										</tr>
									</tbody>
								</table>
								<div className="overflow-x">
									<table className="table table-striped border mt-5">
										<thead>
											<tr>
												<th colSpan={7} className='text-center bg-primary'>Voters</th>
											</tr>
											<tr>
												<th>Name</th>
												<th>Relation</th>
												<th>Gender</th>
												<th>Voter Id</th>
												<th>Age</th>
												<th className="text-center">Action</th>
											</tr>
										</thead>
										<tbody>
										{
											houseDetails?.voters?.map(voter => 
												<tr key={voter?.id_card_number}>
													<td>{voter?.first_name ? voter?.first_name : ''} {voter?.last_name ? voter?.last_name : ''}</td>
													<td>{voter?.relation_firstname ? voter?.relation_firstname : '' } {voter?.relation_lastname ? voter?.relation_lastname : ''}</td>
													<td>{voter?.gender ? getGenderName(voter.gender) : ``}</td>
													<td className="text-uppercase">{voter?.id_card_number}</td>
													<td>{voter?.age}</td>
													<td className="text-center">
														{
															this.props.role == Roles.HOUSE_INCHARGE.value ? 
															<Link to={`/update-voter-details/${this.state.prevPath}/${voter.id_card_number}`}><FontAwesomeIcon icon={ faEdit } /></Link> : <Link to={`/voter-details-view/${this.state.prevPath}/${voter.id_card_number}`}><FontAwesomeIcon icon={ faEye } /></Link>
														}
													</td>
												</tr>
											)
										}
										</tbody>
									</table>
								</div>
								<div>
									{this.state.prevPath ? <Link to={`/${this.state.prevPath}`}  className='btn btn-warning'>Back</Link> : '' }
								</div>
							</div> : this.state.loaderStatus == true ? <RotatingLinesLoader /> 
							: this.state.error != null ? <Error /> : <div className="text-center fw-600 fs-6 text-black-50 my-5">No data found</div>
						}
					</div>
				</div>
			</div>
		)
	}
}

export default withParams(HouseView);