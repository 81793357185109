import React from "react";
import { getUserBlockAssignedEntities, putRemoveAssignedRole } from '../../services/Voters';
import Pagination from "../Pagination";
import RotatingLinesLoader from "components/RotatingLineLoader";
import Error from '../error';
import withParams from "../GetParams";
import { Operations } from "../toast/operations";

class BlockAssignment extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			blockEntities: null,
			pageNumber: 1,
			loading: false
		}
		this.handleEntities = this.handleEntities.bind(this);
		this.handleRemove = this.handleRemove.bind(this);
		this.onChangePage =this.onChangePage.bind(this);
	}

	handleRemove(event) {
		putRemoveAssignedRole(this.props.userId, {id: event}).then((data) => {
			this.props.navigate(`/user-assign-view/${this.props.userId}/?op=${Operations.BLOCK_ASSIGNED_ENTITIES}&status=success`);
			this.handleEntities(this.props.userId, this.state.pageNumber, this.props.pageSize);
		}).catch((error) => {
			this.props.navigate(`/user-assign-view/${this.props.userId}/?op=${Operations.BLOCK_ASSIGNED_ENTITIES}&status=error`);
			this.setState({error});
		})
	}

	onChangePage(event) {
		this.handleEntities(this.props.userId, event, this.props.pageSize, this.props.selectedConstituency);
	}

	handleEntities(userId, pageNumber, pageSize, selectedConstituency) {
		this.setState({blockEntities: null, loading: true});
		getUserBlockAssignedEntities(userId, pageNumber, pageSize, selectedConstituency).then((blockEntities) => {
			this.setState({blockEntities, pageNumber, loading: false});
		}).catch((error) => {
			this.setState({error, loading: false});
		})
	}

	componentDidUpdate(prevProps) {
		if(prevProps.selectedConstituency !== this.props.selectedConstituency) {
			this.handleEntities(this.props.userId, this.state.pageNumber, this.props.pageSize, this.props.selectedConstituency);
		}
	}

	render() {
		return(
			<div className="overflow-x">
				{
					this.state.blockEntities != null && this.state.blockEntities?.data?.length != 0 ? 
						<>
							<table className="table">
								<thead>
									<tr>
										<th className="align-middle">Entities</th>
										<th className="w-10 text-center align-middle">Action</th>
									</tr>
								</thead>
								<tbody>
									{
										this.state.blockEntities?.data?.map(block => 
											<tr key={`${this.state.data?.entities?.length}-${block?.id}`}>
												<td className="align-middle">{`${block?.name}`}{block?.constituency?.number ? `, ( ${block?.constituency?.number} )` : ''}{block?.constituency ? ` - ${block?.constituency?.name}` : ''}</td>
												<td className="text-center align-middle">
													<button className='btn btn-danger' onClick={() => {this.handleRemove(block?.id)}}>Remove</button>
												</td>
											</tr>
										)
									}
								</tbody>
							</table>
							<div className="text-center">
							{
								this.state.blockEntities?.count > this.props.pageSize ? 
								<Pagination pageCount={this.state.blockEntities?.total_pages} currentPage={this.state.blockEntities?.current_page - 1} pageSize={this.props.pageSize} onChangePage={this.onChangePage}/> : ''
							}
							</div>
						</> : this.state.loading == true ? 
						<RotatingLinesLoader /> : this.state.error != null ? 
						<Error /> : <div className="text-center fw-600 text-black-50 p-3">No data found</div>
				}
			</div>
		)
	}
}

export default withParams(BlockAssignment);