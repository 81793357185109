import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus, faMinus } from "@fortawesome/free-solid-svg-icons";

class IssuesActionables extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      issuesActionablesArray: [],
      issueError: '',
      affectedPopulationError: '',
      communitiesAffectedError: '',
      suggestedActionableError: ''
    };
    this.issue = React.createRef();
    this.affectedPopulation = React.createRef();
    this.communitiesAffected = React.createRef();
    this.suggestedActionable = React.createRef();
    this.onClickIssuesActionables = this.onClickIssuesActionables.bind(this);
    this.handleIssue = this.handleIssue.bind(this);
    this.handleAffectedPopulation = this.handleAffectedPopulation.bind(this);
    this.handleCommunitiesAffected = this.handleCommunitiesAffected.bind(this);
    this.handleSuggestedActionable = this.handleSuggestedActionable.bind(this);
  }

  handleIssue() {
    this.setState({issueError: ''});
  }

  handleAffectedPopulation() {
    this.setState({affectedPopulationError: ''});
  }

  handleCommunitiesAffected() {
    this.setState({communitiesAffectedError: ''});
  }

  handleSuggestedActionable() {
    this.setState({suggestedActionableError: ''});
  }

  onClickIssuesActionables() {
    let issue = this.issue.current.value;
    let affectedPopulation = this.affectedPopulation.current.value;
    let communitiesAffected = this.communitiesAffected.current.value;
    let suggestedActionable = this.suggestedActionable.current.value;
    if(issue == '') {
      this.setState({issueError: 'Please enter issue.'});
    }
    else if(affectedPopulation == '') {
      this.setState({affectedPopulationError: 'Please enter affected population.'});
    }
    else if(communitiesAffected == '') {
      this.setState({communitiesAffectedError: 'Please enter communities affected.'});
    }
    else if(suggestedActionable == '') {
      this.setState({suggestedActionableError: 'Please enter suggested actionable.'});
    }
    else {
      let issuesActionablesObject = { 'issue': issue, 'affectedPopulation': affectedPopulation, 'communitiesAffected': communitiesAffected, 'suggestedActionable': suggestedActionable, 'profileId':this.props.profileId };
      let issuesActionablesArray = this.state.issuesActionablesArray;
      issuesActionablesArray = [ ...issuesActionablesArray, issuesActionablesObject ];
      this.issue.current.value = '';
      this.affectedPopulation.current.value = '';
      this.communitiesAffected.current.value = '';
      this.suggestedActionable.current.value = '';
      this.props.issuesActionables(issuesActionablesArray);
      this.setState({ issuesActionablesArray });
    }
  }

  removeIssuesActionables(event) {
    const issuesActionablesArray = this.state.issuesActionablesArray;
    let newIssuesActionablesArray = [];
    for(let i=0;i<issuesActionablesArray.length;i++) {
      if(i !== event.index) {
        newIssuesActionablesArray = [ ...newIssuesActionablesArray, issuesActionablesArray[i] ];
      }
    }
    this.props.issuesActionables(newIssuesActionablesArray);
    this.setState({ issuesActionablesArray: newIssuesActionablesArray });
  }

  render() {
    return(
      <table className="table custom-table">
        <thead>
          <tr>
            <th colSpan={6} className="text-center bg-dark bg-opacity-50 custom-table-th-desktop">Issues - Actionables</th>
            <th className="text-center bg-dark bg-opacity-50 custom-table-th-mobile">Issues - Actionables</th>
          </tr>
          <tr className="align-middle custom-table-tr-desktop">
            <th className="text-center w-5" scope="col">Sl. No.</th>
            <th scope="col">Issue</th>
            <th scope="col">Affected population</th>
            <th scope="col">Communities affected</th>
            <th scope="col">Suggested Actionable</th>
            <th className="text-center" scope="col">Action</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td scope="row" data-label=""></td>
            <td data-label="Issue">
              <input className="form-control" type="text" id="issue" name="issue" placeholder="Issue" ref={ this.issue } onChange={this.handleIssue} />
              <span className="text-danger fw-600">{this.state.issueError && this.state.issueError}</span>
            </td>
            <td data-label="Affected Population">
              <input className="form-control" type="number" id="affectedpopulation" name="affectedpopulation" placeholder="Affected population" ref={ this.affectedPopulation } onChange={this.handleAffectedPopulation} />
              <span className="text-danger fw-600">{this.state.affectedPopulationError && this.state.affectedPopulationError}</span>
            </td>
            <td data-label="Communiies Affected">
              <input className="form-control" type="text" id="communitiesaffected" name="communitiesaffected" placeholder="Communities affected" ref={ this.communitiesAffected } onChange={this.handleCommunitiesAffected} />
              <span className="text-danger fw-600">{this.state.communitiesAffectedError && this.state.communitiesAffectedError}</span>
            </td>
            <td data-label="Suggested Actionable">
              <input className="form-control" type="text" id="suggestedactionable" name="suggestedactionable" placeholder="Suggested actionable" ref={ this.suggestedActionable } onChange={this.handleSuggestedActionable} />
              <span className="text-danger fw-600">{this.state.suggestedActionableError && this.state.suggestedActionableError}</span>
            </td>
            <td className="text-center align-middle" data-label="Action"><FontAwesomeIcon icon={ faPlus } className="btn btn-success" onClick={ this.onClickIssuesActionables } /></td>
          </tr>
          {
            this.props.issuesActionablesList?.map((issuesActionables, index) => 
              <tr key={ index }>
                <td scope="row" data-label="Sl. No. ">{ index +1 }</td>
                <td data-label="Issue">
                  <input className="form-control" type="text" id={`issue-${index}-${this.state.issuesActionablesArray.length}`} key={`issue-${index}-${this.state.issuesActionablesArray.length}`} name="issue" placeholder="Issue" defaultValue={ issuesActionables.issue } readOnly/>
                </td>
                <td data-label="Affected Population">
                  <input className="form-control" type="text" id={`affectedpopulation-${index}-${this.state.issuesActionablesArray.length}`} key={`affectedpopulation-${index}-${this.state.issuesActionablesArray.length}`} name="affectedpopulation" placeholder="Affected population" defaultValue={ issuesActionables.affectedPopulation } readOnly/>
                </td>
                <td data-label="Communities Affected">
                  <input className="form-control" type="text" id={`communitiesaffected-${index}-${this.state.issuesActionablesArray.length}`} key={`communitiesaffected-${index}-${this.state.issuesActionablesArray.length}`} name="communitiesaffected" placeholder="Communities affected" defaultValue={ issuesActionables.communitiesAffected } readOnly/>
                </td>
                <td data-label="Suggested Actionable">
                  <input className="form-control" type="text" id={`suggestedactionable-${index}-${this.state.issuesActionablesArray.length}`} key={`suggestedactionable-${index}-${this.state.issuesActionablesArray.length}`} name="suggestedactionable" placeholder="Suggested actionable" defaultValue={ issuesActionables.suggestedActionable } readOnly/>
                </td>
                <td className="text-center align-middle" data-label="Action"><FontAwesomeIcon icon={ faMinus } className="btn btn-danger" onClick={ () => this.removeIssuesActionables({ index }) } /></td>
            </tr>
            )
          }
        </tbody>
      </table>
    )
  }
}

export default IssuesActionables;