import React from "react";
import withParams from "components/GetParams";
import { getUserDetails, getStateConstituencies, getBlocks } from '../../services/Voters';
import { Roles } from '../../config/defaults';
import { Link } from 'react-router-dom';
import PollingStationAssignment from './PollingStationAssignment';
import HousesAssignment from './HousesAssignment';
import AssemblyConstituencyAssignment from './AssemblyConstituencyAssignment';
import BlockAssignment from './BlockAssignment';
import StatusToast from "../toast/StatusToast";
import UserAssignFilter from './UserAssignFilter';

class UserAssignView extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			userId: null,
			userDetails: null,
			error: null,
			data: null,
			pageSize: 25,
			constituencies: [],
			blocks: [],
			villages: [],
			pollingStationList: [],
			selectedConstituency : null,
			selectedBlock: null,
			selectedVillage: null,
			selectedPollingStation: null,
			selectedConstituencyObj: null
		}
		this.loadData = this.loadData.bind(this);
		this.handlePageSize = this.handlePageSize.bind(this);
		this.onApplyFilter = this.onApplyFilter.bind(this);
	}

	onApplyFilter(selectedConstituency, selectedBlock, selectedVillage, selectedPollingStation, constituencies, blocks, villages, pollingStationList, selectedConstituencyObj) {
		this.setState({selectedConstituency, selectedBlock, selectedVillage, selectedPollingStation, constituencies, blocks, villages, pollingStationList, selectedConstituencyObj});
	}

	handlePageSize(event) {
		this.setState({pageSize: event.target.value});
	}

	loadData() {
		const { id } = this.props.params;
		this.setState({userId: id});
		getUserDetails(id).then((userDetails) => {
			this.setState({userDetails});
		}).catch((error) => {
			this.setState({error});
		})
	}

	componentDidMount() {
		this.loadData();

		let selectedConstituency = 86;
		getStateConstituencies().then((constituencies) => {
			getBlocks(selectedConstituency).then((blocks) => {
				this.setState({blocks, selectedConstituency});
			}).catch((error) => {
				this.setState({error});
			})
			this.setState({constituencies});
		}).catch((error) => {
			this.setState({error});
		})
	}

	render() {
		return(
			<div>
				<StatusToast delayTime="3000"/>
				<UserAssignFilter onApplyFilter={this.onApplyFilter} selectedConstituency={this.state.selectedConstituency} selectedBlock={this.state.selectedBlock} selectedVillage={this.state.selectedVillage} selectedPollingStation={this.state.selectedPollingStation} constituencies={this.state.constituencies} blocks={this.state.blocks} villages={this.state.villages} pollingStationList={this.state.pollingStationList} selectedConstituencyObj={this.state.selectedConstituencyObj} user={this.state.userDetails} Roles={Roles} />
				<div className="card">
					<div className="card-body">
						<div className="row mb-2">
							<div className="col-md-6 col-12">
								<div className="d-inline align-middle">Page Size: </div>
								<select name="pageSize" id="pageSize" onChange={this.handlePageSize} className='form-select w-25 d-inline align-middle'>
									<option value="25">25</option>
									<option value="50">50</option>
									<option value="100">100</option>
									<option value="250">250</option>
									<option value="500">500</option>
								</select>
							</div>
							<div className="col-md-6 col-12">
								<Link to={`/user-assign/${this.state.userId}`} className='float-right btn btn-success align-middle'>Assign more</Link>
							</div>
						</div>
						{
							this.state.userDetails?.role == Roles.POLLING_STATION_INCHARGE.value ? 
							<PollingStationAssignment userId={this.state.userId} pageSize={this.state.pageSize} selectedConstituency={this.state.selectedConstituency} selectedBlock={this.state.selectedBlock} selectedVillage={this.state.selectedVillage} /> : 
							this.state.userDetails?.role == Roles.AC_INCHARGE.value ? 
							<AssemblyConstituencyAssignment userId={this.state.userId} pageSize={this.state.pageSize} /> : 
							this.state.userDetails?.role == Roles.BLOCK_INCHARGE.value ? 
							<BlockAssignment userId={this.state.userId} pageSize={this.state.pageSize} selectedConstituency={this.state.selectedConstituency} /> : 
							this.state.userDetails?.role == Roles.HOUSE_INCHARGE.value ? 
							<HousesAssignment userId={this.state.userId} pageSize={this.state.pageSize} selectedConstituency={this.state.selectedConstituency} selectedBlock={this.state.selectedBlock} selectedVillage={this.state.selectedVillage} selectedPollingStation={this.state.selectedPollingStation} /> : ''
						}
					</div>
				</div>
			</div>
		)
	}
}

export default withParams(UserAssignView);