import React from "react";
import { getHouseVisitedCount } from '../../services/Voters';
import Pagination from '../Pagination';
import { RotatingLines } from  'react-loader-spinner';
import Error from '../error';

class HouseVisitedCount extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      houseVisitedCount: [],
      error: '',

    };
    this.onChangePage = this.onChangePage.bind(this);
  }

  componentDidMount() {
    getHouseVisitedCount('', '', '', '').then((houseVisitedCount) => {
      this.setState({houseVisitedCount});
    }).catch((error) => {
        this.setState({error});
    })
  }

  onChangePage(event) {
    getHouseVisitedCount('', '', '', event).then((houseVisitedCount) => {
      this.setState({houseVisitedCount});
    }).catch((error) => {
      this.setState({error});
    })
  }

  render() {
    return(
        <div className="card">
          <div className="card-body">
            <div>
              <h3>House Visited Count</h3>
            </div>
            { this.state.houseVisitedCount != '' ? 
            <table className="table">
              <thead>
                <tr>
                  <td>PollingStation No</td>
                  <td>Assigned Incharges</td>
                  <td>House No.</td>
                  <td>Visited Count</td>
                </tr>
              </thead>
              <tbody>
                {this.state.houseVisitedCount.result && this.state.houseVisitedCount.result.map((visitedCount, index) => 
                  <tr key={ index }>
                    <td>{ visitedCount.pollingStationNo }</td>
                    <td>{ visitedCount.assignedIncharges }</td>
                    <td>{ visitedCount.houseNo }</td>
                    <td>{ visitedCount.visitedCount }</td>
                  </tr>
                  )}
                  <tr>
                    <td colSpan={4} className='text-center align-middle'>
                      <Pagination pageCount={this.state.houseVisitedCount?.total_pages} onChangePage={this.onChangePage} />
                    </td>
                  </tr>
              </tbody> 
            </table> : this.state.error != '' ? 
              <Error /> :
              <div className='data-loader text-center'>
                <RotatingLines strokeColor="grey"
                          strokeWidth="5"
                          animationDuration="0.75"
                          width="96"
                          visible={true} />
              </div>
        }
          </div>
        </div>
    )
  }
}

export default HouseVisitedCount;