import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus, faMinus, faTurkishLira } from "@fortawesome/free-solid-svg-icons";
import { getNameFromList } from '../../services/Voters';

class UpdatePartyPerceptionAnalysis extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      partyPerceptionArray: this.props.partyPerceptionList,
      partyError: '',
      perceptionError: '',
      strengthsError: '',
      weaknessesError: '',
      tempPartyVal:'',
      loop:[],
    };
    this.party = React.createRef();
    this.perception = React.createRef();
    this.strengths = React.createRef();
    this.weaknesses = React.createRef();
    this.onClickPartyPerception = this.onClickPartyPerception.bind(this);
    this.handleParty = this.handleParty.bind(this);
    this.handlePartyUpdate = this.handlePartyUpdate.bind(this);
    this.handlePerception = this.handlePerception.bind(this);
    this.handleStrengths = this.handleStrengths.bind(this);
    this.handleWeaknesses = this.handleWeaknesses.bind(this);
    this.handleOnChangeForTable = this.handleOnChangeForTable.bind(this);
  }

  handleOnChangeForTable(event){
    this.state.partyPerceptionArray[event.target.dataset.index][event.target.name] = event.target.value;
    let partyPerceptionArray = this.state.partyPerceptionArray;
    this.props.partyPerception(partyPerceptionArray);
    this.setState({partyPerceptionArray:partyPerceptionArray});
  }

  handleParty(event) {
    this.setState({partyError: ''});
    this.setState({tempPartyVal:event.target.value});
  }

  handlePartyUpdate(event) {
    var party ={}
    party.id = event.target.value;
    this.state.partyPerceptionArray[event.target.dataset.index][event.target.name] = party;
    let partyPerceptionArray = this.state.partyPerceptionArray;
    this.props.partyPerception(partyPerceptionArray);
    this.setState({partyPerceptionArray:partyPerceptionArray});
    this.setState({partyError: ''});
  }

  handlePerception() {
    this.setState({perceptionError: ''});
  }

  handleStrengths() {
    this.setState({strengthsError: ''});
  }

  handleWeaknesses() {
    this.setState({weaknessesError: ''});
  }

  onClickPartyPerception() {
    let partyvalue = this.state.tempPartyVal;
    let perception = this.perception.current.value;
    let strengths = this.strengths.current.value;
    let weaknesses = this.weaknesses.current.value;
    if(partyvalue == '') {
      this.setState({partyError: 'Please select a party.'});
    }
    else if(perception == '') {
      this.setState({perceptionError: 'Please enter perception.'});
    }
    else if(strengths == '') {
      this.setState({strengthsError: 'Please enter strengths.'});
    }
    else if(weaknesses == '') {
      this.setState({weaknessesError: 'Please enter weaknesses.'});
    }
    else {
      var party ={}
      party.id = partyvalue;
      let partyPerceptionObject = { 'party': party, 'perception': perception, 'strengths': strengths, 'weaknesses': weaknesses, 'profileId':this.props.profileId };
      let partyPerceptionArray = this.state.partyPerceptionArray;
      partyPerceptionArray = [ ...partyPerceptionArray, partyPerceptionObject ];
      this.party.current.value = '';
      this.perception.current.value = '';
      this.strengths.current.value = '';
      this.weaknesses.current.value = '';
      this.props.partyPerception(partyPerceptionArray);
      this.setState({ partyPerceptionArray });
    }
  }

  removePartyPerception(event) {
    const partyPerceptionArray = this.state.partyPerceptionArray;
    let newPartyPerceptionArray = [];
    for(let i=0;i<partyPerceptionArray.length;i++) {
      if(i !== event.index) {
        newPartyPerceptionArray = [ ...newPartyPerceptionArray, partyPerceptionArray[i] ];
      }
    }
    this.props.partyPerception(newPartyPerceptionArray);
    this.setState({ partyPerceptionArray: newPartyPerceptionArray });
  }

  componentDidUpdate(prevProps) {
    if(this.props.updateInfo != prevProps?.updateInfo){
      let partyPerceptionArray = [];
      this.props?.updateInfo?.map((partyPerception) => 
        partyPerceptionArray = [ ...partyPerceptionArray, partyPerception ]
      );
      this.setState({ partyPerceptionArray: partyPerceptionArray});
      this.props.partyPerception(partyPerceptionArray);
    }
  }

  render() {
    return(
      <table className="table custom-table">
        <thead>
          <tr>
            <th colSpan={6} className="text-center align-middle bg-dark bg-opacity-10 text-dark custom-table-th-desktop">Party perception analysis</th>
            <th className="text-center align-middle bg-dark bg-opacity-10 text-dark custom-table-th-mobile">Party perception analysis</th>
          </tr>
          <tr className="align-middle custom-table-tr-desktop">
            <th className="text-center w-5" scope="col">Sl. No.</th>
            <th scope="col">Party</th>
            <th scope="col">Perception</th>
            <th scope="col">Strengths</th>
            <th scope="col">Weaknesses</th>
            <th className="text-center" scope="col">Action</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td scope="row" data-label=""></td>
            <td data-label="Party">
            <select name="party" id="party" className="form-select" ref={ this.party } onChange={this.handleParty}>
              <option value="">Select Party</option>
              {this.props.opinionList?.map(opinion => <option value={opinion.id} key={opinion.id}>{opinion.name}</option>)}
            </select>
            <span className="text-danger fw-600">{this.state.partyError && this.state.partyError}</span>
            </td>
            <td data-label="Perception">
              <input className="form-control" type="text" id="perception" name="perception" placeholder="Perception" ref={ this.perception } onChange={this.handlePerception} />
              <span className="text-danger fw-600">{this.state.perceptionError && this.state.perceptionError}</span>
            </td>
            <td data-label="Strengths">
              <input className="form-control" type="text" id="strengths" name="strengths" placeholder="Strengths" ref={ this.strengths } onChange={this.handleStrengths} />
              <span className="text-danger fw-600">{this.state.strengthsError && this.state.strengthsError}</span>
            </td>
            <td data-label="Weaknesses">
              <input className="form-control" type="text" id="weaknesses" name="weaknesses" placeholder="Weaknesses" ref={ this.weaknesses } onChange={this.handleWeaknesses} />
              <span className="text-danger fw-600">{this.state.weaknessesError && this.state.weaknessesError}</span>
            </td>
            <td className="text-center align-middle" data-label="Action"><FontAwesomeIcon icon={ faPlus } className="btn btn-success" onClick={ this.onClickPartyPerception } /></td>
          </tr>
          {
            this.state.partyPerceptionArray?.map((partyPerception, index) => 
            <tr key={ index }>
              <td className="text-center align-middle" data-label="Sl. No." >{ index +1 }</td>
              <td data-label="Party">
              <select data-index={index} name="party" id={`party-${index}-${this.state.partyPerceptionArray.length}`} key={`party-${index}-${this.state.partyPerceptionArray.length}`} className="form-select" ref={ this.party } onChange={this.handlePartyUpdate} value={partyPerception.party.id}>
                <option value="">Select Party</option>
                {this.props.opinionList?.map(opinion => <option value={opinion.id} key={opinion.id}>{opinion.name}</option>)}
              </select>
              </td>
              <td data-label="Perception">
                <input className="form-control" data-index={index} type="text" id={`perception-${index}-${this.state.partyPerceptionArray.length}`} key={`perception-${index}-${this.state.partyPerceptionArray.length}`} name="perception" placeholder="perception" defaultValue={ partyPerception.perception } onChange={this.handleOnChangeForTable}/>
              </td>
              <td data-label="Strengths">
                <input className="form-control" data-index={index} type="text" id={`strengths-${index}-${this.state.partyPerceptionArray.length}`} key={`strengths-${index}-${this.state.partyPerceptionArray.length}`} name="strengths" placeholder="Strengths" defaultValue={ partyPerception.strengths } onChange={this.handleOnChangeForTable}/>
              </td>
              <td data-label="Weaknesses">
                <input className="form-control" data-index={index} type="text" id={`weaknesses-${index}-${this.state.partyPerceptionArray.length}`} key={`weaknesses-${index}-${this.state.partyPerceptionArray.length}`} name="weaknesses" placeholder="Weaknesses" defaultValue={ partyPerception.weaknesses } onChange={this.handleOnChangeForTable} />
              </td>
              <td className="text-center align-middle" data-label="Action"><FontAwesomeIcon icon={ faMinus } className="btn btn-danger" onClick={ () => this.removePartyPerception({ index }) } /></td>
            </tr>
            )
          }
        </tbody>
      </table>
    )
  }
}

export default UpdatePartyPerceptionAnalysis;