import React from "react";
import { getUserPollingStationAssignedEntities, putRemoveAssignedRole } from '../../services/Voters';
import Pagination from "../Pagination";
import RotatingLinesLoader from "components/RotatingLineLoader";
import Error from '../error';
import withParams from "../GetParams";
import { Operations } from "../toast/operations";

class PollingStationBlockAssignment extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			psEntities: null,
			pageNumber: 1,
			loading: false
		}
		this.handleEntities = this.handleEntities.bind(this);
		this.handleRemove = this.handleRemove.bind(this);
		this.onChangePage =this.onChangePage.bind(this);
	}

	handleRemove(event) {
		putRemoveAssignedRole(this.props.userId, {id: event}).then((data) => {
			this.props.navigate(`/user-assign-view/${this.props.userId}/?op=${Operations.PS_ASSIGNED_ENTITIES}&status=success`);
			this.handleEntities(this.props.userId, this.state.pageNumber, this.props.pageSize);
		}).catch((error) => {
			this.props.navigate(`/user-assign-view/${this.props.userId}/?op=${Operations.PS_ASSIGNED_ENTITIES}&status=error`);
			this.setState({error});
		})
	}

	onChangePage(event) {
		this.handleEntities(this.props.userId, event, this.props.pageSize, this.props.selectedConstituency, this.props.selectedBlock, this.selectedVillage);
	}

	handleEntities(userId, pageNumber, pageSize, selectedConstituency, selectedBlock, selectedVillage) {
		this.setState({psEntities: null, loading: true});
		getUserPollingStationAssignedEntities(userId, pageNumber, pageSize, selectedConstituency, selectedBlock, selectedVillage).then((psEntities) => {
			this.setState({psEntities, pageNumber, loading: false});
		}).catch((error) => {
			this.setState({error, loading: false});
		})
	}

	componentDidUpdate(prevProps) {
		if(prevProps.selectedConstituency !== this.props.selectedConstituency || prevProps.selectedBlock !== this.props.selectedBlock || prevProps.selectedVillage !== this.props.selectedVillage) {
			this.handleEntities(this.props.userId, this.state.pageNumber, this.props.pageSize, this.props.selectedConstituency, this.props.selectedBlock, this.props.selectedVillage);
		}
	}

	render() {
		return(
			<div className="overflow-x">
				{
					this.state.psEntities != null && this.state.psEntities?.data?.length != 0 ? 
						<>
							<table className="table">
								<thead>
									<tr>
										<th className="align-middle">Entities</th>
										<th className="w-10 text-center align-middle">Action</th>
									</tr>
								</thead>
								<tbody>
									{
										this.state.psEntities?.data?.map(ps => 
											<tr key={`${this.state.data?.entities?.length}-${ps?.part_number}`}>
												<td className="align-middle">{`( ${ps?.part_number} )`}{ps?.name ? ` - ${ps?.name}` : ''}{ps?.village ? `, ${ps?.village?.name}` : ''}{ps?.village?.block ? `, ${ps?.village?.block?.name}` : ''}{ps?.constituency ? `, ${ps?.constituency?.name}` : ''}{ps?.constituency ? ` - ( ${ps?.constituency?.number} )` : ''}</td>
												<td className="text-center align-middle">
													<button className='btn btn-danger' onClick={() => {this.handleRemove(ps?.part_number)}}>Remove</button>
												</td>
											</tr>
										)
									}
								</tbody>
							</table>
							<div className="text-center">
							{
								this.state.psEntities?.count > this.props.pageSize ? 
								<Pagination pageCount={this.state.psEntities?.total_pages} currentPage={this.state.psEntities?.current_page - 1} pageSize={this.props.pageSize} onChangePage={this.onChangePage}/> : ''
							}
							</div>
						</> : this.state.loading == true ? 
						<RotatingLinesLoader /> : this.state.error != null ? 
						<Error /> : <div className="text-center fw-600 text-black-50 p-3">No data found</div>
				}
			</div>
		)
	}
}

export default withParams(PollingStationBlockAssignment);