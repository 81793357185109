import React from "react";
import "react-widgets/styles.css";
import Combobox from "react-widgets/Combobox";
import { searchVillage, getCasteList, getVillageCategory, getOpinion, getReligion, getSubCaste, getInfluentialPosition, getLevelOfInfluence,villageProfileStepOneUpdate,villageProfileStepTwoUpdate,villageProfileStepThreeUpdate,villageProfileStepFourUpdate,villageProfileStepFiveUpdate,villageProfileStepSixUpdate,villageProfileStepSevenUpdate,villageProfileStepEightUpdate,getVoterCount,getVillageProfile } from '../../services/Voters';
import UpdateCasteAnalysis from './UpdateCasteAnalysis';
import UpdatePerceptionAnalysis from './UpdatePerceptionAnalysis';
import UpdatePartyPerceptionAnalysis from './UpdatePartyPerceptionAnalysis';
import UpdateLeaderPerceptionAnalysis from './UpdateLeaderPerceptionAnalysis';
import UpdateIssuesActionables from './UpdateIssuesActionables';
import UpdatePoachableLeaders from './UpdatePoachableLeaders';
import UpdateNeutralInfluencers from './UpdateNeutralInfluencers';
import Stepper from '../Stepper/Stepper';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronLeft, faChevronRight } from "@fortawesome/free-solid-svg-icons";
import { counter } from "@fortawesome/fontawesome-svg-core";
import withParams from '../GetParams';
import {Operations} from '../toast/operations';
import StatusToast from "../toast/StatusToast";
class VillageProfile extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      villages: [],
      villageError: '',
      selectedVillage: [],
      demographics: [],
      categories: [],
      casteAnalysisList: null,
      traditionalVoting: null,
      presentVoting: null,
      partyPerception: null,
      leaderPerception: null,
      issuesActionables: null,
      leadersPoached: null,
      neutralInfluencers: null,
      opinionList: [],
      casteList: [],
      population: '',
      populationError: '',
      category: '',
      categoryError: '',
      religionList: [],
      subCasteList: [],
      activeStep: 8,
      villageProfileError: null,
      villageColor: null,
      casteColor: null,
      traditionalColor: null,
      PartyColor: null,
      leaderPerceptionColor: null,
      issueActionablesColor: null,
      leadersPoachedColor: null,
      neutralInfluencersColor: null,
      influentialPositionList: null,
      levelOfInfluence: null,
      villageProfileID:null,
      voterCount:null,
      villageProfileForUpdate:null,
      villageProfileForUpdateError:null,
    };
    this.onChangeVillage = this.onChangeVillage.bind(this);
    this.handleSunbmit = this.handleSunbmit.bind(this);
    this.casteAnalysis = this.casteAnalysis.bind(this);
    this.traditionalVoting = this.traditionalVoting.bind(this);
    this.presentVotingArray = this.presentVotingArray.bind(this);
    this.partyPerception = this.partyPerception.bind(this);
    this.leaderPerception = this.leaderPerception.bind(this);
    this.issuesActionables = this.issuesActionables.bind(this);
    this.leadersPoached = this.leadersPoached.bind(this);
    this.neutralInfluencers = this.neutralInfluencers.bind(this);
    this.onChangeCategroy = this.onChangeCategroy.bind(this);
    this.handlePopulation = this.handlePopulation.bind(this);
    this.handleSelectedVillage = this.handleSelectedVillage.bind(this);
    this.onChangeStepperStep = this.onChangeStepperStep.bind(this);
  }

  onChangeStepperStep(event, navigateTo) {
    let population = this.state.population;
    let category = this.state.category;

    if(navigateTo == 'next') {
      if(event == 2) {
        if( population == '' || population == undefined || category == '' || category == undefined) {
          this.setState({villageProfileError: 'Please fill the details.', baseStep: 1, villageColor: false});
        }
        else {
          let setponeList = {
            population:this.state.population,
            category_id:this.state.category.id,
          }
          var VillProfileId = this.props.params;
          villageProfileStepOneUpdate(VillProfileId.id,setponeList).then((villageProfileID) =>{
            this.setState({villageProfileID});
          }).catch((error) => {
            this.setState({error});
          });
          this.setState({activeStep: event, villageColor: true, villageProfileError: null});
        }
      }
      if(event == 3) {
        if(this.state.casteAnalysisList == null) {
          this.setState({villageProfileError: 'Please fill the details.', activeStep: 2, casteColor: false});
        }
        else {
          var VillProfileId = this.props.params;
          villageProfileStepTwoUpdate(VillProfileId.id,this.state.casteAnalysisList).then((response) =>{
          }).catch((error) => {
            this.setState({error});
          });
          this.setState({activeStep: event, casteColor: true, villageProfileError: null});
        }
      }
      if(event == 4) {
        if(this.state.traditionalVoting == null || this.state.presentVoting == null) {
          this.setState({villageProfileError: 'Please fill the details.', activeStep: 3, traditionalColor: false});
        }
        else {
          var VillProfileId = this.props.params;
          villageProfileStepThreeUpdate(VillProfileId.id,this.state.traditionalVoting,this.state.presentVoting,VillProfileId.id).then((response) =>{
          }).catch((error) => {
            this.setState({error});
          });
          this.setState({activeStep: event, traditionalColor: true, villageProfileError: null});
        }
      }
  
      if(event == 5) {
        if(this.state.partyPerception == null) {
          this.setState({villageProfileError: 'Please fill the details.', activeStep: 4, PartyColor: false});
        }
        else {
          var VillProfileId = this.props.params;
          villageProfileStepFourUpdate(VillProfileId.id,this.state.partyPerception).then((response) =>{
          }).catch((error) => {
            this.setState({error});
          });
          this.setState({activeStep: event, PartyColor: true, villageProfileError: null});
        }
      }
  
      if(event == 6) {
        if(this.state.leaderPerception == null) {
          this.setState({villageProfileError: 'Please fill the details.', activeStep: 5, leaderPerceptionColor: false});
        }
        else {
          var VillProfileId = this.props.params;
          villageProfileStepFiveUpdate(VillProfileId.id,this.state.leaderPerception).then((response) =>{
          }).catch((error) => {
            this.setState({error});
          });
          this.setState({activeStep: event, leaderPerceptionColor: true, villageProfileError: null});
        }
      }
  
      if(event == 7) {
        if(this.state.issuesActionables == null) {
          this.setState({villageProfileError: 'Please fill the details.', activeStep: 6, issueActionablesColor: false});
        }
        else {
          var VillProfileId = this.props.params;
          villageProfileStepSixUpdate(VillProfileId.id,this.state.issuesActionables).then((response) =>{
          }).catch((error) => {
            this.setState({error});
          });
          this.setState({activeStep: event, issueActionablesColor: true, villageProfileError: null});
        }
      }
  
      if(event == 8) {
        if(this.state.leadersPoached == null) {
          this.setState({villageProfileError: 'Please fill the details.', activeStep: 7, leadersPoachedColor: false});
        }
        else {
          var VillProfileId = this.props.params;
          villageProfileStepSevenUpdate(VillProfileId.id,this.state.leadersPoached).then((response) =>{
          }).catch((error) => {
            this.setState({error});
          });
          this.setState({activeStep: event, leadersPoachedColor: true, villageProfileError: null});
        }
      }
    }
    else {
      this.setState({activeStep: event, villageProfileError: null});
    }
  }

  onChangeVillage(event) {
    searchVillage(event).then((villages) => {
      this.setState({villages});
    }).catch((err) => {
      this.setState({err});
    })
    this.setState({demographics: event, villageError: ''});
  }

  handleSelectedVillage(selectedVillage) {
    this.setState({selectedVillage});
    getVoterCount(selectedVillage?.block?.constituency?.id).then((count) => {
    this.setState({voterCount:count});      
    }).catch((err) => {
      console.error(err);
    })
  }

  componentDidMount() {
    const { id } = this.props.params;
    getVillageCategory().then((categories) => {
      this.setState({categories});
    }).catch((err) => {
      console.log(err);
    })

    getOpinion().then((opinionList) => {
      this.setState({opinionList});
    }).catch((err) => {
      throw err;
    })

    getCasteList().then((casteList) => {
      this.setState({casteList});
    }).catch((err) => {
      throw err;
    })

    getReligion().then((religionList) =>{
      this.setState({religionList});
    }).catch((err) => {
      throw err;
    })

    getSubCaste().then((subCasteList) =>{
      this.setState({subCasteList});
    }).catch((err) => {
      throw err;
    })

    getInfluentialPosition().then((influentialPositionList) => {
      this.setState({influentialPositionList});
    }).catch((error) => {
      this.setState({error});
    })

    getLevelOfInfluence().then((levelOfInfluence) => {
      this.setState({levelOfInfluence:levelOfInfluence});
    }).catch((error) => {
      this.setState({error});
    })

		getVillageProfile(id).then((villageProfileForUpdate) => {
			this.setState({villageProfileForUpdate, id, category:villageProfileForUpdate?.stepone?.category});
      getVoterCount(villageProfileForUpdate?.stepone?.village?.block?.constituency?.id).then((count) => {
        this.setState({voterCount:count});
        this.setState({population:count.count});      
        }).catch((err) => {
      })
      const villages = [];
      villages = [ ...villages, villageProfileForUpdate?.stepone?.village];
      this.setState({villages})
		}).catch((villageProfileForUpdateError) => {
			this.setState({villageProfileForUpdateError});
		})
  }

  onChangeCategroy(event) {
    this.setState({category: event});
  }

  handleSunbmit() {
    var id = this.props.params;
    villageProfileStepEightUpdate(id.id,this.state.neutralInfluencers).then((response) =>{
      this.props.navigate(`/village-profile-list?op=${Operations.EDIT_VILLAGEPROFILE}&status=success`);
    }).catch((error) => {
      this.setState({error});
      this.props.navigate(`/village-profile-list?op=${Operations.EDIT_VILLAGEPROFILE}&status=success`);
    });
  }

  casteAnalysis(casteAnalysisList) {
    this.setState({casteAnalysisList});
  }

  handlePopulation(event) {
    let population = event.target.value;
    this.setState({population, populationError: ''});
  }

  traditionalVoting(traditionalVoting) {
    this.setState({traditionalVoting});
  }

  presentVotingArray(presentVoting) {
    this.setState({presentVoting});
  }

  partyPerception(partyPerception) {
    this.setState({partyPerception});
  }

  leaderPerception(leaderPerception) {
    this.setState({leaderPerception});
  }

  issuesActionables(issuesActionables) {
    this.setState({issuesActionables});
  }

  leadersPoached(leadersPoached) {
    this.setState({leadersPoached});
  }

  neutralInfluencers(neutralInfluencers) {
    this.setState({neutralInfluencers});
  }

  render() {
    const preventMinus = (e) => {
      if (e.code === 'Minus') {
          e.preventDefault();
      }
    };

    const preventPasteNegative = (e) => {
      const clipboardData = e.clipboardData || window.clipboardData;
      const pastedData = parseFloat(clipboardData.getData('text'));
  
      if (pastedData < 0) {
          e.preventDefault();
      }
    };

    const steps = [{lebal: 'Step 1', step: 1, color: this.state.villageColor}, {lebal: 'Step 2', step: 2, color: this.state.casteColor}, {lebal: 'Step 3', step: 3, color: this.state.traditionalColor}, {lebal: 'Step 4', step: 4, color: this.state.PartyColor}, {lebal: 'Step 5', step: 5, color: this.state.leaderPerceptionColor}, {lebal: 'Step 6', step: 6, color: this.state.issueActionablesColor}, {lebal: 'Step 7', step: 7, color: this.state.leadersPoachedColor}, {lebal: 'Step 8', step: 8, color: this.state.neutralInfluencersColor}];
    return(
        <>
          
          <div className="card">
            <StatusToast status='success' delayTime="3000"/>
            <div className="card-body">
            <div className="text-center">
              <span className="mx-auto text-danger fw-600">{this.state.villageProfileError != null ? this.state.villageProfileError : ``}</span>
            </div>
            <div className="overflow-x ps-5">
              <Stepper onChangeStepperStep={this.onChangeStepperStep} steps={steps} active={this.state.activeStep} />
            </div>
            <div className={this.state.activeStep == 1 ? `d-block` : `d-none`}>
              <table className="table custom-table">
                  <thead>
                    <tr>
                      <th colSpan={4} className="text-center custom-table-th-desktop">Village Profile</th>
                      <th className="text-center custom-table-th-mobile">Village Profile</th>
                    </tr>
                    <tr>
                      <th colSpan={4} className="text-center bg-dark bg-opacity-50 fw-700 custom-table-th-desktop">Demographics</th>
                      <th className="text-center bg-dark bg-opacity-50 fw-700 custom-table-th-mobile">Demographics</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td className="align-middle fw-600">Village</td>
                      <td className="col-xs-12 col-md-6">
                        <input className="form-control" id="village" name="village" defaultValue={ this.state.villageProfileForUpdate?.stepone?.village?.name } placeholder="Village Name" type="text" min="0" disabled readOnly />
                        <span className="text-danger fw-600">{this.state.villageError && this.state.villageError}</span>
                      </td>
                    </tr>
                    <tr>
                      <td className="align-middle fw-600">Block</td>
                      <td>
                        <input className="form-control" id="block" name="block" placeholder="Block" type="text" disabled readOnly defaultValue={ this.state.villageProfileForUpdate ? this.state.villageProfileForUpdate?.stepone?.village?.block?.name : '' } />
                      </td>
                    </tr>
                    <tr>
                      <td className="align-middle fw-600">Population</td>
                      <td>
                        <input className="form-control" id="population" name="population" placeholder="Population" type="number" min="0" onChange={this.handlePopulation} onKeyPress={preventMinus} onPaste={preventPasteNegative} defaultValue={this.props.isUpdate == true ? this.state.villageProfileForUpdate?.stepone?.population: ''}/>
                        <span className="text-danger fw-600">{this.state.populationError && this.state.populationError}</span>
                      </td>
                    </tr>
                    <tr>
                      <td className="align-middle fw-600">Voters</td>
                      <td>
                        <input className="form-control" id="voters" name="voters" defaultValue={ this.state.voterCount?.count } placeholder="Voters" type="number" min="0" disabled readOnly />
                      </td>
                    </tr>
                    <tr>
                      <td className="align-middle fw-600">Category</td>
                      <td>
                        <Combobox data={ this.state.categories } placeholder='Select' dataKey='value' textField='name' value={this.state.villageProfileForUpdate?.stepone?.category ? this.state.category : ''} onChange={ this.onChangeCategroy } />
                        <span className="text-danger fw-600">{this.state.categoryError && this.state.categoryError}</span>
                      </td>
                    </tr>
                  </tbody>
                </table>
            </div>
                {this.state.activeStep == 2 && <UpdateCasteAnalysis casteAnalysis={ this.casteAnalysis } casteAnalysisList={this.state.villageProfileForUpdate?.steptwo} casteList={this.state.casteList} religionList={this.state.religionList} subCasteList={this.state.subCasteList} opinionList={this.state.opinionList} profileId = {this.state.villageProfileID} updateInfo = {this.state?.villageProfileForUpdate?.steptwo} isUpdate = {this.props.isUpdate} />}
                {this.state.activeStep == 3 && <UpdatePerceptionAnalysis traditionalVoting={ this.traditionalVoting } traditionalVotingList={this.state?.villageProfileForUpdate?.stepthreeone} presentVotingList={this.state?.villageProfileForUpdate?.stepthreetwo} presentVotingArray={ this.presentVotingArray } profileId = {this.state.villageProfileID} updateInfo = {this.state.villageProfileForUpdate?.stepthreeone} updateInfo2 = {this.state.villageProfileForUpdate?.stepthreetwo} isUpdate = {this.props.isUpdate}/>}
                {this.state.activeStep == 4 && <UpdatePartyPerceptionAnalysis partyPerceptionList={this.state.villageProfileForUpdate?.steptfour} partyPerception={ this.partyPerception } opinionList={this.state.opinionList} profileId = {this.state.villageProfileID} updateInfo = {this.state?.villageProfileForUpdate?.steptfour} isUpdate = {this.props.isUpdate} />}
                {this.state.activeStep == 5 && <UpdateLeaderPerceptionAnalysis leaderPerceptionList={this.state.villageProfileForUpdate?.stepfive} leaderPerception={ this.leaderPerception } opinionList={this.state.opinionList} profileId = {this.state.villageProfileID} updateInfo = {this.state?.villageProfileForUpdate?.stepfive} isUpdate = {this.props?.isUpdate} />}
                {this.state.activeStep == 6 && <UpdateIssuesActionables issuesActionablesList={this.state.villageProfileForUpdate?.stepsix} issuesActionables={ this.issuesActionables } profileId = {this.state.villageProfileID} updateInfo = {this.state.villageProfileForUpdate?.stepsix} isUpdate = {this.props.isUpdate}/>}
                {this.state.activeStep == 7 && <UpdatePoachableLeaders leaderPoachedList={this.state.villageProfileForUpdate?.steptseven} leadersPoached={ this.leadersPoached } opinionList={this.state.opinionList} religionList={this.state.religionList} casteList={this.state.casteList} subCasteList={this.state.subCasteList} profileId = {this.state.villageProfileID} updateInfo = {this.state.villageProfileForUpdate?.stepseven} isUpdate = {this.props.isUpdate} />}
                {this.state.activeStep == 8 && <UpdateNeutralInfluencers religionList={this.state.religionList} neutralInfluencersList={this.state?.villageProfileForUpdate?.stepeight} neutralInfluencers={ this.neutralInfluencers } casteList={this.state.casteList} subCasteList={this.state.subCasteList} influentialPositionList={this.state.influentialPositionList ? this.state.influentialPositionList : [] } levelOfInfluence={this.state.levelOfInfluence} profileId={this.state.villageProfileID} updateInfo = {this.state?.villageProfileForUpdate?.stepeight} isUpdate = {this.props.isUpdate}/>}
                <div className="mt-5 d-flex">
                  <div className="">
                  {
                    this.state.activeStep == 1 ? `` : <button className="btn btn-warning" onClick={ () => {this.onChangeStepperStep(this.state.activeStep - 1)}}><FontAwesomeIcon icon={ faChevronLeft } /> Back</button>
                  }
                  </div>
                  <div className="ms-auto">
                  {
                    this.state.activeStep == steps.length ? 
                    <div className="float-right">
                      <button className="btn btn-warning text-white mx-1">Cancel</button>
                      <button className="btn btn-success mx-1" type="submit" onClick={ this.handleSunbmit }>Submit</button>
                    </div> : 
                    <button className="btn btn-success float-right" onClick={ () => {this.onChangeStepperStep(this.state.activeStep + 1, 'next')}}>Next <FontAwesomeIcon icon={ faChevronRight } /></button>
                  }
                  </div>
                </div>
            </div>
          </div>
        </>
    )
  }
}

export default withParams(VillageProfile);