import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus, faMinus } from "@fortawesome/free-solid-svg-icons";
import { getNameFromList } from '../../services/Voters';
import Combobox from "react-widgets/Combobox";


class LeadersPoached extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      leaderPoachedArray: [],
      leaderNameError: '',
      partyError: '',
      casteError: '',
      reasonError: ''
    };
    this.leaderName = React.createRef();
    this.party = React.createRef();
    this.reason = React.createRef();
    this.religion = React.createRef();
    this.caste = React.createRef();
    this.onClickLeadersPoached = this.onClickLeadersPoached.bind(this);
    this.handleLeaderName = this.handleLeaderName.bind(this);
    this.handleParty = this.handleParty.bind(this);
    this.handleCaste = this.handleCaste.bind(this);
    this.handleReason = this.handleReason.bind(this);
  }

  handleLeaderName() {
    this.setState({leaderNameError: ''});
  }

  handleParty() {
    this.setState({partyError: ''});
  }

  handleCaste() {
    this.setState({casteError: ''});
  }

  handleReason() {
    this.setState({reasonError: ''});
  }

  onClickLeadersPoached() {
    let leaderName = this.leaderName.current.value;
    let party = this.party.current.value;
    let religion = this.religion.current.value;
    let caste = this.caste.current.value;
    let reason = this.reason.current.value;
    if(leaderName == '') {
      this.setState({leaderNameError: 'Please enter leader name.'});
    }
    else if(party == '') {
      this.setState({partyError: 'Please select party.'});
    }
    else if(caste == '') {
      this.setState({casteError: 'Please select caste.'});
    }
    else if(reason == '') {
      this.setState({reasonError: 'Please enter reason.'});
    }
    else {
      let leaderPoachedObject = { 'leaderName': leaderName, 'party': party,'religion':religion ,'caste': caste, 'reason': reason, 'profileId':this.props.profileId };
      let leaderPoachedArray = this.state.leaderPoachedArray;
      leaderPoachedArray = [ ...leaderPoachedArray, leaderPoachedObject ];
      this.leaderName.current.value = '';
      this.party.current.value = '';
      this.caste.current.value = '';
      this.reason.current.value = '';
      this.props.leadersPoached(leaderPoachedArray);
      this.setState({ leaderPoachedArray });
    }
  }

  removeLeadersPoached(event) {
    const leaderPoachedArray = this.state.leaderPoachedArray;
    let newLeaderPoachedArray = [];
    for(let i=0;i<leaderPoachedArray.length;i++) {
      if(i !== event.index) {
        newLeaderPoachedArray = [ ...newLeaderPoachedArray, leaderPoachedArray[i] ];
      }
    }
    this.props.leadersPoached(newLeaderPoachedArray);
    this.setState({ leaderPoachedArray: newLeaderPoachedArray });
  }

  render() {
    return(
      <table className="table custom-table">
        <thead>
          <tr>
            <th className="text-center bg-dark bg-opacity-50 custom-table-th-desktop" colSpan={8}>Leaders who can be poached</th>
            <th className="text-center bg-dark bg-opacity-50 custom-table-th-mobile">Leaders who can be poached</th>
          </tr>
          <tr>
            <th className="text-center bg-dark bg-opacity-10 text-dark custom-table-th-desktop" colSpan={8}>Inactive / Dissatisfied Influential leaders</th>
            <th className="text-center bg-dark bg-opacity-10 text-dark custom-table-th-mobile">Inactive / Dissatisfied Influential leaders</th>
          </tr>
          <tr className="align-middle custom-table-tr-desktop">
            <th className="text-center" scope="col">Sl. No.</th>
            <th scope="col">Leader name</th>
            <th scope="col">Party</th>
            <th scope="col">Religion</th>
            <th scope="col">Caste</th>
            <th scope="col">Reason for Inactiveness / dissatisfaction</th>
            <th className="text-center" scope="col">Action</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td scope="row" data-label=""></td>
            <td data-label="Leader name">
              <input className="form-control" type="text" id="leadername" name="leadername" placeholder="Leader Name" ref={ this.leaderName } onChange={this.handleLeaderName} />
              <span className="text-danger fw-600">{this.state.leaderNameError && this.state.leaderNameError}</span>
            </td>
            <td data-label="Party">
            <select name="party" id="party" className="form-select" ref={ this.party } onChange={this.handleParty} >
              <option value="">Select Party</option>
              { this.props.opinionList.map(opinion => <option value={opinion.id} key={opinion.id}>{opinion.name}</option>)}
            </select>
            <span className="text-danger fw-600">{this.state.partyError && this.state.partyError}</span>
            </td>
            <td data-label="Religion">
            <select name="religion" id="religion" className="form-select" ref={ this.religion }>
              <option value="">Select Religion</option>
              {this.props.religionList?.map(religion => <option value={religion.id} key={religion.id}>{religion.name}</option>)}
            </select>
            </td>
            <td data-label="Caste">
            <select name="caste" id="caste" className="form-select" ref={ this.caste } onChange={this.handleCaste} >
              <option value="">Select Caste</option>
              {this.props.subCasteList?.map(caste => <option value={caste.id} key={caste.id}>{caste.name}</option>)}
            </select>
            <span className="text-danger fw-600">{this.state.casteError && this.state.casteError}</span>
            </td>
            <td data-label="Reason">
              <input className="form-control" type="text" id="reason" name="reason" placeholder="Reason for Inactiveness / dissatisfaction" ref={ this.reason } onChange={this.handleReason} />
              <span className="text-danger fw-600">{this.state.reasonError && this.state.reasonError}</span>
            </td>
            <td className="text-center align-middle" data-label="Action"><FontAwesomeIcon icon={ faPlus } className="btn btn-success" onClick={ this.onClickLeadersPoached } /></td>
          </tr>
          {
            this.props.leaderPoachedList?.map((leaderPoached, index) => 
            <tr key={ index }>
            <td className="text-center align-middle" scope="row" data-label="Sl. No.">{ index +1 }</td>
            <td data-label="Leader name">
              <input className="form-control" type="text" id={`leadername-${index}-${this.state.leaderPoachedArray.length}`} key={`leadername-${index}-${this.state.leaderPoachedArray.length}`} name="leadername" placeholder="Leader Name" defaultValue={ leaderPoached.leaderName } readOnly/>
            </td>
            <td data-label="Party">
              <input className="form-control" type="text" id={`party-${index}-${this.state.leaderPoachedArray.length}`} key={`party-${index}-${this.state.leaderPoachedArray.length}`} name="party" placeholder="Party" defaultValue={ getNameFromList(this.props.opinionList, leaderPoached.party) } readOnly/>
            </td>
            <td data-label="Religion">
              <input className="form-control" type="text" id={`religion-${index}-${this.state.leaderPoachedArray.length}`} key={`religion-${index}-${this.state.leaderPoachedArray.length}`} name="religion" placeholder="Religion" defaultValue={ getNameFromList(this.props.religionList, leaderPoached.religion) } readOnly/>
            </td>
            <td data-label="Caste">
              <input className="form-control" type="text" id={`caste-${index}-${this.state.leaderPoachedArray.length}`} key={`caste-${index}-${this.state.leaderPoachedArray.length}`} name="caste" placeholder="Caste" defaultValue={ getNameFromList(this.props.subCasteList, leaderPoached.caste) } readOnly/>
            </td>
            <td data-label="Reason for Inactiveness / dissatisfaction">
              <input className="form-control" type="text" id={`reason-${index}-${this.state.leaderPoachedArray.length}`} key={`reason-${index}-${this.state.leaderPoachedArray.length}`} name="reason" placeholder="Reason for Inactiveness / dissatisfaction" defaultValue={ leaderPoached.reason } readOnly/>
            </td>
            <td className="text-center align-middle" data-label="Action"><FontAwesomeIcon icon={ faMinus } className="btn btn-danger" onClick={ () => this.removeLeadersPoached({ index }) } /></td>
          </tr>
            )
          }
        </tbody>
      </table>
    )
  }
}

export default LeadersPoached;