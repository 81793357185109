class Operations {
    static get ADD_SURVEY() { return 'addSurvey'; };
    static get SUBMIT_SURVEY_RESPONSE() { return 'submitSurveyResponse'; };
    static get UPDATE_VOTER() { return 'updateVoter'; };
    static get UPDATE_SMS_TEMPLATE() { return 'editsmstemplate'; };
    static get DELETE_SMS_TEMPLATE() { return 'dletesmstemplate'; };
    static get ADD_SMS_TEMPLATE() { return 'addsmstemplate'; };
    static get CREATE_SEND_SMS() { return 'createsendsms'; };
    static get UPDATE_SETTINGS() { return 'updatesettings'; };
    static get UPDATE_SMS_SETTINGS() { return 'updatesmssettings'; };
    static get SEND_PASSWORD_RESET_EMAIL() { return 'sendpasswordresetemail'; };
    static get BIRTHDAY_SMS_TEMPLATE() { return 'birthdaysmstemplate'; };
    static get PASSWORD_RESET() { return 'passwordreset';};
    static get CREATE_USER() { return 'createuser'; };
    static get ASSIGNED_ROLES() { return 'assignedroles'; };
    static get SURVEY_ACTIVATE() { return 'votersurveyactivate'; };
    static get SURVEY_DEACTIVATE() { return 'votersurveydeactivate'; };
    static get SURVEY_ACCESS_STATUS() { return 'votersurveyaccess'; };
    static get AC_ASSIGNED_ENTITIES() { return 'assemblyassignedentities'; };
    static get BLOCK_ASSIGNED_ENTITIES() { return 'blockassignedentities'; };
    static get PS_ASSIGNED_ENTITIES() { return 'pollingstationassignedentities'; };
    static get HOUSES_ASSIGNED_ENTITIES() { return 'housesassignedentities'; };
    static get DELETE_EVENT() { return 'deleteevent'; };
    static get ADD_EVENT() { return 'addevent'; };
    static get UPDATE_EVENT() { return 'updateevent'; };
    static get ADD_VILLAGEPROFILE() {return 'addvillageprofile'; };
    static get EDIT_VILLAGEPROFILE() {return 'editvillageprofile'; };
};

const OPERATION_TOAST_CONFIG = {
    [Operations.ADD_SURVEY] : {heading: 'Voter Survey', successMessage: 'Survey created successfully.', failureMessage: 'Survey creation failed.'},
    [Operations.SUBMIT_SURVEY_RESPONSE] : {heading: 'Survey Response', successMessage: 'Survey Response submitted successfully.', failureMessage: 'Failed to submit survey response.'},
    [Operations.UPDATE_VOTER] : {heading: 'Update Voter', successMessage: 'Voter details updated successfully.', failureMessage: 'Failed to update voter details.'},
    [Operations.UPDATE_SMS_TEMPLATE] : {heading: 'Update SMS Template', successMessage: 'SMS template updated successfully.', failureMessage: 'Failed to update sms template.'},
    [Operations.DELETE_SMS_TEMPLATE] : {heading: 'Delete SMS Template', successMessage: 'SMS template deleted successfully.', failureMessage: 'Failed to delete sms template.'},
    [Operations.ADD_SMS_TEMPLATE] : {heading: 'Add SMS Template', successMessage: 'SMS template added successfully.', failureMessage: 'Failed to add sms template.'},
    [Operations.CREATE_SEND_SMS] : {heading: 'Send SMS', successMessage: 'SMS sent successfully.', failureMessage: 'Failed to send sms.'},
    [Operations.UPDATE_SETTINGS] : {heading: 'Update Settings', successMessage: 'Settings updated successfully.', failureMessage: 'Failed to update settings.'},
    [Operations.UPDATE_SMS_SETTINGS] : {heading: 'Update SMS Settings', successMessage: 'SMS settings updated successfully.', failureMessage: 'Failed to update sms settings.'},
    [Operations.SEND_PASSWORD_RESET_EMAIL] :{heading: 'Password Reset', successMessage:'Email sent successfully on this mail address.',failureMessage:'Something went wrong.'},
    [Operations.CREATE_USER] :{heading: 'Create User', successMessage:'User created successfully.',failureMessage:'User creation failed.'},
    [Operations.BIRTHDAY_SMS_TEMPLATE] :{heading: 'Birthday Alerts', successMessage:'Birthday alerts template save', failureMessage:'Birthday alerts does not save.'},
    [Operations.PASSWORD_RESET] :{heading: 'Password Reset', successMessage:'Password reset successfully', failureMessage:'Password failed reset'},
    [Operations.ASSIGNED_ROLES] :{heading: 'Assined Roles', successMessage:'Roles assigned successfully', failureMessage:'Failed assigning roles.'},
    [Operations.SURVEY_ACTIVATE] :{heading: 'Voter Survey', successMessage:'Voter survey activated successfully', failureMessage:'Failed activate Voter survey.'},
    [Operations.SURVEY_DEACTIVATE] :{heading: 'Voter Survey', successMessage:'Voter survey deactivated successfully', failureMessage:'Failed deactivate Voter survey.'},
    [Operations.SURVEY_ACCESS_STATUS] :{heading: 'Voter Survey', successMessage:'Voter survey access successfully', failureMessage:'You are not allowed to perform this operation.'},
    [Operations.AC_ASSIGNED_ENTITIES] :{heading: 'Assembly Constituency Entities', successMessage:'Assembly constituency entity removed successfully.', failureMessage:'Assembly constituency entity removed failed.'},
    [Operations.BLOCK_ASSIGNED_ENTITIES] :{heading: 'Block Entities', successMessage:'Block entity removed successfully.', failureMessage:'Block entity removed failed.'},
    [Operations.PS_ASSIGNED_ENTITIES] :{heading: 'Polling Station Entities', successMessage:'Polling station entity removed successfully.', failureMessage:'Polling station entity removed failed.'},
    [Operations.HOUSES_ASSIGNED_ENTITIES] :{heading: 'House Entities', successMessage:'House entity removed successfully.', failureMessage:'House entity removed failed.'},
    [Operations.DELETE_EVENT] :{heading: 'Delete Events', successMessage:'Event entity removed successfully.', failureMessage:'Event entity removed failed.'},
    [Operations.ADD_EVENT] :{heading: 'Add Events', successMessage:'Event entity add successfully.', failureMessage:'Event entity add failed.'},
    [Operations.UPDATE_EVENT] :{heading: 'Update Events', successMessage:'Event entity update successfully.', failureMessage:'Event entity update failed.'},
    [Operations.ADD_VILLAGEPROFILE] :{heading: 'Add Village Profile', successMessage:'Village Profile created successfully', failureMessage: 'Village Profile creation failed.'},
    [Operations.EDIT_VILLAGEPROFILE] :{heading: 'Edit village Profile', successMessage:'Village Profile updated successfully', failureMessage: 'Village Profile updated failed'},

};

export {Operations, OPERATION_TOAST_CONFIG};