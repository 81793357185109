import React from 'react';
import { getVoterSurveyQuestionsList,getVoterSurveyResultByGender,getVoterSurveyResultByAge } from '../../services/Voters';
import withParams from '../GetParams';
import { RotatingLines } from  'react-loader-spinner';
import Error from '../error';
import DashboardDoughnut from './DashboardDoughnut';
import { getSurveyStats } from '../../services/Voters';
const CHAR_CODE_A = 97;

class VoterSurveyResponses extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			voterSurvey: [],
			error: '',
			surveyData: null,
			surveyDataByGender:null,
			surveyDataByAge:null,
			surveyDataLoadingOne: true,
			surveyDataLoadingTwo: true,
			surveyDataLoadingThree:true,
		};
	}

	loadDataTotalAnswer() {
		let { id } = this.props.params;
		getVoterSurveyQuestionsList(id).then((voterSurvey) => {
			var count = [];
			var labels = [];
			voterSurvey.responses[0].options.map(item => count.push(item.percentage));
			voterSurvey.responses[0].options.map(item => labels.push(item.option));
			var colors = ['green','yellow','red'];
			let surveyData = {
				name: 'Survey',
				labels,
				datasets: [
				{
				label: 'Voters survey',
				data: count,
				backgroundColor: colors,
				borderColor: ['rgba(255, 255, 255, 1)','rgba(255, 255, 255, 1)'],
				borderWidth: 3
				}
				]
			};
			this.setState({surveyData, surveyDataLoadingOne: false});
		}).catch((error) => {
			this.setState({error});
		})
	}

	loadDataByGender() {
		let { id } = this.props.params;
		getVoterSurveyResultByGender(id).then((voterSurveyByGender) => {
			var count = [];
			var labels = [];
			voterSurveyByGender.responses.map(item => count.push(item.count));
			voterSurveyByGender.responses.map(item => labels.push(item.label));
			var colors = ['green','yellow','red'];
			let surveyDataByGender = {
				name: 'Survey',
				labels,
				datasets: [
				{
				label: 'Voters survey By Gender',
				data: count,
				backgroundColor: colors,
				borderColor: ['rgba(255, 255, 255, 1)','rgba(255, 255, 255, 1)'],
				borderWidth: 3
				}
				]
			};
			this.setState({surveyDataByGender, surveyDataLoadingTwo: false});
		}).catch((error) => {
			this.setState({error});
		})
	}

	loadDataByAge(){
		let { id } = this.props.params;
		getVoterSurveyResultByAge(id).then((voterSurveyByAge) => {
			var count = [];
			var labels = [];
			voterSurveyByAge.responses.map(item => count.push(item.count));
			voterSurveyByAge.responses.map(item => labels.push(item.label));
			var colors = ['green','yellow','red','blue','black'];
			let surveyDataByAge = {
				name: 'Survey',
				labels,
				datasets: [
				{
				label: 'Voters survey By Age',
				data: count,
				backgroundColor: colors,
				borderColor: ['rgba(255, 255, 255, 1)','rgba(255, 255, 255, 1)'],
				borderWidth: 3
				}
				]
			};
			this.setState({surveyDataByAge, surveyDataLoadingThree: false});
		}).catch((error) => {
			this.setState({error});
		})
	}


	componentDidMount() {
		this.loadDataTotalAnswer();
		this.loadDataByGender();
		this.loadDataByAge();

		let { id } = this.props.params;
		getVoterSurveyQuestionsList(id).then((voterSurvey) => {
			this.setState({voterSurvey});
		}).catch((error) => {
			this.setState({error});
		})
	}
	render() {
		return(
			<>
			<div className='row'>
				<DashboardDoughnut data={this.state.surveyData} error={this.props.surveyError} loading={this.state.surveyDataLoadingOne} />	
				<DashboardDoughnut data={this.state.surveyDataByGender} error={this.props.surveyError} loading={this.state.surveyDataLoadingTwo} />
				<DashboardDoughnut data={this.state.surveyDataByAge} error={this.props.surveyError} loading={this.state.surveyDataLoadingThree} />
			</div>
			<div className="card width-60 m-auto">
				<div className="card-body">
					{
						this.state.voterSurvey != '' ?
						<div>
							<div className="text-center fw-700 fs-5 py-3">Voter Survey</div>
								<table className="table">
									<tbody>
										<tr>
											<th className="w-50">Name</th>
											<td>{this.state.voterSurvey.survey.name}</td>
										</tr>
										<tr>
											<th>Created on</th>
											<td>{this.state.voterSurvey.survey.created_on}</td>
										</tr>
										<tr>
											<th>Total Responses Count</th>
											<td>{this.state.voterSurvey.response_count}</td>
										</tr>
									</tbody>
								</table>
								<table className="table">
									<thead>
										<tr className="bg-dark bg-opacity-25 text-dark">
											<th className="w-8 text-center">Sl. No.</th>
											<th>Question</th>
										</tr>
									</thead>
									<tbody>
									{
										this.state.voterSurvey.responses?.map((response, index) => 
											<tr key={index}>
												<td className="text-center align-middle fw-700">{index + 1}</td>
												<td>
													<div className="fw-600">{response.question}</div><br />
														{
															response.options.map((option, index) => 
																<div key={response.options.length + index}>
																	<span className="fw-500">({String.fromCharCode(CHAR_CODE_A + index)})</span> <span>{option.option} - {option.count} ( {option.percentage}% )</span>
																</div>
															)
														}
												</td>
											</tr>
										)
									}
									</tbody>
								</table>
						</div> : this.state.error != '' ? 
						<Error /> :
						<div className='data-loader text-center'>
							<RotatingLines strokeColor="grey"
			                  strokeWidth="5"
			                  animationDuration="0.75"
			                  width="96"
			                  visible={true} />
						</div>
					}
				</div>
			</div>
		</>
		)
	}
}

export default withParams(VoterSurveyResponses);