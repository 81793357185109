import React from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTriangleExclamation } from '@fortawesome/free-solid-svg-icons';
import { Link } from "react-router-dom";
class ServerIssue extends React.Component {
    constructor(props) {
        super(props);
        this.state ={};
    }

  render() {
    console.log(this.props.error, 'error');
	return(
		<div className="text-center text-danger align-middle pt-p-20">
			<FontAwesomeIcon icon={ faTriangleExclamation } className='fs-1' />
			<h3>{this.props.error ? this.props.error : ''}</h3>
		</div>
	)
  }
}

export default ServerIssue;