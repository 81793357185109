import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus, faMinus } from "@fortawesome/free-solid-svg-icons";

class PerceptionAnalysis extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      traditionalVotingArray: [],
      presentVotingArray: [],
      traditionalVotingTrendError: '',
      presentVotingTrendError: ''
    };
    this.onClickTraditionalVoting = this.onClickTraditionalVoting.bind(this);
    this.traditionalVotingTrend = React.createRef();
    this.removeTraditionalVoting = this.removeTraditionalVoting.bind(this);
    this.onClickPresentVotingTrend = this.onClickPresentVotingTrend.bind(this);
    this.presentVotingTrend = React.createRef();
    this.removePresentVotingTrend = this.removePresentVotingTrend.bind(this);
    this.handleTraditionalVotingTrend = this.handleTraditionalVotingTrend.bind(this);
    this.handlePresentVotingTrend = this.handlePresentVotingTrend.bind(this);
  }

  handleTraditionalVotingTrend() {
    this.setState({traditionalVotingTrendError: ''});
  }

  handlePresentVotingTrend() {
    this.setState({presentVotingTrendError: ''});
  }

  onClickTraditionalVoting() {
    let traditionalVotingTrend = this.traditionalVotingTrend.current.value;
    traditionalVotingTrend && this.setState({traditionalVotingTrendError: ''});
    if(traditionalVotingTrend == '') {
      this.setState({traditionalVotingTrendError: 'Please enter traditional voting trend.'});
    }
    else {
      let traditionalVotingObject = { 'traditionalVotingTrend': traditionalVotingTrend };
      let traditionalVotingArray = this.state.traditionalVotingArray;
      traditionalVotingArray = [ ...traditionalVotingArray, traditionalVotingObject ];
      this.traditionalVotingTrend.current.value = '';
      this.props.traditionalVoting(traditionalVotingArray);
      this.setState({ traditionalVotingArray });
    }
  }


  removeTraditionalVoting(event) {
    const traditionalVotingArray = this.state.traditionalVotingArray;
    let newTraditionalVotingArray = [];
    for(let i=0;i<traditionalVotingArray.length;i++) {
      if(i !== event.index) {
        newTraditionalVotingArray = [ ...newTraditionalVotingArray, traditionalVotingArray[i] ];
      }
    }
    this.props.traditionalVoting(newTraditionalVotingArray);
    this.setState({ traditionalVotingArray: newTraditionalVotingArray });
  }

  onClickPresentVotingTrend() {
    let presentVotingTrend = this.presentVotingTrend.current.value;
    if(presentVotingTrend == '') {
      this.setState({presentVotingTrendError: 'Please enter present voting trend.'});
    }
    else {
      let presentVotingObject = { 'presentVotingTrend': presentVotingTrend };
      let presentVotingArray = this.state.presentVotingArray;
      presentVotingArray = [ ...presentVotingArray, presentVotingObject ];
      this.presentVotingTrend.current.value = '';
      this.props.presentVotingArray(presentVotingArray);
      this.setState({ presentVotingArray });
    }
  }

  removePresentVotingTrend(event) {
    const presentVotingArray = this.state.presentVotingArray;
    let newPresentVotingArray = [];
    for(let i=0;i<presentVotingArray.length;i++) {
      if(i !== event.index) {
        newPresentVotingArray = [ ...newPresentVotingArray, presentVotingArray[i] ];
      }
    }
    this.props.presentVotingArray(newPresentVotingArray);
    this.setState({ presentVotingArray: newPresentVotingArray });
  }

  render() {

    return(
      <table className="table">
        <thead>
          <tr>
            <th colSpan={3} className="text-center bg-dark bg-opacity-50">Perception Analysis</th>
          </tr>
        </thead>
        <tbody>
          <tr className="bg-dark bg-opacity-10 text-dark">
            <td className="text-center fw-700 w-5 align-middle">Sl. No.</td>
            <td colSpan={3} className="text-center fw-700 align-middle">Traditional voting trend in the village</td>
          </tr>
          <tr>
            <td className="text-center" scope="row" data-label=""></td>
            <td scope="row" data-label="Religion">
              <textarea className="form-control" id="traditionalvotingtrend" name="traditionalvotingtrend" placeholder="Traditional Voting Trend in the village" ref={ this.traditionalVotingTrend } onChange={this.handleTraditionalVotingTrend} ></textarea>
              <span className="text-danger fw-600">{this.state.traditionalVotingTrendError && this.state.traditionalVotingTrendError}</span>
            </td>
            <td className="align-middle text-center w-5"><FontAwesomeIcon icon={ faPlus } className="btn btn-success" onClick={ this.onClickTraditionalVoting } /></td>
          </tr>
          {
            this.props.traditionalVotingList?.map((traditionalVoting, index) => 
            <tr key={ index }>
              <td className="text-center align-middle">{ index +1 }</td>
              <td>
                <textarea className="form-control" id={`traditionalvotingtrend-${index}-${this.state.traditionalVotingArray.length}`} key={`traditionalvotingtrend-${index}-${this.state.traditionalVotingArray.length}`} name={`traditionalvotingtrend-${index}-${this.state.traditionalVotingArray.length}`} placeholder="Traditional Voting Trend in the   village" defaultValue={ traditionalVoting.traditionalVotingTrend } readOnly></textarea>
              </td>
              <td className="text-center align-middle">
                <FontAwesomeIcon icon={ faMinus } className="btn btn-danger" onClick={ () => this.removeTraditionalVoting({ index }) } />
              </td>
            </tr>
            )
          }
          <tr className="bg-dark bg-opacity-10 text-dark">
            <td className="text-center align-middle w-5 fw-700 align-middle">Sl. No.</td>
            <td colSpan={3} className="text-center fw-700 align-middle">Present voting trend in the village</td>
          </tr>
          <tr>
            <td></td>
            <td>
              <textarea className="form-control" id="presentvotingtrend" name="presentvotingtrend" placeholder="Present Voting Trend in the village" ref={this.presentVotingTrend} onChange={this.handlePresentVotingTrend}></textarea>
              <span className="text-danger fw-600">{this.state.presentVotingTrendError && this.state.presentVotingTrendError}</span>
            </td>
            <td className="align-middle text-center w-5"><FontAwesomeIcon icon={ faPlus } className="btn btn-success" onClick={ this.onClickPresentVotingTrend } /></td>
          </tr>
          {
            this.props.presentVotingList?.map((presentVoting, index) => 
            <tr key={ index }>
              <td className="text-center align-middle">{ index +1 }</td>
              <td>
                <textarea className="form-control" id={`presentvotingtrend-${index}-${this.state.presentVotingArray.length}`} key={`presentvotingtrend-${index}-${this.state.presentVotingArray.length}`} name="presentvotingtrend" placeholder="Present Voting Trend in the village" defaultValue={ presentVoting.presentVotingTrend } readOnly></textarea>
              </td>
              <td className="text-center align-middle">
                <FontAwesomeIcon icon={ faMinus } onClick={ () => this.removePresentVotingTrend({ index }) } className="btn btn-danger" />
              </td>
            </tr>
            )
          }
        </tbody>
      </table>
    )
  }
}

export default PerceptionAnalysis;