import React from 'react'
import FullCalendar, { formatDate } from '@fullcalendar/react'
import dayGridPlugin from '@fullcalendar/daygrid'
import timeGridPlugin from '@fullcalendar/timegrid'
import interactionPlugin from '@fullcalendar/interaction'
import Modal from 'react-bootstrap/Modal';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import { DateRange} from 'react-date-range';
import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css'; // theme css file
import { getCalendarEvent,addCalendarEvent,updateCalendarEvent,deleteCalendarEvent,getUserProfile } from '../../services/Voters';
import { RotatingLines } from  'react-loader-spinner';
import {Operations} from '../toast/operations';
import StatusToast from "../toast/StatusToast";
import withParams from "components/GetParams";


class EventCalendar extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
        currentEvents : [],
        eventInput :'',
        eventTextArea: '',
        eventUpdateInput :'',
        eventUpdateTextarea:'',
        dataObject : {},
        eventDetailObject :{},
        isDisable : true,
        isUpdate :false,
        addModel: false,
        editModel:false,
        checkBox: 'none',
        startDate:'',
        endDate:'',
        startTime:'',
        endTime:'',
        dateState: [{
          startDate: new Date(),
          endDate: new Date(),
          key: 'selection'
        }],
        currentInitDate: formatDate(new Date(), {
          year: 'numeric',
          month: 'numeric',
          day: 'numeric',
          locale: 'sv'
        }),
        isLoading: true,
        iniEvents : [],
        checkBoxValue:true,
        currentUserId:'',
        createdByState:'',
        eventUserId:'',
        isDeleteAction: false,
        error: null
		  }
      this.handleGetEvents = this.handleGetEvents.bind(this);
	}

  handleGetEvents(currentInitDate) {
    this.setState({isLoading: true, currentInitDate});
    getCalendarEvent(currentInitDate).then((data) => {
    	var obj = {};
    	var arr = [];
    	var extendedProps = {};
    	for(var i = 0; i < data.length; i++){
        obj.title = data[i].title;
        obj.allDay = data[i].event_type;
        obj.start = data[i].start_time;
        obj.end = data[i].end_time;
        extendedProps.description = data[i].description;
        extendedProps.created_on = data[i].created_on;
        extendedProps.created_by = data[i].created_by;
        obj.extendedProps = extendedProps;
        obj.id = data[i].id;
        arr.push(obj);
        obj = {};
        extendedProps = {};
    	}
      this.setState({iniEvents: arr, isLoading: false});
    }).catch((error) => {
    this.setState({isLoading: false, error});
    })
  }


  componentDidMount(){
    getUserProfile().then((UserProfile) =>{
      this.setState({currentUserId: UserProfile.user.id});
    }).catch((error) => {
      this.setState({error});
    });
    this.handleGetEvents(this.state.currentInitDate);
  }

  render() {
    return (
      <>
      {this.state.isLoading != true ?
      <div className='demo-app'>
        <StatusToast status='Voter Survey' delayTime="3000"/>
        { this.renderSidebar() }
        <div className='demo-app-main'>
          <FullCalendar
            plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}
            customButtons = {{
              myCustomNext: {
                text: 'Next',
                click: () => {
                    var date = new Date(this.state.currentInitDate);
                    var newDate = new Date(date.setMonth(date.getMonth()+1));
                    let formate = formatDate(newDate, {
                          year: 'numeric',
                          month: 'numeric',
                          day: 'numeric',
                          locale: 'sv'
                    })
                  this.handleGetEvents(formate);
                }
              }, 
              myCustomPrev: {
                text: 'Prev',
                click: () => {
                    var date = new Date(this.state.currentInitDate);
                    let formate = formatDate(new Date(date.setMonth(date.getMonth()-1)), {
                          year: 'numeric',
                          month: 'numeric',
                          day: 'numeric',
                          locale: 'sv'
                    })
                    this.handleGetEvents(formate);
                }
              },
                myCustomToday: {
                  text: 'Today',
                  click: () => {
                  let formate = formatDate(new Date(), {
                    year: 'numeric',
                    month: 'numeric',
                    day: 'numeric',
                    locale: 'sv'
                  })
                  this.handleGetEvents(formate);
                  this.setState({currentInitDate: formate, isLoading: true});
                  },
                }
            }
          }
            headerToolbar={{
              left: 'myCustomPrev,myCustomToday',
              center: 'title',
              right: 'dayGridMonth,timeGridWeek,timeGridDay,myCustomNext'
            }}
            initialView='dayGridMonth'
            editable={false}
            selectable={true}
            selectMirror={true}
            dayMaxEvents={true}
            initialEvents={this.state.iniEvents}
            initialDate={this.state.currentInitDate}
            select={this.handleDateSelect}
            eventContent={renderEventContent} 
            eventClick={this.handleEventClick}
            eventsSet={this.handleEvents}
          />
        </div>
      </div> : <div className='data-loader text-center m-5'>
							<RotatingLines strokeColor="grey"
								strokeWidth="5"
								animationDuration="0.75"
								width="96"
								visible={true} />
						</div> }
      </>
    )
  }

  renderSidebar() {
    return (
        
        <div className='demo-app-sidebar'>
            <div className='demo-app-sidebar-section'>
            <Modal show={this.state.addModel} onHide={this.closeAddModel} backdrop="static" keyboard={false} centered>
                <Modal.Header closeButton>
                    <Modal.Title>Create Event</Modal.Title>
                </Modal.Header>
                <Modal.Body> 
                  <Container>
                    <Row>
                      <Col xs={12} md={12}>
                        <input type='text' name='event-title' className='form-control mb-3' placeholder='Please Add Event Name' onChange={this.handleEventInput}/>
                        <textarea className="form-control mb-3" onChange={this.handelEventDescription}></textarea>
                      </Col>
                    </Row>
                    <Row>
                      <Col xs={12} md={12}>
                        <input type="checkbox" onClick={this.handleCheckbox} name="checkbox" defaultChecked={this.state.checkBoxValue}/>
                        <label htmlFor="checkbox">&nbsp;&nbsp;&nbsp;All day Events</label>
                      </Col>
                    </Row>
                    <Row className='md-3' style={{display:this.state.checkBox}}>
                      <Col xs={12} md={12}>
                        <label>Select Date</label><br />
                        <DateRange moveRangeOnFirstSelection={true} editableDateInputs={true} onChange={item => this.setState({dateState:[item.selection]})} ranges={this.state.dateState} />
                      </Col>
                    </Row>
                    <Row className='md-3' style={{display:this.state.checkBox}}>
                      <Col xs={6} md={6}>
                        <label>Start Time</label>
                        <input type="time" className='form-control' onChange={this.handleStartTime} defaultValue={this.state.startTime} />
                      </Col>
                      <Col xs={6} md={6}>
                        <label>End Time</label>
                        <input type="time" className='form-control' onChange={this.handleEndTime} defaultValue={this.state.endTime}/>
                      </Col>
                    </Row>
                  </Container>
                </Modal.Body>
                <Modal.Footer>
                    <button className="btn btn-danger align-bottom mr-2"  onClick={this.closeAddModel}>Close</button>
                    <button className="btn btn-success" onClick={this.addEvent}>Save</button>
                </Modal.Footer>
            </Modal>

{/* Update Model start */}
            <Modal show={this.state.editModel} onHide={this.closeEditModel} backdrop="static" keyboard={false} centered>
                <Modal.Header closeButton>
                    <Modal.Title>Create Event</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                  <Container>
                    { this.state.isDeleteAction == true ? <Row>
                        <Col xs={12} md={12}>
                         <h4>Do You Want To Delete This Event ?</h4>
                        </Col>
                      </Row> : <><Row>
                        <Col xs={12} md={12}>
                          <input type='text' name='event-title' className='form-control mb-3' placeholder='Please Add Event Name' onChange={this.handleEventUpdateInput} defaultValue={this.state.eventUpdateInput} disabled={this.state.isDisable} />
                          <textarea onChange={this.handelEventUpdateDescription} defaultValue={this.state.eventUpdateTextarea} disabled={this.state.isDisable} className="form-control mb-3"></textarea>
                        </Col>
                      </Row>
                      <Row>
                        <Col xs={8} md={8}>
                          <input type="checkbox" onClick={this.handleCheckbox} name="checkbox" defaultChecked={this.state.checkBoxValue} disabled={this.state.isDisable}/>
                          <label htmlFor="checkbox">&nbsp;&nbsp;&nbsp;All day Events</label>
                        </Col>
                        <Col xs={4} md={4}>
                          <h5>{`Created On ${this.state.createdByState || ''} `}</h5>
                        </Col>
                      </Row>
                      <Row className='md-3' style={{display:this.state.checkBox}}>
                        <Col xs={12} md={12}>
                          <label>Select Date</label><br />
                          <DateRange moveRangeOnFirstSelection={true} editableDateInputs={true} onChange={item => this.setState({dateState:[item.selection]})} ranges={this.state.dateState} />
                        </Col>
                      </Row>
                      <Row className='md-3' style={{display:this.state.checkBox}}>
                        <Col xs={6} md={6}>
                          <label>Start Time</label>
                          <input type="time" className='form-control' onChange={this.handleStartTime} defaultValue={this.state.startTime} disabled={this.state.isDisable}/>
                        </Col>
                        <Col xs={6} md={6}>
                          <label>End Time</label>
                          <input type="time" className='form-control' onChange={this.handleEndTime} defaultValue={this.state.endTime} disabled={this.state.isDisable}/>
                        </Col>
                      </Row></> }
                    </Container>
                </Modal.Body>
                <Modal.Footer>
                 { this.state.isDeleteAction == true ?
                    <>
                    {this.state.eventUserId == this.state.currentUserId ? <button className='btn btn-success align-left' onClick={this.handleDeleteEvent}>Confirm</button> : ''} 
                    <button className="btn btn-danger align-bottom mr-2"  onClick={this.closeEditModel}>Close</button>
                    </>
                    :
                    <>
                    {this.state.eventUserId == this.state.currentUserId ? <button className='btn btn-danger align-left' onClick={this.handleDeleteConfirm}>Delete</button> : ''}
                    {this.state.eventUserId == this.state.currentUserId ? this.state.isUpdate == true ? <button type="button m-auto" className="btn btn-success" onClick={this.handleUpdate}>Update</button> :<button className="btn btn-primary align-bottom mr-2" onClick={this.handleEdit}>Edit</button> : ''}
                    <button className="btn btn-danger align-bottom mr-2"  onClick={this.closeEditModel}>Close</button>
                    </>}
                </Modal.Footer>
            </Modal>
{/* Update Model End */}
        </div>
        <div className='demo-app-sidebar-section'>
            <table className='table w-50 border-top table-responsive-sm table-striped'>
              <thead>
                <tr className='fw-600 bg-primary'>
                  <th colSpan={4} className="text-center">All Events ({this.state.currentEvents?.length})</th>
                </tr>
              </thead>
              <tbody>
                {this.state.currentEvents?.map((event, index) => 
                  <tr key={event.id}>
                    <td className="text-center">{index + 1}</td>
                    <td>{formatDate(event.start, {year: 'numeric', month: 'short', day: 'numeric'})}</td>
                    <td>{event.title}</td>
                    <td>{event.extendedProps.description}</td>
                  </tr>
                )}
              </tbody>
            </table>
        </div>
    </div>
    )
  }

// Mothod to Delete Event
    handleDeleteEvent = (id) =>{
      deleteCalendarEvent(this.state.eventDetailObject.event.id).then((data) => {
        this.state.eventDetailObject.event.remove();
        this.setState({editModel:false});
        this.setState({isUpdate:false});
        this.setState({isDeleteAction:false});
        this.props.navigate(`/event-calendar?op=${Operations.DELETE_EVENT}&status=success`);
        }).catch((error) => {
					this.props.navigate(`/event-calendar?op=${Operations.DELETE_EVENT}&status=error`);
          console.error(error,'error');
        })
    }

// Method to Add event
    addEvent = () =>{
        var selectInfo = this.state.dataObject;
        let title = this.state.eventInput;
        let description = this.state.eventTextArea;
        let calendarApi = selectInfo.view.calendar;

        let stime;
        let etime;
        let eventType = '';
        let event_json = {};

        if(this.state.checkBox == 'block'){
          stime = this.bindTimeWithDate(this.state.dateState[0].startDate,this.state.startTime);
          etime = this.bindTimeWithDate(this.state.dateState[0].endDate,this.state.endTime);
          eventType = false;
        }
        else{
          stime = selectInfo.startStr;
          etime = selectInfo.endStr;
          eventType = true;
        }

        event_json ={
            title,
            start:stime,
            end: etime,
            allDay: eventType,
            extendedProps: {
                description: description,
                created_by:this.state.currentUserId,
            },
        }
        calendarApi.unselect() 
        if (title) {
          this.setState({isLoading: true});
        addCalendarEvent(event_json).then((response) => {
          calendarApi.addEvent(event_json);
          this.handleGetEvents(this.state.currentInitDate);
          this.setState({addModel: false, eventInput: '', dataObject: {}});
          this.props.navigate(`/event-calendar?op=${Operations.ADD_EVENT}&status=success`);
        }).catch((error) => {
          this.setState({error, isLoading: false});
					this.props.navigate(`/event-calendar?op=${Operations.ADD_EVENT}&status=error`);
        })
        }
    }
// handle allDay Event Checkbox
    handleCheckbox = (event) =>{

      if(event.target.checked == true){
        this.setState({checkBox : 'none'});
        this.setState({checkBoxValue:true});
      }
      else{
        this.setState({checkBox : 'block'});
        this.setState({checkBoxValue:false});
        
      }
    }

// This Function will help you to add time in date object just pass object and time you want to set
    bindTimeWithDate = (dateObject, time) => {
      var s = '';
      var st = '';
      var parts = '';
      var hours = '';
      var minutes = '';
      var st = dateObject;
        parts = time.match(/(\d+)\:(\d+)/);
        hours = /am/i.test(parts[3]) ? parseInt(parts[1], 10) : parseInt(parts[1], 10);
        minutes = parseInt(parts[2], 10);
        st.setHours(hours);
        st.setMinutes(minutes);
        return st;
    }

    timeFormatFunction = (dateObeject) =>{
      if(dateObeject != null) {
        var hours = dateObeject.toLocaleTimeString().split(":");
        var hour = ("0" + hours[0]).slice(-2);
        return hour +':'+hours[1]
      }
	}

// model events
    closeAddModel = () =>{
      this.setState({addModel:false});
    }
    closeEditModel = () =>{
      this.setState({isDeleteAction:false});
      this.setState({isUpdate:false});
      this.setState({editModel:false});
    }

// input events 
    handleStartDate = (event) =>{
      this.setState({startDate:event.target.value});
    }
    handleEndDate = (event) =>{
      this.setState({EndDate:event.target.value});
    }
    handleStartTime = (event) =>{
      this.setState({startTime:event.target.value});
    }
    handleEndTime = (event) =>{
      this.setState({endTime:event.target.value});
    }
    handelEventDescription = (event) =>{
        this.setState({eventTextArea:event.target.value});
    }
    handleEventInput = (event) => {
      this.setState({eventInput:event.target.value});
    }
    handleEventUpdateInput = (event) =>{
      this.setState({eventUpdateInput:event.target.value});
    }
    handelEventUpdateDescription = (event) =>{
      this.setState({eventUpdateTextarea:event.target.value});
    }

//this method calls when you click on any date box
    handleDateSelect = (selectInfo) => {


      // console.log(selectInfo,'selectInfo--dateSelect');
    var endDay = selectInfo.end;
      endDay.setDate(endDay.getDate()-1);
      var val = [{
        startDate: selectInfo.start,
        endDate: endDay,
        key: 'selection'
      }];

      this.setState({dateState:val});

      this.setState({checkBox:'none'});
      this.setState({checkBoxValue:true});
    

      this.setState({dataObject:selectInfo});
      this.setState({addModel:true});

        let expiryDate = new Date(new Date().setHours(new Date().getHours() + 1));
        let expiryDate2 = new Date(new Date().setHours(new Date().getHours() + 2));
        let oneHour = new Date(expiryDate.setMinutes(0));
        let twoHours = new Date(expiryDate2.setMinutes(0));

        this.setState({startTime:oneHour.getHours() + ':' + oneHour.getMinutes()});
        this.setState({endTime:twoHours.getHours() + ':' + twoHours.getMinutes()});
    }

// 
    handleEdit = () => {
        this.setState({isDisable:false});
        this.setState({isUpdate:true});
    }

// method for update events
    handleUpdate = () =>{

        this.setState({isLoading:true});
        var selectInfo = this.state.eventDetailObject;
        let title = this.state.eventUpdateInput;
        let calendarApi = selectInfo.view.calendar;
        let event_json = {};

        calendarApi.unselect();

        var stime = this.bindTimeWithDate(this.state.dateState[0].startDate,this.state.startTime);
        var etime = this.bindTimeWithDate(this.state.dateState[0].endDate,this.state.endTime);

        event_json ={
          title: title,
          start: stime,
          end: etime,
          allDay: this.state.checkBoxValue,
          extendedProps: {
              description: this.state.eventUpdateTextarea,
              created_by:this.state.currentUserId,
          },
        }
        if (title) {
            updateCalendarEvent(selectInfo.event.id, event_json).then((response) => {
              this.handleGetEvents(this.state.currentInitDate);
              this.setState({editModel: false, eventUpdateInput: '', eventUpdateTextarea: '', isDisable: true, isUpdate: false});
              this.props.navigate(`/event-calendar?op=${Operations.UPDATE_EVENT}&status=success`);
            }).catch((error) => {
              this.setState({isLoading: false});
					    this.props.navigate(`/event-calendar?op=${Operations.UPDATE_EVENT}&status=error`);
            })
        }
    }

// handle event click call when you click on event
    handleEventClick = (clickInfo) => {

        var endDay = '';

        if(clickInfo.event.allDay == true){
          this.setState({checkBox:'None'});
          this.setState({checkBoxValue:true});
          endDay = clickInfo.event.end;
          endDay.setDate(endDay.getDate()-1);  
        }
        else{
          this.setState({checkBox:'Block'});
          this.setState({checkBoxValue:false});
          endDay = clickInfo.event.end;
        }
        this.setState({startTime:this.timeFormatFunction(clickInfo.event.start)});
        this.setState({endTime:this.timeFormatFunction(clickInfo.event.end)});
        this.setState({eventDetailObject:clickInfo});
        this.setState({eventUpdateInput:clickInfo.event.title});
        this.setState({eventUpdateTextarea:clickInfo.event.extendedProps.description});
        this.setState({editModel:true});
        this.setState({createdByState:clickInfo.event.extendedProps.created_on});
        this.setState({eventUserId:clickInfo.event.extendedProps.created_by})

        var val = [{
          startDate: clickInfo.event.start,
          endDate: endDay,
          key: 'selection'
        }];
        this.setState({dateState:val});
    }

    handleEvents = (events) => {
        this.setState({
        currentEvents: events
        })
    }

    handleDeleteConfirm = () => {
      this.setState({isDeleteAction:true});
    }

}

function renderEventContent(eventInfo) {
  return (
    <>
      <b>{eventInfo.timeText}</b>
      <i>{eventInfo.event.title}</i>
    </>
  )
}


export default withParams(EventCalendar);