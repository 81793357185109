import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus, faMinus } from "@fortawesome/free-solid-svg-icons";
import { getNameFromList } from '../../services/Voters';
import { handleInputTelNumber } from '../helper/Validations';
import Multiselect from "react-widgets/Multiselect";


class UpdateNeutralInfluencers extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      neutralInfluencersArray: this.props.neutralInfluencersList,
      nameError: '',
      phoneNumberError: '',
      casteError: '',
      levelOfInfluenceError: '',
      influentialPosition: null,
      influentialPositionError: null,
    };
    this.name = React.createRef();
    this.phoneNumber = React.createRef();
    this.caste = React.createRef();
    this.religion = React.createRef();
    this.levelOfInfluence = React.createRef();
    this.onClickNeutralInfluencers = this.onClickNeutralInfluencers.bind(this);
    this.handleName = this.handleName.bind(this);
    this.handlePhoneNumber = this.handlePhoneNumber.bind(this);
    this.handleCaste = this.handleCaste.bind(this);
    this.handleLevelOfInfluence = this.handleLevelOfInfluence.bind(this);
    this.handleInfluentialPositionList = this.handleInfluentialPositionList.bind(this);
    this.handleOnChangeForTable = this.handleOnChangeForTable.bind(this);
    this.handlePartyUpdate = this.handlePartyUpdate.bind(this);
    this.handleInfluencersPositions = this.handleInfluencersPositions.bind(this);
  }

  handleInfluencersPositions(index,value){
    var neutralInfluencersArray = this.state.neutralInfluencersArray;
    neutralInfluencersArray[index]['position'] = value;
    this.setState({neutralInfluencersArray});
  }

  handleOnChangeForTable(event){
    this.state.neutralInfluencersArray[event.target.dataset.index][event.target.name] = event.target.value;
    let neutralInfluencersArray = this.state.neutralInfluencersArray;
    this.props.neutralInfluencers(neutralInfluencersArray);
    this.setState({neutralInfluencersArray:neutralInfluencersArray});
  }

  handlePartyUpdate(event) {
    var levelofinfluence = {};
    var religion = {};
    var caste = {};
    religion.id = event.target.value;
    caste.id = event.target.value;
    levelofinfluence.id = event.target.value;

    if(event.target.name == 'caste'){
      this.state.neutralInfluencersArray[event.target.dataset.index][event.target.name] = caste;
    }
    if(event.target.name == 'religion'){
      this.state.neutralInfluencersArray[event.target.dataset.index][event.target.name] = religion;
    }
    if(event.target.name == 'levelofinfluence'){
      this.state.neutralInfluencersArray[event.target.dataset.index][event.target.name] = levelofinfluence;
    }
    let neutralInfluencersArray = this.state.neutralInfluencersArray;
    this.props.neutralInfluencers(neutralInfluencersArray);
    this.setState({neutralInfluencersArray:neutralInfluencersArray});
  }

  handleInfluentialPositionList(event) {
    let newArray = [];
    for(let i=0;i<event?.length;i++) {
      newArray = [ ...newArray, {id: event[i].id} ]
    }
    this.setState({influentialPositionError: '', influentialPosition: newArray});
  }

  handleName() {
    this.setState({nameError: ''});
  }

  handlePhoneNumber() {
    this.setState({phoneNumberError: ''});
  }

  handleCaste() {
    this.setState({casteError: ''});
  }

  handleLevelOfInfluence() {
    this.setState({levelOfInfluenceError: ''});
  }

  onClickNeutralInfluencers() {
    let name = this.name.current.value;
    let phoneNumber = this.phoneNumber.current.value;
    let caste = this.caste.current.value;
    let religion = this.religion.current.value;
    let influentialPosition = this.state.influentialPosition;
    let levelOfInfluence = this.levelOfInfluence.current.value;
    if(name == '') {
      this.setState({nameError: 'Please enter name.'});
    }
    else if(phoneNumber == '') {
      this.setState({phoneNumberError: 'Please enter phone number.'});
    }
    else if(caste == '') {
      this.setState({casteError: 'Please select caste.'});
    }
    else if(influentialPosition == null || influentialPosition.length == 0) {
      this.setState({influentialPositionError: 'Please enter influential position.'});
    }
    else if(levelOfInfluence == '') {
      this.setState({levelOfInfluenceError: 'Please enter level of influence.'});
    }
    else {
      let neutralInfluencersObject = { 'name': name, 'phonenumber': phoneNumber, 'religion': religion, 'caste': caste, 'influentialPosition': influentialPosition, 'level_of_influence': levelOfInfluence, 'profileId':this.props.profileId };
      let neutralInfluencersArray = this.state.neutralInfluencersArray;
      neutralInfluencersArray = [ ...neutralInfluencersArray, neutralInfluencersObject ];
      this.name.current.value = '';
      this.phoneNumber.current.value = '';
      this.religion.current.value = '';
      this.caste.current.value = '';
      this.levelOfInfluence.current.value = '';
      this.props.neutralInfluencers(neutralInfluencersArray);
      this.setState({ neutralInfluencersArray, influentialPosition: null });
    }
  }

  removeNeutralInfluencers(event) {
    const neutralInfluencersArray = this.state.neutralInfluencersArray;
    let newNeutralInfluencersArray = [];
    for(let i=0;i<neutralInfluencersArray.length;i++) {
      if(i !== event.index) {
        newNeutralInfluencersArray = [ ...newNeutralInfluencersArray, neutralInfluencersArray[i] ];
      }
    }
    this.props.neutralInfluencers(newNeutralInfluencersArray);
    this.setState({ neutralInfluencersArray: newNeutralInfluencersArray });
  }

  componentDidUpdate(prevProps) {
    if(this.props.updateInfo != prevProps?.updateInfo){
      let neutralInfluencersArray = [];
      this.props?.updateInfo?.map((neutralInfluencer) => 
      neutralInfluencersArray = [ ...neutralInfluencersArray, neutralInfluencer ]
      );
      this.setState({ neutralInfluencersArray: neutralInfluencersArray});
      this.props.neutralInfluencers(neutralInfluencersArray);
    }
  }

  render() {
    return(
      <table className="table custom-table">
        <thead>
          <tr>
            <th colSpan={8} className="text-center bg-dark bg-opacity-50 custom-table-th-desktop">Neutral Influencers</th>
            <th className="text-center bg-dark bg-opacity-50 custom-table-th-mobile">Neutral Influencers</th>
          </tr>
          <tr className="align-middle custom-table-tr-desktop">
            <th className="text-center" scope="col">Sl. No.</th>
            <th scope="col">Name</th>
            <th scope="col">Phone number</th>
            <th scope="col">Religion</th>
            <th scope="col">Caste</th>
            <th scope="col">Influential Position</th>
            <th scope="col">Level of Influence</th>
            <th className="text-center" scope="col">Action</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td scope="row" data-label="" className="align-middle"></td>
            <td data-label="Name" className="align-middle">
              <input className="form-control" type="text" id="name" name="name" placeholder="Name" ref={ this.name } onChange={this.handleName} />
              <span className="text-danger fw-600">{this.state.nameError && this.state.nameError}</span>
            </td>
            <td data-label="Phone Number" className="align-middle">
              <input className="form-control" type="tel" id="phonenumber" name="phonenumber" placeholder="Phone Number" ref={ this.phoneNumber } onChange={this.handlePhoneNumber} onKeyPress={(event) => { handleInputTelNumber(event) }} />
              <span className="text-danger fw-600">{this.state.phoneNumberError && this.state.phoneNumberError}</span>
            </td>
            <td data-label="Religion" className="align-middle">
            <select name="religion" id="" ref={ this.religion } className='form-select'>
              <option value="">Select religion</option>
              { this.props.religionList?.map(religion => <option value={religion.id} key={religion.id} >{religion.name}</option>)}
            </select>
            </td>
            <td data-label="Caste" className="align-middle">
            <select name="caste" id="caste" ref={ this.caste } className='form-select' onChange={this.handleCaste}>
              <option value="">Select Caste</option>
              { this.props.subCasteList?.map(caste => <option value={caste.id} key={caste.id} >{caste.name}</option>)}
            </select>
            <span className="text-danger fw-600">{this.state.casteError && this.state.casteError}</span>
            </td>
            <td data-label="Influential Position" className="align-middle">
              <Multiselect data={this.props.influentialPositionList ? this.props.influentialPositionList : []} dataKey='id' textField="name" placeholder="Influential Position" onChange={this.handleInfluentialPositionList} value={this.state.influentialPosition != null ? this.state.influentialPosition : []} />
              <span className="text-danger fw-600">{this.state.influentialPositionError && this.state.influentialPositionError}</span>
            </td>
            <td data-label="Level of influence" className="align-middle">
              <select name="levelofinfluence" id="levelofinfluence" className="form-select" onChange={this.handleLevelOfInfluence} ref={ this.levelOfInfluence }>
                {this.props.levelOfInfluence?.map(level => 
                  <option key={level.id} value={level.id} >{level.name}</option>
                )} 
              </select>
              <span className="text-danger fw-600">{this.state.levelOfInfluenceError && this.state.levelOfInfluenceError}</span>
            </td>
            <td className="text-center align-middle" data-label="Action">
              <FontAwesomeIcon icon={ faPlus } className="btn btn-success" onClick={ this.onClickNeutralInfluencers } />
            </td>
          </tr>
          {
            this.state.neutralInfluencersArray?.map((neutralInfluencers, index) => 
              <tr key={ index } >
                <td className="text-center align-middle" scope="row" data-label="Sl. No.">{ index +1 }</td>
                <td data-label="Name" className="align-middle">
                  <input data-index={index} className="form-control" type="text" id={`name-${ index }-${ this.state.neutralInfluencersArray.length }`} key={`name-${ index }-${ this.state.neutralInfluencersArray.length }`} name="name" placeholder="Name" defaultValue={ neutralInfluencers.name } onChange={this.handleOnChangeForTable} />
                </td>
                <td data-label="Phone Number" className="align-middle">
                  <input data-index={index} className="form-control" type="number" id={`phonenumber-${ index }-${ this.state.neutralInfluencersArray.length }`} key={`phonenumber-${ index }-${ this.state.neutralInfluencersArray.length }`} name="phonenumber" placeholder="Phone Number" defaultValue={ neutralInfluencers.phone_number } onChange={this.handleOnChangeForTable} />
                </td>
                <td data-label="Religion" className="align-middle">
                  <select data-index={index} id={`religion-${index}-${this.state.neutralInfluencersArray.length}`} key={`religion-${index}-${this.state.neutralInfluencersArray.length}`} name="religion" ref={ this.religion } className='form-select' value={neutralInfluencers.religion.id} onChange={this.handlePartyUpdate}>
                    <option value="">Select religion</option>
                    { this.props.religionList?.map(religion => <option value={religion.id} key={religion.id} >{religion.name}</option>)}
                  </select>
                </td>
                <td data-label="Caste" className="align-middle">
                  <select data-index={index} id={`caste-${ index }-${ this.state.neutralInfluencersArray.length }`} key={`caste-${ index }-${ this.state.neutralInfluencersArray.length }`} name="caste" ref={ this.caste } className='form-select' onChange={this.handlePartyUpdate} value={neutralInfluencers.caste.id} >
                    <option value="">Select Caste</option>
                    { this.props.subCasteList?.map(caste => <option value={caste.id} key={caste.id} >{caste.name}</option>)}
                  </select>
                </td>
                <td data-label="Influential Position" className="align-middle">
                <Multiselect data={this.props.influentialPositionList ? this.props.influentialPositionList : []} dataKey='id' textField="name" placeholder="Influential Position" onChange={(value) => {this.handleInfluencersPositions(index, value)}} value={neutralInfluencers?.position} id={`influentialPosition-${ index }-${ neutralInfluencers.length }`} key={`influentialPosition-${ index }-${ neutralInfluencers.length }`} name="influentialPosition" />
                </td>
                <td data-label="Level of influence" className="align-middle">
                  <select data-index={index} id={`levelofinfluence-${ index }-${ this.state.neutralInfluencersArray.length }`} key={`levelofinfluence-${ index }-${ this.state.neutralInfluencersArray.length }`} name="levelofinfluence" className="form-select" onChange={this.handlePartyUpdate} ref={ this.levelOfInfluence } value={neutralInfluencers.position.id}>
                    {this.props.levelOfInfluence?.map(level => 
                      <option key={level.id} value={level.id} >{level.name}</option>
                    )} 
                  </select>
                </td>
                <td className="text-center align-middle" data-label="Action">
                  <FontAwesomeIcon icon={ faMinus } className="btn btn-danger" onClick={ () => this.removeNeutralInfluencers({ index }) } />
                </td>
            </tr>
            )
          }
        </tbody>
      </table>
    )
  }
}

export default UpdateNeutralInfluencers;