import React from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { Link } from "react-router-dom";

function dateFormat(date) {
    var date = new Date(date);
    var newDate = date.getDate();
    var newMonth = date.getMonth() + 1;
    var newYear = date.getFullYear();
    return `${newDate}-${newMonth}-${newYear}`;
}

class DashboardMembership extends React.Component {
    constructor(prop) {
        super(prop);
        this.state ={

        }
    }

    render() {
        return(
            <>
                <div className="card">
                    <div className="card-header">
                        <div className="row">
                        {
                            this.props.membersList?.length == 24 ? <div className="col-12 text-center my-auto"><h4>Thank you for enrolling 24 Primary Members.</h4></div> : <>
                                <div className="col-8 text-center my-auto">
                                    <h4>{ this.props.membersList?.length != 0 ? this.props.membersList?.length : 0 } of 24 Primary Members Added</h4>
                                </div>
                                <div className="col-4 text-right my-auto">
                                <Link to={`/primarymembershipform`} className="btn rounded-pill border shadow brand-header-color text-white icon-color-hover d-lg-flex justify-content-center align-items-center py-lg-3"><FontAwesomeIcon icon={ faPlus } className='fs-6 text-white pe-lg-2' /> <span className="d-none d-md-none d-lg-block fs-5"> Add Primary Member</span></Link>
                                </div>
                            </>
                        }
                        </div>
                    </div>
                    <div className="card-body overflow-x-scroll overflow-md-unset">
                        <table className="table">
                            <thead>
                                <tr>
                                    <th className="text-nowrap">Membership ID</th>
                                    <th className="text-nowrap">Name</th>
                                    <th className="text-nowrap">Mobile Number</th>
                                    <th className="text-nowrap">Date</th>
                                </tr>
                            </thead>
                            <tbody>
                            {
                                this.props.membersList?.map((member, index) => (<tr key={ index }>
                                        <td className="text-nowrap">{ member?.membership_number }</td>
                                        <td className="text-nowrap">{ member?.first_name }</td>
                                        <td className="text-nowrap">{ member?.mobile_number }</td>
                                        <td className="text-nowrap">{ dateFormat(member?.created_on) }</td>
                                    </tr>))
                            }
                            </tbody>
                        </table>
                    </div>
                </div>
            </>
        )
    }
}

export default DashboardMembership;