import React from "react";
import HouseFilter from "./HouseFilter";
import InchargesCards from "./InchargesCards";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFilter, faFilterCircleXmark } from "@fortawesome/free-solid-svg-icons";
import { getHousesList } from '../../services/Voters';
import { Link } from 'react-router-dom';
import ScaleLoader from "react-spinners/ScaleLoader";

class Houses extends React.Component {
  constructor(props) {
	super(props);
	this.state = {
	  showFilter: true,
	  value: '',
	  constituency: [],
	  block: [],
	  village: [],
	  houses: null,
	  constituencies: [],
	  blocks: [],
	  villages: [],
	  pollingStations: [],
	  selectedConstituency: 86,
	  selectedBlock: null,
	  selectedVillage: null,
	  selectedPollingStation: null,
	  selectedConstituencyObj: { "id": 86, "name": "Munugode" },
	  pageNUmber: 1,
	  pageSize: 30,
	  housesLoader: false
	};
	this.onClickFilter = this.onClickFilter.bind(this);
	this.onApplyHouseFilter = this.onApplyHouseFilter.bind(this);
	this.onChangeHousePage = this.onChangeHousePage.bind(this);
	this.handlePageSize = this.handlePageSize.bind(this);
  }

  handlePageSize(event) {
	this.setState({housesLoader: true, houses: null, pageSize: event.target.value});
	getHousesList(this.state.selectedConstituency, this.state.selectedBlock, this.state.selectedVillage, this.state.selectedPollingStation, this.state.pageNUmber, event.target.value).then((houses) => {
		this.setState({ houses, housesLoader: false });
	  }).catch((err) => {
		this.setState({housesLoader: false, houses: null})
	  })
  }

  onClickFilter(event) {
	this.setState({ value: event.target.value, showFilter: !this.state.showFilter });
  }

  onApplyHouseFilter(constituencies, blocks, villages, pollingStations, selectedConstituency, selectedBlock, selectedVillage, selectedPollingStation, selectedConstituencyObj) {
	this.setState({housesLoader: true, houses: null})
	getHousesList(selectedConstituency, selectedBlock, selectedVillage, selectedPollingStation, this.state.pageNUmber, this.state.pageSize).then((houses) => {
	  this.setState({ houses, constituencies, blocks, villages, pollingStations, selectedConstituency, selectedBlock, selectedVillage, selectedPollingStation, selectedConstituencyObj, housesLoader: false });
	}).catch((error) => {
	  this.setState({error, housesLoader: false})
	})
  }

  onChangeHousePage(event) {
	this.setState({housesLoader: true, houses: null});
	getHousesList(this.state.selectedConstituency, this.state.selectedBlock, this.state.selectedVillage, this.state.selectedPollingStation, event, this.state.pageSize).then((houses) => {
		this.setState({ houses, housesLoader: false });
	  }).catch((err) => {
		this.setState({housesLoader: false, houses: null})
	  })
  }
  
  componentDidMount() {
	this.setState({housesLoader: true})
	getHousesList(this.state.selectedConstituency, this.state.selectedBlock, this.state.selectedVillage, this.state.selectedPollingStation, this.state.pageNUmber, this.state.pageSize).then((houses) => {
		this.setState({ houses, housesLoader: false });
	  }).catch((error) => {
		this.setState({housesLoader: false, error})
	  })
  }

  render() {
	return(
	  <>
	  <div className="row">
		<div className="col-12">
			<h2 className="d-inline">House Incharges </h2>
			<span className="fs-5 btn btn-success ms-2 rounded-3 d-inlie" onClick={this.onClickFilter}><FontAwesomeIcon icon={ this.state.showFilter ? faFilterCircleXmark : faFilter } /></span>
			{/* <Link to={`/add-new-house-incharge`} className='btn btn-success d-inline float-right'>Add Incharge</Link> */}
		</div>
	  </div>
		  { this.state.showFilter && <HouseFilter onApplyHouseFilter={this.onApplyHouseFilter} constituencies={this.state.constituencies} blocks={this.state.blocks} villages={this.state.villages} pollingStations={this.state.pollingStations} selectedConstituency={this.state.selectedConstituency} selectedBlock={this.state.selectedBlock} selectedVillage={this.state.selectedVillage} selectedPollingStation={this.state.selectedPollingStation} selectedConstituencyObj={this.state.selectedConstituencyObj} /> }
		  <div>
			<div className="d-inline align-middle">
			Page Size: 
				<select name="pageSize" id="pageSize" className="ms-2 w-10 form-select d-inline" value={this.state.pageSize} onChange={this.handlePageSize} >
					<option value="30">30</option>
					<option value="120">120</option>
					<option value="240">240</option>
					<option value="360">360</option>
					<option value="600">600</option>
				</select>
			</div>
			<div className="d-inline float-right align-middle">House Count: {this.state.housesLoader == true ? <span><ScaleLoader color="#3f4342" height="10px" margin="1px" /></span> : this.state.houses?.count }</div>
		  </div>
		  <InchargesCards houses={ this.state.houses } pageSize={this.state.pageSize} housesLoader={this.state.housesLoader} onChangeHousePage={this.onChangeHousePage} />
		</>
	)
  }
}

export default Houses;