import React from "react";
import { getCommentsOfVolunteer,getCommentsFromRole } from '../../services/Voters';
import Pagination from '../Pagination';
import { RotatingLines } from  'react-loader-spinner';
import Error from '../error';

class CommentsOfVolunteer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      error: '',
      commentsList:[],
    };
    this.onChangePage = this.onChangePage.bind(this);
  }

  componentDidMount() {
		this.reloadComments(this.state.pageNumber, this.state.pageSize);
	}

  reloadComments(pageNumber, pageSize) {
		this.setState({loading: true});
		getCommentsFromRole(pageNumber, pageSize).then((commentsList) => {
  	  this.setState({commentsList, loading: false});
		}).catch((error) => {
			this.setState({error});
		})
	}

  onChangePage(event) {
    getCommentsOfVolunteer('', '', '', event).then((commentsList) => {
      this.setState({commentsList});
    }).catch((error) => {
      this.setState({error});
    })
  }

  render() {
    return(
      <>
        <div>
          <h3>Comments of Volunteer</h3>
        </div>
        <div className="card">
          <div className="card-body">
          { this.state.commentsList != '' ? 
            <div>
            <table className="table table-striped">
              <thead className="bg-primary">
                <tr>
                  <th className="align-middle">Date</th>
                  <th className="align-middle">Mobile</th>
                  <th className="align-middle">Volunteer</th>
                  <th className="align-middle">Comments</th>
                </tr>
              </thead>
              <tbody>
              {
                    this.state.commentsList.data.map(( comments, index ) => 
                      <tr key={ index }>
                        <td className='normal-font'>{ new Date(comments.created_on).toISOString().slice(0,10) }</td>
                        <td className='normal-font'>{ comments.profile.user.mobile_number }</td>
                        <td className='normal-font'>{ comments.profile.user.name }</td>
                        <td className='normal-font'>{ comments.text }</td>
                      </tr>
                    )
                }
              </tbody>
            </table>
            <div className="text-center align-middle">
              <Pagination pageCount={this.state.commentsList.total_pages} onChangePage={this.onChangePage} />
            </div>
          </div>
            : this.state.error != '' ? 
              <Error /> :
              <div className='data-loader text-center'>
                <RotatingLines strokeColor="grey"
                          strokeWidth="5"
                          animationDuration="0.75"
                          width="96"
                          visible={true} />
              </div>
            }
          </div>
        </div>
      </>
    )
  }
}

export default CommentsOfVolunteer;